import React from "react";
import NameTag from "../NameTag";
import "./mouse-renderer.scss";

type Props = {
    cursorPosition: [number, number];
    participantName: string;
};

const CurrentParticipantCursor: React.FC<Props> = (props) => {
    return (
        <div
            className="current-participant-cursor"
            style={{
                left: props.cursorPosition[0],
                // center this as closely as possible with the cursor
                top: props.cursorPosition[1] - 8,
            }}
        >
            <NameTag name={props.participantName} isCurrentParticipant={true} />
        </div>
    );
};

export default CurrentParticipantCursor;
