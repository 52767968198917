import { participantHasRole, updateParticipant } from "modules/utils";
import { Role } from "../content-types";
import { RemoveFacilitator } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const REMOVE_FACILITATOR: EventReducerDefinition<RemoveFacilitator, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: RemoveFacilitator) => {
        const participantInfo = state.participants.find((p) => p.id === payload.participantId);

        if (!participantInfo) return false;

        return participantHasRole(participantInfo, Role.Facilitator);
    },
    reducer: (state: SessionState, payload: RemoveFacilitator) => {
        if (payload.participantId) {
            return {
                ...state,
                participants: updateParticipant(state.participants, payload.participantId, (participant) => {
                    const newRoles = participant.assignedRoles.filter((r) => r !== Role.Facilitator);

                    return {
                        ...participant,
                        assignedRoles: newRoles,
                    };
                }),
            };
        }
        return state;
    },
};
export default REMOVE_FACILITATOR;
