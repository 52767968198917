import React, { useState } from "react";
import { Col, Nav, Navbar, Offcanvas, Row } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaRegCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { MainStepAddress, SessionState } from "modules/shared/types";
import { Section } from "modules/shared/content-types";
import { getStepStates } from "modules/shared/selectors/step-state/getStepStates";
import { flattenStepStates } from "modules/utils";
import TextEntryStepState from "modules/shared/activity/TextEntry/types";
import MultipleChoiceStepState from "modules/shared/activity/MultipleChoice/types";
import { DragAndDropStepState } from "modules/shared/activity/DragAndDrop/types";

type InPersonNavBarProps = {
    experienceName: string;
    sections: false | Section[];
    state: boolean | SessionState;
};

type FilteredStep = {
    id: string;
    title: string;
    completed: boolean;
    type: string;
};

const InPersonNavBar: React.FC<InPersonNavBarProps> = ({ experienceName, sections, state }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currentStepAddress = state && (state as SessionState).currentStep;
    const { sectionKey, stepKey } = currentStepAddress as MainStepAddress;
    const stepStates = getStepStates(state as SessionState);

    if (sections) {
        const flattenSteps = [...sections].flatMap((section) => section.steps);
        const flattenStepsAll = flattenStepStates(stepStates);
        const mergedSteps = flattenSteps.map((step, index) => ({ ...step, ...flattenStepsAll[index] }));
        const filteredSteps = mergedSteps.filter((step) => step.options?.enableInPersonDataCollection === true);

        const finalSteps = (stepsArray: typeof filteredSteps) => {
            return stepsArray.map((step) => {
                let completed = false;
                if (!!step.persistent) {
                    switch (step.contentType) {
                        case "textEntryStep":
                            completed = (step as TextEntryStepState).persistent.wasSubmitted;
                            break;
                        case "multipleChoiceStep":
                            completed = (step as MultipleChoiceStepState).persistent.wasSubmitted;
                            break;
                        case "dragAndDropStep":
                            completed = !!(step as DragAndDropStepState).persistent.cardCorrectnessRevealed;
                            break;
                        default:
                            completed = false;
                            break;
                    }
                }
                return {
                    id: step.id,
                    title: step.title,
                    completed: completed,
                    type: step.contentType,
                };
            });
        };

        const currentStepId = sections[sectionKey]?.steps[stepKey].id;

        const navigationLinkStyles = (currentStepId: string, step: FilteredStep) => {
            if (currentStepId === step.id) {
                return { fontWeight: "bold", color: "black" };
            }
            if (step.completed) {
                return { fontWeight: "bold", color: "green" };
            }
            return { color: "black" };
        };

        return (
            <>
                <Navbar bg="primary" data-bs-theme="dark" className="justify-content-center p-3">
                    <Row className="w-100">
                        <Col xs="auto" className="d-flex align-items-center pl-2">
                            <GiHamburgerMenu onClick={handleShow} size={30} color="white" />
                        </Col>
                        <Col className="text-center d-flex justify-content-center align-items-center">
                            <span className="text-white">{experienceName}</span>
                        </Col>
                        <Col xs="auto"></Col>
                    </Row>
                </Navbar>

                <Offcanvas show={show} onHide={handleClose} style={{ maxWidth: "70%" }}>
                    <Offcanvas.Header closeButton className="bg-primary text-white text-center font-weight-bold">
                        <Offcanvas.Title>Activity List</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {finalSteps(filteredSteps).map((step) => (
                            <Nav key={step.id} className="flex-column">
                                <Nav.Link style={navigationLinkStyles(currentStepId, step)}>
                                    {step.completed ? <FaCircle /> : <FaRegCircle />}
                                    &nbsp; {step.title}
                                    &nbsp;
                                    {currentStepId === step.id ? "(current)" : step.completed ? "(complete)" : ""}
                                </Nav.Link>
                            </Nav>
                        ))}
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
    return (
        <>
            <Navbar bg="primary" data-bs-theme="dark" className="justify-content-center">
                <Nav className="text-white">{experienceName}</Nav>
            </Navbar>
        </>
    );
};

export default InPersonNavBar;
