import { updateSessionState } from "modules/utils";
import { ParticipantMessageDelete } from "../actions-types";
import { ApplicationState } from "../application-state";

export default function (state: ApplicationState, payload: ParticipantMessageDelete) {
    return updateSessionState(state, (session) => {
        if (session && session.state === "READY") {
            const newParticipantMessages = session.participantMessages.filter((message) => message.id !== payload.id);
            return {
                ...session,
                participantMessages: newParticipantMessages,
            };
        }

        return session;
    });
}
