// import fromEntries from "object.fromentries"; // TS seems confused - we apparently need to import the polyfill directly? Sigh.
import { filter, first, groupBy, keys, maxBy, sortBy } from "lodash-es";
import { CardPositionState } from "modules/shared/activity/DragAndDrop/types";
import { Card, DragAndDropStep } from "modules/shared/content-types";
import { lruMemoize } from "reselect";

export const createCardPositionState = (unsortedInputCards: Card[], hasInputRow: boolean): CardPositionState[] => {
    return unsortedInputCards.map((c, i): CardPositionState => {
        return {
            cardId: c.id,
            columnId: hasInputRow ? "input-row" : "0",
            index: i,
            heldByParticipant: null,
            version: 0,
        };
    });
};

export type CardPositionCorrectness = "correct" | "incorrect" | null;
export type CardCheckResult = {
    [cardId: string]: CardPositionCorrectness;
};
export type CardCheckSpec = {
    checkCardOrder: boolean;
    columnSpecs: {
        [columnIdx: number]: string[];
    };
    warnings?: string[];
};

/**
 * Check cards against specified positions to see whether they've been sorted
 * correctly or not.
 *
 * Ok so this is messy, but it's for a reason.
 *
 * We want sorting to: a) have multiple possible sorts b) be able to optionally
 * check ordering within a single column, c) be easy to specify, and d) Give
 * reasonable reports about which cards are wrong (i.e. if you don't have the
 * right first card and everything is shifted up, they shouldn't all be wrong)
 *
 * That's hard. So this imperfect algorithm is roughly this:
 * 1) First check if things are in the right column
 * 2) Then, for each column
 *  a) Filter to just the cards that are correctly in that column and check their order
 *  b) Check the order for cards from "a" against each acceptable ordering.
 *  c) Mark the cards as correct or not, matched against the best possible ordering.
 * */

export function checkCardPositions(spec: CardCheckSpec | null, state: CardPositionState[]): CardCheckResult {
    if (!spec) {
        return {};
    }

    const correctArrangement = spec.columnSpecs;
    const checkOrdering = spec.checkCardOrder;

    // Per card evaluation: is it right or wrong ?
    const result: CardCheckResult = {};

    // Invert the spec to give us a card lookup to possible columns
    const expectedPosByCard = remapCorrectArrangementToBeByCard(correctArrangement);

    // Then check that each card is at least in the correct column
    state.forEach((cps) => {
        const currentColumn = cps.columnId;
        if (currentColumn === "input-row") {
            return;
        }

        const expectedColumns = expectedPosByCard[cps.cardId];

        if (expectedColumns && currentColumn) {
            // card is in the column we want it in
            // indexOf is O(n) which makes this algo O(n^2).... but we have
            // really small N so I think we're ok?
            if (expectedColumns.indexOf(currentColumn) >= 0) {
                result[cps.cardId] = "correct";
            } else {
                result[cps.cardId] = "incorrect";
            }
        }
    });

    if (checkOrdering) {
        // let's ignore ordering for cards not in the right column; they'll show
        // up as incorrect regardless
        const cardsInCorrectColumns = filter(state, (cps) => result[cps.cardId] === "correct");
        const cardsByColId = groupBy(cardsInCorrectColumns, (cps) => cps.columnId);

        // number keys are valid (i.e. indexes), but keys(...) only returns a
        // string array. Gah
        const columnIds: number[] = getColumnIds(correctArrangement) as unknown[] as number[];

        const acceptableOrderingsByColumnId = generateAllAcceptableOrderings(columnIds, correctArrangement);

        columnIds.forEach((colId) => {
            if (!colId) {
                // input row?
                return;
            }

            const actualColumnContents = sortBy(cardsByColId[colId] || [], "index");

            const cardsPresent = new Set(actualColumnContents.map((c) => c.cardId));

            const allAcceptableOrderings = acceptableOrderingsByColumnId[colId];
            const acceptableOrderingsForVisibleCards = allAcceptableOrderings.map((ordering) =>
                filter(ordering, (cardId) => cardsPresent.has(cardId)),
            );

            const bestOrdering = maxBy(
                acceptableOrderingsForVisibleCards.map((ordering) => {
                    let score = 0;
                    ordering.forEach((cardId, idx) => {
                        if (actualColumnContents[idx].cardId === cardId) {
                            score++;
                        }
                    });

                    return { ordering, score };
                }),
                "score",
            )!;

            for (let idx = 0; idx < actualColumnContents.length; idx++) {
                const currentElement = actualColumnContents[idx];

                result[currentElement.cardId] =
                    currentElement.cardId === bestOrdering.ordering[idx] ? "correct" : "incorrect";
            }
        });
    }

    return result;
}

// memoized so that the memoized functions below get the same args each time
const getColumnIds = lruMemoize(keys);

/**
 * Helper to read and validate the JSON provided by the user in the step
 * definition and validate it.
 *
 * This is memoized so that the memoization below on
 * generateAllAcceptableOrderings gets the same args.
 * */
export const readCardPositionSpecification = lruMemoize((stepDef: DragAndDropStep): CardCheckSpec | null => {
    const availableCards = new Map<string, Card>(stepDef.cards.map((c) => [c.key, c]));
    const columnEntries = stepDef.columnEntries ?? [];
    const columnKeys = columnEntries.map((column) => column.key);
    const columns = columnKeys.length > 0 ? columnKeys : stepDef.columns;
    const availableColumns = new Set<string>(columns);

    const correctCardPositions = columnEntries
        .map((column) => {
            return {
                [column.key]: column.validCards ? column.validCards : [],
            };
        })
        .filter((entry) => {
            return keys(entry).every((key) => entry[key].length > 0);
        });

    let spec;

    if (correctCardPositions.length > 0) {
        spec = Object.assign(
            {
                checkOrdering: stepDef.checkOrdering,
            },
            ...correctCardPositions,
        );
    } else {
        spec = stepDef.correctCardPositions;
    }

    if (!spec) {
        return null;
    }

    const warnings: string[] = [];

    const checkCardOrder = !!((spec as { checkOrdering: boolean }) || {}).checkOrdering;
    const originalColumnSpecs = spec as { [columnId: string]: string[] };
    const columnSpecs: { [columnIndex: number]: string[] } = {};

    keys(spec).forEach((columnKey) => {
        if (columnKey === "checkOrdering") {
            return;
        }

        if (!availableColumns.has(columnKey)) {
            warnings.push(`Card positions were specified for the unknown column "${columnKey}"`);
        }
    });

    columns.forEach((column, columnIndex) => {
        if (column === "checkOrdering") {
            return;
        }

        columnSpecs[columnIndex] = [];

        (originalColumnSpecs[column] || []).forEach((cardKey) => {
            const card = availableCards.get(cardKey);
            if (!!card) {
                columnSpecs[columnIndex].push(card.id);
            } else {
                warnings.push(
                    `A card position was specified for card "${cardKey}" but no card with that key could be found`,
                );
            }
        });
    });

    return {
        checkCardOrder,
        columnSpecs,
        warnings,
    };
});

/**
 * Helper - convert from { columnId => cards } mapping from Contentful to { card
 * -> columns } for easier checking */
const remapCorrectArrangementToBeByCard = lruMemoize(
    (correctArrangement: { [columnIdx: number]: string[] }): { [cardId: string]: string[] } => {
        const expectedCardColumns: { [cardId: string]: string[] } = {};
        for (const colSpec of Object.entries(correctArrangement)) {
            const [columnId, cardIdList] = colSpec;
            cardIdList.forEach((cardId) => {
                if (!expectedCardColumns[cardId]) {
                    expectedCardColumns[cardId] = [];
                }

                expectedCardColumns[cardId].push(columnId);
            });
        }

        return expectedCardColumns;
    },
);

const generateAllAcceptableOrderings = lruMemoize(
    (columnIds: number[], correctArrangements: { [columnIdx: number]: string[] }): string[][][] => {
        return columnIds.map((columnId) => {
            const acceptableOrderingTree = generateAllAcceptableOrderingTreesForColumn(correctArrangements[columnId]);

            return traverseSuffixTree(acceptableOrderingTree);
        });
    },
);

/**
 * Utility for scoring - this helper generates a tree showing that can be
 * traversed (see below) to get all acceptable orderings. This is because we
 * can allow a card to be dropped in more than one spot and have it be correct.
 * The return type is any[] because I couldn't figure out a good typescript
 * recursive nested-array-as-tree type. */
export const generateAllAcceptableOrderingTreesForColumn = (orderings: string[]): any[] => {
    if (orderings.length === 0) {
        return [];
    }

    const next = first(orderings)!;
    const occursAgain = orderings.lastIndexOf(next) > 0;

    if (!occursAgain) {
        return [next, ...generateAllAcceptableOrderingTreesForColumn(orderings.slice(1))];
    } else {
        const remainingItems = orderings.slice(1);
        const nextInstance = remainingItems.indexOf(next);

        const orderingsWithThisInstance = [
            next,
            ...remainingItems.slice(0, nextInstance),
            ...remainingItems.slice(nextInstance + 1, remainingItems.length),
        ];

        const orderingsWithTheOtherInstance = remainingItems;

        return [
            generateAllAcceptableOrderingTreesForColumn(orderingsWithThisInstance),
            generateAllAcceptableOrderingTreesForColumn(orderingsWithTheOtherInstance),
        ];
    }
};

/**
 * Utility for scoring - given the generateAllAcceptableOrderings fn above, this
 * will walk that tree and give you the flat list of every acceptable option. */
export const traverseSuffixTree = (tree: any[]): string[][] => {
    const currentNode = tree[0];
    if (!currentNode) {
        return [];
    }

    const table: string[][] = [];

    const thisPath: string[] = [];

    let seenArray = false;
    tree.forEach((node) => {
        if (typeof node === "string") {
            if (seenArray) {
                throw new Error("invalid suffix tree");
            }

            thisPath.push(node);
        } else if (Array.isArray(node)) {
            // Once we start branching, there's no going back
            seenArray = true;

            const subpaths = traverseSuffixTree(node);
            subpaths.forEach((subpath) => {
                table.push([...thisPath, ...subpath]);
            });
        }
    });

    if (!seenArray) {
        return [thisPath];
    } else {
        return table;
    }
};
