import { Icon } from "modules/shared/activity/Markup/types";
import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    icon: Icon;
};

const createAddMarkupIcon = (args: Args): SessionEvent => {
    return {
        type: "ADD_MARKUP_ICON",
        icon: args.icon,
        uuid: uuidv4(),
    };
};

export default createAddMarkupIcon;
