import { addMinutes, isAfter } from "date-fns";
import { WebsocketMessage } from "modules/shared/message-types";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { v4 } from "uuid";
import { Action, WebsocketMessageReceived } from "../actions-types";
import { ApplicationState } from "../application-state";
import { IClientSocketManager } from "../client-socket-manager";
import { isPredictable } from "../reducers/utils";
import { sessionState } from "../selectors";

export function createRootSaga(connectionManager: IClientSocketManager): () => Generator {
    function* connectToSession(payload: Action) {
        if (payload.type != "CONNECT_TO_SESSION") {
            throw new Error(`Invalid payload for CONNECT_TO_SESSION: "${payload.type}"`);
        }

        yield put<Action>({
            type: "CONNECT_TO_SESSION_STARTED",
            sessionId: payload.sessionId,
        });
        const socketId: string = yield call(connectionManager.connect, {
            sessionId: payload.sessionId,
        });

        yield put<Action>({
            type: "CONNECT_TO_SESSION_WAITING_FOR_INITIAL_STATE",
            sessionId: payload.sessionId,
            participantId: socketId,
        });
    }

    function* socketDisconnect(payload: Action) {
        if (payload.type !== "DISCONNECT_FROM_SESSION") {
            throw new Error(`Invalid payload for DISCONNECT_FROM_SESSION: "${payload.type}"`);
        }

        yield call(connectionManager.disconnect);
    }

    function* serverMessageSend(payload: Action) {
        if (payload.type != "MESSAGE_SEND") {
            throw new Error(`Invalid payload for CONNECT_TO_SESSION: "${payload.type}"`);
        }
        // Connection manager has list of connections - unwrapping payload.payload based off "nested" actions
        const websocketSender = connectionManager.getDispatcherFor();
        if (websocketSender) {
            const currentAppState: ApplicationState = yield select();
            const currentSessionState = sessionState(currentAppState);
            if (!!currentSessionState && currentSessionState.state === "READY") {
                if (payload.payload.type === "SESSION_MESSAGE") {
                    yield put<Action>({
                        type: "UPDATE_LAST_ACTIVE_TIME",
                    });
                } else {
                    if (
                        !currentSessionState.isIdle &&
                        isAfter(new Date(), addMinutes(currentSessionState.lastActiveTime, 120))
                    ) {
                        yield put<Action>({
                            type: "SET_PARTICIPANT_IDLE",
                            isIdle: true,
                        });
                    }
                }
            }

            if (payload.payload.type === "SESSION_MESSAGE" && isPredictable(payload.payload.payload.event.type)) {
                /** add to uncommitted for Predictable Events */
                yield put({
                    type: "ADD_TO_UNCOMMITTED",
                    payload: payload.payload,
                });
            } else if (payload.payload.type === "SESSION_MESSAGE") {
                /** lock UI until unpredictable event resolves */
                yield put({
                    type: "WAIT_FOR_RESOLUTION",
                    uuid: payload.payload.payload.event.uuid,
                });
            }
            yield call(websocketSender, payload.payload);
        } else {
            throw new Error(`No dispatcher for connection "${payload}"`);
        }
    }

    /**
     * Handle reconnect flow. This manages the state transitions and works in
     * conjunction with:
     * * websocketMessageReceived - handles all incoming messages
     * * reincarnationRequested - state transition noting when we've sent the request
     * * reincarnationCompleted - state transition to READY when the reincarnation is processed */
    function* socketReconnect(payload: Action) {
        if (payload.type != "SOCKET_RECONNECT") {
            throw new Error(`Invalid payload for SOCKET_RECONNECT: "${payload.type}"`);
        }

        // Watch all incoming messages:
        // * when we get both an initial state
        // * and have our current participant
        // Then we can request to be reincarnated

        while (true) {
            const messageReceived: WebsocketMessageReceived = yield take("WEBSOCKET_MESSAGE_RECEIVED");
            // only work on one connection at a time

            const currentAppState: ApplicationState = yield select();
            const currentSessionState = sessionState(currentAppState);
            if (!currentSessionState) {
                console.warn(`Session ${payload} not found, aborting reconnect process`);
                return;
            }

            if (currentSessionState.state !== "RECONNECTED_WAITING_FOR_REINCARNATION") {
                console.warn(`Session ${payload} not in reconnect, aborting`);
                return;
            }

            const websocketSender = connectionManager.getDispatcherFor();
            if (!websocketSender) {
                console.warn("Aborting reconnect, no dispatcher for ", payload);
                return;
            }

            const oldParticipant = currentSessionState.serverSessionState.participants.find(
                (p) => p.id === currentSessionState.oldParticipantId,
            );
            const newParticipant = currentSessionState.serverSessionState.participants.find(
                (p) => p.id === currentSessionState.newParticipantId,
            );

            if (currentSessionState.reincarnationState === "waitingForParticipant") {
                // If we have both the old and new participant, send the
                // reincarnation request
                if (newParticipant && oldParticipant) {
                    const spellToReincarnate: WebsocketMessage = {
                        type: "SESSION_MESSAGE",
                        payload: {
                            event: {
                                type: "reincarnateParticipant",
                                uuid: v4(),
                                participantIdToCopyDataFrom: oldParticipant.id,
                                participantIdToCopyDataTo: newParticipant.id,
                            },
                            resolution: null,
                        },
                    };
                    yield put<Action>({
                        type: "REINCARNATION_REQUESTED",
                        socketId: currentSessionState.newParticipantId,
                    });
                    yield call(websocketSender, spellToReincarnate);
                } else if (!oldParticipant) {
                    // Lost the old one somewhere, nothing left to reincarnate
                    // :( Guess we're done, let's just be connected. Participant
                    // will probably see the welcome seq. again but that's not
                    // avoidable at this point.

                    console.warn("Abandoning reincarnate flow - old participant no longer present");
                    yield put<Action>({
                        type: "REINCARNATION_COMPLETED",
                        socketId: currentSessionState.newParticipantId,
                    });
                }
            } else if (currentSessionState.reincarnationState === "requestSent") {
                // If we've sent the request, check if this is it coming back
                // and if so, let's transition to READY
                if (
                    messageReceived.payload.type === "SESSION_MESSAGE" &&
                    messageReceived.payload.payload.event.type === "reincarnateParticipant"
                ) {
                    const reincarnation = messageReceived.payload.payload.event;
                    if (reincarnation.participantIdToCopyDataTo === currentSessionState.newParticipantId) {
                        const spellToNegateZombies: WebsocketMessage = {
                            type: "SESSION_MESSAGE",
                            payload: {
                                event: {
                                    type: "participantRemoved",
                                    uuid: v4(),
                                    id: currentSessionState.oldParticipantId,
                                },
                                resolution: null,
                            },
                        };
                        yield call(websocketSender, spellToNegateZombies);

                        yield put<Action>({
                            type: "REINCARNATION_COMPLETED",
                            socketId: currentSessionState.newParticipantId,
                        });
                    }
                }
            }
        }
    }

    function* rootSaga() {
        yield takeLatest<Action>("CONNECT_TO_SESSION", connectToSession);
        yield takeLatest<Action>("MESSAGE_SEND", serverMessageSend);
        yield takeLatest<Action>("SOCKET_RECONNECT", socketReconnect);
        yield takeLatest<Action>("DISCONNECT_FROM_SESSION", socketDisconnect);
    }

    return rootSaga;
}
