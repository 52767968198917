import React from "react";
import { useSessionSelector } from "../SessionRenderer/context";
import { currentStepNumber } from "modules/shared/selectors/step-address/currentStepNumber";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { useLocalizedString } from "modules/client/localization";
import { isIntroStepAddress } from "modules/shared/utils";
import { getIntroSectionTitles } from "modules/client/utils";

type StepInfoProps = {
    inWelcomeMode: boolean;
};

const StepInfo: React.FC<StepInfoProps> = (props) => {
    const stepIndex = useSessionSelector(currentStepAddress);
    const currentStepNum = useSessionSelector(currentStepNumber);
    const currentStep = useSessionSelector(createGenericStepDefinitionSelector());
    const localized = useLocalizedString();
    const introCondition = isIntroStepAddress(stepIndex);

    const introTitle =
        introCondition && currentStepNum !== "exit" ? getIntroSectionTitles(localized)[currentStepNum] : "";

    let stepInfo = localized("toolbar_welcome");

    if (introCondition) {
        stepInfo = localized("toolbar_intro", introTitle, localized("vdpIntro_title"));
    } else if (!props.inWelcomeMode) {
        if (stepIndex !== "exit" && currentStepNum !== "exit" && currentStep) {
            const id = `"${currentStep.contentType}"`;

            stepInfo = localized("toolbar_stepOf", stepIndex.sectionKey + 1, currentStepNum, currentStep.title, id);
        } else {
            stepInfo = localized("toolbar_exit");
        }
    }
    return <div className="step-info">{stepInfo}</div>;
};
export default StepInfo;
