import { createExitStepDefinition, createProgressCheckStepDefinition } from "modules/utils";
import { StepDefinition, StepDefinitionKey, StepRecord } from "../../../content-types";
import { SessionState } from "../../../types";

export function createCurrentStepDefinitionSelector<
    TType extends StepDefinitionKey,
    TStepDef extends StepDefinition & StepRecord<TType>,
>(t: TType): (s: SessionState) => TStepDef | null {
    return (sessionState: SessionState): TStepDef | null => {
        const stepKey = sessionState.currentStep;

        if (stepKey === "exit") {
            return createExitStepDefinition(sessionState.script) as TStepDef;
        } else if (stepKey.stepKey === "ProgressCheck") {
            return createProgressCheckStepDefinition(sessionState.script, stepKey.sectionKey) as TStepDef;
        }
        const stepDefinition = sessionState.script.sections[stepKey.sectionKey].steps[stepKey.stepKey];
        return stepDefinition.contentType === t ? (stepDefinition as TStepDef) : null;
    };
}
