import { formatDistance } from "date-fns";
import { ContentfulComment, ParticipantMessage } from "modules/client/application-state";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { sessionContentfulManagementComments } from "modules/client/selectors";
import { contentfulManagementRequest } from "modules/client/services/contentful";
import { ContentfulManagementFetchTypes } from "modules/shared/contentful-mgmt-types";
import { createCurrentStepEntryIdSelector } from "modules/shared/selectors/step-address/createCurrentStepEntryIdSelector";
import React from "react";
import { Badge, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { IoMdTrash } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { useSessionSelector } from "../SessionRenderer/context";
import StepInfo from "./StepInfo";
import { useLocalizedString } from "../../client/localization";

type CommentListModalProps = {
    inWelcomeMode: boolean;
    isCommentListOpen: boolean;
    setIsCommentListOpen: () => void;
};

const CommentListModal: React.FC<CommentListModalProps> = (props) => {
    const dispatch = useDispatch();
    const localized = useLocalizedString();
    const comments = useSelector(sessionContentfulManagementComments);
    const currentCommentsList = useSelector(sessionContentfulManagementComments);
    const currentStepEntryId = useSessionSelector(createCurrentStepEntryIdSelector());

    const timeToString = (date: Date): string => {
        const now = new Date();
        const distanceString = formatDistance(now, date);
        return `${distanceString} ago`;
    };

    const deleteComment = React.useCallback(
        async (comment: ContentfulComment) => {
            const dat = {
                entryId: currentStepEntryId,
                commentId: comment.commentId,
                version: comment.version,
            };
            const deletedComment = await contentfulManagementRequest("DeleteComment", dat).then(
                (resp): Promise<ContentfulManagementFetchTypes["DeleteComment"]["resp"]> =>
                    resp.json() as unknown as Promise<ContentfulManagementFetchTypes["DeleteComment"]["resp"]>,
            );
            let message: ParticipantMessage;
            if (deletedComment.type === "SUCCESS") {
                // create success message for our toast
                message = {
                    id: v4(),
                    message: "Comment successfully deleted.",
                    success: true,
                };

                const newCommentsList = currentCommentsList.filter((x) => x.commentId !== comment.commentId);
                dispatch({
                    type: "UPDATE_CONTENTFUL_MANAGEMENT",
                    payload: {
                        comments: newCommentsList,
                    },
                });
            } else {
                // create failure message for our toast
                message = {
                    id: v4(),
                    message: `Comment failed to delete in contentful with the following message: ${deletedComment.message}`,
                    success: false,
                };
            }

            // send message to state to be displayed as a toast
            const participantMessageEvent = createParticipantMessageReceived({
                payload: message,
            });
            dispatch(participantMessageEvent);
        },
        [currentStepEntryId, dispatch, currentCommentsList],
    );

    const createHandleDeleteComment = (comment: ContentfulComment): (() => void) => {
        return async () => {
            await deleteComment(comment);
        };
    };

    const createCommentRow = (comment: ContentfulComment, i: number) => {
        return (
            <ListGroupItem key={i} className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <Badge bg="info">{timeToString(comment.enteredAt)}</Badge>
                    <p>{comment.body}</p>
                </div>
                {comment.ownerId === APP_CONTENTFUL_USER_ID && (
                    <div onClick={createHandleDeleteComment(comment)} style={{ cursor: "pointer" }}>
                        <IoMdTrash />
                    </div>
                )}
            </ListGroupItem>
        );
    };
    return (
        <Modal contentClassName="comments-modal" show={props.isCommentListOpen} onHide={props.setIsCommentListOpen}>
            <Modal.Header closeButton>
                <Modal.Title>{localized("toolbar_buildComments")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StepInfo inWelcomeMode={props.inWelcomeMode} />
                <hr />
                <ListGroup variant="flush">{comments.map(createCommentRow)}</ListGroup>
            </Modal.Body>
        </Modal>
    );
};
export default CommentListModal;
