import { Icon } from "../../../activity/Markup/types";
import { SessionState } from "../../../types";
import { getStepState } from "../../step-state/getStepState";
import { createSingleAssetPreviousMarkupStepAddressSelector } from "./createSingleAssetPreviousMarkupStepAddressSelector";

export function singleAssetPreviouslyPlacedIcons(state: SessionState): Icon[] {
    const stepAddresses = createSingleAssetPreviousMarkupStepAddressSelector()(state);
    const stepStates = stepAddresses.map((addr) => getStepState("SINGLE_ASSET", state, addr));
    return stepStates.reduce((currentIcons: Icon[], stepState) => {
        if (!!stepState?.persistent?.markupIcons) {
            return currentIcons.concat(...stepState.persistent.markupIcons);
        }
        return currentIcons;
    }, []);
}
