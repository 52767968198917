import { clearPrevAndAddNewRoleAssignment, getPoolOfEligibleParticipantsForNavigatorRole } from "modules/utils";
import { Role, StepExtraOptions } from "../content-types";
import { GoToStepOptionsResolutions, StartExperienceResolutions } from "../session-events";
import { SessionState } from "../types";

const applyStepOptions = (
    state: SessionState,
    options: StepExtraOptions | undefined,
    resolution: GoToStepOptionsResolutions | null,
): SessionState => {
    const newState = { ...state };
    if (newState.currentStep === "exit") {
        throw new Error("Attempting to apply options to exit step.");
    }

    let poolOfEligibleParticipantsForNavigatorRole = newState.poolOfEligibleParticipantsForNavigatorRole;
    let furthestSectionViewed = newState.furthestSectionViewed;

    if (resolution && resolution.rolesAssigned) {
        resolution.rolesAssigned.forEach((assignment) => {
            if (assignment) {
                const result = clearPrevAndAddNewRoleAssignment(newState.participants, assignment);
                newState.participants = result.participants;
                if (assignment.roleId === Role.Navigator || assignment.roleId === Role.Facilitator) {
                    poolOfEligibleParticipantsForNavigatorRole = getPoolOfEligibleParticipantsForNavigatorRole(
                        newState.participants,
                        poolOfEligibleParticipantsForNavigatorRole,
                        assignment.participantId,
                    );
                }
            }
        });
    }

    if (!newState.isIntroStep) {
        furthestSectionViewed = Math.max(furthestSectionViewed, newState.currentStep.sectionKey);
    }

    return {
        ...newState,
        poolOfEligibleParticipantsForNavigatorRole,
        furthestSectionViewed,
    };
};
export default applyStepOptions;
