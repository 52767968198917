import { useMemo } from "react";
import { AppStrings, useLocalizedString } from "modules/client/localization";
import { StepDefinition } from "modules/shared/content-types";

type AriaLabelsMapType = {
    [key: string]: {
        label: keyof AppStrings;
        description: keyof AppStrings;
    };
};

const useActivityAriaLabels = (currentStep: StepDefinition) => {
    const localized = useLocalizedString();
    const noActivityFoundText = localized("vdpActivity_notFound", currentStep.contentType);

    const ariaLabelsMap: AriaLabelsMapType = useMemo(
        () => ({
            cardStackWithVisualStep: {
                label: "vdpActivity_cardStackWithVisualAriaLabel",
                description: "vdpActivity_cardStackWithVisualAriaDescription",
            },
            revealCardStep: {
                label: "vdpActivity_revealCardAriaLabel",
                description: "vdpActivity_revealCardAriaDescription",
            },
            dragAndDropStep: {
                label: "vdpActivity_dragAndDropAriaLabel",
                description: "vdpActivity_dragAndDropAriaDescription",
            },
            matchingStep: {
                label: "vdpActivity_matchingStepAriaLabel",
                description: "vdpActivity_matchingStepAriaDescription",
            },
            recallStep: {
                label: "vdpActivity_recallStepAriaLabel",
                description: "vdpActivity_recallStepAriaDescription",
            },
            multipleChoiceStep: {
                label: "vdpActivity_multipleChoiceAriaLabel",
                description: "vdpActivity_multipleChoiceAriaDescription",
            },
            textEntryStep: {
                label: "vdpActivity_textEntryAriaLabel",
                description: "vdpActivity_textEntryAriaDescription",
            },
            singleAssetStep: {
                label: "vdpActivity_singleAssetStepAriaLabel",
                description: "vdpActivity_singleAssetStepAriaDescription",
            },
            comparisonStep: {
                label: "vdpActivity_comparisonStepAriaLabel",
                description: "vdpActivity_comparisonStepAriaDescription",
            },
            videoStep: {
                label: "vdpActivity_videoStepAriaLabel",
                description: "vdpActivity_videoStepAriaDescription",
            },
            progressCheck: {
                label: "vdpActivity_progressCheckAriaLabel",
                description: "vdpActivity_progressCheckAriaDescription",
            },
            exit: {
                label: "vdpActivity_exitStepAriaLabel",
                description: "vdpActivity_exitStepAriaDescription",
            },
        }),
        [],
    );

    const ariaLabels = useMemo(() => ariaLabelsMap[currentStep.contentType], [currentStep.contentType]);

    if (ariaLabels) {
        return {
            ariaLabel: localized(ariaLabels.label),
            ariaDescription: localized(ariaLabels.description),
        };
    }

    return { ariaLabel: noActivityFoundText, ariaDescription: "No activity found" };
};

export default useActivityAriaLabels;
