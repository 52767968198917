import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";

// not used at the moment but kept in place
export async function loginCallback(
    msalInstance: IPublicClientApplication,
    payload: AuthenticationResult | null,
): Promise<void> {
    if (!payload?.account) {
        return;
    }
    msalInstance.setActiveAccount(payload.account);
    return Promise.resolve();
}
