import classNames from "classnames";
import RichText from "modules/components/RichText";
import { useSessionParticipantId, useSessionSelector } from "modules/components/SessionRenderer/context";
import { Card } from "modules/shared/content-types";
import { createNavigationDisabledSelector } from "modules/shared/selectors/navigation-state/createNavigationDisabledSelector";
import { createCurrentStepStateSelector } from "modules/shared/selectors/step-state/createCurrentStepStateSelector";
import React, { useEffect, useRef } from "react";
import BootstrapCard from "react-bootstrap/Card";
import ReactCardFlip from "react-card-flip";
import "./RevealCard.scss";

type Props = {
    card: Card;
    isCardFlipped: boolean;
    handleFlipCardToBack: (cardId: string) => void;
    handleFlipCardToFront: (cardId: string) => void;
    backgroundHiddenGlobally: boolean;
};

const FlipCard: React.FC<Props> = (props) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const stepState = useSessionSelector(createCurrentStepStateSelector("REVEAL_CARD"));

    const isNavigationDisabled = useSessionSelector(createNavigationDisabledSelector(useSessionParticipantId()));

    const cardWasViewed = stepState?.persistent.viewedCardIds.includes(props.card.id);

    const backgroundIsHidden = (props.card.hideBackground ?? false) || props.backgroundHiddenGlobally;

    const cardClassNames = classNames({
        "reveal-card": true,
        "non-interactive": isNavigationDisabled,
        "hidden-background": backgroundIsHidden,
        viewed: cardWasViewed,
    });

    const cardFrontBodyClassNames = classNames({
        "body-hidden": props.isCardFlipped,
    });

    const cardBackBodyClassNames = classNames({
        "body-hidden": !props.isCardFlipped,
    });

    const cardHasBack = !!props.card.detailsBack;
    // if the card is using the body as its title, do not show the title on reveal
    const cardTitle = props.card.useContentAsTitle ? null : props.card.title;

    useEffect(() => {
        const card = cardRef.current;
        if (card) {
            card.style.cssText = props.card.additionalCssProperties;
        }
    }, [props.card]);

    return (
        <>
            <BootstrapCard
                ref={cardRef}
                className={cardClassNames}
                onClick={() => {
                    props.isCardFlipped
                        ? props.handleFlipCardToFront(props.card.id)
                        : props.handleFlipCardToBack(props.card.id);
                }}
            >
                <ReactCardFlip isFlipped={props.isCardFlipped} flipDirection="horizontal">
                    {/* front side */}
                    <>
                        {cardTitle && (
                            <BootstrapCard.Header className={backgroundIsHidden ? "hidden-background" : ""}>
                                <BootstrapCard.Title>{cardTitle}</BootstrapCard.Title>
                            </BootstrapCard.Header>
                        )}
                        <BootstrapCard.Body className={cardFrontBodyClassNames}>
                            <RichText document={props.card.details} />
                        </BootstrapCard.Body>
                    </>
                    {/* back side */}
                    <>
                        {cardHasBack && (
                            <BootstrapCard.Body className={cardBackBodyClassNames}>
                                <RichText document={props.card.detailsBack} />
                            </BootstrapCard.Body>
                        )}
                    </>
                </ReactCardFlip>
            </BootstrapCard>
        </>
    );
};

export default FlipCard;
