import { Entry } from "contentful";
import { AllEntryTypeSkeletons } from "./contentful-types";
import { MapState, PaceCheck, StepAddress, StepIntroAddress, StepState } from "./types";
import { Section } from "./content-types";

export const getMapState = (focusMapOn: string[] | undefined): MapState | null => {
    const focusArea = focusMapOn?.map((s) => parseInt(s));
    if (!!focusArea && focusArea.length !== 0) {
        if (focusArea.length === 4 && focusArea.every((n) => !Number.isNaN(n))) {
            return {
                topLeft: [focusArea[0], focusArea[1]],
                bottomRight: [focusArea[2], focusArea[3]],
            };
        } else {
            console.error("Could not properly parse map focus area.");
            return null;
        }
    }

    return null;
};

export function notNullOrUndefined<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function getSectionRecommendedTime(sectionEntry: Section): number {
    return sectionEntry.time ? sectionEntry.time * 60 : 0;
}

export function timeRecommendedForSections(sectionEntries: Section[]): number {
    return sectionEntries.reduce((accumulator, sectionEntry) => {
        const recommendedTime = getSectionRecommendedTime(sectionEntry);
        accumulator += recommendedTime;
        return accumulator;
    }, 0);
}

export function definedEntryReference<T extends AllEntryTypeSkeletons>(
    entry: Entry<T, "WITHOUT_UNRESOLVABLE_LINKS", "string"> | undefined,
): entry is Entry<T, "WITHOUT_UNRESOLVABLE_LINKS", "string"> {
    return !!entry && !!entry.fields;
}

export function definedEntryReferences<T extends AllEntryTypeSkeletons>(
    entries: (Entry<T, "WITHOUT_UNRESOLVABLE_LINKS", "string"> | undefined)[],
): Entry<T, "WITHOUT_UNRESOLVABLE_LINKS", "string">[] {
    return entries.filter(definedEntryReference);
}

export const TIME_DIFFERENCE_TOLERANCE_FACTOR = 0.08;

export function evaluatePace(secondsRecommended: number, secondsPassed: number): PaceCheck {
    // we tolerate a percentage difference between the time devoted and recommended
    const diffTolerance = TIME_DIFFERENCE_TOLERANCE_FACTOR * secondsRecommended;
    const timeDiff = secondsPassed - secondsRecommended;
    if (Math.abs(timeDiff) <= diffTolerance) {
        return PaceCheck.OnPace;
    } else if (timeDiff < 0) {
        // negative difference means ahead of pace
        return PaceCheck.AheadOfPace;
    } else {
        return PaceCheck.BehindPace;
    }
}

export const isIntroStepAddress = (x: StepAddress | StepIntroAddress): x is StepIntroAddress => {
    return (x as StepIntroAddress).introStepKey !== undefined;
};

export const isStepAddress = (x: StepAddress | StepIntroAddress): x is StepAddress => {
    if (x === "exit") {
        return true;
    }
    if (typeof x === "object") {
        return (x as StepIntroAddress).introStepKey === undefined;
    }
    return false;
};

export function stepAddressesAreEqual(
    firstAddress: StepAddress | StepIntroAddress,
    secondAddress: StepAddress | StepIntroAddress,
): boolean {
    if (isIntroStepAddress(firstAddress)) {
        if (!isIntroStepAddress(secondAddress)) {
            return false;
        }
        return (
            secondAddress.introStepKey === firstAddress.introStepKey &&
            secondAddress.iceBreakerNavPoolIndex === firstAddress.iceBreakerNavPoolIndex
        );
    }
    if (isStepAddress(firstAddress)) {
        if (!isStepAddress(secondAddress)) {
            return false;
        }
        if (
            (firstAddress === "exit" && !(secondAddress === "exit")) ||
            (!(firstAddress === "exit") && secondAddress === "exit")
        ) {
            return false;
        }
        if (firstAddress === "exit" && secondAddress === "exit") {
            return true;
        }
        if (!(firstAddress === "exit" || secondAddress === "exit")) {
            return (
                secondAddress.sectionKey === firstAddress.sectionKey && secondAddress.stepKey === firstAddress.stepKey
            );
        }
    }
    return false;
}
