import { SessionState } from "../../types";
import { createSelector } from "@reduxjs/toolkit";
import { visibleMarkupIconsSelector } from "modules/shared/selectors/map/visibleMarkupIconsSelector";
import { createParticipantInfoStepMarkupIconsLimitSelector } from "../step-definition/info-step/createParticipantInfoStepMarkupIconLimitSelector";
import { isStepAddressEqual } from "modules/utils";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";

export function participantInfoStepMarkupIconLimit(participantId: string | null): (s: SessionState) => number {
    return createSelector(
        [visibleMarkupIconsSelector, createParticipantInfoStepMarkupIconsLimitSelector(), currentStepAddress],
        (markupIcons, defaultLimit, currentStep) => {
            if (markupIcons) {
                const participantMarkupIcons = markupIcons.filter(
                    (icon) => icon.participantId === participantId && isStepAddressEqual(icon.addedOnStep, currentStep),
                );
                const currentParticipantLimit = defaultLimit && defaultLimit - participantMarkupIcons.length;
                if (currentParticipantLimit !== undefined && currentParticipantLimit <= 0) {
                    return 0;
                }
                return currentParticipantLimit;
            } else {
                return defaultLimit;
            }
        },
    );
}
