import classNames from "classnames";
import RichText from "modules/components/RichText";
import { Card } from "modules/shared/content-types";
import React from "react";
import { Card as BootstrapCard, Col, Row } from "react-bootstrap";
import "./ComparisonCard.scss";

type Props = {
    card: Card;
};

const ComparisonCard: React.FC<Props> = (props) => {
    const cardClassNames = classNames("card", { "hidden-background": props.card.hideBackground });

    return (
        <BootstrapCard className={cardClassNames}>
            <BootstrapCard.Body className="card-body">
                <Row>
                    <Col xs={12}>
                        {props.card.useContentAsTitle ? (
                            <RichText document={props.card.details} />
                        ) : (
                            <BootstrapCard.Title>{props.card.title}</BootstrapCard.Title>
                        )}
                    </Col>
                </Row>
            </BootstrapCard.Body>
        </BootstrapCard>
    );
};

export default ComparisonCard;
