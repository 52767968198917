import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    hotspotIdx: number;
    hotspotUuid: string;
};

const createHotspotShowDetails = (args: Args): SessionEvent => {
    return {
        type: "HOTSPOT__SHOW_DETAILS",
        hotspotIdx: args.hotspotIdx,
        hotspotUuid: args.hotspotUuid,
        uuid: uuidv4(),
    };
};
export default createHotspotShowDetails;
