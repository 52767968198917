import { SendSessionReportRequest } from "modules/client/actions-types/sessionReportActionTypes";
import { SelectedFilterState } from "modules/client/session-report-state";

export default function createSendSessionReportRequest(
    experienceUuid: string,
    filters: SelectedFilterState,
): SendSessionReportRequest {
    return {
        type: "SEND_SESSION_REPORT_REQUEST",
        filters,
        experienceUuid,
    };
}
