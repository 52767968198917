import { Action } from "modules/client/actions-types";
import { ApplicationState, SessionStateContainer } from "modules/client/application-state";
import { SessionEvent } from "modules/shared/session-events";
import { SessionState } from "modules/shared/types";
import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

export type SessionDispatch = (payload: SessionEvent) => string;

export type SessionContextType = {
    sessionId: string;
    state: SessionStateContainer["state"];
    participantId: string | null;
    oldParticipantId: string | null;
    sessionStateSelector: (state: ApplicationState) => SessionState;
    sessionDispatcher: SessionDispatch;
};

export const SessionContext = React.createContext<SessionContextType | null>(null);

export const useSession = (): SessionContextType => {
    const ctx = useContext(SessionContext);
    if (!ctx) {
        throw new Error("useSession error - no session is ready!");
    }
    return ctx;
};

/** Get the dispatcher for the "current" session */
export const useSessionDispatch = (): SessionDispatch => {
    const { sessionDispatcher } = useSession();
    return sessionDispatcher;
};

/** Select data out of the effective state for this session */
export const useSessionSelector = <T, _>(selector: (state: SessionState) => T): T => {
    const { sessionStateSelector } = useSession();

    return useSelector((state: ApplicationState) => selector(sessionStateSelector(state)));
};

/** Get the participantId for this session */
export const useSessionParticipantId = (): string | null => {
    const { participantId } = useSession();
    return participantId;
};

export const useDebug = (): ((k: string, v?: string) => void) => {
    const dispatch = useDispatch<Dispatch<Action>>();

    return useCallback(
        (key: string, value?: string) => {
            dispatch({ type: "DEBUG", key, value });
        },
        [dispatch],
    );
};

export type Props = {
    selector: (state: ApplicationState) => SessionStateContainer;
    children?: React.ReactNode;
};

export const SessionRendererContext: React.FC<Props> = (props) => {
    const session = useSelector(props.selector);

    if (
        session &&
        (session.state === "READY" ||
            session.state === "RECONNECTING" ||
            session.state === "RECONNECTED_WAITING_FOR_REINCARNATION")
    ) {
        const dispatch = useDispatch<Dispatch<Action>>();
        const sessionDispatcher = React.useCallback(
            (payload: SessionEvent) => {
                dispatch({
                    type: "MESSAGE_SEND",
                    payload: {
                        type: "SESSION_MESSAGE",
                        payload: { event: payload, resolution: null },
                    },
                });

                return payload.uuid;
            },
            [dispatch],
        );

        const sessionStateSelector = React.useCallback(
            (state: ApplicationState) => {
                const container = props.selector(state);

                let currentState: SessionState | null = null;

                if (container.state === "READY") {
                    currentState = container.effectiveState;
                } else if (
                    container.state === "RECONNECTING" ||
                    container.state === "RECONNECTED_WAITING_FOR_REINCARNATION"
                ) {
                    currentState = container.serverSessionState;
                }

                return currentState!;
            },
            [props],
        );

        const ctx: SessionContextType = React.useMemo(
            () => ({
                sessionId: session.sessionId,
                state: session.state,
                participantId: session.state === "READY" ? session.participantId : null,
                oldParticipantId:
                    session.state === "RECONNECTING" || session.state === "RECONNECTED_WAITING_FOR_REINCARNATION"
                        ? session.oldParticipantId
                        : null,
                sessionStateSelector,
                sessionDispatcher,
            }),
            // oldParticipantId and participantId are not always defined and will change as
            // session.state changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [session.state, sessionStateSelector, sessionDispatcher, session.sessionId],
        );

        return <SessionContext.Provider value={ctx}>{props.children}</SessionContext.Provider>;
    } else {
        throw new Error("cannot render, session is not ready");
    }
};
