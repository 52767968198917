import { sortBy } from "lodash";
import { CardPositionState, MatchingInputValue, PositionedCard, UnpositionedCard } from "./types";

export function cardStateIsPositioned(card: CardPositionState): card is PositionedCard {
    return card.gridId !== MatchingInputValue;
}

export function cardStateIsUnpositioned(card: CardPositionState): card is UnpositionedCard {
    return !cardStateIsPositioned(card);
}

export function reorderUnpositionedCards(unpositionedCards: UnpositionedCard[], placedCardId?: string) {
    let sortedCards = sortBy(unpositionedCards, (c) => c.idx);

    const placedCard = unpositionedCards.find((c) => c.cardId === placedCardId);
    if (!!placedCard) {
        const cardsWithoutPlaced = unpositionedCards.filter((c) => c.cardId !== placedCardId);
        const sortedCardsWithoutPlaced = sortBy(cardsWithoutPlaced, (c) => c.idx);
        const idxToSplice = placedCard.idx;
        const firstHalf = sortedCardsWithoutPlaced.slice(0, idxToSplice);
        const secondHalf = sortedCardsWithoutPlaced.slice(idxToSplice);
        sortedCards = [...firstHalf, placedCard, ...secondHalf];
    }

    return sortedCards.map((c, idx) => ({ ...c, idx }));
}
