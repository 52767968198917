import { createReportFilterSelector, createReportStatusSelector } from "modules/client/selectors/session-report";
import { ReportStatus } from "modules/client/session-report-state";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SessionBasicInfoFilters from "./SessionBasicInfoFilterForm";
import MultipleChoiceBasicInfoFilters from "./MultipleChoiceBasicInfoFilterForm";
import MultipleChoiceBasicInfoAggregatedFilters from "./MultipleChoiceBasicInfoAggregatedFilterForm";
import ActivityBasicInfoFilters from "./ActivityBasicInfoFilterForm";
import ActivityBasicInfoAggregatedFilters from "./ActivityBasicInfoAggregatedFilterForm";
import DragAndDropBasicInfoFilters from "./DragAndDropBasicInfoFilterForm";
import EditableCardBasicInfoFilters from "./EditableCardBasicInfoFilterForm";
import DragAndDropBasicInfoAggregatedFilters from "./DragAndDropBasicInfoAggregatedFilterForm";
import ReportSwitch from "./ReportSwitch";
import FullPageLoading from "modules/components/FullPageLoading";
import AdminContainer from "modules/components/AdminContainer";
import ReportResults from "./ReportResults";
import HeaderInfo from "./HeaderInfo";
import { useParams } from "react-router-dom";
import "./index.scss";

const SessionReports: React.FC = () => {
    const reportStatus = useSelector(createReportStatusSelector());
    const { uuid } = useParams();

    const { type: reportType } = useSelector(createReportFilterSelector());

    const filterComponent: React.ReactNode | null = useMemo(() => {
        switch (reportType) {
            case "SessionBasicInfo":
                return <SessionBasicInfoFilters experienceUuid={uuid ?? ""} />;
            case "MultipleChoiceBasicInfo":
                return <MultipleChoiceBasicInfoFilters experienceUuid={uuid ?? ""} />;
            case "MultipleChoiceBasicInfoAggregated":
                return <MultipleChoiceBasicInfoAggregatedFilters experienceUuid={uuid ?? ""} />;
            case "ActivityBasicInfo":
                return <ActivityBasicInfoFilters experienceUuid={uuid ?? ""} />;
            case "ActivityBasicInfoAggregated":
                return <ActivityBasicInfoAggregatedFilters experienceUuid={uuid ?? ""} />;
            case "DragAndDropBasicInfo":
                return <DragAndDropBasicInfoFilters experienceUuid={uuid ?? ""} />;
            case "DragAndDropBasicInfoAggregated":
                return <DragAndDropBasicInfoAggregatedFilters experienceUuid={uuid ?? ""} />;
            case "EditableCardBasicInfo":
                return <EditableCardBasicInfoFilters experienceUuid={uuid ?? ""} />;
            default:
                return null;
        }
    }, [reportType, uuid]);

    return (
        <AdminContainer>
            <HeaderInfo experienceUuid={uuid ?? ""} />
            <ReportSwitch />
            <div className="filter-component-container"></div>
            {!!filterComponent && filterComponent}
            {reportStatus === ReportStatus.InProgress ? <FullPageLoading /> : <ReportResults />}
        </AdminContainer>
    );
};
export default SessionReports;
