import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { scriptSections } from "./scriptSections";
import { currentSectionAddress } from "../../step-address/currentSectionAddress";

export function createCurrentSectionTitleSelector(): (s: SessionState) => string {
    return createSelector([currentSectionAddress, scriptSections], (currentSection, sectionDefs) => {
        if (currentSection === "exit") {
            return "";
        }
        if (currentSection === "intro") {
            return "intro";
        }

        const currentSectionDef = sectionDefs[currentSection];
        const title = currentSectionDef.title;
        return title.trim() !== "" ? title.trim() : `Section ${currentSection + 1}`;
    });
}
