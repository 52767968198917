import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useLocalizedString } from "modules/client/localization";
import "./small-screen-warning.scss";

const SmallScreenWarning: React.FC = () => {
    const localized = useLocalizedString();

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (!isSmallScreen) {
        return null;
    }

    return (
        <Modal className="small-screen-warning-modal" show={show}>
            <Modal.Header>
                <Modal.Title>{localized("vdpNameEntry_smallScreenWarningTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="small-screen-modal-body">
                    <Col>{localized("vdpNameEntry_smallScreenWarningBody")}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {localized("vdpNameEntry_smallScreenWarningDismiss")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SmallScreenWarning;
