import classNames from "classnames";
import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import "./ActivityModal.scss";

type Props = {
    modalBackgroundDimmer?: "none" | "light" | "medium" | "heavy";
    modalBackgroundBlur?: "none" | "light" | "medium" | "heavy";
};

const ActivityModal: React.FC<Props & ModalProps> = ({
    modalBackgroundDimmer,
    modalBackgroundBlur,
    children,
    ...modalProps
}) => {
    // props = custom type AND all props that can be accepted by react-bootstrap component
    let dimmer: string;
    let blur: string;

    switch (modalBackgroundDimmer) {
        case "none":
            dimmer = "dimmer-modal--none";
            break;
        case "medium":
            dimmer = "dimmer-modal--medium";
            break;
        case "heavy":
            dimmer = "dimmer-modal--heavy";
            break;
        case "light":
        default:
            dimmer = "dimmer-modal--light";
            break;
    }

    switch (modalBackgroundBlur) {
        case "none":
            blur = "blur-modal--none";
            break;
        case "medium":
            blur = "blur-modal--medium";
            break;
        case "heavy":
            blur = "blur-modal--heavy";
            break;
        case "light":
        default:
            blur = "blur-modal--none";
            break;
    }

    const backdropClassName = classNames(dimmer, blur, "activity-modal-backdrop", modalProps.backdropClassName);
    const finalProps: ModalProps = {
        ...modalProps,
        backdropClassName,
    };

    return (
        <Modal className="sidebar-left-margin" {...finalProps}>
            {children}
        </Modal>
    );
};

export default ActivityModal;
