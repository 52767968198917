import { WebsocketMessage, WebsocketMessageWithResolution } from "modules/shared/message-types";
import { SessionState } from "modules/shared/types";
import { SessionReportState, ReportStatus } from "./session-report-state";

/**
 * A holder for all client-side state related to a single session */
export type SessionStateContainer = { sessionId: string } & (
    | { state: "INITIAL" } // Initial state

    // Error states:
    | { state: "DISCONNECTED" }
    | { state: "ERROR"; error: unknown }

    // Connecting states:
    | SessionStateContainerConnecting
    | SessionStateContainerWaitingForInitialState
    // Connected:
    | SessionStateContainerReady

    // Reconnecting:
    | SessionStateContainerReconnecting
    | SessionStateContainerReconnectedWaitingForReincarnation
);

export type SessionStateContainerWaitingForInitialState = {
    state: "WAITING_FOR_INITIAL_STATE";

    /** Socket ID - shared w/ server */
    participantId: string;

    /** Messages received before we're ready to process them */
    pendingMessages: (WebsocketMessage | WebsocketMessageWithResolution)[];
};

export type SessionStateContainerConnecting = {
    state: "CONNECTING";
    sessionId: string;

    /** Messages received before we're ready to process them */
    pendingMessages: (WebsocketMessage | WebsocketMessageWithResolution)[];
};

export type ParticipantMessage = {
    id: string;
    message: string;
    success: boolean;
    eventTime?: string;
};

export type ContentfulComment = {
    enteredAt: Date;
    body: string;
    commentId: string;
    version: number;
    ownerId: string;
};

export type ContentfulManagementData = {
    comments?: ContentfulComment[];
};

export type SessionStateContainerReady = {
    state: "READY";
    sessionId: string;

    /** Socket ID - shared w/ server */
    participantId: string;

    /** The state as provided directly from the server */
    serverSessionState: SessionState;

    uncommitted: WebsocketMessage[];

    /** The state as produced by the server state and uncommitted actions */
    effectiveState: SessionState;

    /** Way to publish out some debug values */
    debug?: {
        [key: string]: string;
    };

    /** Current visible bounds of the map */
    mapBounds?: L.LatLngBounds | null;

    lastActiveTime: Date;

    isIdle: boolean;

    /** Messages that have been received for the participant */
    participantMessages: ParticipantMessage[];

    /** Contain data received from contentful management api */
    managementData?: ContentfulManagementData;

};

export type SessionStateContainerReconnectedWaitingForReincarnation = Omit<
    SessionStateContainerReconnecting,
    "state"
> & {
    state: "RECONNECTED_WAITING_FOR_REINCARNATION";

    reincarnationState: "waitingForInitialState" | "waitingForParticipant" | "readyToRequest" | "requestSent";

    /** The participant ID that will be assigned upon a successful reconnect */
    newParticipantId: string;
};

export type SessionStateContainerReconnecting = {
    state: "RECONNECTING";
    sessionId: string;

    /** The state as provided directly from the server */
    serverSessionState: SessionState;

    /** The participant ID associated with the previous connection */
    oldParticipantId: string;

    /** Way to publish out some debug values */
    debug?: {
        [key: string]: string;
    };

    /** Current visible bounds of the map */
    mapBounds?: L.LatLngBounds | null;

    // TODO: consider adding participantMessages here and receive messages during reconnection
};

export type ApplicationState = {
    /** The active session **/
    session: SessionStateContainer;

    /**
     * Messages that the session is waiting on. This is for the
     * kinds of messages where we really need to wait for the server. */
    eventsAwaitingResolutions: string[];

    //** Store globally select language. */
    languageCode: string;

    sessionReportState: SessionReportState;
};

export function createDefaultApplicationState(): ApplicationState {
    return {
        session: { sessionId: "INITIAL", state: "INITIAL" },
        eventsAwaitingResolutions: [],
        languageCode: "en-US",
        sessionReportState: {
            status: ReportStatus.Init,
            filters: {
                type: null,
                options: null,
            },
        },
    };
}
