import { compact } from "lodash-es";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { createCurrentStepDefinitionSelector } from "../step-definition/generic/createCurrentStepDefinitionSelector";
import { EntryFields } from "contentful";

export function createMapHotspotsSelector(): (s: SessionState) =>
    | {
          mapCoordinates: [number, number];
          title: string;
          content?: EntryFields.RichText;
          hideBackground?: boolean;
      }[]
    | null {
    return createSelector([createCurrentStepDefinitionSelector("infoStep")], (stepDef) => {
        if (stepDef?.mapHotspots) {
            return compact(
                stepDef?.mapHotspots.map((h) => {
                    if (h.mapCoordinates.length == 2) {
                        const location = h.mapCoordinates.map((c) => parseInt(c));
                        return {
                            ...h,
                            mapCoordinates: [location[0], location[1]],
                        };
                    }
                }),
            );
        }
        return null;
    });
}
