import { Action } from "modules/client/actions-types";
import { ApplicationState, SessionStateContainer } from "modules/client/application-state";
import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";

export type Props = {
    selector: (state: ApplicationState) => SessionStateContainer;
};
const Heartbeat: React.FC<Props> = (props) => {
    const dispatch = useDispatch<Dispatch<Action>>();

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch({
                type: "MESSAGE_SEND",
                payload: {
                    type: "HEARTBEAT",
                },
            });
        }, CLIENT_HEARTBEAT_SEND_INTERVAL as unknown as number);

        return () => clearInterval(interval);
    }, [dispatch]);

    return <></>;
};

export default Heartbeat;
