import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";
import { StepExtraOptions } from "modules/shared/content-types";

type Args = {
    options?: StepExtraOptions;
};
const createStartExperience = (args: Args): SessionEvent => {
    return {
        type: "startExperience",
        options: args.options,
        uuid: uuidv4(),
    };
};

export default createStartExperience;
