import React, { useEffect, useState } from "react";
import L, { LayerGroup } from "leaflet";
import { ClickableArea } from "modules/shared/types";
import classNames from "classnames";
import { useSessionParticipantId, useSessionSelector } from "../SessionRenderer/context";
import { createNavigationDisabledSelector } from "modules/shared/selectors/navigation-state/createNavigationDisabledSelector";
import { buildEllipseFromBounds, getClickableBoundsFromClickableArea } from "./utils";
import { useMapContext } from "./MapContext";

type Props = {
    pixelSize: [number, number];
    mapClickableAreas: ClickableArea[] | null;
    viewedClickableAreaIdxs: number[];
    showClickableAreaModal: (idx: number) => void;
};

const ClickableAreaLayer: React.FC<Props> = ({
    pixelSize,
    mapClickableAreas,
    viewedClickableAreaIdxs,
    showClickableAreaModal,
}) => {
    const { map } = useMapContext();
    const [clickableAreaGroup] = useState<LayerGroup>(L.layerGroup());
    const [clickableAreaViewedMarkerGroup] = useState<LayerGroup>(L.layerGroup());
    const isNavigationDisabled = useSessionSelector(createNavigationDisabledSelector(useSessionParticipantId()));
    useEffect(() => {
        map.addLayer(clickableAreaGroup);
        map.addLayer(clickableAreaViewedMarkerGroup);
        clickableAreaViewedMarkerGroup.clearLayers();

        mapClickableAreas?.map((ca, idx) => {
            const wasViewed = viewedClickableAreaIdxs.includes(idx);
            const clickableAreaClassnames = classNames("clickable-area", {
                "non-interactive": isNavigationDisabled,
            });

            const clickableAreaBounds = getClickableBoundsFromClickableArea(ca, pixelSize);
            const polygon = L.polygon(buildEllipseFromBounds(clickableAreaBounds, 6), {
                className: clickableAreaClassnames,
                interactive: true,
            }).addTo(clickableAreaGroup);

            if (wasViewed) {
                const coordsForIcon = clickableAreaBounds.getCenter();
                const viewedIconHTML = `<div class="clickable-area-selected-icon">
                    <i class="material-icons marker" style="font-size: x-large" >check</i>
                </div>`;
                const viewedIconMarker = L.divIcon({
                    html: viewedIconHTML,
                    iconAnchor: [16, 16],
                    className: "clickable-area-selected-container",
                });
                L.marker(coordsForIcon, {
                    icon: viewedIconMarker,
                    draggable: false,
                    interactive: false,
                }).addTo(clickableAreaViewedMarkerGroup);
            }

            if (!isNavigationDisabled) {
                polygon.on("click", () => {
                    showClickableAreaModal(idx);
                });
            }
        });
        return () => {
            clickableAreaGroup.clearLayers();
        };
    }, [
        map,
        clickableAreaGroup,
        clickableAreaViewedMarkerGroup,
        isNavigationDisabled,
        mapClickableAreas,
        pixelSize,
        showClickableAreaModal,
        viewedClickableAreaIdxs,
    ]);

    return <></>;
};
export default ClickableAreaLayer;
