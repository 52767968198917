import { LocaleOption, useClientLocale } from "modules/client/localization";
import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

type Props = {
    languages: LocaleOption[];
    title: string;
    className?: string;
    onSelect?: (languageName: string, languageCode: string) => void;
};

const LanguageSelection: React.FC<Props> = (props) => {
    const locale = useClientLocale();
    const defaultTitle = props.languages.find(({ value }) => value === locale)?.name || "Language";
    const [languageTitle, setLanguageTitle] = useState(defaultTitle);

    return (
        <DropdownButton id="dropdown-basic-button" className={props.className} title={languageTitle}>
            {props.languages.map(({ name, value }) => (
                <Dropdown.Item
                    eventKey={name}
                    key={name}
                    onClick={() => {
                        setLanguageTitle(name);
                        props.onSelect && props?.onSelect(name, value);
                    }}
                >
                    {name}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};

export default LanguageSelection;
