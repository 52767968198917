import { ReceiveSessionReportError } from "modules/client/actions-types/sessionReportActionTypes";
import { FieldErrors } from "modules/client/session-report-state";

export default function createReceiveSessionReportError(
    fieldErrors: FieldErrors,
    message?: string,
): ReceiveSessionReportError {
    return {
        type: "RECEIVE_SESSION_REPORT_ERROR",
        errors: {
            message: message ?? "Something went wrong when generating the report.",
            fieldErrors,
        },
    };
}
