import { WebsocketMessage, WebsocketMessageWithResolution } from "modules/shared/message-types";
import React from "react";
import client, { WsHandle } from "../ws/client";

type ConnectionInfo =
    | {
          state: "connecting" | "error";
          socket: null;
      }
    | {
          state: "connected";
          socket: WsHandle;
      };

export interface IClientSocketManager {
    connect(opts: { sessionId: string }): Promise<string>;
    disconnect(): void;
    getDispatcherFor(): ((e: WebsocketMessage) => void) | null;
}

export class ClientSocketManager implements IClientSocketManager {
    private connection: ConnectionInfo;
    receiver: ((sessionId: string, e: WebsocketMessageWithResolution) => void) | null = null;

    errorHandler: ((sessionId: string, e: unknown) => void) | null = null;
    reconnectHandler: ((sessionId: string, c: { previousSocketId: string; newSocketId: string }) => void) | null = null;

    constructor() {
        this.connection = { state: "connecting", socket: null };
    }

    connect = async (opts: { sessionId: string }): Promise<string> => {
        try {
            this.connection = {
                state: "connecting",
                socket: null,
            };

            const connection = await client.connect({
                sessionKey: opts.sessionId,
                receiver: {
                    onReceive: (e) => {
                        if (!this.receiver) {
                            throw new Error("no receiver set on socket manager");
                        }
                        this.receiver(opts.sessionId, e);
                    },
                    onError: (e) => {
                        if (!this.errorHandler) {
                            throw new Error("no error handler set on socket manager");
                        }
                        this.errorHandler(opts.sessionId, e);
                    },
                    onReconnect: (newSocket) => {
                        if (!this.reconnectHandler) {
                            throw new Error("no error handler set on socket manager");
                        }
                        const prevConnection = this.connection;
                        if (!prevConnection.socket) {
                            return;
                        }

                        this.connection = {
                            state: "connected",
                            socket: newSocket,
                        };
                        this.reconnectHandler(opts.sessionId, {
                            previousSocketId: prevConnection.socket.id,
                            newSocketId: newSocket.id,
                        });
                    },
                },
            });

            this.connection = {
                state: "connected",
                socket: connection,
            };

            return connection.id;
        } catch (e) {
            this.connection = {
                state: "error",
                socket: null,
            };
            throw e;
        }
    };

    disconnect = () => this.connection.socket?.socket.disconnect();

    getDispatcherFor = (): ((e: WebsocketMessage) => void) | null => {
        const conn = this.connection;
        if (conn.state === "connected") {
            return conn.socket.dispatch;
        }
        return null;
    };
}

const WebSocketContext = React.createContext<IClientSocketManager | null>(null);
