import { debounce } from "lodash";
import { useSessionDispatch, useSessionParticipantId } from "modules/components/SessionRenderer/context";
import { default as React, useEffect, useMemo, useRef } from "react";
import createMouseMove from "../../client/event-factories/createMouseMove";

export function isChildOf(parent: Element, child: Element): boolean {
    const ancestors = getAncestorsRelativeTo({ targetElement: child, targetAncestor: parent });
    return !!ancestors;
}

function getAncestorsRelativeTo(opts: {
    targetElement: Element;
    targetAncestor?: Element;
    path?: Element[];
}): Element[] | null {
    const parent = opts.targetElement.parentElement;

    if (!opts.path) {
        return getAncestorsRelativeTo({ ...opts, path: [opts.targetElement] });
    }

    // If there are no more parents to recurse through, then that means our
    // targetAncestor was not actually an ancestor of the targetElement and
    // there is no path connecting them.
    if (!parent) {
        return null;
    }

    if (parent == opts.targetAncestor) {
        return opts.path;
    }

    return getAncestorsRelativeTo({
        targetElement: parent,
        targetAncestor: opts.targetAncestor,
        path: [parent, ...opts.path],
    });
}

/** Convert a path through the tree into a list of indexes */
function elementPathToIndexes(rootNode: Element, path: Element[]): number[] {
    if (path.length == 0) {
        return [];
    }

    const idx = Array.prototype.indexOf.call(rootNode.children, path[0]);
    return [idx, ...elementPathToIndexes(path[0], path.slice(1))];
}

export function nodeFromIndexPath(rootNode: HTMLElement, path: number[]): HTMLElement | null {
    if (path.length === 0) {
        return rootNode;
    }

    const node = rootNode.children[path[0]];
    if (!node) {
        return null;
    }

    const nextPath = path.slice(1);
    return nodeFromIndexPath(node as HTMLElement, nextPath);
}

/**
 * A mouse position relative to some pre-specified root node. It includes the
 * path through children (by index) to a leaf node, and a percentage offset
 * within that leaf element */
export type PositionInElementHierarchy = {
    type: "relativeToDOM";
    path: number[];
    coords: [number, number];
};

export type PositionInMap = {
    type: "relativeToMap";
    latlng: [number, number];
};

type Props = {
    /** An element to watch for movement within */
    el: React.RefObject<HTMLDivElement>;
};

const DOMMouseTracker: React.FC<Props> = (props) => {
    const dispatch = useSessionDispatch();
    const participantId = useSessionParticipantId();

    const containerRef = props.el;

    const indicatorRef = useRef<HTMLDivElement>(null);
    const onMouseMove = useMemo(
        () =>
            debounce((pos: PositionInElementHierarchy) => {
                // probably could be actually fixed, but just filtering out the
                // noise still looks pretty good.
                const mag2 = pos.coords[0] * pos.coords[0] + pos.coords[1] * pos.coords[1];
                if (
                    // I've found sometimes we just get the zeros and things
                    // jump, so here I'll make some small dead space around
                    // (0,0) to keep things smooth.
                    mag2 < 0.025 ||
                    // And sometimes I see really big proportions (> 100% of the
                    // width). Whatever, dump it.
                    mag2 > 2
                ) {
                    return;
                }

                if (participantId) {
                    dispatch(
                        createMouseMove({
                            participantId,
                            position: pos,
                        }),
                    );
                }
            }, 250),
        [dispatch, participantId],
    );

    useEffect(() => {
        const el = containerRef.current;
        if (el) {
            const handler = (e: MouseEvent) => {
                const target = document.elementFromPoint(e.pageX, e.pageY) as HTMLElement;
                if (!target || !containerRef.current) {
                    return;
                }

                const nodePath = getAncestorsRelativeTo({
                    targetElement: target,
                    targetAncestor: containerRef.current,
                });

                if (nodePath === null) {
                    return;
                }

                const indexPath = elementPathToIndexes(containerRef.current, nodePath);

                const mouseAddress: PositionInElementHierarchy = {
                    type: "relativeToDOM",
                    path: indexPath,
                    coords: [e.offsetX / target.offsetWidth, e.offsetY / target.offsetHeight],
                };

                onMouseMove(mouseAddress);
            };

            el.addEventListener("mousemove", handler);

            return () => {
                el.removeEventListener("mousemove", handler);
            };
        }
    }, [props, onMouseMove, containerRef]);

    return <div ref={indicatorRef}></div>;
};
export default DOMMouseTracker;

/**
 * An invisible placeholder we can use to keep the DOM structure the same on all
 * clients. Mouse tracking over the DOM works by sharing the path, so if some
 * clients have a different tree, this won't work. We can add a placeholder in
 * places where elements are conditional to fix that problem.*/
export const Placeholder: React.FC<unknown> = (props) => (
    <div
        style={{
            width: 0,
            height: 0,
            overflow: "hidden",
            padding: 0,
            margin: 0,
        }}
    />
);
