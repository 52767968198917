import React from "react";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import PreviewMap from "./PreviewMap";
import NameEntryContainer from "./NameEntryContainer";

const NameEntrySequence: React.FC<{ mapLocation: string; mapMetadata: LeafletMapMetadata }> = (props) => {
    return (
        <>
            <PreviewMap mapLocation={props.mapLocation} mapMetadata={props.mapMetadata} />
            <NameEntryContainer />
        </>
    );
};

export default NameEntrySequence;
