import { updateSessionState } from "modules/utils";
import { ParticipantMessageReceived } from "../actions-types";
import { ApplicationState, ParticipantMessage, SessionStateContainerReady } from "../application-state";

function addParticipantMessage(
    state: SessionStateContainerReady,
    newMessage: ParticipantMessage,
): SessionStateContainerReady {
    return {
        ...state,
        participantMessages: [...state.participantMessages, newMessage],
    };
}

export default function (state: ApplicationState, event: ParticipantMessageReceived) {
    return updateSessionState(state, (session) => {
        if (session && session.state === "READY") {
            return addParticipantMessage(session, event.payload);
        }

        return session;
    });
}
