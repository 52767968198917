import { createSelector } from "@reduxjs/toolkit";
import { Asset, ExperienceScript } from "../../content-types";
import { SessionState } from "../../types";
import { scriptSelector } from "../step-definition/generic/scriptSelector";

export function createCustomFaviconSelector(): (s: SessionState) => Asset | null {
    return createSelector([scriptSelector], (script: ExperienceScript) => {
        return script.appearance?.favicon ?? null;
    });
}
