import { SessionState, StepAddress } from "../../types";

export function getStepAddressByStepTitle(state: SessionState, title: string): StepAddress | null {
    let stepAddress: StepAddress | undefined;
    state.script.sections.some((section, i) => {
        section.steps.some((step, j) => {
            if (step.title === title) {
                stepAddress = { sectionKey: i, stepKey: j };
                return true;
            }
        });
    });

    if (!stepAddress || stepAddress === "exit" || stepAddress.stepKey === "ProgressCheck") {
        return null;
    }

    return stepAddress;
}
