import classNames from "classnames";
import RichText from "modules/components/RichText";
import { useSessionParticipantId, useSessionSelector } from "modules/components/SessionRenderer/context";
import { Card } from "modules/shared/content-types";
import { createNavigationDisabledSelector } from "modules/shared/selectors/navigation-state/createNavigationDisabledSelector";
import React, { useEffect, useRef } from "react";
import BootstrapCard from "react-bootstrap/Card";
import "./CardStackWithVisual.scss";
import Container from "react-bootstrap/Container";
import { createCurrentStepStateSelector } from "modules/shared/selectors/step-state/createCurrentStepStateSelector";

type Props = {
    card: Card;
    position: { x: number; y: number };
};

const CardStackWithVisual: React.FC<Props> = (props) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const isNavigationDisabled = useSessionSelector(createNavigationDisabledSelector(useSessionParticipantId()));
    const stepState = useSessionSelector(createCurrentStepStateSelector("CARD_STACK_WITH_VISUAL"));
    const isCardSelected = stepState?.persistent.selectedCardId === props.card.id;

    const cardClassNames = classNames({
        "non-interactive": isNavigationDisabled,
        "hidden-background": props.card.hideBackground,
    });

    const richTextClassNames = classNames({
        "hidden-background": props.card.hideBackground,
    });

    useEffect(() => {
        const card = cardRef.current;
        if (card) {
            card.style.cssText = props.card.additionalCssProperties;
            if (!!isNavigationDisabled) {
                card.focus();
            }
        }
    }, [props.card]);

    return (
        <Container
            className="card-container"
            key={`card-${props.card.id}`}
            style={{ transform: `translate3d(${props.position.x}px, ${props.position.y}px, 0px)` }}
        >
            <BootstrapCard tabIndex={isCardSelected ? 0 : -1} ref={cardRef} className={cardClassNames}>
                {!props.card.useContentAsTitle && (
                    <BootstrapCard.Header as={"h1"}>{props.card.title}</BootstrapCard.Header>
                )}
                <BootstrapCard.Body>
                    <RichText document={props.card.details} className={richTextClassNames} />
                </BootstrapCard.Body>
            </BootstrapCard>
        </Container>
    );
};

export default CardStackWithVisual;
