import { getUnixTime } from "date-fns";
import { getTotalActualTimeByStepState } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { currentStepAddress } from "../step-address/currentStepAddress";
import { getTimeStepStarted } from "./getTimeStepStarted";
import { getSessionStartedAt } from "./getSessionStartedAt";
import { createGetSectionStepStates } from "../step-state/createGetSectionStepStates";

export function createSecondsPassedForSectionSelector(): (s: SessionState, sectionKey: number | "intro") => number {
    return createSelector(
        [
            createGetSectionStepStates(),
            currentStepAddress,
            getTimeStepStarted,
            (_state, sectionKey) => sectionKey,
            getSessionStartedAt,
        ],
        (stepStates, currentStep, timeStarted, sectionKey, sessionStartedAt) => {
            const isIntroStep = isIntroStepAddress(currentStep);
            const currentTime = getUnixTime(new Date());
            let currentStepTime: number;

            if (!sessionStartedAt) {
                currentStepTime = 0;
            } else if (isIntroStep && sectionKey === "intro") {
                currentStepTime = currentTime - timeStarted;
            } else if (
                currentStep !== "exit" &&
                !isIntroStep &&
                currentStep.sectionKey === sectionKey &&
                currentStep.stepKey !== "ProgressCheck"
            ) {
                currentStepTime = currentTime - timeStarted;
            } else {
                currentStepTime = 0;
            }
            return getTotalActualTimeByStepState(stepStates) + currentStepTime;
        },
    );
}
