import { StepStateBundle } from "modules/shared/step-types";

export type MatchingPersistentStepState = {
    cardPositionState: CardPositionState[];
    cardCorrectnessRevealed?: boolean;
    cardPositionStateOnReveal?: CardPositionState[];
};

export type CardPositionState = PositionedCard | UnpositionedCard;

export const MatchingInputValue = "input";

export type PositionedCard = {
    cardId: string;
    gridId: string;
    heldByParticipant: string | null;
    version: number;
};

export type UnpositionedCard = {
    cardId: string;
    gridId: typeof MatchingInputValue;
    heldByParticipant: string | null;
    version: number;
    idx: number;
};

export type MatchingStepState = StepStateBundle<"MATCHING", MatchingPersistentStepState, unknown>;
