import React from "react";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import PreviewMap from "../NameEntryScreen/PreviewMap";
import { useLocalizedString } from "modules/client/localization";
import { Col, Row } from "react-bootstrap";
import { getIntroSectionTitles } from "modules/client/utils";
import "./intro-tutorial.scss";
import { useSessionSelector } from "../SessionRenderer/context";
import { currentStepNumber } from "modules/shared/selectors/step-address/currentStepNumber";
import classNames from "classnames";
import { MdCheckCircle, MdCheckCircleOutline } from "react-icons/md";

const IntroSequence: React.FC<{ mapLocation: string; mapMetadata: LeafletMapMetadata }> = (props) => {
    const localized = useLocalizedString();
    const introSectionTitles = getIntroSectionTitles(localized);
    const currentPage = useSessionSelector(currentStepNumber);

    return (
        <>
            <PreviewMap mapLocation={props.mapLocation} mapMetadata={props.mapMetadata} />
            <div className="intro-container">
                <h2>{localized("vdpIntro_title")}</h2>
                <p>{localized("vdpIntro_subtitle")}</p>
                <Col className="section-table">
                    {introSectionTitles.map((title, index) => {
                        const introSectionClassNames = classNames("gx-0", {
                            active: currentPage === index,
                            disabled: index > currentPage,
                            default: index < currentPage,
                        });
                        return (
                            <Row className={introSectionClassNames} key={title}>
                                <Col xs={9} className="section-label">
                                    {title}
                                </Col>
                                <Col className="check-icon">
                                    {index > currentPage ? (
                                        <MdCheckCircleOutline size={20} />
                                    ) : (
                                        <MdCheckCircle size={20} />
                                    )}
                                </Col>
                            </Row>
                        );
                    })}
                </Col>
            </div>
        </>
    );
};

export default IntroSequence;
