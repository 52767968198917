import { format } from "date-fns";
import {
    createReportErrorSelector,
    createReportResultsSelector,
    createReportStatusSelector,
} from "modules/client/selectors/session-report";
import { ReportStatus } from "modules/client/session-report-state";
import React, { ReactNode } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./ReportResults.scss";
import { useLocalizedString } from "modules/client/localization";

const ReportResults: React.FC = () => {
    const reportStatus = useSelector(createReportStatusSelector());
    const reportResults = useSelector(createReportResultsSelector());
    const reportError = useSelector(createReportErrorSelector());
    const sessionCount = (reportResults as any)?.sessionCount ?? null;
    const localized = useLocalizedString();
    let columnHeaders: string[];
    let rows: ReactNode;
    let rowData: Array<Array<string | number | Date | boolean>>;
    let fileName: string;
    let reportTitle: string;
    const currentDateString = format(new Date(), "yyyy-MM-dd");

    if (!!reportResults?.data) {
        columnHeaders = reportResults.data.length > 0 ? Object.keys(reportResults.data[0]) : [];
        rowData = reportResults.data.map((infoRow) =>
            Object.entries(infoRow).map(([_fieldName, fieldValue]) => fieldValue),
        );
        rows = (
            <tbody>
                {reportResults.data.map((infoRow, ind) => (
                    <tr key={ind}>
                        {Object.entries(infoRow).map(([fieldName, fieldValue]) => (
                            <td key={fieldName + ind}>{fieldValue}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    } else {
        columnHeaders = [];
        rowData = [];
        rows = [];
    }

    switch (reportResults?.type) {
        case "SessionBasicInfo":
            reportTitle = localized("sessionReport_switch_basicInfo");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "ActivityBasicInfo":
            reportTitle = localized("activityReport_switch_basicInfo");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "ActivityBasicInfoAggregated":
            reportTitle = localized("activityReport_switch_basicInfoAggregated");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "MultipleChoiceBasicInfo":
            reportTitle = localized("multipleChoiceReport_switch_basicInfo");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "MultipleChoiceBasicInfoAggregated":
            reportTitle = localized("multipleChoiceReport_switch_basicInfoAggregated");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "DragAndDropBasicInfo":
            reportTitle = localized("dragAndDropReport_switch_basicInfo");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "DragAndDropBasicInfoAggregated":
            reportTitle = localized("dragAndDropReport_switch_basicInfoAggregated");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        case "EditableCardBasicInfo":
            reportTitle = localized("editableCardReport_switch_basicInfo");
            fileName = reportResults.type + "--" + currentDateString + ".csv";
            break;
        default:
            fileName = "unspecified.csv";
            reportTitle = localized("sessionReport_switch_unknown");
            break;
    }

    const csvContent =
        "data:text/csv;charset=utf-8," +
        columnHeaders.join(",") +
        "\n" +
        rowData.map((row) => row.map((e) => `"${String(e).replace(/"/g, '""')}"`).join(",")).join("\n");

    const csvEncodeUri = encodeURI(csvContent);

    return (
        <div className="session-repo-results-container">
            {reportStatus === ReportStatus.Complete && rowData.length > 0 && (
                <>
                    <Row className="justify-content-md-end">
                        <Col>
                            <h3>{reportTitle}</h3>
                            {sessionCount && <h5> Session Count: {sessionCount} </h5>}
                        </Col>
                        <Col className="col-md-auto">
                            <Button as="a" href={csvEncodeUri} download={fileName} variant="success">
                                {localized("sessionReport_results_downloadResults")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Table responsive striped bordered size="sm" className="results-table">
                            <thead>
                                <tr>
                                    {columnHeaders.map((header, ind) =>
                                        header === "external_id" ? (
                                            <th key={ind}>Activity ID</th>
                                        ) : (
                                            <th key={ind}>{header}</th>
                                        ),
                                    )}
                                </tr>
                            </thead>
                            {rows}
                        </Table>
                    </Row>
                </>
            )}
            {reportStatus === ReportStatus.Complete && rowData.length === 0 && (
                <Row className="justify-content-md-center">
                    <Col className="col-md-auto">
                        <Alert variant="warning">
                            <Alert.Heading>{localized("sessionReport_results_noRecordsTitle")}</Alert.Heading>
                            {localized("sessionReport_results_noRecordsSubTitle")}
                        </Alert>
                    </Col>
                </Row>
            )}
            {reportStatus === ReportStatus.Error && !!reportError && (
                <Row className="justify-content-md-center">
                    <Col className="col-md-auto">
                        <Alert variant="danger">
                            <Alert.Heading>{localized("sessionReport_results_errorTitle")}</Alert.Heading>
                            {reportError.message}
                        </Alert>
                    </Col>
                </Row>
            )}
        </div>
    );
};
export default ReportResults;
