import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    clickableAreaIdx: number;
    clickableAreaUuid: string;
};

const createClickableAreaShowDetails = (args: Args): SessionEvent => {
    return {
        type: "CLICKABLE_AREA__SHOW_DETAILS",
        clickableAreaIdx: args.clickableAreaIdx,
        clickableAreaUuid: args.clickableAreaUuid,
        uuid: uuidv4(),
    };
};
export default createClickableAreaShowDetails;
