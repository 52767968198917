import { createExitStepDefinition, createIntroStepDefinition, createProgressCheckStepDefinition } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { lruMemoize } from "reselect";
import { ExperienceScript, StepDefinition } from "../../../content-types";
import { SessionState, StepAddress, StepIntroAddress } from "../../../types";
import { isIntroStepAddress } from "../../../utils";
import { currentStepAddress } from "../../step-address/currentStepAddress";
import { scriptSelector } from "./scriptSelector";

export function createGenericStepDefinitionSelector(): (s: SessionState) => StepDefinition {
    return createSelector(
        [
            lruMemoize((x: SessionState) => {
                return currentStepAddress(x);
            }),
            lruMemoize((x: SessionState) => {
                return scriptSelector(x);
            }),
        ],
        (address: StepAddress | StepIntroAddress, script: ExperienceScript): StepDefinition => {
            if (isIntroStepAddress(address)) {
                return createIntroStepDefinition();
            }
            if (address === "exit") {
                return createExitStepDefinition(script) as StepDefinition;
            } else if (address.stepKey === "ProgressCheck") {
                return createProgressCheckStepDefinition(script, address.sectionKey) as StepDefinition;
            }
            return script.sections[address.sectionKey].steps[address.stepKey];
        },
    );
}
