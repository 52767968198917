import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import CopyToClipboardButton from "modules/components/CopyToClipboard";
import Experience from "modules/database_types/experience";
import React, { useCallback, useRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ParticipantRoute } from "../../modules/shared/routes";
import "./GenerateUrls.scss";
import { Session } from "./types";
import GenerateUrlRow from "./GenerateUrlRow";
import { useLocalizedString } from "modules/client/localization";

type Props = {
    experience: Experience;
    sessions: Session[];
    sessionLanguage: string;
};

const GeneratedUrlDisplay: React.FC<Props> = (props) => {
    const { experience, sessions } = props;
    const localized = useLocalizedString();

    const copyLinkText = localized("genUrl_copyLink");

    const csvFileName = `${experience.experience_name}_session_urls.csv`;
    const buildCsv = (sessions: Session[]) => {
        return sessions.map((session) => ({
            Link: ParticipantRoute.generate(session.uuid, props.sessionLanguage),
            "Link Opens At": format(parseISO(`${session.opens_at}+00:00`), "yyyy-MM-dd KK:mm aa"),
            "Link Expiration": format(parseISO(`${session.closes_at}+00:00`), "yyyy-MM-dd KK:mm aa"),
        }));
    };

    const refTable = useRef<HTMLDivElement | null>(null);

    const onCopyTable = useCallback(async () => {
        if (!refTable.current) {
            return;
        }

        let data = refTable.current.innerText;
        data = data.replace(new RegExp(copyLinkText, "g"), "");
        return navigator.clipboard.writeText(data);
    }, [refTable, copyLinkText]);

    return (
        <>
            <Row>
                <Col>
                    <h5>{localized("genUrl_sessionLinkDetails")}</h5>
                </Col>
            </Row>

            <Row className="url-action-btn-group">
                <Col>
                    <CSVLink data={buildCsv(sessions)} filename={csvFileName} className="btn btn-primary export-btn">
                        {localized("genUrl_exportAsCsv")}
                    </CSVLink>

                    <CopyToClipboardButton onClick={onCopyTable}>{localized("genUrl_copyTable")}</CopyToClipboardButton>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            <div className="urlTable" ref={refTable}>
                <Table striped borderless>
                    <thead>
                        <tr>
                            <th>{localized("genUrl_linkExpiration")}</th>
                            <th>{localized("genUrl_link")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.map((session) => (
                            <GenerateUrlRow
                                key={session.uuid}
                                session={session}
                                languageCode={props.sessionLanguage}
                                buttonLabel={copyLinkText}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default GeneratedUrlDisplay;
