import { participantHasRole } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { Role } from "../../content-types";
import { SessionState } from "../../types";
import { createParticipantSelector } from "../participants/createParticipantSelector";
import { navigationRoleFilled } from "../participants/navigationRoleFilled";

/**
 * Checks if there is a navigator
 * and returns true if the given participant is not the navigator
 *
 * @param participantId Current Participant
 */

export function createNavigationDisabledSelector(participantId: string | null): (s: SessionState) => boolean {
    return createSelector(
        [createParticipantSelector(participantId), navigationRoleFilled],
        (participantInfo, navigationRoleFilled) =>
            navigationRoleFilled && !!participantInfo && !participantHasRole(participantInfo, Role.Navigator),
    );
}
