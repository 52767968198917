import { SessionState } from "../../types";

export function stepExists(state: SessionState): boolean {
    if (state.currentIntroStep && state.currentIntroStep.introStepKey && state.isIntroStep) {
        return true;
    }

    const currentStep = state.currentStep;
    if (currentStep === "exit") {
        return true;
    }

    const sectionIndex = currentStep.sectionKey;
    const stepIndex = currentStep.stepKey;
    const section = state.script.sections[sectionIndex];

    if (!section) {
        return false;
    } else if (stepIndex === "ProgressCheck") {
        return true;
    }

    const step = section.steps[stepIndex];

    return !!step;
}
