import { ORDERED_INTRO_KEY, SessionState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { currentStepAddress } from "./currentStepAddress";

export function currentStepNumber(state: SessionState): number | "exit" {
    const currentStep = currentStepAddress(state);
    if (currentStep === "exit") {
        return "exit";
    } else if (isIntroStepAddress(currentStep)) {
        return ORDERED_INTRO_KEY.findIndex((key) => key === currentStep.introStepKey);
    } else if (currentStep.stepKey === "ProgressCheck") {
        return 0;
    }
    return currentStep.stepKey + 1;
}
