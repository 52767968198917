import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "../application-state";
import {
    EmptyReportFilterOptions,
    ReportData,
    ReportErrorState,
    ReportStatus,
    SelectedFilterState,
    SessionReportState,
} from "../session-report-state";

function sessionReportState(state: ApplicationState): SessionReportState {
    return state.sessionReportState;
}

export function createReportStatusSelector(): (state: ApplicationState) => ReportStatus {
    return createSelector([sessionReportState], (reportState) => reportState.status);
}

export function createReportFilterSelector(): (
    state: ApplicationState,
) => SelectedFilterState | EmptyReportFilterOptions {
    return createSelector([sessionReportState], (reportState) => reportState.filters);
}

export function createReportResultsSelector(): (state: ApplicationState) => ReportData | null {
    return createSelector([sessionReportState], (reportState) => {
        if (reportState.status === ReportStatus.Complete) {
            return reportState.result;
        }
        return null;
    });
}

export function createReportErrorSelector(): (state: ApplicationState) => ReportErrorState["errors"] | null {
    return createSelector([sessionReportState], (reportState) => {
        if (reportState.status === ReportStatus.Error) {
            return reportState.errors;
        }
        return null;
    });
}
