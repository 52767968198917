import { useDateFnsLocale, useLocalizedString } from "modules/client/localization";
import { createStepDefinitionsForCurrentSectionSelector } from "modules/shared/selectors/step-definition/generic/createStepDefinitionsForCurrentSectionSelector";
import { createSecondsPassedForCurrentSectionSelector } from "modules/shared/selectors/time-tracking/createSecondsPassedForCurrentSectionSelector";
import { createSecondsRecommendedForCurrentSectionSelector } from "modules/shared/selectors/time-tracking/createSecondsRecommendedForCurrentSectionSelector";
import { createCurrentSectionTitleSelector } from "modules/shared/selectors/step-definition/generic/createCurrentSectionTitleSelector";
import { currentStepNumber } from "modules/shared/selectors/step-address/currentStepNumber";
import { inIntroSequence } from "modules/shared/selectors/intro-sequence/inIntroSequence";
import { ORDERED_INTRO_KEY } from "modules/shared/types";
import { formatTimeContentTop } from "modules/utils";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ProgressBadge from "../ProgressBadge";
import { useSessionSelector } from "../SessionRenderer/context";
import "./content-top.scss";

const ContentTop: React.FC = () => {
    const inIntro = useSessionSelector(inIntroSequence);
    const localized = useLocalizedString();
    // if in intro section this is still a number
    const currentStepNum = useSessionSelector(currentStepNumber);

    const stepsForSection = useSessionSelector(createStepDefinitionsForCurrentSectionSelector());
    const secondsRecommended = useSessionSelector(createSecondsRecommendedForCurrentSectionSelector());
    const secondsPassed = useSessionSelector(createSecondsPassedForCurrentSectionSelector());
    const secondsRemaining = secondsRecommended - secondsPassed;
    const sectionTitle = useSessionSelector(createCurrentSectionTitleSelector());
    const introTitle = localized("vdpIntro_title");
    const locale = useDateFnsLocale();
    const messageTimeRemaining = formatTimeContentTop(secondsRemaining, locale);

    return (
        <>
            {!inIntro && (
                <>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <ProgressBadge />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small>{localized("vdpHud_progressTimeRemaining", messageTimeRemaining)}</small>
                        </Col>
                    </Row>
                </>
            )}

            <Row>
                <Col>
                    <div className="section-title">{inIntro ? introTitle : sectionTitle}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="step">
                        {inIntro ? (
                            <>
                                {localized(
                                    "vdpHud_progressStepsInSection",
                                    currentStepNum,
                                    ORDERED_INTRO_KEY.length - 1,
                                )}
                            </>
                        ) : (
                            <>
                                {currentStepNum !== "exit"
                                    ? localized("vdpHud_progressStepsInSection", currentStepNum, stepsForSection.length)
                                    : ""}
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ContentTop;
