import { createExitStepDefinition, createProgressCheckStepDefinition } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { StepDefinition, Role } from "../../../content-types";
import { SessionState } from "../../../types";
import { isStepAddress } from "../../../utils";
import { stepAfterCurrentAddress } from "../../step-address/stepAfterCurrentAddress";
import { scriptSelector } from "./scriptSelector";

export function createNextStepDefinitionSelector(): (s: SessionState) => StepDefinition | null {
    return createSelector([scriptSelector, stepAfterCurrentAddress], (script, address) => {
        if (address === "exit") {
            return createExitStepDefinition(script) as StepDefinition;
        } else if (address && isStepAddress(address) && address.stepKey === "ProgressCheck") {
            return createProgressCheckStepDefinition(script, address.sectionKey) as StepDefinition;
        }

        if (script.sections && address && isStepAddress(address)) {
            const assignedNavAutomatically = script.changeNavigatorAutomatically;
            const nextStepDefinition = script.sections[address.sectionKey].steps[address.stepKey];

            if (assignedNavAutomatically && address.stepKey === 0) {
                const assignRoles = nextStepDefinition?.options?.assignRoles ?? [];
                assignRoles?.push(Role.Navigator);
                return {
                    ...nextStepDefinition,
                    options: {
                        ...nextStepDefinition.options,

                        assignRoles,
                    },
                } as StepDefinition;
            }
            return nextStepDefinition;
        }

        return null;
    });
}
