import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InPersonNavBar from "./InPersonNavBar";
import { ApplicationState } from "modules/client/application-state";
import { useSelector } from "react-redux";
import { SessionState } from "modules/shared/types";
import { Section } from "modules/shared/content-types";

type Props = Record<string, unknown>;

const LayoutContent: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    const state = useSelector(
        (state: ApplicationState): boolean | SessionState =>
            state.session.state === "READY" && state.session.effectiveState,
    );

    const experienceName = state && (state as SessionState).sessionMetadata.experience.experience_name;

    const sections = state && ((state as SessionState).script.sections as Section[]);
    return (
        <Container fluid className="d-flex flex-column min-vh-100 p-0 mx-auto" style={{ maxWidth: "480px" }}>
            <Row className="justify-content-center flex-grow-1">
                <Col>
                    <Row bg="primary" className="mb-3">
                        <InPersonNavBar experienceName={experienceName as string} state={state} sections={sections} />
                    </Row>

                    <Row className="p-3 flex-grow-1">
                        <main>{children}</main>
                    </Row>
                </Col>
            </Row>

            <Row className="text-center p-3" style={{ backgroundColor: "#fff" }}>
                <footer>Copyright @ Accenture {new Date().getFullYear()}</footer>
            </Row>
        </Container>
    );
};

const InPersonLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
    return <LayoutContent>{children}</LayoutContent>;
};

export default InPersonLayout;
