import { createSelector } from "@reduxjs/toolkit";
import { Appearance } from "../../../content-types";
import { SessionState } from "../../../types";
import { createCurrentStepOptionsSelector } from "./createCurrentStepOptionsSelector";
import { createScriptAppearanceSelector } from "../../experience-definition/createScriptAppearanceSelector";

export function createCurrentAppearanceOptionsSelector(): (s: SessionState) => Appearance {
    return createSelector(
        [createScriptAppearanceSelector(), createCurrentStepOptionsSelector()],
        (appearance, stepOptions) => {
            const currentValues: Appearance = {
                id: "fake_id",
                contentType: "appearance",
                version: 0,
                ...appearance,
            };

            if (stepOptions?.accentColor && stepOptions.accentColor.length > 0) {
                currentValues.accentColor = stepOptions.accentColor;
            }

            if (stepOptions?.backgroundColor && stepOptions.backgroundColor.length > 0) {
                currentValues.backgroundColor = stepOptions.backgroundColor;
            }

            if (stepOptions?.overlayAdditionalProps && stepOptions.overlayAdditionalProps.length > 0) {
                currentValues.overlayAdditionalProps = stepOptions.overlayAdditionalProps;
            }

            if (stepOptions?.overlayBackgroundColor && stepOptions.overlayBackgroundColor.length > 0) {
                currentValues.overlayBackgroundColor = stepOptions.overlayBackgroundColor;
            }

            if (stepOptions?.modalBackgroundBlur && stepOptions.modalBackgroundBlur.length > 0) {
                currentValues.modalBackgroundBlur = stepOptions.modalBackgroundBlur;
            }

            if (stepOptions?.modalBackgroundDimmer && stepOptions.modalBackgroundDimmer.length > 0) {
                currentValues.modalBackgroundDimmer = stepOptions.modalBackgroundDimmer;
            }

            if (stepOptions?.overlayBackgroundDimmer && stepOptions.overlayBackgroundDimmer.length > 0) {
                currentValues.overlayBackgroundDimmer = stepOptions.overlayBackgroundDimmer;
            }

            if (stepOptions?.overlayBackgroundBlur && stepOptions.overlayBackgroundBlur.length > 0) {
                currentValues.overlayBackgroundBlur = stepOptions.overlayBackgroundBlur;
            }

            if (stepOptions?.cardDropShadow) {
                currentValues.cardDropShadow = stepOptions.cardDropShadow;
            }

            return currentValues;
        },
    );
}
