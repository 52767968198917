import { decrementStepAddress } from "modules/utils";
import { SessionState, StepAddress, StepIntroAddress } from "../../types";
import { lastStepAddressSelector } from "./lastStepAddressSelector";

export function stepBeforeCurrentAddress(state: SessionState): StepAddress | StepIntroAddress | null {
    if (state.currentStep === "exit") {
        return lastStepAddressSelector(state);
    }

    return decrementStepAddress(state);
}
