import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./coordinates-selector.scss";
import { CoordinatePreviewMode } from "modules/shared/types";
import { FaQuestionCircle } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { useLocalizedString } from "modules/client/localization";
import classNames from "classnames";

type CoordinatesSelectorProps = {
    onReset: () => void;
    onDone: () => void;
    coordinatePreviewMode: CoordinatePreviewMode;
    setCoordinatePreviewMode: (mode: CoordinatePreviewMode) => void;
    isSidebarOpen: boolean;
};

const CoordinatesSelector: React.FC<CoordinatesSelectorProps> = (props: CoordinatesSelectorProps) => {
    const localized = useLocalizedString();

    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

    const selectOptions = Object.entries(CoordinatePreviewMode).map(([mode, appStringKey]) => {
        if (mode !== "Off" && appStringKey) {
            return <option key={mode}>{localized(appStringKey)}</option>;
        }
    });

    const setValue = (mode: CoordinatePreviewMode) => {
        switch (mode) {
            case CoordinatePreviewMode.Hotspot:
                return localized(CoordinatePreviewMode.Hotspot);
            case CoordinatePreviewMode.FocusArea:
                return localized(CoordinatePreviewMode.FocusArea);
            default:
                break;
        }
    };

    const handleSelect = (mode: string) => {
        switch (mode) {
            case localized("coordinatePreview_hotspotMode"):
                props.setCoordinatePreviewMode(CoordinatePreviewMode.Hotspot);
                break;
            case localized("coordinatePreview_focusAreaMode"):
                props.setCoordinatePreviewMode(CoordinatePreviewMode.FocusArea);
            default:
                break;
        }
    };

    const helpModal = (
        <Modal className="help-modal" show={isHelpModalOpen} onHide={() => setIsHelpModalOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{localized("coordinatePreview_directionsTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{localized("coordinatePreview_hotspotMode")}</h5>
                <span>{localized("coordinatePreview_hotspotDirectionsLineOne")}</span>
                <span>{localized("coordinatePreview_hotspotDirectionsLineTwo")}</span>
                <h5>{localized("coordinatePreview_focusAreaMode")}</h5>
                <span>{localized("coordinatePreview_focusAreaDirectionsLineOne")}</span>
                <span>{localized("coordinatePreview_focusAreaDirectionsLineTwo")}</span>
                <span>{localized("coordinatePreview_focusAreaDirectionsLineThree")}</span>
            </Modal.Body>
        </Modal>
    );

    const borderClassnames = classNames("coordinates-selector-border", { "sidebar-open": props.isSidebarOpen });

    return (
        <div className={borderClassnames}>
            <div className="coordinates-selector-container">
                <Row>
                    <Col md="7" className="dropdown-column">
                        <div className="coordinates-selector-title">
                            {localized("coordinatePreview_coordinatesSelectorTitle")}
                            <span className="help-button" onClick={() => setIsHelpModalOpen(true)}>
                                <FaQuestionCircle />
                            </span>
                        </div>
                        <Form.Select
                            value={setValue(props.coordinatePreviewMode)}
                            onChange={(e) => handleSelect(e.target.value)}
                        >
                            {selectOptions}
                        </Form.Select>
                    </Col>
                    <Col md="5" className="buttons-column">
                        <Button variant="dark2" className="reset-button" onClick={props.onReset}>
                            {localized("coordinatePreview_reset")}
                        </Button>
                        <Button variant="light" className="done-button" onClick={props.onDone}>
                            {localized("coordinatePreview_done")}
                        </Button>
                    </Col>
                </Row>
                {helpModal}
            </div>
        </div>
    );
};

export default CoordinatesSelector;
