import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import classNames from "classnames";
import React, { useCallback } from "react";
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./map-zoom-controls.scss";
import { useLocalizedString } from "../../client/localization";
import L from "leaflet";
import { useMapContext } from "../LeafletMap/MapContext";

type Props = {
    disabled?: boolean;
    inPreviewMode: boolean;
};

const MapZoomControls: React.FC<Props> = ({ disabled, inPreviewMode }) => {
    const { map } = useMapContext();
    const localized = useLocalizedString();
    const buttonClass = classNames({ disabled: disabled });

    const onZoomIn = useCallback(() => {
        map.zoomIn();
    }, [map]);

    const onZoomOut = useCallback(() => {
        map.zoomOut();
    }, [map]);

    return (
        <section
            aria-label={localized("vdpHud_zoomControlsTooltip")}
            aria-description={localized("vdpHud_zoomControlsDescription")}
            className={classNames("sidebar-zoom-controls", { "preview-mode": inPreviewMode })}
        >
            <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="zoom-controls-tooltip">{localized("vdpHud_zoomControlsTooltip")}</Tooltip>}
            >
                <ButtonGroup vertical aria-disabled={disabled}>
                    <Button
                        variant="sidebarAndZoomColor"
                        aria-label={localized("vdpHud_zoomControlsZoomIn")}
                        onClick={onZoomIn}
                        disabled={disabled}
                    >
                        <FaPlus />
                    </Button>

                    <Button
                        variant="sidebarAndZoomColor"
                        aria-label={localized("vdpHud_zoomControlsZoomOut")}
                        onClick={onZoomOut}
                        disabled={disabled}
                    >
                        <FaMinus />
                    </Button>
                </ButtonGroup>
            </OverlayTrigger>
        </section>
    );
};

export default MapZoomControls;
