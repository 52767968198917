import { StepExtraOptions } from "modules/shared/content-types";
import { SessionEvent } from "modules/shared/session-events";
import { StepAddress, StepIntroAddress } from "modules/shared/types";
import { v4 as uuidv4 } from "uuid";

type Args = {
    currentAddress: StepAddress | StepIntroAddress;
    toStep: StepAddress | StepIntroAddress;
};
const createNavigateIceBreaker = (args: Args): SessionEvent => {
    return {
        type: "navigateIceBreaker",
        from: args.currentAddress,
        to: args.toStep,
        uuid: uuidv4(),
    };
};

export default createNavigateIceBreaker;
