import { Role } from "modules/shared/content-types";
import { participantHasRole, updateStepStates } from "modules/utils";
import { createCurrentStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createCurrentStepDefinitionSelector";
import { participantAssetMarkupLimitSelector } from "modules/shared/selectors/step-definition/single-asset/participantAssetMarkupLimitSelector";
import { participantInfoStepMarkupIconLimit } from "modules/shared/selectors/participants/participantInfoStepMarkupIconLimit";
import { getStepOptions } from "modules/shared/selectors/step-definition/generic/getStepOptions";
import {
    AddAssetMarkupIcon,
    AddMarkupIcon,
    DraggingMarkupIcon,
    PlacedMarkupIcon,
    RemoveAssetMarkupIcon,
    RemoveMarkupIcon,
} from "../../session-events";
import { SessionState } from "../../types";
import { Icon } from "./types";

export const ADD_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: AddMarkupIcon): boolean => {
        const currentStepOptions = getStepOptions(state, state.currentStep);

        const participantId = payload.icon?.participantId;
        const currentParticipantLimit = participantInfoStepMarkupIconLimit(participantId)(state);
        if (typeof currentParticipantLimit === "number" && currentParticipantLimit <= 0) {
            return false;
        }

        return !!(
            currentStepOptions?.enableMapMarkup &&
            !state.visibleMarkupIcons.some((icon) => icon.iconId === payload.icon.iconId)
        );
    },
    reducer: (state: SessionState, payload: AddMarkupIcon): SessionState => {
        return {
            ...state,
            visibleMarkupIcons: [...state.visibleMarkupIcons, payload.icon],
        };
    },
};

export const REMOVE_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: RemoveMarkupIcon): boolean => {
        const currentStepOptions = getStepOptions(state, state.currentStep);
        const clickerInfo = state.participants.find((x) => x.id === payload.clickerId);
        const isFacilitator = clickerInfo && participantHasRole(clickerInfo, Role.Facilitator);
        const canInteractWithIcons = payload.placerId === payload.clickerId || isFacilitator;

        return !!(currentStepOptions?.enableMapMarkup && canInteractWithIcons);
    },
    reducer: (state: SessionState, payload: RemoveMarkupIcon): SessionState => {
        const visibleMarkupIcons = state.visibleMarkupIcons.filter((icon) => icon.iconId !== payload.iconId);

        return {
            ...state,
            visibleMarkupIcons,
        };
    },
};

export const DRAGGING_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: DraggingMarkupIcon): boolean => {
        const currentStepOptions = getStepOptions(state, state.currentStep);
        const clickerInfo = state.participants.find((x) => x.id === payload.draggerId);
        const isFacilitator = clickerInfo && participantHasRole(clickerInfo, Role.Facilitator);
        const canInteractWithIcons = payload.icon.participantId === payload.draggerId || isFacilitator;

        return !!(currentStepOptions?.enableMapMarkup && canInteractWithIcons && !payload.icon.isBeingDraggedBy);
    },
    reducer: (state: SessionState, payload: DraggingMarkupIcon): SessionState => {
        const visibleMarkupIcons = state.visibleMarkupIcons.filter((icon) => icon.iconId !== payload.icon.iconId);
        const icon: Icon = {
            ...payload.icon,
            isBeingDraggedBy: payload.draggerId,
        };

        return {
            ...state,
            visibleMarkupIcons: [...visibleMarkupIcons, icon],
        };
    },
};

export const PLACED_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: PlacedMarkupIcon): boolean => {
        const currentStepOptions = getStepOptions(state, state.currentStep);
        return !!(currentStepOptions?.enableMapMarkup && payload.icon.isBeingDraggedBy === payload.placerId);
    },
    reducer: (state: SessionState, payload: PlacedMarkupIcon): SessionState => {
        const visibleMarkupIcons = state.visibleMarkupIcons.filter((icon) => icon.iconId !== payload.icon.iconId);
        const icon: Icon = {
            ...payload.icon,
            coordinates: payload.coordinates,
            isBeingDraggedBy: null,
        };

        return {
            ...state,
            visibleMarkupIcons: [...visibleMarkupIcons, icon],
        };
    },
};

export const ADD_ASSET_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: AddAssetMarkupIcon): boolean => {
        if (state.isIntroStep || state.currentStep === "exit") {
            return false;
        }
        const participantId = payload.icon?.participantId;
        const currentParticipantLimit = participantAssetMarkupLimitSelector(participantId)(state);
        if (typeof currentParticipantLimit === "number" && currentParticipantLimit <= 0) {
            return false;
        }

        const currentStepDefinition = createCurrentStepDefinitionSelector("singleAssetStep")(state);
        return !!(currentStepDefinition && currentStepDefinition?.enableAssetMarkup);
    },

    reducer: (state: SessionState, payload: AddAssetMarkupIcon): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("SINGLE_ASSET", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        markupIcons: [...stepState.persistent.markupIcons, payload.icon],
                    },
                };
            }),
        };
    },
};

export const REMOVE_ASSET_MARKUP_ICON = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: RemoveAssetMarkupIcon): boolean => {
        if (state.isIntroStep || state.currentStep === "exit") {
            return false;
        }
        const currentStepDefinition = createCurrentStepDefinitionSelector("singleAssetStep")(state);
        const clickerInfo = state.participants.find((x) => x.id === payload.clickerId);
        const isFacilitator = clickerInfo && participantHasRole(clickerInfo, Role.Facilitator);
        const canInteractWithIcons = payload.placerId === payload.clickerId || isFacilitator;
        return !!(currentStepDefinition && currentStepDefinition?.enableAssetMarkup && canInteractWithIcons);
    },
    reducer: (state: SessionState, payload: RemoveAssetMarkupIcon): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("SINGLE_ASSET", state, state.currentStep, (stepState) => {
                const remainingMarkupIcons = stepState.persistent.markupIcons.filter(
                    (icon) => icon.iconId !== payload.iconId,
                );
                return {
                    ...stepState,
                    persistent: {
                        markupIcons: remainingMarkupIcons,
                    },
                };
            }),
        };
    },
};
