import { participantHasRole, updateParticipant } from "modules/utils";
import { Role } from "../content-types";
import { getIceBreakerNavList } from "../selectors/intro-sequence/getIceBreakerNavList";
import { getFullCurrentIntroStep } from "../selectors/intro-sequence/getFullCurrentIntroStep";
import { currentParticipantsEligibleForNavigatorRole } from "../selectors/participants/currentParticipantsEligibleForNavigatorRole";
import { participants } from "../selectors/participants/participants";
import { inIntroSequence } from "../selectors/intro-sequence/inIntroSequence";
import { getIsFirstStep } from "../selectors/step-address/getIsFirstStep";
import { AssignFacilitator } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const updateIcebreakerNavPoolState = (
    state: SessionState,
    newFacilitatorId: string,
): Pick<SessionState, "currentIntroStep" | "iceBreakerNavigatorPool" | "isIntroStep"> => {
    const currentIntroStepAddr = getFullCurrentIntroStep(state);
    const currentIcebreakerNavPool = getIceBreakerNavList(state);
    const currentInIntroSequence = inIntroSequence(state);
    const newIcebreakerNavPool = currentIcebreakerNavPool.filter((id) => id !== newFacilitatorId);
    if (!currentInIntroSequence) {
        return {
            isIntroStep: currentInIntroSequence,
            currentIntroStep: currentIntroStepAddr,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
        };
    }

    if (newIcebreakerNavPool.length === 0 && currentIntroStepAddr.introStepKey === "iceBreaker") {
        return {
            isIntroStep: false,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: {
                ...currentIntroStepAddr,
                iceBreakerNavPoolIndex: "complete",
            },
        };
    } else if (newIcebreakerNavPool.length === 0) {
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    }

    // we have at least one participant in new icebreaker pool
    const indexOfAssignedFacilitator = currentIcebreakerNavPool.indexOf(newFacilitatorId);
    if (currentIntroStepAddr.iceBreakerNavPoolIndex === "complete") {
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    } else if (currentIntroStepAddr.iceBreakerNavPoolIndex === "intro") {
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    } else if (indexOfAssignedFacilitator === -1) {
        // assigned facilitator was not in the icebreaker pool
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: currentIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    } else if (
        indexOfAssignedFacilitator === currentIntroStepAddr.iceBreakerNavPoolIndex &&
        currentIcebreakerNavPool.length === indexOfAssignedFacilitator + 1
    ) {
        // assigned facilitator was the current icebreaker participant and last in the rotation
        return {
            isIntroStep: false,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: {
                ...currentIntroStepAddr,
                iceBreakerNavPoolIndex: "complete",
            },
        };
    } else if (
        indexOfAssignedFacilitator === currentIntroStepAddr.iceBreakerNavPoolIndex &&
        currentIcebreakerNavPool.length > indexOfAssignedFacilitator + 1
    ) {
        // assigned facilitator was the current icebreaker participant and not last in the rotation
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    } else if (indexOfAssignedFacilitator > currentIntroStepAddr.iceBreakerNavPoolIndex) {
        // assigned facilitator was later in the icebreaker rotation
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: currentIntroStepAddr,
        };
    } else if (indexOfAssignedFacilitator < currentIntroStepAddr.iceBreakerNavPoolIndex) {
        // assigned facilitator was earlier in the icebreaker rotation
        const newIcebreakerIndex = currentIntroStepAddr.iceBreakerNavPoolIndex - 1;
        return {
            isIntroStep: currentInIntroSequence,
            iceBreakerNavigatorPool: newIcebreakerNavPool,
            currentIntroStep: {
                ...currentIntroStepAddr,
                iceBreakerNavPoolIndex: newIcebreakerIndex,
            },
        };
    }

    return {
        isIntroStep: currentInIntroSequence,
        currentIntroStep: currentIntroStepAddr,
        iceBreakerNavigatorPool: newIcebreakerNavPool,
    };
};

const updateNavigatorPoolState = (
    state: SessionState,
    newFacilitatorId: string,
): SessionState["poolOfEligibleParticipantsForNavigatorRole"] => {
    const currentNavigatorPool = currentParticipantsEligibleForNavigatorRole(state);
    const isFirstStep = getIsFirstStep(state);
    const currentParticipantsList = participants(state);
    const participantNavigator = state.participants.find(
        (p) =>
            participantHasRole(p, Role.Navigator) &&
            !participantHasRole(p, Role.Facilitator) &&
            p.id !== newFacilitatorId,
    );
    const willReassignNavigator = participantNavigator && isFirstStep;
    const newNavPool = currentNavigatorPool.filter((id) => id !== newFacilitatorId);
    if (willReassignNavigator) {
        return [participantNavigator.id, ...newNavPool];
    }

    if (newNavPool.length === 0) {
        // get a new list of eligible participants
        return currentParticipantsList
            .filter((p) => p.id !== newFacilitatorId && !participantHasRole(p, Role.Facilitator) && p.ready)
            .map((p) => p.id);
    }
    return newNavPool;
};

export const ASSIGN_FACILITATOR: EventReducerDefinition<AssignFacilitator, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: AssignFacilitator) => {
        const participantInfo = state.participants.find((p) => p.id === payload.participantId);

        if (!participantInfo) return false;

        return !participantHasRole(participantInfo, Role.Facilitator);
    },
    reducer: (state: SessionState, payload: AssignFacilitator) => {
        const isFirstStep = getIsFirstStep(state);
        const currentInIntroSequence = inIntroSequence(state);
        const currentIntroStepAddr = getFullCurrentIntroStep(state);

        const otherFacilitators = state.participants.filter(
            (p) => participantHasRole(p, Role.Facilitator) && payload.participantId !== p.id,
        );
        const currentNavigator = state.participants.filter((p) => participantHasRole(p, Role.Navigator));

        const isCurrentNavigator = currentNavigator.length > 0 && payload.participantId === currentNavigator[0].id;
        const shouldAssignBothRoles =
            isFirstStep &&
            otherFacilitators.length === 0 &&
            !(currentInIntroSequence && currentIntroStepAddr.introStepKey === "iceBreaker");
        // sets navigator to first facilitator at beginning of experience, also will not assign the facilitator to navigator if another facilitator already exists
        // don't unassign navigator role when assigning facilitator role
        const role =
            shouldAssignBothRoles ||
            (isCurrentNavigator && !(currentInIntroSequence && currentIntroStepAddr.introStepKey === "iceBreaker"))
                ? [Role.Facilitator, Role.Navigator]
                : [Role.Facilitator];

        // remove the navigator role from any non-facilitator participant on the first step
        // we are assuming only one navigator will exist
        const participantNavigator = state.participants.find(
            (p) => participantHasRole(p, Role.Navigator) && !participantHasRole(p, Role.Facilitator),
        );

        const shouldReassignNavigator = participantNavigator && isFirstStep;

        const updatedParticipants = shouldReassignNavigator
            ? updateParticipant(state.participants, participantNavigator.id, (participant) => {
                  return {
                      ...participant,
                      assignedRoles: [],
                  };
              })
            : state.participants;

        if (payload.participantId) {
            const icebreakerNavPoolUpdates = updateIcebreakerNavPoolState(state, payload.participantId);
            const newNavigatorPool = updateNavigatorPoolState(state, payload.participantId);
            let newParticipantsList = updateParticipant(updatedParticipants, payload.participantId, (participant) => {
                return {
                    ...participant,
                    assignedRoles: [...participant.assignedRoles, ...role],
                };
            });

            if (
                icebreakerNavPoolUpdates.currentIntroStep.introStepKey === "iceBreaker" &&
                icebreakerNavPoolUpdates.currentIntroStep.iceBreakerNavPoolIndex !== "complete" &&
                icebreakerNavPoolUpdates.currentIntroStep.iceBreakerNavPoolIndex !== "intro"
            ) {
                const currentIcebreakerNavigatorId =
                    icebreakerNavPoolUpdates.iceBreakerNavigatorPool[
                        icebreakerNavPoolUpdates.currentIntroStep.iceBreakerNavPoolIndex
                    ];
                newParticipantsList = !!currentIcebreakerNavigatorId
                    ? updateParticipant(newParticipantsList, currentIcebreakerNavigatorId, (p) => ({
                          ...p,
                          assignedRoles: [Role.Navigator],
                      }))
                    : newParticipantsList;
            } else if (
                icebreakerNavPoolUpdates.currentIntroStep.introStepKey === "iceBreaker" &&
                icebreakerNavPoolUpdates.currentIntroStep.iceBreakerNavPoolIndex === "complete" &&
                !icebreakerNavPoolUpdates.isIntroStep &&
                isFirstStep
            ) {
                // give first facilitator in list navigator role
                const firstFacilitator = newParticipantsList.find((p) => participantHasRole(p, Role.Facilitator));
                newParticipantsList = !!firstFacilitator
                    ? updateParticipant(newParticipantsList, firstFacilitator.id, (p) => ({
                          ...p,
                          assignedRoles: [...p.assignedRoles, Role.Navigator],
                      }))
                    : updateParticipant(newParticipantsList, payload.participantId, (p) => ({
                          ...p,
                          assignedRoles: [...p.assignedRoles, Role.Navigator],
                      }));
            }
            return {
                ...state,
                ...icebreakerNavPoolUpdates,
                // if the navigator was reassigned then add them back to the pool of eligible participants
                poolOfEligibleParticipantsForNavigatorRole: newNavigatorPool,
                participants: newParticipantsList,
            };
        }
        return state;
    },
};

export default ASSIGN_FACILITATOR;
