import { createIsAwaitingResponseSelector } from "modules/client/selectors";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./awaiting-server-progress-bar.scss";

const AwaitingServerProgressBar: React.FC = () => {
    const isAwaitingServer = useSelector(createIsAwaitingResponseSelector());
    return <div className="loading-indicator">{isAwaitingServer && <Spinner animation="grow" />}</div>;
};

export default AwaitingServerProgressBar;
