import "./full-page-loading.scss";
import React from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useLocalizedString } from "modules/client/localization";

const FullPageLoading: React.FC = () => {
    const localized = useLocalizedString();

    return (
        <Container>
            <Row>
                <Col>
                    <Card className="full-page-loading">
                        <Card.Body>
                            <div className="loading-bar-container">
                                <ProgressBar animated now={100} />
                            </div>

                            <Card.Text className="loading-text">{localized("vdpLoading_waitForContent")}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default FullPageLoading;
