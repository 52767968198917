import React, { useState, useEffect, useRef } from "react";
import { MdAccessTimeFilled } from "react-icons/md";
import { createTimeRecommendedForPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeRecommendedForPreviousSectionsSelector";
import { createTimeDevotedToPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeDevotedToPreviousSectionsSelector";
import { useSessionSelector } from "../SessionRenderer/context";
import { useLocalizedString } from "modules/client/localization";
import "./progress-badge.scss";
import BadgeWithIcon, { BadgeWithIconProps } from "../BadgeWithIcon";
import { evaluatePace } from "modules/shared/utils";
import { PaceCheck } from "modules/shared/types";

const ProgressBadge: React.FC = () => {
    const localized = useRef(useLocalizedString());
    const timeDevoted = useSessionSelector(createTimeDevotedToPreviousSectionsSelector());
    const timeRecommended = useSessionSelector(createTimeRecommendedForPreviousSectionsSelector());
    const [badgeMessage, setBadgeMessage] = useState("");
    const [badgeBackground, setBadgeBackground] = useState<BadgeWithIconProps["variant"]>();

    useEffect(() => {
        switch (evaluatePace(timeRecommended, timeDevoted)) {
            case PaceCheck.OnPace:
                setBadgeMessage(localized.current("vdpHud_progressOnPaceMessage"));
                setBadgeBackground("secondary");
                break;
            case PaceCheck.AheadOfPace:
                setBadgeMessage(localized.current("vdpHud_progressAheadOfPaceMessage"));
                setBadgeBackground("danger");
                break;
            case PaceCheck.BehindPace:
                setBadgeMessage(localized.current("vdpHud_progressBehindPaceMessage"));
                setBadgeBackground("danger");
                break;
            default:
                break;
        }
    }, [timeDevoted, timeRecommended, setBadgeMessage, setBadgeBackground, localized]);

    return (
        <BadgeWithIcon
            pill
            variant={badgeBackground}
            className="progress-badge"
            icon={MdAccessTimeFilled}
            iconProps={{ size: 16 }}
            roleLabel={badgeMessage}
        />
    );
};

export default ProgressBadge;
