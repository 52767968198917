import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    participantId: string;
    languageCode: string;
};
const createParticipantLocaleChanged = (args: Args): SessionEvent => {
    return {
        type: "PARTICIPANT_LOCALE_CHANGED",
        participantId: args.participantId,
        languageCode: args.languageCode,
        uuid: uuidv4(),
    };
};

export default createParticipantLocaleChanged;
