import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { createCurrentStepDefinitionSelector } from "../generic/createCurrentStepDefinitionSelector";

export function createAssetMarkupIconTypeSelector(): (s: SessionState) => string {
    return createSelector([createCurrentStepDefinitionSelector("singleAssetStep")], (stepDef) => {
        const iconType = stepDef?.assetMarkupIconType;

        if (!iconType) {
            return "check";
        } else {
            return iconType.toLowerCase().replaceAll(" ", "_");
        }
    });
}
