import { useSessionSelector } from "modules/components/SessionRenderer/context";
import { ComparisonStep } from "modules/shared/content-types";
import { getComparisonData } from "modules/shared/selectors/step-state/getComparisonData";
import { BaseActivityProps } from "modules/shared/types";
import React from "react";
import ComparisonRow from "./ComparisonRow";
import "./ComparisonActivity.scss";

type Props = ComparisonStep & BaseActivityProps;

const ComparisonActivity: React.FC<Props> = (props) => {
    const comparisonData = useSessionSelector(getComparisonData);

    if (!comparisonData) {
        return <></>;
    } else {
        return (
            <div className="comparison-activity">
                <ComparisonRow state={comparisonData.topRow} />
                {!!comparisonData.bottomRow && <ComparisonRow state={comparisonData.bottomRow} />}
            </div>
        );
    }
};

export default ComparisonActivity;
