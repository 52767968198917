import { MdAssignment, MdRecordVoiceOver } from "react-icons/md";
import { ContentBlock } from "modules/components/Sidebar/ContentBody";
import { useLocalizedString } from "modules/client/localization";
import { Role, Section } from "modules/shared/content-types";
import { IntroStepKey, PaceCheck, StepAddress } from "modules/shared/types";
import { checkSectionForStartOfRole } from "modules/utils";

type SessionsRequestBody = {
    number: number;
    closes_at: string;
    opens_at: string;
    language_code: string;
    data_collection: boolean;
    isPreview?: boolean;
};

// This function is only to ensure the required fields are being used in every instance where
// we are hitting the endpoint for creating sessions.
export const createSessionsRequestBody = (args: SessionsRequestBody): string => {
    return JSON.stringify(args);
};

export function getWelcomeContent(
    isNavigator: boolean,
    hasStarted: boolean,
    localized: ReturnType<typeof useLocalizedString>,
): ContentBlock[] {
    const welcomeIntro: string = createParagraphText([localized("vdpNameEntry_intro")]);
    const welcomeStarted: string = createParagraphText([localized("vdpNameEntry_introStarted")]);

    const contentBlocks: ContentBlock[] = [];
    if (hasStarted) {
        contentBlocks.push({
            icon: MdRecordVoiceOver,
            title: localized("sidebar_introductionLabel"),
            instruction: isNavigator ? localized("sidebar_introductionInstruction") : undefined,
            content: welcomeStarted,
        });
    } else {
        contentBlocks.push({
            icon: MdRecordVoiceOver,
            title: localized("sidebar_introductionLabel"),
            instruction: isNavigator ? localized("sidebar_introductionInstruction") : undefined,
            content: welcomeIntro,
        });
    }

    if (isNavigator) {
        const strings = [
            localized("vdpNameEntry_navDirections"),
            localized("navigatorDefaultInstructions_selectNextToContinue"),
        ];

        const bulletList = createBulletedDirections(strings);

        contentBlocks.push({
            icon: MdAssignment,
            title: localized("sidebar_directionsLabel"),
            instruction: localized("sidebar_directionsInstruction"),
            content: bulletList,
        });
    }
    return contentBlocks;
}

function createBulletedDirections(bullets: string[]): string {
    const bulletChar = "* ";
    return bullets.reduce((currentList, newListItem, index) => {
        if (index === 0) {
            return currentList + newListItem;
        } else {
            return currentList + "\n" + bulletChar + newListItem;
        }
    }, bulletChar);
}

function createParagraphText(paragraphs: string[]): string {
    const newLineChar = "\n\n";
    return paragraphs.reduce((paragraphs, newParagraph, index) => {
        if (index === 0) {
            return newParagraph;
        } else {
            return paragraphs + newLineChar + newParagraph;
        }
    });
}

export function createProgressCheckIntro(
    sectionEntry: Section,
    sectionKey: number,
    pace: PaceCheck,
    localized: ReturnType<typeof useLocalizedString>,
): string {
    const sectionNumber = sectionKey + 1;
    const instructionStrings: string[] = [
        localized("navigatorDefaultInstructions_progressCheckLineOne", sectionNumber - 1),
    ];

    // check pacing
    switch (pace) {
        case PaceCheck.OnPace:
            instructionStrings.push(localized("navigatorDefaultInstructions_progressCheckLineTwoOnPace"));
            break;
        case PaceCheck.AheadOfPace:
            instructionStrings.push(localized("navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace"));
            break;
        case PaceCheck.BehindPace:
            instructionStrings.push(localized("navigatorDefaultInstructions_progressCheckLineTwoBehindPace"));
            break;
        default:
            break;
    }

    // check for upcoming navigator assignment
    const navAssignmentStep: StepAddress | null = sectionEntry
        ? checkSectionForStartOfRole(Role.Navigator, { sectionKey: sectionKey, sectionEntry })
        : null;
    if (navAssignmentStep && navAssignmentStep !== "exit" && navAssignmentStep.stepKey === 0) {
        // add instructional note about upcoming navigator assignment
        instructionStrings.push(localized("navigatorDefaultInstructions_progressCheckLineThreeNewNav", sectionNumber));
    }

    return createParagraphText(instructionStrings);
}

export function getIntroInstructions(
    localized: ReturnType<typeof useLocalizedString>,
    introStep: IntroStepKey,
    iceBreakerNavPoolIndex: number | "intro" | "complete",
    navigatorName: string | null | undefined,
    customIceBreakerMessage?: string,
): string | null {
    const strings: string[] = [];
    switch (introStep) {
        case "facilitatorRole":
            strings.push(
                localized("vdpIntro_facilitatorInstructionsOne"),
                localized("vdpIntro_facilitatorInstructionsTwo"),
                localized("vdpIntro_facilitatorInstructionsThree"),
            );
            break;
        case "navigatorRole":
            strings.push(
                localized("vdpIntro_navigatorInstructionsOne"),
                localized("vdpIntro_navigatorInstructionsTwo"),
            );
            break;
        case "participantRole":
            strings.push(
                localized("vdpIntro_participantInstructionsOne"),
                localized("vdpIntro_participantInstructionsTwo"),
            );
            break;
        case "iceBreaker":
            if (iceBreakerNavPoolIndex === "intro" || iceBreakerNavPoolIndex === "complete") {
                strings.push(
                    localized("vdpIntro_iceBreakerIntroInstructionsOne"),
                    localized("vdpIntro_iceBreakerIntroInstructionsTwo"),
                );
                break;
            } else if (navigatorName) {
                if (customIceBreakerMessage) {
                    strings.push(
                        `${localized("vdpIntro_iceBreakerInstructionsOne", navigatorName)} ${customIceBreakerMessage}`,
                    );
                } else {
                    strings.push(
                        `${localized("vdpIntro_iceBreakerInstructionsOne", navigatorName)} ${localized(
                            "vdpIntro_iceBreakerInstructionsDefault",
                        )}`,
                    );
                }
                break;
            }
        case "mapVisual":
            strings.push(localized("vdpIntro_mapInstructionsOne"), localized("vdpIntro_mapInstructionsTwo"));
            break;
        default:
            return null;
    }
    return createParagraphText(strings);
}

export function getIntroDirections(
    localized: ReturnType<typeof useLocalizedString>,
    introStep: IntroStepKey,
    iceBreakerNavPoolIndex: number | "intro" | "complete",
): string | null {
    const strings: string[] = [];
    if (introStep === "iceBreaker" && (iceBreakerNavPoolIndex === "intro" || iceBreakerNavPoolIndex === "complete")) {
        strings.push(localized("vdpIntro_iceBreakerIntroDirections"));
    }
    strings.push(localized("navigatorDefaultInstructions_selectNextToContinue"));
    return createBulletedDirections(strings);
}

export function getIntroSectionTitles(localized: ReturnType<typeof useLocalizedString>): string[] {
    return [
        localized("vdpIntro_joinTheTeam"),
        localized("vdpIntro_facilitatorRole"),
        localized("vdpIntro_navigatorRole"),
        localized("vdpIntro_participantRole"),
        localized("vdpIntro_iceBreaker"),
        // localized("vdpIntro_exploreTheVisual"), TODO: ROOT-331
    ];
}
