import { AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./config";

// this is based on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/utils/MsGraphApiCall.ts

export async function callSecureEndpoint(
    msalInstance: IPublicClientApplication,
    ...fetchParams: Parameters<typeof fetch>
): Promise<Response | void> {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return msalInstance.loginRedirect(loginRequest);
    }

    let loginSilent: AuthenticationResult;
    try {
        loginSilent = await msalInstance.acquireTokenSilent({ ...loginRequest, account: account });
    } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
            return msalInstance.loginRedirect(loginRequest);
        }
        throw err;
    }

    const [url, requestInit] = fetchParams;
    const authRequestInit: RequestInit = {
        mode: "same-origin",
        ...requestInit,
        headers: {
            ...requestInit?.headers,
            Authorization: "Bearer " + loginSilent.accessToken,
        },
    };
    const resp = await fetch(url, authRequestInit);
    return resp;
}
