import { EntryFields, Asset as ContentfulAsset } from "contentful";

/////////////////////////////////////////////////////////////////////////////
// Constants ////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export type FeatureFlag = {
    name: string;
    description: string;
    active: boolean;
};

export type RoleId = Role.Navigator | Role.Facilitator;

export enum Role {
    Navigator = "navigator",
    Facilitator = "facilitator",
}

/////////////////////////////////////////////////////////////////////////////
// Helpers & Meta-types /////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export interface ContentRecord<ContentTypeId extends string = string> {
    id: string;
    version: number;
    contentType: ContentTypeId;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Asset extends ContentfulAsset<"WITHOUT_UNRESOLVABLE_LINKS", "string"> {}

/////////////////////////////////////////////////////////////////////////////
// Shared script definition types: //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
export interface ExperienceScript extends ContentRecord<"experience"> {
    clientName: string;
    name: string;
    appearance?: Appearance;
    mapUrl: string;
    welcomeOptions?: WelcomeOptions;
    sections: Section[];
    endingOptions?: EndingOptions;
    progressChecksEnabled?: boolean;
    changeNavigatorAutomatically?: boolean;
    locale: string;
}

export interface Section extends ContentRecord<"section"> {
    title: string;
    time: number;
    steps: StepDefinition[];
}

/////////////////////////////////////////////////////////////////////////////
// Non-step contentful types: ///////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export interface WelcomeOptions extends ContentRecord<"participantWelcome"> {
    icebreakerQuestion?: string;
    disableIntro?: boolean;
    time?: number;
    enableClaimNavigatorRole?: boolean;
}

export interface EndingOptions extends ContentRecord<"endingOptions"> {
    endingMessage?: EntryFields.RichText;
    introduction?: string;
    directions?: string;
    facilitationNotes?: string;
    popupMessage?: EntryFields.RichText;
    options?: StepExtraOptions;
    surveyLink?: string;
}

export interface Column extends ContentRecord<"column"> {
    key: string;
    title: string;
    details: EntryFields.RichText;
    useContentAsTitle?: boolean;
    validCards?: Card["id"][];
}

export interface Card extends ContentRecord<"card"> {
    key: string;
    title: string;
    details: EntryFields.RichText;
    detailsBack: EntryFields.RichText;
    useContentAsTitle?: boolean;
    hideBackground?: boolean;
    additionalCssProperties: string;
    focusMapOn?: string[];
}

export interface TopicCard extends ContentRecord<"topicCard"> {
    key: string;
    title: string;
    additionalCssProperties: string;
}

export interface AdditionalMap extends ContentRecord<"additionalMap"> {
    title: string;
    mapUrl: string;
}

export interface StepExtraOptions extends AppearanceOptions, ContentRecord<"stepOptions"> {
    enableInPersonDataCollection?: boolean;
    assignRoles: RoleId[];
    focusMapOn: string[];
    mapHighlightAreas: string[];
    showParticipantsCursors?: "Yes" | "No" | "Default" | null;
    enableMapMarkup?: boolean;
    showMapMarkupIcons?: boolean;
    markupIconsLimit?: number;
    hasCelebration?: boolean;
    mapMarkupIconType?:
        | "Check"
        | "Close"
        | "Favorite"
        | "Mood"
        | "Mood Bad"
        | "Flag"
        | "Thumb Down"
        | "Thumb Up"
        | "Arrow Forward"
        | "Arrow Upward"
        | "Help"
        | "Priority High"
        | "Star"
        | "Search"
        | "Location On";
    hideMarkupIconsFromSteps?: string[];
    showMarkupIconsFromSteps?: string[];
    hideMarkupIconsFromStepsReferences?: StepDefinition["id"][];
    showMarkupIconsFromStepsReferences?: StepDefinition["id"][];
    changeMapTo?: AdditionalMap;
}

export interface PredefinedAnswer extends ContentRecord<"predefinedAnswer"> {
    title: string;
    answerBody: EntryFields.RichText;
    isCorrect: boolean;
}

interface AppearanceOptions {
    backgroundColor?: string;
    overlayBackgroundColor?: string;
    overlayAdditionalProps?: string;
    modalBackgroundDimmer?: "none" | "light" | "medium" | "heavy";
    modalBackgroundBlur?: "none" | "light" | "medium" | "heavy";
    overlayBackgroundDimmer?: "none" | "light" | "medium" | "heavy";
    overlayBackgroundBlur?: "none" | "light" | "medium" | "heavy";
    cardDropShadow?: boolean;
    accentColor?: string;
    markupIconColor?: string;
    hotspotIconColor?: string;
    sidebarAndZoomColor?: string;
    favicon?: Asset;
    pageTitle?: string;
}
export interface Appearance extends AppearanceOptions, ContentRecord<"appearance"> {}

export interface Hotspot extends ContentRecord<"hotspot"> {
    title: string;
    card?: Card;
    content?: EntryFields.RichText;
    mapCoordinates: string[];
    hideBackground?: boolean;
    openedCardAlignment?: "center" | "left" | "right";
}

export interface ClickableArea extends ContentRecord<"clickableArea"> {
    title: string;
    card?: Card;
    content?: EntryFields.RichText;
    mapCoordinates: string;
    hideBackground?: boolean;
    openedCardAlignment?: "center" | "left" | "right";
}

export interface Match extends ContentRecord<"match"> {
    title: string;
    card: Card;
}

/////////////////////////////////////////////////////////////////////////////
// Individual step types: ///////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

export interface StepRecord<ContentTypeId extends string> extends ContentRecord<ContentTypeId> {
    title: string;
    introduction?: string;
    directions?: string;
    facilitationNotes?: string;
    instructionalContent?: EntryFields.RichText;
    options?: StepExtraOptions;
}

/**
 * Info Activity
 */
export interface InfoStep extends StepRecord<"infoStep"> {
    content: EntryFields.RichText;
    mapHotspots?: Hotspot[];
    clickableAreas?: ClickableArea[];
}

/**
 * Single Asset Activity
 */
export interface SingleAssetStep extends StepRecord<"singleAssetStep"> {
    contentAlignment: "Center" | "Left" | "Right";
    body: EntryFields.RichText;
    additionalProps?: string;
    hideBackground?: boolean;
    enableAssetMarkup?: boolean;
    markupIconsLimit?: number;
    showMarkupIconsFromSteps?: SingleAssetStep["id"][];
    assetMarkupIconType?:
        | "Check"
        | "Close"
        | "Favorite"
        | "Mood"
        | "Mood Bad"
        | "Flag"
        | "Thumb Down"
        | "Thumb Up"
        | "Arrow Forward"
        | "Arrow Upward"
        | "Help"
        | "Priority High"
        | "Star"
        | "Search"
        | "Location On";
}

/**
 * Reveal Card Activity
 */
export interface RevealCardStep extends StepRecord<"revealCardStep"> {
    cards: Card[];
    rowSize?: number;
    openedCardPlacement?: "center" | "left" | "right";
    transparentBackgroundOnOpen?: boolean;
}

/**
 * Card With Visual Activity
 */
export interface CardStackWithVisualStep extends StepRecord<"cardStackWithVisualStep"> {
    cards: Card[];
    stackPlacement?: "center" | "left" | "right";
}

/**
 * Drag and Drop Activity
 */
export interface DragAndDropStep extends StepRecord<"dragAndDropStep"> {
    columns: string[];
    columnEntries?: Column[];
    hasInputRow: boolean;
    cards: Card[];
    completionMessage: EntryFields.RichText;

    /* mks 2020-08-25 I wanted a minimal type... but TypeScript can't represent it. Here's what Contentful has (... as if it would work in typescript)
        type DragAndDropCorrectLayout = {
            checkOrder: boolean;
            [ columnId : string ] : string[];
        }

        Since a) this is user-entered and could be anything the user types in
        and b) TS doesn't like that structure anyway, there's a utility
        method in the DnD utils that will read this value and convert it into
        a TS-friendly type.
    */
    correctCardPositions: unknown | null;
    checkOrdering: boolean;
}

/**
 * Matching Activity
 */
export interface MatchingStep extends StepRecord<"matchingStep"> {
    matches: Match[];
    completionMessage?: EntryFields.RichText;
}

/**
 * Multiple Choice Activity
 */
export interface MultipleChoiceStep extends StepRecord<"multipleChoiceStep"> {
    prompt: EntryFields.RichText;
    align: "Center" | "Left" | "Right";
    questionType: "Single Answer" | "Multiple Answers";
    predefinedAnswers: PredefinedAnswer[];
    answerExplanation: EntryFields.RichText;
    disableCorrectnessReveal: boolean;
}

/**
 * Text Entry Activity
 */
export interface TextEntryStep extends StepRecord<"textEntryStep"> {
    prompt: EntryFields.RichText;
    align: "Center" | "Left" | "Right";
    questionType: "Group Answer" | "Individual Answer";
    followUpText: EntryFields.RichText;
}

/**
 * Editable Card Activity
 */
export interface EditableCardStep extends StepRecord<"editableCardStep"> {
    topicCards: TopicCard[];
    followUpText: EntryFields.RichText;
}

/**
 * Comparison Activity
 */
export interface ComparisonStep extends StepRecord<"comparisonStep"> {
    firstStepTitle?: string;
    secondStepTitle?: string;
    firstStep?: DragAndDropStep["id"];
    secondStep?: DragAndDropStep["id"];
}

/**
 * Video Activity
 */
export interface VideoStep extends StepRecord<"videoStep"> {
    content: EntryFields.RichText;
}

/**
 * Exit step
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Exit extends StepRecord<"exit"> {}

/**
 * Intro step
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IntroStep extends StepRecord<"introStep"> {}

/**
 * Progress check step
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgressCheck extends StepRecord<"progressCheck"> {}

/**
 * Recall step for Previous Steps
 */
export interface RecallStep extends StepRecord<"recallStep"> {
    previousStepStateId?: DragAndDropStep["id"] | TextEntryStep["id"];
    previousStepStateContentType?: DragAndDropStep["contentType"] | TextEntryStep["contentType"];
}

export type StepDefinition =
    | InfoStep
    | DragAndDropStep
    | MatchingStep
    | MultipleChoiceStep
    | RevealCardStep
    | CardStackWithVisualStep
    | SingleAssetStep
    | TextEntryStep
    | EditableCardStep
    | ComparisonStep
    | VideoStep
    | IntroStep
    | ProgressCheck
    | Exit
    | RecallStep;

export type ContentRecordDefinition =
    | StepDefinition
    | Section
    | ExperienceScript
    | EndingOptions
    | WelcomeOptions
    | Appearance
    | Hotspot
    | ClickableArea
    | Card
    | TopicCard
    | Column
    | AdditionalMap
    | PredefinedAnswer
    | Match
    | StepExtraOptions;

export type StepDefinitionKey = StepDefinition["contentType"];
export type ContentRecordDefinitionKey = ContentRecordDefinition["contentType"];
