import React from "react";
import "./loading-block.scss";
import { Spinner } from "react-bootstrap";

const LoadingBlock: React.FC = () => {
    return (
        <div className="loading-block">
            <Spinner animation="border" />
        </div>
    );
};

export default LoadingBlock;
