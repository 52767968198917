// Some of this code
// https://gist.github.com/ktnyt/2573047b5b4c7c775f2be22326ebf6a8
// https://blog.cristiana.tech/calculating-color-contrast-in-typescript-using-web-content-accessibility-guidelines-wcag

export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const COLOR_CONTRAST_LIGHT = WHITE;
export const COLOR_CONTRAST_DARK = "#212529";

type RGB = [number, number, number];
type RGBA = [number, number, number, number];

const clamp = (n: number, min: number, max: number) =>
    n <= min ? min : n >= max ? max : n

const toHex = (v: number) => {
    const h = v.toString(16)
    switch (h.length) {
        case 1:
            return `0${h}`
        case 2:
            return h
        default:
            throw new Error(`expected value from 0~255, got: ${v}`)
    }
}

export class Color extends String {
    constructor(s: string) {
        if (/^#[\dA-Fa-f]{8}$/.test(s) || /^#[\dA-Fa-f]{6}$/.test(s)) {
            super(s);
            return;
        }

        if (/^#[\dA-Fa-f]{4}$/.test(s) || /^#[\dA-Fa-f]{3}$/.test(s)) {
            super(
                `#${s
                    .substring(1)
                    .split("")
                    .map((c) => `${c}${c}`)
                    .join("")}`
            )
            return;
        }

        super("");
        throw new Error(`expected color hex string, got '${s}'`);
    }

    get red() {
        return parseInt(this.substring(1, 3), 16);
    }

    get green() {
        return parseInt(this.substring(3, 5), 16);
    }

    get blue() {
        return parseInt(this.substring(5, 7), 16);
    }

    get alpha() {
        return this.length === 9 ? parseInt(this.substring(7, 9), 16) / 255 : 1.0;
    }

    get hasAlpha() {
        return this.length === 9;
    }

    public static fromRGB(r: number, g: number, b: number): Color {
        return new Color(`#${toHex(r)}${toHex(g)}${toHex(b)}`);
    }

    toRGB(): RGB {
        return [this.red, this.green, this.blue];
    }

    public static fromRGBA(r: number, g: number, b: number, a: number): Color {
        return new Color(`${Color.fromRGB(r, g, b)}${toHex(Math.floor(a * 255))}`);
    }

    toRGBA(): RGBA {
        return [this.red, this.green, this.blue, this.alpha];
    }

    opaque(color: Color): Color {
        return Color.fromRGBA(...color.toRGB(), 1).mix(this, color.alpha * 100);
    }

    mix(color: Color, weight = 50): Color {
        const p = clamp(weight, 0.0, 100.0) / 100.0
        const w = 2.0 * p - 1.0
        const a = this.alpha - color.alpha
        const w1 = ((w * a === -1.0 ? w : (w + a) / (1.0 + w * a)) + 1.0) / 2.0
        const w2 = 1.0 - w1
        const red = Math.round(this.red * w1 + color.red * w2)
        const green = Math.round(this.green * w1 + color.green * w2)
        const blue = Math.round(this.blue * w1 + color.blue * w2)
        const alpha = this.alpha * p + color.alpha * (1.0 - p)
        return this.hasAlpha || color.hasAlpha || alpha !== 1.0
            ? Color.fromRGBA(red, green, blue, alpha)
            : Color.fromRGB(red, green, blue)
    }

    luminance(): number {
        const [r, g, b] = this.toRGB().map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return r * 0.2126 + g * 0.7152 + b * 0.0722;
    }

    contrastRatio(color: Color): number {
        const l1 = this.luminance();
        const l2 = this.opaque(color).luminance();
        return l1 >= l2 ? (l1 + .05)/(l2 + .05) : (l2 + .05)/(l1 + .05);
    }

    colorContrast(): Color {
        const foregrounds = [new Color(COLOR_CONTRAST_LIGHT), new Color(COLOR_CONTRAST_DARK), new Color(WHITE), new Color(BLACK)];
        let maxRatio = 0;
        let maxRatioColor = foregrounds[0];

        for (const color of foregrounds) {
            const contrastRatio = this.contrastRatio(color);
            if (contrastRatio >= 4.5) {
                return color;
            } else if (contrastRatio > maxRatio) {
                maxRatio = contrastRatio;
                maxRatioColor = color;
            }
        }

        return maxRatioColor;
    }
}

export const mix = (color1: string, color2: string, weight = 50): string =>
    new Color(color1).mix(new Color(color2), weight).toString();

export const tintColor = (color: string, weight: number): string =>
    mix(WHITE, color, weight);

export const shadeColor = (color: string, weight: number): string =>
    mix(BLACK, color, weight);

export const luminance = (color: string): number =>
    new Color(color).luminance();

export const contrastRatio = (background: string, foreground = WHITE): number =>
    new Color(background).contrastRatio(new Color(foreground));

export const colorContrast = (color: string): string =>
    new Color(color).colorContrast().toString();

export const toRGB = (color: string): string =>
    new Color(color).toRGB().join(", ")

export const toRGBA = (color: string): string =>
    new Color(color).toRGBA().join(", ")
