import { keyBy } from "lodash-es";
import { PositionInElementHierarchy, PositionInMap } from "modules/components/MouseRenderer/mouse-movement-tracking";
import { createSelector } from "@reduxjs/toolkit";
import { ParticipantInfo, SessionState } from "../../types";
import { participants } from "./participants";
import { participantCursors } from "./participantCursors";

export function createFilteredParticipantCursorsWithParticipantInfo(predicate: (participantId: string) => boolean): (
    s: SessionState,
) => Array<
    {
        mousePosition: PositionInElementHierarchy | PositionInMap | null;
    } & ParticipantInfo
> {
    return createSelector([participantCursors, participants], (cursorDict, participants) => {
        const participantDict = keyBy(participants, (p) => p.id);
        return Object.entries(cursorDict)
            .filter(([participantId, _cursorPosition]) => predicate(participantId) && !!participantDict[participantId])
            .map(([filteredParticipantId, cursorPosition]) => {
                return {
                    participantId: filteredParticipantId,
                    mousePosition: cursorPosition,
                    ...participantDict[filteredParticipantId],
                };
            });
    });
}
