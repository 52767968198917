import { StepExtraOptions } from "../../../content-types";
import { SessionState } from "../../../types";
import { getStepScript } from "./getStepScript";

export function createCurrentStepOptionsSelector(): (s: SessionState) => StepExtraOptions | null {
    return (sessionState: SessionState): StepExtraOptions | null => {
        const stepKey = sessionState.currentStep;
        if (stepKey === "exit") {
            const exitDefinition = getStepScript("exit", sessionState, "exit");
            return exitDefinition?.options ?? null;
        } else if (stepKey.stepKey === "ProgressCheck") {
            return null;
        }
        const stepDefinition = sessionState.script.sections[stepKey.sectionKey].steps[stepKey.stepKey];

        return stepDefinition.options || null;
    };
}
