import { RotateNavigator, SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = Pick<RotateNavigator, "currentNavigator">;

const createRotateNavigator = (args: Args): SessionEvent => {
    return {
        type: "ROTATE_NAVIGATOR",
        uuid: uuidv4(),
        currentNavigator: args.currentNavigator,
    };
};

export default createRotateNavigator;
