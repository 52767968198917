import React, { FC, useCallback, useState } from "react";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ConfirmForm from "./ConfirmForm";
import Alert from "react-bootstrap/Alert";
import Markdown from "../RichText/Markdown";
import { useLocalizedString } from "modules/client/localization";

type Props = {
    title: string;
    body: string;
    action: () => void;
    onCloseCb?: () => void;
    confirmValue?: string;
};

const ConfirmationDialogue: FC<Props> = ({ title, body, action, onCloseCb, confirmValue }) => {
    const [show, setShow] = useState(true);
    const [confirmInput, setConfirmInput] = useState("");
    const [confirmCheckbox, setConfirmCheckbox] = useState(false);
    const [confirmError, setConfirmError] = useState<string | null>(null);
    const localized = useLocalizedString();

    const onChangeConfirmInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmInput(e.currentTarget.value);
        },
        [setConfirmInput],
    );
    const onChangeConfirmCheckbox = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmCheckbox(e.target.checked);
        },
        [setConfirmCheckbox],
    );

    const onClose = useCallback(() => {
        setShow(false);

        if (!!onCloseCb) {
            onCloseCb();
        }
    }, [setShow, onCloseCb]);

    const onConfirm = useCallback(() => {
        if (!confirmCheckbox) {
            setConfirmError(localized("confirmDialog_error_checkBox"));
            return;
        }

        if (!!confirmValue && confirmValue.trim() !== confirmInput.trim()) {
            setConfirmError(localized("confirmDialog_error_confirmValue", confirmValue));
            return;
        }
        setConfirmError(null);
        setShow(false);
        action();
    }, [confirmCheckbox, confirmValue, confirmInput, action, setConfirmError, localized]);

    return (
        <Modal show={show} contentClassName="confirmation-dialog">
            <Modal.Header closeButton onHide={onClose}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Markdown text={body} />
                {!!confirmValue && <Markdown text={localized("confirmDialog_confirmValuePrompt", confirmValue)} />}
                {!!confirmError && (
                    <Alert variant="warning">
                        <Markdown text={confirmError} />
                    </Alert>
                )}
                <ConfirmForm
                    confirmInputValue={confirmInput}
                    onChangeConfirmInputValue={onChangeConfirmInput}
                    confirmCheckboxValue={confirmCheckbox}
                    onChangeConfirmCheckboxValue={onChangeConfirmCheckbox}
                    confirmValue={confirmValue}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {localized("confirmDialog_closeButton")}
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    {localized("confirmDialog_confirmButton")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ConfirmationDialogue;
