import { updateSessionState } from "modules/utils";
import { UpdateIsIdle } from "../actions-types";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState, payload: UpdateIsIdle): ApplicationState => {
    return updateSessionState(state, (session) => {
        let result: SessionStateContainer | null = null;

        if (session && session.state === "READY") {
            result = {
                ...session,
                isIdle: payload.isIdle,
            };
        } else {
            result = session;
        }

        return result;
    });
};
