import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    hotspotUuid: string;
};

const createHotspotHideDetails = (args: Args): SessionEvent => {
    return {
        type: "HOTSPOT__HIDE_DETAILS",
        hotspotUuid: args.hotspotUuid,
        uuid: uuidv4(),
    };
};
export default createHotspotHideDetails;
