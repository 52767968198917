import * as Sentry from "@sentry/react";
import { configureStore, Store } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { Action } from "./client/actions-types";
import { ApplicationState } from "./client/application-state";
import { IClientSocketManager } from "./client/client-socket-manager";
import { reducer } from "./client/reducer";
import { createRootSaga } from "./client/saga";
import { IPublicClientApplication } from "@azure/msal-browser";
import { createSessionReportSaga } from "./client/saga/reportSaga";
import { watchUpdateSession } from "./client/saga/inPersonSaga";

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    normalizeDepth: 10,
});
export type ApplicationStore = Store<ApplicationState, Action>;
export function createStore(
    connectionManager: IClientSocketManager,
    msalInstance?: IPublicClientApplication,
): ApplicationStore {
    const sagaMiddleware = createSagaMiddleware();
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        actionTransformer: (action: Action) => {
            if (action.type === "DEBUG") {
                return null; // Do not send to Sentry
            }
            return action;
        },
    });
    /*  custom middleware disables thunk by default
        configureStore enables composeWithDevTools by default
    */

    const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: false,
                serializableCheck: false,
                immutableCheck: false,
            }).concat(sagaMiddleware),
        enhancers: [sentryReduxEnhancer],
    });

    const rootSaga = createRootSaga(connectionManager);
    sagaMiddleware.run(rootSaga);
    const reportSaga = createSessionReportSaga(msalInstance);
    sagaMiddleware.run(reportSaga);
    sagaMiddleware.run(watchUpdateSession);
    return store;
}
