import { StepExtraOptions } from "../../../content-types";
import { SessionState, StepAddress } from "../../../types";

export function getStepOptions(
    state: Pick<SessionState, "script">,
    stepKey: StepAddress,
): StepExtraOptions | undefined {
    if (stepKey === "exit" || stepKey.stepKey === "ProgressCheck") {
        return undefined;
    }

    const sectionIndex = stepKey.sectionKey;
    const section = state.script.sections[sectionIndex];

    if (!section) {
        throw new Error(`Experience ${state.script.id} does not have a section at index ${sectionIndex}`);
    }
    return section.steps[stepKey.stepKey].options;
}
