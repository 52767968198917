import { MainStepAddress, SessionState, StepAddress, StepIntroAddress } from "../../types";
import { isIntroStepAddress } from "../../utils";

export function stepIsFirst(step: StepAddress | StepIntroAddress): boolean {
    if (isIntroStepAddress(step)) {
        return false;
    }
    return step !== "exit" && step.sectionKey === 0 && step.stepKey === 0;
}

export function firstInPersonStepAddress(state: SessionState): MainStepAddress {
    let sectionKey = 0;
    let stepKey = 0;
    const sections = state.script.sections;
    const sectionsLength = sections.length;

    const isInPersonStepEnabled = (sectionKey: number, stepKey: number) => {
        const step = sections[sectionKey]?.steps[stepKey];
        return step && step.options?.enableInPersonDataCollection;
    };

    while (sectionKey < sectionsLength) {
        const stepsInCurrentSection = sections[sectionKey].steps.length;

        if (isInPersonStepEnabled(sectionKey, stepKey)) {
            break;
        }

        stepKey += 1;
        if (stepKey >= stepsInCurrentSection) {
            sectionKey += 1;
            stepKey = 0;
        }
    }

    return { sectionKey, stepKey };
}

export function isFirstInPersonStep(currentStep: MainStepAddress, firstInPersonStepAddress: MainStepAddress): boolean {
    const { sectionKey, stepKey } = currentStep;
    const { sectionKey: firstInPersonSectionKey, stepKey: firstInPersonStepKey } = firstInPersonStepAddress;

    return sectionKey === firstInPersonSectionKey && stepKey === firstInPersonStepKey;
}
