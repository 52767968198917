import React, { useEffect, useState } from "react";
import L, { LayerGroup } from "leaflet";
import { PreviewHotspot } from "modules/shared/types";
import classNames from "classnames";
import { FaCopy, FaPlus } from "react-icons/fa";
import ReactDOMServer from "react-dom/server";
import { fromMapUnit } from "./utils";
import { v4 } from "uuid";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { useDispatch } from "react-redux";
import { useMapContext } from "./MapContext";

type Props = {
    removePreviewHotspot: (id: number) => void;
    movePreviewHotspot: (movedHotspot: PreviewHotspot) => void;
    pixelSize: [number, number];
    previewHotspots: PreviewHotspot[];
    onIconHover: (value: boolean) => void;
};

const PreviewHotspotLayer: React.FC<Props> = ({
    pixelSize,
    onIconHover,
    previewHotspots,
    movePreviewHotspot,
    removePreviewHotspot,
}) => {
    const { map } = useMapContext();
    const [hotspotPreviewGroup] = useState<LayerGroup>(L.layerGroup);
    const dispatch = useDispatch();

    useEffect(() => {
        map.addLayer(hotspotPreviewGroup);
        hotspotPreviewGroup.clearLayers();

        previewHotspots.forEach((previewHotspot, i) => {
            const buttonClassnames = classNames("btn", "btn-hotspotIconColor");

            const copyIconSvgEl = ReactDOMServer.renderToStaticMarkup(
                <FaCopy className="svg-inline--fa fa-copy fa-w-14" />,
            );
            const plusIconSvgEl = ReactDOMServer.renderToStaticMarkup(
                <FaPlus className="svg-inline--fa fa-plus fa-w-14" />,
            );

            const coordinatesToDisplay = fromMapUnit(
                L.latLng(previewHotspot.mapCoordinates[0], previewHotspot.mapCoordinates[1]),
                pixelSize,
            );

            const previewPaneRawHTML = `
                <div class="preview-pane hotspot" data-testid="hotspot-preview-pane">
                    <div class="coordinates">
                        <div class="title">Hotspot ${i + 1}</div>
                        <div class="coordinates-text">${coordinatesToDisplay[0].toFixed(
                            0,
                        )}, ${coordinatesToDisplay[1].toFixed(0)}</div>
                    </div>
                    <div class="copy-button">${copyIconSvgEl}</div>
                </div>
            `;

            const hotspotPreviewRawHTML = `
            <button class="${buttonClassnames}" data-testid="hotspot">${plusIconSvgEl}</button>`;

            const hotspotIcon = L.divIcon({
                html: hotspotPreviewRawHTML,
                iconAnchor: [20, 20],
                className: "hotspot",
            });

            const previewHotspotMarker = L.marker(previewHotspot.mapCoordinates, {
                icon: hotspotIcon,
                draggable: true,
            }).addTo(hotspotPreviewGroup);

            const previewPaneIcon = L.divIcon({
                html: previewPaneRawHTML,
                className: "hotspot-preview-pane-container",
            });

            const previewPaneMarker = L.marker(previewHotspot.mapCoordinates, {
                icon: previewPaneIcon,
            }).addTo(hotspotPreviewGroup);

            previewHotspotMarker.on("click", () => {
                onIconHover(false);
                removePreviewHotspot(previewHotspot.id);
            });
            previewHotspotMarker.on("mouseover", () => onIconHover(true));
            previewHotspotMarker.on("mouseout", () => onIconHover(false));
            previewHotspotMarker.on("dragstart", () => {
                previewPaneMarker.remove();
            });
            previewHotspotMarker.on("dragend", (e) => {
                const latLng = (e.target as L.Marker).getLatLng();
                movePreviewHotspot({ id: previewHotspot.id, mapCoordinates: [latLng.lat, latLng.lng] });
            });

            previewPaneMarker.on("click", () => {
                dispatch(
                    createParticipantMessageReceived({
                        payload: {
                            id: v4(),
                            message: "Coordinates copied to clipboard",
                            success: true,
                        },
                    }),
                );
                void navigator.clipboard.writeText(`${coordinatesToDisplay[0]}, ${coordinatesToDisplay[1]}`);
            });
            previewPaneMarker.on("mouseover", () => onIconHover(true));
            previewPaneMarker.on("mouseout", () => onIconHover(false));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, previewHotspots, dispatch]);
    return <></>;
};
export default PreviewHotspotLayer;
