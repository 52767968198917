import { getTotalActualTimeByStepState } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { createGetStepStatesForPriorSections } from "../step-state/createGetStepStatesForPriorSections";

export function createTimeDevotedToPreviousSectionsSelector(): (s: SessionState) => number {
    return createSelector([createGetStepStatesForPriorSections()], (stepStates) =>
        getTotalActualTimeByStepState(stepStates),
    );
}
