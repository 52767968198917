import React, { ForwardedRef, forwardRef } from "react";
import "leaflet/dist/leaflet.css";
import "./LeafletMap.scss";

const LeafletMapNode = forwardRef(function LeafletMap(_props, mapElement: ForwardedRef<HTMLDivElement>) {
    return (
        <section
            aria-label="Map Visual Navigation"
            aria-description="Use your arrow keys to pan around the visual."
            ref={mapElement}
            className="leaflet-map-component"
        />
    );
});

export default LeafletMapNode;
