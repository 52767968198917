import {
    clearPrevAndAddNewRoleAssignment,
    getPoolOfEligibleParticipantsForNavigatorRole,
    participantHasRole,
} from "modules/utils";
import { Role } from "../content-types";
import { AssignNavigator } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const ASSIGN_NAVIGATOR: EventReducerDefinition<AssignNavigator, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: AssignNavigator) => {
        const roleHolder = state.participants.find((p) => participantHasRole(p, Role.Navigator));
        const currentNavigator = roleHolder?.id || null;
        const assigneeInfo = state.participants.find((p) => p.id === payload.participantId);

        return !!assigneeInfo && currentNavigator === payload.currentNavigator;
    },
    reducer: (state: SessionState, payload: AssignNavigator) => {
        if (payload.participantId) {
            const { participants, chosenParticipantId } = clearPrevAndAddNewRoleAssignment(state.participants, {
                roleId: Role.Navigator,
                participantId: payload.participantId,
            });

            let poolOfEligibleParticipantsForNavigatorRole = state.poolOfEligibleParticipantsForNavigatorRole;
            if (chosenParticipantId) {
                poolOfEligibleParticipantsForNavigatorRole = getPoolOfEligibleParticipantsForNavigatorRole(
                    participants,
                    state.poolOfEligibleParticipantsForNavigatorRole,
                    chosenParticipantId,
                );
            }
            return {
                ...state,
                poolOfEligibleParticipantsForNavigatorRole,
                participants,
            };
        }

        return state;
    },
};
export default ASSIGN_NAVIGATOR;
