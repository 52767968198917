import React, { useCallback } from "react";
import { Session } from "./types";
import CopyToClipboardButton from "modules/components/CopyToClipboard";
import { format } from "date-fns/format";
import { ParticipantRoute } from "modules/shared/routes";
import { parseISO } from "date-fns";

type Props = {
    session: Session;
    buttonLabel: string;
    languageCode: string;
};

const GenerateUrlRow: React.FC<Props> = (props) => {
    const url = ParticipantRoute.generate(props.session.uuid, props.languageCode);

    const copyLink = useCallback(async () => {
        return navigator.clipboard.writeText(url);
    }, [url]);

    const onCopy = useCallback(() => copyLink(), [copyLink]);

    // parse utc date
    const closes_at_date = parseISO(`${props.session.closes_at}+00:00`);
    return (
        <tr key={props.session.uuid}>
            <td>{format(closes_at_date, "MM/dd/yyyy KK:mm aa")}</td>
            <td>
                <a href={url} target="_blank" rel="noreferrer">
                    {url}
                    <br />
                </a>
            </td>
            <td>
                <CopyToClipboardButton onClick={onCopy}>{props.buttonLabel}</CopyToClipboardButton>
            </td>
        </tr>
    );
};

export default GenerateUrlRow;
