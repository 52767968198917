import { format, isToday, Locale } from "date-fns";
import { format as tzFormat } from "date-fns-tz";
import { useLocalizedString } from "modules/client/localization";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { MdBuildCircle } from "react-icons/md";
import "./maintenance-window.scss";

type Props = {
    timeWindowStart: Date | null;
    timeWindowEnd: Date | null;
    locale: Locale;
};

const MaintenanceWindow: React.FC<Props> = (props) => {
    const { timeWindowStart, timeWindowEnd } = props;
    const localized = useLocalizedString();

    return (
        <>
            {timeWindowStart && timeWindowEnd && (
                <Col className="alert-container">
                    <Alert variant={isToday(timeWindowStart) ? "warning" : "info"} className={"alert-maintenance"}>
                        <Row className={"maintenance-row"}>
                            <Col className="icon-col">
                                <MdBuildCircle size={24} />
                            </Col>
                            <Col>
                                <Alert.Heading className="maintenance-header">
                                    {localized(
                                        "maintenanceWindow_heading",
                                        format(timeWindowStart, "EEEE", { locale: props.locale }),
                                        format(timeWindowStart, "MMMM d, y", { locale: props.locale }),
                                        format(timeWindowStart, "haaa", { locale: props.locale }),
                                        format(timeWindowEnd, "haaa", { locale: props.locale }),
                                        tzFormat(timeWindowEnd, "z", { locale: props.locale }),
                                    )}
                                </Alert.Heading>
                                <p className="maintenance-body">{localized("maintenanceWindow_body")}</p>
                            </Col>
                        </Row>
                    </Alert>
                </Col>
            )}
        </>
    );
};

export default MaintenanceWindow;
