import { getUnixTime } from "date-fns";
import { GoToStepOptionsResolutions, StartExperience, StartExperienceResolutions } from "../session-events";
import { SessionState } from "../types";
import applyStepOptions from "./apply-step-options";
import { EventReducerDefinition } from "./types";

const startExperience: EventReducerDefinition<StartExperience, StartExperienceResolutions | null> = {
    predictable: false,
    checkIfApplicable: (state: SessionState, _payload: StartExperience) => {
        return !state.sessionStartedAt;
    },
    reducer: (state: SessionState, payload: StartExperience, resolution: StartExperienceResolutions | null) => {
        let stateBeforeOptions: SessionState = {
            ...state,
        };

        if (resolution) {
            const goToStepResolution: GoToStepOptionsResolutions = {
                type: "goToStepOptions",
                uuid: resolution.uuid,
                rolesAssigned: resolution.goToStepOptionsResolutions.rolesAssigned,
            };
            stateBeforeOptions = {
                ...stateBeforeOptions,
                sessionStartedAt: resolution.sessionStartedAt,
                timeStepStarted: resolution.sessionStartedAt,
            };
            return applyStepOptions(stateBeforeOptions, payload.options, goToStepResolution);
        }

        return stateBeforeOptions;
    },
};

export default startExperience;
