import { uniq } from "lodash";
import { updateStepStates } from "modules/utils";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import { RevealCardFlipToBack, RevealCardFlipToFront, RevealCardShowDetails } from "../../session-events";
import { SessionState } from "../../types";

export const REVEAL_CARD__SHOW_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState): boolean => {
        const stepState = getStepState("REVEAL_CARD", state);
        if (!stepState?.transient) {
            return false;
        }

        return stepState.transient.modalVisible !== true;
    },
    reducer: (state: SessionState, payload: RevealCardShowDetails): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_CARD", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        viewedCardIds: uniq([...stepState.persistent.viewedCardIds, payload.cardId]),
                    },
                    transient: {
                        ...stepState.transient,
                        modalVisible: true,
                        selectedCardId: payload.cardId,
                    },
                };
            }),
        };
    },
};

export const REVEAL_CARD__HIDE_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState): boolean => {
        const stepState = getStepState("REVEAL_CARD", state);
        if (!stepState?.transient) {
            return false;
        }

        return stepState.transient.modalVisible !== false;
    },
    reducer: (state: SessionState): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_CARD", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {
                        ...stepState.transient,
                        modalVisible: false,
                        selectedCardId: null,
                    },
                };
            }),
        };
    },
};

export const REVEAL_CARD__FLIP_TO_BACK = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: RevealCardFlipToBack): boolean => {
        const stepState = getStepState("REVEAL_CARD", state);
        if (!stepState?.persistent) {
            return false;
        }

        return !stepState.persistent.flippedCardIds.includes(payload.cardId);
    },
    reducer: (state: SessionState, payload: RevealCardFlipToBack): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_CARD", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        viewedCardIds: uniq([...stepState.persistent.viewedCardIds, payload.cardId]),
                        flippedCardIds: uniq([...stepState.persistent.flippedCardIds, payload.cardId]),
                    },
                };
            }),
        };
    },
};

export const REVEAL_CARD__FLIP_TO_FRONT = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: RevealCardFlipToFront): boolean => {
        const stepState = getStepState("REVEAL_CARD", state);
        if (!stepState?.persistent) {
            return false;
        }

        return stepState.persistent.flippedCardIds.includes(payload.cardId);
    },
    reducer: (state: SessionState, payload: RevealCardFlipToFront): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_CARD", state, state.currentStep, (stepState) => {
                const updatedFlippedCardIds = stepState.persistent.flippedCardIds.filter(
                    (flippedCardId) => flippedCardId !== payload.cardId,
                );

                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        flippedCardIds: updatedFlippedCardIds,
                    },
                };
            }),
        };
    },
};
