import { SessionState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { currentStepAddress } from "./currentStepAddress";

export function currentSectionAddress(state: SessionState): number | "exit" | "intro" {
    const currentStep = currentStepAddress(state);
    if (isIntroStepAddress(currentStep)) {
        return "intro";
    }
    return currentStep === "exit" ? "exit" : currentStep.sectionKey;
}
