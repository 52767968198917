import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { createCurrentStepDefinitionSelector } from "../generic/createCurrentStepDefinitionSelector";

export function createParticipantInfoStepMarkupIconsLimitSelector(): (state: SessionState) => number {
    return createSelector([createCurrentStepDefinitionSelector("infoStep")], (stepDef) => {
        if (!stepDef) {
            return 0;
        }
        const markupIconsLimit = stepDef.options?.markupIconsLimit;

        if (!markupIconsLimit) {
            return Infinity;
        }
        return markupIconsLimit;
    });
}
