import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { currentStepAddress } from "./currentStepAddress";
import { endingOptionsSelector } from "../experience-definition/endingOptionsSelector";
import { createGenericStepDefinitionSelector } from "../step-definition/generic/createGenericStepDefinitionSelector";

export function createCurrentStepEntryIdSelector(): (s: SessionState) => string | "exit" {
    return createSelector(
        [createGenericStepDefinitionSelector(), currentStepAddress, endingOptionsSelector],
        (currentStepDefinition, currentStep, endingOptions) => {
            if (currentStep === "exit" && !!endingOptions) {
                return endingOptions.id;
            }

            if (currentStep === "exit") {
                return "exit";
            }

            if (!!currentStepDefinition) {
                return currentStepDefinition.id;
            }

            return "";
        },
    );
}
