import React from "react";
import { Navbar, Container, Button, Image } from "react-bootstrap";
import { BsPersonCircle } from "react-icons/bs";
import { useSignOut, useUserEmail } from "modules/hooks";
import "./top-user-bar.scss";

const TopUserBar = () => {
    const userEmail = useUserEmail();
    const signout = useSignOut();

    return (
        <div className="topuserbar-margin">
            <Navbar bg="secondary" fixed="top">
                <Container className="navbar-container" fluid>
                    <Navbar.Brand href={`${CLIENT_APP_URL}/scheduler`}>
                        <Image src={`${CLIENT_APP_URL}/root-part-of-accenture.png`} fluid />
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <BsPersonCircle size={"1.5em"} className="text-color" />
                        <Navbar.Text className="m-2 text-color">
                            Signed in as: <b>{userEmail}</b>
                        </Navbar.Text>
                        <Button variant="dark" className="m-2 logout-btn" onClick={signout}>
                            Logout
                        </Button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
export default TopUserBar;
