import { getDefaultStepStates } from "modules/utils";
import { scriptSections } from "../selectors/step-definition/generic/scriptSections";
import { ReloadScript, ReloadScriptResolutions } from "../session-events";
import { SessionState } from "../types";

const RELOAD_SCRIPT = {
    predictable: false,
    checkIfApplicable: (
        state: SessionState,
        payload: ReloadScript,
        resolution: ReloadScriptResolutions | null,
    ): boolean => {
        return state.scriptVersion === payload.scriptVersion;
    },

    reducer: (state: SessionState, payload: ReloadScript, resolution: ReloadScriptResolutions | null): SessionState => {
        const scriptVersion = state.scriptVersion + 1;
        if (!resolution) {
            return {
                ...state,
                scriptVersion,
            };
        }

        const script = resolution?.script;
        const stepStates = getDefaultStepStates(script);

        if (state.currentStep === "exit") {
            return {
                ...state,
                scriptVersion,
                script,
                stepStates,
                sessionEndedAt: null,
            };
        }

        let sectionIndex = state.currentStep.sectionKey;

        const sections = scriptSections(state);
        if (sectionIndex >= sections.length) {
            sectionIndex = sections.length - 1;
        }

        let stepIndex = state.currentStep.stepKey;
        const sectionSteps = sections[sectionIndex].steps;
        if (stepIndex === "ProgressCheck") {
            stepIndex = stepIndex;
        } else if (stepIndex >= sectionSteps.length) {
            stepIndex = sectionSteps.length - 1;
        }

        const currentStep = { sectionKey: sectionIndex, stepKey: stepIndex };

        return {
            ...state,
            currentStep,
            scriptVersion,
            script,
            stepStates,
            sessionEndedAt: null,
        };
    },
};
export default RELOAD_SCRIPT;
