import { useLocalizedString } from "modules/client/localization";
import React from "react";
import { Modal, Button } from "react-bootstrap";

type Props = {
    message?: string | JSX.Element;
};
const ErrorModal: React.FC<Props> = (props) => {
    const url = window.location.href;
    const localized = useLocalizedString();

    return (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Modal centered show onHide={() => {}}>
            <Modal.Header>
                <Modal.Title>{localized("vdpErrorModal_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message || localized("vdpErrorModal_message")}
                <p>{localized("vdpErrorModal_body")}</p>
                <div className="d-flex justify-content-center">
                    <Button href={url}>{localized("vdpErrorModal_button")}</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ErrorModal;
