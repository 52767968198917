import { getUnixTime } from "date-fns";
import { getTotalActualTimeByStepState } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { getStepStatesFlattened } from "../step-state/getStepStatesFlattened";
import { currentStepAddress } from "../step-address/currentStepAddress";
import { getTimeStepStarted } from "./getTimeStepStarted";
import { getSessionStartedAt } from "./getSessionStartedAt";
import { createIntroTimeInPacingSelector } from "./createIntroTimeInPacing";

export function createSecondsPassedForAllSectionsSelector(): (s: SessionState) => number {
    return createSelector(
        [
            getStepStatesFlattened(),
            currentStepAddress,
            getTimeStepStarted,
            getSessionStartedAt,
            createIntroTimeInPacingSelector(),
        ],
        (stepStates, currentStep, timeStarted, sessionStartedAt, isIntroTimeActive) => {
            const currentTime = getUnixTime(new Date());
            const isIntroStep = isIntroStepAddress(currentStep);
            let currentStepTime: number;

            if (!sessionStartedAt) {
                currentStepTime = 0;
            } else if (isIntroStep && isIntroTimeActive) {
                currentStepTime = currentTime - timeStarted;
            } else if (currentStep !== "exit" && !isIntroStep && currentStep.stepKey !== "ProgressCheck") {
                currentStepTime = currentTime - timeStarted;
            } else {
                currentStepTime = 0;
            }

            return getTotalActualTimeByStepState(stepStates) + currentStepTime;
        },
    );
}
