import { FaExclamationTriangle, FaExternalLinkAlt } from "react-icons/fa";
import classNames from "classnames";
import { addDays, formatISO } from "date-fns";
import { createSessionsRequestBody } from "modules/client/utils";
import Experience from "modules/database_types/experience";
import { ParticipantRoute } from "modules/shared/routes";
import React, { useState } from "react";
import { Spinner, Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useClientLocale } from "../../client/localization";
import "./PreviewButton.scss";

type Props = {
    experience: Experience;
    disabled: boolean;
};

const PreviewButton: React.FC<Props> = (props) => {
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const locale = useClientLocale();
    let toolTipText = "Preview this experience";

    if (props.experience.status == "unavailable")
        toolTipText =
            "This experience is unavailable, it has either been unpublished or deleted. Republish the experience to use it.";
    else if (props.experience.status == "expired")
        toolTipText =
            "This experience has not been updated in over a year and is expired. Update the experience in Contentful to use it.";
    else if (props.disabled) toolTipText = "Please set the link expiration date in the future to use this experience.";

    const viewPreview = React.useCallback(async () => {
        setIsWorking(true);
        try {
            const closesAt = addDays(new Date(), 1);
            closesAt.setHours(0, 0, 0, 0);
            const response = await fetch(`/api/v1/experiences/${props.experience.uuid}/sessions`, {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: createSessionsRequestBody({
                    number: 1,
                    opens_at: formatISO(new Date()),
                    closes_at: formatISO(closesAt),
                    language_code: locale,
                    data_collection: false,
                    isPreview: true,
                }),
            });

            if (response.ok) {
                const result = (await response.json())[0];
                const joinUrl = ParticipantRoute.generate(result.uuid, "en-US", true);
                window.open(joinUrl, "_blank");
            }
        } finally {
            setIsWorking(false);
        }
    }, [props, locale]);

    if (isWorking) {
        return (
            <div className="preview-button-container">
                <div className="preview-button">
                    <Spinner animation="border" size="sm" />
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={classNames([
                    "preview-button-container",
                    props.disabled && "preview-button-container-disabled",
                ])}
            >
                <OverlayTrigger placement="top" overlay={<Tooltip id={props.experience.uuid}>{toolTipText}</Tooltip>}>
                    <div className="preview-button" onClick={!props.disabled ? viewPreview : undefined}>
                        {props.disabled ? (
                            <span>
                                <FaExclamationTriangle size={15} />
                            </span>
                        ) : (
                            <span>
                                <FaExternalLinkAlt size={15} />
                            </span>
                        )}
                    </div>
                </OverlayTrigger>
            </div>
        );
    }
};

export default PreviewButton;
