import { useDateFnsLocale, useLocalizedString } from "modules/client/localization";
import { useSessionSelector } from "modules/components/SessionRenderer/context";
import { createSecondsPassedForAllSectionsSelector } from "modules/shared/selectors/time-tracking/createSecondsPassedForAllSectionsSelector";
import { createSectionRecommendedTimesSelector } from "modules/shared/selectors/time-tracking/createSectionRecommendedTimesSelector";
import { createSectionNamesSelector } from "modules/shared/selectors/experience-definition/createSectionNamesSelector";
import { createIntroTimeInPacingSelector } from "modules/shared/selectors/time-tracking/createIntroTimeInPacing";
import { createIntroRecommendedSectionTime } from "modules/shared/selectors/time-tracking/getIntroRecommendedSectionTime";
import { formatTimeSectionsDropDown, getTotalRecommendedTime } from "modules/utils";
import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import "./SectionsDisplay.scss";
import SectionsDisplayRow from "./SectionsDisplayRow";
import { is } from "date-fns/locale";

const SectionsDisplay: React.FC = () => {
    const sectionTitles = useSessionSelector(createSectionNamesSelector());
    const recommendedTimes = useSessionSelector(createSectionRecommendedTimesSelector());
    const totalActualTime = useSessionSelector(createSecondsPassedForAllSectionsSelector());
    const isIntroTimeActive = useSessionSelector(createIntroTimeInPacingSelector());
    const introSectionRecommendedTime = useSessionSelector(createIntroRecommendedSectionTime());
    const localized = useLocalizedString();
    const locale = useDateFnsLocale();

    if (!sectionTitles) {
        throw new Error("Sections in script do not have titles.");
    }

    const sectionsMeta = sectionTitles.map((title, i) => {
        return {
            title: title,
            recommendedTime: !!recommendedTimes && !!recommendedTimes[i] ? recommendedTimes[i] : 0,
        };
    });

    const totalRecommendedTime = useMemo(
        () =>
            getTotalRecommendedTime(
                isIntroTimeActive && !!recommendedTimes
                    ? recommendedTimes.concat([introSectionRecommendedTime])
                    : recommendedTimes,
            ),
        [recommendedTimes, isIntroTimeActive, introSectionRecommendedTime],
    );
    const totalRemainingTime = useMemo(
        () => totalRecommendedTime - totalActualTime,
        [totalRecommendedTime, totalActualTime],
    );

    return (
        <Table className="sections-display-component" responsive size="sm" borderless>
            <thead>
                <tr>
                    <th>{localized("vdpHud_infoTableSection")}</th>
                    <th className="spacer" />
                    <th>{localized("vdpHud_infoTableRecommended")}</th>
                    <th className="spacer" />
                    <th>{localized("vdpHud_infoTableActual")}</th>
                    <th className="spacer" />
                    <th>{localized("vdpHud_infoTableRemaining")}</th>
                </tr>
            </thead>
            <tbody>
                {isIntroTimeActive && (
                    <SectionsDisplayRow
                        key={"intro section"}
                        sectionTitle={"Welcome"}
                        sectionKey={"intro"}
                        recommendedTime={introSectionRecommendedTime}
                    />
                )}
                {sectionsMeta.map((meta, i) => (
                    <SectionsDisplayRow
                        key={i}
                        sectionTitle={meta.title}
                        sectionKey={i}
                        recommendedTime={meta.recommendedTime}
                    />
                ))}
                <tr className="totals">
                    <td>{localized("vdpHud_infoTableTotal")}</td>
                    <td className="spacer" />
                    <td className="recommended">{formatTimeSectionsDropDown(totalRecommendedTime, locale)}</td>
                    <td className="spacer" />
                    <td className="actual">{formatTimeSectionsDropDown(totalActualTime, locale)}</td>
                    <td className="spacer" />
                    <td className="remaining">{formatTimeSectionsDropDown(totalRemainingTime, locale)}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default SectionsDisplay;
