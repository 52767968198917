import { createSelector } from "@reduxjs/toolkit";
import { Section } from "../../../content-types";
import { SessionState } from "../../../types";
import { scriptSections } from "./scriptSections";
import { currentSectionAddress } from "../../step-address/currentSectionAddress";

export function createSectionDefinitionForCurrentSectionSelector(): (s: SessionState) => Section | null {
    return createSelector([currentSectionAddress, scriptSections], (currentSection, sectionDefs) => {
        if (currentSection === "exit") {
            return null;
        }
        if (currentSection === "intro") {
            return null;
        }
        const currentSectionDef: Section = sectionDefs[currentSection];
        return currentSectionDef ?? null;
    });
}
