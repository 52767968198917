import { InPersonSessionRoute } from "modules/shared/routes";
import { fetchData } from "./fetchData";
import { addDays, formatISO } from "date-fns";
import { createSessionsRequestBody } from "modules/client/utils";
import { UseFormSetError } from "react-hook-form";
import { SessionState } from "modules/shared/types";

export type FormData = {
    workShopId: string;
    tableId: string;
};
interface ValidateSessionResult {
    isSessionExists: boolean;
    joinUrl: string;
}

const generateSession = async (
    workShopId: string,
    tableId: string,
    experienceId: string,
    dataCollection: boolean,
): Promise<ValidateSessionResult | undefined> => {
    const closesAt = addDays(new Date(), 1);
    closesAt.setHours(0, 0, 0, 0);
    const sessionRequestBody = createSessionsRequestBody({
        number: 1,
        opens_at: formatISO(new Date()),
        closes_at: formatISO(closesAt),
        language_code: "en-US",
        data_collection: dataCollection,
        isPreview: true,
    });
    const response = await fetchData(`/api/v1/experiences/${experienceId}/sessions`, "POST", sessionRequestBody);
    const uuid = response[0].uuid;
    const joinUrl = InPersonSessionRoute.generate(uuid);

    await fetchData(`${CLIENT_WEBSOCKET_URL}/inPersonState/${uuid}`, "POST", { workShopId, tableId, experienceId });
    return { isSessionExists: false, joinUrl };
};

export const validateSession = async (
    workShopId: string,
    tableId: string,
    setError: UseFormSetError<FormData>,
    experienceId: string,
    dataCollection: boolean,
) => {
    try {
        const sessionId = await fetchData(`${CLIENT_WEBSOCKET_URL}/inPersonState/validate`, "POST", {
            workShopId,
            tableId,
            experienceId,
        });

        if (sessionId) {
            const joinUrl = InPersonSessionRoute.generate(sessionId);
            return { isSessionExists: true, joinUrl };
        } else {
            return generateSession(workShopId, tableId, experienceId, dataCollection);
        }
    } catch (errors) {
        if (Array.isArray(errors)) {
            errors.forEach((error) => {
                setError(error.path, { message: error.msg });
            });
        }
    }
};

export const updateSessionCache = async (sessionId: string, updatedState: SessionState) => {
    await fetch(`${CLIENT_WEBSOCKET_URL}/inPersonState/${sessionId}/update`, {
        method: "POST",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(updatedState),
    });
};

export const writeToSessionRepo = async (sessionId: string) => {
    await fetch(`${CLIENT_WEBSOCKET_URL}/inPersonState/${sessionId}/write`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            sessionId,
        }),
    });
};
