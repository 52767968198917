import { createSelector } from "@reduxjs/toolkit";
import { StepExtraOptions } from "../../../content-types";
import { SessionState } from "../../../types";
import { createCurrentStepOptionsSelector } from "./createCurrentStepOptionsSelector";

export function createActivityModalSelector(): (
    s: SessionState,
) => Pick<StepExtraOptions, "modalBackgroundDimmer" | "modalBackgroundBlur"> {
    return createSelector([createCurrentStepOptionsSelector()], (options) => {
        if (!options) {
            return {};
        }
        const { modalBackgroundDimmer, modalBackgroundBlur } = options;
        return { modalBackgroundDimmer, modalBackgroundBlur };
    });
}
