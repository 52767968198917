import { ExperienceScript } from "../../content-types";
import { StepAddress } from "../../types";
import { MainStepAddress } from "../../types";

export function getStepAddressByStepEntryId(script: ExperienceScript, entryId: string): MainStepAddress | null {
    let stepAddress: StepAddress | undefined;
    script.sections.some((section, i) => {
        section.steps.some((step, j) => {
            if (step.id === entryId) {
                stepAddress = { sectionKey: i, stepKey: j };
                return true;
            }
        });
    });

    if (!stepAddress || stepAddress === "exit" || stepAddress.stepKey === "ProgressCheck") {
        return null;
    }

    return stepAddress;
}
