import { ConnectToSessionStartedAction } from "../actions-types";
import { ApplicationState } from "../application-state";

export default (state: ApplicationState, payload: ConnectToSessionStartedAction): ApplicationState => {
    const updatedState: ApplicationState = { ...state };
    updatedState.session = {
        sessionId: payload.sessionId,
        state: "CONNECTING",
        pendingMessages: [],
    };

    return updatedState;
};
