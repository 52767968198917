import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    clickableAreaUuid: string;
};

const createClickableAreaHideDetails = (args: Args): SessionEvent => {
    return {
        type: "CLICKABLE_AREA__HIDE_DETAILS",
        clickableAreaUuid: args.clickableAreaUuid,
        uuid: uuidv4(),
    };
};
export default createClickableAreaHideDetails;
