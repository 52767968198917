import { ComparisonColumn } from "modules/shared/activity/Comparison/types";
import { DragAndDropStepState } from "modules/shared/activity/DragAndDrop/types";
import { Card, Column } from "modules/shared/content-types";

export const getComparisonColumnsFromDragAndDropState = (
    columnEntries: Column[],
    cardEntries: Card[],
    stepState: DragAndDropStepState,
): ComparisonColumn[] => {
    return columnEntries.map((columnEntry, index) => {
        let comparisonColumn: ComparisonColumn = {
            title: columnEntry.title,
            card: null,
        };
        stepState.persistent.cardPositionState.some((cardState) => {
            if (cardState.columnId === `${index}` && cardState.index === 0) {
                const card = cardEntries.find((card) => card.id === cardState.cardId);
                comparisonColumn = {
                    ...comparisonColumn,
                    card: card ?? null,
                };
                return true;
            }
        });
        return comparisonColumn;
    });
};
