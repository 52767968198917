import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import { updateStepStates } from "modules/utils";
import { CardStackWithVisualGoToCard } from "../../session-events";
import { SessionState } from "../../types";

export const CARD_STACK_WITH_VISUAL__GO_TO_CARD = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: CardStackWithVisualGoToCard): boolean => {
        const stepState = getStepState("CARD_STACK_WITH_VISUAL", state);
        if (!stepState?.persistent) {
            return false;
        }
        return stepState.persistent.selectedCardId !== payload.cardId;
    },
    reducer: (state: SessionState, payload: CardStackWithVisualGoToCard): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("CARD_STACK_WITH_VISUAL", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {},
                    persistent: {
                        ...stepState.persistent,
                        selectedCardId: payload.cardId,
                        focusArea: payload.focusArea,
                    },
                };
            }),
        };
    },
};
