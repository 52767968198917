import { createSelector } from "@reduxjs/toolkit";
import { StepDefinition } from "../../../content-types";
import { SessionState } from "../../../types";
import { createSectionDefinitionForCurrentSectionSelector } from "./createSectionDefinitionForCurrentSectionSelector";

export function createStepDefinitionsForCurrentSectionSelector(): (s: SessionState) => StepDefinition[] {
    return createSelector([createSectionDefinitionForCurrentSectionSelector()], (currentSectionDef) => {
        if (!currentSectionDef) {
            return [];
        }
        return currentSectionDef.steps;
    });
}
