import { WebsocketMessage } from "modules/shared/message-types";
import { createSelector } from "@reduxjs/toolkit";
import {
    ApplicationState,
    ContentfulComment,
    ContentfulManagementData,
    ParticipantMessage,
    SessionStateContainer,
    SessionStateContainerConnecting,
    SessionStateContainerReady,
    SessionStateContainerReconnectedWaitingForReincarnation,
    SessionStateContainerReconnecting,
    SessionStateContainerWaitingForInitialState,
} from "../application-state";

export const sessionState = (state: ApplicationState): SessionStateContainer => state.session;

export const activeSessionState = (
    state: ApplicationState,
):
    | SessionStateContainerReady
    | SessionStateContainerReconnecting
    | SessionStateContainerReconnectedWaitingForReincarnation => {
    const session = sessionState(state);
    if (
        session &&
        (session.state === "READY" ||
            session.state === "RECONNECTING" ||
            session.state === "RECONNECTED_WAITING_FOR_REINCARNATION")
    ) {
        return session;
    }
    throw new Error(`Session for ${session?.sessionId} is not ready, state was "${session?.state}"`);
};

export const connectingSessionState = (
    state: ApplicationState,
): SessionStateContainerConnecting | SessionStateContainerWaitingForInitialState | null => {
    const session = sessionState(state);
    if (session && (session.state === "CONNECTING" || session.state === "WAITING_FOR_INITIAL_STATE")) {
        return session;
    }
    return null;
};

export const isReady = (state: ApplicationState): boolean => {
    const session = sessionState(state);
    return !!(session && session.state === "READY");
};

export const isWaitingForReincarnation = (state: ApplicationState): boolean => {
    const session = sessionState(state);
    return !!(session && session.state === "RECONNECTED_WAITING_FOR_REINCARNATION");
};

export const currentParticipant = (state: ApplicationState): string | null => {
    const session = activeSessionState(state);
    if (session.state === "RECONNECTING" || session.state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
        return session.oldParticipantId;
    }
    return session.participantId;
};

export const clientLocale = (state: ApplicationState): string => {
    return state.languageCode;
};

export const createEventsAwaitingResponseSelector = () => {
    return (state: ApplicationState): string[] => {
        return state.eventsAwaitingResolutions ?? [];
    };
};

export const createUncommittedEventsSelector = () => {
    return (state: ApplicationState): WebsocketMessage[] => {
        const session = activeSessionState(state);
        if (session.state === "RECONNECTING" || session.state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
            return [];
        }
        return session.uncommitted;
    };
};

export function createIsAwaitingResponseSelector(): (s: ApplicationState) => boolean {
    return createSelector(
        [createEventsAwaitingResponseSelector(), createUncommittedEventsSelector()],
        (unresolved, uncommitted) => unresolved.length > 0 || uncommitted.length > 0,
    );
}

export function createIsAwaitingServerResolutionSelector(): (state: ApplicationState) => boolean {
    return createSelector([createEventsAwaitingResponseSelector()], (uuids: string[]): boolean => uuids.length > 0);
}

export function sessionContainer(state: ApplicationState): SessionStateContainer {
    return state.session;
}

export function sessionParticipantMessages(state: ApplicationState): ParticipantMessage[] {
    const container = sessionContainer(state);
    return container.state === "READY" ? container.participantMessages : [];
}

export function sessionContentfulManagementData(state: ApplicationState): ContentfulManagementData {
    const container = sessionContainer(state);
    return container.state === "READY" && container.managementData
        ? container.managementData
        : {
              comments: [],
          };
}

export function sessionContentfulManagementComments(state: ApplicationState): ContentfulComment[] {
    const managementData = sessionContentfulManagementData(state);
    return managementData && managementData?.comments ? managementData.comments : [];
}
