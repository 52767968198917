import { uniq } from "lodash";
import { updateStepStates } from "modules/utils";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import {
    ClickableAreaHideDetails,
    ClickableAreaShowDetails,
    HotspotFlipCardToBack,
    HotspotFlipCardToFront,
    HotspotHideDetails,
    HotspotShowDetails,
} from "../../session-events";
import { SessionState } from "../../types";

export const HOTSPOT__SHOW_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: HotspotShowDetails): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }
        return !stepState.transient.modalVisible && !!payload.hotspotUuid;
    },
    reducer: (state: SessionState, payload: HotspotShowDetails): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        viewedHotspotIdxs: uniq([...stepState.persistent.viewedHotspotIdxs, payload.hotspotIdx]),
                    },
                    transient: {
                        ...stepState.transient,
                        modalVisible: true,
                        hotspotUuid: payload.hotspotUuid,
                        selectedHotspotIdx: payload.hotspotIdx,
                    },
                };
            }),
        };
    },
};

export const CLICKABLE_AREA__SHOW_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: ClickableAreaShowDetails): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }
        return !stepState.transient.modalVisible && !!payload.clickableAreaUuid;
    },
    reducer: (state: SessionState, payload: ClickableAreaShowDetails): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        viewedClickableAreaIdxs: uniq([
                            ...stepState.persistent.viewedClickableAreaIdxs,
                            payload.clickableAreaIdx,
                        ]),
                    },
                    transient: {
                        ...stepState.transient,
                        modalVisible: true,
                        clickableAreaUuid: payload.clickableAreaUuid,
                        selectedClickableAreaIdx: payload.clickableAreaIdx,
                    },
                };
            }),
        };
    },
};

export const HOTSPOT__HIDE_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: HotspotHideDetails): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }

        return stepState.transient.modalVisible !== false && stepState.transient.hotspotUuid === payload.hotspotUuid;
    },
    reducer: (state: SessionState): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {
                        ...stepState.transient,
                        modalVisible: false,
                        selectedHotspotIdx: null,
                        hotspotUuid: null,
                        openedCardFlipped: false,
                    },
                };
            }),
        };
    },
};

export const CLICKABLE_AREA__HIDE_DETAILS = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: ClickableAreaHideDetails): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }

        return (
            stepState.transient.modalVisible !== false &&
            stepState.transient.clickableAreaUuid === payload.clickableAreaUuid
        );
    },
    reducer: (state: SessionState): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {
                        ...stepState.transient,
                        modalVisible: false,
                        selectedClickableAreaIdx: null,
                        clickableAreaUuid: null,
                        openedCardFlipped: false,
                    },
                };
            }),
        };
    },
};

export const HOTSPOT__FLIP_CARD_TO_BACK = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: HotspotFlipCardToBack): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }

        return !stepState.transient.openedCardFlipped;
    },
    reducer: (state: SessionState, payload: HotspotFlipCardToBack): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {
                        ...stepState.transient,
                        openedCardFlipped: true,
                    },
                };
            }),
        };
    },
};

export const HOTSPOT__FLIP_CARD_TO_FRONT = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: HotspotFlipCardToFront): boolean => {
        const stepState = getStepState("REVEAL_HOTSPOT", state);
        if (!stepState?.transient) {
            return false;
        }

        return stepState.transient.openedCardFlipped;
    },
    reducer: (state: SessionState, payload: HotspotFlipCardToFront): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("REVEAL_HOTSPOT", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    transient: {
                        ...stepState.transient,
                        openedCardFlipped: false,
                    },
                };
            }),
        };
    },
};
