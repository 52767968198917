import { StepExtraOptions } from "modules/shared/content-types";
import { SessionEvent } from "modules/shared/session-events";
import { StepAddress, StepIntroAddress } from "modules/shared/types";
import { v4 as uuidv4 } from "uuid";

type Args = {
    currentAddress: StepAddress | StepIntroAddress;
    toStep: StepAddress | StepIntroAddress;
    options?: StepExtraOptions;
};
const createGoToStep = (args: Args): SessionEvent => {
    return {
        type: "goToStep",
        from: args.currentAddress,
        to: args.toStep,
        options: args.options,
        uuid: uuidv4(),
    };
};

export default createGoToStep;
