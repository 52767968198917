import {
    clearPrevAndAddNewRoleAssignment,
    getPoolOfEligibleParticipantsForNavigatorRole,
    participantHasRole,
} from "modules/utils";
import { Role } from "../content-types";
import { RotateNavigator, RotateNavigatorResolutions } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const ROTATE_NAVIGATOR: EventReducerDefinition<RotateNavigator, RotateNavigatorResolutions | null> = {
    predictable: false,
    checkIfApplicable: (
        state: SessionState,
        payload: RotateNavigator,
        resolution: RotateNavigatorResolutions | null,
    ) => {
        const roleHolder = state.participants.find((p) => participantHasRole(p, Role.Navigator));
        const currentNavigator = roleHolder?.id || null;

        return !!roleHolder && currentNavigator === payload.currentNavigator;
    },
    reducer: (state: SessionState, payload: RotateNavigator, resolution: RotateNavigatorResolutions | null) => {
        if (resolution && resolution.roleAssigned) {
            const { roleAssigned } = resolution;
            if (roleAssigned) {
                const { participants } = clearPrevAndAddNewRoleAssignment(state.participants, roleAssigned);

                const poolOfEligibleParticipantsForNavigatorRole = getPoolOfEligibleParticipantsForNavigatorRole(
                    participants,
                    state.poolOfEligibleParticipantsForNavigatorRole,
                    roleAssigned.participantId,
                );

                return {
                    ...state,
                    poolOfEligibleParticipantsForNavigatorRole,
                    participants,
                };
            }
        }

        return state;
    },
};
export default ROTATE_NAVIGATOR;
