import { createSelector } from "@reduxjs/toolkit";
import { Appearance, ExperienceScript } from "../../content-types";
import { SessionState } from "../../types";
import { scriptSelector } from "../step-definition/generic/scriptSelector";

export function createScriptAppearanceSelector(): (s: SessionState) => Appearance | undefined {
    return createSelector([scriptSelector], (script: ExperienceScript) => {
        return script.appearance;
    });
}
