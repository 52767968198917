export const fetchData = async (url: string, method: string, body?: { [key: string]: string } | string) => {
    let finalUrl = url;
    const options: RequestInit = {
        method,
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
        },
    };

    if (method.toUpperCase() === "GET" && body && typeof body === "object") {
        const params = new URLSearchParams();
        for (const key in body) {
            params.append(key, body[key]);
        }
        finalUrl = `${url}?${params.toString()}`;
    } else if (body) {
        options.body = typeof body === "string" ? body : JSON.stringify(body);
    }

    const response = await fetch(finalUrl, options);

    if (!response.ok) {
        const errorResponse = await response.json();
        throw errorResponse.errors;
    }

    return response.json();
};
