import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "../actions-types";
import { SendSessionReportRequest } from "../actions-types/sessionReportActionTypes";
import {
    SessionBasicInfoReportData,
    SessionBasicInfoFilterOptions,
    ActivityBasicInfoFilterOptions,
    ActivityBasicInfoReportData,
    ActivityBasicInfoAggregatedFilterOptions,
    ActivityBasicInfoAggregatedReportData,
    MultipleChoiceBasicInfoFilterOptions,
    MultipleChoiceBasicInfoReportData,
    MultipleChoiceBasicInfoAggregatedFilterOptions,
    MultipleChoiceBasicInfoAggregatedReportData,
    DragAndDropBasicInfoReportData,
    DragAndDropBasicInfoFilterOptions,
    DragAndDropBasicInfoAggregatedReportData,
    DragAndDropBasicInfoAggregatedFilterOptions,
    EditableCardBasicInfoReportData,
    EditableCardBasicInfoFilterOptions,
} from "../session-report-state";
import { IPublicClientApplication } from "@azure/msal-browser";
import createReceiveSessionReportError from "../event-factories/session-report/createReceiveSessionReportError";
import createReceiveSessionReportSuccess from "../event-factories/session-report/createReceiveSessionReportSuccess";
import {
    handleSessionBasicInfoRequest,
    handleActivityBasicInfoRequest,
    handleMultipleChoiceBasicInfoRequest,
    handleMultipleChoiceBasicInfoAggregatedRequest,
    handleDragAndDropBasicInfoRequest,
    handleActivityBasicInfoAggregatedRequest,
    handleDragAndDropBasicInfoAggregatedRequest,
    handleEditableCardBasicInfoRequest,
} from "../services/session-repository";
import { UnprocessableContentError } from "../services/session-repository/errorClasses";

export function* retrieveReportResults(msalInstance: IPublicClientApplication, payload: SendSessionReportRequest) {
    switch (payload.filters.type) {
        case "SessionBasicInfo":
        case "MultipleChoiceBasicInfo":
        case "ActivityBasicInfo":
        case "DragAndDropBasicInfo":
        case "EditableCardBasicInfo":
            yield call(retrieveReportResultsNonAggregated, msalInstance, payload);
            break;
        case "ActivityBasicInfoAggregated":
        case "MultipleChoiceBasicInfoAggregated":
        case "DragAndDropBasicInfoAggregated":
            yield call(retrieveReportResultsAggregated, msalInstance, payload);
            break;
        default:
            throw new Error("Unhandled session report type");
    }
}

export function* retrieveReportResultsNonAggregated(
    msalInstance: IPublicClientApplication,
    payload: SendSessionReportRequest,
) {
    switch (payload.filters.type) {
        case "SessionBasicInfo":
            try {
                const reportData: Pick<SessionBasicInfoReportData, "data"> = yield call(
                    handleSessionBasicInfoRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: SessionBasicInfoFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        { type: "SessionBasicInfo", data: reportData.data },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        case "MultipleChoiceBasicInfo":
            try {
                const reportData: Pick<MultipleChoiceBasicInfoReportData, "data"> = yield call(
                    handleMultipleChoiceBasicInfoRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: MultipleChoiceBasicInfoFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        { type: "MultipleChoiceBasicInfo", data: reportData.data },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        case "ActivityBasicInfo":
            try {
                const reportData: Pick<ActivityBasicInfoReportData, "data"> = yield call(
                    handleActivityBasicInfoRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: ActivityBasicInfoFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        { type: "ActivityBasicInfo", data: reportData.data },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        case "DragAndDropBasicInfo":
            try {
                const reportData: Pick<DragAndDropBasicInfoReportData, "data"> = yield call(
                    handleDragAndDropBasicInfoRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: DragAndDropBasicInfoFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        { type: "DragAndDropBasicInfo", data: reportData.data },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        case "EditableCardBasicInfo":
            try {
                const reportData: Pick<EditableCardBasicInfoReportData, "data"> = yield call(
                    handleEditableCardBasicInfoRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: EditableCardBasicInfoFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        { type: "EditableCardBasicInfo", data: reportData.data },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        default:
            throw new Error("Unhandled session report type");
    }
}

export function* retrieveReportResultsAggregated(
    msalInstance: IPublicClientApplication,
    payload: SendSessionReportRequest,
) {
    switch (payload.filters.type) {
        case "ActivityBasicInfoAggregated":
            try {
                const reportData: Pick<ActivityBasicInfoAggregatedReportData, "data" | "sessionCount"> = yield call(
                    handleActivityBasicInfoAggregatedRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: ActivityBasicInfoAggregatedFilterOptions },
                );

                yield put(
                    createReceiveSessionReportSuccess(
                        {
                            type: "ActivityBasicInfoAggregated",
                            data: reportData.data,
                            sessionCount: reportData.sessionCount,
                        },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;

        case "DragAndDropBasicInfoAggregated":
            try {
                const reportData: Pick<DragAndDropBasicInfoAggregatedReportData, "data" | "sessionCount"> = yield call(
                    handleDragAndDropBasicInfoAggregatedRequest,
                    msalInstance,
                    payload as SendSessionReportRequest & { filters: DragAndDropBasicInfoAggregatedFilterOptions },
                );
                yield put(
                    createReceiveSessionReportSuccess(
                        {
                            type: "DragAndDropBasicInfoAggregated",
                            data: reportData.data,
                            sessionCount: reportData.sessionCount,
                        },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        case "MultipleChoiceBasicInfoAggregated":
            try {
                const reportData: Pick<MultipleChoiceBasicInfoAggregatedReportData, "data" | "sessionCount"> =
                    yield call(
                        handleMultipleChoiceBasicInfoAggregatedRequest,
                        msalInstance,
                        payload as SendSessionReportRequest & {
                            filters: MultipleChoiceBasicInfoAggregatedFilterOptions;
                        },
                    );
                yield put(
                    createReceiveSessionReportSuccess(
                        {
                            type: "MultipleChoiceBasicInfoAggregated",
                            data: reportData.data,
                            sessionCount: reportData.sessionCount,
                        },
                        payload.filters,
                    ),
                );
            } catch (e) {
                if (e instanceof UnprocessableContentError) {
                    yield put(createReceiveSessionReportError(e.getFieldErrors(), e.message));
                } else if (e instanceof Error) {
                    yield put(createReceiveSessionReportError({}, e.message));
                } else {
                    yield put(createReceiveSessionReportError({}));
                }
            }
            break;
        default:
            throw new Error("Unhandled session report type");
    }
}

export function createSessionReportSaga(msalInstance?: IPublicClientApplication): () => Generator {
    function* sendSessionReportRequest(payload: Action) {
        if (!msalInstance) {
            throw new Error(
                "No MSAL instance provided with which to retrieve an authorization token for completing SEND_SESSION_REPORT_REQUEST",
            );
        }

        if (payload.type !== "SEND_SESSION_REPORT_REQUEST") {
            throw new Error(`Invalid payload for SEND_SESSION_REPORT_REQUEST: ${payload.type}`);
        }

        yield call(retrieveReportResults, msalInstance, payload);
    }

    function* sessionReportSaga() {
        yield takeLatest<Action>("SEND_SESSION_REPORT_REQUEST", sendSessionReportRequest);
    }

    return sessionReportSaga;
}
