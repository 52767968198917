import React, { Suspense } from "react";
import { useLocalizedString } from "modules/client/localization";
import {
    Exit,
    DragAndDropStep,
    MultipleChoiceStep,
    StepDefinition,
    StepDefinitionKey,
    StepRecord,
    TextEntryStep,
} from "modules/shared/content-types";
import { ExitStepAddress, SessionState } from "modules/shared/types";
import LoadingBlock from "modules/components/LoadingBlock";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules/client/application-state";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import InPersonNavigation from "modules/components/InPersonExperience/InPersonNavigation";
import { Container, Row } from "react-bootstrap";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";

const TextEntryActivity: any = React.lazy(() => import("./InPersonTextEntryActivity"));
const MultipleChoiceActivity: any = React.lazy(() => import("./InPersonMultipleChoiceActivity"));
const ExitActivity: any = React.lazy(() => import("./InPersonExit"));
const DragAndDropActivity: any = React.lazy(() => import("./InPersonDragAndDropActivity"));

const renderActivity = <ContentType extends StepDefinitionKey>(
    currentStep: StepDefinition & StepRecord<ContentType>,
    noActivityFoundText: string,
) => {
    switch (currentStep.contentType) {
        case "textEntryStep":
            return <TextEntryActivity {...(currentStep as TextEntryStep)} />;
        case "multipleChoiceStep":
            return <MultipleChoiceActivity {...(currentStep as MultipleChoiceStep)} />;
        case "dragAndDropStep":
            return <DragAndDropActivity {...(currentStep as DragAndDropStep)} />;
        case "cardStackWithVisualStep":
        case "revealCardStep":
        case "matchingStep":
        case "recallStep":
        case "editableCardStep":
        case "singleAssetStep":
        case "comparisonStep":
        case "videoStep":
        case "progressCheck":
        case "infoStep":
            return <>{noActivityFoundText}</>;
        case "exit":
            return <ExitActivity {...(currentStep as Exit)} />;
        default:
            return <>{noActivityFoundText}</>;
    }
};

const InPersonActivites = () => {
    const localized = useLocalizedString();
    const state = useSelector(
        (state: ApplicationState): boolean | SessionState =>
            state.session.state === "READY" && state.session.effectiveState,
    ) as SessionState;

    const currentStep = createGenericStepDefinitionSelector()(state as SessionState);
    const currentActivityType = currentStep?.contentType;
    const currentAddress = currentStepAddress(state);

    const exit: ExitStepAddress = "exit";

    const noActivityFoundText = localized("vdpActivity_notFound", currentActivityType);

    return (
        <Container className="d-flex flex-column">
            <Row>
                <Suspense fallback={<LoadingBlock />}>{renderActivity(currentStep, noActivityFoundText)}</Suspense>
            </Row>
            <hr />
            <Row className="mt-auto">{currentAddress !== exit && <InPersonNavigation />}</Row>
        </Container>
    );
};

export default InPersonActivites;
