/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { concat, each, filter, identity, omit } from "lodash-es";

export enum ErrorLevel {
    critical = "critical",
    error = "error",
    warning = "warning",
    info = "info",
    debug = "debug",
}

const rankedErrorLevels: ErrorLevel[] = [
    ErrorLevel.debug,
    ErrorLevel.info,
    ErrorLevel.warning,
    ErrorLevel.error,
    ErrorLevel.critical,
];

export type Type = {
    decorator: DecoratorFn;
    critical: LogWriterFn;
    error: LogWriterFn;
    warning: LogWriterFn;
    info: LogWriterFn;
    debug: LogWriterFn;
};

interface MessageWithLevel {
    message?: LogRecord;
    level?: ErrorLevel;
}

// Log messages always have at least a message, plus anything else if the caller wants
export type LogRecord = { message: string };

type LogWriterFn = (...messages: any[]) => void;
type DecoratorFn = (message: LogRecord) => LogRecord;

const NullLogWriterFn: LogWriterFn = (...messages: any[]) => {
    return {};
};
const NullDecoratorFn: DecoratorFn = identity;

function makeWrapper(level: ErrorLevel, transform?: DecoratorFn): LogWriterFn {
    const logThreshold = process.env.LOG_LEVEL as ErrorLevel;
    const logThresholdIdx = rankedErrorLevels.indexOf(logThreshold);

    if (logThresholdIdx >= 0 && rankedErrorLevels.indexOf(level) < logThresholdIdx) {
        return NullLogWriterFn;
    }

    return (...messages) => {
        const normalizedMessage = normalizeMessage(messages);
        const decoratedMessages = transform ? transform(normalizedMessage) : normalizedMessage;

        if (!__TEST__) {
            logByLevel(level, decoratedMessages);
        }

        //   if (
        //     rankedErrorLevels.indexOf(level) >=
        //     rankedErrorLevels.indexOf(notifyRollbarThreshold)
        //   ) {
        //     ErrorNotifier[level].apply(ErrorNotifier, [
        //       decoratedMessages.message,
        //       omit(decoratedMessages, ["message"]),
        //     ]);
        //   }
    };
}

// export for testing purposes only, otherwise private
export function _replaceErrors(key: any, value: any) {
    if (value instanceof Error) {
        const error: any = {};
        Object.getOwnPropertyNames(value).forEach(function (key) {
            error[key] = (value as any)[key];
        });
        return error;
    }
    return value;
}

function logByLevel(level: ErrorLevel, message: LogRecord) {
    let writer: (message?: any, ...x: any[]) => void = console.log;
    switch (level) {
        case ErrorLevel.warning:
            writer = console.warn;
            break;
        case ErrorLevel.error:
        case ErrorLevel.critical:
            writer = console.error;
            break;
    }

    const messageWithLevel: MessageWithLevel = { message, level };

    if (__DEV__) {
        // if in dev and it is long, print out in a pretty way by:
        //    * putting the text first, outside json
        //    * and by indenting w/ newlines, if it is a long message
        const prose = messageWithLevel.message;
        delete messageWithLevel.message; // we'll output at the beginning, not in the object
        delete messageWithLevel.level; // output at the beginning, not in the object

        const renderedString = JSON.stringify(messageWithLevel, _replaceErrors);
        const hasJsonProperties = renderedString !== "{}";

        if (hasJsonProperties) {
            writer(
                level.toLocaleUpperCase(),
                prose,
                hasJsonProperties
                    ? JSON.stringify(messageWithLevel, _replaceErrors, renderedString.length > 80 ? 2 : 0)
                    : undefined,
            );
        } else {
            writer(level.toLocaleUpperCase(), prose);
        }
    } else {
        const renderedString = JSON.stringify(messageWithLevel);
        writer(renderedString);
    }
}

export function normalizeMessage(args: any[]): LogRecord {
    const result: LogRecord = {
        message: "",
    };

    each(args, (arg: any) => {
        if (typeof arg === "string" || arg instanceof String) {
            const nonblank = filter([result.message, `${arg}`], (s) => s != "");
            result.message = nonblank.join("\n");
        } else if (arg instanceof Error) {
            const nonblank = filter([result.message, `${arg.message}`], (s: any) => s != "");
            result.message = nonblank.join("\n");

            // if you have a log that has multiple exception arguments the output
            // might be a bit weird, but we'll at least make sure it gets there.
            if ((result as any)["exception"]) {
                const allExceptions = concat([arg], (result as any)["exception"]);
                (result as any)["exception"] = allExceptions;
            } else {
                (result as any)["exception"] = arg;
            }
        } else if (typeof arg === "object") {
            const argWithoutMessage = omit(arg, ["message"]);
            const message = arg["message"];
            Object.assign(result, argWithoutMessage);
            if (message && message != "") {
                const nonblank = filter([result.message, `${message}`], (s: any) => s != "");
                result.message = nonblank.join("\n");
            }
        }
    });

    return result;
}

export function makeDecoratedLogger(logger: Type, decorator: DecoratorFn) {
    return {
        decorator: (message: LogRecord) => decorator(logger.decorator(message)),
        critical: makeWrapper(ErrorLevel.critical, decorator),
        error: makeWrapper(ErrorLevel.error, decorator),
        warning: makeWrapper(ErrorLevel.warning, decorator),
        info: makeWrapper(ErrorLevel.info, decorator),
        debug: makeWrapper(ErrorLevel.debug, decorator),
    };
}

export const NullLogger: Type = {
    decorator: NullDecoratorFn,
    critical: NullLogWriterFn,
    error: NullLogWriterFn,
    warning: NullLogWriterFn,
    info: NullLogWriterFn,
    debug: NullLogWriterFn,
};

export const Logger: Type = {
    decorator: NullDecoratorFn,
    critical: makeWrapper(ErrorLevel.critical),
    error: makeWrapper(ErrorLevel.error),
    warning: makeWrapper(ErrorLevel.warning),
    info: makeWrapper(ErrorLevel.info),
    debug: makeWrapper(ErrorLevel.debug),
};
