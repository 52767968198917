import { SOCKET_IO_EVENT_NAME, WebsocketMessage, WebsocketMessageWithResolution } from "modules/shared/message-types";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";

export type WsHandle = {
    id: string;
    socket: Socket<DefaultEventsMap, DefaultEventsMap>;
    dispatch: (e: WebsocketMessage) => void;
};

export type ReceiveFn = (e: WebsocketMessageWithResolution) => void;
export type ErrorFn = (e: unknown) => void;
export type ReconnectFn = (d: Dispatcher) => void;

export type Dispatcher = {
    id: string;
    socket: Socket<DefaultEventsMap, DefaultEventsMap>;
    dispatch: (e: WebsocketMessage) => void;
};

function createDispatcher(socketClient: Socket) {
    return {
        id: socketClient.id,
        socket: socketClient,

        dispatch: (e: WebsocketMessage) => {
            socketClient.emit(e.type, e);
        },
    };
}

const connect = (params: {
    sessionKey: string;
    receiver: {
        onReceive: ReceiveFn;
        onError: ErrorFn;
        onReconnect: ReconnectFn;
    };
}): Promise<WsHandle> => {
    return new Promise((resolve, reject) => {
        try {
            const socketClient = io(`${CLIENT_WEBSOCKET_URL}/table/${params.sessionKey}`);

            let socketId: string | null = null;

            socketClient.on("connect", () => {
                const dispatcher = createDispatcher(socketClient);
                resolve(dispatcher);

                if (socketId != null) {
                    console.warn(`Reconnected socket ${socketId} as ${socketClient.id}`);
                    params.receiver.onReconnect(dispatcher);
                }
                socketId = socketClient.id;

                socketClient.on(SOCKET_IO_EVENT_NAME, (payload: WebsocketMessageWithResolution) => {
                    params.receiver.onReceive(payload);
                });

                socketClient.on("disconnect", (reason: Socket.DisconnectReason) => {
                    if (reason !== "io client disconnect") {
                        console.warn("Client disconnected from server unexpectedly!");
                        params.receiver.onError(reason);
                    }
                });

                socketClient.on("error", (payload: unknown) => {
                    console.warn("Websocket error:", payload);
                    params.receiver.onError(payload);
                });
            });
        } catch (e) {
            reject(e);
        }
    });
};

export default { connect };
