import { Icon } from "../../../activity/Markup/types";
import { SessionState } from "../../../types";
import { getStepState } from "../../step-state/getStepState";

export function assetMarkupIconsSelector(state: SessionState): Icon[] | null {
    const currentStepState = getStepState("SINGLE_ASSET", state);
    if (!currentStepState) {
        return null;
    }
    return currentStepState.persistent.markupIcons;
}
