import { createSelector } from "@reduxjs/toolkit";
import { ExperienceScript } from "../../content-types";
import { SessionState } from "../../types";
import { scriptSelector } from "../step-definition/generic/scriptSelector";

export function createEnableNavigatorSelector(): (s: SessionState) => boolean {
    return createSelector([scriptSelector], (script: ExperienceScript) => {
        return !!script.welcomeOptions?.enableClaimNavigatorRole;
    });
}
