import { ParticipantMessage } from "modules/client/application-state";
import { sessionParticipantMessages } from "modules/client/selectors";
import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./toast-manager.scss";

const ToastManager: React.FC = () => {
    const dispatch = useDispatch();
    const participantMessages = useSelector(sessionParticipantMessages);

    // create the onClose action that gets called by thte toast component after the delay period
    const createOnClose = (message: ParticipantMessage): (() => void) => {
        return () => {
            dispatch({
                type: "PARTICIPANT_MESSAGE_DELETE",
                id: message.id,
            });
        };
    };

    const createToast = (message: ParticipantMessage) => {
        const onClose = createOnClose(message);
        const header = message.success ? "Success!" : "Whoops!";

        return (
            <Toast key={message.id} onClose={onClose} animation autohide delay={3000}>
                {message.eventTime ? (
                    <Toast.Header className="event-time-header">
                        <div className="event-time-text">{message.eventTime}</div>
                    </Toast.Header>
                ) : (
                    <Toast.Header className="success-message-header">{header}</Toast.Header>
                )}
                <Toast.Body>{message.message}</Toast.Body>
            </Toast>
        );
    };
    return (
        <ToastContainer className="toast-manager" position="top-end">
            {participantMessages.map(createToast)}
        </ToastContainer>
    );
};
export default ToastManager;
