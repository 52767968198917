import { AssignNavigator, SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = Pick<AssignNavigator, "participantId" | "currentNavigator">;

const createAssignNavigator = (args: Args): SessionEvent => {
    return {
        type: "ASSIGN_NAVIGATOR",
        uuid: uuidv4(),
        participantId: args.participantId,
        currentNavigator: args.currentNavigator,
    };
};

export default createAssignNavigator;
