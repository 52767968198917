import { ConnectToSessionWaitingForInitialState } from "../actions-types";
import { ApplicationState, SessionStateContainer } from "../application-state";
import { sessionState } from "../selectors";
import { initState } from "./websocketMessageReceived";

export default (state: ApplicationState, payload: ConnectToSessionWaitingForInitialState): ApplicationState => {
    const session = sessionState(state);
    if (!session) {
        return state;
    }

    const waitingSessionState: SessionStateContainer = {
        ...session,
        state: "WAITING_FOR_INITIAL_STATE",
        participantId: payload.participantId,
        pendingMessages: session?.state === "CONNECTING" ? session.pendingMessages : [],
    };

    const updatedState: ApplicationState = { ...state };
    updatedState.session = waitingSessionState;

    // Sometimes we get the initial state before this gets triggered, just
    // because if TCP buffers or something. So if we have that already, then
    // apply it:
    const initialStateMessage = waitingSessionState.pendingMessages.find((message) => message.type === "INIT_STATE");
    if (initialStateMessage) {
        waitingSessionState.pendingMessages = waitingSessionState.pendingMessages.filter(
            (m) => m != initialStateMessage,
        );
        return initState(updatedState, initialStateMessage);
    }

    return updatedState;
};
