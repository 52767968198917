import { ApplicationState } from "modules/client/application-state";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useFetch } from "modules/hooks";
import { SessionState } from "modules/shared/types";
import ActionSelectorForm from "modules/components/InPersonExperience/ActionSelectorForm";
import { Card, Placeholder } from "react-bootstrap";
import ParticipantsForm from "modules/components/InPersonExperience/ParticipantsForm";
import { FieldValues, useForm } from "react-hook-form";
import InPersonActivites from "./InPersonActivities";
import { firstInPersonStepAddress } from "modules/shared/selectors/step-address/stepIsFirst";

export type FormData = {
    participantCount: null;
};

const InPersonSession: React.FC = () => {
    const { sessionId } = useParams<{ sessionId: string }>();
    const applicationState = useSelector((state: ApplicationState) => state);
    const session = applicationState.session;
    const { state } = session;
    const [formValues, setFormValues] = useState({ participantCount: null });
    const sessionData = useFetch<SessionState>(`${CLIENT_WEBSOCKET_URL}/inPersonState/${sessionId}`);
    const location = useLocation();
    const isSessionExists = location.state;
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const isSubmitted =
        applicationState.session.state === "READY" &&
        (applicationState.session.effectiveState.inPersonParticipantCount as number) > 0;

    const onSubmit = handleSubmit(async (data: FieldValues) => {
        const { participantCount } = data;

        setFormValues({
            participantCount,
        });

        if (state && state === "READY") {
            dispatch({
                type: "ADD_INPERSON_PARTICIPANTS_TO_STATE",
                participantCount: participantCount,
            });
        }
        if (applicationState.session.state === "READY") {
            const sessionState = applicationState.session.effectiveState;
            const { sectionKey, stepKey } = firstInPersonStepAddress(sessionState);

            dispatch({
                type: "IN_PERSON_GOTO_STEP",
                sectionKey,
                stepKey,
            });
        }
    });

    useEffect(() => {
        if (state && sessionData.state === "DONE") {
            dispatch({
                type: "CONNECT_TO_IN_PERSON_SESSION",
                sessionId: sessionId,
                sessionData: sessionData.data,
            });
        }
    }, [state, sessionData, dispatch, sessionId]);

    if (state && sessionData.state === "LOADING") {
        return (
            <Card>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow" className="text-center m-3">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Card.Body>
                        <div className="d-flex justify-content-center mt-3">
                            <Placeholder.Button variant="primary" xs={6} />
                        </div>
                        <p className="text-center m-3">OR</p>
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <Placeholder.Button variant="primary" xs={6} />
                        </div>
                    </Card.Body>
                </Card.Body>
            </Card>
        );
    }

    if (isSessionExists) return <ActionSelectorForm />;

    return (
        <>
            {!isSubmitted ? (
                <ParticipantsForm register={register} onSubmit={onSubmit} errors={errors} formValues={formValues} />
            ) : (
                <InPersonActivites />
            )}
        </>
    );
};

export default InPersonSession;
