import { Icon } from "modules/shared/activity/Markup/types";
import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    icon: Icon;
};

const createAddAssetMarkupIcon = (args: Args): SessionEvent => {
    return {
        type: "ADD_ASSET_MARKUP_ICON",
        icon: args.icon,
        uuid: uuidv4(),
    };
};

export default createAddAssetMarkupIcon;
