import { ConnectToInPersonSessionAction } from "../actions-types";
import { ApplicationState } from "../application-state";

export default (state: ApplicationState, payload: ConnectToInPersonSessionAction): ApplicationState => {
    const updatedState: ApplicationState = { ...state };
    updatedState.session = {
        state: "READY",
        sessionId: payload.sessionId,
        participantId: "",
        serverSessionState: payload.sessionData,
        uncommitted: [],
        effectiveState: {
            ...payload.sessionData,
        },
        lastActiveTime: new Date(payload.sessionData.sessionMetadata.created_at),
        isIdle: false,
        participantMessages: [],
    };
    return updatedState;
};
