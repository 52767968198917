import { isActiveParticipant, updateParticipantCursor } from "modules/utils";
import { MouseMoveEvent } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const mouseMove: EventReducerDefinition<MouseMoveEvent, null> = {
    predictable: true,
    checkIfApplicable: (state, payload) => isActiveParticipant(state, payload),
    reducer: (state: SessionState, payload: MouseMoveEvent) => {
        return {
            ...state,
            participantCursors: updateParticipantCursor(
                state.participantCursors,
                payload.participantId,
                payload.position,
            ),
        };
    },
};
export default mouseMove;
