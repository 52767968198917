import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { getSectionRecommendedTime } from "../../utils";
import { scriptSections } from "../step-definition/generic/scriptSections";
import { createIntroRecommendedSectionTime } from "./getIntroRecommendedSectionTime";

export function createSectionRecommendedTimesSelector(): (s: SessionState) => number[] | null {
    return createSelector([scriptSections, createIntroRecommendedSectionTime()], (sections) => {
        if (!sections) {
            return null;
        }

        return sections.map((s) => getSectionRecommendedTime(s));
    });
}
