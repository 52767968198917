import { WebsocketMessage } from "modules/shared/message-types";
import { updateSessionState } from "modules/utils";
import { AddClientEventToUncommitted } from "../actions-types";
import { ApplicationState } from "../application-state";
import * as selectors from "../selectors";
import { checkIfApplicable, reduce as applyReducer } from "./utils";

export default (state: ApplicationState, event: AddClientEventToUncommitted): ApplicationState => {
    const isReady = selectors.isReady(state);

    if (isReady && event.payload.type === "SESSION_MESSAGE") {
        return updateSessionState(state, (session) => {
            if (session.state != "READY") {
                return session;
            }

            const uncommitted = [...session.uncommitted, event.payload];
            const filteredUncommitted: WebsocketMessage[] = [];

            let effectiveState = { ...session.serverSessionState };
            uncommitted.forEach((u) => {
                if (u.type === "SESSION_MESSAGE") {
                    const isApplicable = checkIfApplicable(u.payload.event.type, effectiveState, u.payload.event, null);

                    if (isApplicable) {
                        effectiveState = applyReducer(u.payload.event.type, effectiveState, u.payload.event, null);
                        filteredUncommitted.push(u);
                    }
                }
                return false;
            });
            return {
                ...session,
                uncommitted,
                effectiveState,
            };
        });
    } else if (isReady && event.payload.type !== "SESSION_MESSAGE") {
        throw new Error("illegal state");
    } else {
        return state;
    }
};
