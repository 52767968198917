import React from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "modules/client/auth/config";
import TopUserBar from "../TopUserBar";

type Props = Record<string, unknown>;

const Protected: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            <TopUserBar />
            {children}
        </MsalAuthenticationTemplate>
    );
};

export default Protected;
