import { flattenStepStates } from "modules/utils";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState, StepState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { getStepStates } from "./getStepStates";
import { currentStepAddress } from "../step-address/currentStepAddress";
import { createIntroTimeInPacingSelector } from "../time-tracking/createIntroTimeInPacing";
import { getIntroStepStates } from "../intro-sequence/getIntroStepStates";
import { IntroStepStateBundle } from "modules/shared/step-types";

export function createGetStepStatesForPriorSections(): (
    s: SessionState,
) => (StepState | IntroStepStateBundle)[] | null {
    return createSelector(
        [currentStepAddress, getStepStates, getIntroStepStates, createIntroTimeInPacingSelector()],
        (currentStep, stepStates, introStepStates, isIntroTimeActive) => {
            if (isIntroStepAddress(currentStep) && !isIntroTimeActive) {
                return null;
            }

            if (currentStep === "exit") {
                return flattenStepStates(stepStates);
            }

            const currentSection = isIntroStepAddress(currentStep) ? -1 : currentStep.sectionKey;
            const entries = Object.entries(stepStates)
                .filter(([sectionKeyAsString, _stepStates]) => parseInt(sectionKeyAsString) < currentSection)
                .map(([_sectionKeyAsString, stepStatesWithKey]) => Object.values(stepStatesWithKey))
                .reduce((flattened, stepStates) => flattened.concat(stepStates), []);
            return isIntroTimeActive ? [...introStepStates, ...entries] : entries;
        },
    );
}
