import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { getSectionRecommendedTime } from "../../utils";
import { scriptSections } from "../step-definition/generic/scriptSections";
import { currentSectionAddress } from "../step-address/currentSectionAddress";

export function createSecondsRecommendedForCurrentSectionSelector(): (s: SessionState) => number {
    return createSelector([currentSectionAddress, scriptSections], (currentSection, sectionDefs) => {
        if (currentSection === "exit") {
            return 0;
        }
        if (currentSection === "intro") {
            return 0;
        }
        const currentSectionDef = sectionDefs[currentSection];
        const recommendedTime = getSectionRecommendedTime(currentSectionDef);
        return recommendedTime ?? 0;
    });
}
