import { ReceiveSessionReportSuccess } from "modules/client/actions-types/sessionReportActionTypes";
import { ReportData, SelectedFilterState } from "modules/client/session-report-state";

export default function createReceiveSessionReportSuccess(
    reportData: ReportData,
    filters: SelectedFilterState,
): ReceiveSessionReportSuccess {
    return {
        type: "RECEIVE_SESSION_REPORT_SUCCESS",
        reportData,
        filters,
    };
}
