/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";
import { useSession, useSessionDispatch, useSessionSelector } from "../../SessionRenderer/context";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { endingOptionsSelector } from "modules/shared/selectors/experience-definition/endingOptionsSelector";
import { useClientLocale, useLocalizedString } from "modules/client/localization";
import "./exit-step.scss";
import EmbeddedContinuumSurvey from "../../EmbeddedContinuumSurvey";
import createSurveyTaken from "modules/client/event-factories/createSurveyTaken";

const ExitStep: React.FC = () => {
    const { participantId } = useSession();
    const dispatch = useSessionDispatch();
    const endingOptions = useSessionSelector(endingOptionsSelector);
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const clientLocale = useClientLocale();
    const [iframeUrl, setIframeUrl] = React.useState(endingOptions?.surveyLink);

    const localized = useLocalizedString();

    useEffect(() => {
        if (endingOptions?.surveyLink && clientLocale) {
            const url = new URL(endingOptions.surveyLink);
            url.searchParams.set("lang", clientLocale);
            url.searchParams.set("hideNavigation", "true");
            setIframeUrl(url.toString());
        }
    }, [endingOptions, clientLocale]);

    const onSubmitSurveySuccess = React.useCallback(() => {
        if (participantInfo && !participantInfo.surveyTaken) {
            const event = createSurveyTaken({
                participantId: participantInfo.id,
            });
            dispatch(event);
        }
    }, [dispatch, participantInfo]);

    return (
        <Row>
            <Col className="align-content-center d-flex justify-content-center">
                <Card className="exit-step">
                    <Card.Header>
                        <Card.Title>{localized("vdpEnd_title")}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            {localized("vdpEnd_body") +
                                (!endingOptions || !iframeUrl ? " " + localized("vdpEnd_closeWindow") : "")}
                        </p>
                        {endingOptions && iframeUrl && (
                            <>
                                {participantInfo && !participantInfo.surveyTaken && (
                                    <>
                                        <p>{localized("vdpEnd_surveyRequest")}</p>
                                        <hr />
                                        <EmbeddedContinuumSurvey
                                            onSubmit={onSubmitSurveySuccess}
                                            surveyLink={iframeUrl}
                                        />
                                    </>
                                )}
                                {participantInfo && participantInfo.surveyTaken && (
                                    <>
                                        <hr />
                                        <Alert variant="primary">
                                            {localized("vdpEnd_sessionComplete") +
                                                " " +
                                                localized("vdpEnd_closeWindow")}
                                        </Alert>
                                    </>
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ExitStep;
