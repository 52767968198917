import { addDays, formatDuration, Locale } from "date-fns";
import { isEqual, isNull, keyBy, shuffle, sum, uniq, values } from "lodash-es";
import { ApplicationState, SessionStateContainer } from "modules/client/application-state";
import { PositionInElementHierarchy, PositionInMap } from "modules/components/MouseRenderer/mouse-movement-tracking";
import Session from "modules/database_types/session";
import {
    CardStackWithVisualStep,
    DragAndDropStep,
    Exit,
    IntroStep,
    ExperienceScript,
    ProgressCheck,
    Role,
    RoleId,
    Section,
    StepDefinition,
    MatchingStep,
} from "modules/shared/content-types";
import { lastStepAddressSelector } from "modules/shared/selectors/step-address/lastStepAddressSelector";
import { stepExists } from "modules/shared/selectors/step-address/stepExists";
import { getStepAddressByStepEntryId } from "modules/shared/selectors/step-address/getStepAddressByStepEntryId";
import { GoToStep, NavigateIceBreaker } from "modules/shared/session-events";
import {
    IntroStepKey,
    ORDERED_INTRO_KEY,
    ParticipantCursorDict,
    ParticipantHistory,
    ParticipantHistoryPiece,
    ParticipantInfo,
    SessionState,
    StepAddress,
    StepIntroAddress,
    StepState,
} from "modules/shared/types";
import { getMapState, isIntroStepAddress, isStepAddress, stepAddressesAreEqual } from "modules/shared/utils";
import { createCardPositionState } from "../components/ActivityView/DragAndDropActivity/utils";
import {
    createIntroIsDisabledSelector,
    introIsDisabled,
} from "modules/shared/selectors/intro-sequence/createIntroIsDisabledSelector";
import { CardPositionState, MatchingInputValue } from "modules/shared/activity/Matching/types";
import { IntroStepStateBundle } from "modules/shared/step-types";
import { Logger } from "./logger";
import { participants } from "modules/shared/selectors/participants/participants";

export function createEmptySessionState(session: Session, script: ExperienceScript): SessionState {
    const navigatorStartStep = identifyStartOfRole(Role.Navigator, script);
    Logger.debug("inPerson", session.experience);
    return {
        sessionId: session.uuid,
        lastSequenceNumber: "0",
        participants: [],
        participantCursors: {},
        participantsHistory: {},
        inPersonParticipantCount: 0,
        poolOfEligibleParticipantsForNavigatorRole: [],
        iceBreakerNavigatorPool: [],
        currentStep: { sectionKey: 0, stepKey: 0 },
        navigatorStartStep,
        visibleMarkupIcons: [],
        hiddenMarkupIcons: [],
        furthestSectionViewed: 0,
        sessionStartedAt: null,
        sessionEndedAt: null,
        timeStepStarted: 0,
        stepStates: getDefaultStepStates(script),
        introStepStates: getDefaultIntroStepStates(script),
        scriptVersion: 0,
        sessionMetadata: session,
        script: script,
        dataCollectionMethod: "virtual",
        ...getBeginnginIntroStepState(script),
    };
}

export function getBeginnginIntroStepState(
    script: ExperienceScript,
): Pick<SessionState, "isIntroStep" | "currentIntroStep"> {
    if (introIsDisabled(script)) {
        return {
            isIntroStep: false,
            currentIntroStep: { introStepKey: ORDERED_INTRO_KEY[1], iceBreakerNavPoolIndex: "complete" },
        };
    }
    return {
        isIntroStep: true,
        currentIntroStep: { introStepKey: ORDERED_INTRO_KEY[1], iceBreakerNavPoolIndex: "intro" },
    };
}

export function createExitStepDefinition(script: ExperienceScript): Exit {
    const endingOptions = script.endingOptions;

    const instructionalContent = endingOptions ? endingOptions.endingMessage : null;
    const introduction = endingOptions ? endingOptions.introduction : null;
    const directions = endingOptions ? endingOptions.directions : null;
    const facilitationNotes = endingOptions ? endingOptions.facilitationNotes : null;
    const options = !!endingOptions ? endingOptions.options : null;
    const defaultOverlayColor = "rgba(0, 0, 0, 0.82);";
    return {
        contentType: "exit",
        options: {
            overlayBackgroundColor: defaultOverlayColor,
            ...options,
        },
        instructionalContent: instructionalContent,
        introduction,
        directions,
        facilitationNotes,
    } as Exit;
}

export function createIntroStepDefinition(): IntroStep {
    return {
        id: "introStep",
        contentType: "introStep",
        title: "intro step",
        version: 0,
        options: {
            id: "fake_intro_step_options",
            contentType: "stepOptions",
            version: 0,
            assignRoles: [],
            focusMapOn: [],
            mapHighlightAreas: [],
        },
    };
}

export function createProgressCheckStepDefinition(script: ExperienceScript, sectionKey: number): ProgressCheck {
    const sectionDefs = script.sections;
    // section progress checks should only occur when transitioning from one section to another
    // we should not be creating a section progress check when entering the very first section or exiting the last section
    const isIntroTimeActive =
        !!script.welcomeOptions && !!script.welcomeOptions.time && !script.welcomeOptions?.disableIntro;
    if (!isIntroTimeActive && (sectionKey <= 0 || sectionKey >= sectionDefs.length)) {
        throw new Error(`We should not be creating a progress check for a section with the key: ${sectionKey}`);
    }
    const sectionDef = sectionDefs[sectionKey];
    const contentfulSectionId = sectionDef ? sectionDef.id : "fake_section_key";
    return {
        id: "progressCheck",
        contentType: "progressCheck",
        title: `Progress Check-in Before Section ${sectionKey + 1}`,
        version: 0,
        options: {
            id: "fake_progress_step_options",
            contentType: "stepOptions",
            version: 0,
            assignRoles: [],
            focusMapOn: [],
            mapHighlightAreas: [],
        },
    };
}

export function firstStepOfSectionReassignsNavigator(sectionKey: number, sectionEntry: Section): boolean {
    // check for upcoming navigator assignment
    const navAssignmentStep: StepAddress | null = sectionEntry
        ? checkSectionForStartOfRole(Role.Navigator, { sectionKey: sectionKey, sectionEntry })
        : null;
    return !!(navAssignmentStep && navAssignmentStep !== "exit" && navAssignmentStep.stepKey === 0);
}

function sectionRequiresProgressCheck(script: ExperienceScript, sectionKey: number): boolean {
    const progressChecksEnabled = script.progressChecksEnabled === undefined ? true : script.progressChecksEnabled;
    const isIntroTimeActive =
        !!script.welcomeOptions && !!script.welcomeOptions.time && !script.welcomeOptions?.disableIntro;
    // we have progress checks enabled and we are not on the first section if intro time calculation is not active
    return isIntroTimeActive ? progressChecksEnabled : progressChecksEnabled && sectionKey > 0;
}

export function incrementStepAddress(state: SessionState): StepAddress | StepIntroAddress | null {
    const lastStepAddress = lastStepAddressSelector(state);
    const introIsDisabled = createIntroIsDisabledSelector()(state);

    if (state.isIntroStep && !introIsDisabled) {
        const currentIntroStepKey = state.currentIntroStep.introStepKey;
        const currentIntroStepIndex = ORDERED_INTRO_KEY.findIndex((key) => key === state.currentIntroStep.introStepKey);
        const currentIcebreakerIndex = state.currentIntroStep.iceBreakerNavPoolIndex;

        if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex === "intro" &&
            state.iceBreakerNavigatorPool.length > 0
        ) {
            return { introStepKey: currentIntroStepKey, iceBreakerNavPoolIndex: 0 };
        } else if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex !== "intro" &&
            currentIcebreakerIndex !== "complete" &&
            currentIcebreakerIndex < state.iceBreakerNavigatorPool.length - 1
        ) {
            return {
                introStepKey: currentIntroStepKey,
                iceBreakerNavPoolIndex: currentIcebreakerIndex + 1,
            };
        } else if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex !== "intro" &&
            currentIcebreakerIndex !== "complete" &&
            currentIcebreakerIndex >= state.iceBreakerNavigatorPool.length - 1 &&
            currentIntroStepIndex + 1 < ORDERED_INTRO_KEY.length
        ) {
            return { introStepKey: ORDERED_INTRO_KEY[currentIntroStepIndex + 1], iceBreakerNavPoolIndex: "complete" };
        } else if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex === "intro" &&
            state.iceBreakerNavigatorPool.length === 0 &&
            currentIntroStepIndex + 1 < ORDERED_INTRO_KEY.length
        ) {
            return { introStepKey: ORDERED_INTRO_KEY[currentIntroStepIndex + 1], iceBreakerNavPoolIndex: "complete" };
        } else if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex === "intro" &&
            state.iceBreakerNavigatorPool.length === 0 &&
            currentIntroStepIndex + 1 >= ORDERED_INTRO_KEY.length &&
            sectionRequiresProgressCheck(state.script, 0)
        ) {
            return { sectionKey: 0, stepKey: "ProgressCheck" };
        } else if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex === "intro" &&
            state.iceBreakerNavigatorPool.length === 0 &&
            currentIntroStepIndex + 1 >= ORDERED_INTRO_KEY.length &&
            !sectionRequiresProgressCheck(state.script, 0)
        ) {
            return { sectionKey: 0, stepKey: 0 };
        } else if (
            currentIntroStepIndex + 1 >= ORDERED_INTRO_KEY.length &&
            sectionRequiresProgressCheck(state.script, 0)
        ) {
            return { sectionKey: 0, stepKey: "ProgressCheck" };
        } else if (currentIntroStepIndex + 1 < ORDERED_INTRO_KEY.length) {
            return {
                introStepKey: ORDERED_INTRO_KEY[currentIntroStepIndex + 1],
                iceBreakerNavPoolIndex: currentIcebreakerIndex,
            };
        } else if (currentIntroStepIndex + 1 >= ORDERED_INTRO_KEY.length) {
            return { sectionKey: 0, stepKey: 0 };
        }

        return {
            introStepKey: ORDERED_INTRO_KEY[currentIntroStepIndex + 1],
            iceBreakerNavPoolIndex: currentIcebreakerIndex,
        };
    }
    if (state.currentStep === "exit") return null;
    if (isStepAddressEqual(state.currentStep, lastStepAddress)) return "exit";

    const currentSectionKey = state.currentStep.sectionKey;
    const currentStepKey = state.currentStep.stepKey;

    const sectionInScript = state.script.sections[currentSectionKey];
    const lastStepIndexInSection = sectionInScript.steps.length - 1;
    const nextSectionIndex = currentSectionKey + 1;

    const lastSectionIndex = lastStepAddress.sectionKey;
    const hasMoreSections = nextSectionIndex <= lastSectionIndex;

    const nextStepIndexInSection = currentStepKey === "ProgressCheck" ? 0 : currentStepKey + 1;
    const hasMoreStepsInSection = nextStepIndexInSection <= lastStepIndexInSection;

    if (hasMoreStepsInSection) {
        return { sectionKey: currentSectionKey, stepKey: nextStepIndexInSection };
    } else if (hasMoreSections && sectionRequiresProgressCheck(state.script, nextSectionIndex)) {
        return { sectionKey: nextSectionIndex, stepKey: "ProgressCheck" };
    } else if (hasMoreSections) {
        return { sectionKey: nextSectionIndex, stepKey: 0 };
    }
    return null;
}

export function decrementStepAddress(state: SessionState): StepAddress | StepIntroAddress | null {
    const lastStepAddress = lastStepAddressSelector(state);
    const introIsDisabled = createIntroIsDisabledSelector()(state);
    const currentIcebreakerIndex = state.currentIntroStep.iceBreakerNavPoolIndex;
    const currentIntroStepKey = state.currentIntroStep.introStepKey;

    if (state.isIntroStep && !introIsDisabled) {
        if (
            currentIntroStepKey === "iceBreaker" &&
            currentIcebreakerIndex !== "complete" &&
            currentIcebreakerIndex !== "intro"
        ) {
            if (currentIcebreakerIndex === 0) {
                return { introStepKey: currentIntroStepKey, iceBreakerNavPoolIndex: "intro" };
            }
            return {
                introStepKey: currentIntroStepKey,
                iceBreakerNavPoolIndex: currentIcebreakerIndex - 1,
            };
        }
        const index = ORDERED_INTRO_KEY.findIndex((key) => key === currentIntroStepKey);
        if (index - 1 < 1) return null;

        return {
            introStepKey: ORDERED_INTRO_KEY[index - 1],
            iceBreakerNavPoolIndex: currentIcebreakerIndex,
        };
    }

    if (state.currentStep === "exit") return lastStepAddress;

    const currentStepKey = state.currentStep.stepKey;
    const currentSectionKey = state.currentStep.sectionKey;

    if (currentStepKey === "ProgressCheck" && currentSectionKey <= 0 && !introIsDisabled) {
        return {
            introStepKey: ORDERED_INTRO_KEY[ORDERED_INTRO_KEY.length - 1],
            iceBreakerNavPoolIndex: currentIcebreakerIndex,
        };
    } else if (currentStepKey === "ProgressCheck" && currentSectionKey <= 0) {
        throw new Error(
            "Cannot go to a progress check step at the start of the experience when the intro section is disabled",
        );
    } else if (currentStepKey === "ProgressCheck") {
        const sectionInScript = state.script.sections[currentSectionKey - 1];
        const lastStepIndexInSection = sectionInScript.steps.length - 1;
        return { sectionKey: currentSectionKey - 1, stepKey: lastStepIndexInSection };
    } else if (currentStepKey > 0) {
        return { sectionKey: currentSectionKey, stepKey: state.currentStep.stepKey - 1 };
    } else if (
        currentSectionKey >= 0 &&
        currentStepKey === 0 &&
        sectionRequiresProgressCheck(state.script, currentSectionKey)
    ) {
        return { sectionKey: currentSectionKey, stepKey: "ProgressCheck" };
    } else if (currentSectionKey === 0 && currentStepKey === 0 && !introIsDisabled) {
        return {
            introStepKey: ORDERED_INTRO_KEY[ORDERED_INTRO_KEY.length - 1],
            iceBreakerNavPoolIndex: currentIcebreakerIndex,
        };
    } else if (currentSectionKey > 0) {
        const sectionInScript = state.script.sections[currentSectionKey - 1];
        const lastStepIndexInSection = sectionInScript.steps.length - 1;

        if (lastStepIndexInSection < 0) {
            throw new Error(`Section "${currentSectionKey - 1}" is empty`);
        }

        return { sectionKey: currentSectionKey - 1, stepKey: lastStepIndexInSection };
    }
    return null;
}

export function isSkippingIntro(
    addressA: StepAddress | StepIntroAddress | null,
    addressB: StepAddress | StepIntroAddress | null,
): boolean {
    if (
        addressA &&
        addressB &&
        isIntroStepAddress(addressA) &&
        isStepAddressEqual(addressB, { sectionKey: 0, stepKey: 0 })
    ) {
        return true;
    }
    return false;
}

export function isStepAddressEqual(
    addressA: StepAddress | StepIntroAddress | null,
    addressB: StepAddress | StepIntroAddress | null,
): boolean {
    if (addressA === null && addressB === null) {
        throw new Error("Cannot compare two null addresses.");
    }

    if (addressA === null || addressB === null) {
        return false;
    }

    return isEqual(addressA, addressB);
}

export function isStepAddressBefore(
    address: StepAddress | StepIntroAddress,
    comparison: StepAddress | StepIntroAddress,
): boolean {
    if (address === "exit") {
        return false;
    } else if (comparison === "exit") {
        return true;
    }

    if (isIntroStepAddress(address)) {
        if (isStepAddress(comparison)) {
            return true;
        }
        const addressIntroIndex = ORDERED_INTRO_KEY.findIndex((key) => key === address.introStepKey);
        const comparisonIntroIndex = ORDERED_INTRO_KEY.findIndex((key) => key === comparison.introStepKey);

        return addressIntroIndex < comparisonIntroIndex;
    } else {
        if (isIntroStepAddress(comparison)) {
            return false;
        }
        if (address.sectionKey === comparison.sectionKey && address.stepKey === "ProgressCheck") {
            // if our comparison is not progress check, then it must be a step after address
            return comparison.stepKey !== "ProgressCheck";
        } else if (address.sectionKey === comparison.sectionKey && comparison.stepKey === "ProgressCheck") {
            // if our comparison is progress check, then address cannot be before it
            return false;
        } else if (address.sectionKey === comparison.sectionKey) {
            return address.stepKey < comparison.stepKey;
        } else {
            return address.sectionKey < comparison.sectionKey;
        }
    }
}

export function formatTimeSectionsDropDown(seconds: number, locale: Locale): string {
    if (seconds >= 60) {
        return formatDuration({ minutes: Math.floor(seconds / 60) }, { locale: locale });
    } else if (0 < seconds && seconds < 60) {
        return formatDuration({ seconds: seconds }, { locale: locale });
    } else {
        return formatDuration({ seconds: 0 }, { locale: locale, zero: true });
    }
}

export function formatTimeContentTop(seconds: number, locale: Locale): string {
    if (seconds >= 60) {
        return formatDuration({ minutes: Math.floor(seconds / 60) }, { locale: locale });
    } else if (0 < seconds && seconds < 60) {
        return formatDuration({ minutes: 1 }, { locale: locale });
    } else {
        return formatDuration({ minutes: 0 }, { locale: locale, zero: true });
    }
}

export function getTotalRecommendedTime(recommendedTimes: number[] | null): number {
    return sum(recommendedTimes);
}

export function getTotalActualTimeByStepState(stepStates: (IntroStepStateBundle | StepState)[] | null): number {
    if (stepStates === null) {
        return 0;
    }

    return stepStates
        .map((stepState) => getTimeForStepState(stepState))
        .reduce((prevValue, currentValue) => prevValue + currentValue, 0);
}

function getTimeForStepState(stepState: StepState | IntroStepStateBundle): number {
    switch (stepState.type) {
        case "EMPTY":
        case "EXIT":
        case "PROGRESS_CHECK":
            return 0;
        default:
            return stepState.timeElapsed;
    }
}

export function getRemainingSectionTime(
    actualSectionTime: number,
    recommendedTime: number,
    locale: Locale,
): string | null {
    if (recommendedTime - actualSectionTime < 60) {
        return formatTimeSectionsDropDown(recommendedTime - actualSectionTime, locale);
    }
    return formatTimeSectionsDropDown(recommendedTime - Math.floor(actualSectionTime / 60) * 60, locale);
}

export function updateSessionState(
    state: ApplicationState,
    updater: (s: SessionStateContainer) => SessionStateContainer,
): ApplicationState {
    const updatedSession = updater(state.session);

    return {
        ...state,
        session: updatedSession,
    };
}

export function updateParticipant(
    participants: ParticipantInfo[],
    participantId: string,
    updater: (participant: ParticipantInfo) => ParticipantInfo,
): ParticipantInfo[] {
    const updatedParticipants: ParticipantInfo[] = [];
    participants.forEach((p) => {
        if (p.id === participantId) {
            updatedParticipants.push(updater(p));
        } else {
            updatedParticipants.push(p);
        }
    });

    return updatedParticipants;
}

export function updateParticipantCursor(
    participantCursors: ParticipantCursorDict,
    participantId: string,
    position: PositionInElementHierarchy | PositionInMap | null,
): ParticipantCursorDict {
    const updatedCursors = { ...participantCursors };
    updatedCursors[participantId] = position;
    return updatedCursors;
}

export function updateParticipantHistory(
    participants: ParticipantHistory,
    participantId: string,
    updater: (p: ParticipantHistoryPiece) => ParticipantHistoryPiece,
): ParticipantHistory {
    const currentHistory = participants[participantId];
    return currentHistory
        ? {
              ...participants,
              [participantId]: updater(currentHistory),
          }
        : {
              ...participants,
          };
}

export function addParticipantHistory(
    participants: ParticipantHistory,
    participantId: string,
    newHistory: ParticipantHistoryPiece,
): ParticipantHistory {
    return {
        ...participants,
        [participantId]: newHistory,
    };
}

export function updateStepStates<TType extends StepState["type"], TStepState extends StepState & { type: TType }>(
    t: TType,
    state: Pick<SessionState, "stepStates">,
    stepAddress: StepAddress,
    updater: (stepState: TStepState) => TStepState,
): SessionState["stepStates"] {
    if (stepAddress === "exit") {
        const step = {
            type: "EXIT",
            transient: null,
            persistent: null,
        };
        return step as TStepState;
    } else if (stepAddress.stepKey === "ProgressCheck") {
        const step = {
            type: "PROGRESS_CHECK",
            transient: null,
            persistent: null,
        };
        return step as TStepState;
    }

    const sectionKey = stepAddress.sectionKey;
    const stepKey = stepAddress.stepKey;
    const section = state.stepStates[sectionKey];
    const stepState = section[stepKey];

    if (stepState.type !== t) {
        return state.stepStates;
    }

    const updatedStepState = updater(stepState as TStepState);
    const updatedSection = {
        ...section,
    };
    updatedSection[stepKey] = updatedStepState;

    const updatedStepStates = {
        ...state.stepStates,
    };
    updatedStepStates[sectionKey] = updatedSection;

    return updatedStepStates;
}

export function getNameInitials(participantName: string, limit: number): string {
    return participantName
        .split(" ")
        .map((n, j) => {
            if (j < limit) {
                return n[0];
            }
        })
        .join("")
        .toUpperCase();
}

export function participantHasRole(participantInfo: ParticipantInfo, role: RoleId): boolean {
    return participantInfo.assignedRoles.includes(role);
}

export function identifyStartOfRole(role: RoleId, script: ExperienceScript): StepAddress {
    const stepAddressesWithAssignRoleBySection = script.sections
        .map((sectionEntry, sectionKey) => checkSectionForStartOfRole(role, { sectionKey, sectionEntry }))
        .filter((stepAddress) => !isNull(stepAddress));

    const firstStepAddress = stepAddressesWithAssignRoleBySection.reduce((previous, current) => {
        if (isNull(current) && isNull(previous)) {
            return "exit";
        } else if (isNull(current)) {
            return previous;
        } else if (isNull(previous)) {
            return current;
        }

        if (isStepAddressBefore(current, previous)) {
            return current;
        } else {
            return previous;
        }
    }, "exit");

    return firstStepAddress || "exit";
}

export const clearPrevAndAddNewRoleAssignment = (
    initialParticipants: readonly ParticipantInfo[],
    assignment: { roleId: RoleId; participantId: string },
): {
    participants: ParticipantInfo[];
    chosenParticipantId: string | null;
} => {
    const participants = keyBy(initialParticipants, (p) => p.id);

    // clear current participant in role
    if (assignment.roleId === Role.Navigator) {
        const prevParticipantsInRole = initialParticipants.filter((p) => participantHasRole(p, assignment.roleId));
        prevParticipantsInRole.forEach((prevParticipantInRole) => {
            const rolesWithoutRemovedRole = participants[prevParticipantInRole.id].assignedRoles.filter(
                (r) => r != assignment.roleId,
            );

            participants[prevParticipantInRole.id] = {
                ...prevParticipantInRole,
                assignedRoles: rolesWithoutRemovedRole,
            };
        });
    }

    if (!participants[assignment.participantId]) {
        console.warn(`Error: No such participant ${assignment.participantId}, cannot assign role`);
        // Drop whole thing, revert to original state
        return {
            participants: [...initialParticipants],
            chosenParticipantId: null,
        };
    }

    participants[assignment.participantId] = {
        ...participants[assignment.participantId],
        assignedRoles: uniq([...participants[assignment.participantId].assignedRoles, assignment.roleId]),
    };

    return {
        participants: values(participants),
        chosenParticipantId: assignment.participantId,
    };
};

/**
 * Returns list of potential "ready" and non-facilitator participants out of pool
 * @param state
 * @param removedParticipantId
 */
export const getPoolOfEligibleParticipantsForNavigatorRole = (
    participants: ParticipantInfo[],
    currentPool: string[],
    removedParticipantId: string,
): string[] => {
    const readyNonFacilitatorParticipants = participants
        .filter((p) => p.id !== removedParticipantId && !participantHasRole(p, Role.Facilitator) && p.ready)
        .map((p) => p.id);

    if (currentPool.length <= 1) {
        return readyNonFacilitatorParticipants;
    }

    return currentPool.filter((id) => readyNonFacilitatorParticipants.includes(id));
};

export function checkSectionForStartOfRole(
    role: RoleId,
    section: { sectionKey: number; sectionEntry: Section },
): StepAddress | null {
    const stepKey = section.sectionEntry.steps.findIndex((step) => checkStepForStartOfRole(role, step));
    return stepKey >= 0 ? { sectionKey: section.sectionKey, stepKey: stepKey } : null;
}

export const isActiveParticipant = (state: SessionState, payload: { participantId?: string }): boolean => {
    const participant = state.participants.find((p) => p.id == payload.participantId);
    return !!participant;
};

export const isValidStepChange = (state: SessionState, payload: GoToStep | NavigateIceBreaker) => {
    const sourceStep = payload.from;
    const destinationStep = payload.to;
    return (
        stepExists(state) &&
        (isStepAddressEqual(state.currentStep, sourceStep) || isStepAddressEqual(state.currentIntroStep, sourceStep)) &&
        (isStepAddressEqual(destinationStep, incrementStepAddress(state)) ||
            isStepAddressEqual(destinationStep, decrementStepAddress(state)) ||
            isSkippingIntro(sourceStep, destinationStep))
    );
};

function checkStepForStartOfRole(role: RoleId, step: StepDefinition): boolean {
    return step.options?.assignRoles?.includes(role) || false;
}

export function getDefaultStepStates(script: ExperienceScript): {
    [sectionKey: number]: { [stepKey: number]: StepState };
} {
    let mapLocation = script.mapUrl;

    let hideMarkupIconsFromSteps: StepAddress[] = [];
    const allStepAddresses = getAllStepAddresses(script);

    const stepStates = script.sections.map(
        (section, sectionIndex): { [sectionKey: number]: [{ [stepKey: number]: StepState }] } => {
            const steps = section.steps.map((step, stepIndex): { [stepKey: number]: StepState } => {
                if (step?.options?.changeMapTo) {
                    mapLocation = step.options.changeMapTo.mapUrl;
                }

                hideMarkupIconsFromSteps = getHiddenMarkupIconSteps(
                    script,
                    hideMarkupIconsFromSteps,
                    step,
                    allStepAddresses,
                );
                return {
                    [stepIndex]: {
                        ...defaultStepStates(step)[step.contentType],
                        timeElapsed: 0,
                        mapLocation: mapLocation,
                        hideMarkupIconsFromSteps: hideMarkupIconsFromSteps,
                    } as StepState,
                };
            });

            return {
                [sectionIndex]: Object.assign({}, ...steps),
            };
        },
    );

    return Object.assign({}, ...stepStates);
}

//intro step states

export function getDefaultIntroStepStates(script: ExperienceScript): IntroStepStateBundle[] {
    return ORDERED_INTRO_KEY.map((key) => ({
        type: "INTRO",
        persistent: null,
        transient: null,
        introStepKey: key,
        timeElapsed: 0,
    }));
}

const defaultStepStates = (
    step: StepDefinition,
): { [key: string]: Pick<StepState, "type" | "persistent" | "transient"> } => {
    const dragAndDrop = step.contentType === "dragAndDropStep" ? (step as DragAndDropStep) : null;
    const matching = step.contentType === "matchingStep" ? (step as MatchingStep) : null;
    const cardStack = step.contentType === "cardStackWithVisualStep" ? (step as CardStackWithVisualStep) : null;

    return {
        exit: {
            type: "EXIT",
            persistent: null,
            transient: null,
        },
        singleAssetStep: {
            type: "SINGLE_ASSET",
            persistent: {
                markupIcons: [],
            },
            transient: null,
        },
        videoStep: {
            type: "VIDEO",
            persistent: null,
            transient: null,
        },
        textEntryStep: {
            type: "TEXT_ENTRY",
            persistent: {
                wasSubmitted: false,
                answers: {},
            },
            transient: {},
        },
        editableCardStep: {
            type: "EDITABLE_CARD",
            persistent: {
                wasSubmitted: false,
                showWarning: false,
                answers: {},
            },
            transient: {},
        },
        dragAndDropStep: {
            type: "DRAG_AND_DROP",
            persistent: {
                cardPositionState: createCardPositionState(dragAndDrop?.cards ?? [], dragAndDrop?.hasInputRow ?? false),
            },
            transient: {},
        },
        matchingStep: {
            type: "MATCHING",
            persistent: {
                cardPositionState: !!matching ? initMatchingCardPositionState(matching) : [],
            },
            transient: {},
        },
        revealCardStep: {
            type: "REVEAL_CARD",
            persistent: {
                viewedCardIds: [],
                flippedCardIds: [],
            },
            transient: {
                modalVisible: false,
                selectedCardId: null,
            },
        },
        infoStep: {
            type: "REVEAL_HOTSPOT",
            persistent: {
                viewedHotspotIdxs: [],
                viewedClickableAreaIdxs: [],
            },
            transient: {
                modalVisible: false,
                hotspotUuid: null,
                selectedHotspotIdx: null,
                clickableAreaUuid: null,
                selectedClickableAreaIdx: null,
                openedCardFlipped: false,
            },
        },
        multipleChoiceStep: {
            type: "MULTIPLE_CHOICE",
            persistent: {
                wasSubmitted: false,
                selectedAnswerIds: [],
                submissionCorrect: null,
                incorrectAnswers: [],
                submittedCorrectAnswers: [],
            },
            transient: {},
        },
        cardStackWithVisualStep: {
            type: "CARD_STACK_WITH_VISUAL",
            persistent: {
                selectedCardId: cardStack?.cards !== undefined ? cardStack.cards[0]?.id ?? 0 : null,
                focusArea: getMapState(cardStack?.cards !== undefined ? cardStack.cards[0]?.focusMapOn : undefined),
            },
            transient: {},
        },
        comparisonStep: {
            type: "COMPARISON",
            persistent: null,
            transient: null,
        },
        recallStep: {
            type: "RECALL_STEP",
            persistent: null,
            transient: null,
        },
    };
};

export const initMatchingCardPositionState = (entry: MatchingStep): CardPositionState[] => {
    const initialMatches = entry.matches.map((match) => {
        const card = match.card;
        return {
            cardId: card.id,
            gridId: MatchingInputValue,
            heldByParticipant: null,
            version: 0,
        };
    });
    return shuffle(initialMatches).map((card, i) => {
        return {
            ...card,
            idx: i,
        };
    });
};

export const getAllStepAddresses = (script: ExperienceScript): StepAddress[] => {
    const stepAddresses: StepAddress[] = [];
    script.sections.forEach((section, i) => {
        section.steps.forEach((_step, j) => {
            stepAddresses.push({ sectionKey: i, stepKey: j });
        });
    });
    return stepAddresses;
};

export const getHiddenMarkupIconSteps = (
    script: ExperienceScript,
    currentHiddenMarkupIconSteps: StepAddress[],
    step: StepDefinition,
    allStepAddresses: StepAddress[],
): StepAddress[] => {
    const showAllMapMarkupIcons = step.options?.showMapMarkupIcons;

    if (showAllMapMarkupIcons) {
        return [];
    } else if (showAllMapMarkupIcons === false) {
        return allStepAddresses;
    }

    const showMarkupIconsFromStepDefinitions = step.options?.showMarkupIconsFromStepsReferences;
    const hideMarkupIconsFromStepDefinitions = step.options?.hideMarkupIconsFromStepsReferences;

    const showMarkupIconsFromSteps: StepAddress[] = [];
    const hideMarkupIconsFromSteps: StepAddress[] = [];

    if (showMarkupIconsFromStepDefinitions && showMarkupIconsFromStepDefinitions.length > 0) {
        showMarkupIconsFromStepDefinitions.map((stepId) => {
            const stepAddress = getStepAddressByStepEntryId(script, stepId);
            if (stepAddress) {
                showMarkupIconsFromSteps.push(stepAddress);
            }
        });
    } else {
        step.options?.showMarkupIconsFromSteps?.map((step) => {
            const stepAddress = parseStepAddressFromString(step);
            if (stepAddress) {
                showMarkupIconsFromSteps.push(stepAddress);
            }
        });
    }

    if (hideMarkupIconsFromStepDefinitions && hideMarkupIconsFromStepDefinitions.length > 0) {
        hideMarkupIconsFromStepDefinitions.map((stepId) => {
            const stepAddress = getStepAddressByStepEntryId(script, stepId);
            if (stepAddress) {
                hideMarkupIconsFromSteps.push(stepAddress);
            }
        });
    } else {
        step.options?.hideMarkupIconsFromSteps?.map((step) => {
            const stepAddress = parseStepAddressFromString(step);
            if (stepAddress) {
                hideMarkupIconsFromSteps.push(stepAddress);
            }
        });
    }

    let updatedHiddenMarkupIconSteps = currentHiddenMarkupIconSteps;

    if (!!showMarkupIconsFromSteps) {
        updatedHiddenMarkupIconSteps = updatedHiddenMarkupIconSteps.filter(
            (stepA) => !showMarkupIconsFromSteps.some((stepB) => isStepAddressEqual(stepA, stepB)),
        );
    }

    if (!!hideMarkupIconsFromSteps) {
        updatedHiddenMarkupIconSteps = updatedHiddenMarkupIconSteps
            .concat(hideMarkupIconsFromSteps)
            .filter((stepA, i, self) => i === self.findIndex((stepB) => isStepAddressEqual(stepA, stepB)));
    }

    return updatedHiddenMarkupIconSteps;
};

const parseStepAddressFromString = (stringStepAddress: string): StepAddress | undefined => {
    const splitStepAddress = stringStepAddress.split(",");
    if (splitStepAddress.length !== 2) {
        return undefined;
    }

    const isSectionKeyANumber = splitStepAddress[0].trim() !== "" && !isNaN(Number(splitStepAddress[0]));
    const isStepKeyANumber = splitStepAddress[1].trim() !== "" && !isNaN(Number(splitStepAddress[1]));

    if (isSectionKeyANumber && isStepKeyANumber) {
        return { sectionKey: Number(splitStepAddress[0]) - 1, stepKey: Number(splitStepAddress[1]) - 1 };
    }
};

export const setMarkupIconVisibility = (
    state: SessionState,
    stepAddress: StepAddress | StepIntroAddress,
): SessionState => {
    if (stepAddress === "exit" || isIntroStepAddress(stepAddress) || stepAddress.stepKey === "ProgressCheck") {
        return { ...state };
    }

    const allMarkupIcons = state.hiddenMarkupIcons.concat(state.visibleMarkupIcons);
    const hideMarkupIconsFromSteps =
        state.stepStates[stepAddress.sectionKey][stepAddress.stepKey].hideMarkupIconsFromSteps;

    const hiddenMarkupIcons = allMarkupIcons.filter((icon) =>
        hideMarkupIconsFromSteps.some((step) => stepAddressesAreEqual(step, icon.addedOnStep)),
    );

    const visibileMarkupIcons = allMarkupIcons.filter(
        (iconA) => !hiddenMarkupIcons.some((iconB) => iconA.iconId === iconB.iconId),
    );

    return {
        ...state,
        hiddenMarkupIcons: hiddenMarkupIcons,
        visibleMarkupIcons: visibileMarkupIcons,
    };
};

export function createSessionMetadata(id: string, overrides?: Partial<Session>): Session {
    return {
        id: 0,
        uuid: id,
        experience_id: 0,
        opens_at: new Date(),
        closes_at: addDays(new Date(), 1),
        created_at: new Date(),
        updated_at: new Date(),
        socket_server_id: "",
        language_code: "en-US",
        experience: {
            id: 0,
            uuid: "",
            scheduling_available_through: addDays(new Date(), 1),
            company_name: "",
            info: "",
            experience_name: "",
            contentful_id: "",
            status: "available",
            key_contacts: "",
            created_at: new Date(),
            updated_at: new Date(),
            archived_at: null,
            data_collection_default: false,
            inPerson: false,
        },
        data_collection: false,
        ...overrides,
    };
}

export function loadSession(id: string): Promise<Session> {
    return Promise.resolve(createSessionMetadata(id));
}

export function flattenStepStates(stepStates: {
    [sectionKey: number]: {
        [stepKey: number]: StepState;
    };
}): StepState[] {
    return Object.values(stepStates)
        .map((stepStateMap) => Object.values(stepStateMap))
        .reduce((flattened, stepStates) => {
            return flattened.concat(stepStates);
        }, []);
}

export function updateIntroStepState(
    introStepStates: IntroStepStateBundle[],
    introStepKey: IntroStepKey,
    updater: (introStepState: IntroStepStateBundle) => IntroStepStateBundle,
): IntroStepStateBundle[] {
    const index = introStepStates.findIndex((introStepState) => introStepState.introStepKey === introStepKey);
    if (index === -1) {
        return [...introStepStates];
    }
    const beforeSlice = introStepStates.slice(0, index);
    const afterSlice = introStepStates.slice(index + 1);
    const updatedIntroStepState = updater(introStepStates[index]);
    return [...beforeSlice, updatedIntroStepState, ...afterSlice];
}
