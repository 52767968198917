import { AddInpersonParticipantsToState } from "modules/shared/session-events";
import { ApplicationState } from "modules/client/application-state";
import {
    InPersonExit,
    InPersonDragAndDropSubmitted,
    InPersonGotoStep,
    InPersonMultipleChoiceAnswerChanged,
    InPersonMultipleChoiceSubmitted,
    InPersonTextEntryAnswerChanged,
    InPersonTextEntrySubmitted,
    WriteSession,
    InPersonTextEntryUpdated,
    InPersonMultipleChoiceAnswerUpdated,
    InPersonDragAndDropUpdated,
} from "modules/client/actions-types";
import { MainStepAddress } from "modules/shared/types";
import { updateStepStates } from "modules/utils";
import { getStepScript } from "modules/shared/selectors/step-definition/generic/getStepScript";

export const ADD_INPERSON_PARTICIPANTS_TO_STATE = (
    state: ApplicationState,
    payload: AddInpersonParticipantsToState,
) => {
    {
        if (state.session.state !== "READY") {
            return state;
        } else {
            return {
                ...state,
                session: {
                    ...state.session,
                    effectiveState: {
                        ...state.session.effectiveState,
                        inPersonParticipantCount: payload.participantCount,
                        sessionStartedAt: Math.floor(new Date().getTime() / 1000),
                        dataCollectionMethod: "in-person",
                    },
                },
            };
        }
    }
};

export const IN_PERSON_GOTO_STEP = (state: ApplicationState, payload: InPersonGotoStep) => {
    if (state.session.state !== "READY") return state;

    const sections = state.session.effectiveState.script.sections;
    const sectionsLength = sections.length;
    const currentStep = state.session.effectiveState.currentStep as MainStepAddress;
    let { sectionKey, stepKey } = currentStep;
    const stepsInCurrentSection = sections[sectionKey].steps.length;

    const isInPersonStepEnabled = (sectionKey: number, stepKey: number) => {
        const step = sections[sectionKey]?.steps[stepKey];
        return step && step.options?.enableInPersonDataCollection;
    };

    if (payload.sectionKey !== undefined && payload.stepKey !== undefined) {
        sectionKey = payload.sectionKey;
        stepKey = payload.stepKey;
    } else if (payload.direction) {
        if (payload.direction === "NEXT") {
            do {
                stepKey += 1;
                if (stepKey >= stepsInCurrentSection) {
                    sectionKey += 1;
                    stepKey = 0;
                }
                if (sectionKey >= sectionsLength) {
                    sectionKey = sectionsLength - 1;
                    stepKey = sections[sectionKey].steps.length - 1;
                    break;
                }
            } while (!isInPersonStepEnabled(sectionKey, stepKey));
        } else if (payload.direction === "PREVIOUS") {
            do {
                stepKey -= 1;
                if (stepKey < 0) {
                    sectionKey -= 1;
                    if (sectionKey >= 0) {
                        stepKey = sections[sectionKey].steps.length - 1;
                    } else {
                        sectionKey = 0;
                        stepKey = 0;
                        break;
                    }
                }
                if (sectionKey < 0) {
                    sectionKey = 0;
                    stepKey = 0;
                    break;
                }
            } while (!isInPersonStepEnabled(sectionKey, stepKey));
        }
    }

    if (sectionKey >= sectionsLength) {
        sectionKey = sectionsLength - 1;
        stepKey = sections[sectionKey].steps.length - 1;
    } else if (sectionKey < 0) {
        sectionKey = 0;
        stepKey = 0;
    }

    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                currentStep: { sectionKey, stepKey },
                isIntroStep: false,
            },
        },
    };
};

export const IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED = (
    state: ApplicationState,
    payload: InPersonTextEntryAnswerChanged,
) => {
    if (state.session.state !== "READY") return state;
    const sessionState = state.session.effectiveState;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("TEXT_ENTRY", sessionState, sessionState.currentStep, (stepState) => {
                    const updatedAnswers = {
                        ...stepState?.persistent.answers,
                    };
                    updatedAnswers[payload.id] = payload.answer;

                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            answers: updatedAnswers,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_TEXT_ENTRY_SUBMITTED = (state: ApplicationState, payload: InPersonTextEntrySubmitted) => {
    if (state.session.state !== "READY") return state;
    const sessionState = state.session.effectiveState;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("TEXT_ENTRY", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            answers: { [payload.id]: payload.answer },
                            wasSubmitted: true,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_TEXT_ENTRY_UPDATED = (state: ApplicationState, payload: InPersonTextEntryUpdated) => {
    if (state.session.state !== "READY") return state;
    const sessionState = state.session.effectiveState;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("TEXT_ENTRY", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            wasSubmitted: false,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED = (
    state: ApplicationState,
    payload: InPersonMultipleChoiceAnswerChanged,
) => {
    if (state.session.state !== "READY") return state;
    const sessionState = state.session.effectiveState;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("MULTIPLE_CHOICE", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            selectedAnswerIds: payload.selectedAnswerIds,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_MULTIPLE_CHOICE_SUBMITTED = (
    state: ApplicationState,
    payload: InPersonMultipleChoiceSubmitted,
) => {
    if (state.session.state !== "READY") return state;

    const sessionState = state.session.effectiveState;
    const step = getStepScript("multipleChoiceStep", sessionState, sessionState.currentStep);

    if (!step) {
        return state;
    }
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("MULTIPLE_CHOICE", sessionState, sessionState.currentStep, (stepState) => {
                    const submittedCorrectAnswers = step!.predefinedAnswers.filter((answer) => {
                        return answer.isCorrect && payload.selectedAnswerIds.includes(answer.id);
                    });

                    const incorrectAnswers = step.predefinedAnswers.filter((answer) => {
                        return (
                            (answer.isCorrect && !payload.selectedAnswerIds.includes(answer.id)) ||
                            (!answer.isCorrect && payload.selectedAnswerIds.includes(answer.id))
                        );
                    });

                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            selectedAnswerIds: payload.selectedAnswerIds,
                            wasSubmitted: true,
                            incorrectAnswers: incorrectAnswers,
                            submittedCorrectAnswers: submittedCorrectAnswers,
                            submissionCorrect: incorrectAnswers.length === 0,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_MULTIPLE_CHOICE_ANSWER_UPDATED = (
    state: ApplicationState,
    payload: InPersonMultipleChoiceAnswerUpdated,
) => {
    if (state.session.state !== "READY") return state;
    const sessionState = state.session.effectiveState;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("MULTIPLE_CHOICE", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            wasSubmitted: false,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_DRAG_AND_DROP_SUBMITTED = (state: ApplicationState, payload: InPersonDragAndDropSubmitted) => {
    if (state.session.state !== "READY") return state;

    const sessionState = state.session.effectiveState;
    const step = getStepScript("dragAndDropStep", sessionState, sessionState.currentStep);

    if (!step) {
        return state;
    }

    const newCardPositions = Object.values(payload.newCardPositions);

    return {
        ...state,
        session: {
            ...state.session,
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("DRAG_AND_DROP", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            cardPositionState: newCardPositions,
                            cardCorrectnessRevealed: true,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_DRAG_AND_DROP_UPDATED = (state: ApplicationState, payload: InPersonDragAndDropUpdated) => {
    if (state.session.state !== "READY") return state;

    const sessionState = state.session.effectiveState;
    const step = getStepScript("dragAndDropStep", sessionState, sessionState.currentStep);

    if (!step) {
        return state;
    }

    return {
        ...state,
        session: {
            ...state.session,
            effectiveState: {
                ...state.session.effectiveState,
                stepStates: updateStepStates("DRAG_AND_DROP", sessionState, sessionState.currentStep, (stepState) => {
                    return {
                        ...stepState,
                        persistent: {
                            ...stepState.persistent,
                            cardCorrectnessRevealed: false,
                        },
                    };
                }),
            },
        },
    };
};

export const IN_PERSON_EXIT = (state: ApplicationState, payload: InPersonExit) => {
    if (state.session.state !== "READY") return state;
    return {
        ...state,
        session: {
            ...state.session,
            effectiveState: {
                ...state.session.effectiveState,
                currentStep: payload.currentStep,
                sessionEndedAt: Math.floor(new Date().getTime() / 1000),
            },
        },
    };
};

export const WRITE_SESSION = (state: ApplicationState, payload: WriteSession) => {
    if (state.session.state !== "READY") return state;
    return {
        ...state,
        session: {
            ...state.session,
            lastActiveTime: new Date(),
            sessionReachedExit: true,
        },
    };
};
