import { SessionEvent, SessionEventResolution } from "modules/shared/session-events";
import { EventReducerLookup } from "modules/shared/reducers";
import { SessionState } from "modules/shared/types";

export function checkIfApplicable<
    TType extends SessionEvent["type"],
    TPayload extends SessionEvent & { type: TType },
    TRes extends SessionEventResolution,
>(type: TType, state: SessionState, payload: TPayload, resolution: TRes): boolean {
    const handler = EventReducerLookup[type];

    return handler.checkIfApplicable(state, payload, resolution);
}

export function reduce<
    TType extends SessionEvent["type"],
    TPayload extends SessionEvent & { type: TType },
    TRes extends SessionEventResolution,
>(type: TType, state: SessionState, payload: TPayload, resolution: TRes): SessionState {
    const handler = EventReducerLookup[type];
    return handler.reducer(state, payload, resolution);
}

export function isPredictable<TType extends SessionEvent["type"]>(type: TType): boolean {
    const handler = EventReducerLookup[type];
    return handler.predictable;
}
