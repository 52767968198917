import { generatePath } from "react-router-dom";
import { getAppUrl } from "../utils/hostname";

/**
 * Base routes (PATH_TEMPLATE) and any helper functions needed (generate(), etc.)
 */

export const ParticipantRoute = {
    PATH_TEMPLATE: "/participants/join/:sessionId/:previewMode",
    SIMPLE_PATH_TEMPLATE: "/participants/join/:sessionId",
    generate: function (sessionId: string, languageCode: string, previewMode?: boolean): string {
        const path = previewMode
            ? generatePath(this.PATH_TEMPLATE, {
                  sessionId: sessionId,
                  previewMode: "true",
              })
            : generatePath(this.SIMPLE_PATH_TEMPLATE, {
                  sessionId: sessionId,
              });
        const url = new URL(path, getAppUrl()).toString();
        return `${url}?locale=${languageCode}`;
    },
};

export const ClientSchedulerRoute = {
    PATH_TEMPLATE: "/client/scheduler/:uuid",
    generate: function (uuid: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                uuid: uuid,
            }),
            getAppUrl(),
        ).toString();
    },
};

export const ConnectionTestRoute = {
    PATH_TEMPLATE: "/client/connection-test/:uuid",
    generate: function (uuid: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                uuid: uuid,
            }),
            getAppUrl(),
        ).toString();
    },
};

export const SessionRepositoryReportRoute = {
    PATH_TEMPLATE: "/session-repository/report/:uuid",
    generate: function (uuid: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                uuid: uuid,
            }),
            getAppUrl(),
        ).toString();
    },
};

export const GenerateQrCodeRoute = {
    PATH_TEMPLATE: "/generateqrcode/:uuid",
    generate: function (uuid: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                uuid: uuid,
            }),
            getAppUrl(),
        ).toString();
    },
};

export const InPersonExperienceRoute = {
    PATH_TEMPLATE: "/client/inPerson/:experienceId/",
    generate: function (experienceId: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                experienceId: experienceId,
            }),
            getAppUrl(),
        ).toString();
    },
};

export const InPersonSessionRoute = {
    PATH_TEMPLATE: "/client/inPersonSession/:sessionId",
    generate: function (sessionId: string): string {
        return new URL(
            generatePath(this.PATH_TEMPLATE, {
                sessionId: sessionId,
            }),
            getAppUrl(),
        ).toString();
    },
};
