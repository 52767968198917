import { createSelector } from "@reduxjs/toolkit";
import { ExperienceScript } from "../../content-types";
import { SessionState } from "../../types";
import { scriptSelector } from "../step-definition/generic/scriptSelector";

export function createCustomPageTitleSelector(): (s: SessionState) => string | null {
    return createSelector([scriptSelector], (script: ExperienceScript) => {
        return script.appearance?.pageTitle ?? null;
    });
}
