import { ParticipantMessageReceived } from "../actions-types";

type Args = Pick<ParticipantMessageReceived, "payload">;

export default function (args: Args): ParticipantMessageReceived {
    return {
        type: "PARTICIPANT_MESSAGE_RECEIVED",
        payload: args.payload,
    };
}
