import { useLocalizedString } from "modules/client/localization";
import Experience from "modules/database_types/experience";
import { useFetch } from "modules/hooks";
import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";

type Props = {
    experienceUuid: string;
};

const HeaderInfo: React.FC<Props> = ({ experienceUuid }) => {
    const localized = useLocalizedString();
    const experience = useFetch<Experience>(`/api/v1/experiences/${experienceUuid}`);
    return (
        <Row>
            {experience.state === "LOADING" && (
                <Col>
                    <Placeholder as={"h1"} animation="glow">
                        <Placeholder xs={3} />
                    </Placeholder>
                    <Placeholder as={"h5"} animation="glow">
                        <Placeholder xs={2} />
                    </Placeholder>
                </Col>
            )}
            {experience.state === "DONE" && (
                <Col>
                    <h1>{localized("sessionReport_header_title", experience.data.company_name)}</h1>
                    <h5>{localized("sessionReport_header_experience", experience.data.experience_name)}</h5>
                </Col>
            )}
            {experience.state === "ERROR" && (
                <Col>
                    <h1>{localized("sessionReport_header_errorTitle")}</h1>
                    <h5>{localized("sessionReport_header_errorSubtitle")}</h5>
                </Col>
            )}
        </Row>
    );
};
export default HeaderInfo;
