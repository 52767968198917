import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { isIntroStepAddress } from "../../utils";
import { currentStepAddress } from "../step-address/currentStepAddress";
import { createSecondsPassedForSectionSelector } from "./createSecondsPassedForSectionSelector";

export function createSecondsPassedForCurrentSectionSelector(): (s: SessionState) => number {
    return createSelector(
        [currentStepAddress, createSecondsPassedForSectionSelector, (state) => state],
        (currentStep, secondsPassedSelector, state) => {
            if (currentStep === "exit" || isIntroStepAddress(currentStep)) {
                return 0;
            }

            return secondsPassedSelector(state, currentStep.sectionKey);
        },
    );
}
