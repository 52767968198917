import { useLocalizedString } from "modules/client/localization";
import { default as React, useCallback, useRef, useState } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";

type Props = {
    onClick: () => Promise<void>;
    disabled?: boolean;
    variant?: string;
    children?: React.ReactNode;
};

const CopyToClipboardButton: React.FC<Props> = (props) => {
    const localized = useRef(useLocalizedString());

    const [tooltipMessage, setTooltipMessage] = useState(localized.current("vdpClipboard_copied"));
    const [showCopyTooltip, setShowCopyTooltip] = useState(false);
    const copyButton = useRef(null);

    const onClose = useCallback(() => setShowCopyTooltip(false), [setShowCopyTooltip]);

    const onCopied = useCallback(async () => {
        await props
            .onClick()
            .then(() => {
                setShowCopyTooltip(true);
            })
            .catch(() => {
                setTooltipMessage(localized.current("vdpClipboard_error"));
                setShowCopyTooltip(true);
            });
    }, [props, setShowCopyTooltip, localized]);

    return (
        <>
            <Button ref={copyButton} disabled={props.disabled} onClick={onCopied} variant={props.variant}>
                {props.children || localized.current("vdpClipboard_copy")}
            </Button>
            <Overlay target={copyButton} placement="right" show={showCopyTooltip} rootClose onHide={onClose}>
                <Tooltip id="tooltip-right">{tooltipMessage}</Tooltip>
            </Overlay>
        </>
    );
};

export default CopyToClipboardButton;
