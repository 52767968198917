import classNames from "classnames";
import createAssignFacilitator from "modules/client/event-factories/createAssignFacilitator";
import { useLocalizedString } from "modules/client/localization";
import { useSession, useSessionDispatch, useSessionSelector } from "modules/components/SessionRenderer/context";
import { Role } from "modules/shared/content-types";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { createEnableNavigatorSelector } from "modules/shared/selectors/intro-sequence/createEnableNavigatorSelector";
import { participantHasRole } from "modules/utils";
import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsCheck2 } from "react-icons/bs";
import "./claim-facilitator.scss";

const ClaimFacilitator: React.FC<{ inPreviewMode: boolean }> = (props) => {
    const localized = useLocalizedString();

    const dispatch = useSessionDispatch();

    const { participantId } = useSession();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isParticipantFacilitator = !!participantInfo && participantHasRole(participantInfo, Role.Facilitator);
    const isNavigatorEnabledByParticipant = useSessionSelector(createEnableNavigatorSelector());

    const [show, setShow] = useState(false);
    const buttonTitle = isParticipantFacilitator ? (
        <>
            <BsCheck2 fontSize={"1.3rem"} /> {localized("vdpNameEntry_facilitatorClaimed")}
        </>
    ) : (
        <>{localized("vdpNameEntry_claimFacilitatorTitle")}</>
    );

    const claimFacilitator = useCallback(() => {
        dispatch(
            createAssignFacilitator({
                participantId,
            }),
        );
    }, [dispatch, participantId]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className={classNames("claim-facilitator-container", { "preview-mode": props.inPreviewMode })}>
            {!isNavigatorEnabledByParticipant && (
                <Button className="claim-facilitator-button" onClick={handleShow} disabled={isParticipantFacilitator}>
                    {buttonTitle}
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{localized("vdpNameEntry_claimFacilitatorTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body id="facilitator-description">
                    <p>{localized("vdpNameEntry_claimFacilitatorModalParagraphOne")}</p>
                    <p>{localized("vdpNameEntry_claimFacilitatorModalParagraphTwo")}</p>
                    <p>{localized("vdpNameEntry_claimFacilitatorModalParagraphThree")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        {localized("vdpNameEntry_claimFacilitatorModalCancel")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleClose();
                            claimFacilitator();
                        }}
                        data-testid="claim-facilitator-confirmation"
                        aria-describedby="facilitator-description"
                    >
                        {localized("vdpNameEntry_claimFacilitatorButton")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ClaimFacilitator;
