import { SessionState } from "../../types";
import { scriptSections } from "../step-definition/generic/scriptSections";
import { numberOfSections } from "../step-definition/generic/numberOfSections";

export function lastStepAddressSelector(state: SessionState): { sectionKey: number; stepKey: number } {
    const lastSectionIdx = numberOfSections(state) - 1;
    const lastStepIdx = scriptSections(state)[lastSectionIdx].steps.length - 1;
    return { sectionKey: lastSectionIdx, stepKey: lastStepIdx };
}

export function inPersonlastStepAddressSelector(state: SessionState): { sectionKey: number; stepKey: number } {
    const lastSectionIdx = numberOfSections(state) - 1;
    let lastStepIdx = scriptSections(state)[lastSectionIdx].steps.length - 1;

    while (lastStepIdx >= 0) {
        const inPersonDataCollectionEnabled =
            state.script.sections[lastSectionIdx].steps[lastStepIdx].options?.enableInPersonDataCollection;

        if (inPersonDataCollectionEnabled) {
            return { sectionKey: lastSectionIdx, stepKey: lastStepIdx };
        }

        lastStepIdx -= 1;
    }

    return { sectionKey: lastSectionIdx + 1, stepKey: 0 };
}
