const APP_STRING = {
    "en-US": {
        name: "English (US)",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Cannot find activity {0}",
            vdpActivity_closeModal: "Close",
            vdpActivity_correctExclamation: "Correct!",
            vdpActivity_correct: "Correct",
            vdpActivity_correctAnswerBadge: "Correct Answer",
            vdpActivity_partiallyCorrect: "Partially correct!",
            vdpActivity_incorrect: "Incorrect",
            vdpActivity_notCorrect: "Not Correct",
            vdpActivity_continue: "Continue",
            vdpActivity_checkAnswer: "Check Answer",
            vdpActivity_submit: "Submit",
            vdpActivity_submitted: "Submitted",
            vdpActivity_ready: "Ready.",
            vdpActivity_cardsPlaced: "All the cards have been placed.",
            vdpActivity_cardsCorrectHeader: "Success!",
            vdpActivity_cardsCorrect: "All cards have been placed successfully.",
            vdpActivity_cardsCorrectContinue: "Continue to the next activity.",
            vdpActivity_cardsPartiallyCorrectHeader: "Not quite.",
            vdpActivity_cardsPartiallyCorrect: "Review the incorrect answers and try moving those to a new place.",
            vdpActivity_cardsCheckAnswers: "Check Your Answers",
            vdpActivity_textEntryPlaceholder: "Your response",
            vdpActivity_textEntryNavigatorPrompt: "The navigator, {0}, will enter text below",
            vdpActivity_textEntryPrompt: "Enter text below:",
            vdpActivity_textEntrySubmit: "Submit",
            vdpActivity_editableCardWarning: "Please fill out all the empty cards before submitting.",
            vdpActivity_selectOne: "Select one",
            vdpActivity_selectOneOrMany: "Select one or many",
            vdpActivity_defaultCurrentParticipantName: "Me",
            vdpActivity_hotspotTooltip: "Select the hotspots to learn more.",
            vdpActivity_matchingSuccess: "Success!",
            vdpActivity_matchingSuccessDescription: "All the cards have been placed correctly.",
            vdpActivity_matchingFail: "Not quite.",
            vdpActivity_matchingFailDescription:
                "Drag the incorrect cards back to the left column. Then try matching them to a new spot.",
            vdpActivity_matchingReady: "Ready.",
            vdpActivity_matchingReadyDescription: "All the cards have been placed.",
            vdpActivity_matchingCheckAnswers: "Check Your Answers",
            vdpActivity_matchingContinue: "Continue to the next activity.",
            // Activity Container Aria Labels and Descriptions
            vdpActivity_cardStackWithVisualAriaLabel: "Card Stack With Visuals",
            vdpActivity_cardStackWithVisualAriaDescription: "Put the cards into the correct order",
            vdpActivity_revealCardAriaLabel: "Reveal Card Step",
            vdpActivity_revealCardAriaDescription: "Select the card to reveal it",
            vdpActivity_dragAndDropAriaLabel: "Drag and Drop Step",
            vdpActivity_dragAndDropAriaDescription: "Drag and drop the cards in the correct columns",
            vdpActivity_matchingStepAriaLabel: "Matching Cards Step",
            vdpActivity_matchingStepAriaDescription: "Move the card to the correct place to match it",
            vdpActivity_recallStepAriaLabel: "Recall Step",
            vdpActivity_recallStepAriaDescription: "This is a recall step, follow the instructions",
            vdpActivity_multipleChoiceAriaLabel: "Multiple Choice Step",
            vdpActivity_multipleChoiceAriaDescription: "Choose from the multiple choice options.",
            vdpActivity_textEntryAriaLabel: "Text Entry Activity",
            vdpActivity_textEntryAriaDescription: "Submit your response into the text field",
            vdpActivity_singleAssetStepAriaLabel: "Single Asset Step",
            vdpActivity_singleAssetStepAriaDescription: "Follow the instructions.",
            vdpActivity_comparisonStepAriaLabel: "Comparison Step",
            vdpActivity_comparisonStepAriaDescription: "Follow the instructions.",
            vdpActivity_FirstTitlePosition: "You are at the first item",
            vdpActivity_LastTitlePosition: "You are at the last item",
            vdpActivity_videoStepAriaLabel: "Video Step",
            vdpActivity_videoStepAriaDescription: "In this step, a video will be show to the participants",
            vdpActivity_progressCheckAriaLabel: "Progress Check",
            vdpActivity_progressCheckAriaDescription:
                "You are ahead of pace. Try not to rush through the remaining material",
            vdpActivity_exitStepAriaLabel: "Exit Step",
            vdpActivity_exitStepAriaDescription: "We've completed our experience. You may now close this window",

            vdpClipboard_copied: "Copied to clipboard!",
            vdpClipboard_copy: "Copy to clipboard",
            vdpClipboard_error: "Unable to copy to clipboard",
            vdpHud_hide: "Hide",
            vdpHud_show: "Show",
            vdpHud_changeNavigatorTitle: "Change the navigator to a new person.",
            vdpHud_changeNavigatorText1:
                'You selected "Change the Navigator". This will randomly pick another navigator and will briefly disable all interactions on this page.',
            vdpHud_changeNavigatorText2: "Are you sure you want to change the navigator?",
            vdpHud_close: "Close",
            vdpHud_accept: "Change the Navigator",
            vdpHud_back: "Back",
            vdpHud_next: "Next",
            vdpHud_endExperience: "End Experience",
            vdpHud_defaultExitMsg:
                "We’ve completed our experience. Thanks for participating! We hope you found today’s discussion insightful. You may close this window.",
            vdpHud_navigatorReminder: "You're the navigator. Please read the following:",
            vdpHud_timerNoTimeSpecified: "No time specified for section",
            vdpHud_timerRemaining: "remaining in section",
            vdpHud_timerLessThanOneMinute: "Less than one minute left",
            vdpHud_timerOver: "over",
            vdpHud_roleNone: "None Assigned",
            vdpHud_infoTableSection: "Section",
            vdpHud_infoTableRecommended: "Recommended",
            vdpHud_infoTableActual: "Actual",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Remaining",
            vdpHud_infoTeamButton: "Team",
            vdpHud_infoTeamButtonDescription:
                "Select this item to view a list of the session participants, rotate the navigator role to a random participant, and change your participant name.",
            vdpHud_infoTeamParticipantJoining: "User joining...",
            vdpHud_navigator: "Navigator",
            vdpHud_facilitator: "Facilitator",
            vdpHud_facilitatorMenuMakeNavigator: "Make Navigator",
            vdpHud_facilitatorMenuRemoveFacilitator: "Remove Facilitator Role",
            vdpHud_removeFacilitatorWarningConfirm: "Confirm",
            vdpHud_removeFacilitatorWarningCancel: "Cancel",
            vdpHud_removeFacilitatorWarningMessage:
                "You are about to leave your role as facilitator. Are you sure you want to continue?",
            vdpHud_progressOnPaceMessage: "On Pace",
            vdpHud_progressAheadOfPaceMessage: "Ahead of Pace",
            vdpHud_progressBehindPaceMessage: "Behind Pace",
            vdpHud_progressTimeRemaining: "{0} remaining in section",
            vdpHud_progressStepsInSection: "Page {0} of {1}",
            vdpHud_zoomControlsTooltip: "Visual Zoom Controls",
            vdpHud_zoomControlsDescription: "Use these actions to zoom in and out of the visual.",
            vdpHud_zoomControlsZoomIn: "Zoom In",
            vdpHud_zoomControlsZoomOut: "Zoom Out",
            sidebar_scheduleButton: "Schedule",
            sidebar_scheduleButtonDescription:
                "View a summary estimating the time for which each section is expected to take and how much time has been spent in each section.",
            sidebar_teamAssignNavigator: "Assign Navigator",
            sidebar_teamChooseRandomly: "Choose Randomly",
            sidebar_teamChooseHelperText:
                "Chooses an eligible participant at random to serve as the navigator. Participants will not be chosen again until everyone has had a turn.",
            sidebar_editNameMenuItem: "Edit Name",
            sidebar_editNameSave: "Save Changes",
            sidebar_nameEditPlaceholder: "Enter the name you want to be called...",
            sidebar_manageRolesMenuLabel: "Manage Roles",
            sidebar_navigatorDescription:
                "Reads questions, prompts, and instructions aloud. Advances the group through the experience by selecting Next and following other directions noted on each screen.",
            sidebar_facilitatorDescription:
                "Serves as a guide throughout the session to make sure the group has a rich discussion and the conversation stays on track.",
            sidebar_previousButton: "Previous",
            sidebar_previousButtonDescription:
                "Select this button to advance the group's session to the previous page.",
            sidebar_nextButton: "Next",
            sidebar_nextButtonDescription: "Select this button to advance the group's session to the next page.",
            sidebar_lockTooltip: "Only the navigator can change the page.",
            sidebar_navigatorBadge: "You're the Navigator",
            sidebar_navigatorBadgeDescription:
                "The navigator is responsible for advancing the group to the next steps in the session. Use the 'Next' and 'Previous' buttons to navigate the group.",
            sidebar_facilitatorBadge: "You're a Facilitator",
            sidebar_introductionLabel: "Introduction",
            sidebar_introductionInstruction: "Read aloud to the group:",
            sidebar_directionsLabel: "Directions",
            sidebar_nonNavigatorDirectionsLabel: "{0} does the following:",
            sidebar_directionsInstruction: "Do the following:",
            sidebar_facilitationNotesLabel: "Facilitation Notes",
            sidebar_skipButtonDescription:
                "Select this button to skip the welcome section for the entire group's session.",
            genUrl_copyLink: "Copy link",
            genUrl_sessionLinkDetails:
                "These session links will disappear when you refresh the page. Be sure to download or copy these links before leaving this page.",
            genUrl_exportAsCsv: "Export as CSV",
            genUrl_copyTable: "Copy table",
            genUrl_linkExpiration: "Link Expiration",
            genUrl_link: "Link",
            genUrl_title: "{0} Session Link Generator",
            genUrl_experience: "Experience: {0}",
            genUrl_description:
                "This portal lets you generate session links for this experience. To generate session links, enter the number of sessions you would like to generate, pick a date the sessions should expire, and select Generate Sessions. Once these links are generated, copy them and paste into your favorite text editor or spreadsheet program. Once you refresh the page, all the links will disappear.",
            genUrl_numSessions: "Number of sessions:",
            genUrl_sessionsExpirationLabel: "Session links expire on:",
            genUrl_sessionsOpenAtLabel: "Session links are valid starting on:",
            genUrl_numSessionsInputLabel: "Number of sessions",
            genUrl_sessionLanguageLabel: "Session Language",
            genUrl_numSessionsInputTypeError: "Must be a number",
            genUrl_closesOnError: "Pick a date in the future",
            genUrl_experienceClosesOnError: "Pick a date prior to {0}",
            genUrl_notifSuccessSingle: "Successfully generated {0} link",
            genUrl_notifSuccessMultiple: "Successfully generated {0} links",
            genUrl_notifErrorGenerating: "Unable to generate sessions. Please try again or contact administrator.",
            genUrl_notifErrorProcessing: "Unable to process. Contact an administrator.",
            genUrl_sessionsExpireToday: "Sessions will expire today",
            genUrl_sessionsExpireInOneDay: "Sessions will expire in {0} day",
            genUrl_sessionsExpireInDays: "Sessions will expire in {0} days",
            genUrl_sessionsOpenToday: "Sessions will open today",
            genUrl_sessionsOpenOneDay: "Sessions will open in {0} day",
            genUrl_sessionsOpenDays: "Sessions will open in {0} days",
            genUrl_generate: "Generate sessions",
            genUrl_schedulingLinkExpired: "Scheduling link has expired.",
            genUrl_error: "Error",
            genUrl_dataCollectionLabel: "Data Collection",
            genUrl_dataCollectionDescription:
                "Session links are intended for a scheduled experience. Turn off this button if the links are intended for previewing or conducting a demo or training of the experience.",
            genUrl_dataCollectionDefaultLabel: "Data Collection Default",
            genUrl_emailContact: "Email Contact",
            genUrl_goToSessionRepoReport: "Reporting Tool",
            genUrl_archiveButton: "Archive",
            genUrl_resurrectButton: "Resurrect",
            genUrl_destroyButton: "Destroy",
            vdpLoading_waitForContent: "Please wait while your content loads…",
            vdpNotFound_title: "Virtual Dialogue Platform",
            vdpNotFound_message: "The page you are looking for could not be found.",
            vdpRenderer_refresh: "Refresh",
            vdpRenderer_refreshDescription:
                "You need to refresh the page in order to attempt to reconnect to the session.",
            vdpRenderer_sessionDisconnectedHeader: "You've been disconnected.",
            vpdRenderer_inactiveDisconnectedBody:
                "You've been disconnected due to inactivity. Select the button below to attempt to reconnect.",
            vdpRenderer_inactiveHeader: "You're inactive.",
            vdpRenderer_inactiveBody:
                "You've been inactive for too long. Select the button below to remain active or you will be removed from the session when the timer runs out.",
            vdpRenderer_setSelfActiveButton: "Set yourself as active",
            vdpRenderer_setSelfActiveButtonDescription:
                "Select this button to remain an active and connected participant of the current session.",
            vdpRenderer_sessionDisconnected: "Attempting to reconnect",
            vdpRenderer_sessionDisconnectedReload: "Please reload",
            vdpRenderer_endOfSession: "End of Session",
            vdpRenderer_invalidMapUrl:
                "An invalid URL was provided for the visual. Please contact Root for assistance.",
            vdpRenderer_unexpectedError: "An unexpected system error has occurred. Here are the technical details:",
            vdpRenderer_unexpectedErrorMsg: "An unexpected error has occurred: unknown session state {0}",
            vdpRenderer_defaultEndingMessage: "You may now close your window and disconnect from audio.",
            vdpErrorModal_title: "Cannot Load Page",
            vdpErrorModal_message: "Something went wrong! Please contact us for assistance at: vmap.help@rootinc.com",
            vdpErrorModal_body: "The error may be resolved by reloading this page or selecting below:",
            vdpErrorModal_button: "Reload this experience.",
            vdpNameEntry_welcomeText: "Welcome",
            vdpNameEntry_defaultWelcomeMessage:
                "This virtual dialogue session will be fully interactive. Prepare to explore information and share your thoughts and opinions with your peers.",
            vdpNameEntry_inputName: "What do you want to be called?",
            vdpNameEntry_inputNamePlaceholder: "Enter the name you want to be called:",
            vdpNameEntry_inputDepartment: "What department are you in?",
            vdpNameEntry_inputEmail: "What's your email address?",
            vdpNameEntry_inputTeam: "What team are you on?",
            vdpNameEntry_saveDescription: "Submit what you would like to be called and await the start of the session.",
            vdpNameEntry_continueDescription: "Submit what you would like to be called and join the ongoing session.",
            vdpNameEntry_identifiersName: "Please enter name",
            vdpNameEntry_identifiersDuplicateName:
                "It looks like there's already a {0} connected today. Would you like to try entering a different name so we can tell you apart? Consider adding an initial for your last name or entering a nickname.",
            vdpNameEntry_identifiersDepartment: "Please enter department name",
            vdpNameEntry_identifiersEmail: "Please enter email",
            vdpNameEntry_identifiersInvalidEmailFormat: "Invalid email format",
            vdpNameEntry_identifiersTeam: "Please enter team name",
            vdpNameEntry_welcomeHeader: "Nice to meet you, {0}!",
            vdpNameEntry_welcomeAdvance: "Meet Your Team",
            vdpNameEntry_claimFacilitatorTitle: "Claim the Facilitator Role",
            vdpNameEntry_facilitatorClaimed: "Facilitator Role Claimed",
            vdpNameEntry_claimFacilitatorButton: "Claim Facilitator",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancel",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "If you are the facilitator of this session, select Claim Facilitator below.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "As facilitator, you will have control of the first few pages to get us started. Then, the system will automatically select a new navigator.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "If you have been invited to this session as a participant, please select Cancel.",
            vdpNameEntry_smallScreenWarningTitle: "We're glad you're here!",
            vdpNameEntry_smallScreenWarningBody: "... For the best experience, please log in from a laptop or desktop.",
            vdpNameEntry_smallScreenWarningDismiss: "Dismiss",
            vdpNameEntry_numParticipantsInSession: "{0} participants are waiting...",
            vdpNameEntry_oneParticipantsInSession: "1 participant is waiting...",
            vdpNameEntry_save: "Save",
            vdpNameEntry_continue: "Continue",
            vdpNameEntry_thanks: "Thanks, {0}. We'll get started soon!",
            vdpNameEntry_intro: "Enter your name and wait for others to join. We'll get started soon!",
            vdpNameEntry_introStarted: "Enter your name and join your peers.",
            vdpNameEntry_navDirections: "Wait for the team to join.",
            vdpValidationErrorMinLength: "Please enter at least {0} characters.",
            vdpValidationErrorMaxLength: "Please enter no more than {0} characters.",
            toolbar_stepOf: "Section {0}, Page {1} - {2} ({3})",
            toolbar_intro: "Step {0} of Section {1}",
            toolbar_welcome: "Welcome Sequence",
            toolbar_exit: "Exit Sequence",
            toolbar_reloadContent: "Reload Content",
            toolbar_commentCreationButton: "Comment ({0})",
            toolbar_buildComments: "Build Comments",
            toolbar_commentListButton: "View Comments ({0})",
            toolbar_commentCreationTitle: "Add Build Comment",
            toolbar_commentCreationDescription:
                "Comments will be saved and sent to the build team. Please include your name or initials with your comment.",
            toolbar_commentCreationCancelButton: "Cancel",
            toolbar_commentCreationSubmitButton: "Post Comment",
            toolbar_commentCreationSubmitButtonDisabled: "Posting Comment...",
            toolbar_commentCreationPlaceholder: "Enter Comment...",
            toolbar_previewMode: "Preview Mode:",
            toolbar_aboutPreviewMode: "About Preview Mode",
            toolbar_focusAreaSelector: "Focus Area Selector",
            toolbar_hotspotSelector: "Hotspot Selector",
            toolbar_previewModeModalTitle: "Preview Mode",
            toolbar_previewModeModalFirstIntroduction:
                "You are viewing this experience in Preview Mode. This view is designed for Root internal review only and provides access to Authoring Tools.",
            toolbar_previewModeModalSecondIntroduction:
                "These options are only available in Preview Mode and will not be visible to the client or participants.",
            toolbar_previewModeModalReloadContent: "Reload Content",
            toolbar_previewModeModalReloadContentDescription:
                "Use this button to refresh the experience with the latest updates from Contentful without having to reload the session.",
            toolbar_previewModeModalComments: "Comments",
            toolbar_previewModeModalCommentsDescription:
                "Add a comment to provide feedback or note any changes that need to be made. Comments will be captured in Contentful for the build team.",
            toolbar_previewModeModalMapCoordinates: "Visual Coordinates",
            toolbar_previewModeModalMapCoordinatesDescription: "Copy the coordinates of the visual.",
            toolbar_previewModeModalHotspotSelector: "Hotspot Selector",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Preview hotspot locations and copy coordinates for use in Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Coordinates Selector",
            coordinatePreview_hotspotMode: "Hotspot Mode",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Hotspot coordinates have been copied to your clipboard.",
            coordinatePreview_focusAreaMode: "Focus Area Mode",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Focus area coordinates have been copied to your clipboard.",
            coordinatePreview_directionsTitle: "Coordinates Selector Directions",
            coordinatePreview_hotspotDirectionsLineOne: "Select a point on the visual to create a hotspot.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Select the Copy button on each hotspot to copy coordinates to Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne: "Select and drag to draw a rectangle on the visual.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Place the rectangle over the area that will be visible for a step.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Select the Copy button on each focus area to copy coordinates to Contentful.",
            coordinatePreview_reset: "Reset",
            coordinatePreview_done: "Done",
            navigatorDefaultInstructions_selectNextToContinue: "Select ***Next*** to continue.",
            navigatorDefaultInstructions_progressCheckLineOne: "We've reached the end of section {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "We’re on pace. Let’s keep up the good work!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "We’re ahead of pace. Let’s try not to rush through the remaining material.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "We’re behind pace. We'll need to move faster to complete the session on time.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "On the next page, we’ll introduce a new navigator for section {0}.",
            maintenanceWindow_heading: "Maintenance is scheduled on {0}, {1} from {2} - {3} {4}.",
            maintenanceWindow_body: "The application may not be available during this time.",
            maintenanceError_overlap:
                "The dates you've chosen conflict with a scheduled maintenance period on {0}. Maintenance occurs every week from {1} to {2}. Please make sure the starting and expiration dates do not overlap with a maintenance window.",
            progressCheck_sectionTitle: "Section {0} of {1}",
            progressCheck_paceCheckTitle: "Session Pace Check",
            progressCheck_paceMessageOnPace: "Keep up the good work!",
            progressCheck_paceMessageAheadOfPace: "Try not to rush through the remaining material.",
            progressCheck_paceMessageBehindPace: "You'll need to move faster to complete the session on time.",
            progressCheck_navigatorChangeTitle: "Navigator Change",
            progressCheck_navigatorChangeMessage:
                "Thank you for leading, {0}. A new navigator will be assigned on the next page.",
            vdpIntro_title: "Introduction",
            vdpIntro_subtitle: "A few quick things before we get started...",
            vdpIntro_joinTheTeam: "Join the Team",
            vdpIntro_facilitatorRole: "Facilitator Role",
            vdpIntro_navigatorRole: "Navigator Role",
            vdpIntro_participantRole: "Participant Role",
            vdpIntro_iceBreaker: "Meet the Team",
            vdpIntro_exploreTheVisual: "The Visual",
            vdpIntro_skipShort: "Skip",
            vdpIntro_skipLong: "Skip Introduction",
            vdpIntro_facilitatorInstructionsOne:
                "Welcome! Today’s session is different from other courses or training you may have attended.",
            vdpIntro_facilitatorInstructionsTwo:
                "There is no teacher or trainer – you’ll learn from each other and the information on screen.",
            vdpIntro_facilitatorInstructionsThree:
                "My role as facilitator is to keep the conversation on track and on time.",
            vdpIntro_navigatorInstructionsOne:
                "For the next few screens, I’ll also serve as our navigator. The navigator’s role is to read the Introduction text on each screen, select the ***Next*** button to move us through the experience, and follow other directions as prompted.",
            vdpIntro_navigatorInstructionsTwo:
                "The navigator role will be assigned randomly and will change every few screens.",
            vdpIntro_participantInstructionsOne:
                "You are all participants! Your role is to actively participate by sharing your perspectives with the group. You’ll help the session run smoothly by taking turns talking, respecting others’ thoughts and opinions, and keeping the conversation on track.",
            vdpIntro_participantInstructionsTwo:
                "You can see who is in the session and who has each role using the ***Team*** button at the lower left.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Next, we’ll each get a chance to see what the navigator role is like as we introduce ourselves.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "When you see the ***You’re the Navigator*** banner above the Introduction text, it’s your turn to read and follow the directions. Does anyone have any questions?",
            vdpIntro_iceBreakerInstructionsOne: "Hi, I’m {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "One fact about me is _______.",
            vdpIntro_mapInstructionsOne: "Now that we’ve all met, let’s learn more about our session today.",
            vdpIntro_mapInstructionsTwo:
                "Our conversation will center on an image called a *Learning Map*® visual. It’s an illustrated metaphor representing aspects of our business that we’ll explore together. ",
            vdpIntro_iceBreakerIntroDirections: "Answer any questions.",
            vdpEnd_title: "Thank you.",
            vdpEnd_body:
                "We’ve completed our experience. Thanks for participating! We hope you found today’s discussion insightful.",
            vdpEnd_surveyRequest:
                "Please take a few minutes to share your thoughts and reactions to the information we discussed. Your feedback is important to our success.",
            vdpEnd_surveyThankYou: "Thank you for your response.",
            vdpEnd_sessionComplete: "The session is complete.",
            vdpEnd_closeWindow: "You may close this window.",
            privacyBanner_title: "Data Privacy",
            privacyBanner_message:
                "The protection of your personal data is very important to Accenture and any personal data you provide through this tool may be processed by Accenture. " +
                "Accenture is committed to keeping your personal data secure and processing it, in accordance with applicable privacy laws and our internal policies. " +
                "Personal data Accenture processes may be transferred or be accessible internationally throughout Accenture’s global business and between its entities and affiliates," +
                "and third parties. Before using this tool, Accenture invites you to carefully read its privacy statement [Privacy Statement]({1}) and [Cookies Policy]({0}), " +
                "which includes important information on why and how Accenture is processing your personal data.",
            privacyBanner_dismiss: "Agree",
            privacyBanner_dismissDescription:
                "This action will dismiss the notice detailing the site's privacy and cookie policies.",
            adminMessage_unauthorized: "You are unauthorized to take the following action: {0}.",
            adminMessage_unauthorized_viewExp: "view all experiences",

            //Session Report Strings
            sessionReport_submitButton: "Generate Report",
            sessionReport_header_title: "{0} Session Reporting Tool",
            sessionReport_header_experience: "Experience Name: {0}",
            sessionReport_header_errorTitle: "Error Loading",
            sessionReport_header_errorSubtitle: "Could not retrieve experience information.",
            sessionReport_switch_unknown: "Unrecognized Report Result Type",
            sessionReport_results_downloadResults: "Download Results",
            sessionReport_results_noRecordsTitle: "No Records Found",
            sessionReport_results_noRecordsSubTitle: "There is no data that fits that criteria.",
            sessionReport_results_errorTitle: "Error Generating Report",
            //Session Basic Info Report Strings
            sessionReport_basicInfo_startDate: "Start Date",
            sessionReport_basicInfo_endDate: "End Date",
            sessionReport_basicInfo_useSeedData: "Use Seed Data",
            sessionReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            sessionReport_switch_basicInfo: "Basic Session Info",
            //Activity Basic Info Report Strings
            activityReport_basicInfo_startDate: "Start Date",
            activityReport_basicInfo_endDate: "End Date",
            activityReport_basicInfo_useSeedData: "Use Seed Data",
            activityReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            activityReport_switch_basicInfo: "Basic Activity Info",
            //Activity Basic Info Aggregated Report Strings
            activityReport_basicInfoAggregated_startDate: "Start Date",
            activityReport_basicInfoAggregated_endDate: "End Date",
            activityReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            activityReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            activityReport_switch_basicInfoAggregated: "Basic Activity Info Aggregated",
            //Multiple Choice Basic Info Report Strings
            multipleChoiceReport_basicInfo_startDate: "Start Date",
            multipleChoiceReport_basicInfo_endDate: "End Date",
            multipleChoiceReport_basicInfo_useSeedData: "Use Seed Data",
            multipleChoiceReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            multipleChoiceReport_switch_basicInfo: "Basic Multiple Choice Info",
            multipleChoiceReport_basicInfo_activityId: "Enter Activity ID",
            //Multiple Choice Basic Info Aggregated Report Strings
            multipleChoiceReport_basicInfoAggregated_startDate: "Start Date",
            multipleChoiceReport_basicInfoAggregated_endDate: "End Date",
            multipleChoiceReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            multipleChoiceReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            multipleChoiceReport_switch_basicInfoAggregated: "Basic Multiple Choice Info Aggregated",
            multipleChoiceReport_basicInfoAggregated_activityId: "Enter Activity ID",
            //Drag And Drop Basic Info Report Strings
            dragAndDropReport_basicInfo_useSeedData: "Use Seed Data",
            dragAndDropReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            dragAndDropReport_basicInfo_startDate: "Start Date",
            dragAndDropReport_basicInfo_endDate: "End Date",
            dragAndDropReport_switch_basicInfo: "Basic Drag & Drop Info",
            dragAndDropReport_basicInfo_activityId: "Enter Activity ID",
            //Drag And Drop Basic Info Aggregated Report Strings
            dragAndDropReport_basicInfoAggregated_useSeedData: "Use Seed Data",
            dragAndDropReport_basicInfoAggregated_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            dragAndDropReport_basicInfoAggregated_startDate: "Start Date",
            dragAndDropReport_basicInfoAggregated_endDate: "End Date",
            dragAndDropReport_switch_basicInfoAggregated: "Basic Drag & Drop Info Aggregated",
            dragAndDropReport_basicInfoAggregated_activityId: "Enter Activity ID",
            //Drag And Drop Basic Info Report Strings
            editableCardReport_basicInfo_useSeedData: "Use Seed Data",
            editableCardReport_basicInfo_useSeedData_help:
                "Indicates if the generated report should include random, sample data that was generated. This option is meant for testing purposes only.",
            editableCardReport_basicInfo_startDate: "Start Date",
            editableCardReport_basicInfo_endDate: "End Date",
            editableCardReport_switch_basicInfo: "Basic Editable Card Info",
            editableCardReport_basicInfo_activityId: "Enter Activity ID",
            // confirm dialog
            confirmDialog_confirmValuePrompt: "To confirm this action, enter the value __*{0}*__ in the input below.",
            confirmDialog_confirmButton: "Confirm",
            confirmDialog_closeButton: "Close",
            confirmDialog_error_checkBox: "You must check the box to successfully confirm this action.",
            confirmDialog_error_confirmValue:
                "You must enter the value __*{0}*__ in order to successfully confirm this action.",
            confirmDialog_scheduler_title: "Confirm Archive",
            confirmDialog_scheduler_body:
                "You are about to archive the experience named {0}. This will disable access to the experience, including any session links that may have been generated for future sessions. To confirm this action, check the box and enter the experience's name.",
            confirmDialog_scheduler_resurrectTitle: "Confirm Resurrect",
            confirmDialog_scheduler_resurrectBody:
                "You are about to resurrect the experience named {0}. This will enable access to the experience. Any generated links for future sessions will become valid. Please note that you may need to update the expiration date for the experience after this action completes. To confirm this action, check the box and enter the experience's name.",
            confirmDialog_scheduler_destroyTitle: "Confirm Destroy",
            confirmDialog_scheduler_destroyBody:
                "You are about to destroy the experience named {0}. This is an unrecoverable action that cannot be undone. Prior to executing this action you must confirm the following: \n" +
                "1. All assets of the experience have been downloaded and archived in project land;\n" +
                "2. A video of navigating through the experience has been taken and stored in project land;\n" +
                "3. The project contacts (if any are listed from the Contentful field in our system) have been contacted to verify that deleting is acceptable;\n" +
                "4. The session repository data - text entry activities and the general repository - have been downloaded and archived in project land.;\n\n" +
                "To confirm this action, check the box and enter the experience's name.",

            confirmDialog_dataCollectionDefault_title: "Confirm Change to Data Collection Default",
            confirmDialog_dataCollectionDefault_body:
                "You have chosen to {0} the data collection feature on the {1} experience. This will affect all sessions that are generated in the future for this experience. Are you sure you want to {0} data collection for this experience?",
            confirmDialog_dataCollectionDefault_body_enable: "enable",
            confirmDialog_dataCollectionDefault_body_disable: "disable",
            confirmDialog_dataCollection_off:
                "This action will disable data collection.  Please confirm that at least one of the following conditions is true:" +
                "\n1. These links are for the purpose of previewing or conducting a demo of the experience." +
                "\n2. These links are for a scheduled experience: I am aware of the contractual data agreement and have authority to disable data collection for this experience.",
            confirmDialog_dataCollection_on:
                "This action will enable data collection. Please confirm the following is true:" +
                "\n1. I am aware of the contractual data agreements and have authority to enable data collection for this experience." +
                "\n2. These links are intended for a scheduled experience (not a demo or preview)",
            // Enable Navigator Manually Entries
            confirmDialog_enable_NavigatorTitle: "Are you sure?",
            confirmDialog_enable_NavigatorBody:
                "By selecting 'Confirm', you'll get the group started by reading the information on the next few pages aloud. Select 'Close' if you prefer not to be the first reader",
            vdpNameEntry_NavigatorSelectionMessage:
                "Well need a navigator to read the instructions aloud to get us started",
            vdpNameEntry_NavigatorButtonMessage: "Select here to serve as the first reader",
        },
    },
    es: {
        name: "Spanish",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "No se puede encontrar la actividad {0}",
            vdpActivity_closeModal: "Cerrar",
            vdpActivity_flipCard: "Dar vuelta",
            vdpActivity_correctExclamation: "¡Correcto!",
            vdpActivity_correct: "Correcto",
            vdpActivity_correctAnswerBadge: "Respuesta correcta",
            vdpActivity_partiallyCorrect: "¡Parcialmente correcto!",
            vdpActivity_incorrect: "Incorrecto",
            vdpActivity_notCorrect: "No es correcto",
            vdpActivity_continue: "Continuar",
            vdpActivity_checkAnswer: "Ver respuesta",
            vdpActivity_submit: "Enviar",
            vdpActivity_ready: "Listo.",
            vdpActivity_cardsPlaced: "Se colocaron todas las tarjetas.",
            vdpActivity_cardsCorrectHeader: "¡Éxito!",
            vdpActivity_cardsCorrect: "Se colocaron todas las tarjetas con éxito.",
            vdpActivity_cardsCorrectContinue: "Continuar a la siguiente actividad.",
            vdpActivity_cardsPartiallyCorrectHeader: "No exactamente.",
            vdpActivity_cardsPartiallyCorrect: "Revisar las respuestas incorrectas y probar moverlas a otro lugar.",
            vdpActivity_cardsCheckAnswers: "Vea sus respuestas",
            vdpActivity_textEntryPlaceholder: "Su respuesta",
            vdpActivity_textEntryNavigatorPrompt: "El navegador, {0}, ingresará el texto a continuación",
            vdpActivity_textEntryPrompt: "Ingrese el texto a continuación:",
            vdpActivity_textEntrySubmit: "Enviar",
            vdpActivity_selectOne: "Seleccionar una",
            vdpActivity_selectOneOrMany: "Seleccionar una o más",
            vdpActivity_defaultCurrentParticipantName: "Yo",
            vdpActivity_hotspotTooltip: "Haga clic en los puntos de acceso para ver más.",
            vdpClipboard_copied: "¡Copiado en portapapeles!",
            vdpClipboard_copy: "Copiar en portapapeles",
            vdpClipboard_error: "No se pudo copiar en portapapeles",
            vdpHud_hide: "Ocultar",
            vdpHud_show: "Mostrar",
            vdpHud_changeNavigatorTitle: "Cambiar el navegador a otra persona.",
            vdpHud_changeNavigatorText1:
                "Seleccionó “Cambiar el navegador”. Esto elegirá al azar a otro navegador y deshabilitará brevemente todas las interacciones en esta página.",
            vdpHud_changeNavigatorText2: "¿Está seguro de que desea cambiar el navegador?",
            vdpHud_close: "Cerrar",
            vdpHud_accept: "Cambiar el navegador",
            vdpHud_back: "Volver",
            vdpHud_next: "Siguiente",
            vdpHud_endExperience: "Finalizar la experiencia",
            vdpHud_defaultExitMsg:
                "Hemos completado nuestra experiencia. ¡Gracias por participar! Esperamos que el debate de hoy les haya resultado informativo. Puede cerrar esta ventana.",
            vdpHud_navigatorReminder: "Usted es el navegador. Lea lo siguiente:",
            vdpHud_timerNoTimeSpecified: "No hay tiempo especificado para la sección",
            vdpHud_timerRemaining: "restantes en la sección",
            vdpHud_timerLessThanOneMinute: "Queda menos de un minuto",
            vdpHud_timerOver: "más",
            vdpHud_roleNone: "No se asignó",
            vdpHud_infoTableSection: "Sección",
            vdpHud_infoTableRecommended: "Recomendado",
            vdpHud_infoTableActual: "Real",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restante",
            vdpHud_infoTeamButton: "Equipo",
            vdpHud_infoTeamParticipantJoining: "Usuario uniéndose…",
            vdpHud_navigator: "Navegador",
            vdpHud_facilitator: "Facilitador",
            vdpHud_facilitatorMenuMakeNavigator: "Designar como navegador",
            vdpHud_facilitatorMenuRemoveFacilitator: "Quitar el rol de facilitador",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmar",
            vdpHud_removeFacilitatorWarningCancel: "Cancelar",
            vdpHud_removeFacilitatorWarningMessage:
                "Está por abandonar su rol de facilitador. ¿Está seguro de que desea continuar?",
            vdpHud_progressOnPaceMessage: "Cumple los tiempos",
            vdpHud_progressAheadOfPaceMessage: "Adelantado",
            vdpHud_progressBehindPaceMessage: "Atrasado",
            vdpHud_progressTimeRemaining: "{0} restantes en la sección",
            vdpHud_progressStepsInSection: "Página {0} de {1}",
            vdpHud_zoomControlsTooltip: "Controles de acercar y alejar el material visual",
            sidebar_scheduleButton: "Programar",
            sidebar_teamAssignNavigator: "Asignar navegador",
            sidebar_teamChooseRandomly: "Elegir al azar",
            sidebar_teamChooseHelperText:
                "Elige un participante elegible al azar para que sea el navegador. Los participantes no serán elegidos otra vez hasta que todos hayan tenido su turno.",
            sidebar_editNameMenuItem: "Editar nombre",
            sidebar_editNameSave: "Guardar cambios",
            sidebar_nameEditPlaceholder: "Ingrese el nombre por el que quiere que lo llamen…",
            sidebar_manageRolesMenuLabel: "Administrar roles",
            sidebar_navigatorDescription:
                "Lee preguntas, indicaciones e instrucciones en voz alta. Hace avanzar al grupo por la experiencia seleccionando Siguiente y siguiendo otras indicaciones que figuran en cada pantalla.",
            sidebar_facilitatorDescription:
                "Sirve como guía durante la sesión para asegurarse de que el grupo tenga un debate rico y que la conversación se mantenga encaminada.",
            sidebar_previousButton: "Anterior",
            sidebar_nextButton: "Siguiente",
            sidebar_lockTooltip: "Solo el navegador puede cambiar la página.",
            sidebar_navigatorBadge: "Usted es el navegador",
            sidebar_facilitatorBadge: "Usted es el facilitador",
            sidebar_introductionLabel: "Introducción",
            sidebar_introductionInstruction: "Lea en voz alta al grupo:",
            sidebar_directionsLabel: "Indicaciones",
            sidebar_nonNavigatorDirectionsLabel: "{0} hace lo siguiente:",
            sidebar_directionsInstruction: "Haga lo siguiente:",
            sidebar_facilitationNotesLabel: "Notas de facilitación",
            genUrl_copyLink: "Copiar enlace",
            genUrl_sessionLinkDetails:
                "Estos enlaces de sesiones desaparecerán cuando usted actualice la página. Asegúrese de descargar o copiar estos enlaces antes de abandonar esta página.",
            genUrl_exportAsCsv: "Exportar como CSV",
            genUrl_copyTable: "Copiar tabla",
            genUrl_linkExpiration: "Vencimiento del enlace",
            genUrl_link: "Enlace",
            genUrl_title: "Generador de enlaces de sesiones {0} ",
            genUrl_experience: "Experiencia: {0}",
            genUrl_description:
                "Este portal le permite generar enlaces de sesiones para esta experiencia. Para generar enlaces de sesiones, ingrese la cantidad de sesiones que quiere generar, elija una fecha de vencimiento para las sesiones y seleccione Generar sesiones. Una vez generados estos enlaces, cópielos y péguelos en su editor de texto o programa de hoja de cálculo favorito. Cuando actualice la página, desaparecerán los enlaces.",
            genUrl_numSessions: "Cantidad de sesiones:",
            genUrl_sessionsExpirationLabel: "Los enlaces de sesiones vencen el:",
            genUrl_sessionsOpenAtLabel: "Los enlaces de sesiones son válidos desde el:",
            genUrl_numSessionsInputLabel: "Cantidad de sesiones",
            genUrl_sessionLanguageLabel: "Idioma de la sesión",
            genUrl_numSessionsInputTypeError: "Debe ser un número",
            genUrl_closesOnError: "Elija una fecha futura",
            genUrl_experienceClosesOnError: "Elija una fecha antes del {0}",
            genUrl_notifSuccessSingle: "Enlace {0} generado con éxito",
            genUrl_notifSuccessMultiple: "Enlaces {0} generados con éxito",
            genUrl_notifErrorGenerating: "No se pudo generar sesiones. Intente nuevamente o contacte al administrador.",
            genUrl_notifErrorProcessing: "No se pudo procesar. Contacte al administrador.",
            genUrl_sessionsExpireToday: "Las sesiones vencerán hoy",
            genUrl_sessionsExpireInOneDay: "Las sesiones vencerán en {0} día",
            genUrl_sessionsExpireInDays: "Las sesiones vencerán en {0} días",
            genUrl_sessionsOpenToday: "Las sesiones se iniciarán hoy",
            genUrl_sessionsOpenOneDay: "Las sesiones se iniciarán en {0} día",
            genUrl_sessionsOpenDays: "Las sesiones se iniciarán en {0} días",
            genUrl_generate: "Generar sesiones",
            genUrl_schedulingLinkExpired: "Venció el enlace de programación.",
            genUrl_error: "Error",
            genUrl_dataCollectionLabel: "Recopilación de datos",
            genUrl_emailContact: "Contacto de correo electrónico",
            vdpLoading_waitForContent: "Espere mientras se carga su contenido…",
            vdpNotFound_title: "Plataforma de diálogo virtual",
            vdpNotFound_message: "La página que está buscando no se pudo encontrar.",
            vdpRenderer_refresh: "Actualizar",
            vdpRenderer_sessionDisconnectedHeader: "Se ha desconectado.",
            vpdRenderer_inactiveDisconnectedBody:
                "Se ha desconectado por inactividad. Seleccione el botón abajo para intentar reconectarse.",
            vdpRenderer_inactiveHeader: "Está inactivo.",
            vdpRenderer_inactiveBody:
                "Ha estado inactivo por demasiado tiempo. Seleccione el botón abajo para seguir activo o se lo eliminará de la sesión cuando se agote el tiempo del cronómetro.",
            vdpRenderer_setSelfActiveButton: "Configúrese como activo",
            vdpRenderer_sessionDisconnected: "Intentando reconectar",
            vdpRenderer_sessionDisconnectedReload: "Vuelva a cargar",
            vdpRenderer_endOfSession: "Fin de la sesión",
            vdpRenderer_invalidMapUrl:
                "Se brindó una URL no válida para el material visual. Contacte a Root para solicitar asistencia.",
            vdpRenderer_unexpectedError: "Hubo un error de sistema inesperado. Estos son los detalles técnicos:",
            vdpRenderer_unexpectedErrorMsg: "Hubo un error inesperado: estado de sesión desconocido {0}",
            vdpRenderer_defaultEndingMessage: "Ahora puede cerrar su ventana y desconectar el audio.",
            vdpErrorModal_title: "No se puede cargar la página",
            vdpErrorModal_message: "¡Algo salió mal! Contáctenos para solicitar asistencia a: vmap.help@rootinc.com",
            vdpErrorModal_body:
                "El error puede resolverse volviendo a cargar esta página o si selecciona lo siguiente:",
            vdpErrorModal_button: "Volver a cargar esta experiencia.",
            vdpNameEntry_welcomeText: "Bienvenido",
            vdpNameEntry_defaultWelcomeMessage:
                "Esta sesión de diálogo virtual será totalmente interactiva. Prepárese para explorar la información y compartir sus reflexiones y opiniones con sus pares.",
            vdpNameEntry_inputName: "¿Por qué nombre quiere que lo llamen?",
            vdpNameEntry_inputNamePlaceholder: "Ingrese el nombre por el que quiere que lo llamen:",
            vdpNameEntry_inputDepartment: "¿De qué departamento es?",
            vdpNameEntry_inputEmail: "¿Cuál es su dirección de correo electrónico?",
            vdpNameEntry_inputTeam: "¿En qué equipo está?",
            vdpNameEntry_identifiersName: "Ingrese el nombre",
            vdpNameEntry_identifiersDuplicateName:
                "Parece que ya hay alguien llamado {0} conectado hoy. ¿Le gustaría ingresar otro nombre para que podamos diferenciarlos? Considere agregar una inicial para su apellido, o ingresar un apodo.",
            vdpNameEntry_identifiersDepartment: "Ingrese el nombre del departamento",
            vdpNameEntry_identifiersEmail: "Ingrese el correo electrónico",
            vdpNameEntry_identifiersInvalidEmailFormat: "Formato de correo electrónico no válido",
            vdpNameEntry_identifiersTeam: "Ingrese el nombre del equipo",
            vdpNameEntry_welcomeHeader: "¡Gusto en conocerlo, {0}!",
            vdpNameEntry_welcomeAdvance: "Conozca a su equipo",
            vdpNameEntry_claimFacilitatorTitle: "Desempeñar el rol del facilitador",
            vdpNameEntry_facilitatorClaimed: "Rol de facilitador ocupado",
            vdpNameEntry_claimFacilitatorButton: "Desempeñar facilitador",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancelar",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Si usted es el facilitador de esta sesión, seleccione Desempeñar facilitador abajo.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Como facilitador, tendrá el control de las primeras páginas para comenzar. Luego, el sistema automáticamente seleccionará a un nuevo navegador.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Si fue invitado a esta sesión como participante, seleccione Cancelar.",
            vdpNameEntry_numParticipantsInSession: "{0} participantes esperando…",
            vdpNameEntry_oneParticipantsInSession: "1 participante esperando…",
            vdpNameEntry_save: "Guardar",
            vdpNameEntry_continue: "Continuar",
            vdpNameEntry_thanks: "Gracias, {0}. ¡Comenzaremos pronto!",
            vdpNameEntry_intro: "Ingrese su nombre y espere a que se unan los demás. ¡Comenzaremos pronto!",
            vdpNameEntry_introStarted: "Ingrese su nombre y únase a sus pares.",
            vdpNameEntry_navDirections: "Espere a que se una el equipo.",
            vdpValidationErrorMinLength: "Ingrese al menos {0} caracteres.",
            vdpValidationErrorMaxLength: "No ingrese más de {0} caracteres.",
            toolbar_stepOf: "Sección {0}, Página {1} - {2} ({3})",
            toolbar_intro: "Paso {0} de la Sección {1}",
            toolbar_welcome: "Secuencia de bienvenida",
            toolbar_exit: "Secuencia de salida",
            toolbar_reloadContent: "Volver a cargar contenido",
            toolbar_commentCreationButton: "Comentario ({0})",
            toolbar_buildComments: "Crear comentarios",
            toolbar_commentListButton: "Ver comentarios ({0})",
            toolbar_commentCreationTitle: "Agregar comentarios de diseño",
            toolbar_commentCreationDescription:
                "Los comentarios se guardarán y enviarán al equipo de diseño. Incluya su nombre o iniciales con su comentario.",
            toolbar_commentCreationCancelButton: "Cancelar",
            toolbar_commentCreationSubmitButton: "Publicar comentario",
            toolbar_commentCreationSubmitButtonDisabled: "Publicando comentario…",
            toolbar_commentCreationPlaceholder: "Ingresar comentario…",
            toolbar_previewMode: "Modo de vista previa:",
            toolbar_aboutPreviewMode: "Acerca del modo de vista previa",
            toolbar_focusAreaSelector: "Selector de área de enfoque",
            toolbar_hotspotSelector: "Selector de punto de acceso",
            toolbar_previewModeModalTitle: "Modo de vista previa",
            toolbar_previewModeModalFirstIntroduction:
                "Está visualizando esta experiencia en Modo de vista previa. Esta vista está diseñada para revisión interna de Root únicamente, y brinda acceso a herramientas de autoría.",
            toolbar_previewModeModalSecondIntroduction:
                "Estas opciones solo están disponibles en Modo de vista previa y no están visibles para el cliente ni para los participantes.",
            toolbar_previewModeModalReloadContent: "Volver a cargar contenido",
            toolbar_previewModeModalReloadContentDescription:
                "Use este botón para actualizar la experiencia con las últimas actualizaciones de Contentful sin tener que volver a cargar la sesión.",
            toolbar_previewModeModalComments: "Comentarios",
            toolbar_previewModeModalCommentsDescription:
                "Agregue un comentario para dar su opinión o registre los cambios que haya que hacer. Los comentarios se guardarán en Contentful para el equipo de creación.",
            toolbar_previewModeModalMapCoordinates: "Coordenadas del material visual",
            toolbar_previewModeModalMapCoordinatesDescription: "Copie las coordenadas del material visual.",
            toolbar_previewModeModalHotspotSelector: "Selector de punto de acceso",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Previsualice las ubicaciones de puntos de acceso y copie las coordenadas para usar en Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Selector de coordenadas",
            coordinatePreview_hotspotMode: "Modo punto de acceso",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Se copiaron las coordenadas de punto de acceso en su portapapeles.",
            coordinatePreview_focusAreaMode: "Modo área de enfoque",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Se copiaron las coordenadas de área de enfoque en su portapapeles.",
            coordinatePreview_directionsTitle: "Indicaciones del Selector de coordenadas",
            coordinatePreview_hotspotDirectionsLineOne:
                "Seleccione un punto del material visual para crear un punto de acceso.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Seleccione el botón Copiar en cada punto de acceso para copiar las coordenadas en Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Seleccione y arrastre para dibujar un rectángulo en el material visual.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Coloque el rectángulo sobre el área que será visible para un paso.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Seleccione el botón Copiar en cada área de enfoque para copiar las coordenadas en Contentful.",
            coordinatePreview_reset: "Reiniciar",
            coordinatePreview_done: "Terminado",
            navigatorDefaultInstructions_selectNextToContinue: "Seleccione **_Siguiente_** para continuar.",
            navigatorDefaultInstructions_progressCheckLineOne: "Hemos llegado al final de la sección {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Estamos cumpliendo los tiempos. ¡Sigamos con este buen trabajo!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Estamos adelantados. Tratemos de no apresurarnos con el material restante.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Estamos atrasados. Debemos acelerar un poco para completar la sesión a tiempo.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "En la próxima página, presentaremos un nuevo navegador para la sección {0}.",
            maintenanceWindow_heading: "Hay un mantenimiento programado el {0}, {1} de {2} a {3} {4}.",
            maintenanceWindow_body: "La aplicación tal vez no esté disponible en ese período.",
            maintenanceError_overlap:
                "Las fechas que eligió se superponen con el período de mantenimiento programado el {0}. Se realiza mantenimiento todas las semanas de {1} a {2}. Asegúrese de que las fechas de inicio y fin no se superpongan con el período de mantenimiento.",
            progressCheck_sectionTitle: "Sección {0} de {1}",
            progressCheck_paceCheckTitle: "Verificación de ritmo de la sesión",
            progressCheck_paceMessageOnPace: "¡Sigan con este buen trabajo!",
            progressCheck_paceMessageAheadOfPace: "Trate de no apresurarse con el material restante.",
            progressCheck_paceMessageBehindPace: "Debe acelerar un poco para completar la sesión a tiempo.",
            progressCheck_navigatorChangeTitle: "Cambio de navegador",
            progressCheck_navigatorChangeMessage:
                "Gracias por su dirección, {0}. Se asignará un nuevo navegador en la página siguiente.",
            vdpIntro_title: "Introducción",
            vdpIntro_subtitle: "Algunos temas rápidos antes de empezar…",
            vdpIntro_joinTheTeam: "Únase al equipo",
            vdpIntro_facilitatorRole: "Rol de facilitador",
            vdpIntro_navigatorRole: "Rol de navegador",
            vdpIntro_participantRole: "Rol de participante",
            vdpIntro_iceBreaker: "Conozca al equipo",
            vdpIntro_exploreTheVisual: "El material visual",
            vdpIntro_skipShort: "Omitir",
            vdpIntro_skipLong: "Omitir introducción",
            vdpIntro_facilitatorInstructionsOne:
                "¡Bienvenidos! La sesión de hoy es diferente de los otros cursos o capacitaciones a los que puedan haber asistido.",
            vdpIntro_facilitatorInstructionsTwo:
                "No hay un maestro ni un capacitador. Aprenderán unos de otros y de la información en pantalla.",
            vdpIntro_facilitatorInstructionsThree:
                "Mi rol como facilitador es mantener encaminada la conversación y hacer que se respeten los tiempos.",
            vdpIntro_navigatorInstructionsOne:
                "Durante las siguientes pantallas, seré también nuestro navegador. El rol de navegador consiste en leer el texto de Introducción en cada pantalla, seleccionar el botón **_Siguiente_** para ir atravesando la experiencia, y seguir otras indicaciones cuando corresponda.",
            vdpIntro_navigatorInstructionsTwo: "Este rol se asignará al azar y cambiará cada unas cuantas pantallas.",
            vdpIntro_participantInstructionsOne:
                "¡Todos son participantes! Su rol es participar compartiendo sus perspectivas con el grupo. Ayudarán a que la sesión se desarrolle de manera fluida turnándose para hablar, respetando las ideas y opiniones de los demás y manteniendo la conversación encaminada.",
            vdpIntro_participantInstructionsTwo:
                "Pueden ver quién está participando de la sesión y quién tiene cada rol, mediante el botón **_Equipo_** en la esquina inferior izquierda.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Luego, cada uno de nosotros tendrá la oportunidad de ver cómo es el rol de navegador mientras nos presentamos.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Cuando vean el cartel **_Usted es el navegador_** sobre el texto de Introducción, será su turno para leer y seguir indicaciones. ¿Alguno tiene alguna pregunta?",
            vdpIntro_iceBreakerInstructionsOne: "Hola, soy {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Un dato sobre mí es **___**.",
            vdpIntro_mapInstructionsOne: "Ahora que ya nos conocemos todos, hablaremos sobre nuestra sesión de hoy.",
            vdpIntro_mapInstructionsTwo:
                "Nuestra conversación se centrará en una imagen llamada material visual *Learning Map*®. Es una metáfora ilustrada que representa aspectos de nuestra empresa que exploraremos juntos. ",
            vdpIntro_iceBreakerIntroDirections: "Responda cualquier pregunta.",
            vdpEnd_title: "Gracias.",
            vdpEnd_body:
                "Hemos completado nuestra experiencia. ¡Gracias por participar! Esperamos que el debate de hoy les haya resultado informativo.",
            vdpEnd_surveyRequest:
                "Tómense unos minutos para compartir sus reflexiones y reacciones a la información que analizamos. Sus comentarios son importantes para nuestro éxito.",
            vdpEnd_surveyThankYou: "Gracias por su respuesta.",
            vdpEnd_sessionComplete: "Se ha completado la sesión.",
            vdpEnd_closeWindow: "Puede cerrar esta ventana.",
            privacyBanner_message:
                "Utilizamos cookies para permitir cierta funcionalidad del sitio web a fin de brindarle una experiencia más relevante. Para obtener más información, revise nuestra [Política sobre cookies]({0}) y [Declaración de privacidad]({1}).",
            privacyBanner_dismiss: "Ignorar",
            adminMessage_unauthorized: "No está autorizado para realizar la siguiente acción: {0}.",
            adminMessage_unauthorized_viewExp: "ver todas las experiencias",
        },
    },
    "de-DE": {
        name: "German",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Aktivität {0} kann nicht gefunden werden",
            vdpActivity_closeModal: "Schließen",
            vdpActivity_flipCard: "Umdrehen",
            vdpActivity_correctExclamation: "Richtig!",
            vdpActivity_correct: "Richtig",
            vdpActivity_correctAnswerBadge: "Korrekte Antwort",
            vdpActivity_partiallyCorrect: "Teilweise richtig!",
            vdpActivity_incorrect: "Falsch",
            vdpActivity_notCorrect: "Nicht korrekt",
            vdpActivity_continue: "Weiter",
            vdpActivity_checkAnswer: "Antwort überprüfen",
            vdpActivity_ready: "Bereit.",
            vdpActivity_cardsPlaced: "Alle Karten wurden positioniert.",
            vdpActivity_cardsCorrectHeader: "Erfolg!",
            vdpActivity_cardsCorrect: "Alle Karten wurden erfolgreich positioniert.",
            vdpActivity_cardsCorrectContinue: "Fahren Sie nun mit der nächsten Aktivität fort.",
            vdpActivity_cardsPartiallyCorrectHeader: "Nicht ganz.",
            vdpActivity_cardsPartiallyCorrect:
                "Überprüfen Sie die falschen Antworten und versuchen Sie, diese an eine andere Stelle zu verschieben.",
            vdpActivity_cardsCheckAnswers: "Überprüfen Sie Ihre Antworten",
            vdpActivity_textEntryPlaceholder: "Ihre Antwort",
            vdpActivity_textEntryNavigatorPrompt: "Der Navigator {0} gibt unten den Text ein",
            vdpActivity_textEntryPrompt: "Geben Sie unten den Text ein:",
            vdpActivity_textEntrySubmit: "Absenden",
            vdpActivity_selectOne: "Eine Option auswählen",
            vdpActivity_selectOneOrMany: "Wählen Sie eine oder mehrere aus",
            vdpActivity_defaultCurrentParticipantName: "Ich",
            vdpActivity_hotspotTooltip: "Klicken Sie auf die Hotspots, um mehr zu erfahren.",
            vdpClipboard_copied: "In die Zwischenablage kopiert!",
            vdpClipboard_copy: "In die Zwischenablage kopieren",
            vdpClipboard_error: "Kopieren in die Zwischenablage nicht möglich",
            vdpHud_hide: "Ausblenden",
            vdpHud_show: "Anzeigen",
            vdpHud_changeNavigatorTitle: "Übertragen Sie die Rolle des Navigators auf eine neue Person.",
            vdpHud_changeNavigatorText1:
                "Sie haben „Navigator ändern“ ausgewählt. Dadurch wird zufällig ein anderer Navigator ausgewählt und alle Interaktionen auf dieser Seite werden kurzzeitig deaktiviert.",
            vdpHud_changeNavigatorText2: "Sind Sie sicher, dass Sie den Navigator ändern möchten?",
            vdpHud_close: "Schließen",
            vdpHud_accept: "Den Navigator ändern",
            vdpHud_back: "Zurück",
            vdpHud_next: "Weiter",
            vdpHud_endExperience: "Lernerfahrung beenden",
            vdpHud_defaultExitMsg:
                "Wir haben unsere Lernerfahrung abgeschlossen. Danke fürs Ihre Teilnahme! Wir hoffen, dass Sie die heutige Diskussion aufschlussreich fanden. Sie können dieses Fenster schließen.",
            vdpHud_navigatorReminder: "Sie sind der Navigator. Bitte lesen Sie Folgendes:",
            vdpHud_timerNoTimeSpecified: "Für den Abschnitt wurde kein Zeitrahmen festgelegt",
            vdpHud_timerRemaining: "verbleiben in diesem Abschnitt",
            vdpHud_timerLessThanOneMinute: "Weniger als eine Minute übrig",
            vdpHud_timerOver: "mehr als",
            vdpHud_roleNone: "Keine Zuweisung",
            vdpHud_infoTableSection: "Abschnitt",
            vdpHud_infoTableRecommended: "Empfohlen",
            vdpHud_infoTableActual: "Tatsächlich",
            vdpHud_infoTableTotal: "Gesamt",
            vdpHud_infoTableRemaining: "Verbleibend",
            vdpHud_infoTeamButton: "Team",
            vdpHud_infoTeamParticipantJoining: "Benutzer tritt bei...",
            vdpHud_navigator: "Navigator",
            vdpHud_facilitator: "Moderator",
            vdpHud_facilitatorMenuMakeNavigator: "Zum Navigator machen",
            vdpHud_facilitatorMenuRemoveFacilitator: "Die Rolle des Moderators entfernen",
            vdpHud_removeFacilitatorWarningConfirm: "Bestätigen",
            vdpHud_removeFacilitatorWarningCancel: "Abbrechen",
            vdpHud_removeFacilitatorWarningMessage:
                "Sie sind dabei, Ihre Rolle als Moderator zu entfernen. Sind Sie sicher, dass Sie fortfahren möchten?",
            vdpHud_progressOnPaceMessage: "Sie liegen im Zeitplan",
            vdpHud_progressAheadOfPaceMessage: "Sie sind dem Zeitplan voraus",
            vdpHud_progressBehindPaceMessage: "Sie liegen hinter dem Zeitplan",
            vdpHud_progressTimeRemaining: "{0} verbleiben in diesem Abschnitt",
            vdpHud_progressStepsInSection: "Seite {0} von {1}",
            vdpHud_zoomControlsTooltip: "Zoom-Anzeigesteuerungen",
            sidebar_scheduleButton: "Zeitplan",
            sidebar_teamAssignNavigator: "Navigator zuweisen",
            sidebar_teamChooseRandomly: "Zufällig wählen",
            sidebar_teamChooseHelperText:
                "Wählt nach dem Zufallsprinzip einen berechtigten Teilnehmer aus, der als Navigator fungiert. Die Teilnehmer werden erst wieder ausgewählt, wenn alle an der Reihe waren.",
            sidebar_editNameMenuItem: "Namen bearbeiten",
            sidebar_editNameSave: "Änderungen speichern",
            sidebar_nameEditPlaceholder: "Geben Sie den Namen ein, mit dem Sie angesprochen werden möchten...",
            sidebar_manageRolesMenuLabel: "Rollen verwalten",
            sidebar_navigatorDescription:
                "Liest Fragen, Aufforderungen und Anweisungen laut vor. Führt die Gruppe während der Erfahrung weiter, indem „Weiter“ ausgewählt und anderen Anweisungen gefolgt wird, die auf jedem Bildschirm angegeben sind.",
            sidebar_facilitatorDescription:
                "Dient während der gesamten Sitzung als Begleiter, um sicherzustellen, dass die Gruppe eine intensive Diskussion führt und das Gespräch auf dem richtigen Weg bleibt.",
            sidebar_previousButton: "Vorherige",
            sidebar_nextButton: "Weiter",
            sidebar_lockTooltip: "Nur der Navigator kann die Seite wechseln.",
            sidebar_navigatorBadge: "Sie sind der Navigator",
            sidebar_facilitatorBadge: "Sie sind der Moderator",
            sidebar_introductionLabel: "Einleitung",
            sidebar_introductionInstruction: "Lesen Sie der Gruppe laut vor:",
            sidebar_directionsLabel: "Anweisungen",
            sidebar_nonNavigatorDirectionsLabel: "{0} führt Folgendes aus:",
            sidebar_directionsInstruction: "Führen Sie Folgendes aus:",
            sidebar_facilitationNotesLabel: "Anmerkungen für die Durchführung",
            genUrl_copyLink: "Link kopieren",
            genUrl_sessionLinkDetails:
                "Diese Sitzungslinks verschwinden, wenn Sie die Seite aktualisieren. Laden Sie diese Links unbedingt herunter oder kopieren Sie sie, bevor Sie diese Seite verlassen.",
            genUrl_exportAsCsv: "Als CSV exportieren",
            genUrl_copyTable: "Als Tabelle kopieren",
            genUrl_linkExpiration: "Linkablauf",
            genUrl_link: "Link",
            genUrl_title: "{0} Sitzungslinkgenerator",
            genUrl_experience: "Erfahrung: {0}",
            genUrl_description:
                "Über dieses Portal können Sie Sitzungslinks für diese Erfahrung generieren. Um Sitzungslinks zu generieren, geben Sie die Anzahl der Sitzungen ein, die Sie generieren möchten, wählen Sie ein Datum aus, an dem die Sitzungen ablaufen sollen, und wählen Sie Sitzungen generieren. Sobald diese Links generiert sind, kopieren Sie sie und fügen Sie sie in Ihren bevorzugten Texteditor oder Ihr Tabellenkalkulationsprogramm ein. Sobald Sie die Seite aktualisieren, verschwinden alle Links.",
            genUrl_numSessions: "Anzahl Sitzungen:",
            genUrl_sessionsExpirationLabel: "Sitzungslinks laufen ab am:",
            genUrl_sessionsOpenAtLabel: "Sitzungslinks sind gültig ab:",
            genUrl_numSessionsInputLabel: "Anzahl der Sitzungen",
            genUrl_sessionLanguageLabel: "Sitzungssprache",
            genUrl_numSessionsInputTypeError: "Muss eine Nummer sein",
            genUrl_closesOnError: "Wählen Sie ein Datum in der Zukunft",
            genUrl_experienceClosesOnError: "Wählen Sie ein Datum vor {0}",
            genUrl_notifSuccessSingle: "{0}-Link erfolgreich generiert",
            genUrl_notifSuccessMultiple: "{0}-Links erfolgreich generiert",
            genUrl_notifErrorGenerating:
                "Es können keine Sitzungen generiert werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator.",
            genUrl_notifErrorProcessing: "Kann nicht verarbeitet werden. Kontaktieren Sie einen Administrator.",
            genUrl_sessionsExpireToday: "Die Sitzungen laufen heute ab",
            genUrl_sessionsExpireInOneDay: "Sitzungen laufen in {0} Tag ab",
            genUrl_sessionsExpireInDays: "Sitzungen laufen in {0} Tagen ab",
            genUrl_sessionsOpenToday: "Die Sitzungen werden heute eröffnet",
            genUrl_sessionsOpenOneDay: "Die Sitzungen werden in {0} Tag eröffnet",
            genUrl_sessionsOpenDays: "Die Sitzungen werden in {0} Tagen eröffnet",
            genUrl_generate: "Sitzungen generieren",
            genUrl_schedulingLinkExpired: "Der Link für den Zeitplan ist abgelaufen.",
            genUrl_error: "Fehler",
            genUrl_dataCollectionLabel: "Datensammlung",
            genUrl_emailContact: "E-Mail Kontakt",
            vdpLoading_waitForContent: "Bitte warten Sie, während Ihr Inhalt geladen wird…",
            vdpNotFound_title: "Virtuelle Dialogplattform",
            vdpNotFound_message: "Die gesuchte Seite konnte nicht gefunden werden.",
            vdpRenderer_refresh: "Aktualisieren",
            vdpRenderer_sessionDisconnectedHeader: "Ihre Verbindung wurde getrennt.",
            vpdRenderer_inactiveDisconnectedBody:
                "Ihre Verbindung wurde aufgrund von Inaktivität getrennt. Wählen Sie die Schaltfläche unten aus, um zu versuchen, die Verbindung wiederherzustellen.",
            vdpRenderer_inactiveHeader: "Sie sind inaktiv.",
            vdpRenderer_inactiveBody:
                "Sie waren zu lange inaktiv. Wählen Sie die Schaltfläche unten aus, um aktiv zu bleiben. Andernfalls werden Sie aus der Sitzung entfernt, wenn der Timer abgelaufen ist.",
            vdpRenderer_setSelfActiveButton: "Stellen Sie sich als aktiv ein",
            vdpRenderer_sessionDisconnected: "Wiederverbindung wird versucht",
            vdpRenderer_sessionDisconnectedReload: "Bitte neu laden",
            vdpRenderer_endOfSession: "Ende der Sitzung",
            vdpRenderer_invalidMapUrl:
                "Für das Bild wurde eine ungültige URL angegeben. Bitte wenden Sie sich an Root, um Unterstützung zu erhalten.",
            vdpRenderer_unexpectedError:
                "Es ist ein unerwarteter Systemfehler aufgetreten. Hier die technischen Details:",
            vdpRenderer_unexpectedErrorMsg: "Ein unerwarteter Fehler ist aufgetreten: unbekannter Sitzungsstatus {0}",
            vdpRenderer_defaultEndingMessage: "Sie können jetzt Ihr Fenster schließen und die Audioverbindung trennen.",
            vdpErrorModal_title: "Seite kann nicht geladen werden",
            vdpErrorModal_message:
                "Es ist etwas schief gegangen. Bitte kontaktieren Sie uns für Unterstützung unter: vmap.help@rootinc.com",
            vdpErrorModal_body: "Der Fehler kann behoben werden, indem Sie diese Seite neu laden oder unten auswählen:",
            vdpErrorModal_button: "Laden Sie diese Erfahrung neu.",
            vdpNameEntry_welcomeText: "Willkommen",
            vdpNameEntry_defaultWelcomeMessage:
                "Diese virtuelle Dialogsitzung wird vollständig interaktiv sein. Bereiten Sie sich darauf vor, Informationen zu erkunden und Ihre Gedanken und Meinungen mit Ihren Kollegen zu teilen.",
            vdpNameEntry_inputName: "Wie möchtest Sie genannt werden?",
            vdpNameEntry_inputNamePlaceholder: "Geben Sie den Namen ein, mit dem Sie angesprochen werden möchten:",
            vdpNameEntry_inputDepartment: "In welcher Abteilung sind Sie tätig?",
            vdpNameEntry_inputEmail: "Wie lautet Ihre E-Mail-Adresse?",
            vdpNameEntry_inputTeam: "In welchem Team sind Sie?",
            vdpNameEntry_identifiersName: "Bitte Namen eingeben",
            vdpNameEntry_identifiersDuplicateName:
                "Es sieht so aus, als ob heute bereits ein {0} verbunden ist. Möchten Sie versuchen, einen anderen Namen einzugeben, damit wir Sie unterscheiden können? Erwägen Sie, Ihrem Nachnamen einen Anfangsbuchstaben hinzuzufügen oder einen Spitznamen einzugeben.",
            vdpNameEntry_identifiersDepartment: "Bitte geben Sie den Abteilungsnamen ein",
            vdpNameEntry_identifiersEmail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
            vdpNameEntry_identifiersInvalidEmailFormat: "Ungültiges E-Mail-Format",
            vdpNameEntry_identifiersTeam: "Bitte geben Sie den Team-Namen ein",
            vdpNameEntry_welcomeHeader: "Schön, Sie kennenzulernen, {0}!",
            vdpNameEntry_welcomeAdvance: "Lernen Sie Ihr Team kennen",
            vdpNameEntry_claimFacilitatorTitle: "Die Rolle des Moderators beanspruchen",
            vdpNameEntry_facilitatorClaimed: "Die Rolle des Moderators beansprucht",
            vdpNameEntry_claimFacilitatorButton: "Die Moderator-Rolle beanspruchen",
            vdpNameEntry_claimFacilitatorModalCancel: "Abbrechen",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Wenn Sie der Moderator dieser Sitzung sind, wählen Sie unten „Die Rolle des Moderators beanspruchen“ aus.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Als Moderator haben Sie die Kontrolle über die ersten paar Seiten, um uns den Einstieg zu erleichtern. Anschließend wählt das System automatisch einen neuen Navigator aus.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Wenn Sie als Teilnehmer zu dieser Sitzung eingeladen wurden, wählen Sie bitte Abbrechen.",
            vdpNameEntry_numParticipantsInSession: "{0} Teilnehmer warten...",
            vdpNameEntry_oneParticipantsInSession: "1 Teilnehmer wartet...",
            vdpNameEntry_save: "Speichern",
            vdpNameEntry_continue: "Weiter",
            vdpNameEntry_thanks: "Danke, {0}. Bald geht es los!",
            vdpNameEntry_intro: "Geben Sie Ihren Namen ein und warten Sie, bis andere beitreten. Bald geht es los!",
            vdpNameEntry_introStarted: "Geben Sie Ihren Namen ein und schließen Sie sich den anderen Teilnehmern an.",
            vdpNameEntry_navDirections: "Warten Sie, bis das Team beitritt.",
            vdpValidationErrorMinLength: "Bitte geben Sie mindestens {0} Zeichen ein.",
            vdpValidationErrorMaxLength: "Bitte geben Sie nicht mehr als {0} Zeichen ein.",
            toolbar_stepOf: "Abschnitt {0}, Seite {1} - {2} ({3})",
            toolbar_intro: "Schritt {0} von Abschnitt {1}",
            toolbar_welcome: "Willkommenssequenz",
            toolbar_exit: "Schlusssequenz",
            toolbar_reloadContent: "Inhalt neu laden",
            toolbar_commentCreationButton: "Kommentar ({0})",
            toolbar_buildComments: "Build-Kommentare",
            toolbar_commentListButton: "Kommentare anzeigen ({0})",
            toolbar_commentCreationTitle: "Build-Kommentar hinzufügen",
            toolbar_commentCreationDescription:
                "Kommentare werden gespeichert und an das Build-Team gesendet. Bitte geben Sie in Ihrem Kommentar Ihren Namen oder Ihre Initialen an.",
            toolbar_commentCreationCancelButton: "Abbrechen",
            toolbar_commentCreationSubmitButton: "Kommentar veröffentlichen",
            toolbar_commentCreationSubmitButtonDisabled: "Kommentar wird veröffentlicht...",
            toolbar_commentCreationPlaceholder: "Kommentar eingeben...",
            toolbar_previewMode: "Vorschau-Modus:",
            toolbar_aboutPreviewMode: "Über den Vorschaumodus",
            toolbar_focusAreaSelector: "Fokusbereich-Auswahl",
            toolbar_hotspotSelector: "Hotspot-Auswahl",
            toolbar_previewModeModalTitle: "Vorschaumodus",
            toolbar_previewModeModalFirstIntroduction:
                "Sie sehen dieses Erlebnis im Vorschaumodus. Diese Ansicht ist nur für die interne Root-Überprüfung gedacht und bietet Zugriff auf Authoring Tools.",
            toolbar_previewModeModalSecondIntroduction:
                "Diese Optionen sind nur im Vorschaumodus verfügbar und für den Kunden oder die Teilnehmer nicht sichtbar.",
            toolbar_previewModeModalReloadContent: "Inhalt neu laden",
            toolbar_previewModeModalReloadContentDescription:
                "Verwenden Sie diese Schaltfläche, um die Lernerfahrung mit den neuesten Updates von Contentful zu aktualisieren, ohne die Sitzung neu laden zu müssen.",
            toolbar_previewModeModalComments: "Kommentare",
            toolbar_previewModeModalCommentsDescription:
                "Fügen Sie einen Kommentar hinzu, um Feedback zu geben oder etwaige Änderungen zu notieren, die vorgenommen werden müssen. Kommentare werden in Contentful für das Build-Team erfasst.",
            toolbar_previewModeModalMapCoordinates: "Koordinaten des Bildmaterials",
            toolbar_previewModeModalMapCoordinatesDescription: "Kopieren Sie die Koordinaten des Bildmaterials.",
            toolbar_previewModeModalHotspotSelector: "Hotspot-Auswahl",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Sehen Sie sich Hotspot-Standorte in der Vorschau an und kopieren Sie Koordinaten zur Verwendung in Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Koordinaten-Auswahl",
            coordinatePreview_hotspotMode: "Hotspot-Modus",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Die Hotspot-Koordinaten wurden in Ihre Zwischenablage kopiert.",
            coordinatePreview_focusAreaMode: "Fokusbereich-Modus",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Die Koordinaten des Fokusbereichs wurden in Ihre Zwischenablage kopiert.",
            coordinatePreview_directionsTitle: "Anweisungen zur Koordinatenauswahl",
            coordinatePreview_hotspotDirectionsLineOne:
                "Wählen Sie einen Punkt im Bild aus, um einen Hotspot zu erstellen.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Wählen Sie an jedem Hotspot die Schaltfläche „Kopieren“, um die Koordinaten nach Contentful zu kopieren.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Verwenden Sie die Auwahl- und Ziehfunktion, um ein Rechteck auf dem Bild zu zeichnen.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Platzieren Sie das Rechteck über dem Bereich, der für eine Stufe sichtbar sein soll.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Wählen Sie in jedem Fokusbereich die Schaltfläche „Kopieren“, um die Koordinaten nach Contentful zu kopieren.",
            coordinatePreview_reset: "Zurücksetzen",
            coordinatePreview_done: "Fertig",
            navigatorDefaultInstructions_selectNextToContinue: "Wählen Sie **_Weiter_**, um fortzufahren.",
            navigatorDefaultInstructions_progressCheckLineOne: "Wir haben das Ende von Abschnitt {0} erreicht.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "Wir liegen im Zeitplan. Weiter so!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Wir sind dem Zeitplan voraus. Versuchen wir, nicht durch das verbleibende Material zu hasten.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Sie liegen hinter dem Zeitplan. Wir müssen schneller vorgehen, um die Sitzung pünktlich abzuschließen.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Auf der nächsten Seite stellen wir einen neuen Navigator für Abschnitt {0} vor.",
            maintenanceWindow_heading: "Die Wartung ist für {0}, {1} von {2} - {3} {4} geplant.",
            maintenanceWindow_body: "Die Anwendung ist während dieser Zeit möglicherweise nicht verfügbar.",
            maintenanceError_overlap:
                "Die von Ihnen ausgewählten Daten stehen im Widerspruch zu einem geplanten Wartungszeitraum am {0}. Die Wartung erfolgt jede Woche von {1} bis {2}. Bitte stellen Sie sicher, dass sich das von Ihnen gewählte Start- und Ablaufdatum nicht mit dem Wartungsfenster überschneidet.",
            progressCheck_sectionTitle: "Abschnitt {0} von {1}",
            progressCheck_paceCheckTitle: "Überprüfung des Sitzungs-Fortschritts",
            progressCheck_paceMessageOnPace: "Weiter so!",
            progressCheck_paceMessageAheadOfPace: "Versuchen Sie, nicht durch das verbleibende Material zu hasten.",
            progressCheck_paceMessageBehindPace:
                "Sie müssen schneller vorgehen, um die Sitzung pünktlich abzuschließen.",
            progressCheck_navigatorChangeTitle: "Wechsel des Navigators",
            progressCheck_navigatorChangeMessage:
                "Danke für deine Unterstützung, {0}. Auf der nächsten Seite wird ein neuer Navigator zugewiesen.",
            vdpIntro_title: "Einleitung",
            vdpIntro_subtitle: "Vor dem Start noch schnell ein paar Dinge...",
            vdpIntro_joinTheTeam: "Werden Sie Teil unseres Teams",
            vdpIntro_facilitatorRole: "Moderator-Rolle",
            vdpIntro_navigatorRole: "Navigator-Rolle",
            vdpIntro_participantRole: "Teilnehmer-Rolle",
            vdpIntro_iceBreaker: "Lernen Sie das Team kennen",
            vdpIntro_exploreTheVisual: "Das Bildmaterial",
            vdpIntro_skipShort: "Überspringen",
            vdpIntro_skipLong: "Einleitung überspringen",
            vdpIntro_facilitatorInstructionsOne:
                "Herzlich willkommen! Die heutige Sitzung unterscheidet sich von anderen Kursen oder Schulungen, die Sie möglicherweise besucht haben.",
            vdpIntro_facilitatorInstructionsTwo:
                "Es gibt keinen Lehrer oder Trainer – Sie lernen voneinander und durch die Informationen auf dem Bildschirm.",
            vdpIntro_facilitatorInstructionsThree:
                "Meine Rolle als Moderator besteht darin, das Gespräch in die richtige Richtung zu lenken und die Zeitlinie einzuhalten.",
            vdpIntro_navigatorInstructionsOne:
                "Auf den nächsten Bildschirmen werde ich auch als unser Navigator fungieren. Die Rolle des Navigators besteht darin, den Einleitungstext auf jedem Bildschirm zu lesen, die Schaltfläche **_Weiter_** auszuwählen, um uns durch die Erfahrung zu führen, und bei Aufforderung anderen Anweisungen zu folgen.",
            vdpIntro_navigatorInstructionsTwo:
                "Die Rolle des Navigators wird zufällig zugewiesen und ändert sich alle paar Bildschirme.",
            vdpIntro_participantInstructionsOne:
                "Ihr seid alle Teilnehmer! Ihre Rolle besteht darin, sich aktiv zu beteiligen, indem Sie Ihre Perspektiven mit der Gruppe teilen. Sie tragen dazu bei, dass die Sitzung reibungslos verläuft, indem Sie abwechselnd reden, die Gedanken und Meinungen anderer respektieren und nicht vom Gesprächsthema abzukommen.",
            vdpIntro_participantInstructionsTwo:
                "Über die Schaltfläche **_Team_** unten links können Sie sehen, wer an der Sitzung teilnimmt und wer welche Rolle innehat.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Als nächstes bekommt jeder von uns die Möglichkeit, die Rolle des Navigators kennen zu lernen, während wir uns vorstellen.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Wenn Sie das Banner **_Sie sind der Navigator_** über dem Einleitungstext sehen, sind Sie an der Reihe, die Anweisungen zu lesen und zu befolgen. Hat jemand Fragen?",
            vdpIntro_iceBreakerInstructionsOne: "Hallo, ich bin {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Eine Tatsache über mich ist **___**.",
            vdpIntro_mapInstructionsOne:
                "Nachdem wir uns nun alle kennengelernt haben, erfahren wir mehr über unsere heutige Sitzung.",
            vdpIntro_mapInstructionsTwo:
                "Unser Gespräch wird sich auf ein Bild konzentrieren, das als *Learning Map*® bezeichnet wird. Es handelt sich um eine illustrierte Metapher, die Aspekte unseres Geschäfts darstellt, die wir gemeinsam erkunden werden. ",
            vdpIntro_iceBreakerIntroDirections: "Beantworten Sie alle Fragen.",
            vdpEnd_title: "Danke.",
            vdpEnd_body:
                "Wir haben unsere Lernerfahrung abgeschlossen. Danke fürs Ihre Teilnahme! Wir hoffen, dass Sie die heutige Diskussion aufschlussreich fanden.",
            vdpEnd_surveyRequest:
                "Bitte nehmen Sie sich ein paar Minuten Zeit, um Ihre Gedanken und Reaktionen auf die von uns besprochenen Informationen mitzuteilen. Ihr Feedback ist wichtig für unseren Erfolg.",
            vdpEnd_surveyThankYou: "Vielen Dank für Ihre Antwort.",
            vdpEnd_sessionComplete: "Die Sitzung ist abgeschlossen.",
            vdpEnd_closeWindow: "Sie können dieses Fenster schließen.",
            privacyBanner_message:
                "Wir verwenden Cookies, um einige Website-Funktionen zu ermöglichen und Ihnen eine bessere Lernerfahrung zu bieten. Weitere Informationen finden Sie in unserer [Cookie-Richtlinie]({0}) und unserer [Datenschutzerklärung]({1}).",
            privacyBanner_dismiss: "Schließen",
            adminMessage_unauthorized: "Es ist Ihnen nicht gestattet, folgende Maßnahmen zu ergreifen: {0}.",
            adminMessage_unauthorized_viewExp: "alle Lernerfahrungen anzeigen",
        },
    },
    fr: {
        name: "French",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Impossible de trouver l’activité {0}",
            vdpActivity_closeModal: "Fermer",
            vdpActivity_correctExclamation: "Correct !",
            vdpActivity_correct: "Correct",
            vdpActivity_correctAnswerBadge: "Bonne réponse",
            vdpActivity_partiallyCorrect: "Partiellement correct !",
            vdpActivity_incorrect: "Incorrect",
            vdpActivity_notCorrect: "Pas correct",
            vdpActivity_continue: "Continuer",
            vdpActivity_checkAnswer: "Vérifier la réponse",
            vdpActivity_ready: "Prêt.",
            vdpActivity_cardsPlaced: "Toutes les cartes ont été placées.",
            vdpActivity_cardsCorrectHeader: "Réussis !",
            vdpActivity_cardsCorrect: "Toutes les cartes ont été placées avec succès.",
            vdpActivity_cardsCorrectContinue: "Passer à l’activité suivante.",
            vdpActivity_cardsPartiallyCorrectHeader: "Pas tout à fait.",
            vdpActivity_cardsPartiallyCorrect:
                "Passer en revue les mauvaises réponses et essayer de les déplacer vers un nouvel emplacement.",
            vdpActivity_cardsCheckAnswers: "Vérifiez vos réponses",
            vdpActivity_textEntryPlaceholder: "Votre réponse",
            vdpActivity_textEntryNavigatorPrompt: "L’assistant, {0}, saisira le texte ci-dessous",
            vdpActivity_textEntryPrompt: "Saisissez le texte ci-dessous :",
            vdpActivity_textEntrySubmit: "Envoyer",
            vdpActivity_selectOne: "Sélectionnez-en une",
            vdpActivity_selectOneOrMany: "Sélectionnez-en une ou plusieurs",
            vdpActivity_defaultCurrentParticipantName: "Moi",
            vdpActivity_hotspotTooltip: "Cliquez sur les zones actives pour en savoir plus.",
            vdpClipboard_copied: "Copié dans le presse-papiers !",
            vdpClipboard_copy: "Copier dans le presse-papiers",
            vdpClipboard_error: "Impossible de copier dans le presse-papiers",
            vdpHud_hide: "Masquer",
            vdpHud_show: "Afficher",
            vdpHud_changeNavigatorTitle: "Nommer une nouvelle personne comme l’assistant.",
            vdpHud_changeNavigatorText1:
                'Vous avez cliqué sur "Changer d’assistant". Cela sélectionnera au hasard une autre personne faisant office d’assistant et désactivera temporairement toutes les interactions sur cette page.',
            vdpHud_changeNavigatorText2: "Voulez-vous vraiment changer d’assistant ?",
            vdpHud_close: "Fermer",
            vdpHud_accept: "Changer d’assistant ",
            vdpHud_back: "Retour",
            vdpHud_next: "Suivant",
            vdpHud_endExperience: "Terminer l’expérience",
            vdpHud_defaultExitMsg:
                "Vous avez terminé notre expérience. Merci d’avoir participé ! Nous espérons que vous avez trouvé la discussion d’aujourd’hui intéressante. Vous pouvez fermer cette fenêtre.",
            vdpHud_navigatorReminder: "Vous êtes l’assistant(e). Veuillez lire ce qui suit :",
            vdpHud_timerNoTimeSpecified: "Aucune heure spécifiée pour la section",
            vdpHud_timerRemaining: "restant dans la section",
            vdpHud_timerLessThanOneMinute: "Moins d’une minute restante",
            vdpHud_timerOver: "plus de",
            vdpHud_roleNone: "Aucun attribué",
            vdpHud_infoTableSection: "Section",
            vdpHud_infoTableRecommended: "Recommandé",
            vdpHud_infoTableActual: "Réel",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restant",
            vdpHud_infoTeamButton: "Équipe",
            vdpHud_infoTeamParticipantJoining: "Utilisateur rejoignant...",
            vdpHud_navigator: "Assistant(e)",
            vdpHud_facilitator: "Animateur",
            vdpHud_facilitatorMenuMakeNavigator: "Nommer dans le rôle d’assistant",
            vdpHud_facilitatorMenuRemoveFacilitator: "Supprimer le rôle de l’animateur",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmer",
            vdpHud_removeFacilitatorWarningCancel: "Annuler",
            vdpHud_removeFacilitatorWarningMessage:
                "Vous êtes sur le point de quitter votre rôle d’animateur. Voulez-vous vraiment continuer ?",
            vdpHud_progressOnPaceMessage: "À l’heure",
            vdpHud_progressAheadOfPaceMessage: "En avance",
            vdpHud_progressBehindPaceMessage: "En retard",
            vdpHud_progressTimeRemaining: "{0} restant dans la section",
            vdpHud_progressStepsInSection: "Page {0} sur {1}",
            vdpHud_zoomControlsTooltip: "Commandes de zoom de carte",
            sidebar_scheduleButton: "Calendrier",
            sidebar_teamAssignNavigator: "Attribuer le rôle d’assistant",
            sidebar_teamChooseRandomly: "Choisir de façon aléatoire",
            sidebar_teamChooseHelperText:
                "Choisit de façon aléatoire un participant admissible pour servir de navigateur. Les participants ne seront pas choisis à nouveau tant que tout le monde n’aura pas eu son tour.",
            sidebar_editNameMenuItem: "Modifier le nom",
            sidebar_editNameSave: "Enregistrer les modifications",
            sidebar_nameEditPlaceholder: "Saisissez le nom par lequel vous souhaitez être appelé...",
            sidebar_manageRolesMenuLabel: "Gérer les rôles",
            sidebar_navigatorDescription:
                "Lit les questions, les invites et les instructions à voix haute. Fait progresser le groupe tout au long de l’expérience en sélectionnant Suivant et en suivant les autres instructions indiquées sur chaque écran.",
            sidebar_facilitatorDescription:
                "Sert de guide tout au long de la session pour s’assurer que le groupe a une discussion riche et que la conversation reste sur la bonne voie.",
            sidebar_previousButton: "Précédent",
            sidebar_nextButton: "Suivant",
            sidebar_lockTooltip: "Seul l’assistant(e) peut modifier la page.",
            sidebar_navigatorBadge: "Vous avez le rôle d’assistant(e)",
            sidebar_facilitatorBadge: "Vous avez le rôle d’animateur(trice)",
            sidebar_introductionLabel: "Introduction",
            sidebar_introductionInstruction: "Lisez à voix haute au groupe :",
            sidebar_directionsLabel: "Indications",
            sidebar_nonNavigatorDirectionsLabel: "{0} procède comme suit :",
            sidebar_directionsInstruction: "Procédez comme suit :",
            sidebar_facilitationNotesLabel: "Notes de l’animateur(trice)",
            genUrl_copyLink: "Copier le lien",
            genUrl_sessionLinkDetails:
                "Ces liens de sessions disparaîtront lorsque vous actualiserez la page. Assurez-vous de télécharger ou de copier ces liens avant de quitter cette page.",
            genUrl_exportAsCsv: "Exporter au format CSV",
            genUrl_copyTable: "Copier le tableau",
            genUrl_linkExpiration: "Expiration du lien",
            genUrl_link: "Lien",
            genUrl_title: "{0} Générateur de lien de session",
            genUrl_experience: "Expérience : {0}",
            genUrl_description:
                "Ce portail vous permet de générer des liens de session pour cette expérience. Pour générer des liens de session, saisissez le nombre de sessions que vous souhaitez générer, choisissez une date à laquelle les sessions doivent expirer et cliquez sur Générer. Une fois ces sessions générées, copiez-les et collez-les dans l’éditeur de texte ou le programme de feuille de calcul de votre choix. Une fois la page actualisée, toutes les sessions disparaîtront.",
            genUrl_numSessions: "Nombre de sessions :",
            genUrl_sessionsExpirationLabel: "Les liens de session expirent le :",
            genUrl_sessionsOpenAtLabel: "Les liens de session sont valides à partir du :",
            genUrl_numSessionsInputLabel: "Nombre de sessions",
            genUrl_sessionLanguageLabel: "Langue de la session",
            genUrl_numSessionsInputTypeError: "Doit être un nombre",
            genUrl_closesOnError: "Choisir une date dans le futur",
            genUrl_experienceClosesOnError: "Choisir une date antérieure à {0}",
            genUrl_notifSuccessSingle: "{0} lien généré avec succès",
            genUrl_notifSuccessMultiple: "{0} liens générés avec succès",
            genUrl_notifErrorGenerating:
                "Impossible de générer des sessions. Veuillez réessayer ou contacter l’administrateur.",
            genUrl_notifErrorProcessing: "Impossible à traiter. Contactez un administrateur.",
            genUrl_sessionsExpireToday: "Les sessions expireront aujourd’hui",
            genUrl_sessionsExpireInOneDay: "Les sessions expireront dans {0} jour",
            genUrl_sessionsExpireInDays: "Les sessions expireront dans {0} jours",
            genUrl_sessionsOpenToday: "Les sessions s’ouvriront aujourd’hui",
            genUrl_sessionsOpenOneDay: "Les sessions s’ouvriront dans {0} jour",
            genUrl_sessionsOpenDays: "Les sessions s’ouvriront dans {0} jours",
            genUrl_generate: "Générer des sessions",
            genUrl_schedulingLinkExpired: "Le lien de planification a expiré.",
            genUrl_error: "Erreur",
            genUrl_dataCollectionLabel: "Collecte de données",
            genUrl_emailContact: "Contact e-mail",
            vdpLoading_waitForContent: "Veuillez patienter pendant le chargement de votre contenu...",
            vdpNotFound_title: "Plateforme de dialogue virtuel",
            vdpNotFound_message: "La page que vous recherchez est introuvable.",
            vdpRenderer_refresh: "Actualiser",
            vdpRenderer_sessionDisconnectedHeader: "Vous avez été déconnecté.",
            vpdRenderer_inactiveDisconnectedBody:
                "Vous avez été déconnecté en raison de votre inactivité. Cliquez sur le bouton ci-dessous pour essayer de vous reconnecter.",
            vdpRenderer_inactiveHeader: "Vous êtes inactif.",
            vdpRenderer_inactiveBody:
                "Vous êtes inactif depuis trop longtemps. Cliquez sur le bouton ci-dessous pour vous marquer comme actif, sinon vous serez retiré de la session lorsque le minuteur sera épuisé.",
            vdpRenderer_setSelfActiveButton: "Définissez votre statut sur actif",
            vdpRenderer_sessionDisconnected: "Tentative de reconnexion",
            vdpRenderer_sessionDisconnectedReload: "Veuillez recharger",
            vdpRenderer_endOfSession: "Fin de la session",
            vdpRenderer_invalidMapUrl:
                "Une URL de carte non valide a été fournie. Veuillez contacter Root pour obtenir de l’aide.",
            vdpRenderer_unexpectedError: "Une erreur système inattendue s’est produite. Voici les détails techniques :",
            vdpRenderer_unexpectedErrorMsg: "Une erreur inattendue s’est produite dans un état de session inconnu {0}",
            vdpRenderer_defaultEndingMessage:
                "Vous pouvez maintenant fermer votre fenêtre et vous déconnecter de l’audio.",
            vdpErrorModal_title: "Impossible de charger la page",
            vdpErrorModal_message:
                "Une erreur s’est produite ! Veuillez nous contacter pour obtenir de l’aide à l’adresse : vmap.help@rootinc.com",
            vdpErrorModal_body: "L’erreur peut être résolue en rechargeant cette page ou en cliquant ci-dessous :",
            vdpErrorModal_button: "Recharger cette expérience.",
            vdpNameEntry_welcomeText: "Bienvenue",
            vdpNameEntry_defaultWelcomeMessage:
                "Cette session de dialogue virtuel sera entièrement interactive. Préparez-vous à explorer les informations et à partager vos réflexions et opinions avec vos pairs.",
            vdpNameEntry_inputName: "Comment voulez-vous être appelé ?",
            vdpNameEntry_inputNamePlaceholder: "Saisissez le nom par lequel vous souhaitez être appelé...",
            vdpNameEntry_inputDepartment: "Dans quel service travaillez-vous ?",
            vdpNameEntry_inputEmail: "Quelle est votre adresse e-mail ?",
            vdpNameEntry_inputTeam: "De quelle équipe faites-vous partie ?",
            vdpNameEntry_identifiersName: "Veuillez saisir un nom",
            vdpNameEntry_identifiersDuplicateName:
                "Il semble qu’il y ait déjà un(e) {0} connecté(e) aujourd’hui. Souhaitez-vous essayer de saisir un autre nom afin que nous puissions vous distinguer ? Envisagez d’ajouter une initiale pour le nom de famille ou de saisir un surnom.",
            vdpNameEntry_identifiersDepartment: "Veuillez saisir le nom du service",
            vdpNameEntry_identifiersEmail: "Veuillez saisir l’adresse e-mail",
            vdpNameEntry_identifiersInvalidEmailFormat: "Format d’e-mail non valide",
            vdpNameEntry_identifiersTeam: "Veuillez saisir un nom d’équipe",
            vdpNameEntry_welcomeHeader: "C’est un plaisir de vous rencontrer, {0} !",
            vdpNameEntry_welcomeAdvance: "Rencontrez votre équipe",
            vdpNameEntry_claimFacilitatorTitle: "Revendiquer le rôle d’animateur",
            vdpNameEntry_facilitatorClaimed: "Rôle d’animateur revendiqué",
            vdpNameEntry_claimFacilitatorButton: "Se proposer comme animateur",
            vdpNameEntry_claimFacilitatorModalCancel: "Annuler",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Si vous êtes l’animateur de cette session, sélectionnez Revendiquer le rôle d’animateur ci-dessous.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "En tant qu’animateur, vous aurez le contrôle des premières pages pour nous aider à démarrer. Ensuite, le système sélectionnera automatiquement un nouveau navigateur.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Si vous avez été invité à cette session en tant que participant, veuillez sélectionner Annuler.",
            vdpNameEntry_numParticipantsInSession: "{0} participants attendent...",
            vdpNameEntry_oneParticipantsInSession: "1 participant attend...",
            vdpNameEntry_save: "Enregistrer",
            vdpNameEntry_continue: "Continuer",
            vdpNameEntry_thanks: "Merci, {0}. Nous allons bientôt commencer !",
            vdpNameEntry_intro:
                "Saisissez votre nom et attendez les autres participants. Nous allons bientôt commencer !",
            vdpNameEntry_introStarted: "Saisissez votre nom et rejoignez ceux qui sont déjà connectés.",
            vdpNameEntry_navDirections: "Attendez que l’équipe se connecte.",
            vdpValidationErrorMinLength: "Veuillez saisir au moins {0} caractères.",
            vdpValidationErrorMaxLength: "Veuillez ne pas saisir plus de {0} caractères.",
            toolbar_stepOf: "Section {0}, Page {1} -{2} ({3})",
            toolbar_intro: "Étape {0} de la section {1} :",
            toolbar_welcome: "Séquence de bienvenue",
            toolbar_exit: "Quitter la séquence",
            toolbar_reloadContent: "Charger de nouveau le contenu",
            toolbar_commentCreationButton: "Commentaire ({0})",
            toolbar_buildComments: "Créer des commentaires",
            toolbar_commentListButton: "Afficher les commentaires ({0})",
            toolbar_commentCreationTitle: "Ajouter un commentaire de création",
            toolbar_commentCreationDescription:
                "Les commentaires seront enregistrés et envoyés à Experience Builder.  Veuillez saisir votre nom avec le commentaire.",
            toolbar_commentCreationCancelButton: "Annuler",
            toolbar_commentCreationSubmitButton: "Publier un commentaire",
            toolbar_commentCreationSubmitButtonDisabled: "Publication du commentaire...",
            toolbar_commentCreationPlaceholder: "Saisir un commentaire...",
            toolbar_previewMode: "Mode Aperçu :",
            toolbar_aboutPreviewMode: "À propos du mode Aperçu",
            toolbar_focusAreaSelector: "Sélecteur de domaine d’intervention",
            toolbar_hotspotSelector: "Sélecteur de point d’accès",
            toolbar_previewModeModalTitle: "Mode Aperçu",
            toolbar_previewModeModalFirstIntroduction:
                "Vous visualisez cette expérience en mode Aperçu. Cette vue est conçue pour une révision interne Root uniquement et donne accès aux outils de création.",
            toolbar_previewModeModalSecondIntroduction:
                "Ces options sont uniquement disponibles en mode Aperçu et ne seront visibles ni le client, ni par les participants.",
            toolbar_previewModeModalReloadContent: "Charger de nouveau le contenu",
            toolbar_previewModeModalReloadContentDescription:
                "Utilisez ce bouton pour actualiser l’expérience en incorporant les dernières mises à jour de Contentful sans avoir à recharger la session.",
            toolbar_previewModeModalComments: "Commentaires",
            toolbar_previewModeModalCommentsDescription:
                "Ajoutez un commentaire à titre d’appréciation ou notez les changements à apporter. Les commentaires seront saisis dans Contentful pour l’équipe d’élaboration.",
            toolbar_previewModeModalMapCoordinates: "Coordonnées de carte",
            toolbar_previewModeModalMapCoordinatesDescription: "Copiez les coordonnées de la carte.",
            toolbar_previewModeModalHotspotSelector: "Sélecteur de point d’accès",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Visualisez les coordonnées des points d’accès, que vous copierez pour les utiliser dans Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Sélecteur de coordonnées",
            coordinatePreview_hotspotMode: "Mode point d’accès",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "Les coordonnées de point d’accès ont été copiées dans votre presse-papiers.",
            coordinatePreview_focusAreaMode: "Mode Domaine d’intervention",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "Les coordonnées du domaine d’intervention ont été copiées dans votre presse-papiers.",
            coordinatePreview_directionsTitle: "Indications pour la sélection des coordonnées",
            coordinatePreview_hotspotDirectionsLineOne: "Cliquez sur la carte pour créer des points d’accès.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Cliquez sur le bouton Copier pour chaque point d’accès afin de copier les coordonnées dans Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne:
                "Cliquez et déplacez le curseur pour tracer un rectangle sur la carte.",
            coordinatePreview_focusAreaDirectionsLineTwo: "Placez le rectangle sur la zone à afficher pour une étape.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Cliquez sur le bouton Copier pour chaque point d’accès afin de copier les coordonnées dans Contentful.",
            coordinatePreview_reset: "Réinitialiser",
            coordinatePreview_done: "Terminé",
            navigatorDefaultInstructions_selectNextToContinue: "Cliquez sur ***Suivant*** pour continuer.",
            navigatorDefaultInstructions_progressCheckLineOne: "Nous sommes arrivés à la fin de la section {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Vous progressez à bonne allure. Du bon travail jusqu’à présent !",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Vous êtes en avance sur le programme. Inutile de se précipiter sur le reste du module.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Vous êtes en retard sur le programme. Mieux vaut accélerer un peu pour terminer la session à temps.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Sur la page suivante, nous présenterons un nouveau navigateur pour la section {0}.",
            maintenanceWindow_heading: "La maintenance est programmée le {0}, {1} de {2} à {3} {4}.",
            maintenanceWindow_body: "Il se peut que l’application ne soit pas disponible pendant ce laps de temps.",
            maintenanceError_overlap:
                "Les dates que vous avez choisies sont en conflit avec une période de maintenance planifiée le {0}. La maintenance a lieu chaque semaine de {1} à {2}. Veuillez vous assurer que les dates de début et d’expiration ne chevauchent pas une fenêtre de maintenance.",
            progressCheck_sectionTitle: "Section {0} sur {1}",
            progressCheck_paceCheckTitle: "Vérification du rythme de la session",
            progressCheck_paceMessageOnPace: "Du bon travail jusqu’à présent !",
            progressCheck_paceMessageAheadOfPace: "Inutile de se précipiter sur le reste du module.",
            progressCheck_paceMessageBehindPace: "Mieux vaut accélerer un peu pour terminer la session à temps.",
            progressCheck_navigatorChangeTitle: "Changement de navigateur",
            progressCheck_navigatorChangeMessage:
                "Merci d’avoir animé cette session, {0}. Un nouveau navigateur sera affecté à la page suivante.",
            vdpIntro_title: "Introduction",
            vdpIntro_subtitle: "Quelques remarques avant de commencer...",
            vdpIntro_joinTheTeam: "Rejoignez l’équipe",
            vdpIntro_facilitatorRole: "Rôle de l’animateur",
            vdpIntro_navigatorRole: "Rôle du navigateur",
            vdpIntro_participantRole: "Rôle du participant",
            vdpIntro_iceBreaker: "Rencontrer l’équipe",
            vdpIntro_exploreTheVisual: "Le visuel",
            vdpIntro_skipShort: "Passer",
            vdpIntro_skipLong: "Passer l’introduction",
            vdpIntro_facilitatorInstructionsOne:
                "Bienvenue ! La session d’aujourd’hui est différente des autres cours ou formations auxquels vous avez pu assister.",
            vdpIntro_facilitatorInstructionsTwo:
                "Il n’y a pas ni enseignant, ni formateur – vous apprendrez les uns des autres et d’après le contenu que vous verrez à l’écran.",
            vdpIntro_facilitatorInstructionsThree:
                "Mon rôle en tant qu’animateur(trice) est de maintenir l’orientation de la conversation et de tenir le rythme du cours.",
            vdpIntro_navigatorInstructionsOne:
                "Pour les prochains écrans, je servirai également de navigateur(trice). Le rôle des navigateurs est de lire le texte de l’introduction sur chaque écran, de sélectionner le bouton ***Suivant*** pour continuer à avancer dans l’expérience et de suivre d’autres instructions à l’invite.",
            vdpIntro_navigatorInstructionsTwo:
                "Le rôle de navigateur sera attribué de manière aléatoire et changera tous les écrans.",
            vdpIntro_participantInstructionsOne:
                "Vous êtes tous des participants ! Votre rôle est de participer activement en partageant vos points de vue avec le groupe. Vous aiderez la session à se dérouler sans heurts en parlant à tour de rôle, en respectant les pensées et les opinions des autres et en maintenant la conversation sur la bonne voie.",
            vdpIntro_participantInstructionsTwo:
                "Vous pouvez voir qui est dans la session et qui a chaque rôle à l’aide du bouton ***Équipe*** en bas à gauche.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Ensuite, nous aurons chacun l’occasion de voir à quoi ressemble le rôle de navigateur lorsque nous nous présenterons.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Lorsque vous voyez la bannière ***Vous êtes le navigateur*** au-dessus du texte d’introduction, c’est à votre tour de lire et de suivre les instructions. Quelqu’un a-t-il des questions ?",
            vdpIntro_iceBreakerInstructionsOne: "Bonjour, je suis {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "L’un de mes traits caractéristiques est _______.",
            vdpIntro_mapInstructionsOne:
                "Maintenant que nous nous sommes tous rencontrés, apprenons-en plus sur notre session d’aujourd’hui.",
            vdpIntro_mapInstructionsTwo:
                "Notre conversation se concentrera sur une image appelée *Carte d’apprentissage*®. Il s’agit d’une métaphore illustrée représentant des aspects de notre activité que nous explorerons ensemble. ",
            vdpIntro_iceBreakerIntroDirections: "Répondez à toutes les questions.",
            vdpEnd_title: "Merci.",
            vdpEnd_body:
                "Vous avez terminé notre expérience. Merci d’avoir participé ! Nous espérons que vous avez trouvé la discussion d’aujourd’hui intéressante.",
            vdpEnd_surveyRequest:
                "Veuillez prendre quelques minutes pour nous faire part de vos réflexions et réactions concernant les informations dont nous avons discuté. Votre feedback est essentiel à notre succès.",
            vdpEnd_surveyThankYou: "Merci pour votre réponse.",
            vdpEnd_sessionComplete: "La session est terminée.",
            vdpEnd_closeWindow: "Vous pouvez fermer cette fenêtre.",
            privacyBanner_message:
                "Nous utilisons des cookies pour activer certaines fonctionnalités du site Web afin de vous offrir une expérience plus pertinente. Pour plus d’informations, veuillez consulter notre [Politique relative aux cookies]({0}) et notre [Déclaration de confidentialité]({1}).",
            privacyBanner_dismiss: "Rejeter",
        },
    },
    ja: {
        name: "Japanese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "アクティビティ {0} が見つかりません ",
            vdpActivity_closeModal: "閉じる",
            vdpActivity_correctExclamation: "正解です!",
            vdpActivity_correct: "正解です",
            vdpActivity_correctAnswerBadge: "正しい回答です",
            vdpActivity_partiallyCorrect: "一部正解です。",
            vdpActivity_incorrect: "不正解",
            vdpActivity_notCorrect: "不正解",
            vdpActivity_continue: "同じ場所から再開",
            vdpActivity_checkAnswer: "回答をチェック",
            vdpActivity_ready: "準備ができました。",
            vdpActivity_cardsPlaced: "すべてのカードが配置されました。",
            vdpActivity_cardsCorrectHeader: "成功！",
            vdpActivity_cardsCorrect: "カードはすべべ正常に配置されました。",
            vdpActivity_cardsCorrectContinue: "次のアクティビティに進んでください。",
            vdpActivity_cardsPartiallyCorrectHeader: "不正解です。",
            vdpActivity_cardsPartiallyCorrect: "誤った回答を見直し、新しい場所への移動を試してください。",
            vdpActivity_cardsCheckAnswers: "回答をチェックする",
            vdpActivity_textEntryPlaceholder: "あなたの回答",
            vdpActivity_textEntryNavigatorPrompt: "ナビゲーター {0} が下にテキストを入力します",
            vdpActivity_textEntryPrompt: "以下にテキストを入力してください。",
            vdpActivity_textEntrySubmit: "送信",
            vdpActivity_selectOne: "1つ選択してください",
            vdpActivity_selectOneOrMany: "1つ以上選択してください",
            vdpActivity_defaultCurrentParticipantName: "自分",
            vdpActivity_hotspotTooltip: "ホットスポットをクリックして詳細を確認します。",
            vdpClipboard_copied: "クリップボードにコピーされました。",
            vdpClipboard_copy: "クリップボードにコピーする",
            vdpClipboard_error: "クリップボードにコピーできません",
            vdpHud_hide: "非表示",
            vdpHud_show: "表示",
            vdpHud_changeNavigatorTitle: "ナビゲーターを新しい人に変更します。",
            vdpHud_changeNavigatorText1:
                '"ナビゲーターの変更"をクリックしました。これにより、別のナビゲーターがランダムに選択され、このページのすべての対話が一時的に無効になります。',
            vdpHud_changeNavigatorText2: "ナビゲーターを変更してもいいですか。",
            vdpHud_close: "閉じる",
            vdpHud_accept: "ナビゲータの変更",
            vdpHud_back: "戻る",
            vdpHud_next: "次へ",
            vdpHud_endExperience: "エクスペリエンスの終了",
            vdpHud_defaultExitMsg:
                "体験が完了しました。ご参加いただきありがとうございます。本日のディスカッションは洞察に満ちたものとなったでしょうか。このウィンドウを閉じることができます。",
            vdpHud_navigatorReminder: "あなたはナビゲーターです。以下をお読みください。",
            vdpHud_timerNoTimeSpecified: "セクションに時間が指定されていない",
            vdpHud_timerRemaining: "セクションに残っている",
            vdpHud_timerLessThanOneMinute: "残り1分未満",
            vdpHud_timerOver: "以上",
            vdpHud_roleNone: "割り当てなし",
            vdpHud_infoTableSection: "セクション",
            vdpHud_infoTableRecommended: "推奨",
            vdpHud_infoTableActual: "実績",
            vdpHud_infoTableTotal: "マージン総額",
            vdpHud_infoTableRemaining: "残り",
            vdpHud_infoTeamButton: "チーム",
            vdpHud_infoTeamParticipantJoining: "ユーザーが参加しています。",
            vdpHud_navigator: "ナビゲーター",
            vdpHud_facilitator: "ファシリテーター",
            vdpHud_facilitatorMenuMakeNavigator: "ナビゲーターの作成",
            vdpHud_facilitatorMenuRemoveFacilitator: "ファシリテーターの役割を削除",
            vdpHud_removeFacilitatorWarningConfirm: "確認",
            vdpHud_removeFacilitatorWarningCancel: "キャンセル",
            vdpHud_removeFacilitatorWarningMessage:
                "あなたはファシリテーターとしての役割を終了しようとしています。続行してもよろしいですか。",
            vdpHud_progressOnPaceMessage: "順調に進んでいる",
            vdpHud_progressAheadOfPaceMessage: "予定より進んでいる",
            vdpHud_progressBehindPaceMessage: "予定より遅れている",
            vdpHud_progressTimeRemaining: "{0} セクションに残っている",
            vdpHud_progressStepsInSection: "{0}/{1}ページ",
            vdpHud_zoomControlsTooltip: "マップのズームコントロール",
            sidebar_scheduleButton: "スケジュール",
            sidebar_teamAssignNavigator: "ナビゲーターの割り当て",
            sidebar_teamChooseRandomly: "ランダムに選択",
            sidebar_teamChooseHelperText:
                "ナビゲーターを務める適格な参加者を無作為に選択します。全員が交代するまで、参加者は再選されません。",
            sidebar_editNameMenuItem: "名前の編集",
            sidebar_editNameSave: "変更を保存",
            sidebar_nameEditPlaceholder: "呼び出す名前を入力してください。",
            sidebar_manageRolesMenuLabel: "役割の管理",
            sidebar_navigatorDescription:
                "質問、プロンプト、指示を声に出して読みます。「次へ」を選択し、各スクリーンに記載されている他の指示に従って、エクスペリエンスを通してグループを前に進めます。",
            sidebar_facilitatorDescription:
                "セッション全体を通して、グループが充実したディスカッションを行い、会話が順調に進むようにガイドとしての役割を果たします。",
            sidebar_previousButton: "前へ",
            sidebar_nextButton: "次へ",
            sidebar_lockTooltip: "ページを変更できるのはナビゲーターのみです。",
            sidebar_navigatorBadge: "あなたはナビゲーターです",
            sidebar_facilitatorBadge: "あなたはファシリテーターです",
            sidebar_introductionLabel: "はじめに",
            sidebar_introductionInstruction: "声に出して、グループに読み上げます。",
            sidebar_directionsLabel: "指示",
            sidebar_nonNavigatorDirectionsLabel: "{0}は以下を実行します。",
            sidebar_directionsInstruction: "以下を実行します。",
            sidebar_facilitationNotesLabel: "ファシリテーションメモ",
            genUrl_copyLink: "リンクをコピー",
            genUrl_sessionLinkDetails:
                "ページを更新すると、これらのセッションリンクは消えます。このページを離れる前に、必ずこれらのリンクをダウンロードまたはコピーしてください。",
            genUrl_exportAsCsv: "CSVとしてエクスポート",
            genUrl_copyTable: "テーブルをコピー",
            genUrl_linkExpiration: "リンクの有効期限",
            genUrl_link: "リンク",
            genUrl_title: "{0} セッションリンク ジェネレータ",
            genUrl_experience: "エクスペリエンス： {0}",
            genUrl_description:
                "このポータルでは、このエクスペリエンスのセッションリンクを生成できます。セッションリンクを生成するには、生成するセッションの数を入力し、セッションが期限切れになる日付を選択し、「生成」をクリックします。これらのセッションが生成されたら、コピーして、お気に入りのテキストエディタまたはスプレッドシートプログラムに貼り付けます。ページを更新すると、これらのセッションリンクは消えます。",
            genUrl_numSessions: "セッション数：",
            genUrl_sessionsExpirationLabel: "セッションリンクの有効期限：",
            genUrl_sessionsOpenAtLabel: "セッションリンクは、次の日付から有効です。",
            genUrl_numSessionsInputLabel: "セッション数",
            genUrl_sessionLanguageLabel: "セッション言語",
            genUrl_numSessionsInputTypeError: "数字でなければなりません",
            genUrl_closesOnError: "将来の日付を選択",
            genUrl_experienceClosesOnError: "{0} より前の日付を選択",
            genUrl_notifSuccessSingle: "{0} リンクが正常に生成されました",
            genUrl_notifSuccessMultiple: "{0} リンクが正常に生成されました",
            genUrl_notifErrorGenerating: "セッションを生成できません。再試行するか、管理者にお問い合わせください。",
            genUrl_notifErrorProcessing: "処理できません。管理者に連絡してください。",
            genUrl_sessionsExpireToday: "セッションは、本日期限切れになります",
            genUrl_sessionsExpireInOneDay: "セッションは、{0} 日後に期限切れになります",
            genUrl_sessionsExpireInDays: "セッションは、{0} 日後に期限切れになります",
            genUrl_sessionsOpenToday: "セッションは、本日開始します",
            genUrl_sessionsOpenOneDay: "あと {0} 日でセッションが開始します",
            genUrl_sessionsOpenDays: "あと {0} 日でセッションが開始します",
            genUrl_generate: "セッションを作成",
            genUrl_schedulingLinkExpired: "スケジュールリンクの有効期限が切れています。",
            genUrl_error: "エラー",
            genUrl_dataCollectionLabel: "データ収集",
            genUrl_emailContact: "電子メールの連絡先",
            vdpLoading_waitForContent: "コンテンツを読み込むまでお待ちください。",
            vdpNotFound_title: "仮想対話プラットフォーム",
            vdpNotFound_message: "お探しのページが見つかりませんでした。",
            vdpRenderer_refresh: "更新",
            vdpRenderer_sessionDisconnectedHeader: "切断されました。",
            vpdRenderer_inactiveDisconnectedBody:
                "非アクティブのため切断されました。以下のボタンをクリックして、再接続を試してください。",
            vdpRenderer_inactiveHeader: "非アクティブです。",
            vdpRenderer_inactiveBody:
                "非アクティブ状態が長すぎます。下のボタンをクリックして、自分をアクティブにマークしてください。さもなければ、タイマーが切れるとセッションから削除されます。",
            vdpRenderer_setSelfActiveButton: "自分自身をアクティブに設定",
            vdpRenderer_sessionDisconnected: "再接続を試しています",
            vdpRenderer_sessionDisconnectedReload: "再読み込みしてください",
            vdpRenderer_endOfSession: "セッションの終了",
            vdpRenderer_invalidMapUrl:
                "無効なマップのURLが指定されました。サポートが必要な場合は、Rootにお問い合わせください。",
            vdpRenderer_unexpectedError: "予期しないシステムエラーが発生しました。技術的な詳細は次のとおりです。",
            vdpRenderer_unexpectedErrorMsg: "予期しない不明なセッション状態 {0} のエラーが発生しました",
            vdpRenderer_defaultEndingMessage: "これで、ウィンドウを閉じて音声から切断できます。",
            vdpErrorModal_title: "ページを読み込めません",
            vdpErrorModal_message:
                "問題が発生しました。サポートが必要な場合は、vmap.help@rootinc.comへお問い合わせください",
            vdpErrorModal_body: "このページを再ロードするか、以下をクリックすると、エラーを解決できる場合があります。",
            vdpErrorModal_button: "このエクスペリエンスを再ロードします。",
            vdpNameEntry_welcomeText: "ようこそ",
            vdpNameEntry_defaultWelcomeMessage:
                "このバーチャル対話セッションは、完全インタラクティブ型です。情報を探し、自分の考えや意見を仲間と共有できるよう準備します。",
            vdpNameEntry_inputName: "どう呼ばれたいですか。",
            vdpNameEntry_inputNamePlaceholder: "呼び出す名前を入力してください。",
            vdpNameEntry_inputDepartment: "所属部署はどこですか。",
            vdpNameEntry_inputEmail: "あなたのメールアドレスは。",
            vdpNameEntry_inputTeam: "どのチームに所属していますか。",
            vdpNameEntry_identifiersName: "名前を入力してください",
            vdpNameEntry_identifiersDuplicateName:
                "今日はすでに {0} 接続されているようです。区別できるように、別の名前を入力してみてください。姓のイニシャルを追加するか、ニックネームを入力してください。",
            vdpNameEntry_identifiersDepartment: "所属部署名を入力してください",
            vdpNameEntry_identifiersEmail: "メールアドレスを入力してください",
            vdpNameEntry_identifiersInvalidEmailFormat: "無効な電子メール形式",
            vdpNameEntry_identifiersTeam: "チーム名を入力してください",
            vdpNameEntry_welcomeHeader: "お会いできてうれしいです {0}。",
            vdpNameEntry_welcomeAdvance: "チームの紹介",
            vdpNameEntry_claimFacilitatorTitle: "ファシリテーターの役割を要求する",
            vdpNameEntry_facilitatorClaimed: "要求したファシリテーターの役割",
            vdpNameEntry_claimFacilitatorButton: "ファシリテーターを請け負う",
            vdpNameEntry_claimFacilitatorModalCancel: "キャンセル",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "あなたがこのセッションのファシリテーターである場合は、以下の「ファシリテーターを請け負う」を選択してください。",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "ファシリテーターは、最初の数ページを制御し、開始することができます。その後、新しいナビゲーターが自動的に選択されます。",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "参加者としてこのセッションに招待されている場合は、キャンセルを選択してください。",
            vdpNameEntry_numParticipantsInSession: "{0} 参加者が待機しています。",
            vdpNameEntry_oneParticipantsInSession: "1人の参加者が待機しています。",
            vdpNameEntry_save: "保存",
            vdpNameEntry_continue: "同じ場所から再開",
            vdpNameEntry_thanks: "では、よろしくお願いします、{0}さん。まもなく開始します。",
            vdpNameEntry_intro: "自分の名前を入力し、他の人が参加するのを待ちます。まもなく開始します。",
            vdpNameEntry_introStarted: "自分の名前を入力し、同僚に合流してください。",
            vdpNameEntry_navDirections: "チームが参加するのを待ちます。",
            vdpValidationErrorMinLength: "{0}文字以上で入力してください。",
            vdpValidationErrorMaxLength: "{0}文字以内で入力してください。",
            toolbar_stepOf: "セクション {0}、{1} - {2} ページ（{3}）",
            toolbar_intro: "セクション {1} のステップ {0}",
            toolbar_welcome: "ウェルカムシーケンス",
            toolbar_exit: "シーケンスを終了する",
            toolbar_reloadContent: "コンテンツの再読み込み",
            toolbar_commentCreationButton: "コメント（{0}）",
            toolbar_buildComments: "コメントの作成",
            toolbar_commentListButton: "コメントの表示（{0}）",
            toolbar_commentCreationTitle: "ビルドコメントの追加",
            toolbar_commentCreationDescription:
                "コメントが保存され、エクスペリエンスビルダーに送信されます。名前とコメントを入力してください。",
            toolbar_commentCreationCancelButton: "キャンセル",
            toolbar_commentCreationSubmitButton: "投稿コメント",
            toolbar_commentCreationSubmitButtonDisabled: "コメントを投稿しています。",
            toolbar_commentCreationPlaceholder: "コメントを入力します。",
            toolbar_previewMode: "プレビューモード：",
            toolbar_aboutPreviewMode: "プレビューモードについて",
            toolbar_focusAreaSelector: "フォーカスエリアのセレクター",
            toolbar_hotspotSelector: "ホットスポットセレクター",
            toolbar_previewModeModalTitle: "プレビューモード",
            toolbar_previewModeModalFirstIntroduction:
                "この体験はプレビューモードで表示しています。このビューはRootの内部レビュー専用に設計されており、オーサリングツールへのアクセスを提供します。",
            toolbar_previewModeModalSecondIntroduction:
                "これらのオプションはプレビューモードでのみ使用でき、クライアントや参加者には表示されません。",
            toolbar_previewModeModalReloadContent: "コンテンツの再読み込み",
            toolbar_previewModeModalReloadContentDescription:
                "このボタンを使用して、セッションを再読み込みすることなく、Contentfulから最新のアップデートで体験を更新します。",
            toolbar_previewModeModalComments: "コメント",
            toolbar_previewModeModalCommentsDescription:
                "コメントを追加してフィードバックを提供するか、行うべき変更があれば指摘します。コメントは、構築チームのためにContentfulに取り込まれます。",
            toolbar_previewModeModalMapCoordinates: "マップの座標",
            toolbar_previewModeModalMapCoordinatesDescription: "マップの座標をコピーしてください。",
            toolbar_previewModeModalHotspotSelector: "ホットスポットセレクター",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Contentfulで使用するホットスポットのロケーションをプレビューして、座標をコピーします。",
            coordinatePreview_coordinatesSelectorTitle: "座標セレクター",
            coordinatePreview_hotspotMode: "ホットスポットモード",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "ホットスポット座標がクリップボードにコピーされました。",
            coordinatePreview_focusAreaMode: "フォーカスエリアモード",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "フォーカスエリア座標がクリップボードにコピーされました。",
            coordinatePreview_directionsTitle: "座標セレクターの方向",
            coordinatePreview_hotspotDirectionsLineOne: "マップをクリックしてホットスポットを作成します。",
            coordinatePreview_hotspotDirectionsLineTwo:
                "各ホットスポットで[コピー]ボタンをクリックして、座標をContentfulにコピーします。",
            coordinatePreview_focusAreaDirectionsLineOne: "クリックしてドラッグし、マップ上に長方形を描きます。",
            coordinatePreview_focusAreaDirectionsLineTwo: "長方形を、ステップで表示するエリア上に置きます。",
            coordinatePreview_focusAreaDirectionsLineThree:
                "各フォーカスエリアで[コピー]ボタンをクリックして、座標をContentfulにコピーします。",
            coordinatePreview_reset: "リセット",
            coordinatePreview_done: "完了",
            navigatorDefaultInstructions_selectNextToContinue: "***[次へ]***をクリックして続行します。",
            navigatorDefaultInstructions_progressCheckLineOne: "セクション {0} の終わりに達しました。",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "予定どおりのペースで進んでいます。良い仕事を続けましょう。",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "予定より速いペースで進んでいます。残りのマテリアルを急いで進むことのないようにしましょう。",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "予定より遅いペースで進んでいます。時間通りにセッションを完了するには、進行速度を速める必要があります。",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "次のページでは、セクション {0} の新しいナビゲーターを紹介します。",
            maintenanceWindow_heading: "メンテナンスは{0}、{2}から{1}まで、{3}{4}で予定されています。",
            maintenanceWindow_body: "この間、アプリケーションは利用できない場合があります。",
            maintenanceError_overlap:
                "選択した日付は、{0}に予定されたメンテナンス期間と重複しています。メンテナンスは、{1}から{2}まで毎週行われます。開始日と有効期限がメンテナンス期間に該当しないことを確認してください。",
            progressCheck_sectionTitle: "セクション {0} / {1}",
            progressCheck_paceCheckTitle: "セッションのペースチェック",
            progressCheck_paceMessageOnPace: "良い仕事を続けましょう。",
            progressCheck_paceMessageAheadOfPace: "残りのマテリアルを急いで進むことのないようにしましょう。",
            progressCheck_paceMessageBehindPace: "時間通りにセッションを完了するには、進行速度を速める必要があります。",
            progressCheck_navigatorChangeTitle: "ナビゲーターの変更",
            progressCheck_navigatorChangeMessage:
                "リードしていただきありがとうございます、{0}さん 次のページで新しいナビゲーターが割り当てられます。",
            vdpIntro_title: "はじめに",
            vdpIntro_subtitle: "始める前に、いくつかの簡単なことを行います...",
            vdpIntro_joinTheTeam: "チームに加わる",
            vdpIntro_facilitatorRole: "ファシリテーターの役割",
            vdpIntro_navigatorRole: "ナビゲーターの役割",
            vdpIntro_participantRole: "参加者の役割",
            vdpIntro_iceBreaker: "チームを見る",
            vdpIntro_exploreTheVisual: "ビジュアル",
            vdpIntro_skipShort: "スキップ",
            vdpIntro_skipLong: "「はじめに」のスキップ",
            vdpIntro_facilitatorInstructionsOne:
                "ようこそ！今日のセッションは、これまで参加した他のコースやトレーニングとは異なります。",
            vdpIntro_facilitatorInstructionsTwo:
                "教師やトレーナーはいません。お互いから、また画面上の情報から学びましょう。",
            vdpIntro_facilitatorInstructionsThree:
                "ファシリテーターとしての私の役割は、会話を順調にかつ予定通りに進めることです。",
            vdpIntro_navigatorInstructionsOne:
                "次のいくつかの画面では、私はナビゲーターも務めます。ナビゲーターの役割は、各画面で「はじめに」のテキストを読み、***[次へ]***ボタンを選択して体験を進め、プロンプト表示のとおり他の指示に従うことです。",
            vdpIntro_navigatorInstructionsTwo: "ナビゲーターの役割は無作為に割り当てられ、数画面ごとに変更されます。",
            vdpIntro_participantInstructionsOne:
                "皆さんが全員参加者です！あなたの役割は、自分の見解をグループと共有して、積極的に参加することです。順番に話したり、他者の考えや意見を尊重したり、会話を順調に進めたりすることで、セッションの円滑な進行を助けます。",
            vdpIntro_participantInstructionsTwo:
                "左下の***[チーム]***ボタンを使用すれば、セッション参加者や、各役割の担当者を確認できます。",
            vdpIntro_iceBreakerIntroInstructionsOne: "次に、自己紹介を通して、ナビゲーターの役割を理解します。",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "「はじめに」のテキストの上に***You’re the Navigator（あなたはナビゲーターです）***バナーが表示されたら、指示を読み、内容に従ってください。質問がある方はいますか？",
            vdpIntro_iceBreakerInstructionsOne: "こんにちは、{0}です。",
            vdpIntro_iceBreakerInstructionsDefault: "私に関するある事実は_______です。",
            vdpIntro_mapInstructionsOne: "全員が集合したので、本日のセッションについて詳しく学びましょう。",
            vdpIntro_mapInstructionsTwo:
                "*Learning Map*®ビジュアルと呼ばれる画像を中心に会話を進めます。これは、私たちが共に取り上げる当社事業の側面を表すイラスト入りの例えです。",
            vdpIntro_iceBreakerIntroDirections: "私の質問に答えてください。",
            vdpEnd_title: "ありがとうございます。",
            vdpEnd_body:
                "体験が完了しました。ご参加いただきありがとうございます。本日のディスカッションは洞察に満ちたものとなったでしょうか。",
            vdpEnd_surveyRequest:
                "少し時間を設けるので、話し合った情報についてあなたの感想やご意見をお聞かせください。皆さんのフィードバックは、当社の成功にとって重要です。",
            vdpEnd_surveyThankYou: "ご回答ありがとうございます。",
            vdpEnd_sessionComplete: "セッションが完了しました。",
            vdpEnd_closeWindow: "このウィンドウを閉じることができます。",
            privacyBanner_message:
                "当社は、お客様により関連性のある体験を提供する目的で、一部のウェブサイト機能を有効にするためにクッキーを使用しています。詳細については、当社の[クッキーポリシー]({0}）および[プライバシーステートメント]({1})をご覧ください。",
            privacyBanner_dismiss: "解散",
        },
    },
    pt: {
        name: "Portuguese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "Não é possível encontrar a atividade {0}",
            vdpActivity_closeModal: "Fechar",
            vdpActivity_correctExclamation: "Correto!",
            vdpActivity_correct: "Correto",
            vdpActivity_correctAnswerBadge: "Resposta correta",
            vdpActivity_partiallyCorrect: "Parcialmente correto!",
            vdpActivity_incorrect: "Incorreto",
            vdpActivity_notCorrect: "Não correto",
            vdpActivity_continue: "Continuar",
            vdpActivity_checkAnswer: "Verificar resposta",
            vdpActivity_ready: "Pronto.",
            vdpActivity_cardsPlaced: "Todos os cartões foram colocados.",
            vdpActivity_cardsCorrectHeader: "Sucesso!",
            vdpActivity_cardsCorrect: "Todos os cartões foram colocados com sucesso.",
            vdpActivity_cardsCorrectContinue: "Continue para a próxima atividade.",
            vdpActivity_cardsPartiallyCorrectHeader: "Não exatamente.",
            vdpActivity_cardsPartiallyCorrect: "Revise as respostas incorretas e tente movê-las para um novo lugar.",
            vdpActivity_cardsCheckAnswers: "Verifique suas respostas",
            vdpActivity_textEntryPlaceholder: "Sua resposta",
            vdpActivity_textEntryNavigatorPrompt: "O navegador, {0}, inserirá o texto abaixo",
            vdpActivity_textEntryPrompt: "Insira o texto abaixo:",
            vdpActivity_textEntrySubmit: "Enviar",
            vdpActivity_selectOne: "Selecione um",
            vdpActivity_selectOneOrMany: "Selecione um ou vários",
            vdpActivity_defaultCurrentParticipantName: "Eu",
            vdpActivity_hotspotTooltip: "Clique nos pontos em destaque para saber mais.",
            vdpClipboard_copied: "Copiado para a área de transferência!",
            vdpClipboard_copy: "Copiar para a área de transferência",
            vdpClipboard_error: "Não é possível copiar para a área de transferência",
            vdpHud_hide: "Ocultar",
            vdpHud_show: "Mostrar",
            vdpHud_changeNavigatorTitle: "Altere o navegador para uma nova pessoa.",
            vdpHud_changeNavigatorText1:
                'Você clicou em "Alterar o navegador". Isso escolherá aleatoriamente outro navegador e desativará brevemente todas as interações nesta página.',
            vdpHud_changeNavigatorText2: "Tem certeza de que deseja alterar o navegador?",
            vdpHud_close: "Fechar",
            vdpHud_accept: "Alterar o navegador",
            vdpHud_back: "Voltar",
            vdpHud_next: "Avançar",
            vdpHud_endExperience: "Experiência final",
            vdpHud_defaultExitMsg:
                "Concluímos a nossa experiência. Obrigado por participar! Esperamos que a discussão de hoje tenha sido informativa para você. Você pode fechar esta janela.",
            vdpHud_navigatorReminder: "Você é o navegador. Leia o seguinte:",
            vdpHud_timerNoTimeSpecified: "Não há tempo especificado para a seção",
            vdpHud_timerRemaining: "restantes na seção",
            vdpHud_timerLessThanOneMinute: "Falta menos de um minuto",
            vdpHud_timerOver: "mais de",
            vdpHud_roleNone: "Nenhum atribuído",
            vdpHud_infoTableSection: "Seção",
            vdpHud_infoTableRecommended: "Recomendado",
            vdpHud_infoTableActual: "Real",
            vdpHud_infoTableTotal: "Total",
            vdpHud_infoTableRemaining: "Restante",
            vdpHud_infoTeamButton: "Equipe",
            vdpHud_infoTeamParticipantJoining: "Usuário ingressando...",
            vdpHud_navigator: "Navegador",
            vdpHud_facilitator: "Facilitador",
            vdpHud_facilitatorMenuMakeNavigator: "Criar navegador",
            vdpHud_facilitatorMenuRemoveFacilitator: "Remover função do facilitador",
            vdpHud_removeFacilitatorWarningConfirm: "Confirmar",
            vdpHud_removeFacilitatorWarningCancel: "Cancelar",
            vdpHud_removeFacilitatorWarningMessage:
                "Você está prestes a deixar sua função como facilitador. Tem certeza de que deseja continuar?",
            vdpHud_progressOnPaceMessage: "No ritmo",
            vdpHud_progressAheadOfPaceMessage: "À frente do ritmo",
            vdpHud_progressBehindPaceMessage: "Atrás do ritmo",
            vdpHud_progressTimeRemaining: "{0} restantes na seção",
            vdpHud_progressStepsInSection: "Página {0} de {1}",
            vdpHud_zoomControlsTooltip: "Controles de zoom de mapa",
            sidebar_scheduleButton: "Agendamento",
            sidebar_teamAssignNavigator: "Atribuir navegador",
            sidebar_teamChooseRandomly: "Escolher aleatoriamente",
            sidebar_teamChooseHelperText:
                "Escolhe um participante elegível aleatoriamente para servir como o navegador. Os participantes não serão escolhidos novamente até que todos tenham participado.",
            sidebar_editNameMenuItem: "Editar nome",
            sidebar_editNameSave: "Salvar alterações",
            sidebar_nameEditPlaceholder: "Insira o nome pelo qual deseja ser chamado...",
            sidebar_manageRolesMenuLabel: "Gerenciar funções",
            sidebar_navigatorDescription:
                "Lê perguntas, avisos e instruções em voz alta. Avança o grupo pela experiência selecionando Avançar e seguindo outras instruções indicadas em cada tela.",
            sidebar_facilitatorDescription:
                "Serve como guia durante toda a sessão para garantir que o grupo tenha uma discussão rica e que a conversa permaneça no caminho certo.",
            sidebar_previousButton: "Anterior",
            sidebar_nextButton: "Avançar",
            sidebar_lockTooltip: "Somente o navegador pode alterar a página.",
            sidebar_navigatorBadge: "Você é o navegador",
            sidebar_facilitatorBadge: "Você é um facilitador",
            sidebar_introductionLabel: "Introdução",
            sidebar_introductionInstruction: "Leia em voz alta para o grupo:",
            sidebar_directionsLabel: "Orientações",
            sidebar_nonNavigatorDirectionsLabel: "{0} faça o seguinte:",
            sidebar_directionsInstruction: "Faça o seguinte:",
            sidebar_facilitationNotesLabel: "Notas de facilitação",
            genUrl_copyLink: "Copiar link",
            genUrl_sessionLinkDetails:
                "Esses links de sessões desaparecerão quando você atualizar a página. Certifique-se de baixar ou copiar esses links antes de sair desta página.",
            genUrl_exportAsCsv: "Exportar como CSV",
            genUrl_copyTable: "Copiar tabela",
            genUrl_linkExpiration: "Expiração do link",
            genUrl_link: "Link",
            genUrl_title: "Gerador de link da sessão do {0}",
            genUrl_experience: "Experiência: {0}",
            genUrl_description:
                "Este portal permite gerar links de sessão para esta experiência. Para gerar links de sessão, insira o número de sessões que você gostaria de gerar, escolha uma data em que as sessões devem expirar e clique em “Gerar”. Depois que essas sessões forem geradas, copie e cole-as no seu editor de texto ou programa de planilhas favorito. Depois de atualizar a página, todas as sessões desaparecerão.",
            genUrl_numSessions: "Número de sessões:",
            genUrl_sessionsExpirationLabel: "Os links da sessão expiram em:",
            genUrl_sessionsOpenAtLabel: "Os links da sessão são válidos a partir de:",
            genUrl_numSessionsInputLabel: "Número de sessões",
            genUrl_sessionLanguageLabel: "Idioma da sessão",
            genUrl_numSessionsInputTypeError: "Deve ser um número",
            genUrl_closesOnError: "Escolha uma data no futuro",
            genUrl_experienceClosesOnError: "Escolha uma data anterior a {0}",
            genUrl_notifSuccessSingle: "{0} link gerado com sucesso",
            genUrl_notifSuccessMultiple: "{0} links gerados com sucesso",
            genUrl_notifErrorGenerating:
                "Não é possível gerar sessões. Tente novamente ou entre em contato com o administrador.",
            genUrl_notifErrorProcessing: "Impossível processar. Entre em contato com um administrador.",
            genUrl_sessionsExpireToday: "As sessões expiram hoje",
            genUrl_sessionsExpireInOneDay: "As sessões expiram em {0} dia",
            genUrl_sessionsExpireInDays: "As sessões expiram em {0} dias",
            genUrl_sessionsOpenToday: "As sessões abrirão hoje",
            genUrl_sessionsOpenOneDay: "As sessões abrirão em {0} dia",
            genUrl_sessionsOpenDays: "As sessões abrirão em {0} dias",
            genUrl_generate: "Gerar sessões",
            genUrl_schedulingLinkExpired: "O link de agendamento expirou.",
            genUrl_error: "Erro",
            genUrl_dataCollectionLabel: "Coleta de dados",
            genUrl_emailContact: "Contato por e-mail",
            vdpLoading_waitForContent: "Aguarde enquanto seu conteúdo carrega…",
            vdpNotFound_title: "Plataforma de diálogo virtual",
            vdpNotFound_message: "Não foi possível encontrar a página que você está procurando.",
            vdpRenderer_refresh: "Atualizar",
            vdpRenderer_sessionDisconnectedHeader: "Você foi desconectado.",
            vpdRenderer_inactiveDisconnectedBody:
                "Você foi desconectado devido a inatividade. Clique no botão abaixo para tentar reconectar.",
            vdpRenderer_inactiveHeader: "Você está inativo.",
            vdpRenderer_inactiveBody:
                "Você está inativo há muito tempo. Clique no botão abaixo para marcar-se como ativo ou você será removido da sessão quando o temporizador expirar.",
            vdpRenderer_setSelfActiveButton: "Defina-se como ativo",
            vdpRenderer_sessionDisconnected: "Tentando reconectar",
            vdpRenderer_sessionDisconnectedReload: "Recarregue",
            vdpRenderer_endOfSession: "Fim da sessão",
            vdpRenderer_invalidMapUrl:
                "Um URL de mapa inválido foi fornecido. Entre em contato com a Root para obter ajuda.",
            vdpRenderer_unexpectedError: "Ocorreu um erro inesperado no sistema. Aqui estão os detalhes técnicos:",
            vdpRenderer_unexpectedErrorMsg: "Ocorreu um erro inesperado de estado desconhecido da sessão {0}",
            vdpRenderer_defaultEndingMessage: "Agora você pode fechar a janela e desconectar do áudio.",
            vdpErrorModal_title: "Impossível carregar a página",
            vdpErrorModal_message:
                "Algo deu errado! Entre em contato conosco para obter ajuda em: vmap.help@rootinc.com",
            vdpErrorModal_body: "O erro pode ser resolvido recarregando esta página ou clicando abaixo:",
            vdpErrorModal_button: "Recarregue esta experiência.",
            vdpNameEntry_welcomeText: "Bem-vindo(a)",
            vdpNameEntry_defaultWelcomeMessage:
                "Esta sessão de diálogo virtual será totalmente interativa. Prepare-se para explorar informações e compartilhar suas ideias e opiniões com seus colegas.",
            vdpNameEntry_inputName: "Como você quer ser chamado?",
            vdpNameEntry_inputNamePlaceholder: "Insira o nome pelo qual deseja ser chamado...",
            vdpNameEntry_inputDepartment: "Em qual departamento você está?",
            vdpNameEntry_inputEmail: "Qual é o seu endereço de e-mail?",
            vdpNameEntry_inputTeam: "Em qual equipe você está?",
            vdpNameEntry_identifiersName: "Insira o nome",
            vdpNameEntry_identifiersDuplicateName:
                "Parece que já existe um {0} conectado hoje. Gostaria de tentar inserir um nome diferente para que possamos diferenciar você? Considere adicionar uma inicial para o sobrenome ou inserir um apelido.",
            vdpNameEntry_identifiersDepartment: "Insira o nome do departamento",
            vdpNameEntry_identifiersEmail: "Insira o e-mail",
            vdpNameEntry_identifiersInvalidEmailFormat: "Formato de e-mail inválido",
            vdpNameEntry_identifiersTeam: "Insira o nome da equipe",
            vdpNameEntry_welcomeHeader: "Prazer em conhecê-lo, {0}!",
            vdpNameEntry_welcomeAdvance: "Conheça sua equipe",
            vdpNameEntry_claimFacilitatorTitle: "Reivindique a função de facilitador",
            vdpNameEntry_facilitatorClaimed: "Função de facilitador reivindicada",
            vdpNameEntry_claimFacilitatorButton: "Reivindicar facilitador",
            vdpNameEntry_claimFacilitatorModalCancel: "Cancelar",
            vdpNameEntry_claimFacilitatorModalParagraphOne:
                "Se você for o facilitador desta sessão, selecione Reivindicar facilitador abaixo.",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "Como facilitador, você terá controle das primeiras páginas para começarmos. Em seguida, o sistema selecionará automaticamente um novo navegador.",
            vdpNameEntry_claimFacilitatorModalParagraphThree:
                "Se você foi convidado para esta sessão como participante, selecione Cancelar.",
            vdpNameEntry_numParticipantsInSession: "{0} participantes estão esperando...",
            vdpNameEntry_oneParticipantsInSession: "1 participante está esperando...",
            vdpNameEntry_save: "Salvar",
            vdpNameEntry_continue: "Continuar",
            vdpNameEntry_thanks: "Obrigada, {0}. Começaremos em breve!",
            vdpNameEntry_intro: "Insira seu nome e aguarde a entrada de outras pessoas. Começaremos em breve!",
            vdpNameEntry_introStarted: "Insira seu nome e junte-se aos seus colegas.",
            vdpNameEntry_navDirections: "Espere a equipe entrar.",
            vdpValidationErrorMinLength: "Insira pelo menos {0} caracteres.",
            vdpValidationErrorMaxLength: "Insira no máximo {0} caracteres.",
            toolbar_stepOf: "Seção {0}, Página {1} - {2} ({3})",
            toolbar_intro: "Etapa {0} da seção {1}",
            toolbar_welcome: "Sequência de boas-vindas",
            toolbar_exit: "Sair da sequência",
            toolbar_reloadContent: "Recarregar conteúdo",
            toolbar_commentCreationButton: "Comentário ({0})",
            toolbar_buildComments: "Comentários de construção",
            toolbar_commentListButton: "Visualizar comentários ({0})",
            toolbar_commentCreationTitle: "Adicionar comentário de construção",
            toolbar_commentCreationDescription:
                "Os comentários serão salvos e enviados para o Construtor de experiência.  Insira seu nome com o comentário.",
            toolbar_commentCreationCancelButton: "Cancelar",
            toolbar_commentCreationSubmitButton: "Publicar comentário",
            toolbar_commentCreationSubmitButtonDisabled: "Publicando comentário...",
            toolbar_commentCreationPlaceholder: "Inserir comentário...",
            toolbar_previewMode: "Modo de visualização:",
            toolbar_aboutPreviewMode: "Sobre o modo de visualização",
            toolbar_focusAreaSelector: "Seletor de área de foco",
            toolbar_hotspotSelector: "Seletor de ponto em destaque",
            toolbar_previewModeModalTitle: "Modo de visualização",
            toolbar_previewModeModalFirstIntroduction:
                "Você está vendo esta experiência no modo de visualização. Esta visualização foi projetada apenas para revisão interna da Root e fornece acesso às ferramentas de criação.",
            toolbar_previewModeModalSecondIntroduction:
                "Essas opções só estão disponíveis no modo de visualização e não ficarão visíveis para o cliente ou os participantes.",
            toolbar_previewModeModalReloadContent: "Recarregar conteúdo",
            toolbar_previewModeModalReloadContentDescription:
                "Use este botão para atualizar a experiência com as últimas atualizações do Contentful sem precisar recarregar a sessão.",
            toolbar_previewModeModalComments: "Comentários",
            toolbar_previewModeModalCommentsDescription:
                "Adicione um comentário para fornecer feedback ou anote quaisquer alterações que precisem ser feitas. Os comentários serão capturados no Contentful para a equipe de construção.",
            toolbar_previewModeModalMapCoordinates: "Coordenadas do mapa",
            toolbar_previewModeModalMapCoordinatesDescription: "Copie as coordenadas do mapa.",
            toolbar_previewModeModalHotspotSelector: "Seletor de ponto em destaque",
            toolbar_previewModeModalHotspotSelectorDescription:
                "Visualize as localizações de ponto em destaque e copie as coordenadas para uso no Contentful.",
            coordinatePreview_coordinatesSelectorTitle: "Seletor de coordenadas",
            coordinatePreview_hotspotMode: "Modo de ponto em destaque",
            coordinatePreview_hotspotModeCoordinatesCopiedToast:
                "As coordenadas de ponto em destaque foram copiadas para a sua área de transferência.",
            coordinatePreview_focusAreaMode: "Modo de área de foco",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast:
                "As coordenadas da área de foco foram copiadas para sua área de transferência.",
            coordinatePreview_directionsTitle: "Coordena as direções do seletor",
            coordinatePreview_hotspotDirectionsLineOne: "Clique no mapa para criar pontos em destaque.",
            coordinatePreview_hotspotDirectionsLineTwo:
                "Clique no botão “Copiar” em cada ponto em destaque para copiar as coordenadas para o Contentful.",
            coordinatePreview_focusAreaDirectionsLineOne: "Clique e arraste para desenhar um retângulo no mapa.",
            coordinatePreview_focusAreaDirectionsLineTwo:
                "Coloque o retângulo sobre a área a ser visualizada para uma etapa.",
            coordinatePreview_focusAreaDirectionsLineThree:
                "Clique no botão “Copiar” em cada área de foco para copiar coordenadas para o Contentful.",
            coordinatePreview_reset: "Redefinir",
            coordinatePreview_done: "Concluído",
            navigatorDefaultInstructions_selectNextToContinue: "Clique em ***Avançar*** para continuar.",
            navigatorDefaultInstructions_progressCheckLineOne: "Chegamos ao final da seção {0}.",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace:
                "Estamos no ritmo certo. Vamos continuar com o bom trabalho!",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "Estamos à frente do ritmo. Vamos tentar não apressar o material restante.",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace:
                "Estamos atrasados. Precisaremos nos mover mais rápido para concluir a sessão no prazo.",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav:
                "Na próxima página, apresentaremos um novo navegador para a seção {0}.",
            maintenanceWindow_heading: "A manutenção está programada para {0}, {1} de {2} a {3} {4}.",
            maintenanceWindow_body: "O aplicativo pode não estar disponível durante esse período.",
            maintenanceError_overlap:
                "As datas que você escolheu entram em conflito com um período de manutenção programada em {0}. A manutenção ocorre toda semana das {1} às {2}. Certifique-se de que as datas de início e expiração não se sobreponham a um período de manutenção.",
            progressCheck_sectionTitle: "Seção {0} de {1}",
            progressCheck_paceCheckTitle: "Verificação do ritmo da sessão",
            progressCheck_paceMessageOnPace: "Continue com o bom trabalho!",
            progressCheck_paceMessageAheadOfPace: "Tente não apressar o material restante.",
            progressCheck_paceMessageBehindPace: "Você precisará se mover mais rápido para concluir a sessão no prazo.",
            progressCheck_navigatorChangeTitle: "Alteração do navegador",
            progressCheck_navigatorChangeMessage:
                "Obrigado por liderar, {0}. Um novo navegador será atribuído na próxima página.",
            vdpIntro_title: "Introdução",
            vdpIntro_subtitle: "Alguns detalhes antes de começarmos...",
            vdpIntro_joinTheTeam: "Junte-se à equipe",
            vdpIntro_facilitatorRole: "Função do facilitador",
            vdpIntro_navigatorRole: "Função do navegador",
            vdpIntro_participantRole: "Função do participante",
            vdpIntro_iceBreaker: "Conheça a equipe",
            vdpIntro_exploreTheVisual: "Os recursos visuais",
            vdpIntro_skipShort: "Pular",
            vdpIntro_skipLong: "Ignorar introdução",
            vdpIntro_facilitatorInstructionsOne:
                "Bem-vindo(a)! A sessão de hoje é diferente de outros cursos ou treinamentos que você possa ter participado.",
            vdpIntro_facilitatorInstructionsTwo:
                "Não há professor ou instrutor, vocês aprenderão uns com os outros e com as informações na tela.",
            vdpIntro_facilitatorInstructionsThree:
                "Minha função como facilitador é manter a conversa no caminho certo e dentro do prazo.",
            vdpIntro_navigatorInstructionsOne:
                "Nas próximas telas, também atuarei como nosso navegador. A função do navegador é ler o texto de introdução em cada tela, selecionar no botão ***Avançar*** para nos mover pela experiência e seguir outras instruções conforme solicitado.",
            vdpIntro_navigatorInstructionsTwo:
                "A função de navegador será atribuída aleatoriamente e mudará a cada poucas telas.",
            vdpIntro_participantInstructionsOne:
                "Vocês são todos participantes! Sua função é participar ativamente compartilhando suas perspectivas com o grupo. Vocês ajudarão a sessão a correr sem problemas, fazendo revezamento, respeitando as ideias e opiniões dos outros e mantendo a conversa no caminho certo.",
            vdpIntro_participantInstructionsTwo:
                "Você pode ver quem está na sessão e quem ocupa cada função usando o botão ***Equipe*** no canto inferior esquerdo.",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "Em seguida, cada um de nós terá a chance de ver como é a função do navegador à medida que nos apresentamos.",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "Quando você vir o banner ***Você é o navegador*** acima do texto de Introdução, é sua vez de ler e seguir as instruções. Alguém tem alguma dúvida?",
            vdpIntro_iceBreakerInstructionsOne: "Olá, me chamo {0}.",
            vdpIntro_iceBreakerInstructionsDefault: "Um fato sobre mim é _______.",
            vdpIntro_mapInstructionsOne:
                "Agora que todos nós nos conhecemos, vamos aprender mais sobre nossa sessão hoje.",
            vdpIntro_mapInstructionsTwo:
                "Nossa conversa se concentrará em uma imagem chamada *Learning Map*® visual. É uma metáfora ilustrada que representa aspectos do nosso negócio que exploraremos juntos. ",
            vdpIntro_iceBreakerIntroDirections: "Responda quaisquer perguntas.",
            vdpEnd_title: "Obrigada.",
            vdpEnd_body:
                "Concluímos a nossa experiência. Obrigado por participar! Esperamos que a discussão de hoje tenha sido informativa para você.",
            vdpEnd_surveyRequest:
                "Reserve alguns minutos para compartilhar suas ideias e reações às informações que discutimos. Seu feedback é importante para o nosso sucesso.",
            vdpEnd_surveyThankYou: "Obrigado por sua resposta.",
            vdpEnd_sessionComplete: "A sessão está concluída.",
            vdpEnd_closeWindow: "Você pode fechar esta janela.",
            privacyBanner_message:
                "Usamos cookies para habilitar algumas funcionalidades do site e fornecer uma experiência mais relevante para você. Para obter mais informações, consulte nossa [Política de cookies]({0}) e [Declaração de privacidade]({1}).",
            privacyBanner_dismiss: "Dispensar",
        },
    },
    "zh-CN": {
        name: "Chinese",
        direction: "LTR",
        strings: {
            vdpActivity_notFound: "无法找到活动 {0}",
            vdpActivity_closeModal: "关闭",
            vdpActivity_correctExclamation: "正确！",
            vdpActivity_correct: "正确",
            vdpActivity_correctAnswerBadge: "正确答案",
            vdpActivity_partiallyCorrect: "部分正确！",
            vdpActivity_incorrect: "不正确",
            vdpActivity_notCorrect: "不正确",
            vdpActivity_continue: "继续",
            vdpActivity_checkAnswer: "检查答案",
            vdpActivity_ready: "已准备好。",
            vdpActivity_cardsPlaced: "所有卡片均已放置。",
            vdpActivity_cardsCorrectHeader: "成功！",
            vdpActivity_cardsCorrect: "所有卡片已成功放置。",
            vdpActivity_cardsCorrectContinue: "继续下一项活动。",
            vdpActivity_cardsPartiallyCorrectHeader: "不完全正确。",
            vdpActivity_cardsPartiallyCorrect: "查看错误的答案，并尝试将其移至新位置。",
            vdpActivity_cardsCheckAnswers: "检查您的答案",
            vdpActivity_textEntryPlaceholder: "您的回答",
            vdpActivity_textEntryNavigatorPrompt: "导航员{0}将在下面输入文本",
            vdpActivity_textEntryPrompt: "在下面输入文本：",
            vdpActivity_textEntrySubmit: "提交",
            vdpActivity_selectOne: "选择一项",
            vdpActivity_selectOneOrMany: "选择一项或多项",
            vdpActivity_defaultCurrentParticipantName: "我",
            vdpActivity_hotspotTooltip: "点击热点了解更多信息。",
            vdpClipboard_copied: "已复制到剪贴板！",
            vdpClipboard_copy: "复制到剪贴板",
            vdpClipboard_error: "无法复制到剪贴板",
            vdpHud_hide: "隐藏",
            vdpHud_show: "显示",
            vdpHud_changeNavigatorTitle: "更换新的导航员。",
            vdpHud_changeNavigatorText1:
                '您已点击"更换导航员"。这将随机选择另一个导航员，并将短暂禁用此页面上的所有交互。',
            vdpHud_changeNavigatorText2: "是否确定要更换导航员？",
            vdpHud_close: "关闭",
            vdpHud_accept: "更换导航员",
            vdpHud_back: "返回",
            vdpHud_next: "下一个",
            vdpHud_endExperience: "结束体验",
            vdpHud_defaultExitMsg:
                "我们已经完成了体验。感谢您的参与！我们希望您觉得今天的讨论很有启发。您可以关闭此窗口。",
            vdpHud_navigatorReminder: "您是导航员。请阅读以下内容：",
            vdpHud_timerNoTimeSpecified: "未为章节指定时间",
            vdpHud_timerRemaining: "部分中的剩余",
            vdpHud_timerLessThanOneMinute: "还剩不到一分钟",
            vdpHud_timerOver: "超过",
            vdpHud_roleNone: "未分配",
            vdpHud_infoTableSection: "章节",
            vdpHud_infoTableRecommended: "推荐",
            vdpHud_infoTableActual: "实际",
            vdpHud_infoTableTotal: "总计",
            vdpHud_infoTableRemaining: "剩余",
            vdpHud_infoTeamButton: "团队",
            vdpHud_infoTeamParticipantJoining: "用户加入……",
            vdpHud_navigator: "导航员",
            vdpHud_facilitator: "讲师",
            vdpHud_facilitatorMenuMakeNavigator: "创建导航员",
            vdpHud_facilitatorMenuRemoveFacilitator: "删除讲师角色",
            vdpHud_removeFacilitatorWarningConfirm: "确认",
            vdpHud_removeFacilitatorWarningCancel: "取消",
            vdpHud_removeFacilitatorWarningMessage: "即将离开您的讲师角色。确定要继续吗？",
            vdpHud_progressOnPaceMessage: "步调一致",
            vdpHud_progressAheadOfPaceMessage: "领先一步",
            vdpHud_progressBehindPaceMessage: "落后一步",
            vdpHud_progressTimeRemaining: "{0}部分中的剩余",
            vdpHud_progressStepsInSection: "第 {0} 页，共 {1} 页",
            vdpHud_zoomControlsTooltip: "地图缩放控件",
            sidebar_scheduleButton: "时间表",
            sidebar_teamAssignNavigator: "分配导航员",
            sidebar_teamChooseRandomly: "随机选择",
            sidebar_teamChooseHelperText: "随机选择符合资格的学员作为导航员。在每个人都轮到之前，不会再次选择学员。",
            sidebar_editNameMenuItem: "编辑名字",
            sidebar_editNameSave: "保存更换",
            sidebar_nameEditPlaceholder: "输入想叫的名字……",
            sidebar_manageRolesMenuLabel: "管理角色",
            sidebar_navigatorDescription:
                "大声朗读问题、提示和说明。通过选择“下一个”并遵循每个屏幕上注明的其他指示，推进小组完成体验。",
            sidebar_facilitatorDescription: "在整个课程期间作为指导，确保小组进行充分地讨论且对话没有偏离主题。",
            sidebar_previousButton: "上一个",
            sidebar_nextButton: "下一个",
            sidebar_lockTooltip: "只有导航员可以更换页面。",
            sidebar_navigatorBadge: "您是导航员",
            sidebar_facilitatorBadge: "您是讲师",
            sidebar_introductionLabel: "介绍",
            sidebar_introductionInstruction: "向小组大声朗读：",
            sidebar_directionsLabel: "指示",
            sidebar_nonNavigatorDirectionsLabel: "{0} 执行以下操作：",
            sidebar_directionsInstruction: "执行以下操作：",
            sidebar_facilitationNotesLabel: "引导笔记",
            genUrl_copyLink: "复制链接",
            genUrl_sessionLinkDetails: "刷新页面时，这些课程链接将消失。离开此页面之前，请务必下载或复制这些链接。",
            genUrl_exportAsCsv: "作为 CSV 文件导出",
            genUrl_copyTable: "复制表格",
            genUrl_linkExpiration: "链接到期",
            genUrl_link: "链接",
            genUrl_title: "{0}课程链接生成器",
            genUrl_experience: "体验：{0}",
            genUrl_description:
                "此门户允许您为完成这一体验而生成课程链接。如果要生成课程链接，需要输入要生成的课程数量，选择课程的截止日期，然后点击“生成”。这些课程生成后，将其复制并粘贴到您最喜欢的文本编辑器或电子表格程序中。刷新页面后，所有课程都将消失。",
            genUrl_numSessions: "课程数量：",
            genUrl_sessionsExpirationLabel: "课程链接截止于：",
            genUrl_sessionsOpenAtLabel: "课程链接生效于：",
            genUrl_numSessionsInputLabel: "课程数量",
            genUrl_sessionLanguageLabel: "课程语言",
            genUrl_numSessionsInputTypeError: "必须为数字",
            genUrl_closesOnError: "选择未来的日期",
            genUrl_experienceClosesOnError: "选择早于 {0} 的日期",
            genUrl_notifSuccessSingle: "已成功生成 {0} 链接",
            genUrl_notifSuccessMultiple: "已成功生成 {0} 链接",
            genUrl_notifErrorGenerating: "无法生成课程。请重试或联系管理员。",
            genUrl_notifErrorProcessing: "无法处理。联系管理员。",
            genUrl_sessionsExpireToday: "课程将于今天到期",
            genUrl_sessionsExpireInOneDay: "课程将在 {0} 天后到期",
            genUrl_sessionsExpireInDays: "课程将在 {0} 天后过期",
            genUrl_sessionsOpenToday: "课程将于今天开始",
            genUrl_sessionsOpenOneDay: "课程将在 {0} 天后开始",
            genUrl_sessionsOpenDays: "课程将在 {0} 天后开始",
            genUrl_generate: "生成课程",
            genUrl_schedulingLinkExpired: "计划链接已过期。",
            genUrl_error: "错误",
            genUrl_dataCollectionLabel: "数据收集",
            genUrl_emailContact: "电子邮件联系人",
            vdpLoading_waitForContent: "正在加载您的内容，请稍候……",
            vdpNotFound_title: "虚拟对话平台",
            vdpNotFound_message: "找不到您要查找的页面。",
            vdpRenderer_refresh: "刷新",
            vdpRenderer_sessionDisconnectedHeader: "您已断开连接。",
            vpdRenderer_inactiveDisconnectedBody: "由于处于不活动状态，您已断开连接。点击下面的按钮尝试重新连接。",
            vdpRenderer_inactiveHeader: "您处于不活动状态。",
            vdpRenderer_inactiveBody:
                "您已长时间处于不活动状态。点击下面的按钮将自己标记为活动状态，否则如果计时器的时间用完，会将您从课程中删除。",
            vdpRenderer_setSelfActiveButton: "将自己设置为活动状态",
            vdpRenderer_sessionDisconnected: "正在尝试重新连接",
            vdpRenderer_sessionDisconnectedReload: "请重新加载",
            vdpRenderer_endOfSession: "课程结束",
            vdpRenderer_invalidMapUrl: "提供的地图 URL 无效。请联系 Root 寻求帮助。",
            vdpRenderer_unexpectedError: "发生意外系统错误。以下是它的技术细节：",
            vdpRenderer_unexpectedErrorMsg: "发生未知课程状态的意外错误 {0}",
            vdpRenderer_defaultEndingMessage: "现在可以关闭窗口并断开与音频的连接。",
            vdpErrorModal_title: "无法加载页面",
            vdpErrorModal_message: "出了点问题！如需帮助，请联系我们：vmap.help@rootinc.com",
            vdpErrorModal_body: "可通过重新加载此页面或点击下面的选项来解决错误：",
            vdpErrorModal_button: "重新加载这项体验。",
            vdpNameEntry_welcomeText: "欢迎",
            vdpNameEntry_defaultWelcomeMessage:
                "本虚拟对话课程完全是交互式的。准备探索信息，并与同伴分享您的想法和意见。",
            vdpNameEntry_inputName: "您想叫什么？",
            vdpNameEntry_inputNamePlaceholder: "输入想叫的名字……",
            vdpNameEntry_inputDepartment: "您在哪个部门？",
            vdpNameEntry_inputEmail: "您的电子邮箱地址是什么？",
            vdpNameEntry_inputTeam: "您属于哪个团队？",
            vdpNameEntry_identifiersName: "请输入名字",
            vdpNameEntry_identifiersDuplicateName:
                "看起来今天已经有 {0} 连接了。是否想尝试输入其他名字，以便我们能够区分您？ 考虑为姓氏添加首字母或输入昵称。",
            vdpNameEntry_identifiersDepartment: "请输入部门名称",
            vdpNameEntry_identifiersEmail: "请输入电子邮箱",
            vdpNameEntry_identifiersInvalidEmailFormat: "电子邮箱格式无效",
            vdpNameEntry_identifiersTeam: "请输入团队名称",
            vdpNameEntry_welcomeHeader: "很高兴见到您，{0}！",
            vdpNameEntry_welcomeAdvance: "认识您的团队",
            vdpNameEntry_claimFacilitatorTitle: "要求担任讲师角色",
            vdpNameEntry_facilitatorClaimed: "讲师角色已要求",
            vdpNameEntry_claimFacilitatorButton: "要求担任讲师",
            vdpNameEntry_claimFacilitatorModalCancel: "取消",
            vdpNameEntry_claimFacilitatorModalParagraphOne: "如果您是本课程的讲师，请选择下面的“要求担任讲师”。",
            vdpNameEntry_claimFacilitatorModalParagraphTwo:
                "作为讲师，您将能够控制前几页以帮助我们开始课程。然后，系统将自动选择新的导航员。",
            vdpNameEntry_claimFacilitatorModalParagraphThree: "如果您作为学员受邀参加此课程，请选择取消。",
            vdpNameEntry_numParticipantsInSession: "{0} 名学员正在等待……",
            vdpNameEntry_oneParticipantsInSession: "1 名学员正在等待……",
            vdpNameEntry_save: "保存",
            vdpNameEntry_continue: "继续",
            vdpNameEntry_thanks: "谢谢，{0}。我们很快就会开始！",
            vdpNameEntry_intro: "输入您的姓名，等待其他人加入。我们很快就会开始！",
            vdpNameEntry_introStarted: "输入您的姓名并加入您的同伴。",
            vdpNameEntry_navDirections: "等待团队加入。",
            vdpValidationErrorMinLength: "请至少输入 {0} 个字符。",
            vdpValidationErrorMaxLength: "请最多输入 {0} 个字符。",
            toolbar_stepOf: "第 {0} 节，第 {1} - {2} ({3}) 页",
            toolbar_intro: "第 {1} 部分的第 {0} 步",
            toolbar_welcome: "欢迎顺序",
            toolbar_exit: "退出序列",
            toolbar_reloadContent: "重新加载内容",
            toolbar_commentCreationButton: "评论 （{0}）",
            toolbar_buildComments: "创建评论",
            toolbar_commentListButton: "查看评论 （{0}）",
            toolbar_commentCreationTitle: "添加构建评论",
            toolbar_commentCreationDescription: "评论将被保存并发送给“体验构建者”。请输入您的名字和评论。",
            toolbar_commentCreationCancelButton: "取消",
            toolbar_commentCreationSubmitButton: "发布评论",
            toolbar_commentCreationSubmitButtonDisabled: "正在发布评论……",
            toolbar_commentCreationPlaceholder: "输入评论……",
            toolbar_previewMode: "预览模式：",
            toolbar_aboutPreviewMode: "关于预览模式",
            toolbar_focusAreaSelector: "焦点领域选择器",
            toolbar_hotspotSelector: "热点选择器",
            toolbar_previewModeModalTitle: "预览模式",
            toolbar_previewModeModalFirstIntroduction:
                "您正在预览模式下查看此体验。此视图仅用于根内部审核，并提供对编写工具的访问权限。",
            toolbar_previewModeModalSecondIntroduction: "这些选项仅在预览模式下可用，且将对客户或学员不可见。",
            toolbar_previewModeModalReloadContent: "重新加载内容",
            toolbar_previewModeModalReloadContentDescription:
                "使用此按钮即可使用 Contentful 的最新更新来刷新体验，无需重新加载会话。",
            toolbar_previewModeModalComments: "评论",
            toolbar_previewModeModalCommentsDescription:
                "添加评论以提供反馈或记录所需做出的任何更改。将在 Contentful 中为构建团队收集评论。",
            toolbar_previewModeModalMapCoordinates: "地图坐标",
            toolbar_previewModeModalMapCoordinatesDescription: "复制地图的坐标。",
            toolbar_previewModeModalHotspotSelector: "热点选择器",
            toolbar_previewModeModalHotspotSelectorDescription: "预览热点位置并复制坐标，以便在 Contentful 中使用。",
            coordinatePreview_coordinatesSelectorTitle: "坐标选择器",
            coordinatePreview_hotspotMode: "热点模式",
            coordinatePreview_hotspotModeCoordinatesCopiedToast: "热点坐标已复制到剪贴板。",
            coordinatePreview_focusAreaMode: "焦点领域模式",
            coordinatePreview_focusAreaModeCoordinatesCopiedToast: "焦点领域坐标已复制到剪贴板。",
            coordinatePreview_directionsTitle: "坐标选择器指示",
            coordinatePreview_hotspotDirectionsLineOne: "点击地图以创建热点。",
            coordinatePreview_hotspotDirectionsLineTwo: "点击每个热点上的复制按钮，将坐标复制到 Contentful。",
            coordinatePreview_focusAreaDirectionsLineOne: "点击并拖动以在地图上绘制矩形。",
            coordinatePreview_focusAreaDirectionsLineTwo: "将矩形放在要查看的某个步骤区域上。",
            coordinatePreview_focusAreaDirectionsLineThree: "点击每个焦点领域上的复制按钮，将坐标复制到 Contentful。",
            coordinatePreview_reset: "重置",
            coordinatePreview_done: "已完成",
            navigatorDefaultInstructions_selectNextToContinue: "点击“下一步”继续。",
            navigatorDefaultInstructions_progressCheckLineOne: "我们已完成第 {0} 节。",
            navigatorDefaultInstructions_progressCheckLineTwoOnPace: "我们进度顺利。让我们再接再厉！",
            navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace:
                "我们已保持领先。让我们尽量不要匆忙浏览剩余的材料。",
            navigatorDefaultInstructions_progressCheckLineTwoBehindPace: "我们落后了。我们需要加快速度，按时完成课程。",
            navigatorDefaultInstructions_progressCheckLineThreeNewNav: "在下一页，我们将为第 {0} 节介绍一名新导航员。",
            maintenanceWindow_heading: "维护的计划时间是 {0}，{1}，{2}-{3} {4}。",
            maintenanceWindow_body: "在此期间，应用程序可能不可用。",
            maintenanceError_overlap:
                "您选择的日期与 {0} 的计划维护时段冲突。从 {1} 到 {2}，每周都会进行维护。请确保开始日期和到期日期与维护窗口期不重叠。",
            progressCheck_sectionTitle: "第 {0} 节，共 {1} 节 ",
            progressCheck_paceCheckTitle: "课程速度检查",
            progressCheck_paceMessageOnPace: "再接再厉！",
            progressCheck_paceMessageAheadOfPace: "尽量避免匆忙浏览剩余的材料。",
            progressCheck_paceMessageBehindPace: "您需要加快速度，按时完成课程。",
            progressCheck_navigatorChangeTitle: "导航员变更",
            progressCheck_navigatorChangeMessage: "感谢您的领导，{0}。下一页将分配一名新导航员。",
            vdpIntro_title: "介绍",
            vdpIntro_subtitle: "在我们开始之前，有几件事要做……",
            vdpIntro_joinTheTeam: "加入团队",
            vdpIntro_facilitatorRole: "讲师角色",
            vdpIntro_navigatorRole: "导航员角色",
            vdpIntro_participantRole: "学员角色",
            vdpIntro_iceBreaker: "认识团队",
            vdpIntro_exploreTheVisual: "图示",
            vdpIntro_skipShort: "跳过",
            vdpIntro_skipLong: "跳过简介",
            vdpIntro_facilitatorInstructionsOne: "欢迎！今天的课程不同于大家可能参加过的其他课程或培训。",
            vdpIntro_facilitatorInstructionsTwo: "这次没有老师或培训师——大家将从彼此和屏幕上的信息中学习。",
            vdpIntro_facilitatorInstructionsThree: "我作为讲师的职责是让课程保持正轨和准时。",
            vdpIntro_navigatorInstructionsOne:
                "在接下来的几个屏幕中，我还将担任我们的导航员。导航员的职责是阅读每个屏幕上的介绍文本，选择“下一步”按钮带领我们完成体验，并按照提示遵循其他指示。",
            vdpIntro_navigatorInstructionsTwo: "导航员角色将随机分配，并将每隔几个屏幕更改一次。",
            vdpIntro_participantInstructionsOne:
                "大家都是学员！大家的职责是通过与小组分享自己的观点来积极参与。通过轮流发言、尊重他人的想法和观点，以及保持对话正常，大家将帮助课程顺利进行。",
            vdpIntro_participantInstructionsTwo: "您可以使用左下角的“团队”按钮，查看谁在课程中，谁在担任各个角色。",
            vdpIntro_iceBreakerIntroInstructionsOne:
                "接下来，我们每个人都有机会在自我介绍时了解导航员的角色是什么样子。",
            vdpIntro_iceBreakerIntroInstructionsTwo:
                "当您看到简介文本上方的“您是导航员”横幅时，即轮到您来阅读并遵循指示了。是否有人有任何问题？",
            vdpIntro_iceBreakerInstructionsOne: "嗨，我是{0}。",
            vdpIntro_iceBreakerInstructionsDefault: "关于我的一个事实是_______。",
            vdpIntro_mapInstructionsOne: "现在我们都见过面了，我们今天来详细了解一下我们的课程。",
            vdpIntro_mapInstructionsTwo:
                "我们的对话将以一张名为 *Learning Map*® Visual 的图像为中心。这是一个图示的隐喻，代表了我们将一起探索的关于我们业务的各个方面。",
            vdpIntro_iceBreakerIntroDirections: "回答任何问题。",
            vdpEnd_title: "谢谢。",
            vdpEnd_body: "我们已经完成了体验。感谢您的参与！我们希望您觉得今天的讨论很有启发。",
            vdpEnd_surveyRequest: "请花几分钟时间分享您对我们所讨论信息的想法和反应。您的反馈对我们的成功至关重要。",
            vdpEnd_surveyThankYou: "感谢您的回复。",
            vdpEnd_sessionComplete: "课程已结束。",
            vdpEnd_closeWindow: "您可以关闭此窗口。",
            privacyBanner_message:
                "我们使用 Cookie 来启用某些网站功能，以便为您提供更相关的体验。有关更多信息，请查看我们的 [Cookie 政策]（{0}）和 [隐私声明]（{1}）。",
            privacyBanner_dismiss: "忽略",
        },
    },
};

export default APP_STRING as any;
