import { updateSessionState } from "modules/utils";
import { MapBoundsChanged } from "../actions-types";
import { ApplicationState } from "../application-state";

export default (state: ApplicationState, payload: MapBoundsChanged): ApplicationState => {
    return updateSessionState(state, (session) => {
        if (session && session.state === "READY") {
            return {
                ...session,
                mapBounds: payload.bounds,
            };
        }

        return session;
    });
};
