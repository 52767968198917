import React from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

type Props = {
    eventKey: string;
    children?: React.ReactNode;
};

const InfoToggle: React.FC<Props> = ({ children, eventKey }: Props) => (
    <span onClick={useAccordionButton(eventKey)}>{children}</span>
);

export default InfoToggle;
