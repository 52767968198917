import { getUnixTime } from "date-fns";
import {
    clearPrevAndAddNewRoleAssignment,
    incrementStepAddress,
    isValidStepChange,
    updateIntroStepState,
} from "modules/utils";
import { Role } from "../content-types";
import { currentFacilitatorSelector } from "../selectors/participants/currentFacilitatorSelector";
import { NavigateIceBreaker } from "../session-events";
import { SessionState } from "../types";
import { isIntroStepAddress, isStepAddress, stepAddressesAreEqual } from "../utils";
import applyStepOptions from "./apply-step-options";
import { EventReducerDefinition } from "./types";

// needs to increment step and assign navigator according to index
const navigateIceBreaker: EventReducerDefinition<NavigateIceBreaker> = {
    predictable: true,
    checkIfApplicable: (state, payload, resolution) => {
        return (
            isValidStepChange(state, payload) &&
            state.isIntroStep &&
            ((isIntroStepAddress(payload.to) && payload.to.introStepKey === "iceBreaker") ||
                (isIntroStepAddress(payload.from) && payload.from.introStepKey === "iceBreaker"))
        );
    },
    reducer: (state: SessionState, payload: NavigateIceBreaker) => {
        const stepExistsAfterCurrentStep = incrementStepAddress(state);
        if (!stepExistsAfterCurrentStep) {
            return applyStepOptions(state, undefined, null);
        }
        const movingForwards = stepAddressesAreEqual(payload.to, stepExistsAfterCurrentStep);
        let stateBeforeOptions = { ...state };
        const currentFacilitator = currentFacilitatorSelector(state);
        const currentStepIsLastIceBreakerStep =
            isIntroStepAddress(payload.from) &&
            movingForwards &&
            (isStepAddress(payload.to) || (isIntroStepAddress(payload.to) && payload.to.introStepKey !== "iceBreaker"));
        const nextStepIsIceBreakerIntro =
            isIntroStepAddress(payload.to) &&
            payload.to.introStepKey === "iceBreaker" &&
            payload.to.iceBreakerNavPoolIndex === "intro";

        let participants;
        let chosenParticipantId;
        const currentTime = getUnixTime(new Date());

        if (isIntroStepAddress(payload.from)) {
            //intro step time calculation
            stateBeforeOptions = {
                ...stateBeforeOptions,
                introStepStates: updateIntroStepState(
                    stateBeforeOptions.introStepStates,
                    payload.from.introStepKey,
                    (introStepState) => ({
                        ...introStepState,
                        timeElapsed: introStepState.timeElapsed + currentTime - state.timeStepStarted,
                    }),
                ),
                timeStepStarted: currentTime,
            };
        }

        if ((currentStepIsLastIceBreakerStep || nextStepIsIceBreakerIntro) && currentFacilitator) {
            ({ participants: participants, chosenParticipantId: chosenParticipantId } =
                clearPrevAndAddNewRoleAssignment(state.participants, {
                    roleId: Role.Navigator,
                    participantId: currentFacilitator.id,
                }));
        } else if (
            isIntroStepAddress(payload.to) &&
            payload.to.iceBreakerNavPoolIndex !== "complete" &&
            payload.to.iceBreakerNavPoolIndex !== "intro"
        ) {
            ({ participants: participants, chosenParticipantId: chosenParticipantId } =
                clearPrevAndAddNewRoleAssignment(state.participants, {
                    roleId: Role.Navigator,
                    participantId: state.iceBreakerNavigatorPool[payload.to.iceBreakerNavPoolIndex],
                }));
        }
        if (!chosenParticipantId || !participants) {
            return applyStepOptions(stateBeforeOptions, undefined, null);
        }

        if (movingForwards) {
            if (currentStepIsLastIceBreakerStep) {
                if (isIntroStepAddress(payload.to)) {
                    stateBeforeOptions = {
                        ...stateBeforeOptions,
                        currentIntroStep: { introStepKey: payload.to.introStepKey, iceBreakerNavPoolIndex: "complete" },
                        isIntroStep: true,
                        participants: participants,
                    };
                } else if (isIntroStepAddress(payload.from)) {
                    stateBeforeOptions = {
                        ...stateBeforeOptions,
                        timeStepStarted: getUnixTime(new Date()),
                        currentIntroStep: {
                            introStepKey: payload.from.introStepKey,
                            iceBreakerNavPoolIndex: "complete",
                        },
                        currentStep: payload.to,
                        isIntroStep: false,
                        participants: participants,
                    };
                }

                return applyStepOptions(stateBeforeOptions, undefined, null);
            }
        }

        if (isIntroStepAddress(payload.to)) {
            stateBeforeOptions = {
                ...stateBeforeOptions,
                currentIntroStep: payload.to,
                isIntroStep: true,
                participants: participants,
            };
        }
        return applyStepOptions(stateBeforeOptions, undefined, null);
    },
};
export default navigateIceBreaker;
