import { updateSessionState } from "modules/utils";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState): ApplicationState => {
    return updateSessionState(state, (session: SessionStateContainer) => {
        if (session.state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
            return {
                ...session,
                reincarnationState: "requestSent",
            };
        } else {
            throw new Error(`Received REINCARNATION_REQUESTED in invalid state ${session.state}`);
        }
    });
};
