import { SessionState } from "modules/shared/types";
import { ParticipantReadyEvent } from "modules/shared/session-events";
import { updateParticipant, addParticipantHistory } from "modules/utils";

export const participantReady = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: ParticipantReadyEvent): boolean => {
        const participant = state.participants.find((participant) => participant.id === payload.participantId);

        return !!participant && !participant.ready;
    },
    reducer: (state: SessionState, payload: ParticipantReadyEvent): SessionState => {
        const participant = state.participants.find((participant) => participant.id === payload.participantId);
        if (!participant) {
            throw new Error("Couldn't find participant with id: " + payload.participantId);
        }

        return {
            ...state,
            poolOfEligibleParticipantsForNavigatorRole: [
                ...state.poolOfEligibleParticipantsForNavigatorRole,
                participant.id,
            ],
            participants: updateParticipant(state.participants, payload.participantId, (p) => ({ ...p, ready: true })),
            participantsHistory: addParticipantHistory(state.participantsHistory, payload.participantId, {
                stepWhenJoined: state.currentStep,
                languageCode: payload.languageCode,
            }),
        };
    },
};
