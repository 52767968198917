import { IPublicClientApplication } from "@azure/msal-browser";
import { SendSessionReportRequest } from "modules/client/actions-types/sessionReportActionTypes";
import {
    ActivityBasicInfoFilterOptions,
    ActivityBasicInfoReportData,
    FieldErrors,
    SelectedFilterState,
} from "modules/client/session-report-state";
import { UnprocessableContentError } from "./errorClasses";
import { callSecureEndpoint } from "modules/client/auth";
import { isNull } from "lodash";

export async function handleActivityBasicInfoRequest(
    msalInstance: IPublicClientApplication,
    payload: SendSessionReportRequest & { filters: ActivityBasicInfoFilterOptions },
): Promise<Pick<ActivityBasicInfoReportData, "data">> {
    const resp = await constructActivityBasicInfoRequest(msalInstance, payload.experienceUuid, payload.filters);

    if (!resp) {
        throw new Error("redirecting for login...");
    }

    if (resp.status === 422) {
        // validation error
        const errorBody = (await resp.json()) as { message: string; errors: FieldErrors };
        throw new UnprocessableContentError(
            "There were some errors with the filter criteria provided.",
            errorBody.errors,
        );
    } else if (resp.status >= 400) {
        throw new Error("Something went wrong when generating the report.");
    }

    const reportData = (await resp.json()) as Pick<ActivityBasicInfoReportData, "data">;

    return reportData;
}

async function constructActivityBasicInfoRequest(
    msalInstance: IPublicClientApplication,
    experienceUuid: string,
    filters: SelectedFilterState & { type: "ActivityBasicInfo" },
) {
    const urlString = `/api/v1/experiences/${experienceUuid}/reports/activity-basic-info`;
    const url = new URL(urlString, CLIENT_APP_URL);
    Object.entries(filters.options)
        .filter(([_key, value]) => !!value && !isNull(value))
        .forEach(([key, value]) => {
            url.searchParams.set(key, value.toString());
        });
    if (filters.useSeedData) {
        url.searchParams.set("useSeedData", "1");
    }
    return callSecureEndpoint(msalInstance, url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}
