import classNames from "classnames";
import DOMMouseTracker from "modules/components/MouseRenderer/mouse-movement-tracking";
import { CoordinatePreviewMode } from "modules/shared/types";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { createMapMarkupIconTypeSelector } from "modules/shared/selectors/map/createMapMarkupIconTypeSelector";
import { createEnableMapMarkupSelector } from "modules/shared/selectors/step-definition/generic/createEnableMapMarkupSelector";
import { createCurrentAppearanceOptionsSelector } from "modules/shared/selectors/step-definition/generic/createCurrentAppearanceOptionsSelector";
import { sessionEndedSelector } from "modules/shared/selectors/navigation-state/sessionEndedSelector";
import ActivityView from "../ActivityView/ActivityView";
import AwaitingServerProgressBar from "../AwaitingServerProgressBar";
import MouseRenderer from "../MouseRenderer";
import CoordinatesSelector from "../PreviewModeToolbar/CoordinatesSelector";
import "../Sidebar/sidebar.scss";
import { useSessionSelector } from "./context";
import EndModal from "./EndModal";

type Props = {
    leafletSizeRef: React.RefObject<HTMLDivElement>;
    containerRef: React.RefObject<HTMLDivElement>;
    showRotateNavigator: () => void;
    iconHovered: boolean;
    onIconHovered: (value: boolean) => void;
    isSidebarOpen: boolean;
    inPreviewMode: boolean;
    clearPreviewCoordinates: () => void;
    setCoordinatePreviewMode: (mode: CoordinatePreviewMode) => void;
    coordinatePreviewMode: CoordinatePreviewMode;
};

const SessionContainer: React.FC<Props> = (props) => {
    const sessionEnded = useSessionSelector(sessionEndedSelector);
    const currentStepDefinition = useSessionSelector(createGenericStepDefinitionSelector());
    const enableMapMarkup = useSessionSelector(createEnableMapMarkupSelector());
    const markupImage = useSessionSelector(createMapMarkupIconTypeSelector());
    const allowsMapInteraction = currentStepDefinition ? currentStepDefinition.contentType === "infoStep" : false;

    const { overlayBackgroundColor, overlayAdditionalProps, overlayBackgroundDimmer, overlayBackgroundBlur } =
        useSessionSelector(createCurrentAppearanceOptionsSelector());

    const overlayRef = useRef<HTMLDivElement>(null);
    const hudRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const currentRef = overlayRef.current;

        if (currentRef) {
            let newProps = "";
            if (overlayBackgroundColor) {
                newProps += `background-color: ${overlayBackgroundColor};`;
            }

            if (overlayAdditionalProps) {
                newProps += overlayAdditionalProps;
            }

            currentRef.style.cssText = newProps;

            return () => {
                currentRef.style.cssText = "";
            };
        }
    }, [overlayRef, overlayAdditionalProps, overlayBackgroundColor, currentStepDefinition, allowsMapInteraction]);

    const overlayClasses = useMemo(() => {
        let overlayDimmerClass: string;
        let overlayBlurClass: string;
        switch (overlayBackgroundBlur) {
            case "heavy":
                overlayBlurClass = "blur-overlay--heavy";
                break;
            case "medium":
                overlayBlurClass = "blur-overlay--medium";
                break;
            case "light":
                overlayBlurClass = "blur-overlay--light";
                break;
            case "none":
            default:
                overlayBlurClass = "blur-overlay--none";
        }

        switch (overlayBackgroundDimmer) {
            case "heavy":
                overlayDimmerClass = "dimmer-overlay--heavy";
                break;
            case "medium":
                overlayDimmerClass = "dimmer-overlay--medium";
                break;
            case "light":
                overlayDimmerClass = "dimmer-overlay--light";
                break;
            case "none":
            default:
                overlayDimmerClass = "dimmer-overlay--none";
        }

        const showOverlayClass =
            !allowsMapInteraction &&
            (!!overlayBackgroundColor ||
                !!overlayAdditionalProps ||
                !!overlayBackgroundDimmer ||
                !!overlayBackgroundBlur)
                ? "show-overlay"
                : "hide-overlay";

        return classNames("overlay", overlayBlurClass, overlayDimmerClass, showOverlayClass);
    }, [
        overlayBackgroundDimmer,
        overlayBackgroundBlur,
        allowsMapInteraction,
        overlayAdditionalProps,
        overlayBackgroundColor,
    ]);

    return (
        <>
            <MouseRenderer
                mapElement={props.leafletSizeRef}
                excludeElement={hudRef}
                activityElement={props.containerRef}
                enableMapMarkup={enableMapMarkup}
                markupImage={markupImage}
                iconHovered={props.iconHovered}
                coordinatePreviewMode={props.coordinatePreviewMode}
            />

            <div ref={overlayRef} className={overlayClasses} />
            <div
                ref={props.containerRef}
                className={classNames(["session-content", !allowsMapInteraction && "session-content-full-screen"])}
            >
                <DOMMouseTracker el={props.containerRef} />

                {!sessionEnded ? (
                    <>
                        <AwaitingServerProgressBar />
                        <Container className="d-flex h-100 p-0" fluid>
                            <Row className={allowsMapInteraction ? "d-flex m-0" : "d-flex m-0 flex-fill"}>
                                <Col
                                    className={classNames("activity-view-container", {
                                        "preview-mode": props.inPreviewMode,
                                    })}
                                >
                                    <ActivityView
                                        showRotateNavigator={props.showRotateNavigator}
                                        isSidebarOpen={props.isSidebarOpen}
                                        onIconHovered={props.onIconHovered}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </>
                ) : (
                    <EndModal isSidebarOpen={props.isSidebarOpen} />
                )}
            </div>
            {props.coordinatePreviewMode !== CoordinatePreviewMode.Off && (
                <CoordinatesSelector
                    onReset={props.clearPreviewCoordinates}
                    onDone={() => {
                        props.clearPreviewCoordinates();
                        props.setCoordinatePreviewMode(CoordinatePreviewMode.Off);
                    }}
                    coordinatePreviewMode={props.coordinatePreviewMode}
                    setCoordinatePreviewMode={props.setCoordinatePreviewMode}
                    isSidebarOpen={props.isSidebarOpen}
                />
            )}
        </>
    );
};

export default SessionContainer;
