import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    participantId: string;
    languageCode: string;
};
const createParticipantReady = (args: Args): SessionEvent => {
    return {
        type: "participantReady",
        participantId: args.participantId,
        languageCode: args.languageCode,
        uuid: uuidv4(),
    };
};

export default createParticipantReady;
