import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaMousePointer, FaPlus } from "react-icons/fa";
import { LatLngBounds } from "leaflet";
import { ApplicationState } from "modules/client/application-state";
import { useLocalizedString } from "modules/client/localization";
import {
    nodeFromIndexPath,
    PositionInElementHierarchy,
    PositionInMap,
} from "modules/components/MouseRenderer/mouse-movement-tracking";
import { createFilteredParticipantCursorsWithParticipantInfo } from "modules/shared/selectors/participants/createFilteredParticipantCursorsWithParticipantInfo";
import { participants as participantsSelector } from "modules/shared/selectors/participants/participants";
import { createShowParticipantCursorsSelector } from "modules/shared/selectors/participants/createShowParticipantCursorsSelector";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { createAssetMarkupIconTypeSelector } from "modules/shared/selectors/step-definition/single-asset/createAssetMarkupIconTypeSelector";
import { createEnableAssetMarkupSelector } from "modules/shared/selectors/step-definition/single-asset/createEnableAssetMarkupSelector";
import { participantInfoStepMarkupIconLimit } from "modules/shared/selectors/participants/participantInfoStepMarkupIconLimit";
import React, { useEffect, useRef, useState } from "react";
import { Placeholder } from "modules/components/MouseRenderer/mouse-movement-tracking";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useSessionParticipantId, useSessionSelector } from "../SessionRenderer/context";
import CurrentParticipantCursor from "./CurrentParticipantCursor";
import NamedCursor from "./NamedCursor";
import { CoordinatePreviewMode } from "modules/shared/types";

/**
 * How close to let the rendered cursors get to the side of the screen. In
 * pixels. Should be enough to allow the label to show */
const CURSOR_MARGIN = 48;

type Props = {
    /** Root element to base positions on for DOM-based positioning */
    activityElement: React.RefObject<HTMLDivElement>;

    /** Element we don't want to cover up */
    excludeElement: React.RefObject<HTMLElement>;

    /** Root element to base positions on for DOM-based positioning */
    mapElement: React.RefObject<HTMLDivElement>;

    /**Boolean for map markup mode */
    enableMapMarkup: boolean;

    /**markup image from contentful */
    markupImage: string;

    /**markup hover state */
    iconHovered: boolean;

    coordinatePreviewMode: CoordinatePreviewMode;
};

type PlacementPreviewProps = {
    markupImage?: string;

    hoverState: boolean;
};

export const PlacementPreview: React.FC<PlacementPreviewProps> = (props) => {
    const cursorIcon = useRef<HTMLDivElement>(null);
    const endX = useRef(window.innerWidth / 2);
    const endY = useRef(window.innerHeight / 2);

    const participantId = useSessionParticipantId();
    const markupIconsLimit = useSessionSelector(participantInfoStepMarkupIconLimit(participantId));
    const mouseMoveEvent = (e: MouseEvent) => {
        endX.current = e.pageX;
        endY.current = e.pageY;

        // offset icon slightly from cursor
        if (cursorIcon.current) {
            cursorIcon.current.style.top = endY.current - 15 + "px";
            cursorIcon.current.style.left = endX.current - 15 + "px";
        }
    };

    useEffect(() => {
        document.addEventListener("mousemove", mouseMoveEvent);

        return () => {
            document.removeEventListener("mousemove", mouseMoveEvent);
        };
    }, []);

    return (
        <>
            {!!props.markupImage ? (
                <div ref={cursorIcon} className={markupIconsLimit !== 0 ? "placement-preview markup" : ""}>
                    <i className="material-icons" style={{ fontSize: "large" }}>
                        {props.markupImage}
                    </i>
                </div>
            ) : (
                <div ref={cursorIcon} className="placement-preview hotspot">
                    <span>
                        <FaPlus />
                    </span>
                </div>
            )}
        </>
    );
};

const MouseRenderer: React.FC<Props> = (props) => {
    const participantId = useSessionParticipantId();
    const localized = useLocalizedString();
    const currentStepDefinition = useSessionSelector(createGenericStepDefinitionSelector());
    const isSingleAssetStep = currentStepDefinition?.contentType === "singleAssetStep";
    const isAssetMarkupEnabled = useSessionSelector(createEnableAssetMarkupSelector());
    const singleAssetHoverState = isSingleAssetStep && isAssetMarkupEnabled && props.iconHovered;
    const assetMarkupIcon = useSessionSelector(createAssetMarkupIconTypeSelector());

    const currentParticipant = useSessionSelector(
        createSelector([participantsSelector], (participants) => participants.find((p) => p.id === participantId)),
    );
    const filteredParticipantCursors = useSessionSelector(
        createFilteredParticipantCursorsWithParticipantInfo((pId) => pId !== participantId),
    );

    const mapBounds = useSelector((state: ApplicationState) => {
        const session = state.session;
        if (session.state === "READY") {
            return session.mapBounds;
        }

        return null;
    });

    const [currentCursorPosition, setCurrentCursorPosition] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        const container = props.activityElement.current;

        const mouseListener = (e: MouseEvent) => {
            if (!container) {
                return;
            }

            const pos: [number, number] = [
                (e.pageX ?? e.clientX) - container.offsetLeft,
                (e.pageY ?? e.clientY) - container.offsetTop,
            ];
            setCurrentCursorPosition(pos);
        };
        document.addEventListener("mousemove", mouseListener);

        return () => {
            document.removeEventListener("mousemove", mouseListener);
        };
    }, [props.activityElement]);

    const cursorsVisible = useSessionSelector(createShowParticipantCursorsSelector());

    const activityElement = props.activityElement.current;
    const mapElement = props.mapElement.current;

    if (!activityElement || !mapElement || !mapBounds) {
        return <></>;
    }

    let cursorFollower = <Placeholder />;

    if (singleAssetHoverState) {
        cursorFollower = <PlacementPreview markupImage={assetMarkupIcon} hoverState={props.iconHovered} />;
    } else if (
        !props.iconHovered &&
        props.coordinatePreviewMode !== CoordinatePreviewMode.Hotspot &&
        props.enableMapMarkup
    ) {
        cursorFollower = <PlacementPreview markupImage={props.markupImage} hoverState={props.iconHovered} />;
    } else if (!props.iconHovered && props.coordinatePreviewMode === CoordinatePreviewMode.Hotspot) {
        cursorFollower = <PlacementPreview hoverState={false} />;
    }
    return (
        <div className="mouse-overlay">
            {cursorsVisible &&
                filteredParticipantCursors.map((p) => {
                    return (
                        !!p.mousePosition &&
                        !!p.name && (
                            <PositionedCursor
                                key={p.id}
                                visible={cursorsVisible}
                                excludeElement={props.excludeElement.current}
                                mapBounds={mapBounds ?? null}
                                activityContainer={activityElement}
                                mapContainer={mapElement}
                                participantId={p.id}
                                name={p.name}
                                mousePosition={p.mousePosition}
                                currentUserMousePositionInContainer={currentCursorPosition}
                            />
                        )
                    );
                })}
            {cursorsVisible && (
                <CurrentParticipantCursor
                    cursorPosition={currentCursorPosition}
                    participantName={currentParticipant?.name || localized("vdpActivity_defaultCurrentParticipantName")}
                />
            )}
            {cursorFollower}
        </div>
    );
};

export default MouseRenderer;

type CursorProps = {
    participantId: string;
    visible: boolean;
    mapBounds: LatLngBounds;
    name: string;
    excludeElement?: HTMLElement | null;
    mousePosition: PositionInElementHierarchy | PositionInMap;
    activityContainer: HTMLDivElement;
    mapContainer: HTMLDivElement;
    currentUserMousePositionInContainer: [number, number];
};
const PositionedCursor: React.FC<CursorProps> = (props) => {
    let positionTop: number | null = null;
    let positionLeft: number | null = null;

    let referenceRect: DOMRect | null = null;
    let icon = <FaMousePointer />;

    if (props.mousePosition.type === "relativeToDOM") {
        referenceRect = props.activityContainer.getBoundingClientRect();
        /** Calculate position based on position relative to DOM */
        const targetNode = nodeFromIndexPath(props.activityContainer, props.mousePosition.path);
        if (targetNode) {
            const targetRect = targetNode.getBoundingClientRect();
            const baseX = targetRect.left - referenceRect.left;
            const baseY = targetRect.top - referenceRect.top;

            positionLeft = baseX + props.mousePosition.coords[0] * targetRect.width;
            positionTop = baseY + props.mousePosition.coords[1] * targetRect.height;
        }
    } else if (props.mousePosition.type === "relativeToMap") {
        referenceRect = props.mapContainer.getBoundingClientRect();
        const { mapBounds } = props;
        const nw = mapBounds.getNorthWest();
        const se = mapBounds.getSouthEast();

        const [mouseY, mouseX] = props.mousePosition.latlng;
        const [minY, minX] = [nw.lat, nw.lng];
        const [maxY, maxX] = [se.lat, se.lng];

        const visibleWidth = maxX - minX;
        const visibleHeight = maxY - minY;

        positionLeft = ((mouseX - minX) / visibleWidth) * referenceRect.width;
        positionTop = ((mouseY - minY) / visibleHeight) * referenceRect.height;

        if (positionTop && positionLeft) {
            if (positionLeft < 0) {
                positionLeft = 0;
                icon = <FaArrowLeft />;
            } else if (positionLeft > referenceRect.width - CURSOR_MARGIN) {
                positionLeft = referenceRect.width - CURSOR_MARGIN;
                icon = <FaArrowRight />;
            }

            if (positionTop < 0) {
                positionTop = 0;
                icon = <FaArrowUp />;
            } else if (positionTop > referenceRect.height - CURSOR_MARGIN) {
                positionTop = referenceRect.height - CURSOR_MARGIN;
                icon = <FaArrowDown />;
            }
        }
    }

    if (typeof positionTop !== "number" || typeof positionLeft !== "number") {
        return <></>;
    }

    let visible = true;
    const excludeRect = props.excludeElement?.getBoundingClientRect();
    if (excludeRect) {
        visible = !(
            excludeRect.left < positionLeft &&
            excludeRect.right > positionLeft &&
            excludeRect.top < positionTop &&
            excludeRect.bottom > positionTop
        );
    }

    const distance2 =
        Math.pow(props.currentUserMousePositionInContainer[0] - positionLeft, 2) +
        Math.pow(props.currentUserMousePositionInContainer[1] - positionTop, 2);

    // Start turning down opacity when within 75px, to a min of 25%
    const rawOpacity = distance2 / (75 * 75);
    const opacity = Math.max(Math.min(rawOpacity, 1.0), 0.25);

    return (
        <NamedCursor
            visible={props.visible && visible}
            opacity={opacity}
            top={positionTop}
            left={positionLeft}
            name={props.name}
        />
    );
};
