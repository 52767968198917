import { ComparisonRow } from "modules/shared/activity/Comparison/types";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ComparisonCard from "./ComparisonCard";
import "./ComparisonRow.scss";
import { useLocalizedString } from "modules/client/localization";

type Props = {
    state: ComparisonRow;
};

const ComparisonRowComponent: React.FC<Props> = (props) => {
    const localized = useLocalizedString();
    const lastIndx = props.state.columns.length - 1;

    return (
        <>
            <div className="activity-title" aria-label={props.state.title} tabIndex={0}>
                {props.state.title}
            </div>
            <Row className="comparison-row">
                {props.state.columns.map((column, indx) => {
                    const cardPosition =
                        indx === 0
                            ? localized("vdpActivity_FirstTitlePosition")
                            : indx === lastIndx
                            ? localized("vdpActivity_LastTitlePosition")
                            : "";
                    return (
                        <Col key={column.title} className="comparison-column">
                            <div
                                className="column-title"
                                aria-label={`${column.title}, ${column.card?.title}, ${cardPosition}`}
                                tabIndex={0}
                            >
                                {column.title}
                            </div>
                            <div className="comparison-card-container">
                                {column.card && <ComparisonCard card={column.card}></ComparisonCard>}
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default ComparisonRowComponent;
