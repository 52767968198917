import "./badge-with-icon.scss";
import React from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import classNames from "classnames";
import { IconType, IconBaseProps } from "react-icons";

export interface BadgeWithIconProps extends BadgeProps {
    roleLabel: string;
    icon?: IconType;
    iconProps?: IconBaseProps;
    variant?: Variant;
    tab?: boolean;
}

const BadgeWithIcon: React.FC<BadgeWithIconProps> = (props) => {
    const { roleLabel, icon, iconProps, variant, tab, className, ...remainingProps } = props;
    const badgeClassName = classNames(className, "badge-with-icon", {
        [`badge-with-icon-${variant}`]: !!variant,
        "tab-badge": !!tab
    });

    return (
        <Badge bg="" className={badgeClassName} {...remainingProps}>
            {!!icon && icon(!!iconProps ? iconProps : { size: 14 })}
            <span className="badge-with-icon-label">{roleLabel}</span>
        </Badge>
    );
};

export default BadgeWithIcon;
