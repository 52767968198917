import { createCurrentStepEntryIdSelector } from "modules/shared/selectors/step-address/createCurrentStepEntryIdSelector";
import { scriptSelector } from "modules/shared/selectors/step-definition/generic/scriptSelector";
import React, { useRef, useState } from "react";
import { Button, Dropdown, Overlay, Popover } from "react-bootstrap";
import { MdMoreVert } from "react-icons/md";
import StepInfo from "./StepInfo";
import { useSessionDispatch, useSessionSelector } from "../SessionRenderer/context";
import "./preview-mode-toolbar.scss";
import DispatchButton from "../DispatchButton";
import { useLocalizedString } from "modules/client/localization";
import { v4 } from "uuid";
import { sessionContentfulManagementComments } from "modules/client/selectors";
import { useDispatch, useSelector } from "react-redux";
import CommentCreationView from "./CommentCreationView";
import CommentListModal from "./CommentListModal";
import PreviewModeAboutModal from "./PreviewModeAboutModal";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { ContentfulManagementFetchTypes } from "modules/shared/contentful-mgmt-types";
import { parseISO } from "date-fns";
import { ContentfulComment } from "modules/client/application-state";
import { useContentfulManagementFetch } from "modules/client/services/contentful";
import { CoordinatePreviewMode } from "modules/shared/types";

const PreviewModeToolbar: React.FC<{
    inWelcomeMode: boolean;
    setCoordinatePreviewMode: (mode: CoordinatePreviewMode) => void;
}> = (props) => {
    const sessionDispatch = useSessionDispatch();
    const dispatch = useDispatch();
    const localized = useLocalizedString();
    const script = useSessionSelector(scriptSelector);
    const currentVersion = useSessionSelector((s) => s.scriptVersion);
    const currentCommentsList = useSelector(sessionContentfulManagementComments);
    const currentStepEntryId = useSessionSelector(createCurrentStepEntryIdSelector());
    const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
    const [isCommentCreationOpen, setIsCommentCreationOpen] = useState(false);
    const [isCommentListOpen, setIsCommentListOpen] = useState(false);
    const commentCreationTarget = useRef(null);
    const experienceName = script.name;

    const reset = () => {
        const uuid = sessionDispatch({
            type: "RELOAD_SCRIPT",
            uuid: v4(),
            scriptVersion: currentVersion,
        });
        const message = {
            id: v4(),
            message: "Contentful content was reloaded.",
            success: true,
            eventTime: "Just Now",
        };
        const participantMessageEvent = createParticipantMessageReceived({
            payload: message,
        });
        dispatch(participantMessageEvent);

        return uuid;
    };

    const handleComments = React.useCallback(
        (resp: ContentfulManagementFetchTypes["GetComments"]["resp"]) => {
            switch (resp.type) {
                case "FAILURE":
                    if (
                        currentStepEntryId === "exit" ||
                        currentStepEntryId === "introStep" ||
                        currentStepEntryId === "progressCheck" ||
                        currentStepEntryId === ""
                    ) {
                        // if our step entry id is "exit" or has an id of an empty string then there is no entry for us to fetch comments
                        dispatch({
                            type: "UPDATE_CONTENTFUL_MANAGEMENT",
                            payload: {
                                comments: [],
                            },
                        });
                        break;
                    }
                    const event = createParticipantMessageReceived({
                        payload: {
                            id: v4(),
                            success: false,
                            message: resp.message,
                        },
                    });
                    dispatch(event);
                    break;
                case "SUCCESS":
                    const comments: ContentfulComment[] = resp.response.items.map((c) => ({
                        enteredAt: parseISO(c.sys.createdAt),
                        body: c.body,
                        commentId: c.sys.id,
                        version: c.sys.version,
                        ownerId: c.sys.createdBy?.sys.id ?? "",
                    }));
                    dispatch({
                        type: "UPDATE_CONTENTFUL_MANAGEMENT",
                        payload: {
                            comments,
                        },
                    });
                    break;
            }
        },
        [currentStepEntryId, dispatch],
    );
    const fetchArgs = React.useMemo(() => ({ entryId: currentStepEntryId }), [currentStepEntryId]);
    useContentfulManagementFetch("GetComments", fetchArgs, handleComments);

    return (
        <>
            <div className="toolbar">
                <div className="title-container">
                    <div className="preview-mode-title">{localized("toolbar_previewMode")}</div>
                    <div className="experience-title">{experienceName}</div>
                </div>
                <div className="section">
                    <StepInfo inWelcomeMode={props.inWelcomeMode} />
                </div>
                <div className="button-group">
                    <DispatchButton variant="secondary" onClick={reset}>
                        {localized("toolbar_reloadContent")}
                    </DispatchButton>
                    <Button
                        variant="secondary"
                        onClick={() => setIsCommentCreationOpen(!isCommentCreationOpen)}
                        ref={commentCreationTarget}
                    >
                        {localized("toolbar_commentCreationButton", currentCommentsList.length)}
                    </Button>
                    <Dropdown className="toolbar-dropdown d-flex align-items-center">
                        <Dropdown.Toggle variant="">
                            <MdMoreVert size={24} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            renderOnMount
                            popperConfig={{ modifiers: [{ name: "offset", options: { offset: [0, 20] } }] }}
                        >
                            <Dropdown.Item onClick={() => setIsAboutModalOpen(!isAboutModalOpen)}>
                                <div>{localized("toolbar_aboutPreviewMode")}</div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                                onClick={() => {
                                    props.setCoordinatePreviewMode(CoordinatePreviewMode.FocusArea);
                                }}
                            >
                                {localized("toolbar_focusAreaSelector")}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                                onClick={() => {
                                    props.setCoordinatePreviewMode(CoordinatePreviewMode.Hotspot);
                                }}
                            >
                                {localized("toolbar_hotspotSelector")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <Overlay
                target={commentCreationTarget.current}
                show={isCommentCreationOpen}
                placement="bottom-end"
                offset={[0, 20]}
                popperConfig={{ strategy: "fixed" }}
            >
                <Popover className="comment-creation">
                    <Popover.Body>
                        <CommentCreationView
                            cancel={() => setIsCommentCreationOpen(false)}
                            openModal={() => {
                                setIsCommentCreationOpen(false);
                                setIsCommentListOpen(true);
                            }}
                            inWelcomeMode={props.inWelcomeMode}
                        />
                    </Popover.Body>
                </Popover>
            </Overlay>
            <CommentListModal
                inWelcomeMode={props.inWelcomeMode}
                isCommentListOpen={isCommentListOpen}
                setIsCommentListOpen={() => setIsCommentListOpen(false)}
            />
            <PreviewModeAboutModal
                isAboutModalOpen={isAboutModalOpen}
                setIsAboutModalOpen={() => setIsAboutModalOpen(false)}
            />
        </>
    );
};
export default PreviewModeToolbar;
