import { ReceiveSessionReportError } from "modules/client/actions-types/sessionReportActionTypes";
import { ApplicationState } from "modules/client/application-state";
import { ReportStatus } from "modules/client/session-report-state";

export default function receiveSessionReportError(
    state: ApplicationState,
    payload: ReceiveSessionReportError,
): ApplicationState {
    return {
        ...state,
        sessionReportState: {
            filters: state.sessionReportState.filters,
            status: ReportStatus.Error,
            errors: payload.errors,
        },
    };
}
