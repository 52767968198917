import L, { LatLng, LatLngBounds } from "leaflet";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { ClickableArea, Hotspot, MapState, PreviewHotspot } from "../../shared/types";
import union from "@turf/union";
import { Feature, Polygon, MultiPolygon, polygon, multiPolygon, Properties } from "@turf/helpers";

export const fromMapUnit = (coords: L.LatLng, pixelSize: [number, number]): [number, number] => {
    // project fixes us from (X,Y) => (Lat, Lng)
    const ll = L.CRS.Simple.project(coords);

    return [coords.lng * pixelSize[0], coords.lat * pixelSize[1]];
};

export const toMapUnit = (coords: Array<number>, pixelSize: [number, number]): L.LatLng => {
    const [scaledX, scaledY] = [coords[0] / pixelSize[0], coords[1] / pixelSize[1]];

    // project fixes us from (X,Y) => (Lat, Lng)
    return L.CRS.Simple.unproject(L.point(scaledX, scaledY));
};

export const toPixels = (coords: LatLng, pixelSize: [number, number]): [number, number] => {
    return [L.CRS.Simple.project(coords).x * pixelSize[0], L.CRS.Simple.project(coords).y * pixelSize[1]];
};

export const getPixelSize = (
    mapMetadata: Pick<LeafletMapMetadata, "minzoom" | "tile_matrix">,
    zoomLevel = 0,
): [number, number] => {
    return [
        mapMetadata.tile_matrix[getZoomOffset(mapMetadata, zoomLevel)].pixel_size[0],
        mapMetadata.tile_matrix[getZoomOffset(mapMetadata, zoomLevel)].pixel_size[1],
    ];
};

export const getMinZoom = (mapMetadata: Pick<LeafletMapMetadata, "minzoom">): number => {
    const s = mapMetadata.minzoom;
    if (/^-?\d+$/.test(s)) {
        return +s;
    }
    throw new Error(`Unable to parse field minzoom with value "${mapMetadata.minzoom}"`);
};

export const getMaxZoom = (mapMetadata: Pick<LeafletMapMetadata, "maxzoom">): number => {
    const s = mapMetadata.maxzoom;
    if (/^-?\d+$/.test(s)) {
        return +s;
    }
    throw new Error(`Unable to parse field minzoom with value "${mapMetadata.maxzoom}"`);
};

export const getZoomSnap = (mapMetadata: Pick<LeafletMapMetadata, "zoomsnap">): number => {
    const s = mapMetadata.zoomsnap;
    if (s === undefined) {
        return 1;
    }
    if (/^\d*\.?\d+$/.test(s)) {
        return +s;
    }
    throw new Error(`Unable to parse field zoomsnap with value "${mapMetadata.zoomsnap}"`);
};

export const getZoomDelta = (mapMetadata: Pick<LeafletMapMetadata, "zoomdelta">): number => {
    const s = mapMetadata.zoomdelta;
    if (s === undefined) {
        return 1;
    }
    if (/^\d*\.?\d+$/.test(s)) {
        return +s;
    }
    throw new Error(`Unable to parse field zoomdelta with value "${mapMetadata.zoomdelta}"`);
};

const getZoomOffset = (mapMetadata: Pick<LeafletMapMetadata, "minzoom">, zoomLevel: number) => {
    return zoomLevel - getMinZoom(mapMetadata);
};

export const getTileSize = (
    mapMetadata: Pick<LeafletMapMetadata, "tile_matrix" | "minzoom">,
    zoomLevel = 0,
): L.Point => {
    return L.point(
        mapMetadata.tile_matrix[getZoomOffset(mapMetadata, zoomLevel)].tile_size[0],
        mapMetadata.tile_matrix[getZoomOffset(mapMetadata, zoomLevel)].tile_size[1],
    );
};

const calculatePointOnEllipse = (
    center: [number, number],
    xSemiAxis: number,
    ySemiAxis: number,
    angleInDegrees: number,
): [number, number] => {
    return [
        center[0] + ySemiAxis * Math.sin((angleInDegrees * Math.PI) / 180),
        center[1] + xSemiAxis * Math.cos((angleInDegrees * Math.PI) / 180),
    ];
};

export const buildEllipseFromBounds = (bounds: LatLngBounds, precision: number): [number, number][] => {
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const west = bounds.getWest();
    const east = bounds.getEast();

    const xSemiAxis = Math.abs(east - west) / 2;
    const ySemiAxis = Math.abs(south - north) / 2;
    const center: [number, number] = [(north + south) / 2, (west + east) / 2];

    const ellipsePath: [number, number][] = [];

    const numberOfPointsOnEllipse = precision * 4 + 4;
    const angleIncrement = 360 / numberOfPointsOnEllipse;
    for (let i = 0; i < numberOfPointsOnEllipse; i++) {
        const angle = i * angleIncrement;

        const point = calculatePointOnEllipse(center, xSemiAxis, ySemiAxis, angle);
        ellipsePath.push(point);
    }

    ellipsePath.push(ellipsePath[0]);

    return ellipsePath;
};

export const createOverlayWithHighlights = (
    boundedAreas: LatLngBounds[],
    mapCenter: L.LatLng,
    mapSize: { x: number; y: number },
): [number, number][][] | null => {
    if (boundedAreas.length < 1) {
        return null;
    }

    let ellipses: Feature<Polygon | MultiPolygon, Properties>;

    boundedAreas.map((bounds, i) => {
        if (i === 0) {
            ellipses = multiPolygon([[buildEllipseFromBounds(bounds, 5)]]);
        } else {
            ellipses = union(ellipses, polygon([buildEllipseFromBounds(bounds, 5)]))!;
        }
    });

    const ellipsesAsCoords =
        ellipses!.geometry.type === "MultiPolygon"
            ? ellipses!.geometry.coordinates.flat()
            : ellipses!.geometry.coordinates;

    const darkenedBackground: number[][] = [
        [mapCenter.lat - mapSize.y / 2, mapCenter.lng - mapSize.x / 2],
        [mapCenter.lat - mapSize.y / 2, mapCenter.lng + mapSize.x / 2],
        [mapCenter.lat + mapSize.y / 2, mapCenter.lng + mapSize.x / 2],
        [mapCenter.lat + mapSize.y / 2, mapCenter.lng - mapSize.x / 2],
    ];

    return [darkenedBackground, ellipsesAsCoords] as [number, number][][];
};

export const getHighlightAreasFromMapState = (
    highlightAreas: MapState[],
    pixelSize: [number, number],
): L.LatLngBounds[] | null => {
    if (highlightAreas.length) {
        return highlightAreas.map((highlightArea) => calculateBoundsFor(pixelSize, highlightArea));
    } else {
        return null;
    }
};

export const getClickableBoundsFromClickableArea = (
    clickableArea: ClickableArea,
    pixelSize: [number, number],
): L.LatLngBounds => {
    return calculateBoundsFor(pixelSize, {
        topLeft: [clickableArea.mapCoordinates[0], clickableArea.mapCoordinates[1]],
        bottomRight: [clickableArea.mapCoordinates[2], clickableArea.mapCoordinates[3]],
    });
};

export const getShouldUpdateHighlightAreaKey = (highlightAreas: L.LatLngBounds[] | null): string => {
    if (highlightAreas && highlightAreas.length) {
        return highlightAreas
            .flatMap((highlightArea) => [`${highlightArea.getNorthWest()}`, `${highlightArea.getSouthEast()}`])
            .join("-");
    } else {
        return "";
    }
};

export function calculateBoundsFor(pixelSize: [number, number], focus: MapState): L.LatLngBounds {
    const north = focus.topLeft[1];
    const south = focus.bottomRight[1];

    const west = focus.topLeft[0];
    const east = focus.bottomRight[0];

    const [ne, sw] = [toMapUnit([east, north], pixelSize), toMapUnit([west, south], pixelSize)];
    return L.latLngBounds(ne, sw);
}

export function calculateCoordinatesFor(pixelSize: [number, number], hotspot: Hotspot | PreviewHotspot) {
    return toMapUnit(hotspot.mapCoordinates, pixelSize);
}

export function focusAreasAreEqual(first: MapState | null, second: MapState | null): boolean {
    return (
        !!first &&
        !!second &&
        // points are the exact same or reversed in the order they are stored
        ((first.bottomRight[0] === second.bottomRight[0] &&
            first.bottomRight[1] === second.bottomRight[1] &&
            first.topLeft[0] === second.topLeft[0] &&
            first.topLeft[1] === second.topLeft[1]) ||
            (first.bottomRight[0] === second.topLeft[0] &&
                first.bottomRight[1] === second.topLeft[1] &&
                first.topLeft[0] === second.bottomRight[0] &&
                first.topLeft[1] === second.bottomRight[1]))
    );
}
