import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { createSecondsPassedForSectionSelector } from "modules/shared/selectors/time-tracking/createSecondsPassedForSectionSelector";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { isIntroStepAddress } from "modules/shared/utils";
import { formatTimeSectionsDropDown, getRemainingSectionTime } from "modules/utils";
import { ApplicationState } from "modules/client/application-state";
import { useSession, useSessionSelector } from "../SessionRenderer/context";
import { useSelector } from "react-redux";
import { useDateFnsLocale } from "modules/client/localization";

type Props = {
    sectionKey: number | "intro";
    sectionTitle: string;
    recommendedTime: number;
};
const SectionsDisplayRow: React.FC<Props> = (props) => {
    const { sessionStateSelector } = useSession();
    const sectionKey = useMemo(() => props.sectionKey, [props.sectionKey]);

    const sectionTimeElapsed = useSelector((state: ApplicationState) => {
        return createSecondsPassedForSectionSelector()(sessionStateSelector(state), sectionKey);
    });

    const currentStepAdd = useSessionSelector((s) => currentStepAddress(s));
    const currentSectionIdx =
        currentStepAdd !== "exit" && !isIntroStepAddress(currentStepAdd) ? currentStepAdd.sectionKey : -1;

    const isSectionActive = useCallback((sectionKey: number | "intro", currentIdx: number) => {
        if (sectionKey === "intro") {
            return currentIdx === -1;
        }
        return sectionKey === currentIdx;
    }, []);

    const isSectionDisabled = useCallback(
        (sectionKey: number | "intro", currentIdx: number, sectionTimeElapsed: number) => {
            if (typeof sectionKey === "string") {
                return false;
            }
            return sectionTimeElapsed === 0 && currentIdx !== null && sectionKey > currentIdx;
        },
        [],
    );

    const sectionClassNames = classnames({
        active: isSectionActive(props.sectionKey, currentSectionIdx),
        disabled: isSectionDisabled(props.sectionKey, currentSectionIdx, sectionTimeElapsed),
    });

    const locale = useDateFnsLocale();

    return (
        <tr className={sectionClassNames}>
            <td>{props.sectionTitle}</td>
            <td className="spacer" />
            <td className="recommended">
                {!!props.recommendedTime ? formatTimeSectionsDropDown(props.recommendedTime, locale) : ""}
            </td>
            <td className="spacer" />
            <td className="actual">
                {!!props.recommendedTime ? formatTimeSectionsDropDown(sectionTimeElapsed, locale) : ""}
            </td>
            <td className="spacer" />
            <td className="remaining">
                {!!props.recommendedTime
                    ? getRemainingSectionTime(sectionTimeElapsed, props.recommendedTime, locale)
                    : ""}
            </td>
        </tr>
    );
};

export default SectionsDisplayRow;
