import { all, call, select, takeLatest } from "redux-saga/effects";
import { Action } from "../actions-types";
import { updateSessionCache, writeToSessionRepo } from "pages/inPerson/utils/sessionUtils";
import { SagaIterator } from "redux-saga";

export function* inPersonSaga(action: Action): SagaIterator {
    const state = yield select();
    switch (action.type) {
        case "ADD_INPERSON_PARTICIPANTS_TO_STATE":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_GOTO_STEP":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_TEXT_ENTRY_SUBMITTED":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_MULTIPLE_CHOICE_SUBMITTED":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_DRAG_AND_DROP_SUBMITTED":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "IN_PERSON_EXIT":
            yield call(updateSessionCache, state.session.sessionId, state.session.effectiveState);
            break;
        case "WRITE_SESSION":
            yield call(writeToSessionRepo, state.session.sessionId);
            break;
        default:
            break;
    }
}

export function* watchUpdateSession() {
    yield all([
        takeLatest("ADD_INPERSON_PARTICIPANTS_TO_STATE", inPersonSaga),
        takeLatest("IN_PERSON_GOTO_STEP", inPersonSaga),
        takeLatest("IN_PERSON_TEXT_ENTRY_ANSWER_CHANGED", inPersonSaga),
        takeLatest("IN_PERSON_TEXT_ENTRY_SUBMITTED", inPersonSaga),
        takeLatest("IN_PERSON_MULTIPLE_CHOICE_ANSWER_CHANGED", inPersonSaga),
        takeLatest("IN_PERSON_MULTIPLE_CHOICE_SUBMITTED", inPersonSaga),
        takeLatest("IN_PERSON_DRAG_AND_DROP_SUBMITTED", inPersonSaga),
        takeLatest("IN_PERSON_EXIT", inPersonSaga),
        takeLatest("WRITE_SESSION", inPersonSaga),
    ]);
}
