import { format, parseISO } from "date-fns";
import { useDateFnsLocale, useLocalizedString } from "modules/client/localization";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsInfoCircle } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";
import Experience from "../../database_types/experience";
import { SessionRepositoryReportRoute } from "../../shared/routes";
import "./ExperienceRow.scss";
import { Link } from "react-router-dom";
import { toDate, toZonedTime } from "date-fns-tz";
import InfoToggle from "./InfoToggle";
import ConfirmationDialogue from "../ConfirmationDialog";

type Props = {
    experience: Experience;
    onResurrect: (experienceUuid: string) => void;
    onDestroy: (experienceUuid: string) => void;
};

const ArchivedExperienceRow: React.FC<Props> = ({ experience, onResurrect, onDestroy }) => {
    const localized = useLocalizedString();
    const dateFnLocale = useDateFnsLocale();
    const [isIconActive, setIsIconActive] = useState(false);
    const [confirmResurrect, setConfirmResurrect] = useState(false);
    const [confirmDestroy, setConfirmDestroy] = useState(false);

    const keyContacts = JSON.parse(experience.key_contacts, (_key, value) => (!!value ? value : []));

    const reportingLink = React.useMemo(() => SessionRepositoryReportRoute.generate(experience.uuid), [experience]);

    const emailContact = () => {
        window.location.href = "mailto:" + keyContacts.join(",");
    };

    const archivedDateUTC = !!experience.archived_at ? toDate(experience.archived_at, { timeZone: "UTC" }) : null;
    const archivedDateString = !!archivedDateUTC
        ? format(
              toZonedTime(archivedDateUTC, Intl.DateTimeFormat().resolvedOptions().timeZone),
              "yyyy-MM-dd KK:mm aa",
              { locale: dateFnLocale },
          )
        : "";

    return (
        <Accordion defaultActiveKey="0" className="experienceRow">
            <Row className="align-items-center">
                <Col md="3">
                    <Row className="align-items-center">
                        <Col md="10">
                            {experience.company_name} <i>{experience.experience_name}</i>
                        </Col>
                        <Col md="2">
                            <InfoToggle eventKey="1">
                                {experience.info && (
                                    <span onClick={() => setIsIconActive(!isIconActive)} style={{ cursor: "pointer" }}>
                                        <BsInfoCircle size={20} color={isIconActive ? "#266ed9" : "black"} />
                                    </span>
                                )}
                            </InfoToggle>
                        </Col>
                    </Row>
                </Col>
                <Col md="2">{archivedDateString}</Col>
                <Col md="1">
                    <Link to={reportingLink}>{localized("genUrl_goToSessionRepoReport")}</Link>
                </Col>
                <Col md="1">
                    {keyContacts.length > 0 && (
                        <Button type="submit" onClick={emailContact}>
                            {localized("genUrl_emailContact")}
                        </Button>
                    )}
                </Col>
                <Col md="1">
                    <Button type="submit" variant="warning" onClick={() => setConfirmResurrect(true)}>
                        {localized("genUrl_resurrectButton")}
                    </Button>
                </Col>
                <Col md="1">
                    <Button type="submit" variant="danger" onClick={() => setConfirmDestroy(true)}>
                        {localized("genUrl_destroyButton")}
                    </Button>
                </Col>
                <Row>
                    <Accordion.Collapse eventKey="1">
                        <p style={{ width: "60%", margin: "10px 0" }}>{experience.info}</p>
                    </Accordion.Collapse>
                </Row>
            </Row>
            {confirmResurrect && (
                <ConfirmationDialogue
                    title={localized("confirmDialog_scheduler_resurrectTitle")}
                    body={localized("confirmDialog_scheduler_resurrectBody", experience.experience_name)}
                    action={() => onResurrect(experience.uuid)}
                    onCloseCb={() => setConfirmResurrect(false)}
                    confirmValue={experience.experience_name}
                />
            )}
            {confirmDestroy && (
                <ConfirmationDialogue
                    // TODO: add new app strings for destroy dialogue
                    title={localized("confirmDialog_scheduler_destroyTitle")}
                    body={localized("confirmDialog_scheduler_destroyBody", experience.experience_name)}
                    action={() => onDestroy(experience.uuid)}
                    onCloseCb={() => setConfirmDestroy(false)}
                    confirmValue={experience.experience_name}
                />
            )}
        </Accordion>
    );
};

export default ArchivedExperienceRow;
