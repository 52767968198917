import { Button, Col, Modal, Row } from "react-bootstrap";
import React from "react";
import { useLocalizedString } from "modules/client/localization";
import "./disconnected-modal.scss";

const DisconnectedModal: React.FC = () => {
    const localized = useLocalizedString();

    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>{localized("vdpRenderer_sessionDisconnectedHeader")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="disconnected-modal-body">
                    <Col>{localized("vpdRenderer_inactiveDisconnectedBody")}</Col>
                </Row>
                <Row className="disconnected-modal-button-container">
                    <Col md="auto">
                        <Button onClick={() => window.location.reload()}>{localized("vdpRenderer_refresh")}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default DisconnectedModal;
