import { filter, find, groupBy, keyBy, partition, sortBy, values } from "lodash-es";
import { DragAndDropCardDropped, DragAndDropCardPickedUp } from "modules/shared/session-events";
import { SessionState } from "modules/shared/types";
import { updateStepStates } from "modules/utils";
import { getStepScript } from "modules/shared/selectors/step-definition/generic/getStepScript";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import { CardPositionState } from "./types";

function updateCardPosition(
    positions: CardPositionState[],
    cardId: string,
    updater: (card: CardPositionState) => CardPositionState,
): CardPositionState[] {
    const positionLookup = keyBy(positions, (cps) => cps.cardId);
    const cardPosition = positionLookup[cardId];

    if (!cardPosition) {
        throw new Error(`Received drag event for unknown card with id "${cardId}"`);
    }

    const updatedPosition = updater(cardPosition);

    const updatedPositions = {
        ...positionLookup,
    };
    updatedPositions[cardId] = updatedPosition;

    return values(updatedPositions);
}

export const DRAG_AND_DROP__CARD_PICKED_UP = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: DragAndDropCardPickedUp): boolean => {
        const stepState = getStepState("DRAG_AND_DROP", state);

        if (!stepState?.persistent) return false;
        const script = getStepScript("dragAndDropStep", state, state.currentStep);

        if (!script) {
            return false;
        }

        const cardPosition = stepState.persistent.cardPositionState.find(
            (c) => c.cardId === payload.cardId && c.version === payload.version,
        );

        if (!cardPosition) return false;

        if (cardPosition?.heldByParticipant === null) return true;

        if (cardPosition?.heldByParticipant) {
            const currentHolder = state.participants.find((p) => p.id === cardPosition.heldByParticipant);
            if (!currentHolder) {
                return true;
            }
        }

        return false;
    },
    reducer: (state: SessionState, payload: DragAndDropCardPickedUp): SessionState => {
        const script = getStepScript("dragAndDropStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("DRAG_AND_DROP", state, state.currentStep, (stepState) => {
            const updatedPositions = updateCardPosition(
                stepState.persistent.cardPositionState,
                payload.cardId,
                (card: CardPositionState) => {
                    return {
                        ...card,
                        heldByParticipant: payload.participantId,
                        version: payload.version + 1,
                    };
                },
            );

            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardPositionState: updatedPositions,
                },
            };
        });

        return {
            ...state,
            stepStates,
        };
    },
};

export const DRAG_AND_DROP__CARD_DROPPED = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: DragAndDropCardDropped): boolean => {
        const script = getStepScript("dragAndDropStep", state, state.currentStep);

        if (!script) {
            return false;
        }

        const stepState = getStepState("DRAG_AND_DROP", state);

        if (!stepState?.persistent) return false;

        const cardPosition = stepState.persistent.cardPositionState?.find(
            (c) => c.cardId === payload.cardId && c.version === payload.version,
        );

        if (!cardPosition) return false;

        const heldByParticipant = cardPosition?.heldByParticipant === payload.participantId;

        const columnHasCard = stepState.persistent.cardPositionState?.find(
            // @todo: need verision?
            (c) => c.columnId === payload.destinationColumn,
        );

        const unknownColumn = payload.destinationIndex > 0 && !columnHasCard;

        if (unknownColumn) {
            return false;
        }

        return heldByParticipant;
    },
    reducer: (state: SessionState, payload: DragAndDropCardDropped): SessionState => {
        const script = getStepScript("dragAndDropStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("DRAG_AND_DROP", state, state.currentStep, (stepState) => {
            const cardPosition = find(stepState.persistent.cardPositionState, (cps) => cps.cardId === payload.cardId);
            if (!cardPosition) {
                throw new Error(`Received drag event for unknown card with id "${payload.cardId}"`);
            }

            const columns = groupBy(stepState.persistent.cardPositionState, (cps) => cps.columnId);

            const otherCards = stepState.persistent.cardPositionState.filter(
                (cps) =>
                    !cps.columnId ||
                    (cps.columnId != payload.sourceColumn && cps.columnId != payload.destinationColumn),
            );

            const updatedCardPosition = {
                ...cardPosition,
                version: cardPosition.version + 1,
                heldByParticipant: null,
                columnId: payload.destinationColumn,
                index: payload.destinationIndex,
            };

            // mks 2020-08-25 - Ugh, sorry. This got really nasty. I feel
            // like this should be able to be waaay cleaner. It's not nasty
            // for any particular reason - I just didn't have enough coffee
            // to figure out how to know how to adjust our indexes to leave
            // room in the right spot to drop the card. If you can make it
            // better... please do!

            let destinationColumn: CardPositionState[] | null;
            const sortedDest = sortBy(columns[payload.destinationColumn], "index");
            if (payload.destinationIndex == 0) {
                destinationColumn = [
                    updatedCardPosition,
                    ...filter(sortedDest, (cps) => cps.cardId !== payload.cardId),
                ];
            } else if (payload.destinationIndex == columns[payload.destinationColumn].length) {
                destinationColumn = [
                    ...filter(sortedDest, (cps) => cps.cardId !== payload.cardId),
                    updatedCardPosition,
                ];
            } else {
                const [before, after] = partition(sortedDest, (cps) => {
                    if (cardPosition.columnId === "input-row") {
                        return cps.index! < payload.destinationIndex;
                    } else if (
                        cardPosition.index != null &&
                        cardPosition.index < payload.destinationIndex &&
                        cps.columnId === payload.destinationColumn
                    ) {
                        return cps.index! <= payload.destinationIndex;
                    } else {
                        return cps.index! < payload.destinationIndex;
                    }
                });
                destinationColumn = [
                    ...filter(before, (cps) => cps.cardId !== payload.cardId),
                    updatedCardPosition,
                    ...filter(after, (cps) => cps.cardId !== payload.cardId),
                ];
            }
            const sortedDestColumn = destinationColumn.map((cps, index) =>
                cps.index == index ? cps : { ...cps, index },
            );

            const sourceColumn = (
                payload.sourceColumn === payload.destinationColumn
                    ? []
                    : filter(columns[payload.sourceColumn], (cps) => cps.cardId !== payload.cardId)
            ).map((cps, index) => (cps.index === index ? cps : { ...cps, index }));

            const allUpdatedCardPositions = [...sortedDestColumn, ...sourceColumn, ...otherCards];
            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardPositionState: allUpdatedCardPositions as CardPositionState[],
                },
            };
        });
        return {
            ...state,
            stepStates,
        };
    },
};

export const DRAG_AND_DROP__REVEAL_CORRECTNESS = {
    predictable: true,
    checkIfApplicable: (state: SessionState): boolean => {
        const stepState = getStepState("DRAG_AND_DROP", state);

        if (!stepState?.persistent) return false;

        return (
            !stepState.persistent.cardCorrectnessRevealed &&
            (!stepState.persistent.cardPositionStateOnReveal ||
                stepState.persistent.cardPositionStateOnReveal.length === 0)
        );
    },
    reducer: (state: SessionState): SessionState => {
        const script = getStepScript("dragAndDropStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("DRAG_AND_DROP", state, state.currentStep, (stepState) => {
            const initialPlacements = stepState.persistent.cardPositionState.slice();
            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardCorrectnessRevealed: true,
                    cardPositionStateOnReveal: initialPlacements,
                },
            };
        });

        return {
            ...state,
            stepStates,
        };
    },
};
