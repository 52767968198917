import { addDays, formatISO } from "date-fns";
import { ApplicationState } from "modules/client/application-state";
import { createSessionsRequestBody } from "modules/client/utils";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { BsCheck2, BsX } from "react-icons/bs";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Session } from "./types";

type ConnectedState = "TESTING" | "SUCCESS" | "FAILURE";

const ConnectionTest: React.FC = () => {
    const [socketWorking, setSocketWorking] = useState<ConnectedState>("TESTING");
    const [youtubeWorking, setYoutubeWorking] = useState<ConnectedState>("TESTING");
    const [vimeoWorking, setVimeoWorking] = useState<ConnectedState>("TESTING");
    const [s3Working, setS3Working] = useState<ConnectedState>("TESTING");
    const [contentfulWorking, setContentfulWorking] = useState<ConnectedState>("TESTING");

    return (
        <Container style={{ textAlign: "center", position: "relative", top: "25%" }}>
            <SocketTest stateChangeHandler={setSocketWorking} socketWorking={socketWorking} />
            <VideoTest url="https://www.youtube.com/watch?v=6g-i_kMn-Io" stateChangeHandler={setYoutubeWorking} />
            <VideoTest url="https://vimeo.com/708282549" stateChangeHandler={setVimeoWorking} />
            <ImageTest
                url="https://virtual-dialogue.s3.amazonaws.com/resources/images/root-logo-white.png"
                stateChangeHandler={setS3Working}
            />

            {CONTENTFUL_IMG_URL && <ImageTest url={CONTENTFUL_IMG_URL} stateChangeHandler={setContentfulWorking} />}

            <ConnectionTestRow service="Socket Server Connection" connectedState={socketWorking} />
            <ConnectionTestRow service="YouTube" connectedState={youtubeWorking} />
            <ConnectionTestRow service="Vimeo" connectedState={vimeoWorking} />
            <ConnectionTestRow service="S3" connectedState={s3Working} />
            <ConnectionTestRow service="Contentful" connectedState={contentfulWorking} />

            {socketWorking === "FAILURE" && (
                <p>
                    There is a websocket connection issue. Please ensure secure websocket connections are allowed and
                    unmodified on the browser and through the network. See Allowable Domains guide.
                </p>
            )}
            {(youtubeWorking === "FAILURE" || vimeoWorking === "FAILURE") && (
                <p>
                    There was an issue connecting to the video CDN. Please ensure the video CDN is allowed. See
                    Allowable Domains guide.
                </p>
            )}
            {s3Working === "FAILURE" && (
                <p>
                    There is an issue connecting to the Map Assets. Please ensure the Map Assets are allowed. See
                    Allowable Domains guide.
                </p>
            )}
            {contentfulWorking === "FAILURE" && (
                <p>
                    There is an issue connecting to the Contentful CDN. Please ensure the Contentful CDN is allowed. See
                    Allowable Domains guide.
                </p>
            )}
        </Container>
    );
};

type RowProps = {
    service: string;
    connectedState: ConnectedState;
};

const ConnectionTestRow: React.FC<RowProps> = (props) => {
    let connectedIcon;

    if (props.connectedState === "TESTING") {
        connectedIcon = <Spinner animation="border" />;
    } else if (props.connectedState === "SUCCESS") {
        connectedIcon = <BsCheck2 color="green" size="36px" />;
    } else if (props.connectedState === "FAILURE") {
        connectedIcon = <BsX color="red" size="36px" />;
    }

    return (
        <Row>
            <Col>{props.service}</Col>
            <Col data-testid="connected-icon">{connectedIcon}</Col>
        </Row>
    );
};

type SiteTestProps = {
    url: string;
    stateChangeHandler: (newState: ConnectedState) => void;
};

const VideoTest: React.FC<SiteTestProps> = (props) => {
    return (
        <ReactPlayer
            url={props.url}
            volume={0}
            muted={true}
            controls={false}
            onReady={() => props.stateChangeHandler("SUCCESS")}
            onError={() => props.stateChangeHandler("FAILURE")}
            style={{
                display: "none",
            }}
        />
    );
};

const ImageTest: React.FC<SiteTestProps> = (props) => {
    return (
        <Image
            src={props.url}
            onLoad={() => props.stateChangeHandler("SUCCESS")}
            onError={() => props.stateChangeHandler("FAILURE")}
            style={{
                display: "none",
            }}
        />
    );
};

type SocketTestProps = {
    stateChangeHandler: (newState: ConnectedState) => void;
    socketWorking: ConnectedState;
};

const SocketTest: React.FC<SocketTestProps> = (props) => {
    const { stateChangeHandler, socketWorking } = props;
    const { uuid } = useParams();
    const [sessionId, setSessionId] = useState<string | undefined>(undefined);
    useEffect(() => {
        async function createSession() {
            const closesAt = addDays(new Date(), 1);
            closesAt.setHours(0, 0, 0, 0);
            const response = await fetch(`/api/v1/experiences/${uuid}/sessions`, {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: createSessionsRequestBody({
                    number: 1,
                    opens_at: formatISO(new Date()),
                    closes_at: formatISO(closesAt),
                    language_code: "en-US",
                    data_collection: false,
                    isPreview: true,
                }),
            });

            if (response.ok) {
                const body = (await response.json()) as Session[];
                setSessionId(body[0].uuid);
            } else {
                stateChangeHandler("FAILURE");
            }
        }
        void createSession();
    }, [stateChangeHandler, uuid]);

    const session = useSelector((state: ApplicationState) => state.session);
    const dispatch = useDispatch();

    useEffect(() => {
        if (sessionId) {
            if (session.state === "INITIAL") {
                dispatch({
                    type: "CONNECT_TO_SESSION",
                    sessionId: sessionId,
                });
            } else if (session?.state === "READY" && socketWorking !== "FAILURE") {
                stateChangeHandler("SUCCESS");
            } else if (session?.state === "ERROR") {
                stateChangeHandler("FAILURE");
            }
        }
    }, [sessionId, session, dispatch, stateChangeHandler, socketWorking]);

    return <></>;
};

export default ConnectionTest;
