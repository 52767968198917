import { RemoveFacilitator, SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = Pick<RemoveFacilitator, "participantId">;

const createRemoveFacilitator = (args: Args): SessionEvent => {
    return {
        type: "REMOVE_FACILITATOR",
        uuid: uuidv4(),
        participantId: args.participantId,
    };
};

export default createRemoveFacilitator;
