import { SessionState } from "../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { assetMarkupIconsSelector } from "./assetMarkupIconsSelector";
import { createAssetMarkupIconsLimitSelector } from "./createAssetMarkupIconsLimitSelector";

export function participantAssetMarkupLimitSelector(participantId: string | null): (s: SessionState) => number {
    return createSelector(
        [assetMarkupIconsSelector, createAssetMarkupIconsLimitSelector()],
        (markupIcons, defaultLimit) => {
            if (markupIcons) {
                const participantMarkupIcons = markupIcons.filter((icons) => icons.participantId === participantId);
                const currentParticipantLimit = defaultLimit && defaultLimit - participantMarkupIcons.length;
                if (currentParticipantLimit !== undefined && currentParticipantLimit <= 0) {
                    return 0;
                }
                return currentParticipantLimit;
            } else {
                return defaultLimit;
            }
        },
    );
}
