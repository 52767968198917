import { SessionEvent } from "modules/shared/session-events";
import { v4 } from "uuid";
import { Coordinates, Icon } from "../../shared/activity/Markup/types";

type Args = {
    icon: Icon;
    placerId: string;
    coordinates: Coordinates;
};

const createPlacedMarkupIcon = (args: Args): SessionEvent => {
    return {
        type: "PLACED_MARKUP_ICON",
        uuid: v4(),
        icon: args.icon,
        placerId: args.placerId,
        coordinates: args.coordinates,
    };
};

export default createPlacedMarkupIcon;
