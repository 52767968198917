import { Action } from "modules/client/actions-types";
import createSendSessionReportRequest from "modules/client/event-factories/session-report/createSendSessionReportRequest";
import { useLocalizedString } from "modules/client/localization";
import { createReportErrorSelector, createReportStatusSelector } from "modules/client/selectors/session-report";
import {
    ReportStatus,
    SelectedFilterState,
    MultipleChoiceBasicInfoAggregatedFilters,
} from "modules/client/session-report-state";
import React, { Dispatch, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

type Props = {
    experienceUuid: string;
};

type FilterFields = MultipleChoiceBasicInfoAggregatedFilters & Pick<SelectedFilterState, "useSeedData">;

const MultipleChoiceBasicInfoAggregatedFilterForm: React.FC<Props> = ({ experienceUuid }) => {
    const reportStatus = useSelector(createReportStatusSelector());
    const reportError = useSelector(createReportErrorSelector());
    const dispatch = useDispatch<Dispatch<Action>>();
    const editingDisabled = reportStatus === ReportStatus.InProgress;
    const localized = useLocalizedString();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FilterFields>({});

    useEffect(() => {
        if (reportStatus === ReportStatus.Error && !!reportError && !!reportError.fieldErrors) {
            Object.entries(reportError.fieldErrors).forEach(([fieldName, errorMessages]) => {
                const errorMessage = errorMessages.join(" \n");
                setError(fieldName as keyof FilterFields, { message: errorMessage });
            });
        }
    }, [reportError, reportStatus, setError]);

    const onSubmit = handleSubmit((filterValues) => {
        if (!!experienceUuid) {
            const filterOptions: MultipleChoiceBasicInfoAggregatedFilters = {
                ...filterValues,
            };
            const useSeedData = filterValues.useSeedData;
            dispatch(
                createSendSessionReportRequest(experienceUuid, {
                    type: "MultipleChoiceBasicInfoAggregated",
                    options: filterOptions,
                    useSeedData,
                }),
            );
        }
    });

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group controlId="startDate">
                        <Form.Label>{localized("multipleChoiceReport_basicInfoAggregated_startDate")}</Form.Label>
                        <Form.Control
                            {...register("startDate")}
                            type="date"
                            disabled={editingDisabled}
                            isInvalid={!!errors.startDate}
                        />
                        {!!errors.startDate && (
                            <Form.Text as="span" className="text-danger">
                                {errors.startDate.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="endDate">
                        <Form.Label>{localized("multipleChoiceReport_basicInfoAggregated_endDate")}</Form.Label>
                        <Form.Control
                            {...register("endDate")}
                            type="date"
                            disabled={editingDisabled}
                            isInvalid={!!errors.endDate}
                        />
                        {!!errors.endDate && (
                            <Form.Text as="span" className="text-danger">
                                {errors.endDate.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="useSeedData">
                        <Form.Label>{localized("multipleChoiceReport_basicInfoAggregated_useSeedData")}</Form.Label>
                        <Form.Check
                            {...register("useSeedData")}
                            aria-describedby="useSeedDataHelp"
                            type="switch"
                            disabled={editingDisabled}
                            isInvalid={!!errors.useSeedData}
                        />
                        <Form.Text id="useSeedDataHelp">
                            {localized("multipleChoiceReport_basicInfoAggregated_useSeedData_help")}
                        </Form.Text>
                        {!!errors.useSeedData && (
                            <Form.Text as="span" className="text-danger">
                                {errors.useSeedData.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="activityId">
                        <Form.Label>{localized("multipleChoiceReport_basicInfoAggregated_activityId")}</Form.Label>
                        <Form.Control
                            {...register("activityId")}
                            aria-describedby="activityId"
                            type="input"
                            disabled={editingDisabled}
                            isInvalid={!!errors.activityId}
                        />
                        {!!errors.activityId && (
                            <Form.Text as="span" className="text-danger">
                                {errors.activityId.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Button type="submit">{localized("sessionReport_submitButton")}</Button>
        </Form>
    );
};

export default MultipleChoiceBasicInfoAggregatedFilterForm;
