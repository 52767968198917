import L from "leaflet";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { MapState } from "modules/shared/types";
import { calculateBoundsFor, focusAreasAreEqual, getPixelSize } from "./utils";

type FocusPixelAreaProp = MapState | null;

interface DialoguePlatformMapMixin {
    setFocusPixelArea: (value: FocusPixelAreaProp) => this;
    unsetFocusPixelArea: () => this;
    getFocusPixelArea: () => FocusPixelAreaProp;
}

export class DialoguePlatformMap extends L.Map implements DialoguePlatformMapMixin {
    _focusPixelArea: FocusPixelAreaProp;
    metadata: LeafletMapMetadata;
    constructor(metadata: LeafletMapMetadata, ...[element, options]: Parameters<typeof L.map>) {
        super(element, options);
        this._focusPixelArea = null;
        this.metadata = metadata;

        // unset cached focus pixel area if we adjust our view ever
    }

    flyToPixelArea(focusPixelArea: MapState): this {
        const currentFocusValue = this.getFocusPixelArea();
        const currentFocus = !!currentFocusValue ? { ...currentFocusValue } : null;
        const pixelSize = getPixelSize(this.metadata);
        const latLngFocus: L.LatLngBounds = calculateBoundsFor(pixelSize, focusPixelArea);
        this.options.minZoom = 0;
        const futureMinZoom = this.getBoundsZoom(latLngFocus);

        this.navigateToBounds(latLngFocus, currentFocus, focusPixelArea);

        // if the user navigates away from the tab, we want to ensure that the map is focused on the correct area
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.navigateToBounds(latLngFocus, currentFocus, focusPixelArea);
            }
        });

        this.options.maxBounds = latLngFocus;
        this.options.minZoom = futureMinZoom;
        this.setFocusPixelArea(focusPixelArea);
        return this;
    }

    navigateToBounds(latLngFocus: L.LatLngBounds, currentFocus: MapState | null, focusPixelArea: MapState) {
    // if we are already focusing on the specified pixel area, don't attempt to navigate to it again
        if (!focusAreasAreEqual(currentFocus, focusPixelArea)) {
            this.flyToBounds(latLngFocus);
        }
    }

    setView(...args: Parameters<typeof L.Map.prototype.setView>): this {
        this.unsetFocusPixelArea();
        return super.setView(...args);
    }

    setFocusPixelArea(value: FocusPixelAreaProp): this {
        this._focusPixelArea = value;
        return this;
    }

    unsetFocusPixelArea(): this {
        return this.setFocusPixelArea(null);
    }

    getFocusPixelArea(): FocusPixelAreaProp {
        return this._focusPixelArea;
    }
}

// export const DialoguePlatformMap = L.Map.extend(dialoguePlatformMapMixin) as DialoguePlatformMapType;
export function dialoguePlatformMap(
    metadata: LeafletMapMetadata,
    ...[element, options]: Parameters<typeof L.map>
): DialoguePlatformMap {
    return new DialoguePlatformMap(metadata, element, options);
}
