import { EventWithResolution, SessionEvent } from "./session-events";
import { SessionState } from "./types";

/**
 * The messages sent / received that manipulate the state
 */
export type InitState = {
    type: "INIT_STATE";
    state: SessionState;
};

export type SessionMessage = {
    type: "SESSION_MESSAGE";
    payload: { event: SessionEvent; resolution: null };
};

export type InitFailed = {
    type: "INIT_FAILED";
    payload: { message: string };
};

export type SessionMessageWithResolution = {
    type: "SESSION_MESSAGE";
    payload: EventWithResolution;
};

export type HeartbeatMessage = {
    type: "HEARTBEAT";
};

export type WebsocketMessage = InitState | HeartbeatMessage | SessionMessage | InitFailed;
export type WebsocketMessageWithResolution = InitState | HeartbeatMessage | InitFailed | SessionMessageWithResolution;

export const SOCKET_IO_EVENT_NAME = "SESSION_MESSAGE";
