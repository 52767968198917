import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { MainStepAddress, SessionState } from "modules/shared/types";
import { ApplicationState } from "modules/client/application-state";
import { firstInPersonStepAddress } from "modules/shared/selectors/step-address/stepIsFirst";
import { inPersonlastStepAddressSelector } from "modules/shared/selectors/step-address/lastStepAddressSelector";
import { isFirstInPersonStep } from "modules/shared/selectors/step-address/stepIsFirst";

const InPersonNavigation = () => {
    const state = useSelector(
        (state: ApplicationState): boolean | SessionState =>
            state.session.state === "READY" && state.session.effectiveState,
    ) as SessionState;

    const currentAddress = currentStepAddress(state) as MainStepAddress;
    const isFirstStep = isFirstInPersonStep(currentAddress, firstInPersonStepAddress(state));
    const { sectionKey: lastSectionKey, stepKey: lastStepKey } = inPersonlastStepAddressSelector(state);
    const { sectionKey: currentSectionKey, stepKey: currentStepKey } = currentAddress;
    const isLastStep = currentSectionKey === lastSectionKey && currentStepKey === lastStepKey;
    const exitStep = "exit";

    const dispatch = useDispatch();

    const nextStepHandler = () => {
        dispatch({
            type: "IN_PERSON_GOTO_STEP",
            direction: "NEXT",
        });
    };

    const prevStepHandler = () => {
        dispatch({
            type: "IN_PERSON_GOTO_STEP",
            direction: "PREVIOUS",
        });
    };

    const lastStepHandler = () => {
        dispatch({
            type: "IN_PERSON_EXIT",
            currentStep: exitStep,
        });
    };

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
                {isFirstStep ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>You are at the first step</Tooltip>}>
                        <div className="d-inline-block">
                            <Button
                                variant="secondary"
                                onClick={prevStepHandler}
                                disabled
                                style={{ pointerEvents: "none", opacity: 0.65 }}
                            >
                                PREV
                            </Button>
                        </div>
                    </OverlayTrigger>
                ) : (
                    <Button variant="secondary" onClick={prevStepHandler}>
                        PREV
                    </Button>
                )}
                {isLastStep ? (
                    <>
                        <OverlayTrigger placement="top" overlay={<Tooltip>You are at the last step</Tooltip>}>
                            <div className="d-inline-block">
                                <Button variant="primary" onClick={lastStepHandler}>
                                    End Session
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </>
                ) : (
                    <Button variant="primary" onClick={nextStepHandler}>
                        NEXT
                    </Button>
                )}
            </div>
        </div>
    );
};

export default InPersonNavigation;
