import { FaSyncAlt } from "react-icons/fa";
import classNames from "classnames";
import { useLocalizedString } from "modules/client/localization";
import React, { useEffect, useRef } from "react";
import { Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ActivityModal from "../ActivityModal";
import { Card } from "../../shared/content-types";
import FlipCard from "../ActivityView/RevealCardActivity/FlipCard";
import "./CardModal.scss";
import { useSessionSelector } from "../SessionRenderer/context";
import { createActivityModalSelector } from "modules/shared/selectors/step-definition/generic/createActivityModalSelector";
import { createCurrentAppearanceOptionsSelector } from "modules/shared/selectors/step-definition/generic/createCurrentAppearanceOptionsSelector";

type Props = {
    card: Card;
    isModalVisible: boolean;
    isNavigationDisabled: boolean;
    horizontalAlignment: "center" | "left" | "right";
    handleCloseModal: (cardId: string) => void;
    isCardFlipped: boolean;
    handleFlipCardToBack: (cardId: string) => void;
    handleFlipCardToFront: (cardId: string) => void;
    showRotateNavigator: () => void;
    backgroundHidden: boolean;
};

const CardModal: React.FC<Props> = (props) => {
    // Difficult accessing Modal component via ref so we're overriding the inner components' styles
    const headerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const localized = useLocalizedString();

    const activityModalOptions = useSessionSelector(createActivityModalSelector());

    const appearance = useSessionSelector(createCurrentAppearanceOptionsSelector());
    const hasCardShadow = !!appearance.cardDropShadow;

    // if the card is using the body as its title, do not show the title
    const cardTitle = props.card.useContentAsTitle ? null : props.card.title;

    let dialogClassName: string;
    switch (props.horizontalAlignment) {
        case "left":
            dialogClassName = "card-modal--left";
            break;
        case "right":
            dialogClassName = "card-modal--right";
            break;
        case "center":
        default:
            dialogClassName = "card-modal--center";
            break;
    }

    const modalClassName = classNames({
        "hidden-background": props.card.hideBackground || props.backgroundHidden,
    });

    const modalBodyClassName = classNames({
        "no-title": !cardTitle,
        "hidden-background": props.card.hideBackground || props.backgroundHidden,
        "card-modal-hidden-background-alignment": props.card.hideBackground || props.backgroundHidden,
        "drop-shadow": hasCardShadow,
    });

    const additionalCssProperties = props.card.additionalCssProperties;

    const cardHasBack = !!props.card.detailsBack;

    useEffect(() => {
        const header = headerRef.current;
        if (header) {
            header.style.cssText = additionalCssProperties;
        }
    }, [additionalCssProperties]);
    useEffect(() => {
        const body = bodyRef.current;
        if (body) {
            body.style.cssText = additionalCssProperties;
        }
    }, [additionalCssProperties]);

    return (
        <>
            <ActivityModal
                dialogClassName={dialogClassName}
                size="lg"
                centered
                show={props.isModalVisible}
                onHide={() => props.handleCloseModal(props.card.id)}
                {...activityModalOptions}
            >
                <Modal.Header ref={headerRef} className={modalClassName}>
                    {!props.isNavigationDisabled && (
                        <Row className="card-navigation-container gx-0">
                            {cardHasBack && (
                                <Button
                                    className="card-flip-btn"
                                    onClick={() => {
                                        props.isCardFlipped
                                            ? props.handleFlipCardToFront(props.card.id)
                                            : props.handleFlipCardToBack(props.card.id);
                                    }}
                                >
                                    <FaSyncAlt />
                                </Button>
                            )}
                            <Button className="card-close-btn" onClick={() => props.handleCloseModal(props.card.id)}>
                                {localized("vdpActivity_closeModal")}
                            </Button>
                        </Row>
                    )}
                </Modal.Header>

                <Modal.Body ref={bodyRef} className={modalBodyClassName}>
                    {cardHasBack ? (
                        <FlipCard
                            card={props.card}
                            isCardFlipped={props.isCardFlipped}
                            handleFlipCardToBack={props.handleFlipCardToBack}
                            handleFlipCardToFront={props.handleFlipCardToFront}
                            backgroundHiddenGlobally={props.backgroundHidden}
                        />
                    ) : (
                        <FlipCard
                            card={props.card}
                            isCardFlipped={props.isCardFlipped}
                            handleFlipCardToBack={() => null}
                            handleFlipCardToFront={() => null}
                            backgroundHiddenGlobally={props.backgroundHidden}
                        />
                    )}
                </Modal.Body>
            </ActivityModal>
        </>
    );
};

export default CardModal;
