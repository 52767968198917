import { uniq } from "lodash-es";
import { WaitForResolution } from "../actions-types";
import { ApplicationState } from "../application-state";

export default (state: ApplicationState, event: WaitForResolution): ApplicationState => {
    const events = state.eventsAwaitingResolutions ?? [];
    const updatedEvents = [...events, event.uuid];
    const eventsAwaitingResolutions = uniq(updatedEvents);

    return {
        ...state,
        eventsAwaitingResolutions,
    };
};
