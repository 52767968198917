import { useLocalizedString } from "modules/client/localization";
import { StepAddress, StepIntroAddress } from "modules/shared/types";
import React from "react";
import { Button, Col, OverlayTrigger, Placeholder, Tooltip } from "react-bootstrap";
import { MdLock } from "react-icons/md";
import "./sidebar.scss";

type NavigationPanelProps = {
    goToPrevious: () => void;
    goToNext: () => void;
    startExperience: () => void;
    skipIntro: () => void;
    isNavigator: boolean;
    currentStep: StepAddress | StepIntroAddress;
    disablePrevious: boolean;
    disableNext: boolean;
    showSkip: boolean;
    inWelcomeMode: boolean;
};

export const NavigationPanel: React.FC<NavigationPanelProps> = (props) => {
    const localized = useLocalizedString();
    return (
        <nav className="navigation">
            <div className="navigation-container">
                <Col className="navigation-buttons-col">
                    <Button
                        variant={"outline-primary"}
                        className="navigation-btn navigation-prev"
                        onClick={props.goToPrevious}
                        disabled={props.disablePrevious}
                        aria-description={localized("sidebar_previousButtonDescription")}
                    >
                        {localized("sidebar_previousButton")}
                    </Button>
                    {props.currentStep !== "exit" && (
                        <Button
                            className="navigation-btn navigation-next"
                            onClick={props.inWelcomeMode ? props.startExperience : props.goToNext}
                            disabled={props.disableNext}
                            aria-description={localized("sidebar_nextButtonDescription")}
                        >
                            {localized("sidebar_nextButton")}
                        </Button>
                    )}
                </Col>
                <Col className="skip-button-col">
                    {props.showSkip && (
                        <Button
                            variant="outline-primary"
                            className="navigation-btn skip-button"
                            onClick={props.skipIntro}
                            aria-description={localized("sidebar_skipButtonDescription")}
                        >
                            {localized("vdpIntro_skipShort")}
                        </Button>
                    )}
                </Col>
            </div>
            {props.isNavigator ? (
                <Placeholder />
            ) : (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="lock-tooltip">{localized("sidebar_lockTooltip")}</Tooltip>}
                >
                    <div>
                        <MdLock size={15} className="navigation-lock" />
                    </div>
                </OverlayTrigger>
            )}
        </nav>
    );
};
