import { ApplicationState, SessionStateContainer } from "modules/client/application-state";
import { useLocalizedString, useClientLocale } from "modules/client/localization";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { useFetch } from "modules/hooks";
import { currentSectionAddress } from "modules/shared/selectors/step-address/currentSectionAddress";
import { currentStepNumber } from "modules/shared/selectors/step-address/currentStepNumber";
import { currentNavigatorSelector } from "modules/shared/selectors/participants/currentNavigatorSelector";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { getCurrentMap } from "modules/shared/selectors/map/getCurrentMap";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { createCustomPageTitleSelector } from "modules/shared/selectors/experience-definition/createCustomPageTitleSelector";
import { createCustomFaviconSelector } from "modules/shared/selectors/experience-definition/createCustomFaviconSelector";
import { inIntroSequence } from "modules/shared/selectors/intro-sequence/inIntroSequence";
import { sessionStarted } from "modules/shared/selectors/navigation-state/sessionStarted";
import { default as React, useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Confetti from "react-confetti";
import { useSelector } from "react-redux";
import createRotateNavigator from "../../client/event-factories/createRotateNavigator";
import { StepDefinition } from "../../shared/content-types";
import ErrorModal from "../ErrorModal";
import FullPageLoading from "../FullPageLoading";
import IntroSequence from "../IntroTutorial";
import SessionLeafletMap from "../LeafletMap/SessionLeafletMap";
import PreviewModeToolbar from "../PreviewModeToolbar";
import Sidebar from "../Sidebar";
import ToastManager from "../Toast";
import NameEntrySequence from "../NameEntryScreen";
import ClaimFacilitator from "../NameEntryScreen/ClaimFacilitator";
import SmallScreenWarning from "../SmallScreenWarning";
import {
    SessionRendererContext,
    useSession,
    useSessionDispatch,
    useSessionParticipantId,
    useSessionSelector,
} from "./context";
import Heartbeat from "./heartbeat";
import InactivityPrompt from "./InactivityPrompt";
import ReconnectingAttemptOverlay from "./ReconnectingAttemptOverlay";
import "./session-renderer.scss";
import SessionContainer from "./SessionContainer";
import { CoordinatePreviewMode, PreviewFocusArea, PreviewHotspot } from "modules/shared/types";
import PrivacyPolicyBanner from "../PrivacyPolicyBanner/PrivacyPolicyBanner";
import createParticipantLocaleChanged from "modules/client/event-factories/createParticipantLocaleChanged";

export type Props = {
    selector: (state: ApplicationState) => SessionStateContainer;
    inPreviewMode: boolean;
};

const confetti = (currentStep: StepDefinition) => {
    if (currentStep.options && currentStep.options.hasCelebration) {
        const key = `step-${currentStep.id}`;
        return <Confetti key={key} numberOfPieces={2000} recycle={false} tweenDuration={20000} />;
    } else {
        return <></>;
    }
};

const SessionRenderInternal: React.FC<{
    sessionId: string;
    inPreviewMode: boolean;
    mapMetadata: LeafletMapMetadata;
    isIdle: boolean;
}> = (props) => {
    const localized = useLocalizedString();
    const clientLocale = useClientLocale();
    const participantId = useSessionParticipantId();
    const dispatch = useSessionDispatch();
    const currentStepDefinition = useSessionSelector(createGenericStepDefinitionSelector());
    const currentSection = useSessionSelector(currentSectionAddress);
    const currentStep = useSessionSelector(currentStepNumber);

    const containerRef = useRef<HTMLDivElement>(null);

    const leafletSizeRef = useRef<HTMLDivElement>(null);

    const { state, oldParticipantId } = useSession();

    const reconnecting = state === "RECONNECTING" || state === "RECONNECTED_WAITING_FOR_REINCARNATION";

    const mapLocation = useSessionSelector(getCurrentMap);

    useEffect(() => {
        if (participantId) {
            dispatch(createParticipantLocaleChanged({ participantId, languageCode: clientLocale }));
        }
    }, [clientLocale, participantId, dispatch]);

    const [previewHotspots, setPreviewHotspots] = useState<PreviewHotspot[]>([]);
    const addPreviewHotspot = useCallback(
        (hotspot: PreviewHotspot) => {
            setPreviewHotspots([...previewHotspots, hotspot]);
        },
        [previewHotspots],
    );

    const removePreviewHotspot = useCallback(
        (id: number) => {
            const filteredPreviewHotspots: PreviewHotspot[] = previewHotspots.filter(
                (previewHotspot) => previewHotspot.id !== id,
            );
            setPreviewHotspots(filteredPreviewHotspots);
        },
        [previewHotspots],
    );

    const movePreviewHotspot = useCallback(
        (movedHotspot: PreviewHotspot) => {
            const filteredPreviewHotspots = previewHotspots.filter(
                (previewHotspot) => previewHotspot.id !== movedHotspot.id,
            );
            setPreviewHotspots([...filteredPreviewHotspots, movedHotspot]);
        },
        [previewHotspots],
    );

    const clearPreviewHotspots = useCallback(() => {
        setPreviewHotspots([]);
    }, []);

    const [previewFocusAreas, setPreviewFocusAreas] = useState<PreviewFocusArea[]>([]);
    const addPreviewFocusArea = useCallback(
        (focusArea: PreviewFocusArea) => {
            setPreviewFocusAreas([...previewFocusAreas, focusArea]);
        },
        [previewFocusAreas],
    );

    const removePreviewFocusArea = useCallback(
        (id: number) => {
            const filteredPreviewFocusAreas = previewFocusAreas.filter((previewFocusArea) => previewFocusArea.id != id);
            setPreviewFocusAreas(filteredPreviewFocusAreas);
        },
        [previewFocusAreas],
    );

    const clearPreviewFocusAreas = useCallback(() => {
        setPreviewFocusAreas([]);
    }, []);

    const clearPreviewCoordinates = useCallback(() => {
        clearPreviewHotspots();
        clearPreviewFocusAreas();
    }, [clearPreviewHotspots, clearPreviewFocusAreas]);

    useEffect(() => {
        clearPreviewCoordinates();
        setCoordinatePreviewMode(CoordinatePreviewMode.Off);
    }, [currentStep, currentSection, clearPreviewCoordinates]);

    const currentNavigator = useSessionSelector(currentNavigatorSelector);

    const activeParticipant = useSessionSelector(createParticipantSelector(useSessionParticipantId()));
    const disconnectedParticipant = useSessionSelector(createParticipantSelector(oldParticipantId));

    const currentParticipant = disconnectedParticipant || activeParticipant;

    const hasSessionStarted = useSessionSelector(sessionStarted);

    const [isRotateNavigatorOpen, setIsRotateNavigatorOpen] = useState<boolean>(false); // Rotate navigator open state
    const handleCloseRotateNavigator = useCallback(() => {
        setIsRotateNavigatorOpen(false);
    }, []);

    const handleShowRotateNavigator = useCallback(() => {
        setIsRotateNavigatorOpen(true);
    }, []);
    const rotateNavigator = useCallback(() => {
        dispatch(
            createRotateNavigator({
                currentNavigator: currentNavigator?.id || null,
            }),
        );
    }, [dispatch, currentNavigator]);

    const favicon = useSessionSelector(createCustomFaviconSelector());
    const pageTitle = useSessionSelector(createCustomPageTitleSelector());

    useEffect(() => {
        if (!!favicon && !!favicon.fields.file) {
            const faviconElement: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
            if (!!faviconElement) {
                faviconElement.href = favicon.fields.file.url;
            }
        }

        if (!!pageTitle) {
            document.title = pageTitle;
        }
    }, []);

    const inWelcomeSequence = !(currentParticipant && currentParticipant.ready && hasSessionStarted);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isTeamHovered, setIsTeamHovered] = useState(false);
    const [isScheduleOpen, setIsScheduleOpen] = useState(false);
    const [isIconHovered, setIconHovered] = useState(false);
    const hoverHandler = useCallback((v: boolean) => {
        setIconHovered(v);
    }, []);

    const [coordinatePreviewMode, setCoordinatePreviewMode] = useState<CoordinatePreviewMode>(
        CoordinatePreviewMode.Off,
    );
    const coordinatePreviewModeHandler = useCallback(
        (mode: CoordinatePreviewMode) => setCoordinatePreviewMode(mode),
        [],
    );

    const onIntroSequence = useSessionSelector(inIntroSequence);
    return (
        <>
            {props.isIdle && <InactivityPrompt />}
            {reconnecting && <ReconnectingAttemptOverlay />}
            <ToastManager />
            <div className="session-renderer-component">
                <div ref={leafletSizeRef} className="session-content-container">
                    {props.inPreviewMode && (
                        <PreviewModeToolbar
                            inWelcomeMode={inWelcomeSequence}
                            setCoordinatePreviewMode={coordinatePreviewModeHandler}
                        />
                    )}
                    <Sidebar
                        inWelcomeMode={inWelcomeSequence}
                        isScheduleOpen={isScheduleOpen}
                        isTeamHovered={isTeamHovered}
                        inPreviewMode={props.inPreviewMode}
                        isSidebarOpen={isSidebarOpen}
                        toggleScheduleOpen={setIsScheduleOpen}
                        handleTeamHover={setIsTeamHovered}
                        handleSidebarToggle={setIsSidebarOpen}
                    />
                    {inWelcomeSequence && (
                        <>
                            <SmallScreenWarning />
                            <ClaimFacilitator inPreviewMode={props.inPreviewMode} />
                            <NameEntrySequence mapLocation={mapLocation} mapMetadata={props.mapMetadata} />
                        </>
                    )}
                    {!inWelcomeSequence && onIntroSequence && (
                        <>
                            <IntroSequence mapLocation={mapLocation} mapMetadata={props.mapMetadata} />
                        </>
                    )}
                    {!inWelcomeSequence && !onIntroSequence && (
                        <>
                            {currentStepDefinition && confetti(currentStepDefinition)}
                            <SessionContainer
                                leafletSizeRef={leafletSizeRef}
                                containerRef={containerRef}
                                showRotateNavigator={handleShowRotateNavigator}
                                iconHovered={isIconHovered}
                                isSidebarOpen={isSidebarOpen}
                                inPreviewMode={props.inPreviewMode}
                                clearPreviewCoordinates={clearPreviewCoordinates}
                                setCoordinatePreviewMode={coordinatePreviewModeHandler}
                                coordinatePreviewMode={coordinatePreviewMode}
                                onIconHovered={hoverHandler}
                            />
                            <SessionLeafletMap
                                mapMetadata={props.mapMetadata}
                                location={mapLocation}
                                coordinatePreviewMode={coordinatePreviewMode}
                                previewHotspots={previewHotspots}
                                addPreviewHotspot={addPreviewHotspot}
                                removePreviewHotspot={removePreviewHotspot}
                                movePreviewHotspot={movePreviewHotspot}
                                clearPreviewHotspots={clearPreviewHotspots}
                                previewFocusAreas={previewFocusAreas}
                                addPreviewFocusArea={addPreviewFocusArea}
                                removePreviewFocusArea={removePreviewFocusArea}
                                clearPreviewFocusAreas={clearPreviewFocusAreas}
                                setCoordinatePreviewMode={coordinatePreviewModeHandler}
                                showRotateNavigator={handleShowRotateNavigator}
                                iconHovered={isIconHovered}
                                onIconHovered={hoverHandler}
                                inPreviewMode={props.inPreviewMode}
                            />
                            <Modal show={isRotateNavigatorOpen} onHide={handleCloseRotateNavigator}>
                                <Modal.Body>
                                    <p>{localized("vdpHud_changeNavigatorText1")}</p>
                                    <p>{localized("vdpHud_changeNavigatorText2")}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={handleCloseRotateNavigator}>
                                        {localized("vdpHud_close")}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            rotateNavigator();
                                            handleCloseRotateNavigator();
                                        }}
                                    >
                                        {localized("vdpHud_accept")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                    <div
                        className={
                            isTeamHovered || isScheduleOpen
                                ? "sidebar-isolation-overlay"
                                : "sidebar-isolation-overlay out"
                        }
                    />
                    <PrivacyPolicyBanner />
                </div>
            </div>
        </>
    );
};

const ConnectedSessionRenderer: React.FC<Props> = ({ selector, inPreviewMode }) => {
    const sessionState = useSelector(selector);
    const isIdle = sessionState.state === "READY" && sessionState.isIdle;
    const localized = useLocalizedString();

    let mapLocation: string | null = null;

    if (sessionState.state === "READY") {
        mapLocation = sessionState.effectiveState.script.mapUrl;
    } else if (
        sessionState.state === "RECONNECTING" ||
        sessionState.state === "RECONNECTED_WAITING_FOR_REINCARNATION"
    ) {
        mapLocation = sessionState.serverSessionState.script.mapUrl;
    }

    const mapMetadata = useFetch<LeafletMapMetadata>(`${mapLocation}/metadata.json`);

    if (mapMetadata.state === "LOADING") {
        return <FullPageLoading />;
    }

    return (
        <SessionRendererContext selector={selector}>
            <Heartbeat selector={selector} />
            {mapMetadata.state === "DONE" && (
                <SessionRenderInternal
                    inPreviewMode={inPreviewMode}
                    sessionId={sessionState.sessionId}
                    mapMetadata={mapMetadata.data}
                    isIdle={isIdle}
                />
            )}
            {mapMetadata.state === "ERROR" && <ErrorModal message={localized("vdpRenderer_invalidMapUrl")} />}
        </SessionRendererContext>
    );
};

const SessionRenderer: React.FC<Props> = ({ selector, inPreviewMode }) => {
    const sessionState = useSelector(selector);
    const localized = useLocalizedString();

    if (sessionState.state === "ERROR") {
        const message = JSON.stringify(sessionState.error, undefined, 2);

        const errorElements = (
            <>
                <p>{localized("vdpRenderer_unexpectedError")}</p>
                <div>
                    <pre>{message}</pre>
                </div>
            </>
        );
        return <ErrorModal message={errorElements} />;
    }

    if (sessionState.state === "CONNECTING") {
        return <FullPageLoading />;
    }

    if (
        sessionState.state === "READY" ||
        sessionState.state === "RECONNECTING" ||
        sessionState.state === "RECONNECTED_WAITING_FOR_REINCARNATION"
    ) {
        return <ConnectedSessionRenderer selector={selector} inPreviewMode={inPreviewMode} />;
    }

    return <ErrorModal message={localized("vdpRenderer_unexpectedErrorMsg", sessionState.state)} />;
};

export default SessionRenderer;
