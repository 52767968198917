import { EditingSessionReportFilters } from "modules/client/actions-types/sessionReportActionTypes";
import { ApplicationState } from "modules/client/application-state";
import { ReportStatus } from "modules/client/session-report-state";

export default function setEditingReportFilters(
    state: ApplicationState,
    payload: EditingSessionReportFilters,
): ApplicationState {
    return {
        ...state,
        sessionReportState: {
            status: ReportStatus.Editing,
            filters: {
                type: payload.reportType,
                options: {},
            },
        },
    };
}
