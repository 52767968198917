import { useMsal } from "@azure/msal-react";
import { parseISO } from "date-fns";
import { sortBy } from "lodash-es";
import { callSecureEndpoint } from "modules/client/auth";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { useDateFnsLocale, useLocalizedString } from "modules/client/localization";
import AdminContainer from "modules/components/AdminContainer";
import FullPageLoading from "modules/components/FullPageLoading";
import ArchivedExperienceRows from "modules/components/GenerateClientInvitation/ArchivedExperienceRows";
import ExperienceRows from "modules/components/GenerateClientInvitation/ExperienceRows";
import MaintenanceWindow from "modules/components/MaintenanceWindow";
import ToastManager from "modules/components/Toast";
import Experience from "modules/database_types/experience";
import { useFetch, useProtectedFetch } from "modules/hooks";
import { MaintenanceWindowResponse } from "modules/shared/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";

const GenerateClientInvitation: React.FC = () => {
    const { instance: msalInstance } = useMsal();
    const dispatch = useDispatch();
    const localized = useLocalizedString();

    const dateFnLocale = useDateFnsLocale();
    const timeWindow = useFetch<MaintenanceWindowResponse>("/api/v1/maintenance-window");
    const [timeWindowStart, setTimeWindowStart] = useState<Date | null>(null);
    const [timeWindowEnd, setTimeWindowEnd] = useState<Date | null>(null);

    const getExperiencesOptions = React.useMemo(() => ({ method: "GET" }), []);
    const experiences_get = useProtectedFetch<Experience[]>(msalInstance, "/api/v1/experiences", getExperiencesOptions);
    const [experiences, setExperiences] = useState<Experience[]>([]);
    const [loadingExperiences, setLoadingExperiences] = useState<boolean>(true);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);
    const [statusFilter, setStatusFilter] = useState<"available" | "all" | "archived" | "inPerson">("available");

    useEffect(() => {
        if (timeWindow.state === "DONE") {
            const { start, end } = timeWindow.data;
            const startTime = start ? parseISO(start) : null;
            const endTime = end ? parseISO(end) : null;
            setTimeWindowStart(startTime);
            setTimeWindowEnd(endTime);
        }
    }, [timeWindow]);

    useEffect(() => {
        // TODO: refactor participantMessages to be out of SessionState and in ApplicationState
        // this will allow us to use toast messages for 403 responses where we'd like
        if (experiences_get.state === "ERROR" && experiences_get.status === 403) {
            dispatch(
                createParticipantMessageReceived({
                    payload: {
                        id: v4(),
                        success: false,
                        message: localized("adminMessage_unauthorized", localized("adminMessage_unauthorized_viewExp")),
                    },
                }),
            );
        }

        if (experiences_get.state === "ERROR") {
            setLoadingExperiences(false);
            setErrorLoading(true);
        }

        // experiences_get.status will change at the same time as experiences_get.state
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experiences_get.state, dispatch, localized, setExperiences, setErrorLoading]);

    useEffect(() => {
        if (experiences_get.state === "DONE" && !!experiences_get.data) {
            setExperiences(experiences_get.data);
            setLoadingExperiences(false);
        }
        // experiences_get.data will change at the same time as experiences_get is set to done
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExperiences, setLoadingExperiences, experiences_get.state]);

    const sortedExperiences = useMemo(() => {
        if (experiences.length > 0) {
            return sortBy(experiences, ["company_name", "experience_name"]);
        } else return [];
    }, [experiences]);

    const sortedWithoutInPersonExperiences = useMemo(() => {
        return sortedExperiences.filter((e) => e.inPerson !== true);
    }, [sortedExperiences]);

    const numAvailableExperiences = sortedExperiences.filter((e) => e.status === "available").length;

    const refreshExperiences = useCallback(async () => {
        if (loadingExperiences) {
            return Promise.resolve();
        }

        setLoadingExperiences(true);
        try {
            const import_results = await callSecureEndpoint(msalInstance, "/api/v1/experiences/import", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            });

            if (import_results?.ok) {
                const data: Experience[] = await import_results.json();
                setExperiences(data);
                setErrorLoading(false);
                setLoadingExperiences(false);
            } else {
                setErrorLoading(true);
                setLoadingExperiences(false);
            }
        } catch {
            setErrorLoading(true);
            setLoadingExperiences(false);
        }
    }, [msalInstance, loadingExperiences]);

    const archiveExperience = useCallback(
        async (experienceUuid: string) => {
            if (loadingExperiences) {
                return Promise.resolve();
            }

            setLoadingExperiences(true);
            try {
                const archiveResults = await callSecureEndpoint(
                    msalInstance,
                    "/api/v1/experiences/" + experienceUuid + "/archive",
                    {
                        method: "PUT",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    },
                );

                if (archiveResults?.ok) {
                    const data: { status: boolean; experience: Experience } = await archiveResults.json();
                    const newExperiences = experiences.filter((exp) => exp.id !== data.experience.id);
                    newExperiences.push(data.experience);
                    setExperiences(newExperiences);
                    setErrorLoading(false);
                    setLoadingExperiences(false);
                } else {
                    setErrorLoading(true);
                    setLoadingExperiences(false);
                }
            } catch {
                setErrorLoading(true);
                setLoadingExperiences(false);
            }
        },
        [msalInstance, loadingExperiences, experiences],
    );

    const resurrectExperience = useCallback(
        async (experienceUuid: string) => {
            if (loadingExperiences) {
                return Promise.resolve();
            }

            setLoadingExperiences(true);
            try {
                const resurrectResults = await callSecureEndpoint(
                    msalInstance,
                    "/api/v1/experiences/" + experienceUuid + "/resurrect",
                    {
                        method: "PUT",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    },
                );

                if (resurrectResults?.ok) {
                    const data: { status: boolean; experience: Experience } = await resurrectResults.json();
                    const newExperiences = experiences.filter((exp) => exp.id !== data.experience.id);
                    newExperiences.push(data.experience);
                    setExperiences(newExperiences);
                    setErrorLoading(false);
                    setLoadingExperiences(false);
                } else {
                    setErrorLoading(true);
                    setLoadingExperiences(false);
                }
            } catch {
                setErrorLoading(true);
                setLoadingExperiences(false);
            }
        },
        [msalInstance, loadingExperiences, experiences],
    );

    const destroyExperience = useCallback(
        async (experienceUuid: string) => {
            if (loadingExperiences) {
                return Promise.resolve();
            }

            setLoadingExperiences(true);
            try {
                const archiveResults = await callSecureEndpoint(msalInstance, "/api/v1/experiences/" + experienceUuid, {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                });

                if (archiveResults?.ok) {
                    const data: { status: boolean; experience_id: number } = await archiveResults.json();
                    const newExperiences = experiences.filter((exp) => exp.id !== data.experience_id);
                    setExperiences(newExperiences);
                    setErrorLoading(false);
                    setLoadingExperiences(false);
                } else {
                    setErrorLoading(true);
                    setLoadingExperiences(false);
                }
            } catch {
                setErrorLoading(true);
                setLoadingExperiences(false);
            }
        },
        [msalInstance, loadingExperiences, experiences],
    );

    const updateExperienceDataCollectionDefault = useCallback(
        async (experienceUuid: string, data_collection_default: boolean) => {
            if (loadingExperiences) {
                return Promise.resolve();
            }

            setLoadingExperiences(true);
            try {
                const updateResults = await callSecureEndpoint(
                    msalInstance,
                    "/api/v1/experiences/" + experienceUuid + "/data-collection-default",
                    {
                        method: "PUT",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        body: JSON.stringify({
                            data_collection_default: data_collection_default,
                        }),
                    },
                );

                if (updateResults?.ok) {
                    const data: Experience = await updateResults.json();
                    const newExperiences = experiences.filter((exp) => exp.id !== data.id);
                    newExperiences.push(data);
                    setExperiences(newExperiences);
                    setErrorLoading(false);
                    setLoadingExperiences(false);
                } else {
                    setErrorLoading(true);
                    setLoadingExperiences(false);
                }
            } catch {
                setErrorLoading(true);
                setLoadingExperiences(false);
            }
        },
        [msalInstance, loadingExperiences, experiences],
    );

    return (
        <>
            {loadingExperiences && <FullPageLoading />}
            {!loadingExperiences && !errorLoading && (
                <AdminContainer>
                    <Row className="mt-2">
                        <Col>
                            <h1>Root Internal: Experience list</h1>
                        </Col>
                        <Col className="float-end text-end">
                            <Button onClick={refreshExperiences} disabled={loadingExperiences}>
                                Refresh Experience Data
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                This portal lets you see all experiences configured in{" "}
                                <a
                                    href="https://be.contentful.com/login"
                                    // eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    rel="noopener noreferrer" // https://mathiasbynens.github.io/rel-noopener/#recommendations
                                >
                                    Contentful
                                </a>
                                . Use the links below to navigate to the session generating page for each experience.
                                Currently {numAvailableExperiences} experiences are available for scheduling, and there
                                are {sortedExperiences.length} total.
                            </p>
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <Button
                                            disabled={statusFilter === "available"}
                                            onClick={() => setStatusFilter("available")}
                                        >
                                            Published only
                                        </Button>
                                        <Button
                                            disabled={statusFilter === "archived"}
                                            onClick={() => setStatusFilter("archived")}
                                        >
                                            View archived
                                        </Button>
                                        <Button
                                            disabled={statusFilter === "all"}
                                            onClick={() => setStatusFilter("all")}
                                        >
                                            View all
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Col>
                        <MaintenanceWindow
                            timeWindowStart={timeWindowStart}
                            timeWindowEnd={timeWindowEnd}
                            locale={dateFnLocale}
                        />
                    </Row>

                    {statusFilter === "available" && (
                        <ExperienceRows
                            onArchive={archiveExperience}
                            onUpdateDataCollectionDefault={updateExperienceDataCollectionDefault}
                            experiences={sortedExperiences.filter(
                                (e) =>
                                    e.status !== "expired" &&
                                    e.status !== "unavailable" &&
                                    e.status !== "archiving" &&
                                    e.status !== "archived",
                            )}
                        />
                    )}

                    {statusFilter === "archived" && (
                        <ArchivedExperienceRows
                            experiences={sortedExperiences.filter((e) => e.status === "archived")}
                            onResurrect={resurrectExperience}
                            onDestroy={destroyExperience}
                        />
                    )}

                    {statusFilter === "all" && <ExperienceRows experiences={sortedWithoutInPersonExperiences} />}

                    <ToastManager />
                </AdminContainer>
            )}
            {!loadingExperiences && errorLoading && <>Error Loading Experiences</>}
        </>
    );
};

export default GenerateClientInvitation;
