import { FaMousePointer } from "react-icons/fa";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import NameTag from "../NameTag";
import "./mouse-renderer.scss";

type Props = {
    top: number;
    left: number;
    visible: boolean;
    name: string | null;
    opacity?: number;
};

// eslint-disable-next-line react/display-name
const _NamedCursor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const container = (ref || innerRef) as React.RefObject<HTMLDivElement>;

    // mks 2020-09-09 - It feels weird to just use straight-up JS DOM manipulation
    // here to add/remove the class, but I think it makes sense - this way we
    // don't trigger a React re-render.
    useEffect(() => {
        const timerId = setTimeout(
            () => {
                if (!container.current) {
                    return;
                }

                const computedStyle = window.getComputedStyle(container.current);
                const currentOpacity = parseFloat(computedStyle.getPropertyValue("opacity"));
                if (currentOpacity < 0.1) {
                    container.current.classList.remove("here");
                } else {
                    container.current.classList.add("here");
                }
            },
            // 250ms matches the CSS transition duration in mouse-renderer.css
            250,
        );

        return () => {
            clearTimeout(timerId);
        };
    }, [container]);

    return (
        <div
            ref={container}
            style={{
                left: props.left,
                top: props.top,
                opacity: props.visible ? props.opacity : undefined,
            }}
            className={classNames(["here", "cursor-container", props.visible && "visible"])}
        >
            <FaMousePointer className="cursor" />
            {props.name && <NameTag name={props.name} />}
        </div>
    );
});

const NamedCursor = React.memo(_NamedCursor);
export default NamedCursor;
