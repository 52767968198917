import { updateSessionState } from "modules/utils";
import { Debug } from "../actions-types";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState, payload: Debug): ApplicationState => {
    return updateSessionState(state, (session) => {
        let result: SessionStateContainer | null = null;

        if (session && session.state === "READY") {
            const debug = { ...(session.debug || {}) };

            if (typeof payload.value === "undefined") {
                delete debug[payload.key];
            } else {
                debug[payload.key] = payload.value;
            }

            result = {
                ...session,
                debug,
            };
        } else {
            result = session;
        }

        return result;
    });
};
