import { SessionState } from "../../types";
import { scriptSections } from "../step-definition/generic/scriptSections";
import { lastStepAddressSelector } from "../step-address/lastStepAddressSelector";

export function getCurrentMap(state: SessionState): string {
    if (state.isIntroStep) {
        return state.script.mapUrl;
    }
    if (state.currentStep === "exit") {
        const lastStepAddress = lastStepAddressSelector(state);
        return state.stepStates[lastStepAddress.sectionKey][lastStepAddress.stepKey].mapLocation;
    }

    if (state.currentStep.stepKey === "ProgressCheck") {
        const lastSectionKey = state.currentStep.sectionKey - 1;
        if (lastSectionKey < 0) {
            return state.stepStates[0][0].mapLocation;
        }
        const lastKeyBeforeProgressCheck = scriptSections(state)[lastSectionKey].steps.length - 1;
        return state.stepStates[lastSectionKey][lastKeyBeforeProgressCheck].mapLocation;
    }
    return state.stepStates[state.currentStep.sectionKey][state.currentStep.stepKey].mapLocation;
}
