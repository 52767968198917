import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { timeRecommendedForSections } from "../../utils";
import { previousScriptSections } from "../step-definition/generic/previousScriptSections";
import { getIntroSectionDefiniton } from "../intro-sequence/getIntroSectionDefinition";
import { createIntroTimeInPacingSelector } from "./createIntroTimeInPacing";

export function createTimeRecommendedForPreviousSectionsSelector(): (s: SessionState) => number {
    return createSelector(
        [previousScriptSections, getIntroSectionDefiniton, createIntroTimeInPacingSelector()],
        (previousSections, introSection, isIntroTimeActive) => {
            return isIntroTimeActive
                ? timeRecommendedForSections([introSection, ...previousSections])
                : timeRecommendedForSections(previousSections);
        },
    );
}
