import { AssignFacilitator, SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = Pick<AssignFacilitator, "participantId">;

const createAssignFacilitator = (args: Args): SessionEvent => {
    return {
        type: "ASSIGN_FACILITATOR",
        uuid: uuidv4(),
        participantId: args.participantId,
    };
};

export default createAssignFacilitator;
