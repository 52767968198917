import { compact } from "lodash-es";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { createCurrentStepDefinitionSelector } from "../step-definition/generic/createCurrentStepDefinitionSelector";
import { EntryFields } from "contentful";

export function createMapClickAbleAreasSelector(): (s: SessionState) =>
    | {
          title: string;
          content?: EntryFields.RichText;
          mapCoordinates: [number, number, number, number];
          hideBackground?: boolean;
      }[]
    | null {
    return createSelector([createCurrentStepDefinitionSelector("infoStep")], (stepDef) => {
        if (stepDef?.clickableAreas) {
            return compact(
                stepDef?.clickableAreas.map((ca) => {
                    const clickableAreaCoords = ca.mapCoordinates.split(",");
                    if (clickableAreaCoords.length == 4) {
                        const location = clickableAreaCoords.map((c) => parseInt(c));
                        return {
                            ...ca,
                            mapCoordinates: [location[0], location[1], location[2], location[3]],
                        };
                    }
                }),
            );
        }
        return null;
    });
}
