import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    participantId: string;
};
const createSurveyTaken = (args: Args): SessionEvent => {
    return {
        type: "SURVEY_TAKEN",
        participantId: args.participantId,
        uuid: uuidv4(),
    };
};

export default createSurveyTaken;
