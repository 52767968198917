import React from "react";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";

type Props = {
    text: string;
    className?: string;
};

const Markdown: React.FC<Props> = (props) => {
    const text = props.text.replaceAll(String.fromCharCode(160), " "); // replace all nbsp characters
    return (
        <div className={classNames("markdown", props.className)}>
            <ReactMarkdown>{text}</ReactMarkdown>
        </div>
    );
};

export default Markdown;
