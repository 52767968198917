import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

const createHotspotFlipCardToBack = (): SessionEvent => {
    return {
        type: "HOTSPOT__FLIP_CARD_TO_BACK",
        uuid: uuidv4(),
    };
};
export default createHotspotFlipCardToBack;
