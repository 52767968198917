import { CollectionProp, CommentProps } from "contentful-management";

// outgoing message types to server
export type PostComment = {
    body: string;
    entryId: string;
};
export type GetComments = {
    entryId: string;
};

export type DeleteComment = {
    entryId: string;
    commentId: string;
    version: number;
};

// incoming message types from Server
export type PostCommentResponse = {
    response: CommentProps;
};
export type GetCommentsResponse = {
    response: CollectionProp<CommentProps>;
};

export type DeleteCommentResponse = {
    response: void;
};

// message types for websocket message payload
export type ContentfulManagementFailure = {
    type: "FAILURE";
    message: string;
};

// types to enable type inference when making api requests forwarded to contentful by the rest server
type ContentfulManagementPath = "/comments" | "/comment" | "/delete_comment";
type ContentfulManagementOpt = Pick<RequestInit, "method"> & { path: ContentfulManagementPath };
export type ContentfulManagementBodyTypes = {
    GetComments: GetComments;
    PostComment: PostComment;
    DeleteComment: DeleteComment;
};
export const contentfulManagementOptValues: Record<keyof ContentfulManagementBodyTypes, ContentfulManagementOpt> = {
    GetComments: { method: "POST", path: "/comments" },
    PostComment: { method: "POST", path: "/comment" },
    DeleteComment: { method: "POST", path: "/delete_comment" },
};
type ContentfulManagementRespTypes = {
    GetComments: GetCommentsResponse;
    PostComment: PostCommentResponse;
    DeleteComment: DeleteCommentResponse;
};
type ContentfulManagementResponseType<K extends keyof ContentfulManagementBodyTypes> =
    | (ContentfulManagementRespTypes[K] & { type: "SUCCESS" })
    | ContentfulManagementFailure;
export type ContentfulManagementFetchTypes = {
    [K in keyof ContentfulManagementBodyTypes]: {
        body: ContentfulManagementBodyTypes[K];
        resp: ContentfulManagementResponseType<K>;
    };
};
