import { Configuration, ProtocolMode, RedirectRequest } from "@azure/msal-browser";

export const authConfig: Configuration = {
    auth: {
        clientId: AZURE_ESO_CLIENTID ?? "",
        authority: `https://${AZURE_ESO_DOMAIN}/${AZURE_ESO_TENANTID}`,
        knownAuthorities: [AZURE_ESO_DOMAIN ?? ""],
        protocolMode: ProtocolMode.OIDC,
        redirectUri: `${CLIENT_APP_URL}/login/callback`,
        navigateToLoginRequestUrl: true,
    },
};

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"],
};
