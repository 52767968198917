import { flattenDeep } from "lodash";
import { ApplicationState } from "modules/client/application-state";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import { useSelector } from "react-redux";

type DispatchButtonProps = {
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    variant?: ButtonVariant;

    /** Callback that will dispatch event(s). Must return UUIDs of said events. */
    onClick: () => string[] | string;
    children?: React.ReactNode;
};

/**
 * Button to use when you want to disable it until an action finishes being
 * dispatched. */
const DispatchButton: React.FC<DispatchButtonProps> = (props) => {
    const [dispatchedMessageUuids, setDispatchedMessageUuids] = useState<string[] | null>(null);

    const isAwaitingMessage: boolean = useSelector<ApplicationState, boolean>((app): boolean => {
        if (!dispatchedMessageUuids) {
            return false;
        }

        const allAwaiting = app.eventsAwaitingResolutions;
        const stillNeededAwaiting = [];
        dispatchedMessageUuids.forEach((neededUuid) => {
            if (!allAwaiting.find((uuid) => uuid === neededUuid)) {
                stillNeededAwaiting.push(neededUuid);
            }
        });
        return stillNeededAwaiting.length === 0;
    });

    const dispatch = React.useCallback(async () => {
        const uuids = props.onClick();
        setDispatchedMessageUuids(flattenDeep([uuids]));
    }, [props]);

    return (
        <Button
            type={props.type}
            variant={props.variant}
            disabled={props.disabled || isAwaitingMessage}
            onClick={dispatch}
        >
            {props.children}
        </Button>
    );
};

export default DispatchButton;
