import { keyBy } from "lodash-es";
import { ReincarnateParticipant } from "../session-events";
import { EventReducerDefinition } from "./types";

const reincarnateParticipant: EventReducerDefinition<ReincarnateParticipant> = {
    predictable: true,
    checkIfApplicable: (state, payload) => {
        const participants = keyBy(state.participants, "id");

        const dataFrom = participants[payload.participantIdToCopyDataFrom];
        const dataTo = participants[payload.participantIdToCopyDataTo];

        return !!dataFrom && !!dataTo && dataFrom.name != null && !dataTo.name;
    },
    reducer: (state, payload) => {
        const participants = keyBy(state.participants, "id");
        const participantCursors = { ...state.participantCursors };
        const dataFrom = participants[payload.participantIdToCopyDataFrom];
        const dataTo = participants[payload.participantIdToCopyDataTo];
        const cursorDataTo = participantCursors[payload.participantIdToCopyDataTo];
        const cursorDataFrom = participantCursors[payload.participantIdToCopyDataFrom];

        const updatedTo = {
            id: dataTo.id,

            //internalUuid: dataFrom.internalUuid, // not a socket id, stable for this person
            name: dataFrom.name,
            ready: dataFrom.ready,
            assignedRoles: dataFrom.assignedRoles,
        };

        participantCursors[dataTo.id] = cursorDataTo || cursorDataFrom;

        const updatedFrom = {
            ...dataFrom,
            assignedRoles: [],
        };

        const updatedParticipants = [
            ...state.participants.filter((p) => p.id !== dataTo.id && p.id !== dataFrom.id),
            updatedTo,
            updatedFrom,
        ];

        const poolOfEligibleParticipantsForNavigatorRole = [...state.poolOfEligibleParticipantsForNavigatorRole];
        if (!updatedTo.assignedRoles) {
            poolOfEligibleParticipantsForNavigatorRole.push(updatedTo.id);
        }

        return {
            ...state,
            participants: updatedParticipants,
            participantCursors,
            poolOfEligibleParticipantsForNavigatorRole,
        };
    },
};

export default reincarnateParticipant;
