import { SessionEvent } from "modules/shared/session-events";
import { v4 } from "uuid";
import { Icon } from "../../shared/activity/Markup/types";

type Args = {
    icon: Icon;
    draggerId: string;
};

const createDraggingMarkupIcon = (args: Args): SessionEvent => {
    return {
        type: "DRAGGING_MARKUP_ICON",
        uuid: v4(),
        icon: args.icon,
        draggerId: args.draggerId,
    };
};

export default createDraggingMarkupIcon;
