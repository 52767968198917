import { createSelector } from "@reduxjs/toolkit";
import { SessionState, StepState } from "../../types";
import { getStepStates } from "./getStepStates";
import { IntroStepStateBundle } from "modules/shared/step-types";
import { getIntroStepStates } from "../intro-sequence/getIntroStepStates";

export function createGetSectionStepStates(): (
    s: SessionState,
    sectionKey: number | "intro",
) => (IntroStepStateBundle | StepState)[] | null {
    return createSelector(
        [getStepStates, getIntroStepStates, (_state, sectionKey) => sectionKey],
        (stepStates, introStepStates, sectionKey: number | "intro") => {
            if (sectionKey === "intro") {
                return introStepStates;
            }
            if (!stepStates[sectionKey]) {
                return null;
            }

            return Object.values(stepStates[sectionKey]);
        },
    );
}
