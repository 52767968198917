import { useLocalizedString } from "modules/client/localization";
import React, { useEffect, useState } from "react";
import { Spinner, Button } from "react-bootstrap";

const ReconnectingAttemptOverlay: React.FC = () => {
    const [timeRemaining, setTimeRemaining] = useState<number>(30);

    useEffect(() => {
        let timeLeft = timeRemaining;
        const timer = window.setInterval(() => {
            if (timeLeft > 0) {
                timeLeft = timeLeft - 1;
                setTimeRemaining(timeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const localized = useLocalizedString();
    return (
        <div className="disconnected-overlay">
            <div className="d-flex flex-column disconnected-container">
                {timeRemaining > 0 ? (
                    <>
                        <div>
                            <Spinner animation="border"></Spinner>
                            <h2>{timeRemaining}</h2>
                        </div>
                        <span className="h4">{localized("vdpRenderer_sessionDisconnected")}</span>
                    </>
                ) : (
                    <>
                        <Button
                            className="refresh-button"
                            aria-description={localized("vdpRenderer_refreshDescription")}
                            onClick={() => window.location.reload()}
                        >
                            {localized("vdpRenderer_refresh")}
                        </Button>
                        <span className="h4">{localized("vdpRenderer_sessionDisconnectedReload")}</span>
                    </>
                )}
            </div>
        </div>
    );
};

export default ReconnectingAttemptOverlay;
