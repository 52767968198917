import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../types";
import { createCurrentStepOptionsSelector } from "../step-definition/generic/createCurrentStepOptionsSelector";

export function createMapMarkupIconTypeSelector(): (s: SessionState) => string {
    return createSelector([createCurrentStepOptionsSelector()], (options) => {
        const iconType = options?.mapMarkupIconType;

        if (!iconType) {
            return "check";
        } else {
            return iconType.toLowerCase().replaceAll(" ", "_");
        }
    });
}
