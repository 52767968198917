import { Locale as DateFnLocale } from "date-fns";
import * as DateFnsLocales from "date-fns/locale";
import { keys } from "lodash";
import { useSelector } from "react-redux";
import appStrings from "resources/app-strings";
import { clientLocale } from "./selectors";
import { useCallback, useMemo } from "react";

export type AppStrings = {
    // Activity
    vdpActivity_notFound: string;
    vdpActivity_closeModal: string;
    vdpActivity_correct: string;
    vdpActivity_correctExclamation: string;
    vdpActivity_correctAnswerBadge: string;
    vdpActivity_partiallyCorrect: string;
    vdpActivity_incorrect: string;
    vdpActivity_notCorrect: string;
    vdpActivity_continue: string;
    vdpActivity_checkAnswer: string;
    vdpActivity_submit: string;
    vdpActivity_submitted: string;
    vdpActivity_cardsPlaced: string;
    vdpActivity_ready: string;
    vdpActivity_cardsCorrectHeader: string;
    vdpActivity_cardsCorrect: string;
    vdpActivity_cardsCorrectContinue: string;
    vdpActivity_cardsPartiallyCorrectHeader: string;
    vdpActivity_cardsPartiallyCorrect: string;
    vdpActivity_cardsCheckAnswers: string;
    vdpActivity_textEntryPlaceholder: string;
    vdpActivity_textEntryNavigatorPrompt: string;
    vdpActivity_textEntryPrompt: string;
    vdpActivity_textEntrySubmit: string;
    vdpActivity_selectOne: string;
    vdpActivity_selectOneOrMany: string;
    vdpActivity_defaultCurrentParticipantName: string;
    vdpActivity_hotspotTooltip: string;
    vdpActivity_matchingSuccess: string;
    vdpActivity_matchingSuccessDescription: string;
    vdpActivity_matchingFail: string;
    vdpActivity_matchingFailDescription: string;
    vdpActivity_matchingReady: string;
    vdpActivity_matchingReadyDescription: string;
    vdpActivity_matchingCheckAnswers: string;
    vdpActivity_matchingContinue: string;
    vdpActivity_editableCardWarning: string;

    //Aria Labels and Descriptions For Activity Containers
    vdpActivity_cardStackWithVisualAriaLabel: string;
    vdpActivity_cardStackWithVisualAriaDescription: string;
    vdpActivity_revealCardAriaLabel: string;
    vdpActivity_revealCardAriaDescription: string;
    vdpActivity_dragAndDropAriaLabel: string;
    vdpActivity_dragAndDropAriaDescription: string;
    vdpActivity_matchingStepAriaLabel: string;
    vdpActivity_matchingStepAriaDescription: string;
    vdpActivity_recallStepAriaLabel: string;
    vdpActivity_recallStepAriaDescription: string;
    vdpActivity_multipleChoiceAriaLabel: string;
    vdpActivity_multipleChoiceAriaDescription: string;
    vdpActivity_textEntryAriaLabel: string;
    vdpActivity_textEntryAriaDescription: string;
    vdpActivity_singleAssetStepAriaLabel: string;
    vdpActivity_singleAssetStepAriaDescription: string;
    vdpActivity_comparisonStepAriaLabel: string;
    vdpActivity_comparisonStepAriaDescription: string;
    vdpActivity_FirstTitlePosition: string;
    vdpActivity_LastTitlePosition: string;
    vdpActivity_videoStepAriaLabel: string;
    vdpActivity_videoStepAriaDescription: string;
    vdpActivity_progressCheckAriaLabel: string;
    vdpActivity_progressCheckAriaDescription: string;
    vdpActivity_exitStepAriaLabel: string;
    vdpActivity_exitStepAriaDescription: string;

    // CopyToClipboard
    vdpClipboard_copied: string;
    vdpClipboard_copy: string;
    vdpClipboard_error: string;

    // HUD
    vdpHud_hide: string;
    vdpHud_show: string;
    vdpHud_changeNavigatorTitle: string;
    vdpHud_changeNavigatorText1: string;
    vdpHud_changeNavigatorText2: string;
    vdpHud_close: string;
    vdpHud_accept: string;
    vdpHud_back: string;
    vdpHud_next: string;
    vdpHud_endExperience: string;
    vdpHud_defaultExitMsg: string;
    vdpHud_navigatorReminder: string;
    vdpHud_timerNoTimeSpecified: string;
    vdpHud_timerRemaining: string;
    vdpHud_timerLessThanOneMinute: string;
    vdpHud_timerOver: string;
    vdpHud_roleNone: string;
    vdpHud_infoTableSection: string;
    vdpHud_infoTableRecommended: string;
    vdpHud_infoTableActual: string;
    vdpHud_infoTableTotal: string;
    vdpHud_infoTableRemaining: string;
    vdpHud_infoTeamButton: string;
    vdpHud_infoTeamButtonDescription: string;
    vdpHud_infoTeamParticipantJoining: string;
    vdpHud_navigator: string;
    vdpHud_facilitator: string;
    vdpHud_facilitatorMenuMakeNavigator: string;
    vdpHud_facilitatorMenuRemoveFacilitator: string;
    vdpHud_removeFacilitatorWarningConfirm: string;
    vdpHud_removeFacilitatorWarningCancel: string;
    vdpHud_removeFacilitatorWarningMessage: string;
    vdpHud_progressOnPaceMessage: string;
    vdpHud_progressAheadOfPaceMessage: string;
    vdpHud_progressBehindPaceMessage: string;
    vdpHud_progressTimeRemaining: string;
    vdpHud_progressStepsInSection: string;
    vdpHud_zoomControlsTooltip: string;
    vdpHud_zoomControlsDescription: string;
    vdpHud_zoomControlsZoomIn: string;
    vdpHud_zoomControlsZoomOut: string;
    vdpNameEntry_claimFacilitatorTitle: string;
    vdpNameEntry_facilitatorClaimed: string;
    vdpNameEntry_claimFacilitatorButton: string;
    vdpNameEntry_claimFacilitatorModalCancel: string;
    vdpNameEntry_claimFacilitatorModalParagraphOne: string;
    vdpNameEntry_claimFacilitatorModalParagraphTwo: string;
    vdpNameEntry_claimFacilitatorModalParagraphThree: string;
    vdpNameEntry_smallScreenWarningTitle: string;
    vdpNameEntry_smallScreenWarningBody: string;
    vdpNameEntry_smallScreenWarningDismiss: string;

    // Sidebar
    sidebar_scheduleButton: string;
    sidebar_scheduleButtonDescription: string;
    sidebar_teamAssignNavigator: string;
    sidebar_teamChooseRandomly: string;
    sidebar_teamChooseHelperText: string;
    sidebar_editNameMenuItem: string;
    sidebar_manageRolesMenuLabel: string;
    sidebar_navigatorDescription: string;
    sidebar_facilitatorDescription: string;
    sidebar_previousButton: string;
    sidebar_previousButtonDescription: string;
    sidebar_nextButton: string;
    sidebar_nextButtonDescription: string;
    sidebar_lockTooltip: string;
    sidebar_navigatorBadge: string;
    sidebar_navigatorBadgeDescription: string;
    sidebar_facilitatorBadge: string;
    sidebar_introductionLabel: string;
    sidebar_introductionInstruction: string;
    sidebar_directionsLabel: string;
    sidebar_nonNavigatorDirectionsLabel: string;
    sidebar_directionsInstruction: string;
    sidebar_facilitationNotesLabel: string;
    sidebar_nameEditPlaceholder: string;
    sidebar_editNameSave: string;
    sidebar_skipButtonDescription: string;

    // GenerateUrlDisplay
    genUrl_copyLink: string;
    genUrl_sessionLinkDetails: string;
    genUrl_exportAsCsv: string;
    genUrl_copyTable: string;
    genUrl_linkExpiration: string;
    genUrl_link: string;
    genUrl_title: string;
    genUrl_experience: string;
    genUrl_description: string;
    genUrl_numSessions: string;
    genUrl_sessionsExpirationLabel: string;
    genUrl_sessionsOpenAtLabel: string;
    genUrl_numSessionsInputLabel: string;
    genUrl_sessionLanguageLabel: string;
    genUrl_numSessionsInputTypeError: string;
    genUrl_closesOnError: string;
    genUrl_experienceClosesOnError: string;
    genUrl_notifSuccessSingle: string;
    genUrl_notifSuccessMultiple: string;
    genUrl_notifErrorGenerating: string;
    genUrl_notifErrorProcessing: string;
    genUrl_sessionsExpireToday: string;
    genUrl_sessionsExpireInOneDay: string;
    genUrl_sessionsExpireInDays: string;
    genUrl_generate: string;
    genUrl_schedulingLinkExpired: string;
    genUrl_error: string;
    genUrl_dataCollectionLabel: string;
    genUrl_dataCollectionDescription: string;
    genUrl_dataCollectionDefaultLabel: string;
    genUrl_sessionsOpenOneDay: string;
    genUrl_sessionsOpenToday: string;
    genUrl_sessionsOpenDays: string;
    genUrl_emailContact: string;
    genUrl_goToSessionRepoReport: string;
    genUrl_archiveButton: string;
    genUrl_resurrectButton: string;
    genUrl_destroyButton: string;

    // VDP Application
    vdpLoading_waitForContent: string;

    // Not Found
    vdpNotFound_title: string;
    vdpNotFound_message: string;

    // Error Modal
    vdpErrorModal_title: string;
    vdpErrorModal_message: string;
    vdpErrorModal_body: string;
    vdpErrorModal_button: string;

    // Session Renderer
    vdpRenderer_refresh: string;
    vdpRenderer_refreshDescription: string;
    vdpRenderer_sessionDisconnectedHeader: string;
    vdpRenderer_sessionDisconnected: string;
    vdpRenderer_sessionDisconnectedReload: string;
    vpdRenderer_inactiveDisconnectedBody: string;
    vdpRenderer_inactiveHeader: string;
    vdpRenderer_inactiveBody: string;
    vdpRenderer_setSelfActiveButton: string;
    vdpRenderer_setSelfActiveButtonDescription: string;
    vdpRenderer_endOfSession: string;
    vdpRenderer_invalidMapUrl: string;
    vdpRenderer_unexpectedError: string;
    vdpRenderer_unexpectedErrorMsg: string;
    vdpRenderer_defaultEndingMessage: string;

    // Welcome
    vdpNameEntry_welcomeText: string;
    vdpNameEntry_defaultWelcomeMessage: string;
    vdpNameEntry_welcomeHeader: string;
    vdpNameEntry_welcomeAdvance: string;
    vdpNameEntry_inputName: string;
    vdpNameEntry_inputNamePlaceholder: string;
    vdpNameEntry_inputDepartment: string;
    vdpNameEntry_inputEmail: string;
    vdpNameEntry_inputTeam: string;
    vdpNameEntry_identifiersName: string;
    vdpNameEntry_identifiersDuplicateName: string;
    vdpNameEntry_identifiersDepartment: string;
    vdpNameEntry_identifiersEmail: string;
    vdpNameEntry_identifiersInvalidEmailFormat: string;
    vdpNameEntry_identifiersTeam: string;
    vdpNameEntry_numParticipantsInSession: string;
    vdpNameEntry_oneParticipantsInSession: string;
    vdpNameEntry_save: string;
    vdpNameEntry_continue: string;
    vdpNameEntry_thanks: string;
    vdpNameEntry_intro: string;
    vdpNameEntry_introStarted: string;
    vdpNameEntry_navDirections: string;
    vdpNameEntry_saveDescription: string;
    vdpNameEntry_continueDescription: string;

    // Preview Mode Toolbar
    toolbar_stepOf: string;
    toolbar_intro: string;
    toolbar_welcome: string;
    toolbar_exit: string;
    toolbar_reloadContent: string;
    toolbar_commentCreationButton: string;
    toolbar_buildComments: string;
    toolbar_commentListButton: string;
    toolbar_commentCreationTitle: string;
    toolbar_commentCreationDescription: string;
    toolbar_commentCreationCancelButton: string;
    toolbar_commentCreationSubmitButton: string;
    toolbar_commentCreationSubmitButtonDisabled: string;
    toolbar_commentCreationPlaceholder: string;
    toolbar_previewMode: string;
    toolbar_aboutPreviewMode: string;
    toolbar_focusAreaSelector: string;
    toolbar_hotspotSelector: string;
    toolbar_previewModeModalTitle: string;
    toolbar_previewModeModalFirstIntroduction: string;
    toolbar_previewModeModalSecondIntroduction: string;
    toolbar_previewModeModalReloadContent: string;
    toolbar_previewModeModalReloadContentDescription: string;
    toolbar_previewModeModalComments: string;
    toolbar_previewModeModalCommentsDescription: string;
    toolbar_previewModeModalMapCoordinates: string;
    toolbar_previewModeModalMapCoordinatesDescription: string;
    toolbar_previewModeModalHotspotSelector: string;
    toolbar_previewModeModalHotspotSelectorDescription: string;

    // Coordinate Preview
    coordinatePreview_coordinatesSelectorTitle: string;
    coordinatePreview_hotspotMode: string;
    coordinatePreview_hotspotModeCoordinatesCopiedToast: string;
    coordinatePreview_focusAreaMode: string;
    coordinatePreview_focusAreaModeCoordinatesCopiedToast: string;
    coordinatePreview_directionsTitle: string;
    coordinatePreview_hotspotDirectionsLineOne: string;
    coordinatePreview_hotspotDirectionsLineTwo: string;
    coordinatePreview_focusAreaDirectionsLineOne: string;
    coordinatePreview_focusAreaDirectionsLineTwo: string;
    coordinatePreview_focusAreaDirectionsLineThree: string;
    coordinatePreview_reset: string;
    coordinatePreview_done: string;

    // Validation
    vdpValidationErrorMinLength: string;
    vdpValidationErrorMaxLength: string;

    // Navigator Default
    navigatorDefaultInstructions_selectNextToContinue: string;
    navigatorDefaultInstructions_progressCheckLineOne: string;
    navigatorDefaultInstructions_progressCheckLineTwoOnPace: string;
    navigatorDefaultInstructions_progressCheckLineTwoAheadOfPace: string;
    navigatorDefaultInstructions_progressCheckLineTwoBehindPace: string;
    navigatorDefaultInstructions_progressCheckLineThreeNewNav: string;

    // maintenanceWindow
    maintenanceWindow_heading: string;
    maintenanceWindow_body: string;

    // URL generation errors
    maintenanceError_overlap: string;

    // progress check step
    progressCheck_sectionTitle: string;
    progressCheck_paceCheckTitle: string;
    progressCheck_paceMessageOnPace: string;
    progressCheck_paceMessageAheadOfPace: string;
    progressCheck_paceMessageBehindPace: string;
    progressCheck_navigatorChangeTitle: string;
    progressCheck_navigatorChangeMessage: string;

    // intro tutorial step
    vdpIntro_title: string;
    vdpIntro_subtitle: string;
    vdpIntro_joinTheTeam: string;
    vdpIntro_facilitatorRole: string;
    vdpIntro_navigatorRole: string;
    vdpIntro_participantRole: string;
    vdpIntro_iceBreaker: string;
    vdpIntro_exploreTheVisual: string;
    vdpIntro_skipShort: string;
    vdpIntro_skipLong: string;
    vdpIntro_facilitatorInstructionsOne: string;
    vdpIntro_facilitatorInstructionsTwo: string;
    vdpIntro_facilitatorInstructionsThree: string;
    vdpIntro_navigatorInstructionsOne: string;
    vdpIntro_navigatorInstructionsTwo: string;
    vdpIntro_participantInstructionsOne: string;
    vdpIntro_participantInstructionsTwo: string;
    vdpIntro_iceBreakerIntroInstructionsOne: string;
    vdpIntro_iceBreakerIntroInstructionsTwo: string;
    vdpIntro_iceBreakerInstructionsOne: string;
    vdpIntro_iceBreakerInstructionsDefault: string;
    vdpIntro_mapInstructionsOne: string;
    vdpIntro_mapInstructionsTwo: string;
    vdpIntro_iceBreakerIntroDirections: string;

    // end of session messages
    vdpEnd_title: string;
    vdpEnd_body: string;
    vdpEnd_surveyRequest: string;
    vdpEnd_surveyThankYou: string;
    vdpEnd_sessionComplete: string;
    vdpEnd_closeWindow: string;

    // privacy banner
    privacyBanner_title: string;
    privacyBanner_message: string;
    privacyBanner_dismiss: string;
    privacyBanner_dismissDescription: string;

    // admin messages
    adminMessage_unauthorized: string;
    adminMessage_unauthorized_viewExp: string;

    // session repository reporting strings
    sessionReport_submitButton: string;
    sessionReport_header_title: string;
    sessionReport_header_experience: string;
    sessionReport_header_errorTitle: string;
    sessionReport_header_errorSubtitle: string;
    sessionReport_switch_unknown: string;
    sessionReport_results_downloadResults: string;
    sessionReport_results_noRecordsTitle: string;
    sessionReport_results_noRecordsSubTitle: string;
    sessionReport_results_errorTitle: string;
    //Session Basic Info Reporting Strings
    sessionReport_basicInfo_useSeedData: string;
    sessionReport_basicInfo_useSeedData_help: string;
    sessionReport_basicInfo_startDate: string;
    sessionReport_basicInfo_endDate: string;
    sessionReport_switch_basicInfo: string;
    //Multiple Choice Basic Info Reporting Strings
    multipleChoiceReport_basicInfo_useSeedData: string;
    multipleChoiceReport_basicInfo_useSeedData_help: string;
    multipleChoiceReport_basicInfo_startDate: string;
    multipleChoiceReport_basicInfo_endDate: string;
    multipleChoiceReport_switch_basicInfo: string;
    multipleChoiceReport_basicInfo_activityId: string;
    //Multiple Choice Basic Info Reporting Strings
    multipleChoiceReport_basicInfoAggregated_useSeedData: string;
    multipleChoiceReport_basicInfoAggregated_useSeedData_help: string;
    multipleChoiceReport_basicInfoAggregated_startDate: string;
    multipleChoiceReport_basicInfoAggregated_endDate: string;
    multipleChoiceReport_switch_basicInfoAggregated: string;
    multipleChoiceReport_basicInfoAggregated_activityId: string;
    //Activity Basic Info Reporting Strings
    activityReport_basicInfo_useSeedData: string;
    activityReport_basicInfo_useSeedData_help: string;
    activityReport_basicInfo_startDate: string;
    activityReport_basicInfo_endDate: string;
    activityReport_switch_basicInfo: string;
    //Activity Basic Info Aggregated Reporting Strings
    activityReport_basicInfoAggregated_useSeedData: string;
    activityReport_basicInfoAggregated_useSeedData_help: string;
    activityReport_basicInfoAggregated_startDate: string;
    activityReport_basicInfoAggregated_endDate: string;
    activityReport_switch_basicInfoAggregated: string;
    //Drag And Drop Info Reporting Strings
    dragAndDropReport_basicInfo_useSeedData: string;
    dragAndDropReport_basicInfo_useSeedData_help: string;
    dragAndDropReport_basicInfo_startDate: string;
    dragAndDropReport_basicInfo_endDate: string;
    dragAndDropReport_switch_basicInfo: string;
    dragAndDropReport_basicInfo_activityId: string;
    //Drag And Drop Info Aggregated Reporting Strings
    dragAndDropReport_basicInfoAggregated_useSeedData: string;
    dragAndDropReport_basicInfoAggregated_useSeedData_help: string;
    dragAndDropReport_basicInfoAggregated_startDate: string;
    dragAndDropReport_basicInfoAggregated_endDate: string;
    dragAndDropReport_switch_basicInfoAggregated: string;
    dragAndDropReport_basicInfoAggregated_activityId: string;
    //Editable Card Info Reporting Strings
    editableCardReport_basicInfo_useSeedData: string;
    editableCardReport_basicInfo_useSeedData_help: string;
    editableCardReport_basicInfo_startDate: string;
    editableCardReport_basicInfo_endDate: string;
    editableCardReport_switch_basicInfo: string;
    editableCardReport_basicInfo_activityId: string;
    // confirmation dialog
    confirmDialog_confirmValuePrompt: string;
    confirmDialog_confirmButton: string;
    confirmDialog_closeButton: string;
    confirmDialog_error_checkBox: string;
    confirmDialog_error_confirmValue: string;
    confirmDialog_scheduler_title: string;
    confirmDialog_scheduler_body: string;
    confirmDialog_scheduler_resurrectTitle: string;
    confirmDialog_scheduler_resurrectBody: string;
    confirmDialog_scheduler_destroyTitle: string;
    confirmDialog_scheduler_destroyBody: string;
    confirmDialog_dataCollectionDefault_title: string;
    confirmDialog_dataCollectionDefault_body: string;
    confirmDialog_dataCollectionDefault_body_enable: string;
    confirmDialog_dataCollectionDefault_body_disable: string;
    confirmDialog_enable_NavigatorTitle: string;
    confirmDialog_enable_NavigatorBody: string;
    vdpNameEntry_NavigatorSelectionMessage: string;
    vdpNameEntry_NavigatorButtonMessage: string;
    confirmDialog_dataCollection_off: string;
    confirmDialog_dataCollection_on: string;
};

type Direction = "LTR" | "RTL" | "NEUTRAL";

export type AppLocale = {
    name: string;
    direction: Direction;
    strings: AppStrings;
};
export type LocaleOption = { name: string; value: string };
type LocalizedAppStrings = Record<string, AppLocale>;
type StringArg = number | string;
type UseLocalizedString = () => (appString: keyof AppStrings, ...args: StringArg[]) => string;
type DateFnsLocalesModule = typeof DateFnsLocales;

const DEFAULT_LOCALE = "en-US";

const localesFromAppStrings = (appStrings: LocalizedAppStrings): LocaleOption[] =>
    keys(appStrings).map((value) => ({
        name: appStrings[value].name,
        value,
    }));

export const getAvailableLocales = (localeSubset?: string[]): LocaleOption[] => {
    const staticStrings = appStrings as LocalizedAppStrings;
    const staticLocales = localesFromAppStrings(staticStrings);

    // If localeSubset is not provided, do not filter locales
    // just return them all
    if (!localeSubset) {
        return staticLocales;
    }

    return staticLocales.filter(
        (staticLocale) => localeSubset.includes(staticLocale.value) || staticLocale.value === "en-US",
    );
};

export const useClientLocale = (): string => useSelector(clientLocale);

export const useDirection = (): Direction => {
    const locale = useClientLocale();
    const staticStrings = appStrings as LocalizedAppStrings;
    return staticStrings[locale || DEFAULT_LOCALE].direction;
};

const format = (string: string, ...args: StringArg[]): string =>
    string.replace(/{(\d+)}/g, (match, number) => (typeof args[number] != "undefined" ? `${args[number]}` : match));

export const useLocalizedString: UseLocalizedString = () => {
    const locale = useClientLocale();
    // NOTE: Could load this from external source in the future
    const staticStrings = useMemo(() => appStrings as LocalizedAppStrings, []);

    return useCallback(
        (str: keyof AppStrings, ...args: StringArg[]) => {
            let localized;
            if (staticStrings?.[locale]?.strings[str]) {
                localized = staticStrings[locale].strings[str];
            } else {
                console.log(`No "${str}" string found for "${locale}" locale.`);
                localized = staticStrings[DEFAULT_LOCALE].strings[str];
            }
            return format(localized, ...args);
        },
        [staticStrings, locale],
    );
};

const formatLocaleCodeForDateFns = (code: string): string => {
    return code.replaceAll("-", "");
};

const formatLocaleCodeWithoutRegion = (code: string): string => {
    return code.slice(0, code.indexOf("-"));
};

export const getDateFnLocale = (locale: string): DateFnLocale => {
    const dateFnLocaleCode = formatLocaleCodeForDateFns(locale);
    const dateFnLocaleCodeNoRegion = formatLocaleCodeWithoutRegion(locale);
    return (
        DateFnsLocales[dateFnLocaleCode as keyof DateFnsLocalesModule] ??
        DateFnsLocales[dateFnLocaleCodeNoRegion as keyof DateFnsLocalesModule] ??
        DateFnsLocales.enUS
    );
};

export const useDateFnsLocale = (): DateFnLocale => {
    const locale = useClientLocale();
    return getDateFnLocale(locale);
};
