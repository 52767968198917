import { delay } from "lodash";
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

type Props = {
    transitionDelay: number;
    transitionClassNames: string;
    renderKey: string;
};

export const DelayContent: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [childContentShown, setChildContentShown] = useState<React.ReactNode>(null);
    const [showContent, setShowContent] = useState(false);
    const { transitionDelay, transitionClassNames, renderKey } = props;

    useEffect(() => {
        const timerId = setTimeout(() => {
            setShowContent(true);
            setChildContentShown(props.children);
        }, transitionDelay);

        return () => {
            setShowContent(false);
            clearTimeout(timerId);
        };
        // the renderKey here is something that we explicitly use to trigger re-renders with this component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderKey]);

    return (
        <CSSTransition in={showContent} timeout={transitionDelay} classNames={transitionClassNames}>
            {childContentShown ?? <div></div>}
        </CSSTransition>
    );
};
export default DelayContent;
