import { incrementStepAddress } from "modules/utils";
import { SessionState, StepAddress, StepIntroAddress } from "../../types";

export function stepAfterCurrentAddress(state: SessionState): StepAddress | StepIntroAddress | null {
    if (state.currentStep === "exit") {
        return null;
    }
    const nextAddress = incrementStepAddress(state);

    if (nextAddress === null) {
        return "exit";
    }

    return nextAddress;
}
