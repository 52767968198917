import { updateStepStates } from "modules/utils";
import { getStepScript } from "modules/shared/selectors/step-definition/generic/getStepScript";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import { MultipleChoiceAnswerChanged, MultipleChoiceSubmitted } from "../../session-events";
import { SessionState } from "../../types";

export const MULTIPLE_CHOICE__ANSWER_CHANGED = {
    predictable: true,
    checkIfApplicable: (state: SessionState): boolean => {
        const stepState = getStepState("MULTIPLE_CHOICE", state);
        if (!stepState) return false;

        return true;
    },
    reducer: (state: SessionState, payload: MultipleChoiceAnswerChanged): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("MULTIPLE_CHOICE", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        selectedAnswerIds: payload.selectedAnswerIds,
                    },
                };
            }),
        };
    },
};

export const MULTIPLE_CHOICE__SUBMITTED = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: MultipleChoiceSubmitted): boolean => {
        const stepState = getStepState("MULTIPLE_CHOICE", state);
        if (!stepState?.persistent) return false;

        if (payload.selectedAnswerIds.length === 0) return false;

        return true;
    },
    reducer: (state: SessionState, payload: MultipleChoiceSubmitted): SessionState => {
        const step = getStepScript("multipleChoiceStep", state, state.currentStep);

        if (!step) {
            return state;
        }

        return {
            ...state,
            stepStates: updateStepStates("MULTIPLE_CHOICE", state, state.currentStep, (stepState) => {
                if (payload.selectedAnswerIds.length == 0) {
                    return stepState;
                }

                const submittedCorrectAnswers = step!.predefinedAnswers.filter((answer) => {
                    return (
                        // correct answer and chosen
                        answer.isCorrect && payload.selectedAnswerIds.includes(answer.id)
                    );
                });

                // Any answers that should have been chosen but were not OR should not have been chosen but were
                const incorrectAnswers = step.predefinedAnswers.filter((answer) => {
                    return (
                        // correct answer but not chosen
                        (answer.isCorrect && !payload.selectedAnswerIds.includes(answer.id)) || // incorrect answer but chosen
                        (!answer.isCorrect && payload.selectedAnswerIds.includes(answer.id))
                    );
                });

                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,

                        // update selectedAnswerIds in case submit fires off before the "answer changed" reducer updates it on server
                        // @todo: should this be updated/checked differently?
                        selectedAnswerIds: payload.selectedAnswerIds,
                        wasSubmitted: true,
                        incorrectAnswers: incorrectAnswers,
                        submittedCorrectAnswers: submittedCorrectAnswers,
                        submissionCorrect: incorrectAnswers.length === 0,
                    },
                };
            }),
        };
    },
};
