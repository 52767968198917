import { authConfig } from "modules/client/auth/config";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"; // set up authentication handler
import { loginCallback } from "./utils";

export function createMsalInstance(): PublicClientApplication {
    if (!AZURE_ESO_CLIENTID || !AZURE_ESO_DOMAIN || !AZURE_ESO_TENANTID) {
        throw new Error("Incomplete configurations for authentication provided in env.");
    }
    const msalInstance = new PublicClientApplication(authConfig);
    msalInstance.addEventCallback(async (event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            await loginCallback(msalInstance, payload);
        }
    });
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        // TODO: select accounts
        msalInstance.setActiveAccount(accounts[0]);
    }
    return msalInstance;
}
