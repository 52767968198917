import { EventReducerDefinition } from "modules/shared/reducers/types";
import { TextEntryAnswerChanged, TextEntrySubmitted } from "modules/shared/session-events";
import { SessionState } from "modules/shared/types";
import { updateStepStates } from "modules/utils";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";

export const TEXT_ENTRY__ANSWER_CHANGED: EventReducerDefinition<TextEntryAnswerChanged, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: TextEntryAnswerChanged): boolean => {
        const stepState = getStepState("TEXT_ENTRY", state);
        if (!stepState?.persistent) return false;

        return true;
    },
    reducer: (state: SessionState, payload: TextEntryAnswerChanged): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("TEXT_ENTRY", state, state.currentStep, (stepState) => {
                const updatedAnswers = {
                    ...stepState?.persistent.answers,
                };
                updatedAnswers[payload.id] = payload.answer;

                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        answers: updatedAnswers,
                    },
                };
            }),
        };
    },
};

export const TEXT_ENTRY__SUBMITTED: EventReducerDefinition<TextEntrySubmitted, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: TextEntrySubmitted): boolean => {
        const stepState = getStepState("TEXT_ENTRY", state);
        if (!stepState?.persistent) return false;

        return true;
    },
    reducer: (state: SessionState, payload: TextEntrySubmitted): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("TEXT_ENTRY", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        answers: payload.answers,
                        wasSubmitted: true,
                    },
                };
            }),
        };
    },
};
