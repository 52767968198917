import { updateSessionState } from "modules/utils";
import { WebsocketError } from "../actions-types";
import { ApplicationState } from "../application-state";

export default (state: ApplicationState, payload: WebsocketError): ApplicationState => {
    return updateSessionState(state, (session) => {
        if (
            (session.state === "READY" ||
                session.state === "RECONNECTING" ||
                session.state === "RECONNECTED_WAITING_FOR_REINCARNATION") &&
            (payload.payload === "transport close" ||
                payload.payload === "ping timeout" ||
                payload.payload === "transport error")
        ) {
            return {
                ...session,
                state: "RECONNECTING",
                participantId: undefined,
                newParticipantId: null,
                oldParticipantId: session.state === "READY" ? session.participantId : session.oldParticipantId,
            };
        } else {
            return {
                ...session,
                state: "ERROR",
                error: payload.payload,
            };
        }
    });
};
