import { EventReducerDefinition } from "modules/shared/reducers/types";
import {
    EditableCardAnswerChanged,
    EditableCardShowWarning,
    EditableCardSubmitted,
} from "modules/shared/session-events";
import { SessionState } from "modules/shared/types";
import { updateStepStates } from "modules/utils";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";

export const EDITABLE_CARD__ANSWER_CHANGED: EventReducerDefinition<EditableCardAnswerChanged, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: EditableCardAnswerChanged): boolean => {
        const stepState = getStepState("EDITABLE_CARD", state);
        if (!stepState?.persistent) return false;
        return true;
    },
    reducer: (state: SessionState, payload: EditableCardAnswerChanged): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("EDITABLE_CARD", state, state.currentStep, (stepState) => {
                const updatedAnswers = {
                    ...stepState.persistent?.answers,
                };
                updatedAnswers[payload.id] = payload.answer;
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        answers: updatedAnswers,
                    },
                };
            }),
        };
    },
};

export const EDITABLE_CARD__SUBMITTED: EventReducerDefinition<EditableCardSubmitted, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: EditableCardSubmitted): boolean => {
        const stepState = getStepState("EDITABLE_CARD", state);
        if (!stepState?.persistent) return false;

        return true;
    },
    reducer: (state: SessionState, payload: EditableCardSubmitted): SessionState => {
        const areAllAnswersFilled = Object.values(payload.answers).every((answer) => answer.trim() !== "");
        return {
            ...state,
            stepStates: updateStepStates("EDITABLE_CARD", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        answers: payload.answers,
                        wasSubmitted: areAllAnswersFilled,
                        showWarning: !areAllAnswersFilled,
                    },
                };
            }),
        };
    },
};

export const EDITABLE_CARD__SHOW_WARNING: EventReducerDefinition<EditableCardShowWarning, null> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: EditableCardShowWarning): boolean => {
        const stepState = getStepState("EDITABLE_CARD", state);
        if (!stepState?.persistent) return false;

        return true;
    },
    reducer: (state: SessionState, payload: EditableCardShowWarning): SessionState => {
        return {
            ...state,
            stepStates: updateStepStates("EDITABLE_CARD", state, state.currentStep, (stepState) => {
                return {
                    ...stepState,
                    persistent: {
                        ...stepState.persistent,
                        showWarning: payload.showWarning,
                    },
                };
            }),
        };
    },
};
