import React, { FC, FormEvent, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";

type Props = {
    confirmInputValue: string;
    onChangeConfirmInputValue: React.ChangeEventHandler<HTMLInputElement>;
    confirmCheckboxValue: boolean;
    onChangeConfirmCheckboxValue: React.ChangeEventHandler<HTMLInputElement>;
    confirmValue?: string;
};

const ConfirmForm: FC<Props> = ({
    confirmInputValue,
    onChangeConfirmInputValue,
    confirmCheckboxValue,
    onChangeConfirmCheckboxValue,
    confirmValue,
}) => {
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };
    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formConfirmationCheckbox">
                <Form.Check
                    type="checkbox"
                    checked={confirmCheckboxValue}
                    label={"Yes, take the action described."}
                    onChange={onChangeConfirmCheckboxValue}
                />
            </Form.Group>
            {!!confirmValue && (
                <Form.Group className="mb-3" controlId="formConfirmationInput">
                    <Form.Control
                        disabled={!confirmCheckboxValue}
                        type="text"
                        value={confirmInputValue}
                        placeholder={"Enter " + confirmValue + " to confirm"}
                        onChange={onChangeConfirmInputValue}
                    />
                </Form.Group>
            )}
        </Form>
    );
};
export default ConfirmForm;
