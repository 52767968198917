import React, { useRef } from "react";
import AdminContainer from "modules/components/AdminContainer";
import { QRCodeCanvas } from "qrcode.react";
import { toPng } from "html-to-image";
import { useParams } from "react-router-dom";
import { Button, Col, Row, Container, Card, Alert, Placeholder } from "react-bootstrap";
import Experience from "modules/database_types/experience";
import { useFetch } from "modules/hooks";

const GenerateQRCode: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const url = `${CLIENT_APP_URL}/client/inPerson/${uuid}`;
    const downloadRef = useRef<HTMLDivElement>(null);
    const experience = useFetch<Experience>(`/api/v1/experiences/${uuid}`);
    const experienceName = experience.state === "DONE" && experience.data.experience_name;

    const downloadQRCode = () => {
        if (downloadRef.current === null) return;
        const canvas = downloadRef.current.querySelector("canvas");
        if (canvas) {
            toPng(canvas)
                .then((dataUrl) => {
                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = `${experienceName}-qrcode.png`;
                    link.click();
                })
                .catch((err) => {
                    console.error("Couldn't generate qrCode", err);
                });
        }
    };

    return (
        <AdminContainer>
            <Container className="py-4">
                <Row className="d-flex flex-column align-items-center">
                    <Col md={5}>
                        <h1>QR Code Generator</h1>
                        {experience.state === "LOADING" && (
                            <Placeholder as={"h2"} animation="glow">
                                <Placeholder xs={12} />
                            </Placeholder>
                        )}
                        {experience.state === "DONE" && <h2>{experience.data.experience_name}</h2>}
                        {experience.state === "ERROR" && <h2>Experience name NOT Found...</h2>}
                    </Col>
                    <Col md={5} className="d-flex flex-column align-items-center">
                        <p>
                            Here is your QR code. You can download it as a PNG file or print it directly. Use the slider
                            to adjust the size of the QR code before downloading or printing.
                        </p>
                        <p>
                            You can also scan this QR Code directly in your in person sessions, and access to the
                            relevant experience.
                        </p>

                        <Card>
                            <Card.Body>
                                <Alert key="light" variant="light">
                                    Here is your QR Code <Alert.Link href={url}>link</Alert.Link> that you can use to
                                    access.
                                </Alert>
                                <div className="mb-3" style={{ width: 400, height: 400 }}>
                                    <QRCodeCanvas value={url} size={400} />
                                </div>
                                <div ref={downloadRef} style={{ position: "absolute", left: "-9999px" }}>
                                    <QRCodeCanvas value={url} size={400} />
                                </div>
                                <Button variant="primary" className="mb-2 w-100" onClick={downloadQRCode}>
                                    Download as PNG
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </AdminContainer>
    );
};

export default GenerateQRCode;