import { useContext, createContext } from "react";
import L from "leaflet";
import { DialoguePlatformMap } from "./DialoguePlatformMap";

export type LeafletMapContextType = {
    map: DialoguePlatformMap;
    setMap: (map: DialoguePlatformMap) => void;
};

export const MapContext = createContext<LeafletMapContextType | null>(null);

export const MapContextProvider = MapContext.Provider;

export const useMapContext = () => {
    const context = useContext(MapContext);
    if (context === null) {
        throw new Error("MapContext can only be accessed from within the SessionLeafletMap Component");
    }
    return context;
};
