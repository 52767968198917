import React from "react";
import Experience from "../../database_types/experience";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArchivedExperienceRow from "./ArchivedExperienceRow";
import { sortBy } from "lodash";

type Props = {
    experiences: Experience[];
    onResurrect: (experienceUuid: string) => void;
    onDestroy: (experienceUuid: string) => void;
};

const ArchivedExperienceRows: React.FC<Props> = ({ experiences, onResurrect, onDestroy }) => {
    const sortedByDate = sortBy(experiences, (exp: Experience) => {
        return exp.archived_at;
    });
    return (
        <>
            <Row className="experienceRowHeader align-items-end">
                <Col md="3">Company & Experience</Col>
                <Col md="2">Archived Date</Col>
                <Col md="1">Link for reporting</Col>
                <Col md="1">Contact</Col>
            </Row>

            {sortedByDate.map((experience) => (
                <ArchivedExperienceRow
                    key={experience.id}
                    experience={experience}
                    onResurrect={onResurrect}
                    onDestroy={onDestroy}
                />
            ))}
        </>
    );
};
export default ArchivedExperienceRows;
