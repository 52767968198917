import React from "react";
import { Form, Button, Alert, Card } from "react-bootstrap";
import { FieldErrors, UseFormRegister } from "react-hook-form";

export type FormData = {
    workShopId: string;
    tableId: string;
};

type WorkShopIdFormProps = {
    register: UseFormRegister<FormData>;
    onSubmit: () => Promise<void>;
    errors: FieldErrors<FormData>;
    formValues: {
        workShopId: string;
        tableId: string;
    };
};

const WorkShopIdForm: React.FC<WorkShopIdFormProps> = ({ onSubmit, register, errors, formValues }) => {
    return (
        <>
            <Card.Text className="text-center font-weight-bold mb-4">
                Please Enter Your Workshop ID and Table ID
            </Card.Text>
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Workshop ID:</Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={formValues.workShopId}
                        {...register("workShopId", {
                            required: "Workshop ID is required",
                            pattern: {
                                value: /^[A-Za-z0-9]+$/i,
                                message: "Only letters and numbers are allowed",
                            },
                        })}
                    />
                    {errors.workShopId && (
                        <Alert variant="warning" className="mt-1">
                            {errors.workShopId.message}
                        </Alert>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Table ID:</Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={formValues.tableId}
                        {...register("tableId", {
                            required: "Table ID is required",
                            pattern: {
                                value: /^[A-Za-z0-9]+$/i,
                                message: "Only letters and numbers are allowed",
                            },
                        })}
                    />
                    {errors.tableId && (
                        <Alert variant="warning" className="mt-1">
                            {errors.tableId.message}
                        </Alert>
                    )}
                </Form.Group>
                <div className="d-flex justify-content-center mt-5">
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default WorkShopIdForm;
