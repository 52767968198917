import { EndingOptions } from "../../content-types";
import { SessionState } from "../../types";

export function endingOptionsSelector(state: SessionState): EndingOptions | null {
    const endingOptions = state.script.endingOptions;
    if (!endingOptions) {
        return null;
    }

    return endingOptions;
}
