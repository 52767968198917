import { getUnixTime } from "date-fns";
import { every } from "lodash-es";
import {
    getPoolOfEligibleParticipantsForNavigatorRole,
    isActiveParticipant,
    isValidStepChange,
    setMarkupIconVisibility,
    updateIntroStepState,
} from "modules/utils";
import { GoToStep, GoToStepOptionsResolutions } from "../session-events";
import { SessionState } from "../types";
import { isIntroStepAddress, isStepAddress } from "../utils";
import { EventReducerDefinition } from "./types";
import applyStepOptions from "./apply-step-options";

const goToStep: EventReducerDefinition<GoToStep, GoToStepOptionsResolutions | null> = {
    predictable: false,
    checkIfApplicable: (state, payload, resolution) =>
        isValidStepChange(state, payload) &&
        (!resolution ||
            !resolution.rolesAssigned ||
            every(resolution.rolesAssigned.map((p) => p && isActiveParticipant(state, p)))),
    reducer: (state: SessionState, payload: GoToStep, resolution: GoToStepOptionsResolutions | null) => {
        if (payload.from === null || payload.from === undefined) {
            throw new Error("Invalid state: current step address is not an array of numbers");
        }
        let stateBeforeOptions = {
            ...state,
            timeStepStarted: getUnixTime(new Date()),
        };
        const currentTime = getUnixTime(new Date());
        let updatedState = setMarkupIconVisibility(state, payload.to);

        // first layer: are we coming from an intro step or not
        if (isIntroStepAddress(payload.from)) {
            // case 1 - intro step to intro step
            if (isIntroStepAddress(payload.to)) {
                updatedState.currentIntroStep = payload.to;
                updatedState.isIntroStep = true;
                stateBeforeOptions = {
                    ...updatedState,
                    currentIntroStep: payload.to,
                    isIntroStep: true,
                };
                stateBeforeOptions =
                    payload.to.iceBreakerNavPoolIndex === "intro" && payload.to.introStepKey === "iceBreaker"
                        ? {
                              ...updatedState,
                              currentIntroStep: payload.to,
                              iceBreakerNavigatorPool: getPoolOfEligibleParticipantsForNavigatorRole(
                                  updatedState.participants,
                                  updatedState.participants.map((p) => p.id),
                                  "",
                              ),
                              isIntroStep: true,
                          }
                        : {
                              ...updatedState,
                              currentIntroStep: payload.to,
                              isIntroStep: true,
                          };
            } else {
                // case 2 - intro step to non-intro step
                updatedState.currentStep = payload.to;
                updatedState.currentIntroStep = {
                    introStepKey: payload.from.introStepKey,
                    iceBreakerNavPoolIndex: "complete",
                };
                updatedState.isIntroStep = false;
                stateBeforeOptions = {
                    ...updatedState,
                    currentStep: payload.to,
                    isIntroStep: false,
                };
            }
            //intro step time calculation
            stateBeforeOptions = {
                ...stateBeforeOptions,
                introStepStates: updateIntroStepState(
                    stateBeforeOptions.introStepStates,
                    payload.from.introStepKey,
                    (introStepState) => ({
                        ...introStepState,
                        timeElapsed: introStepState.timeElapsed + currentTime - state.timeStepStarted,
                    }),
                ),
                timeStepStarted: currentTime,
            };

            return applyStepOptions(stateBeforeOptions, payload.options, resolution);
        } else {
            if (isStepAddress(payload.to)) {
                if (payload.from === "exit" || updatedState.currentStep === "exit") {
                    // case 3 - exit step to non-intro step
                    updatedState.currentStep = payload.to;
                    return {
                        ...updatedState,
                        currentStep: payload.to,
                        timeStepStarted: currentTime,
                    };
                }

                let section = updatedState.stepStates[updatedState.currentStep.sectionKey];
                // we don't care about tracking time elapsed on our progress check steps
                if (updatedState.currentStep.stepKey !== "ProgressCheck") {
                    const stepState = section[updatedState.currentStep.stepKey];
                    const timeElapsed = stepState.timeElapsed + currentTime - updatedState.timeStepStarted;
                    section = {
                        ...section,
                        [updatedState.currentStep.stepKey]: {
                            ...stepState,
                            timeElapsed: timeElapsed,
                        },
                    };
                }

                stateBeforeOptions = {
                    ...updatedState,
                    currentStep: payload.to,
                    stepStates: {
                        ...updatedState.stepStates,
                        [updatedState.currentStep.sectionKey]: section,
                    },
                    timeStepStarted: currentTime,
                };

                if (payload.to === "exit") {
                    stateBeforeOptions["sessionReachedExit"] = true;
                    return stateBeforeOptions;
                }
            } else {
                // non-intro to intro
                updatedState = {
                    ...updatedState,
                    currentIntroStep: payload.to,
                    isIntroStep: true,
                };
                stateBeforeOptions = {
                    ...updatedState,
                    timeStepStarted: currentTime,
                };
                if (payload.from !== "exit" && payload.from.stepKey !== "ProgressCheck") {
                    let section = state.stepStates[payload.from.sectionKey];
                    const stepState = section[payload.from.stepKey];
                    const timeElapsed = stepState.timeElapsed + currentTime - state.timeStepStarted;
                    section = {
                        ...section,
                        [payload.from.stepKey]: {
                            ...stepState,
                            timeElapsed: timeElapsed,
                        },
                    };
                    stateBeforeOptions = {
                        ...stateBeforeOptions,
                        stepStates: {
                            ...stateBeforeOptions.stepStates,
                            [payload.from.sectionKey]: section,
                        },
                    };
                }
            }
            return applyStepOptions(stateBeforeOptions, payload.options, resolution);
        }
    },
};
export default goToStep;
