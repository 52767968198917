import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { createCurrentStepDefinitionSelector } from "../generic/createCurrentStepDefinitionSelector";

export function createAssetMarkupIconsLimitSelector(): (state: SessionState) => number {
    return createSelector([createCurrentStepDefinitionSelector("singleAssetStep")], (stepDef) => {
        if (!stepDef) {
            return 0;
        }
        const markupIconsLimit = stepDef?.markupIconsLimit;

        if (!markupIconsLimit) {
            return Infinity;
        }
        return markupIconsLimit;
    });
}
