import { updateSessionState } from "modules/utils";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState): ApplicationState => {
    return updateSessionState(state, (session: SessionStateContainer) => {
        if (session.state === "READY") {
            return {
                ...session,
                state: "DISCONNECTED",
            };
        } else {
            throw new Error(`Received DISCONNECT_FROM_SESSION in invalid state ${session.state}`);
        }
    });
};
