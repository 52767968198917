import { updateParticipant, isActiveParticipant } from "modules/utils";
import { SurveyTaken } from "../../session-events";
import { SessionState } from "../../types";

export const SURVEY_TAKEN = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: SurveyTaken): boolean => {
        return isActiveParticipant(state, payload);
    },
    reducer: (state: SessionState, payload: SurveyTaken): SessionState => {
        return {
            ...state,
            participants: updateParticipant(state.participants, payload.participantId, (participant) => {
                return {
                    ...participant,
                    surveyTaken: true,
                };
            }),
        };
    },
};
