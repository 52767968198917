import RichText from "modules/components/RichText";
import { VideoStep } from "modules/shared/content-types";
import { BaseActivityProps } from "modules/shared/types";
import React from "react";
import "./video-activity.scss";

const VideoActivity: React.FC<VideoStep & BaseActivityProps> = (props) => {
    return (
        <div className="video-player-container">
            <RichText document={props.content} />
        </div>
    );
};

export default VideoActivity;
