import { SessionState, StepState } from "../../types";
import { getStepState } from "./getStepState";

export function createCurrentStepStateSelector<
    TType extends StepState["type"],
    TStepState extends StepState & { type: TType },
>(t: TType) {
    return (sessionState: SessionState): TStepState | null => {
        if (sessionState.currentStep === "exit") {
            const step = {
                type: "EXIT",
                transient: null,
                persistent: null,
            };
            return step as TStepState;
        }

        return getStepState(t, sessionState);
    };
}
