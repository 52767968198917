import React from "react";
import { Modal } from "react-bootstrap";
import { useLocalizedString } from "../../client/localization";

type PreviewModeAboutModalProps = {
    isAboutModalOpen: boolean;
    setIsAboutModalOpen: () => void;
};

const PreviewModeAboutModal: React.FC<PreviewModeAboutModalProps> = (props) => {
    const localized = useLocalizedString();

    return (
        <Modal className="preview-mode-modal" show={props.isAboutModalOpen} onHide={props.setIsAboutModalOpen} centered>
            <Modal.Header closeButton>
                <Modal.Title>{localized("toolbar_previewModeModalTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="preview-mode-overview">
                    <span>{localized("toolbar_previewModeModalFirstIntroduction")}</span>
                    <span>{localized("toolbar_previewModeModalSecondIntroduction")}</span>
                </div>
                <h5>{localized("toolbar_previewModeModalReloadContent")}</h5>
                <span>{localized("toolbar_previewModeModalReloadContentDescription")}</span>
                <h5>{localized("toolbar_previewModeModalComments")}</h5>
                <span>{localized("toolbar_previewModeModalCommentsDescription")}</span>
                <h5>{localized("toolbar_previewModeModalMapCoordinates")}</h5>
                <span>{localized("toolbar_previewModeModalMapCoordinatesDescription")}</span>
                <h5>{localized("toolbar_previewModeModalHotspotSelector")}</h5>
                <span>{localized("toolbar_previewModeModalHotspotSelectorDescription")}</span>
            </Modal.Body>
        </Modal>
    );
};

export default PreviewModeAboutModal;
