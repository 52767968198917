import { keyBy, throttle } from "lodash";
import createChangeName from "modules/client/event-factories/createChangeName";
import createParticipantReady from "modules/client/event-factories/createParticipantReady";
import { useClientLocale, useLocalizedString } from "modules/client/localization";
import {
    useSessionDispatch,
    useSessionParticipantId,
    useSessionSelector,
} from "modules/components/SessionRenderer/context";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { readyParticipants } from "modules/shared/selectors/participants/readyParticipants";
import { sessionStarted } from "modules/shared/selectors/navigation-state/sessionStarted";
import { ChangeNameValidationRules } from "modules/shared/sessionEventValidator";
import { ParticipantInfo, ReadyParticipant } from "modules/shared/types";
import React, { useCallback, useState } from "react";
import { Alert, Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { getNameInitials } from "modules/utils";
import "./name-entry.scss";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { createEnableNavigatorSelector } from "modules/shared/selectors/intro-sequence/createEnableNavigatorSelector";
import ConfirmationDialogue from "../ConfirmationDialog";
import createAssignFacilitator from "modules/client/event-factories/createAssignFacilitator";
import { navigationRoleFilled } from "modules/shared/selectors/participants/navigationRoleFilled";

type FormData = { participantIdentifier: string };

const NameEntryContainer: React.FC = () => {
    const localized = useLocalizedString();
    const participantLocale = useClientLocale();
    const hasStarted = useSessionSelector(sessionStarted);
    const participantId = useSessionParticipantId();
    const participants = useSessionSelector(readyParticipants);
    const participantList = keyBy(participants, (p: ParticipantInfo) => {
        if (p.name && p.id !== participantId) {
            return p.name.toLowerCase();
        }
        return null;
    });

    const dispatch = useSessionDispatch();

    const participantName = useSessionSelector(createParticipantSelector(participantId))?.name;
    const isNavigatorEnabledByParticipant = useSessionSelector(createEnableNavigatorSelector());
    const isNavigatorRoleFilled = useSessionSelector(navigationRoleFilled);

    const [nameEntered, setNameEntered] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const ChangeNameSchema = object().shape({
        participantIdentifier: string()
            .test("duplicate_name", "", function (value: string | undefined) {
                if (value && value.toLowerCase() in participantList) {
                    return this.createError({
                        message: localized("vdpNameEntry_identifiersDuplicateName", value),
                    });
                }
                return true;
            })
            .trim()
            .required(localized("vdpNameEntry_identifiersName"))
            .min(
                ChangeNameValidationRules.name.minLength,
                localized("vdpValidationErrorMinLength", ChangeNameValidationRules.name.minLength),
            )
            .max(
                ChangeNameValidationRules.name.maxLength,
                localized("vdpValidationErrorMaxLength", ChangeNameValidationRules.name.maxLength),
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(ChangeNameSchema),
    });

    const onSaveName = handleSubmit((data: FormData) => {
        if (participantId) {
            dispatch(
                createChangeName({
                    participantId: participantId,
                    participantName: data.participantIdentifier,
                }),
            );
            setNameEntered(true);
            dispatch(
                createParticipantReady({
                    participantId: participantId,
                    languageCode: participantLocale,
                }),
            );
        }
    });

    const handleNavigator = useCallback(() => {
        if (participantId) {
            dispatch(
                createAssignFacilitator({
                    participantId: participantId,
                }),
            );
        }
    }, [participantId, dispatch]);

    return (
        <div className="welcome-container">
            {isNavigatorEnabledByParticipant && showModal && (
                <ConfirmationDialogue
                    title={localized("confirmDialog_enable_NavigatorTitle")}
                    body={localized("confirmDialog_enable_NavigatorBody")}
                    action={handleNavigator}
                    onCloseCb={() => setShowModal(false)}
                />
            )}
            {nameEntered && (
                <Alert className="thanks-message" variant="success">
                    {localized("vdpNameEntry_thanks", participantName!)}
                </Alert>
            )}
            <h2>{localized("vdpNameEntry_welcomeText")}</h2>
            <p className="welcome-text">{localized("vdpNameEntry_defaultWelcomeMessage")}</p>
            <ParticipantBubbles participants={participants} />
            <p className="num-participants-string">
                {participants.length === 1
                    ? localized("vdpNameEntry_oneParticipantsInSession")
                    : localized("vdpNameEntry_numParticipantsInSession", participants.length)}
            </p>
            {isNavigatorEnabledByParticipant && !!participantName && !isNavigatorRoleFilled && (
                <>
                    <p className="welcome-text">{localized("vdpNameEntry_NavigatorSelectionMessage")}</p>
                    <Button variant="primary" onClick={() => setShowModal(true)}>
                        {localized("vdpNameEntry_NavigatorButtonMessage")}
                    </Button>
                </>
            )}

            {!nameEntered && (
                <div className="name-entry-form">
                    <Form onSubmit={onSaveName}>
                        <Form.Group as={Col} controlId="participantIdentifier">
                            <Form.Label id="name-entry-input">{localized("vdpNameEntry_inputName")}</Form.Label>
                            <Row className="g-0">
                                <Col className="form-container">
                                    <Form.Control
                                        data-testid="form-control-name-entry"
                                        autoComplete="nickname participant-identifier-no-auto-fill"
                                        data-lpignore="true"
                                        placeholder={localized("vdpNameEntry_inputNamePlaceholder")}
                                        isInvalid={!!errors.participantIdentifier?.message}
                                        aria-labelledby="name-entry-input"
                                        {...register("participantIdentifier")}
                                    />
                                    <Form.Text className="text-danger">
                                        {errors.participantIdentifier?.message}
                                    </Form.Text>
                                </Col>
                                <NameFormButton sessionStarted={hasStarted} />
                            </Row>
                        </Form.Group>
                    </Form>
                </div>
            )}
        </div>
    );
};

const ParticipantBubbles: React.FC<{ participants: ReadyParticipant[] }> = (props) => {
    const BUBBLE_LIMIT = 6;
    let participantName: string;
    const INITIALS_LIMIT = 3;

    const overflowNames: string[] = [];
    props.participants.map((p, i) => {
        if (i >= BUBBLE_LIMIT) {
            overflowNames.push(" " + p.name);
        }
    });
    const names = overflowNames.toString();

    return (
        <TransitionGroup className="participant-bubbles">
            {props.participants.length === 0 && (
                <CSSTransition timeout={500} classNames="bubble-transition">
                    <ParticipantBubble label={"..."} trueName={"..."} />
                </CSSTransition>
            )}
            {props.participants.map((p, i) => {
                if (i <= BUBBLE_LIMIT - 1) {
                    participantName = getNameInitials(p.name, INITIALS_LIMIT);

                    return (
                        <CSSTransition key={p.id} timeout={500} classNames="bubble-transition">
                            <ParticipantBubble key={p.id} label={participantName} trueName={p.name} />
                        </CSSTransition>
                    );
                }
            })}
            {props.participants.length > BUBBLE_LIMIT && (
                <CSSTransition timeout={500} classNames="bubble-transition">
                    <ParticipantBubble label={`+${props.participants.length - BUBBLE_LIMIT}`} trueName={`${names}`} />
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

function updatePopper(overlayProps: OverlayInjectedProps): void {
    const { popper, show } = overlayProps;
    if (show && !!popper && !!popper.scheduleUpdate) {
        popper.scheduleUpdate();
    }
}

const throttledUpdatePopper = throttle(updatePopper, 250);

const ParticipantBubble: React.FC<{ label: string; trueName: string }> = (props) => {
    const renderTooltip = (overlayProps: OverlayInjectedProps) => {
        throttledUpdatePopper(overlayProps);
        return <Tooltip {...overlayProps}>{props.trueName}</Tooltip>;
    };

    return (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
            <div className="participant-bubble g-0">{props.label}</div>
        </OverlayTrigger>
    );
};

type NameFormButtonProps = {
    sessionStarted: boolean;
};

const NameFormButton: React.FC<NameFormButtonProps> = (props) => {
    const localized = useLocalizedString();

    return (
        <Col>
            <Button
                className="submit-button"
                type="submit"
                aria-description={
                    props.sessionStarted
                        ? localized("vdpNameEntry_continueDescription")
                        : localized("vdpNameEntry_saveDescription")
                }
            >
                {props.sessionStarted ? localized("vdpNameEntry_continue") : localized("vdpNameEntry_save")}
            </Button>
        </Col>
    );
};

export default NameEntryContainer;
