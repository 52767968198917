import { isNull } from "lodash-es";
import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { MainStepAddress } from "../../../types";
import { getStepAddressByStepEntryId } from "../../step-address/getStepAddressByStepEntryId";
import { scriptSelector } from "../generic/scriptSelector";
import { createSingleAssetPreviousMarkupStepIdsSelector } from "./createSingleAssetPreviousMarkupStepIdsSelector";

export function createSingleAssetPreviousMarkupStepAddressSelector(): (state: SessionState) => MainStepAddress[] {
    return createSelector([createSingleAssetPreviousMarkupStepIdsSelector(), scriptSelector], (stepIds, script) => {
        return stepIds
            .map((entryId) => getStepAddressByStepEntryId(script, entryId))
            .filter((addr): addr is MainStepAddress => !isNull(addr));
    });
}
