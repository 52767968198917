import { createSelector } from "reselect";
import { ExperienceScript } from "../../content-types";
import { SessionState } from "../../types";
import { scriptSelector } from "../step-definition/generic/scriptSelector";

export function createIntroIsDisabledSelector(): (s: SessionState) => boolean {
    return createSelector([scriptSelector], (script: ExperienceScript) => {
        return introIsDisabled(script);
    });
}

export function introIsDisabled(script: ExperienceScript): boolean {
    return !!script.welcomeOptions && !!script.welcomeOptions?.disableIntro;
}
