import classNames from "classnames";
import React from "react";
import { Variant } from "react-bootstrap/esm/types";
import { BsPrefixProps } from "react-bootstrap/helpers";
import { IconBaseProps, IconType } from "react-icons";
import Markdown from "../RichText/Markdown";
import "./content-body.scss";

export type ContentBlock = {
    title: string;
    content: string;
    instruction?: string;
    icon?: IconType;
    iconProps?: IconBaseProps;
};

interface ContentBodyProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
    contentBlocks: ContentBlock[];
    variant?: Variant;
    badge?: JSX.Element;
}

const ContentBody: React.FC<ContentBodyProps> = (props) => {
    const { contentBlocks, variant, badge, className, ...remainingProps } = props;
    const contentBodyClassName = classNames("content-body", className, {
        [`content-body-${variant}`]: !!variant,
    });
    const contentBlockComponents = contentBlocks.map((contentBlock, index) => {
        const { title, content, instruction, icon, iconProps } = contentBlock;
        return (
            <div className="content-block-container" key={`content-block-${index}`}>
                <div className="content-block">
                    <div className="content-title-container">
                        {!!icon && icon(!!iconProps ? iconProps : { size: 15 })}
                        <span className="content-title">{title}</span>
                    </div>
                    {!!instruction && <span className="content-instruction">{instruction}</span>}
                    <Markdown className="content-text" text={content} />
                </div>
            </div>
        );
    });

    return contentBlocks.length > 0 ? (
        <div className={contentBodyClassName} {...remainingProps}>
            {badge && <div className="badge-content">{badge}</div>}
            <div className="content-blocks-container">{contentBlockComponents}</div>
        </div>
    ) : (
        <></>
    );
};

export default ContentBody;
