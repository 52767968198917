import { yupResolver } from "@hookform/resolvers/yup";
import { parseISO } from "date-fns";
import { ContentfulComment, ParticipantMessage } from "modules/client/application-state";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { useLocalizedString } from "modules/client/localization";
import { sessionContentfulManagementComments } from "modules/client/selectors";
import { contentfulManagementRequest } from "modules/client/services/contentful";
import { ContentfulManagementFetchTypes } from "modules/shared/contentful-mgmt-types";
import { createCurrentStepEntryIdSelector } from "modules/shared/selectors/step-address/createCurrentStepEntryIdSelector";
import React, { useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import BootstrapCard from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { object, string } from "yup";
import { useSessionSelector } from "../SessionRenderer/context";
import StepInfo from "./StepInfo";

type CommentCreationProps = {
    cancel: () => void;
    openModal: () => void;
    inWelcomeMode: boolean;
};

type CommentCreationInputs = {
    body: string;
};

const CommentCreationView: React.FC<CommentCreationProps> = (props: CommentCreationProps) => {
    const localized = useLocalizedString();
    const dispatch = useDispatch();
    const [postButtonState, setPostButtonState] = useState<"SENDING" | "ENABLED" | "DISABLED">("DISABLED");
    const currentStepEntryId = useSessionSelector(createCurrentStepEntryIdSelector());
    const currentCommentsList = useSelector(sessionContentfulManagementComments);

    const minBodyLength = 2;
    const CommentCreationSchema = object().shape({
        body: string()
            .required()
            .trim()
            .min(minBodyLength, localized("vdpValidationErrorMinLength", minBodyLength))
            .max(512, localized("vdpValidationErrorMaxLength", 512)),
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<CommentCreationInputs>({
        resolver: yupResolver(CommentCreationSchema),
    });

    const sendComment = React.useCallback(
        async (data: CommentCreationInputs) => {
            const dat = {
                body: data.body,
                entryId: currentStepEntryId,
            };
            const postedComment = await contentfulManagementRequest("PostComment", dat).then(
                (resp): Promise<ContentfulManagementFetchTypes["PostComment"]["resp"]> =>
                    resp.json() as unknown as Promise<ContentfulManagementFetchTypes["PostComment"]["resp"]>,
            );
            let message: ParticipantMessage;
            if (postedComment.type === "SUCCESS") {
                // create success message for our toast
                message = {
                    id: v4(),
                    message: `Comment successfully posted for entry ID ${postedComment.response.sys.parentEntity.sys.id}`,
                    success: true,
                };

                // append comment to our list of already fetched comments
                const commentSuccessfullyPosted: ContentfulComment = {
                    enteredAt: parseISO(postedComment.response.sys.createdAt),
                    body: postedComment.response.body,
                    commentId: postedComment.response.sys.id,
                    version: postedComment.response.sys.version,
                    ownerId: postedComment.response.sys.createdBy?.sys.id ?? "",
                };
                dispatch({
                    type: "UPDATE_CONTENTFUL_MANAGEMENT",
                    payload: {
                        comments: [...currentCommentsList, commentSuccessfullyPosted],
                    },
                });
            } else {
                // create failure message for our toast
                message = {
                    id: v4(),
                    message: `Comment failed to send to contentful with the following message: ${postedComment.message}`,
                    success: false,
                };
            }

            // send message to state to be displayed as a toast
            const participantMessageEvent = createParticipantMessageReceived({
                payload: message,
            });
            dispatch(participantMessageEvent);
        },
        [currentStepEntryId, dispatch, currentCommentsList],
    );

    const onSubmit = handleSubmit(async (data) => {
        setPostButtonState("SENDING");
        await sendComment(data);
        reset();
        setPostButtonState("ENABLED");
    });
    const onCancel = () => {
        props.cancel();
    };
    const onClickGoToCommentList = React.useCallback(() => {
        props.openModal();
    }, [props]);

    const evaluateFormButtonState = () => {
        const bodyFieldValue = getValues("body");
        if (bodyFieldValue.length < minBodyLength && postButtonState === "ENABLED") {
            setPostButtonState("DISABLED");
        } else if (bodyFieldValue.length >= minBodyLength && postButtonState === "DISABLED") {
            setPostButtonState("ENABLED");
        }
    };

    return (
        <>
            <Stack direction="horizontal">
                <BootstrapCard.Title>{localized("toolbar_commentCreationTitle")}</BootstrapCard.Title>
                <Button className="ms-auto" variant="link" onClick={onClickGoToCommentList}>
                    {localized("toolbar_commentListButton", currentCommentsList.length)}
                </Button>
            </Stack>
            <StepInfo inWelcomeMode={props.inWelcomeMode} />
            <Form onSubmit={onSubmit}>
                <Row>
                    <Form.Group controlId="commentBody">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder={localized("toolbar_commentCreationPlaceholder")}
                            isInvalid={!!errors?.body}
                            {...register("body", {
                                onChange: evaluateFormButtonState,
                            })}
                        />
                    </Form.Group>
                    {errors?.body && <Form.Text className="text-danger">{errors.body.message}</Form.Text>}
                </Row>
                <Row>
                    <Col>
                        <div className="comment-creation-description">
                            {localized("toolbar_commentCreationDescription")}
                        </div>
                    </Col>
                </Row>
                <Row className="actions-container">
                    <Col>
                        <Stack direction="horizontal" gap={2}>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={postButtonState === "SENDING" || postButtonState === "DISABLED"}
                            >
                                {postButtonState === "ENABLED" || postButtonState === "DISABLED"
                                    ? localized("toolbar_commentCreationSubmitButton")
                                    : ""}
                                {postButtonState === "SENDING"
                                    ? localized("toolbar_commentCreationSubmitButtonDisabled")
                                    : ""}
                            </Button>
                            <Button variant="outline-primary" onClick={onCancel}>
                                {localized("toolbar_commentCreationCancelButton")}
                            </Button>
                        </Stack>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
export default CommentCreationView;
