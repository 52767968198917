import { Action } from "modules/client/actions-types";
import { useLocalizedString } from "modules/client/localization";
import { createReportFilterSelector } from "modules/client/selectors/session-report";
import React, { Dispatch, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { ReportType } from "modules/client/session-report-state";
import createEditingReportFilters from "modules/client/event-factories/session-report/createEditingReportFilters";
import { isNull } from "lodash";

const ReportSwitch: React.FC = () => {
    const localized = useLocalizedString();
    const dispatch = useDispatch<Dispatch<Action>>();
    const { type: selectedReportType } = useSelector(createReportFilterSelector());

    const reportTypesAndLabels: { type: ReportType; displayValue: string }[] = useMemo(
        () => [
            { type: "SessionBasicInfo", displayValue: localized("sessionReport_switch_basicInfo") },
            { type: "MultipleChoiceBasicInfo", displayValue: localized("multipleChoiceReport_switch_basicInfo") },
            {
                type: "MultipleChoiceBasicInfoAggregated",
                displayValue: localized("multipleChoiceReport_switch_basicInfoAggregated"),
            },
            { type: "ActivityBasicInfo", displayValue: localized("activityReport_switch_basicInfo") },
            {
                type: "ActivityBasicInfoAggregated",
                displayValue: localized("activityReport_switch_basicInfoAggregated"),
            },
            { type: "DragAndDropBasicInfo", displayValue: localized("dragAndDropReport_switch_basicInfo") },
            {
                type: "DragAndDropBasicInfoAggregated",
                displayValue: localized("dragAndDropReport_switch_basicInfoAggregated"),
            },
            { type: "EditableCardBasicInfo", displayValue: localized("editableCardReport_switch_basicInfo") },
        ],
        [localized],
    );
    const reportTypes = useMemo(() => reportTypesAndLabels.map(({ type }) => type), [reportTypesAndLabels]);

    const switchReportType = useCallback(
        (reportType: ReportType) => {
            if (selectedReportType === reportType) {
                return;
            }
            dispatch(createEditingReportFilters(reportType));
        },
        [dispatch, selectedReportType],
    );

    const validateReportType = useCallback(
        (reportType: string | null): reportType is ReportType => {
            if (!reportType || isNull(reportType)) {
                return false;
            }

            return reportTypes.includes(reportType as ReportType);
        },
        [reportTypes],
    );

    const onSelect = useCallback(
        (eventKey: string | null, _event: React.SyntheticEvent<unknown>) => {
            if (validateReportType(eventKey)) return switchReportType(eventKey);
        },
        [switchReportType, validateReportType],
    );

    return (
        <div>
            <Dropdown onSelect={onSelect}>
                <Dropdown.Toggle variant={"success"}>
                    {selectedReportType
                        ? reportTypesAndLabels.find(({ type }) => type === selectedReportType)?.displayValue
                        : "Select Report Type"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {reportTypesAndLabels.map(({ type, displayValue }) => (
                        <Dropdown.Item key={type} eventKey={type} active={type === selectedReportType}>
                            {displayValue}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
export default ReportSwitch;
