import * as React from "react";

import ErrorModal from "../ErrorModal";
import { Logger } from "modules/utils/logger";

export type Props = {
    onError?: (error: Error, info: React.ErrorInfo) => void;
    children: JSX.Element;
};

const initialState = { hasError: false };
type State = Readonly<typeof initialState>;

export class ErrorBoundary extends React.Component<Props, State> {
    readonly state: State = initialState;

    static getDerivedStateFromError(error: Error): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        Logger.error(error, info);

        if (this.props.onError) {
            this.props.onError(error, info);
        }
    }

    render(): JSX.Element {
        if (this.state.hasError) {
            return <ErrorModal />;
        }

        return this.props.children;
    }
}
