import { keyBy, values } from "lodash-es";
import { MatchingCardDropped, MatchingCardPickedUp } from "modules/shared/session-events";
import { SessionState } from "modules/shared/types";
import { updateStepStates } from "modules/utils";
import { getStepScript } from "modules/shared/selectors/step-definition/generic/getStepScript";
import { getStepState } from "modules/shared/selectors/step-state/getStepState";
import { CardPositionState, MatchingInputValue } from "./types";
import { cardStateIsPositioned, cardStateIsUnpositioned, reorderUnpositionedCards } from "./utils";

function updateCardPosition(
    positions: CardPositionState[],
    cardId: string,
    updater: (card: CardPositionState) => CardPositionState,
): CardPositionState[] {
    const positionLookup = keyBy(positions, (cps) => cps.cardId);
    const cardPosition = positionLookup[cardId];

    if (!cardPosition) {
        throw new Error(`Received drag event for unknown card with id "${cardId}"`);
    }

    const updatedPosition = updater(cardPosition);

    const updatedPositions = {
        ...positionLookup,
    };
    updatedPositions[cardId] = updatedPosition;

    return values(updatedPositions);
}

export const MATCHING__CARD_PICKED_UP = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: MatchingCardPickedUp): boolean => {
        const stepState = getStepState("MATCHING", state);

        if (!stepState?.persistent) return false;
        const script = getStepScript("matchingStep", state, state.currentStep);

        if (!script) {
            return false;
        }

        const cardPosition = stepState.persistent.cardPositionState.find(
            (c) => c.cardId === payload.cardId && c.version === payload.version,
        );

        if (!cardPosition) return false;

        if (!cardPosition.heldByParticipant) return true;

        if (!!cardPosition.heldByParticipant) {
            const currentHolder = state.participants.find((p) => p.id === cardPosition.heldByParticipant);
            if (!currentHolder) {
                return true;
            }
        }

        return false;
    },
    reducer: (state: SessionState, payload: MatchingCardPickedUp): SessionState => {
        const script = getStepScript("matchingStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("MATCHING", state, state.currentStep, (stepState) => {
            const updatedPositions = updateCardPosition(
                stepState.persistent.cardPositionState,
                payload.cardId,
                (card: CardPositionState) => {
                    return {
                        ...card,
                        heldByParticipant: payload.participantId,
                        version: payload.version + 1,
                    };
                },
            );

            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardPositionState: updatedPositions,
                },
            };
        });

        return {
            ...state,
            stepStates,
        };
    },
};

export const MATCHING__CARD_DROPPED = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: MatchingCardDropped): boolean => {
        const script = getStepScript("matchingStep", state, state.currentStep);

        if (!script) {
            return false;
        }

        const stepState = getStepState("MATCHING", state);

        if (!stepState?.persistent) return false;

        const cardPosition = stepState.persistent.cardPositionState?.find(
            (c) => c.cardId === payload.cardId && c.version === payload.version,
        );

        if (!cardPosition) return false;

        const heldByParticipant = cardPosition?.heldByParticipant === payload.participantId;

        return heldByParticipant;
    },
    reducer: (state: SessionState, payload: MatchingCardDropped): SessionState => {
        const script = getStepScript("matchingStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("MATCHING", state, state.currentStep, (stepState) => {
            const cardPosition = stepState.persistent.cardPositionState.find((c) => c.cardId === payload.cardId);
            if (!cardPosition) {
                throw new Error(`Received drag event for unknown card with id "${payload.cardId}"`);
            }

            const otherCards: CardPositionState[] = stepState.persistent.cardPositionState.filter(
                (c) => c.cardId !== payload.cardId,
            );

            let updatedCardPosition: CardPositionState = {
                ...cardPosition,
                version: cardPosition.version + 1,
                heldByParticipant: null,
                gridId: payload.destinationGrid,
            };

            const destinationGridHasDifferentCard = stepState.persistent.cardPositionState?.find(
                (c) => c.gridId === payload.destinationGrid && c.cardId !== payload.cardId,
            );
            if (!!destinationGridHasDifferentCard && payload.destinationGrid !== MatchingInputValue) {
                updatedCardPosition = {
                    ...updatedCardPosition,
                    gridId: payload.sourceGrid,
                };
            }

            let unpositionedCards = otherCards.filter(cardStateIsUnpositioned);
            let positionedCards = otherCards.filter(cardStateIsPositioned);
            if (cardStateIsUnpositioned(updatedCardPosition) && cardStateIsUnpositioned(cardPosition)) {
                updatedCardPosition = {
                    ...updatedCardPosition,
                    idx: payload.idx ?? cardPosition?.idx ?? 0,
                };
                unpositionedCards = reorderUnpositionedCards(
                    [...unpositionedCards, updatedCardPosition],
                    payload.cardId,
                );
            } else if (cardStateIsUnpositioned(updatedCardPosition)) {
                updatedCardPosition = {
                    ...updatedCardPosition,
                    idx: payload.idx ?? 0,
                };
                unpositionedCards = reorderUnpositionedCards(
                    [...unpositionedCards, updatedCardPosition],
                    payload.cardId,
                );
            } else {
                unpositionedCards = reorderUnpositionedCards(unpositionedCards);
                positionedCards = [...positionedCards, updatedCardPosition];
            }

            const allUpdatedCardPositions = [...positionedCards, ...unpositionedCards];

            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardPositionState: allUpdatedCardPositions,
                },
            };
        });
        return {
            ...state,
            stepStates,
        };
    },
};

export const MATCHING__REVEAL_CORRECTNESS = {
    predictable: true,
    checkIfApplicable: (state: SessionState): boolean => {
        const stepState = getStepState("MATCHING", state);

        if (!stepState?.persistent) return false;

        return (
            !stepState.persistent.cardCorrectnessRevealed &&
            (!stepState.persistent.cardPositionStateOnReveal ||
                stepState.persistent.cardPositionStateOnReveal.length === 0)
        );
    },
    reducer: (state: SessionState): SessionState => {
        const script = getStepScript("matchingStep", state, state.currentStep);

        if (!script) {
            throw new Error("No script found for step " + state.currentStep);
        }

        const stepStates = updateStepStates("MATCHING", state, state.currentStep, (stepState) => {
            const initialPlacements = stepState.persistent.cardPositionState.slice();
            return {
                ...stepState,
                persistent: {
                    ...stepState.persistent,
                    cardCorrectnessRevealed: true,
                    cardPositionStateOnReveal: initialPlacements,
                },
            };
        });

        return {
            ...state,
            stepStates,
        };
    },
};
