import { SessionState } from "../../../types";
import { scriptSections } from "./scriptSections";

export function numberOfSections(state: SessionState): number {
    const sections = scriptSections(state);

    if (!sections) {
        return 0;
    }

    return sections.length;
}
