/* eslint-disable react/display-name */
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import classNames from "classnames";
import * as React from "react";
import "./RichText.scss";
import { EntryFields } from "contentful";

/*
 * Component used to render links. This will test to see if it's a link to a
 * video and if so will embed a player, so I made it a lazy component so that we
 * don't have to load the player code unless/until it's used. */
const LinkComponent = React.lazy(() => import("./Link"));

interface Props {
    document: EntryFields.RichText;
    className?: string;
    style?: React.CSSProperties;
}

const OPTIONS: Options = {
    renderNode: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { title, description, file } = node.data.target.fields;
            const mimeType = file.contentType;
            const mimeGroup = mimeType.split("/")[0];

            switch (mimeGroup) {
                case "image":
                    return (
                        <img
                            style={{ pointerEvents: "none" }}
                            title={title ? title : null}
                            alt={description ? description : null}
                            src={file.url}
                        />
                    );
                case "video":
                    return (
                        <div className="video-container">
                            <video
                                className="video-content"
                                controls={true}
                                src={file.url}
                                title={title ? title : null}
                            />
                        </div>
                    );
                case "application":
                    return (
                        <a style={{ pointerEvents: "none" }} href={file.url}>
                            {title ? title : file.details.fileName}
                        </a>
                    );
                default:
                    return (
                        <span style={{ pointerEvents: "none", backgroundColor: "red", color: "white" }}>
                            {mimeType} embedded asset
                        </span>
                    );
            }
        },
        [INLINES.HYPERLINK]: (node: any, children: any) => <LinkComponent node={node}>{children}</LinkComponent>,
        [INLINES.ASSET_HYPERLINK]: (node: any) => {
            const { title, description, file } = node.data.target.fields;
            const mimeType = file.contentType;
            const mimeGroup = mimeType.split("/")[0];

            switch (mimeGroup) {
                case "image":
                    return (
                        <img
                            style={{ pointerEvents: "none" }}
                            title={title ? title : null}
                            alt={description ? description : null}
                            src={file.url}
                        />
                    );
                case "application":
                    return (
                        <a href={file.url} target="_blank" rel="noreferrer" download={file.fileName}>
                            {title ? title : file.details.fileName}
                        </a>
                    );
                default:
                    return <span style={{ backgroundColor: "red", color: "white" }}> {mimeType} embedded asset </span>;
            }
        },
    },
    renderText: (text: string) => text.replaceAll(String.fromCharCode(160), " "), // replace all nbsp characters
};

const _RichText: React.FC<Props> = (props) => {
    if (!props.document || !props.document.content) {
        return <></>;
    }
    return (
        <div style={props.style} className={classNames("contentful-rich-text", props.className)}>
            {documentToReactComponents(props.document, OPTIONS)}
        </div>
    );
};

export const RichText = React.memo(_RichText);
export default RichText;
