import { ChangeName, SessionEvent } from "./session-events";

export function validateSessionEvent(event: SessionEvent): boolean {
    switch (event.type) {
        case "CHANGE_NAME":
            return validateChangeName(event);
        default:
            return true;
    }
}

type ChangeNameSchemaValidation = {
    [K in keyof Pick<ChangeName, "name">]: { maxLength: number; minLength: number };
};

// export this constant for use by both our validation helper and the client app
export const ChangeNameValidationRules: ChangeNameSchemaValidation = {
    name: {
        maxLength: 16,
        minLength: 2,
    },
};
function validateChangeName(event: ChangeName): boolean {
    if (
        event.name.length > ChangeNameValidationRules.name.maxLength ||
        event.name.length < ChangeNameValidationRules.name.minLength
    ) {
        return false;
    }

    return true;
}
