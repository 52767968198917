import React from "react";
import { Form, Button, Alert, Card } from "react-bootstrap";
import { FieldErrors, UseFormRegister } from "react-hook-form";

export type FormData = {
    participantCount: null;
};

type WorkShopIdFormProps = {
    register: UseFormRegister<FormData>;
    onSubmit: () => Promise<void>;
    errors: FieldErrors<FormData>;
    formValues: {
        participantCount: null;
    };
};

const ParticipantsForm: React.FC<WorkShopIdFormProps> = ({ onSubmit, register, errors, formValues }) => {
    return (
        <>
            <Card.Text className="text-center font-weight-bold mb-4">
                Please Provide the Following Information:
            </Card.Text>
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Enter Number of Participants:</Form.Label>
                    <Form.Control
                        type="number"
                        defaultValue={formValues.participantCount || ""}
                        {...register("participantCount", {
                            required: "Participant Count is required",
                            pattern: {
                                value: /^[0-9]+$/i,
                                message: "Please enter a number only",
                            },
                            min: {
                                value: 1,
                                message: "Participant count must be at least 1",
                            },
                        })}
                    />
                    {errors.participantCount && (
                        <Alert variant="warning" className="mt-1">
                            {errors.participantCount.message}
                        </Alert>
                    )}
                </Form.Group>
                <div className="d-flex justify-content-center mt-5">
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default ParticipantsForm;
