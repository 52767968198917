import classNames from "classnames";
import { useLocalizedString } from "modules/client/localization";
import {
    ComparisonStep,
    DragAndDropStep,
    MultipleChoiceStep,
    RevealCardStep,
    Role,
    SingleAssetStep,
    StepDefinition,
    TextEntryStep,
    VideoStep,
    RecallStep,
    MatchingStep,
    StepDefinitionKey,
    StepRecord,
    CardStackWithVisualStep,
    EditableCardStep,
} from "modules/shared/content-types";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { default as React, Suspense } from "react";
import { BaseActivityProps } from "../../shared/types";
import { participantHasRole } from "../../utils";
import LoadingBlock from "../LoadingBlock";
import { useSession, useSessionSelector } from "../SessionRenderer/context";
import ExitStep from "./ExitStep";
import "./activity-view.scss";
import CardStackWithVisualActivity from "./CardStackWithVisualActivity";
import ProgressCheck from "./ProgressCheck";
import SingleAssetActivity from "./SingleAssetActivity";
import ComparisonActivity from "./ComparisonActivity";
import VideoActivity from "./VideoActivity";
import useActivityAriaLabels from "./useActivityAriaLabels";

// I decided not to lazy-load the single asset activity, because it doesn't have
// anything that we won't have already loaded by this point.
const RevealCardActivity = React.lazy(() => import("./RevealCardActivity"));
const DragAndDropActivity = React.lazy(() => import("./DragAndDropActivity"));
const MatchingActivity = React.lazy(() => import("./MatchingActivity"));
const TextEntryActivity = React.lazy(() => import("./TextEntryActivity"));
const MultipleChoiceActivity = React.lazy(() => import("./MultipleChoiceActivity"));
const RecallStepActivity = React.lazy(() => import("./RecallStepActivity"));
const EditableCardActivity = React.lazy(() => import("./EditableCardActivity"));

const renderActivity = <ContentType extends StepDefinitionKey>(
    currentStep: StepDefinition & StepRecord<ContentType>,
    noActivityFoundText: string,
    showRotateNavigator: () => void,
    onIconHovered: (value: boolean) => void,
) => {
    const baseProps: BaseActivityProps = {
        showRotateNavigator: showRotateNavigator,
    };

    switch (currentStep.contentType) {
        case "cardStackWithVisualStep":
            return <CardStackWithVisualActivity {...(currentStep as CardStackWithVisualStep)} />;
        case "revealCardStep":
            return <RevealCardActivity {...(currentStep as RevealCardStep)} {...baseProps} />;
        case "dragAndDropStep":
            return <DragAndDropActivity {...(currentStep as DragAndDropStep)} />;
        case "matchingStep":
            return <MatchingActivity {...(currentStep as MatchingStep)} />;
        case "recallStep":
            return <RecallStepActivity {...(currentStep as RecallStep)} />;
        case "multipleChoiceStep":
            return <MultipleChoiceActivity {...(currentStep as MultipleChoiceStep)} {...baseProps} />;
        case "textEntryStep":
            return <TextEntryActivity {...(currentStep as TextEntryStep)} {...baseProps} />;
        case "editableCardStep":
            return <EditableCardActivity {...(currentStep as EditableCardStep)} {...baseProps} />;
        case "singleAssetStep":
            return (
                <SingleAssetActivity
                    {...(currentStep as SingleAssetStep)}
                    {...baseProps}
                    onIconHovered={onIconHovered}
                />
            );
        case "comparisonStep":
            return <ComparisonActivity {...(currentStep as ComparisonStep)} {...baseProps} />;
        case "videoStep":
            return <VideoActivity {...(currentStep as VideoStep)} {...baseProps} />;
        case "progressCheck":
            return <ProgressCheck />;
        case "exit":
            return <ExitStep />;
        default:
            return <>{noActivityFoundText}</>;
    }
};

type Props = {
    showRotateNavigator: () => void;
    isSidebarOpen: boolean;
    onIconHovered: (value: boolean) => void;
};

const ActivityView: React.FC<Props> = (props) => {
    const { participantId } = useSession();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isNavigator = !!participantInfo && participantHasRole(participantInfo, Role.Navigator);
    const currentStep = useSessionSelector(createGenericStepDefinitionSelector());
    const localized = useLocalizedString();
    const { ariaLabel, ariaDescription } = useActivityAriaLabels(currentStep);

    const currentActivityType = currentStep?.contentType;
    if (!currentActivityType || currentActivityType === "infoStep") {
        return <></>;
    }

    const noActivityFoundText = localized("vdpActivity_notFound", currentActivityType);
    const activityViewClasses = classNames(
        "flex-fill",
        "activity-view-component",
        { open: props.isSidebarOpen },
        { closed: !props.isSidebarOpen },
        { navigator: isNavigator },
    );
    return (
        <main
            aria-label={ariaLabel}
            aria-description={ariaDescription}
            className={activityViewClasses}
            id={"activity-view-id"}
        >
            <Suspense fallback={<LoadingBlock />}>
                {renderActivity(currentStep, noActivityFoundText, props.showRotateNavigator, props.onIconHovered)}
            </Suspense>
        </main>
    );
};

export default ActivityView;
