import { updateSessionState } from "modules/utils";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState): ApplicationState => {
    return updateSessionState(state, (session: SessionStateContainer) => {
        if (session.state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
            return {
                ...session,
                state: "READY",
                participantId: session.newParticipantId,
                newParticipantId: undefined,
                oldParticipantId: undefined,
                reincarnationState: undefined,
                effectiveState: {
                    ...session.serverSessionState,
                },
                lastActiveTime: new Date(),
                isIdle: false,
                uncommitted: [],
                participantMessages: [],
            };
        } else {
            throw new Error(`Received REINCARNATION_COMPLETED in invalid state ${session.state}`);
        }
    });
};
