import { createCurrentAppearanceOptionsSelector } from "modules/shared/selectors/step-definition/generic/createCurrentAppearanceOptionsSelector";
import React from "react";
import { Badge } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { useSessionSelector } from "../SessionRenderer/context";
import "./name-tag.scss";

type Props = { name: string; isCurrentParticipant?: boolean };

const NameTag: React.FC<Props> = (props) => {
    const appearance = useSessionSelector(createCurrentAppearanceOptionsSelector());
    const color: Variant | undefined = props.isCurrentParticipant
        ? "info"
        : appearance?.accentColor ?? appearance?.backgroundColor ?? undefined;

    return (
        <Badge bg={color} className="name-tag">
            {props.name}
        </Badge>
    );
};

export default NameTag;
