import React, { useEffect, useRef } from "react";
import L, { LatLngBoundsExpression } from "leaflet";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { getPixelSize } from "../LeafletMap/utils";

type Props = {
    mapLocation: string;
    mapMetadata: LeafletMapMetadata;
};

const PreviewMap: React.FC<Props> = (props) => {
    const mapElement = useRef<HTMLDivElement | null>(null);

    const pixelSize = React.useMemo(() => getPixelSize(props.mapMetadata), [props]);
    const [yMax, yMin, xMax, xMin] = props.mapMetadata.extent;
    const maximumMapBounds: LatLngBoundsExpression = React.useMemo(
        () => [
            [yMax / pixelSize[1], -xMin / pixelSize[0]],
            [-yMin / pixelSize[1], xMax / pixelSize[0]],
        ],
        [yMax, yMin, xMax, xMin, pixelSize],
    );

    useEffect(
        () => {
            if (!mapElement.current) {
                return;
            }
            const map = L.map(mapElement.current, {
                crs: L.CRS.Simple,
                zoomControl: false,
                maxBoundsViscosity: 1,
                attributionControl: false,
                dragging: false,
            });

            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();

            map.fitBounds(maximumMapBounds, {});

            const tileLayer = L.tileLayer(`${props.mapLocation}/{z}/{x}/{y}.png`, {
                tms: true,
            });
            tileLayer.addTo(map);

            map.setZoom(4);

            // on occasion, we'll lazy load a component which causes the map to resize incorrectly (e.g., the authoring tools).
            // resizing the map after a slight delay fixes the issue.
            setTimeout(() => {
                map.invalidateSize();
            }, 100);
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return <div ref={mapElement} className="leaflet-map-preview" />;
};

export default PreviewMap;
