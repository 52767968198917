import { ApplicationState, SessionStateContainer } from "modules/client/application-state";
import { useClientLocale } from "modules/client/localization";
import FullPageLoading from "modules/components/FullPageLoading";
import SessionRenderer from "modules/components/SessionRenderer";
import DisconnectedModal from "modules/components/SessionRenderer/DisconnectedModal";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createCurrentAppearanceOptionsSelector } from "modules/shared/selectors/step-definition/generic/createCurrentAppearanceOptionsSelector";
import { setButtonProperties, setMarkupIconProperties, setSidebarProperties } from "../../modules/components/utils";

const ParticipantPage: React.FC = () => {
    const locale = useClientLocale();
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`?locale=${locale}`);
    }, [locale, navigate]);

    const params = useParams<{ sessionId: string; previewMode?: string }>();
    const dispatch = useDispatch();
    const sessionSelector = React.useCallback((state: ApplicationState): SessionStateContainer => {
        const session = state.session;
        if (!session) {
            throw new Error(`Session ${state.session.sessionId} not found`);
        }
        return session;
    }, []);

    const session = useSelector((state: ApplicationState) => state.session);
    const { state } = session;

    const [backgroundColor, setBackgroundColor] = useState<string>();
    const [sessionComponents, setSessionComponents] = useState<JSX.Element>();

    useEffect(() => {
        if (state === "INITIAL") {
            dispatch({
                type: "CONNECT_TO_SESSION",
                sessionId: params.sessionId,
            });
            setSessionComponents(<FullPageLoading />);
        } else if (state === "ERROR") {
            setSessionComponents(<SessionRenderer selector={sessionSelector} inPreviewMode={false} />);
        } else if (state === "READY" || state === "RECONNECTING" || state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
            const appearance = createCurrentAppearanceOptionsSelector()(session.serverSessionState);
            const root = document.documentElement;

            const sidebarAndZoomColor =
                appearance.sidebarAndZoomColor ?? getComputedStyle(root).getPropertyValue("--bs-dark").trim();
            setSidebarProperties(sidebarAndZoomColor);
            setButtonProperties(sidebarAndZoomColor, "sidebarAndZoomColor");

            const hotspotIconColor =
                appearance.hotspotIconColor ?? getComputedStyle(root).getPropertyValue("--bs-primary").trim();
            root.style.setProperty("--hotspotIconColor", hotspotIconColor);
            setButtonProperties(hotspotIconColor, "hotspotIconColor");

            const markupIconColor =
                appearance.markupIconColor ?? getComputedStyle(root).getPropertyValue("--bs-info").trim();
            setMarkupIconProperties(markupIconColor);

            setBackgroundColor(appearance.backgroundColor);
            setSessionComponents(
                <SessionRenderer selector={sessionSelector} inPreviewMode={params.previewMode === "true"} />,
            );
        } else if (state === "DISCONNECTED") {
            setSessionComponents(<DisconnectedModal />);
        }

        // Since serverSessionState can constantly be changing, even though appearance won't, there's no
        // ideal way to add it as a dependency and not have this fire off a lot.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch, params.previewMode, params.sessionId]);

    // While connecting but not yet connected
    return (
        <SessionBackground key="session-renderer" backgroundColor={backgroundColor}>
            {sessionComponents || <FullPageLoading />}
        </SessionBackground>
    );
};

const SessionBackground: React.FC<{ backgroundColor?: string; children?: React.ReactNode }> = (props) => {
    return (
        <div
            className="session-renderer-background d-flex flex-column h-100"
            style={{ backgroundColor: props.backgroundColor }}
        >
            <Suspense fallback={<FullPageLoading />}>{props.children}</Suspense>
        </div>
    );
};

export default ParticipantPage;
