import * as Sentry from "@sentry/react";
import { ClientSocketManager } from "modules/client/client-socket-manager";
import { sessionState } from "modules/client/selectors";
import { createStore } from "modules/redux-store";
import {
    ClientSchedulerRoute,
    ConnectionTestRoute,
    GenerateQrCodeRoute,
    InPersonExperienceRoute,
    InPersonSessionRoute,
    ParticipantRoute,
    SessionRepositoryReportRoute,
} from "modules/shared/routes";
import ConnectionTest from "pages/client/ConnectionTest";
import GenerateUrls from "pages/client/GenerateUrls";
import GenerateClientInvitation from "pages/internal/GenerateClientInvitation";
import ParticipantPage from "pages/participants/ParticipantPage";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import "sass/app.scss";
import { v4 } from "uuid";
import { ErrorBoundary } from "./ErrorBoundary";
import ErrorModal from "./ErrorModal";
import { MsalProvider } from "@azure/msal-react";
import { createMsalInstance, loginCallback } from "modules/client/auth";
import { PublicClientApplication } from "@azure/msal-browser";
import Protected from "./Protected";
import LoginCallback from "pages/loginCallback";
import SessionReports from "pages/session-reports";
import GenerateQRCode from "pages/inPerson/GenerateQRCode";
import InPersonExperience from "pages/inPerson/InPersonExperience";
import InPersonLayout from "./InPersonExperience/InPersonLayout";
import InPersonSession from "pages/inPerson/InPersonSession";

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    normalizeDepth: 10,
});

// create our instance of the auth context
const msalInstance = createMsalInstance();
msalInstance
    .handleRedirectPromise()
    .then((payload) => {
        if (!payload) return;
        return loginCallback(msalInstance, payload);
    })
    .catch((error) => {
        console.error("Error from login callback: ", error);
    });

type AppProps = {
    authClient: PublicClientApplication;
};

const connectionManager = new ClientSocketManager();
const store = createStore(connectionManager, msalInstance);

connectionManager.receiver = (sessionId, payload) => {
    const session = sessionState(store.getState());
    Sentry.setTag("session_id", sessionId);
    if (session.state === "READY") {
        const experienceName = session.effectiveState.script.name;
        if (experienceName) {
            Sentry.setTag("experience_name", experienceName);
        }

        Sentry.setUser({
            id: v4(),
            ip_address: "{{auto}}",
        });
    }
    store.dispatch({
        type: "WEBSOCKET_MESSAGE_RECEIVED",
        payload,
    });
};
connectionManager.errorHandler = (sessionId, error) => {
    store.dispatch({
        type: "WEBSOCKET_ERROR",
        sessionId,
        payload: error,
    });
};
connectionManager.reconnectHandler = (sessionId, c) => {
    store.dispatch({
        type: "SOCKET_RECONNECT",
        sessionId,
        ...c,
    });
};

const App: React.FC<AppProps> = ({ authClient }: AppProps) => {
    window.onerror = (message, filename?, lineno?, colno?, error?) => {
        console.error("OnError: ", message, error);
    };

    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        const error = event.reason;
        console.error("OnUnhandledRejection: ", error);
    };

    const routes = {
        path: "/",
        children: [
            {
                path: "/scheduler",
                element: (
                    <Protected>
                        <div className="main-container">
                            <GenerateClientInvitation />
                        </div>
                    </Protected>
                ),
            },
            {
                path: "/internal/scheduler/:key",
                element: <Navigate to="/scheduler" />,
            },
            {
                path: "/participants/join/error",
                element: (
                    <ErrorModal
                        message="We don't understand that invitation code, or it
                            may be expired. Please contact your administrator."
                    />
                ),
            },
            {
                path: ParticipantRoute.PATH_TEMPLATE,
                element: <ParticipantPage />,
            },
            {
                path: ParticipantRoute.SIMPLE_PATH_TEMPLATE,
                element: <ParticipantPage />,
            },
            {
                path: ClientSchedulerRoute.PATH_TEMPLATE,
                element: <GenerateUrls />,
            },
            {
                path: ConnectionTestRoute.PATH_TEMPLATE,
                element: <ConnectionTest />,
            },
            {
                path: "/login/callback",
                element: <LoginCallback />,
            },
            {
                path: SessionRepositoryReportRoute.PATH_TEMPLATE,
                element: (
                    <Protected>
                        <SessionReports />
                    </Protected>
                ),
            },
            {
                path: GenerateQrCodeRoute.PATH_TEMPLATE,
                element: (
                    <Protected>
                        <GenerateQRCode />
                    </Protected>
                ),
            },
            {
                path: InPersonExperienceRoute.PATH_TEMPLATE,
                element: (
                    <InPersonLayout>
                        <InPersonExperience />
                    </InPersonLayout>
                ),
            },
            {
                path: InPersonSessionRoute.PATH_TEMPLATE,
                element: (
                    <InPersonLayout>
                        <InPersonSession />
                    </InPersonLayout>
                ),
            },
        ],
    };
    const routing = useRoutes([routes]);

    return (
        <ErrorBoundary>
            <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
                <MsalProvider instance={authClient}>
                    <ReduxProvider store={store}>{routing}</ReduxProvider>
                </MsalProvider>
            </Sentry.ErrorBoundary>
        </ErrorBoundary>
    );
};

export default App;

const appContainer = document.getElementById("app");
if (appContainer) {
    const root = createRoot(appContainer);
    const element = (
        <BrowserRouter>
            <App authClient={msalInstance} />
        </BrowserRouter>
    );
    root.render(element);
}
