import { isActiveParticipant, updateParticipantHistory } from "modules/utils";
import { ParticipantLocaleChanged } from "../session-events";
import { SessionState } from "../types";

const PARTICIPANT_LOCALE_CHANGED = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: ParticipantLocaleChanged): boolean => {
        const historyExists = state.participantsHistory[payload.participantId];
        const languageDoesNotMatch = historyExists?.languageCode !== payload.languageCode;

        return isActiveParticipant(state, payload) && !!historyExists && languageDoesNotMatch;
    },
    reducer: (state: SessionState, payload: ParticipantLocaleChanged): SessionState => {
        return {
            ...state,
            participantsHistory: updateParticipantHistory(
                state.participantsHistory,
                payload.participantId,
                (participant) => {
                    return {
                        ...participant,
                        languageCode: payload.languageCode,
                    };
                },
            ),
        };
    },
};

export default PARTICIPANT_LOCALE_CHANGED;
