import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";

type Args = {
    participantId: string;
    participantName: string;
};
const createChangeName = (args: Args): SessionEvent => {
    return {
        type: "CHANGE_NAME",
        participantId: args.participantId,
        name: args.participantName,
        uuid: uuidv4(),
    };
};

export default createChangeName;
