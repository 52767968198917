import { createSelector } from "@reduxjs/toolkit";
import { ParticipantInfo, SessionState } from "../../types";
import { participants } from "./participants";

export function createParticipantSelector(participantId: string | null): (s: SessionState) => ParticipantInfo | null {
    return createSelector([participants], (participants) => {
        if (participantId) {
            const current = participants.find((p) => p.id === participantId);
            if (current) {
                return current;
            }
        }
        return null;
    });
}
