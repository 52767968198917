import { Section } from "../../../content-types";
import { SessionState } from "../../../types";
import { scriptSections } from "./scriptSections";
import { currentSectionAddress } from "../../step-address/currentSectionAddress";

export function previousScriptSections(state: SessionState): Section[] {
    const currentSection = currentSectionAddress(state);
    const sectionEntries = scriptSections(state);
    if (currentSection === "intro") {
        return [];
    }
    return currentSection === "exit" ? sectionEntries : sectionEntries.slice(0, currentSection);
}
