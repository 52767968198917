import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import L, { LayerGroup } from "leaflet";
import { PreviewFocusArea, PreviewFocusAreaPlacement } from "modules/shared/types";
import { fromMapUnit } from "./utils";
import { FaCopy } from "react-icons/fa";
import { useDispatch } from "react-redux";
import createParticipantMessageReceived from "modules/client/event-factories/createParticipantMessageReceived";
import { v4 } from "uuid";
import { useMapContext } from "./MapContext";

type Props = {
    pixelSize: [number, number];
    previewFocusAreas: PreviewFocusArea[];
    removePreviewFocusArea: (id: number) => void;
    onIconHover: (value: boolean) => void;
    focusAreaPreviewPlacement: PreviewFocusAreaPlacement | null;
};

const PreviewFocusAreaLayer: React.FC<Props> = ({
    pixelSize,
    previewFocusAreas,
    removePreviewFocusArea,
    onIconHover,
    focusAreaPreviewPlacement,
}) => {
    const { map } = useMapContext();
    const dispatch = useDispatch();
    const [focusAreaPreviewGroup] = useState<LayerGroup>(L.layerGroup);
    const [focusAreaPreviewPlacementGroup] = useState<LayerGroup>(L.layerGroup);

    useEffect(() => {
        map.addLayer(focusAreaPreviewGroup);
        focusAreaPreviewGroup.clearLayers();

        previewFocusAreas.forEach((previewFocusArea, i) => {
            const coordinatesToDisplay = [
                fromMapUnit(
                    L.latLng(previewFocusArea.mapCoordinates.y1, previewFocusArea.mapCoordinates.x1),
                    pixelSize,
                ),
                fromMapUnit(
                    L.latLng(previewFocusArea.mapCoordinates.y2, previewFocusArea.mapCoordinates.x2),
                    pixelSize,
                ),
            ];
            const coordinatesToDisplayAsString = `${Math.round(coordinatesToDisplay[0][0])}, ${Math.round(
                coordinatesToDisplay[0][1],
            )}, ${Math.round(coordinatesToDisplay[1][0])}, ${Math.round(coordinatesToDisplay[1][1])}`;

            const copyIconSvgEl = ReactDOMServer.renderToStaticMarkup(
                <FaCopy className="svg-inline--fa fa-copy fa-w-14" />,
            );

            const previewPaneRawHTML = `
                <div class="preview-pane focus-area" data-testid="focus-area-preview-pane">
                    <div class="coordinates">
                        <div class="title">Focus Area ${i + 1}</div>
                        <div class="coordinates-text">${coordinatesToDisplayAsString}</div>
                    </div>
                    <div class="copy-button">${copyIconSvgEl}</div>
                </div>
            `;

            const deleteButtonRawHTML = '<i class="material-icons delete" alt="delete markup icon" >close</i>';

            L.rectangle(
                [
                    [previewFocusArea.mapCoordinates.y1, previewFocusArea.mapCoordinates.x1],
                    [previewFocusArea.mapCoordinates.y2, previewFocusArea.mapCoordinates.x2],
                ],
                {
                    className: "preview-focus-area",
                    weight: 4,
                },
            ).addTo(focusAreaPreviewGroup);

            const previewPaneIcon = L.divIcon({
                html: previewPaneRawHTML,
                iconAnchor: [110, 0],
                className: "focus-area-preview-pane-container",
            });

            const bottomRightCoordinates: [number, number] = [
                Math.min(previewFocusArea.mapCoordinates.y1, previewFocusArea.mapCoordinates.y2),
                Math.max(previewFocusArea.mapCoordinates.x1, previewFocusArea.mapCoordinates.x2),
            ];

            const topLeftCoordinates: [number, number] = [
                Math.max(previewFocusArea.mapCoordinates.y1, previewFocusArea.mapCoordinates.y2),
                Math.min(previewFocusArea.mapCoordinates.x1, previewFocusArea.mapCoordinates.x2),
            ];

            const previewPaneCoordinates: [number, number] = [
                bottomRightCoordinates[0],
                (bottomRightCoordinates[1] + topLeftCoordinates[1]) / 2,
            ];

            const previewPaneMarker = L.marker(previewPaneCoordinates, {
                icon: previewPaneIcon,
            }).addTo(focusAreaPreviewGroup);

            const deleteButtonIcon = L.divIcon({
                html: deleteButtonRawHTML,
                iconAnchor: [25, 15],
                className: "delete-button-marker",
            });

            const deleteButtonMarker = L.marker([topLeftCoordinates[0], bottomRightCoordinates[1]], {
                icon: deleteButtonIcon,
            }).addTo(focusAreaPreviewGroup);

            previewPaneMarker.on("mouseover", () => onIconHover(true));
            previewPaneMarker.on("mouseout", () => onIconHover(false));

            deleteButtonMarker.on("click", () => {
                onIconHover(false);
                removePreviewFocusArea(previewFocusArea.id);
            });
            deleteButtonMarker.on("mouseover", () => onIconHover(true));
            deleteButtonMarker.on("mouseout", () => onIconHover(false));
            previewPaneMarker.on("click", () => {
                dispatch(
                    createParticipantMessageReceived({
                        payload: {
                            id: v4(),
                            message: "Coordinates copied to clipboard",
                            success: true,
                        },
                    }),
                );
                void navigator.clipboard.writeText(coordinatesToDisplayAsString);
            });
        });
        return () => {
            focusAreaPreviewGroup.clearLayers();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, previewFocusAreas]);

    useEffect(() => {
        map.addLayer(focusAreaPreviewPlacementGroup);
        focusAreaPreviewPlacementGroup.clearLayers();

        if (!!focusAreaPreviewPlacement) {
            L.rectangle(
                [
                    [focusAreaPreviewPlacement.firstPoint.y, focusAreaPreviewPlacement.firstPoint.x],
                    [focusAreaPreviewPlacement.secondPoint.y, focusAreaPreviewPlacement.secondPoint.x],
                ],
                {
                    className: "preview-focus-area",
                    color: "white",
                    weight: 4,
                },
            ).addTo(focusAreaPreviewPlacementGroup);
        }
        return () => {
            focusAreaPreviewPlacementGroup.clearLayers();
        };
    }, [map, focusAreaPreviewPlacement, focusAreaPreviewPlacementGroup]);

    return <></>;
};
export default PreviewFocusAreaLayer;
