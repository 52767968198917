import classNames from "classnames";
import createAssignFacilitator from "modules/client/event-factories/createAssignFacilitator";
import createAssignNavigator from "modules/client/event-factories/createAssignNavigator";
import createGoToStep from "modules/client/event-factories/createGoToStep";
import createNavigateIceBreaker from "modules/client/event-factories/createNavigateIceBreaker";
import createRemoveFacilitator from "modules/client/event-factories/createRemoveFacilitator";
import createRotateNavigator from "modules/client/event-factories/createRotateNavigator";
import createStartExperience from "modules/client/event-factories/createStartExperience";
import { useLocalizedString } from "modules/client/localization";
import {
    createProgressCheckIntro,
    getIntroDirections,
    getIntroInstructions,
    getWelcomeContent,
} from "modules/client/utils";
import { Role, StepDefinition } from "modules/shared/content-types";
import { getIceBreakerNavList } from "modules/shared/selectors/intro-sequence/getIceBreakerNavList";
import { createSectionDefinitionForCurrentSectionSelector } from "modules/shared/selectors/step-definition/generic/createSectionDefinitionForCurrentSectionSelector";
import { createTimeRecommendedForPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeRecommendedForPreviousSectionsSelector";
import { createTimeDevotedToPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeDevotedToPreviousSectionsSelector";
import { currentNavigatorSelector } from "modules/shared/selectors/participants/currentNavigatorSelector";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { participants as participantSelector } from "modules/shared/selectors/participants/participants";
import { createNextStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createNextStepDefinitionSelector";
import { createGenericStepDefinitionSelector } from "modules/shared/selectors/step-definition/generic/createGenericStepDefinitionSelector";
import { createCustomIceBreakerSelector } from "modules/shared/selectors/intro-sequence/createCustomIceBreakerSelector";
import { sessionEndedSelector } from "modules/shared/selectors/navigation-state/sessionEndedSelector";
import { inIntroSequence } from "modules/shared/selectors/intro-sequence/inIntroSequence";
import { sessionStarted } from "modules/shared/selectors/navigation-state/sessionStarted";
import { stepBeforeCurrentAddress } from "modules/shared/selectors/step-address/stepBeforeCurrentAddress";
import { stepAfterCurrentAddress } from "modules/shared/selectors/step-address/stepAfterCurrentAddress";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { getStepOptions } from "modules/shared/selectors/step-definition/generic/getStepOptions";
import { evaluatePace, isIntroStepAddress } from "modules/shared/utils";
import { participantHasRole } from "modules/utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import {
    MdAssignment,
    MdAssistantDirection,
    MdDoubleArrow,
    MdPeopleAlt,
    MdRecordVoiceOver,
    MdTimer,
} from "react-icons/md";
import { RiShieldStarFill } from "react-icons/ri";
import { ParticipantInfo, StepAddress, StepIntroAddress } from "../../shared/types";
import BadgeWithIcon from "../BadgeWithIcon";
import { Placeholder } from "../MouseRenderer/mouse-movement-tracking";
import RichText from "../RichText";
import { useSession, useSessionDispatch, useSessionSelector } from "../SessionRenderer/context";
import ContentBody, { ContentBlock } from "./ContentBody";
import ContentTop from "./ContentTop";
import { ParticipantRow } from "./MenuComponents/TeamInfo";
import { NavigationPanel } from "./NavigationPanel";
import SectionsDisplay from "./SectionsDisplay";
import "./sidebar.scss";
import DelayContent from "./DelayContent";

export const SIDEBAR_CONTENT_ANIMATION_TIME = 300; // animation

type NavbarTeamInfoProps = {
    isTeamHovered: boolean;
    isFacilitator: boolean;
    handleMakeNavigator: (participantId: string) => void;
    handleChangeNavigator: () => void;
    handleMakeFacilitator: (participantId: string) => void;
    handleRemoveFacilitator: (participantId: string | null) => void;
    handleOnMouseLeaveTeam: () => void;
};

const AssignNavigatorPopover = () => {
    const dispatch = useSessionDispatch();
    const currentNavigator = useSessionSelector(currentNavigatorSelector);
    const localized = useLocalizedString();

    const rotateNavigator = useCallback(() => {
        dispatch(
            createRotateNavigator({
                currentNavigator: currentNavigator?.id || null,
            }),
        );
    }, [dispatch, currentNavigator]);

    return (
        <div className="assign-popover">
            <h6 className="assign-nav-title">{localized("sidebar_teamAssignNavigator")}</h6>
            <Button
                className="choose-randomly"
                variant="outline-primary"
                onClick={() => rotateNavigator()}
                data-testid="rotate-navigator-button"
            >
                {localized("sidebar_teamChooseRandomly")}
            </Button>
            <p className="helper-text">{localized("sidebar_teamChooseHelperText")}</p>
        </div>
    );
};

const NavbarTeamInfo: React.FC<NavbarTeamInfoProps> = (props) => {
    const localized = useLocalizedString();
    const participants = useSessionSelector(participantSelector);
    const { participantId } = useSession();
    const currentNavigator = useSessionSelector(currentNavigatorSelector);

    return (
        <Row className="team-popover g-0">
            <Col className="all-participants">
                {participants.map((p, i) => (
                    <React.Fragment key={p.id}>
                        <ParticipantRow
                            isNavigator={p.id === currentNavigator?.id}
                            isFacilitator={participantHasRole(p, Role.Facilitator)}
                            joiningText={localized("vdpHud_infoTeamParticipantJoining")}
                            isUsersOwnRow={p.id === participantId}
                            currentUserIsFacilitator={props.isFacilitator}
                            handleMakeNavigator={props.handleMakeNavigator}
                            handleChangeNavigator={props.handleChangeNavigator}
                            handleMakeFacilitator={props.handleMakeFacilitator}
                            handleRemoveFacilitator={props.handleRemoveFacilitator}
                            isTeamHovered={props.isTeamHovered}
                            handleOnMouseLeaveTeam={props.handleOnMouseLeaveTeam}
                            {...p}
                        />
                        {i < participants.length - 1 && (
                            <Dropdown.Divider style={{ opacity: 0.1, margin: "0px 23px" }} />
                        )}
                    </React.Fragment>
                ))}
            </Col>
            <Col className="assign-navigator-popover">{<AssignNavigatorPopover />}</Col>
        </Row>
    );
};

type SidebarContentProps = {
    isNavigator: boolean;
    isFacilitator: boolean;
    currentNavigator: ParticipantInfo | null;
    currentStep: StepAddress | StepIntroAddress;
    currentStepDefinition: StepDefinition | null;
    inWelcomeMode: boolean;
    navigatorPermissions: boolean;
    separateNavigatorBadge: JSX.Element;
    hasStarted: boolean;
    contentRef: React.RefObject<HTMLDivElement>;
};

export const SidebarContent: React.FC<SidebarContentProps> = (props) => {
    const localized = useLocalizedString();
    const timeDevoted = useSessionSelector(createTimeDevotedToPreviousSectionsSelector());
    const timeRecommended = useSessionSelector(createTimeRecommendedForPreviousSectionsSelector());
    const currentSectionEntry = useSessionSelector(createSectionDefinitionForCurrentSectionSelector());
    const pace = evaluatePace(timeRecommended, timeDevoted);
    const customIceBreaker = useSessionSelector(createCustomIceBreakerSelector());

    const currentStepDefinition = props.currentStepDefinition;
    const deprecatedInstructionalText = currentStepDefinition?.instructionalContent;

    let stepIntroduction: string | null | undefined;
    let stepDirections: string | null | undefined;

    // if the dependencies for the content are changed you must also change the render-key in the DelayContent prop appropriately
    if (!props.inWelcomeMode) {
        if (isIntroStepAddress(props.currentStep)) {
            const introKey = props.currentStep.introStepKey;
            const iceBreakerNavPoolIndex = props.currentStep.iceBreakerNavPoolIndex;
            if (
                introKey === "iceBreaker" &&
                iceBreakerNavPoolIndex !== "complete" &&
                iceBreakerNavPoolIndex !== "intro" &&
                customIceBreaker
            ) {
                stepIntroduction = getIntroInstructions(
                    localized,
                    introKey,
                    iceBreakerNavPoolIndex,
                    props.currentNavigator?.name,
                    customIceBreaker,
                );
            } else {
                stepIntroduction = getIntroInstructions(
                    localized,
                    introKey,
                    iceBreakerNavPoolIndex,
                    props.currentNavigator?.name,
                );
            }

            stepDirections = getIntroDirections(localized, introKey, iceBreakerNavPoolIndex);
        } else {
            stepIntroduction =
                currentStepDefinition?.contentType === "progressCheck" &&
                props.currentStep !== "exit" &&
                currentSectionEntry
                    ? createProgressCheckIntro(currentSectionEntry, props.currentStep.sectionKey, pace, localized)
                    : currentStepDefinition?.introduction;
            stepDirections = currentStepDefinition?.directions;
        }
    }
    const stepFacilitationNotes = currentStepDefinition?.facilitationNotes;
    const directions = stepDirections;

    const navigatorBadge = (
        <BadgeWithIcon
            tab
            variant="warning-200"
            icon={MdAssistantDirection}
            className="navigator-badge"
            roleLabel={localized("sidebar_navigatorBadge")}
            aria-description={localized("sidebar_navigatorBadgeDescription")}
        />
    );
    const facilitatorBadge = (
        <BadgeWithIcon
            tab
            variant="secondary"
            icon={RiShieldStarFill}
            roleLabel={localized("sidebar_facilitatorBadge")}
        />
    );
    const defaultMsg =
        currentStepDefinition?.contentType === "exit" ? (
            <p data-testid="exit-default-instructional-text">{localized("vdpHud_defaultExitMsg")}</p>
        ) : (
            <Placeholder />
        );
    const instructionalContent = !!deprecatedInstructionalText ? (
        <>
            {props.navigatorPermissions && props.separateNavigatorBadge}
            <RichText className="step-instructions" document={deprecatedInstructionalText} />
        </>
    ) : (
        defaultMsg
    );

    let contentBlocks: ContentBlock[] = [];
    const facilitatorContent: ContentBlock[] = [];
    if (props.inWelcomeMode) {
        contentBlocks = getWelcomeContent(props.navigatorPermissions, props.hasStarted, localized);
    } else {
        if (stepIntroduction) {
            contentBlocks.push({
                icon: MdRecordVoiceOver,
                title: localized("sidebar_introductionLabel"),
                instruction: props.isNavigator ? localized("sidebar_introductionInstruction") : undefined,
                content: stepIntroduction,
            });
        }

        if ((props.isNavigator || props.isFacilitator) && !!directions) {
            let instruction: string;

            if (props.isFacilitator && !props.isNavigator && props.currentNavigator?.name) {
                instruction = localized("sidebar_nonNavigatorDirectionsLabel", props.currentNavigator.name);
            } else {
                instruction = localized("sidebar_directionsInstruction");
            }

            contentBlocks.push({
                icon: MdAssignment,
                title: localized("sidebar_directionsLabel"),
                instruction: instruction,
                content: directions,
            });
        }

        if (props.isFacilitator && !!stepFacilitationNotes) {
            facilitatorContent.push({
                title: localized("sidebar_facilitationNotesLabel"),
                content: stepFacilitationNotes,
            });
        }
    }

    const shouldShowContentBody =
        props.inWelcomeMode ||
        stepIntroduction ||
        stepDirections ||
        (!deprecatedInstructionalText && props.currentStep !== "exit");

    const stepInstructionsContent = shouldShowContentBody ? (
        <ContentBody
            className="step-instructions"
            contentBlocks={contentBlocks}
            variant={props.isNavigator ? "warning-100" : "info-100"}
            badge={props.isNavigator ? navigatorBadge : undefined}
        />
    ) : (
        instructionalContent
    );

    const facNotesContent = (
        <ContentBody
            className="step-instructions"
            contentBlocks={facilitatorContent}
            variant={"secondary-100"}
            badge={facilitatorBadge}
        />
    );

    return (
        <DelayContent
            transitionDelay={SIDEBAR_CONTENT_ANIMATION_TIME}
            transitionClassNames="sidebarContent"
            renderKey={`${JSON.stringify(props.currentStep)}-${props.isNavigator}-${props.isFacilitator}-${
                props.hasStarted
            }-${props.inWelcomeMode}`}
        >
            <aside className="content" ref={props.contentRef}>
                {stepInstructionsContent}
                {facNotesContent}
            </aside>
        </DelayContent>
    );
};

type SidebarProps = {
    inWelcomeMode: boolean;
    isScheduleOpen: boolean;
    isTeamHovered: boolean;
    inPreviewMode: boolean;
    isSidebarOpen: boolean;
    toggleScheduleOpen: (open: boolean) => void;
    handleTeamHover: (hovered: boolean) => void;
    handleSidebarToggle: (open: boolean) => void;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
    const teamTarget = useRef(null);
    const scheduleTarget = useRef(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const contentDrawerRef = useRef<HTMLDivElement>(null);

    const { participantId } = useSession();

    const localized = useLocalizedString();
    const dispatch = useSessionDispatch();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isNavigator = !!participantInfo && participantHasRole(participantInfo, Role.Navigator);
    const isFacilitator = !!participantInfo && participantHasRole(participantInfo, Role.Facilitator);
    const currentNavigator = useSessionSelector(currentNavigatorSelector);
    const previousStep = useSessionSelector(stepBeforeCurrentAddress);
    const currentStep = useSessionSelector(currentStepAddress);
    const nextStep = useSessionSelector(stepAfterCurrentAddress);
    const currentStepDefinition = useSessionSelector(createGenericStepDefinitionSelector());
    const nextStepDefinition = useSessionSelector(createNextStepDefinitionSelector());
    const inIntro = useSessionSelector(inIntroSequence);
    const firstStepOptions = useSessionSelector((s) => getStepOptions(s, { sectionKey: 0, stepKey: 0 }));
    const hasStarted = useSessionSelector(sessionStarted);
    const sessionEnded = useSessionSelector(sessionEndedSelector);
    const navigatorPermissions = props.inWelcomeMode ? isFacilitator && isNavigator && !hasStarted : isNavigator;
    const iceBreakerList = useSessionSelector(getIceBreakerNavList);
    const disablePreviousButton = !isNavigator || (isNavigator && previousStep === null) || sessionEnded;
    const disableNextButton =
        !navigatorPermissions || !participantInfo?.ready || (navigatorPermissions && nextStep === null) || sessionEnded;
    const showSkipButton = inIntro && isFacilitator && isNavigator && !props.inWelcomeMode;

    const sidebarExpandedClassNames = useMemo(
        () =>
            classNames(
                "sidebar-expanded",
                { open: props.isSidebarOpen, closed: !props.isSidebarOpen },
                { "preview-mode": props.inPreviewMode },
            ),
        [props.isSidebarOpen, props.inPreviewMode],
    );

    const { handleSidebarToggle: handleSidebarToggleFromProps } = props;

    const handleSidebarToggle = useCallback(
        (show: boolean) => {
            const contentDrawer = contentDrawerRef.current;
            if (!!contentDrawer && show) {
                contentDrawer.removeAttribute("hidden");
                // this is a bit of a hacky solution to force the browser to repaint the element so that it sees the hidden attribute being removed
                // this allows us to transition the element back to open; solution taken from https://cloudfour.com/thinks/transitioning-hidden-elements/
                const _reflow = contentDrawer.offsetHeight;
            }
            handleSidebarToggleFromProps(show);
        },
        [handleSidebarToggleFromProps],
    );

    useEffect(() => {
        const drawer = contentDrawerRef.current;

        const cb = (e: TransitionEvent) => {
            if (!!drawer && e.target === drawer && drawer.classList.contains("open")) {
                drawer.removeAttribute("hidden");
            } else if (!!drawer && e.target === drawer && drawer.classList.contains("closed")) {
                drawer.setAttribute("hidden", "true");
            }
        };

        if (!!drawer) {
            if (drawer.classList.contains("closed")) {
                drawer.setAttribute("hidden", "true");
            }
            // use an event listener to add a callback to the transitionend event so that the css transition may first appear
            drawer.addEventListener("transitionend", cb);
        }

        return () => {
            if (!!drawer) {
                drawer.removeEventListener("transitionend", cb);
            }
        };
    }, []);

    const handleMakeNavigator = useCallback(
        (participantId: string) => {
            dispatch(
                createAssignNavigator({
                    participantId: participantId,
                    currentNavigator: currentNavigator?.id || null,
                }),
            );
        },
        [dispatch, currentNavigator],
    );

    const handleChangeNavigator = useCallback(() => {
        dispatch(
            createRotateNavigator({
                currentNavigator: currentNavigator?.id || null,
            }),
        );
    }, [dispatch, currentNavigator]);

    const handleMakeFacilitator = useCallback(
        (participantId: string) => {
            dispatch(
                createAssignFacilitator({
                    participantId: participantId,
                }),
            );
        },
        [dispatch],
    );

    const handleRemoveFacilitator = useCallback(
        (participantId: string | null) => {
            dispatch(
                createRemoveFacilitator({
                    participantId: participantId,
                }),
            );
        },
        [dispatch],
    );

    const navigatorBadgeClassNames = classNames(
        "navigator-badge",
        { "sidebar-open": props.isSidebarOpen },
        { "preview-mode": props.inPreviewMode },
    );

    const navigatorBadge = (
        <BadgeWithIcon
            pill
            variant="warning-200"
            icon={MdAssistantDirection}
            className={navigatorBadgeClassNames}
            roleLabel={localized("sidebar_navigatorBadge")}
        />
    );

    const previous = useCallback(() => {
        if (previousStep && !sessionEnded) {
            if (
                isIntroStepAddress(previousStep) &&
                previousStep.introStepKey === "iceBreaker" &&
                previousStep.iceBreakerNavPoolIndex !== "complete"
            ) {
                dispatch(
                    createNavigateIceBreaker({
                        currentAddress: currentStep,
                        toStep: previousStep,
                    }),
                );
            } else {
                dispatch(
                    createGoToStep({
                        currentAddress: currentStep,
                        toStep: previousStep,
                    }),
                );
            }
        }
    }, [currentStep, dispatch, previousStep, sessionEnded]);

    const next = useCallback(() => {
        if (nextStep && isIntroStepAddress(currentStep)) {
            if (currentStep.introStepKey === "iceBreaker" && currentStep.iceBreakerNavPoolIndex !== "complete") {
                dispatch(
                    createNavigateIceBreaker({
                        currentAddress: currentStep,
                        toStep: nextStep,
                    }),
                );
            } else {
                dispatch(
                    createGoToStep({
                        currentAddress: currentStep,
                        toStep: nextStep,
                    }),
                );
            }
        } else if (nextStepDefinition && nextStep) {
            dispatch(
                createGoToStep({
                    currentAddress: currentStep,
                    toStep: nextStep,
                    options: nextStepDefinition.options,
                }),
            );
        }
    }, [currentStep, dispatch, nextStep, nextStepDefinition, sessionEnded, iceBreakerList, currentNavigator]);

    // scrolls to the top of the sidebar content area on currentStep change
    // so that the user always sees the start of the sidebar content instructions
    useEffect(() => {
        const contentTop = contentRef.current;
        if (contentTop) {
            contentTop.scrollTo({ top: 0 });
        }
    }, [currentStep]);

    const skipIntro = useCallback(() => {
        if (participantId) {
            dispatch(
                createGoToStep({
                    currentAddress: currentStep,
                    toStep: { sectionKey: 0, stepKey: 0 },
                }),
            );
        }
    }, [currentStep, dispatch, participantId]);

    const startExperience = useCallback(() => {
        if (participantId) {
            dispatch(
                createStartExperience({
                    options: firstStepOptions,
                }),
            );
        }
    }, [participantId, dispatch, firstStepOptions]);

    useEffect(() => {
        if (navigatorPermissions) {
            handleSidebarToggle(true);
        }
    }, [navigatorPermissions, handleSidebarToggle]);

    useEffect(() => {
        if (inIntro && !props.inWelcomeMode) {
            handleSidebarToggle(true);
        }
    }, [inIntro, props.inWelcomeMode, handleSidebarToggle]);

    return (
        <div className="sidebar-container">
            <nav className="sidebar">
                <div className="navbar-top">
                    <button className="show-hide-sidebar" onClick={() => handleSidebarToggle(!props.isSidebarOpen)}>
                        <div className={`show-hide-icon ${props.isSidebarOpen ? "open" : "closed"}`}>
                            <MdDoubleArrow size={16} />
                        </div>
                        {props.isSidebarOpen ? (
                            <span className="navbar-option-label">{localized("vdpHud_hide")}</span>
                        ) : (
                            <span className="navbar-option-label">{localized("vdpHud_show")}</span>
                        )}
                    </button>
                </div>
                <div className="navbar-bottom">
                    <button
                        className="navbar-option"
                        ref={scheduleTarget}
                        onMouseOver={() => props.toggleScheduleOpen(true)}
                        onMouseLeave={() => props.toggleScheduleOpen(false)}
                        aria-description={localized("sidebar_scheduleButtonDescription")}
                    >
                        <MdTimer size={25} />
                        <span className="navbar-option-label">{localized("sidebar_scheduleButton")}</span>
                        <Overlay
                            target={scheduleTarget.current}
                            show={props.isScheduleOpen}
                            placement="right-end"
                            offset={[30, 20]}
                            popperConfig={{ strategy: "fixed" }}
                        >
                            <Popover className="sections-display" data-testid="popover-schedule">
                                <Popover.Body>
                                    <SectionsDisplay />
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </button>
                    <button
                        className="navbar-option"
                        onMouseOver={() => props.handleTeamHover(true)}
                        onMouseLeave={() => props.handleTeamHover(false)}
                        ref={teamTarget}
                        data-testid="team-hover-icon"
                        aria-description={localized("vdpHud_infoTeamButtonDescription")}
                    >
                        <MdPeopleAlt size={25} />
                        <span className="navbar-option-label">{localized("vdpHud_infoTeamButton")}</span>
                        <Overlay
                            target={teamTarget.current}
                            show={props.isTeamHovered}
                            placement="right-end"
                            offset={[5, 20]}
                        >
                            <Popover className="navbar-team" data-testid="popover-team">
                                <Popover.Body className="navbar-team-body">
                                    <NavbarTeamInfo
                                        isTeamHovered={props.isTeamHovered}
                                        isFacilitator={isFacilitator}
                                        handleMakeNavigator={handleMakeNavigator}
                                        handleChangeNavigator={handleChangeNavigator}
                                        handleMakeFacilitator={handleMakeFacilitator}
                                        handleRemoveFacilitator={handleRemoveFacilitator}
                                        handleOnMouseLeaveTeam={() => props.handleTeamHover(false)}
                                    />
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </button>
                </div>
            </nav>
            <div className={sidebarExpandedClassNames} ref={contentDrawerRef}>
                {!props.inWelcomeMode && (
                    <header>
                        <ContentTop />
                    </header>
                )}
                <SidebarContent
                    isNavigator={isNavigator}
                    isFacilitator={isFacilitator}
                    currentNavigator={currentNavigator}
                    currentStep={currentStep}
                    currentStepDefinition={currentStepDefinition}
                    inWelcomeMode={props.inWelcomeMode}
                    separateNavigatorBadge={navigatorBadge}
                    navigatorPermissions={navigatorPermissions}
                    hasStarted={hasStarted}
                    contentRef={contentRef}
                />
                <NavigationPanel
                    goToNext={next}
                    goToPrevious={previous}
                    startExperience={startExperience}
                    skipIntro={skipIntro}
                    isNavigator={isNavigator}
                    currentStep={currentStep}
                    disablePrevious={disablePreviousButton}
                    disableNext={disableNextButton}
                    showSkip={showSkipButton}
                    inWelcomeMode={props.inWelcomeMode}
                />
            </div>
            {navigatorPermissions ? navigatorBadge : <Placeholder />}
        </div>
    );
};
export default Sidebar;
