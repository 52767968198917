import { createSelector } from "@reduxjs/toolkit";
import { SessionState } from "../../../types";
import { createCurrentStepDefinitionSelector } from "../generic/createCurrentStepDefinitionSelector";

export function createEnableAssetMarkupSelector(): (state: SessionState) => boolean {
    return createSelector([createCurrentStepDefinitionSelector("singleAssetStep")], (stepDef) => {
        if (!stepDef) {
            return false;
        }
        const markupEnabled = stepDef?.enableAssetMarkup;

        if (!markupEnabled) {
            return false;
        }
        return markupEnabled;
    });
}
