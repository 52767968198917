import { PositionInElementHierarchy, PositionInMap } from "modules/components/MouseRenderer/mouse-movement-tracking";
import Session from "modules/database_types/session";
import CardStackWithVisualStepState from "./activity/CardStackWithVisual/types";
import { DragAndDropStepState } from "./activity/DragAndDrop/types";
import { MatchingStepState } from "./activity/Matching/types";
import { Icon } from "./activity/Markup/types";
import MultipleChoiceStepState from "./activity/MultipleChoice/types";
import RevealCardStepState from "./activity/RevealCard/types";
import RevealHotspotStepState from "./activity/RevealHotspot/types";
import SingleAssetStepState from "./activity/SingleAsset/types";
import TextEntryStepState from "./activity/TextEntry/types";
import RecallStepState from "./activity/Recall/types";
import EditableCardStepState from "./activity/EditableCard/types";
import { ExperienceScript, RoleId } from "./content-types";
import { IntroStepStateBundle, StepStateBundle } from "./step-types";
import { EntryFields } from "contentful";

export type EmptyStepState = StepStateBundle<"EMPTY", unknown, unknown>;

export type ExitStepState = StepStateBundle<"EXIT", null, null>;
export type ProgressCheckStepState = StepStateBundle<"PROGRESS_CHECK", null, null>;
export type VideoStepState = StepStateBundle<"VIDEO", null, null>;
export type ComparisonStepState = StepStateBundle<"COMPARISON", null, null>;

export type StepState =
    | EmptyStepState
    | RevealCardStepState
    | CardStackWithVisualStepState
    | RevealHotspotStepState
    | DragAndDropStepState
    | MatchingStepState
    | MultipleChoiceStepState
    | TextEntryStepState
    | EditableCardStepState
    | SingleAssetStepState
    | ComparisonStepState
    | VideoStepState
    | ProgressCheckStepState
    | ExitStepState
    | RecallStepState;

/** The full shared state of an experience */
export type SessionState = {
    sessionId: string;
    lastSequenceNumber: string;
    participants: ParticipantInfo[];
    participantCursors: ParticipantCursorDict;
    participantsHistory: ParticipantHistory;
    dataCollectionMethod?: DataCollectionTypes | string;
    inPersonParticipantCount?: number;
    poolOfEligibleParticipantsForNavigatorRole: string[];
    iceBreakerNavigatorPool: string[];
    isIntroStep: boolean;
    currentIntroStep: StepIntroAddress;
    currentStep: StepAddress;
    navigatorStartStep: StepAddress;
    furthestSectionViewed: number;
    sessionStartedAt: number | null;
    sessionEndedAt: number | null;
    sessionReachedExit?: boolean;
    timeStepStarted: number;

    stepStates: {
        [sectionKey: number]: {
            [stepKey: number]: StepState;
        };
    };

    introStepStates: IntroStepStateBundle[];

    scriptVersion: number;
    script: ExperienceScript;

    visibleMarkupIcons: Icon[];
    hiddenMarkupIcons: Icon[];
    sessionMetadata: Session;
};

export type BaseActivityProps = {
    showRotateNavigator: () => void;
};

export type DataCollectionTypes = "in-person" | "virtual";

export type IntroStepKey =
    | "joinTeam"
    | "facilitatorRole"
    | "navigatorRole"
    | "participantRole"
    | "iceBreaker"
    | "mapVisual";

export type StepIntroAddress = { introStepKey: IntroStepKey; iceBreakerNavPoolIndex: number | "intro" | "complete" };

export type MainStepAddress = { sectionKey: number; stepKey: number };
export type ProgressCheckAddress = { sectionKey: number; stepKey: "ProgressCheck" };
export type ExitStepAddress = "exit";

export type StepAddress = MainStepAddress | ExitStepAddress | ProgressCheckAddress;

export const ORDERED_INTRO_KEY: IntroStepKey[] = [
    "joinTeam",
    "facilitatorRole",
    "navigatorRole",
    "participantRole",
    "iceBreaker",
];

export type ParticipantInfo = {
    /** A unique ID for this participant's connection */
    id: string;
    name: string | null;
    ready: boolean;
    assignedRoles: RoleId[];
    surveyTaken?: boolean;
};

export type ParticipantCursorDict = {
    [participantId: string]: PositionInElementHierarchy | PositionInMap | null;
};

export type ReadyParticipant = ParticipantInfo & { ready: true; name: string };

export type ParticipantHistoryPiece = {
    stepWhenJoined: StepAddress;
    stepWhenLeft?: StepAddress;
    languageCode: string;
};
export type ParticipantHistory = {
    [K: string]: ParticipantHistoryPiece;
};

export type MapState = {
    topLeft: [number, number];
    bottomRight: [number, number];
};

export type Hotspot = {
    mapCoordinates: [number, number];
    title: string;
    content?: EntryFields.RichText;
    hideBackground?: boolean;
};

export type ClickableArea = {
    mapCoordinates: [number, number, number, number];
    title: string;
    content?: EntryFields.RichText;
    hideBackground?: boolean;
};

export enum CoordinatePreviewMode {
    Off = "",
    Hotspot = "coordinatePreview_hotspotMode",
    FocusArea = "coordinatePreview_focusAreaMode",
}

export type PreviewHotspot = {
    id: number;
    mapCoordinates: [number, number];
};

export type PreviewFocusArea = {
    id: number;
    mapCoordinates: { x1: number; y1: number; x2: number; y2: number };
};

export type PreviewFocusAreaPlacement = {
    firstPoint: { x: number; y: number };
    secondPoint: { x: number; y: number };
};

export enum PaceCheck {
    OnPace,
    AheadOfPace,
    BehindPace,
}

export type MaintenanceWindowResponse = {
    start: string | null;
    end: string | null;
};
