import { createExitStepDefinition, createProgressCheckStepDefinition } from "modules/utils";
import { StepDefinition, StepDefinitionKey, StepRecord } from "../../../content-types";
import { SessionState, StepAddress } from "../../../types";

export function getStepScript<
    TKey extends StepDefinitionKey,
    TStepDefinitionType extends StepDefinition & StepRecord<TKey>,
>(type: TKey, state: Pick<SessionState, "script">, stepKey: StepAddress): TStepDefinitionType | null {
    if (stepKey === "exit") {
        return createExitStepDefinition(state.script) as TStepDefinitionType;
    }

    const section = state.script.sections[stepKey.sectionKey];

    if (!section) {
        return null;
    } else if (stepKey.stepKey === "ProgressCheck") {
        return createProgressCheckStepDefinition(state.script, stepKey.sectionKey) as TStepDefinitionType;
    }

    const step = section.steps[stepKey.stepKey];

    if (step.contentType === type) {
        return step as TStepDefinitionType;
    }

    return null;
}
