import { SendSessionReportRequest } from "modules/client/actions-types/sessionReportActionTypes";
import { ApplicationState } from "modules/client/application-state";
import { ReportStatus } from "modules/client/session-report-state";

export default function sendSessionReportRequest(
    state: ApplicationState,
    payload: SendSessionReportRequest,
): ApplicationState {
    return {
        ...state,
        sessionReportState: {
            status: ReportStatus.InProgress,
            filters: payload.filters,
        },
    };
}
