import { createSelector } from "@reduxjs/toolkit";
import { MapState, SessionState } from "../../types";
import { getMapState, notNullOrUndefined } from "../../utils";
import { createCurrentStepDefinitionSelector } from "../step-definition/generic/createCurrentStepDefinitionSelector";
import { createCurrentStepOptionsSelector } from "../step-definition/generic/createCurrentStepOptionsSelector";

export function createMapHighlightAreasSelector(): (s: SessionState) => MapState[] | null {
    return createSelector(
        [createCurrentStepOptionsSelector(), createCurrentStepDefinitionSelector("infoStep")],
        (options, stepDef) => {
            if (stepDef && stepDef.clickableAreas && stepDef.clickableAreas.length > 0) {
                const { clickableAreas } = stepDef;
                return clickableAreas
                    .map((ca) => {
                        const coords = ca.mapCoordinates;
                        return getMapState(coords.split(","));
                    })
                    .filter(notNullOrUndefined);
            } else if (options?.mapHighlightAreas) {
                return options.mapHighlightAreas
                    .map((highlightArea) => getMapState(highlightArea.split(",")))
                    .filter(notNullOrUndefined);
            } else {
                return null;
            }
        },
    );
}
