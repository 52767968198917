import { SessionState } from "../../types";
import { Section } from "modules/shared/content-types";

export function getIntroSectionDefiniton(state: SessionState): Section {
    const introSectionTime = state.script.welcomeOptions?.time;
    const introSection: Section = {
        title: "Welcome",
        steps: [],
        id: "intro",
        version: 0,
        contentType: "section",
        time: introSectionTime || 0,
    };
    return introSection;
}
