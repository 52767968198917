import { FieldErrors } from "modules/client/session-report-state";

export class UnprocessableContentError extends Error {
    public statusCode = 422;
    public fieldErrors: FieldErrors;
    constructor(message?: string, fieldErrors: FieldErrors = {}) {
        super(message);
        this.fieldErrors = fieldErrors;
        this.name = "FieldValidationError";
    }

    getFieldErrors(): FieldErrors {
        return this.fieldErrors;
    }
}
