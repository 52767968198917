import React from "react";
import "./admin-container.scss";
import { Container } from "react-bootstrap";

const AdminContainer: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <Container className="administrativeContainer" fluid>
            {props.children}
        </Container>
    );
};

export default AdminContainer;
