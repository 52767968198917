import { getComparisonColumnsFromDragAndDropState } from "modules/components/ActivityView/ComparisonActivity/utils";
import { ComparisonData, ComparisonRow } from "../../activity/Comparison/types";
import { SessionState, StepAddress } from "../../types";
import { getStepState } from "./getStepState";
import { getStepAddressByStepTitle } from "../step-address/getStepAddressByStepTitle";
import { getStepAddressByStepEntryId } from "../step-address/getStepAddressByStepEntryId";
import { getStepScript } from "../step-definition/generic/getStepScript";
import { ComparisonStep, DragAndDropStep } from "modules/shared/content-types";

export function getComparisonData(state: SessionState): ComparisonData | null {
    const script = getStepScript<"comparisonStep", ComparisonStep>("comparisonStep", state, state.currentStep);

    if (!script) {
        throw new Error("No script found for step " + state.currentStep);
    }

    let topRowAddress: StepAddress | null = null;
    let bottomRowAddress: StepAddress | null = null;

    if (script.firstStep) {
        topRowAddress = getStepAddressByStepEntryId(state.script, script.firstStep);
    } else if (script.firstStepTitle) {
        topRowAddress = getStepAddressByStepTitle(state, script.firstStepTitle);
    }

    if (script.secondStep) {
        bottomRowAddress = getStepAddressByStepEntryId(state.script, script.secondStep);
    } else if (script.secondStepTitle) {
        bottomRowAddress = getStepAddressByStepTitle(state, script.secondStepTitle);
    }

    if (!topRowAddress) {
        return null;
    }

    const topRowScript = getStepScript<"dragAndDropStep", DragAndDropStep>("dragAndDropStep", state, topRowAddress);
    const bottomRowScript = !!bottomRowAddress
        ? getStepScript<"dragAndDropStep", DragAndDropStep>("dragAndDropStep", state, bottomRowAddress)
        : null;

    if (!topRowScript?.columnEntries) {
        return null;
    }

    const topRowDragAndDropState = getStepState("DRAG_AND_DROP", state, topRowAddress);
    const bottomRowDragAndDropState = !!bottomRowAddress
        ? getStepState("DRAG_AND_DROP", state, bottomRowAddress)
        : null;

    if (!topRowDragAndDropState) {
        return null;
    }

    const topRowColumns = getComparisonColumnsFromDragAndDropState(
        topRowScript.columnEntries,
        topRowScript.cards,
        topRowDragAndDropState,
    );
    const bottomRowColumns =
        !!bottomRowScript && !!bottomRowScript.columnEntries && !!bottomRowDragAndDropState
            ? getComparisonColumnsFromDragAndDropState(
                  bottomRowScript.columnEntries,
                  bottomRowScript.cards,
                  bottomRowDragAndDropState,
              )
            : null;

    const topRow: ComparisonRow = {
        title: topRowScript.title,
        columns: topRowColumns,
    };
    const bottomRow: ComparisonRow | null =
        !!bottomRowScript && !!bottomRowColumns
            ? {
                  title: bottomRowScript.title,
                  columns: bottomRowColumns,
              }
            : null;

    return {
        topRow,
        bottomRow,
    };
}
