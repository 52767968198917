import React, { ReactEventHandler, SyntheticEvent, useEffect, useRef } from "react";
import "./embedded-continuum-survey.scss";

type Props = {
    surveyLink: string;
    onSubmit: () => void;
};

type ContinuumIframeResizedMessage = {
    status: "survey_page_updated";
    height: number;
};

type ContinuumIframeSubmittedMessage = {
    status: "survey_succeeded";
    message: string;
};

type ContinuumIframeMessage = ContinuumIframeResizedMessage | ContinuumIframeSubmittedMessage;

const EmbeddedContinuumSurvey: React.FC<Props> = (props) => {
    // listen for success event dispatched to the window by the iframe
    const { onSubmit, surveyLink } = props;
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const resizeIframe = React.useCallback(
        (height: number) => {
            if (iframeRef.current) {
                iframeRef.current.height = height + "px";
            }
        },
        [iframeRef],
    );

    const iframeMessageHandler = React.useCallback(
        (e: MessageEvent<ContinuumIframeMessage>) => {
            // handle successful submission event
            if (e.data && e.data.status === "survey_succeeded") {
                onSubmit();
            }

            // handle message about survey's height
            if (e.data && e.data.status === "survey_page_updated") {
                resizeIframe(e.data.height);
            }
        },
        [onSubmit, resizeIframe],
    );

    useEffect(() => {
        window.addEventListener("message", iframeMessageHandler);

        return () => {
            window.removeEventListener("message", iframeMessageHandler);
        };
    }, [onSubmit, iframeMessageHandler]);

    return (
        <div className="continuum-survey">
            <iframe ref={iframeRef} src={surveyLink} frameBorder="0"></iframe>
        </div>
    );
};

export default EmbeddedContinuumSurvey;
