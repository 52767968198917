import { Action } from "modules/client/actions-types";
import createAddMarkupIcon from "modules/client/event-factories/createAddMarkupIcon";
import createMouseMove from "modules/client/event-factories/createMouseMove";
import createRemoveMarkupIcon from "modules/client/event-factories/createRemoveMarkupIcon";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { Icon } from "modules/shared/activity/Markup/types";
import { createMapClickAbleAreasSelector } from "modules/shared/selectors/map/createMapClickAbleAreasSelector";
import { createMapHotspotsSelector } from "modules/shared/selectors/map/createMapHotspotsSelector";
import { createMapHighlightAreasSelector } from "modules/shared/selectors/map/createMapHighlightAreasSelector";
import { createMapFocusSelector } from "modules/shared/selectors/map/createMapFocusSelector";
import { createCurrentStepStateSelector } from "modules/shared/selectors/step-state/createCurrentStepStateSelector";
import { createMapMarkupIconTypeSelector } from "modules/shared/selectors/map/createMapMarkupIconTypeSelector";
import { createEnableMapMarkupSelector } from "modules/shared/selectors/step-definition/generic/createEnableMapMarkupSelector";
import { createCurrentAppearanceOptionsSelector } from "modules/shared/selectors/step-definition/generic/createCurrentAppearanceOptionsSelector";

import { visibleMarkupIconsSelector } from "modules/shared/selectors/map/visibleMarkupIconsSelector";
import { participantInfoStepMarkupIconLimit } from "modules/shared/selectors/participants/participantInfoStepMarkupIconLimit";

import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { createNavigationDisabledSelector } from "modules/shared/selectors/navigation-state/createNavigationDisabledSelector";
import React, { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import createHotspotHideDetails from "../../client/event-factories/createHotspotHideDetails";
import createHotspotShowDetails from "../../client/event-factories/createHotspotShowDetails";
import createClickableAreaShowDetails from "modules/client/event-factories/createClickableAreaShowDetails";
import createClickableAreaHideDetails from "modules/client/event-factories/createClickableAreaHideDetails";
import createHotspotFlipCardToBack from "modules/client/event-factories/createHotspotFlipCardToBack";
import createHotspotFlipCardToFront from "modules/client/event-factories/createHotspotFlipCardToFront";
import { useDebug, useSessionDispatch, useSessionParticipantId, useSessionSelector } from "../SessionRenderer/context";
import LeafletMap from "./LeafletMap";
import { fromMapUnit, getPixelSize, getZoomDelta, getZoomSnap } from "./utils";
import createDraggingMarkupIcon from "../../client/event-factories/createDraggingMarkupIcon";
import L from "leaflet";
import createPlacedMarkupIcon from "../../client/event-factories/createPlacedMarkupIcon";
import {
    CoordinatePreviewMode,
    PreviewFocusArea,
    PreviewFocusAreaPlacement,
    PreviewHotspot,
} from "modules/shared/types";
import { isStepAddress } from "modules/shared/utils";
import { isStepAddressEqual } from "modules/utils";
import HotspotLayer from "./HotspotLayer";
import PreviewHotspotLayer from "./PreviewHotspotLayer";
import ClickableAreaLayer from "./ClickableAreaLayer";
import MarkupIconLayer from "./MarkupIconLayer";
import PreviewFocusAreaLayer from "./PreviewFocusAreaLayer";
import FocusAreaLayer from "./FocusAreaLayer";
import { HotspotModal } from "../ActivityView/HotspotActivity/Hotspot";
import MapZoomControls from "../MapZoomControls";
import { MapContextProvider } from "./MapContext";
import LeafletMapNode from "./LeafletMapNode";
import { DialoguePlatformMap, dialoguePlatformMap } from "./DialoguePlatformMap";

type Props = {
    location: string;
    coordinatePreviewMode: CoordinatePreviewMode;
    previewHotspots: PreviewHotspot[];
    addPreviewHotspot: (previewHotspot: PreviewHotspot) => void;
    removePreviewHotspot: (id: number) => void;
    movePreviewHotspot: (movedHotspot: PreviewHotspot) => void;
    clearPreviewHotspots: () => void;
    previewFocusAreas: PreviewFocusArea[];
    addPreviewFocusArea: (PreviewFocusArea: PreviewFocusArea) => void;
    removePreviewFocusArea: (id: number) => void;
    clearPreviewFocusAreas: () => void;
    setCoordinatePreviewMode: (mode: CoordinatePreviewMode) => void;
    showRotateNavigator: () => void;
    iconHovered: boolean;
    onIconHovered: (value: boolean) => void;
    inPreviewMode: boolean;
    mapMetadata: LeafletMapMetadata;
    children?: React.ReactNode;
};

export const SessionLeafletMap: React.FC<Props> = ({
    location,
    coordinatePreviewMode,
    previewHotspots,
    addPreviewHotspot,
    removePreviewHotspot,
    movePreviewHotspot,
    previewFocusAreas,
    addPreviewFocusArea,
    removePreviewFocusArea,
    showRotateNavigator,
    iconHovered,
    onIconHovered,
    inPreviewMode,
    children: _children,
    mapMetadata,
}) => {
    const debug = useDebug();

    const participantId = useSessionParticipantId();
    const currentStep = useSessionSelector(currentStepAddress);

    const dispatch = useDispatch<Dispatch<Action>>();
    const sessionDispatch = useSessionDispatch();

    const hotspotStepState = useSessionSelector(createCurrentStepStateSelector("REVEAL_HOTSPOT"));
    const cardStackWithVisualStepState = useSessionSelector(createCurrentStepStateSelector("CARD_STACK_WITH_VISUAL"));
    const focus = useSessionSelector(createMapFocusSelector());

    const focusPixelArea = cardStackWithVisualStepState?.persistent?.focusArea ?? focus;
    const mapHighlightAreas = useSessionSelector(createMapHighlightAreasSelector());

    const hotspots = useSessionSelector(createMapHotspotsSelector());
    const hotspotsMemo = React.useMemo(
        () => hotspots,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [(hotspots || []).map((h) => h.mapCoordinates.join("x")).join("-")],
    );

    const clickableAreas = useSessionSelector(createMapClickAbleAreasSelector());
    const clickableAreasMemo = React.useMemo(
        () => clickableAreas,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [(clickableAreas || []).map((h) => h.mapCoordinates.join("x")).join("-")],
    );

    const enableMapMarkup =
        useSessionSelector(createEnableMapMarkupSelector()) || coordinatePreviewMode !== CoordinatePreviewMode.Off;

    const markupIcons = useSessionSelector(visibleMarkupIconsSelector);
    const markupIconsLimit = useSessionSelector(participantInfoStepMarkupIconLimit(participantId));

    const markupImage = useSessionSelector(createMapMarkupIconTypeSelector());
    const [userCurrentlyDragging, setUserCurrentlyDragging] = useState<boolean>(false);
    const userCurrentlyDraggingHandler = (isDragging: boolean) => {
        if (isDragging) {
            setUserCurrentlyDragging(true);
        } else {
            // Give the icon a second to drop.
            setTimeout(() => setUserCurrentlyDragging(false), 250);
        }
    };

    const backgroundColor = useSessionSelector(
        createSelector([createCurrentAppearanceOptionsSelector()], (appearance) => appearance?.backgroundColor),
    );

    const [focusAreaPreviewPlacement, setFocusAreaPreviewPlacement] = useState<PreviewFocusAreaPlacement | null>(null);
    const [leafletMap, setLeafletMap] = useState<DialoguePlatformMap>();

    // this was created with reference to how react-leaflet binds the dom node to the map
    // see https://github.com/PaulLeCam/react-leaflet/blob/9be06c0c1bb1e355f468393ac31ecb19e9a1f20d/packages/react-leaflet/src/MapContainer.tsx#L56C13-L56C13
    const leafletElement = useCallback(
        (node: HTMLDivElement | null) => {
            if (node !== null && !leafletMap) {
                const zoomSnap = getZoomSnap(mapMetadata);
                const zoomDelta = getZoomDelta(mapMetadata);

                const map = dialoguePlatformMap(mapMetadata, node, {
                    crs: L.CRS.Simple,
                    zoomControl: false,
                    maxBoundsViscosity: 1,
                    attributionControl: false,
                    zoomSnap: zoomSnap,
                    zoomDelta: zoomDelta,
                });
                setLeafletMap(map);
            }
        },
        // once we have initialized the map for our context we do not want to do so again
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // needed to properly clean the map when re-initializing it
    useEffect(() => {
        return () => {
            if (!!leafletMap) {
                leafletMap.stop();
                leafletMap.off();
                leafletMap.remove();
            }
        };
    }, [leafletMap]);

    const { tile_matrix, minzoom } = mapMetadata;

    const tile_matrix_string = JSON.stringify(tile_matrix);
    const pixelSize = React.useMemo(() => getPixelSize({ minzoom, tile_matrix }), [minzoom, tile_matrix_string]);

    const onMouseMove = React.useCallback(
        (e: { latlng: L.LatLng }) => {
            if (participantId) {
                sessionDispatch(
                    createMouseMove({
                        participantId,
                        position: {
                            type: "relativeToMap",
                            latlng: [e.latlng.lat, e.latlng.lng],
                        },
                    }),
                );
            }
            const [x, y] = fromMapUnit(e.latlng, getPixelSize({ tile_matrix, minzoom }));
            debug("Cursor location relative to map", [x, y].map((n) => Math.floor(n).toString()).join(", "));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [participantId, minzoom, tile_matrix_string, debug, sessionDispatch],
    );

    const onMouseMoveForFocusAreaPreviewPlacement = React.useCallback(
        (e: { latlng: L.LatLng }) => {
            if (!!focusAreaPreviewPlacement) {
                setFocusAreaPreviewPlacement({
                    firstPoint: focusAreaPreviewPlacement.firstPoint,
                    secondPoint: { x: e.latlng.lng, y: e.latlng.lat },
                });
            }
        },
        [focusAreaPreviewPlacement, setFocusAreaPreviewPlacement],
    );

    const onMouseClick = React.useCallback(
        (e: { latlng: L.LatLng }) => {
            if (!iconHovered) {
                if (coordinatePreviewMode === CoordinatePreviewMode.Hotspot) {
                    addPreviewHotspot({
                        id: Math.random(),
                        mapCoordinates: [e.latlng.lat, e.latlng.lng],
                    });
                } else if (coordinatePreviewMode === CoordinatePreviewMode.FocusArea) {
                    if (!focusAreaPreviewPlacement) {
                        setFocusAreaPreviewPlacement({
                            firstPoint: { x: e.latlng.lng, y: e.latlng.lat },
                            secondPoint: { x: e.latlng.lng, y: e.latlng.lat },
                        });
                    } else {
                        addPreviewFocusArea({
                            id: Math.random(),
                            mapCoordinates: {
                                x1: focusAreaPreviewPlacement.firstPoint.x,
                                y1: focusAreaPreviewPlacement.firstPoint.y,
                                x2: e.latlng.lng,
                                y2: e.latlng.lat,
                            },
                        });
                        setFocusAreaPreviewPlacement(null);
                    }
                } else if (
                    coordinatePreviewMode === CoordinatePreviewMode.Off &&
                    participantId &&
                    isStepAddress(currentStep) &&
                    enableMapMarkup &&
                    !userCurrentlyDragging &&
                    markupIconsLimit !== 0
                ) {
                    const iconId = uuidv4();

                    sessionDispatch(
                        createAddMarkupIcon({
                            icon: {
                                iconId: iconId,
                                participantId: participantId,
                                coordinates: { x: e.latlng.lng, y: e.latlng.lat },
                                addedOnStep: currentStep,
                                iconType: markupImage,
                                isBeingDraggedBy: null,
                            },
                        }),
                    );
                }
            }
        },
        [
            iconHovered,
            coordinatePreviewMode,
            addPreviewHotspot,
            addPreviewFocusArea,
            focusAreaPreviewPlacement,
            participantId,
            currentStep,
            sessionDispatch,
            markupImage,
            enableMapMarkup,
            userCurrentlyDragging,
            markupIconsLimit,
        ],
    );

    const onIconClick = React.useCallback(
        (icon: Icon) => {
            const iconPlacedOnCurrentStep = isStepAddressEqual(currentStep, icon.addedOnStep);
            if (participantId && iconPlacedOnCurrentStep) {
                onIconHovered(false);
                sessionDispatch(
                    createRemoveMarkupIcon({
                        iconId: icon.iconId,
                        placerId: icon.participantId,
                        clickerId: participantId,
                    }),
                );
            }
        },
        [participantId, onIconHovered, sessionDispatch, currentStep],
    );

    const onMarkupIconDragStart = React.useCallback(
        (icon: Icon) => {
            if (participantId && !icon.isBeingDraggedBy) {
                userCurrentlyDraggingHandler(true);
                sessionDispatch(
                    createDraggingMarkupIcon({
                        icon: icon,
                        draggerId: participantId,
                    }),
                );
            }
        },
        [participantId, sessionDispatch],
    );

    const onMarkupIconDragEnd = React.useCallback(
        (icon: Icon, latlng: L.LatLng) => {
            if (participantId && participantId === icon.isBeingDraggedBy) {
                userCurrentlyDraggingHandler(false);
                sessionDispatch(
                    createPlacedMarkupIcon({
                        icon: icon,
                        placerId: participantId,
                        coordinates: { x: latlng.lng, y: latlng.lat },
                    }),
                );
            }
        },
        [participantId, sessionDispatch],
    );

    const onMapMove = React.useCallback(
        (bounds: L.LatLngBounds) => {
            dispatch({ type: "MAP_BOUNDS_CHANGED", bounds });

            const [left, top] = fromMapUnit(
                bounds.getNorthWest(),
                // not specifying zoom because we specifically want it at the
                // outermost zoom
                getPixelSize({ tile_matrix, minzoom }),
            );
            const [right, bottom] = fromMapUnit(bounds.getSouthEast(), getPixelSize({ tile_matrix, minzoom }));

            debug("Focus Map area", [left, top, right, bottom].map((n) => Math.floor(n).toString()).join(", "));
        },
        [debug, dispatch, tile_matrix, minzoom],
    );

    const showHotspotModal = React.useCallback(
        (idx: number) => {
            sessionDispatch(
                createHotspotShowDetails({
                    hotspotIdx: idx,
                    hotspotUuid: uuidv4(),
                }),
            );
        },
        [sessionDispatch],
    );

    const showClickableAreaModal = React.useCallback(
        (idx: number) => {
            sessionDispatch(
                createClickableAreaShowDetails({
                    clickableAreaIdx: idx,
                    clickableAreaUuid: uuidv4(),
                }),
            );
        },
        [sessionDispatch],
    );

    const closeClickableAreaModal = React.useCallback(() => {
        const clickableAreaUuid = hotspotStepState?.transient?.clickableAreaUuid;
        if (!clickableAreaUuid) {
            return;
        }
        sessionDispatch(
            createClickableAreaHideDetails({
                clickableAreaUuid: clickableAreaUuid,
            }),
        );
    }, [sessionDispatch, hotspotStepState]);

    const closeHotspotModal = React.useCallback(() => {
        const hotspotUuid = hotspotStepState?.transient?.hotspotUuid;
        if (!hotspotUuid) {
            return;
        }

        sessionDispatch(
            createHotspotHideDetails({
                hotspotUuid,
            }),
        );
    }, [sessionDispatch, hotspotStepState]);

    const flipHotspotCardToBack = React.useCallback(() => {
        sessionDispatch(createHotspotFlipCardToBack());
    }, [sessionDispatch]);

    const flipHotspotCardToFront = React.useCallback(() => {
        sessionDispatch(createHotspotFlipCardToFront());
    }, [sessionDispatch]);

    // TODO: need to memoize these, specficially those that return an object (e.g. viewedHotspotIdxs and activeHotspot)
    const isModalVisible = hotspotStepState?.transient?.modalVisible ?? false;
    const isCardFlipped = hotspotStepState?.transient?.openedCardFlipped ?? false;
    const selectedHotspotIdx = useMemo(
        () => hotspotStepState?.transient?.selectedHotspotIdx ?? null,
        [hotspotStepState],
    );
    const activeHotspot = useMemo(
        () => hotspotsMemo?.find((_spot, idx) => idx === selectedHotspotIdx),
        [selectedHotspotIdx, hotspotsMemo],
    );
    const viewedHotspotIdxs = useMemo(() => hotspotStepState?.persistent?.viewedHotspotIdxs ?? [], [hotspotStepState]);
    const selectedClickableAreaIdx = useMemo(
        () => hotspotStepState?.transient?.selectedClickableAreaIdx ?? null,
        [hotspotStepState],
    );
    const activeClickableArea = useMemo(
        () => clickableAreasMemo?.find((_ca, idx) => idx === selectedClickableAreaIdx),
        [selectedClickableAreaIdx, clickableAreasMemo],
    );
    const viewedClickableAreaIdxs: number[] = useMemo(
        () => hotspotStepState?.persistent?.viewedClickableAreaIdxs ?? [],
        [hotspotStepState],
    );
    const isNavigationDisabled = useSessionSelector(createNavigationDisabledSelector(useSessionParticipantId()));

    return (
        <>
            {/* The LeafletMapNode component should never need to re-render.
                Unmounting that node will not re-initialize the leaflet map itself,
                which will cause certain class names the library intends to add to
                not be added as they are only added during the map's initialization. */}
            <LeafletMapNode ref={leafletElement} />
            {hotspotsMemo && activeHotspot && (
                <HotspotModal
                    key={activeHotspot.title}
                    hotspot={activeHotspot}
                    isOpen={isModalVisible}
                    onHide={closeHotspotModal}
                    isNavigationDisabled={isNavigationDisabled}
                    showRotateNavigator={showRotateNavigator}
                    hideBackground={activeHotspot.hideBackground ?? false}
                    isCardFlipped={isCardFlipped}
                    flipCardToBack={flipHotspotCardToBack}
                    flipCardToFront={flipHotspotCardToFront}
                />
            )}
            {clickableAreasMemo && activeClickableArea && (
                <HotspotModal
                    key={activeClickableArea.title}
                    hotspot={activeClickableArea}
                    isOpen={isModalVisible}
                    onHide={closeClickableAreaModal}
                    isNavigationDisabled={isNavigationDisabled}
                    showRotateNavigator={showRotateNavigator}
                    hideBackground={activeClickableArea.hideBackground ?? false}
                    isCardFlipped={isCardFlipped}
                    flipCardToBack={flipHotspotCardToBack}
                    flipCardToFront={flipHotspotCardToFront}
                />
            )}
            {!!leafletMap && (
                <MapContextProvider value={{ map: leafletMap, setMap: setLeafletMap }}>
                    <LeafletMap
                        focusPixelArea={focusPixelArea}
                        onMapMove={onMapMove}
                        onMouseMove={onMouseMove}
                        onMouseClick={onMouseClick}
                        focusAreaPreviewPlacement={focusAreaPreviewPlacement}
                        onMouseMoveForFocusAreaPreviewPlacement={onMouseMoveForFocusAreaPreviewPlacement}
                        location={location}
                        backgroundColor={backgroundColor}
                        enableMapMarkup={enableMapMarkup}
                        mapMetadata={mapMetadata}
                    />
                    <MapZoomControls inPreviewMode={inPreviewMode} />
                    <HotspotLayer
                        mapHotspots={hotspotsMemo}
                        showHotspotModal={showHotspotModal}
                        selectedHotspotIdx={selectedHotspotIdx}
                        viewedHotspotIdxs={viewedHotspotIdxs}
                        pixelSize={pixelSize}
                        isNavigationDisabled={isNavigationDisabled}
                    />

                    <ClickableAreaLayer
                        pixelSize={pixelSize}
                        mapClickableAreas={clickableAreasMemo}
                        viewedClickableAreaIdxs={viewedClickableAreaIdxs}
                        showClickableAreaModal={showClickableAreaModal}
                    />
                    <MarkupIconLayer
                        markupIcons={markupIcons}
                        enableMapMarkup={enableMapMarkup}
                        currentStepAddr={currentStep}
                        onIconClick={onIconClick}
                        onIconHover={onIconHovered}
                        onIconDragStart={onMarkupIconDragStart}
                        onIconDragEnd={onMarkupIconDragEnd}
                    />
                    <FocusAreaLayer pixelSize={pixelSize} mapHighlightAreas={mapHighlightAreas} />
                    {!!inPreviewMode && (
                        <>
                            <PreviewHotspotLayer
                                pixelSize={pixelSize}
                                previewHotspots={previewHotspots}
                                removePreviewHotspot={removePreviewHotspot}
                                movePreviewHotspot={movePreviewHotspot}
                                onIconHover={onIconHovered}
                            />
                            <PreviewFocusAreaLayer
                                pixelSize={pixelSize}
                                previewFocusAreas={previewFocusAreas}
                                removePreviewFocusArea={removePreviewFocusArea}
                                onIconHover={onIconHovered}
                                focusAreaPreviewPlacement={focusAreaPreviewPlacement}
                            />
                        </>
                    )}
                </MapContextProvider>
            )}
        </>
    );
};

export default SessionLeafletMap;
