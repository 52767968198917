import { FaUserFriends } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { keyBy } from "lodash-es";
import createChangeName from "modules/client/event-factories/createChangeName";
import createRemoveFacilitator from "modules/client/event-factories/createRemoveFacilitator";
import { useLocalizedString } from "modules/client/localization";
import { useSessionDispatch, useSessionSelector } from "modules/components/SessionRenderer/context";
import { participants } from "modules/shared/selectors/participants/participants";
import { ChangeNameValidationRules } from "modules/shared/sessionEventValidator";
import { ParticipantInfo } from "modules/shared/types";
import React, { useCallback, useState } from "react";
import { Badge, Button, Col, Dropdown, Modal, OverlayTrigger, Row, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdMoreVert } from "react-icons/md";
import { object, string } from "yup";
import "./TeamInfo.scss";

type Props = {
    id: string;
    participantId: string | null;
    currentNavigator: ParticipantInfo | null;
    isFacilitator: boolean;
    participants: ParticipantInfo[];
};

type FormData = { participantIdentifier: string };

const _TeamInfo: React.FC<Props> = (props) => {
    const localized = useLocalizedString();
    const dispatch = useSessionDispatch();

    const [showWarningModal, setShowWarningModal] = useState(false);

    const handleCloseWarningModal = () => {
        setShowWarningModal(false);
    };

    const handleRemoveFacilitator = useCallback(
        (participantId: string | null) => {
            dispatch(
                createRemoveFacilitator({
                    participantId: participantId,
                }),
            );
        },
        [dispatch],
    );

    const removingOwnFacilitatorRoleWarningModal = (
        <Modal show={showWarningModal} onHide={handleCloseWarningModal}>
            <Modal.Header />
            <Modal.Body>
                <p> {localized("vdpHud_removeFacilitatorWarningMessage")}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleCloseWarningModal}>
                    {localized("vdpHud_removeFacilitatorWarningCancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleCloseWarningModal();
                        handleRemoveFacilitator(props.participantId);
                    }}
                >
                    {localized("vdpHud_removeFacilitatorWarningConfirm")}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <>
            <OverlayTrigger placement="bottom" overlay={<div />}>
                <Button variant="outline-primary" className="hide-show-btn float-right">
                    <span>
                        <FaUserFriends />
                    </span>
                    <div>{localized("vdpHud_infoTeamButton")}</div>
                </Button>
            </OverlayTrigger>
            {removingOwnFacilitatorRoleWarningModal}
        </>
    );
};
export const TeamInfo = React.memo(_TeamInfo);

export const ParticipantRow: React.FC<
    ParticipantInfo & {
        isNavigator: boolean;
        isFacilitator: boolean;
        joiningText: string;
        isUsersOwnRow: boolean;
        currentUserIsFacilitator: boolean;
        handleMakeNavigator: (participantId: string) => void;
        handleChangeNavigator: () => void;
        handleMakeFacilitator: (participantId: string) => void;
        handleRemoveFacilitator: (participantId: string) => void;
        isTeamHovered: boolean;
        handleOnMouseLeaveTeam: () => void;
    }
> = (props) => {
    const localized = useLocalizedString();

    return (
        <Row className="participant-row g-0">
            <Col className="name-col">{props.name ?? props.joiningText}</Col>
            <Col className="badge-col" style={{ padding: "unset" }}>
                {props.assignedRoles.length > 0 && (
                    <Badge className="role-badge" pill bg="light" text="primary">
                        {props.isFacilitator && localized("vdpHud_facilitator")}
                        {props.assignedRoles.length > 1 && " + "}
                        {props.isNavigator && localized("vdpHud_navigator")}
                    </Badge>
                )}
            </Col>
            <Col className="facilitator-menu-col">
                <FacilitatorMenu
                    participantId={props.id}
                    isNavigator={props.isNavigator}
                    isFacilitator={props.isFacilitator}
                    currentUserIsFacilitator={props.currentUserIsFacilitator}
                    isUsersOwnRow={props.isUsersOwnRow}
                    handleMakeNavigator={props.handleMakeNavigator}
                    handleChangeNavigator={props.handleChangeNavigator}
                    handleMakeFacilitator={props.handleMakeFacilitator}
                    handleRemoveFacilitator={props.handleRemoveFacilitator}
                    isTeamHovered={props.isTeamHovered}
                    handleOnMouseLeaveTeam={props.handleOnMouseLeaveTeam}
                />
            </Col>
        </Row>
    );
};

type EditNameModalProps = {
    participantId: string;
    isEditNameOpen: boolean;
    setIsEditNameOpen: (bool: boolean) => void;
    handleOnMouseLeaveTeam: () => void;
};

export const EditNameModal: React.FC<EditNameModalProps> = (props) => {
    const localized = useLocalizedString();
    const dispatch = useSessionDispatch();

    const handleEditNameClose = () => {
        props.setIsEditNameOpen(false);
    };

    const participantList = keyBy(useSessionSelector(participants), (p: ParticipantInfo) => {
        if (p.name) {
            return p.name.toLowerCase();
        }
        return null;
    });

    const EnterIdentifiersSchema = object().shape({
        participantIdentifier: string()
            .test("duplicate_name", "", function (value: string | undefined) {
                if (value && value.toLowerCase() in participantList) {
                    return this.createError({
                        message: localized("vdpNameEntry_identifiersDuplicateName", value),
                    });
                }
                return true;
            })
            .trim()
            .required(localized("vdpNameEntry_identifiersName"))
            .min(
                ChangeNameValidationRules.name.minLength,
                localized("vdpValidationErrorMinLength", ChangeNameValidationRules.name.minLength),
            )
            .max(
                ChangeNameValidationRules.name.maxLength,
                localized("vdpValidationErrorMaxLength", ChangeNameValidationRules.name.maxLength),
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(EnterIdentifiersSchema),
    });

    const onSubmit = handleSubmit((data) => {
        handleEditNameClose();
        props.handleOnMouseLeaveTeam();
        if (props.participantId) {
            dispatch(
                createChangeName({
                    participantId: props.participantId,
                    participantName: data.participantIdentifier,
                }),
            );
        }
    });

    return (
        <div onKeyDown={(e) => e.stopPropagation()}>
            <Modal
                dialogClassName="modal-frame"
                centered
                show={props.isEditNameOpen}
                onHide={() => {
                    handleEditNameClose();
                    props.handleOnMouseLeaveTeam();
                }}
                size="lg"
                backdrop="static"
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{localized("sidebar_editNameMenuItem")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="name-box">
                            <Form.Group controlId="participantIdentifier">
                                <Form.Label>{localized("vdpNameEntry_inputName")}</Form.Label>
                                <Form.Control
                                    autoComplete="nickname participant-identifier-no-auto-fill"
                                    placeholder={localized("sidebar_nameEditPlaceholder")}
                                    data-lpignore="true"
                                    isInvalid={!!errors.participantIdentifier?.message}
                                    {...register("participantIdentifier")}
                                />
                                <Form.Text className="text-danger">{errors.participantIdentifier?.message}</Form.Text>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                handleEditNameClose();
                                props.handleOnMouseLeaveTeam();
                            }}
                        >
                            {localized("vdpActivity_closeModal")}
                        </Button>
                        <Button variant="primary" onClick={onSubmit}>
                            {localized("sidebar_editNameSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

type FacilitatorMenuProps = {
    participantId: string;
    isNavigator: boolean;
    isFacilitator: boolean;
    currentUserIsFacilitator: boolean;
    isUsersOwnRow: boolean;
    handleMakeNavigator: (participantId: string) => void;
    handleChangeNavigator: () => void;
    handleMakeFacilitator: (participantId: string) => void;
    handleRemoveFacilitator: (participantId: string) => void;
    handleOnMouseLeaveTeam: () => void;
    isTeamHovered: boolean;
};

export const FacilitatorMenu: React.FC<FacilitatorMenuProps> = (props) => {
    const localized = useLocalizedString();
    const [isEditNameOpen, setIsEditNameOpen] = React.useState(false);

    const handleNavigatorToggle = () => {
        if (props.currentUserIsFacilitator) {
            if (props.isNavigator) {
                // if facilitator removes navigator role from user, will be assigned randomly
                props.handleChangeNavigator();
            } else {
                props.handleMakeNavigator(props.participantId);
            }
        }
    };

    const handleFacilitatorToggle = () => {
        if (props.currentUserIsFacilitator) {
            if (props.isFacilitator) {
                // toggling off a facilitator
                props.handleRemoveFacilitator(props.participantId);
            } else {
                // toggling on a facilitator (only allowed if current user/person toggling is facilitator)
                props.handleMakeFacilitator(props.participantId);
            }
        }
    };

    return (
        <>
            <Dropdown drop={"up"}>
                <Dropdown.Toggle
                    variant=""
                    className="facilitator-menu-button"
                    disabled={!(props.currentUserIsFacilitator || props.isUsersOwnRow)}
                >
                    <MdMoreVert size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="facilitator-menu" popperConfig={{ strategy: "fixed" }} renderOnMount>
                    <Dropdown.Item
                        className="item-label edit-name"
                        onClick={() => {
                            setIsEditNameOpen(true);
                        }}
                    >
                        {localized("sidebar_editNameMenuItem")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header className="manage-roles">
                        {localized("sidebar_manageRolesMenuLabel")}
                    </Dropdown.Header>
                    <Dropdown.Item disabled={!props.currentUserIsFacilitator} onClick={handleNavigatorToggle}>
                        <div className="item-container">
                            <div className="item-label">{localized("vdpHud_navigator")}</div>
                            <Form.Switch
                                readOnly
                                disabled={!props.currentUserIsFacilitator}
                                checked={props.isNavigator}
                            />
                        </div>
                        <div className="item-description">{localized("sidebar_navigatorDescription")}</div>
                    </Dropdown.Item>
                    <Dropdown.Item disabled={!props.currentUserIsFacilitator} onClick={handleFacilitatorToggle}>
                        <div className="item-container">
                            <div className="item-label">{localized("vdpHud_facilitator")}</div>
                            <Form.Switch
                                readOnly
                                disabled={!props.currentUserIsFacilitator}
                                checked={props.isFacilitator}
                            />
                        </div>
                        <div className="item-description">{localized("sidebar_facilitatorDescription")}</div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <EditNameModal
                participantId={props.participantId}
                isEditNameOpen={isEditNameOpen}
                setIsEditNameOpen={setIsEditNameOpen}
                handleOnMouseLeaveTeam={props.handleOnMouseLeaveTeam}
            />
        </>
    );
};
