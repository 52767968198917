import { Role } from "../content-types";
import { navigatorShouldBeAssigned } from "../selectors/participants/navigatorShouldBeAssigned";
import { ParticipantAddedEvent } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const participantAdded: EventReducerDefinition<ParticipantAddedEvent> = {
    predictable: true,
    checkIfApplicable: (state: SessionState, payload: ParticipantAddedEvent) => {
        const alreadyAdded = state.participants.filter((p) => p.id == payload.id);
        return alreadyAdded.length == 0;
    },
    reducer: (state: SessionState, payload: ParticipantAddedEvent) => {
        // if I joined an empty session and the navigator should be assigned, assign it
        const assignedRoles =
            state.participants.length === 0 && navigatorShouldBeAssigned(state) ? [Role.Navigator] : [];
        const participantCursors = {
            ...state.participantCursors,
            [payload.id]: null,
        };
        return {
            ...state,
            participants: [
                ...state.participants,
                {
                    id: payload.id,
                    name: null,
                    ready: false,
                    assignedRoles,
                },
            ],
            participantCursors,
        };
    },
};
export default participantAdded;
