import { TestMessage } from "../session-events";
import { SessionState } from "../types";

const TEST_MESSAGE = {
    predictable: true,
    checkIfApplicable: (): boolean => {
        return true;
    },
    reducer: (state: SessionState, payload: TestMessage): SessionState => {
        return state;
    },
};
export default TEST_MESSAGE;
