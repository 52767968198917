import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { StepAddress, StepIntroAddress } from "modules/shared/types";
import { useSessionParticipantId, useSessionSelector } from "../SessionRenderer/context";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { Icon } from "modules/shared/activity/Markup/types";
import { difference } from "lodash";
import { isStepAddressEqual, participantHasRole } from "modules/utils";
import { Role } from "modules/shared/content-types";
import { useLocalizedString } from "modules/client/localization";
import { useMapContext } from "./MapContext";

type Props = {
    markupIcons: Icon[];
    enableMapMarkup: boolean;
    currentStepAddr: StepAddress | StepIntroAddress;
    onIconClick: (icon: Icon) => void;
    onIconHover: (value: boolean) => void;
    onIconDragStart: (icon: Icon) => void;
    onIconDragEnd: (icon: Icon, latlng: L.LatLng) => void;
};

const MarkupIconLayer: React.FC<Props> = ({
    markupIcons,
    enableMapMarkup,
    currentStepAddr,
    onIconClick,
    onIconHover,
    onIconDragStart,
    onIconDragEnd,
}) => {
    const { map } = useMapContext();
    const markersOnMap = useRef<Map<string, { icon: Icon; marker: L.Marker }>>(new Map());
    const localized = useLocalizedString();
    const participantId = useSessionParticipantId();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isFacilitator = !!participantInfo && participantHasRole(participantInfo, Role.Facilitator);

    useEffect(() => {
        const markersToRemove = difference(
            Array.from(markersOnMap.current.keys()),
            markupIcons.map((icon) => icon.iconId),
        );

        markersToRemove.forEach((key) => {
            markersOnMap.current.get(key)?.marker?.removeFrom(map);
            markersOnMap.current.delete(key);
        });

        markupIcons.forEach((icon) => {
            const iconPlacedOnCurrentStep = isStepAddressEqual(currentStepAddr, icon.addedOnStep);
            if (markersOnMap.current.has(icon.iconId)) {
                const marker = markersOnMap.current.get(icon.iconId)!;
                const interactionPermitted =
                    enableMapMarkup &&
                    iconPlacedOnCurrentStep &&
                    (icon.participantId === participantId || isFacilitator);
                let draggingEnabled = interactionPermitted;
                if (icon.isBeingDraggedBy !== marker.icon.isBeingDraggedBy) {
                    marker.icon.isBeingDraggedBy = icon.isBeingDraggedBy;

                    if (!icon.isBeingDraggedBy) {
                        if (interactionPermitted) {
                            marker.marker.dragging?.enable();
                            draggingEnabled = true;
                        } else {
                            marker.marker.dragging?.disable();
                            draggingEnabled = false;
                        }
                        marker.marker.setOpacity(1.0);
                    } else if (icon.isBeingDraggedBy !== participantId) {
                        marker.marker.dragging?.disable();
                        draggingEnabled = false;
                        marker.marker.setOpacity(0.7);
                    }
                }

                if (!draggingEnabled || !interactionPermitted) {
                    marker.marker.dragging?.disable();
                } else if (draggingEnabled && interactionPermitted) {
                    marker.marker.dragging?.enable();
                }

                if (
                    icon.coordinates.x !== marker.icon.coordinates.x ||
                    icon.coordinates.y !== marker.icon.coordinates.y
                ) {
                    marker.icon.coordinates = icon.coordinates;
                    marker.marker.setLatLng({ lat: icon.coordinates.y, lng: icon.coordinates.x });
                }

                marker.marker.off("click");
                marker.marker.off("dragstart");
                marker.marker.off("dragend");

                // only redraw the icon if it isn't being dragged and wasn't placed on the current step
                if (!iconPlacedOnCurrentStep && !icon.isBeingDraggedBy) {
                    const hasDeleteOption =
                        interactionPermitted && enableMapMarkup
                            ? '<i class="material-icons delete" alt="delete markup icon" >close</i>'
                            : "";
                    const isMyIcon =
                        participantId === icon.participantId
                            ? `<div class="me-badge">${localized("vdpActivity_defaultCurrentParticipantName")}</div>`
                            : "";
                    const markerHtml = `<div class="markup-icon">
                        <i class="material-icons marker" style="font-size: x-large" >${icon.iconType}</i>
                        ${hasDeleteOption}
                    </div>
                    ${isMyIcon}`;
                    const mapIcon = L.divIcon({
                        html: markerHtml,
                        iconAnchor: [25, 25],
                        className: "markup-icon-container",
                    });
                    marker.marker.setIcon(mapIcon);
                }

                marker.marker
                    .on("click", () => onIconClick(icon))
                    .on("dragstart", () => {
                        onIconDragStart(icon);
                    })
                    .on("dragend", (e) => {
                        marker.marker.on("click", () => onIconClick(icon));
                        marker.marker.on("mouseover", () => onIconHover(true));
                        marker.marker.on("mouseout", () => onIconHover(false));
                        onIconDragEnd(icon, (e.target as L.Marker).getLatLng());
                    });
            } else {
                const canInteractWithIcon = participantId === icon.participantId || isFacilitator;
                const hasDeleteOption =
                    canInteractWithIcon && enableMapMarkup
                        ? '<i class="material-icons delete" alt="delete markup icon" >close</i>'
                        : "";
                const isMyIcon =
                    participantId === icon.participantId
                        ? `<div class="me-badge">${localized("vdpActivity_defaultCurrentParticipantName")}</div>`
                        : "";
                const markerHtml = `<div class="markup-icon">
                    <i class="material-icons marker" style="font-size: x-large" >${icon.iconType}</i>
                    ${hasDeleteOption}
                </div>
                ${isMyIcon}`;

                const mapIcon = L.divIcon({
                    html: markerHtml,
                    iconAnchor: [25, 25],
                    className: "markup-icon-container",
                });

                const marker = L.marker([icon.coordinates.y, icon.coordinates.x], {
                    icon: mapIcon,
                    draggable: iconPlacedOnCurrentStep ? icon.participantId === participantId || isFacilitator : false,
                })
                    .on("click", () => onIconClick(icon))
                    .on("mouseover", () => onIconHover(true))
                    .on("mouseout", () => onIconHover(false))
                    .addTo(map);

                marker.on("dragstart", () => {
                    marker.off("click");
                    marker.off("mouseover");
                    marker.off("mouseout");
                    onIconDragStart(icon);
                });
                marker.on("drag", () => {
                    marker.off("click");
                    marker.off("mouseover");
                    marker.off("mouseout");
                });
                marker.on("dragend", (e) => {
                    marker.on("click", () => onIconClick(icon));
                    marker.on("mouseover", () => onIconHover(true));
                    marker.on("mouseout", () => onIconHover(false));
                    onIconDragEnd(icon, (e.target as L.Marker).getLatLng());
                });

                markersOnMap.current.set(icon.iconId, { icon, marker });
            }
        });
    }, [
        map,
        markupIcons,
        enableMapMarkup,
        isFacilitator,
        participantId,
        localized,
        onIconClick,
        onIconHover,
        onIconDragStart,
        onIconDragEnd,
        currentStepAddr,
    ]);

    return <></>;
};
export default MarkupIconLayer;
