import classNames from "classnames";
import createAddAssetMarkupIcon from "modules/client/event-factories/createAddAssetMarkupIcon";
import RichText from "modules/components/RichText";
import {
    useSessionDispatch,
    useSessionParticipantId,
    useSessionSelector,
} from "modules/components/SessionRenderer/context";
import { Role, SingleAssetStep } from "modules/shared/content-types";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { createAssetMarkupIconTypeSelector } from "modules/shared/selectors/step-definition/single-asset/createAssetMarkupIconTypeSelector";
import { assetMarkupIconsSelector } from "modules/shared/selectors/step-definition/single-asset/assetMarkupIconsSelector";
import { singleAssetPreviouslyPlacedIcons } from "modules/shared/selectors/step-definition/single-asset/singleAssetPreviouslyPlacedIcons";
import { currentStepAddress } from "modules/shared/selectors/step-address/currentStepAddress";
import { participantAssetMarkupLimitSelector } from "modules/shared/selectors/step-definition/single-asset/participantAssetMarkupLimitSelector";
import { v4 as uuidv4 } from "uuid";
import { BaseActivityProps } from "modules/shared/types";
import { isStepAddress } from "modules/shared/utils";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BootstrapCard from "react-bootstrap/Card";
import "./single-asset-activity.scss";
import { participantHasRole } from "modules/utils";
import { useLocalizedString } from "modules/client/localization";
import createRemoveAssetMarkupIcon from "modules/client/event-factories/createRemoveAssetMarkupIcon";
import { Coordinates, Icon } from "modules/shared/activity/Markup/types";
import { CSSTransition } from "react-transition-group";

type Props = SingleAssetStep &
    BaseActivityProps & {
        onIconHovered: (value: boolean) => void;
    };

const SingleAssetActivity: React.FC<Props> = (props) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const markupGroupRef = useRef<HTMLDivElement>(null);
    const dispatch = useSessionDispatch();
    const participantId = useSessionParticipantId();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isFacilitator = !!participantInfo && participantHasRole(participantInfo, Role.Facilitator);
    const currentStep = useSessionSelector(currentStepAddress);
    const markupImage = useSessionSelector(createAssetMarkupIconTypeSelector());
    const currentMarkupIcons = useSessionSelector(assetMarkupIconsSelector);
    const allMarkupIcons = useSessionSelector(singleAssetPreviouslyPlacedIcons);
    const [cardDimensions, setCardDimensions] = useState<Coordinates | null>(null);

    //currentparticipant limit selectors
    const currentParticipantLimit = useSessionSelector(participantAssetMarkupLimitSelector(participantId));

    const [showIcons, setShowIcons] = useState(true);

    useLayoutEffect(() => {
        const styles = [];

        if (props.additionalProps) {
            styles.push(props.additionalProps);
        }

        const card = cardRef.current;
        if (!card) {
            return;
        }

        card.style.cssText = styles.join(";");
    }, [props.additionalProps]);

    const changeCardDimensions = useCallback(() => {
        const card = cardRef.current;
        if (!card) {
            return;
        }
        const width = card.clientWidth;
        const height = card.clientHeight;
        setCardDimensions({ x: width, y: height });
    }, []);

    const beginIconTransition = useCallback((e: TransitionEvent) => {
        // we only want this event to fire on a padding event from the sidebar
        if (e.propertyName !== "padding-left") {
            return;
        }
        setShowIcons(false);
    }, []);

    const endIconTransition = useCallback((e: TransitionEvent) => {
        // we only want this event to fire on a padding event from the sidebar
        if (e.propertyName !== "padding-left") {
            return;
        }
        setShowIcons(true);
    }, []);

    useLayoutEffect(() => {
        const card = cardRef.current;
        if (!card) {
            return;
        }
        window.addEventListener("resize", changeCardDimensions);
        const activityViewParent = document.getElementById("activity-view-id");
        activityViewParent?.addEventListener("transitionstart", beginIconTransition, true);
        activityViewParent?.addEventListener("transitionend", changeCardDimensions, true);
        activityViewParent?.addEventListener("transitionend", endIconTransition, true);

        // if we are loading the activity page on page load, we need to wait for the inner content to be created
        setTimeout(changeCardDimensions, 750);

        return () => {
            window.removeEventListener("resize", changeCardDimensions);
            activityViewParent?.removeEventListener("transitionstart", beginIconTransition, true);
            activityViewParent?.removeEventListener("transitionend", changeCardDimensions, true);
            activityViewParent?.removeEventListener("transitionend", endIconTransition, true);
        };
    }, [changeCardDimensions, beginIconTransition, endIconTransition]);

    const className = classNames(
        "single-asset-activity-card",
        {
            "hidden-background": props.hideBackground,
        },
        {
            "markup-enabled": props.enableAssetMarkup,
        },
        {
            "no-asset-markup": !props.enableAssetMarkup,
        },
    );

    const alignmentClassName = classNames(
        "single-asset-container",
        {
            "text-start": props.contentAlignment === "Left" || !props.contentAlignment,
        },
        {
            "text-center": props.contentAlignment === "Center",
        },
        {
            "text-end": props.contentAlignment === "Right",
        },
    );

    const placeMarkupIcon = (e: React.MouseEvent<HTMLElement>) => {
        const iconId = uuidv4();
        if (participantId && isStepAddress(currentStep) && !!cardDimensions) {
            const calculatedX = e.nativeEvent.offsetX / cardDimensions.x;
            const calculatedY = e.nativeEvent.offsetY / cardDimensions.y;
            if (currentParticipantLimit !== 0) {
                dispatch(
                    createAddAssetMarkupIcon({
                        icon: {
                            iconId: iconId,
                            participantId: participantId,
                            coordinates: { x: calculatedX, y: calculatedY },
                            addedOnStep: currentStep,
                            iconType: markupImage,
                            isBeingDraggedBy: null,
                        },
                    }),
                );
            } else {
                console.error(`Participant ${participantId} has reached or exceeded their limit!`);
            }
        }
    };

    const removeMarkupIcon = React.useCallback(
        (icon: Icon) => {
            if (participantId) {
                dispatch(
                    createRemoveAssetMarkupIcon({
                        iconId: icon.iconId,
                        placerId: icon.participantId,
                        clickerId: participantId,
                    }),
                );
            }
        },
        [participantId, dispatch],
    );

    return (
        <Row>
            <Col className={alignmentClassName} style={{ width: "fit-content" }}>
                <BootstrapCard className={className} ref={cardRef}>
                    <BootstrapCard.Body
                        onClick={(e: React.MouseEvent<HTMLElement>) => props.enableAssetMarkup && placeMarkupIcon(e)}
                        onMouseEnter={() =>
                            currentParticipantLimit === 0 ? props.onIconHovered(false) : props.onIconHovered(true)
                        }
                        onMouseLeave={() => props.onIconHovered(false)}
                    >
                        {/* if the enableAssetMarkup true, show only current icons */}
                        {(props.enableAssetMarkup || (!!allMarkupIcons && allMarkupIcons.length > 0)) &&
                            !!cardDimensions && (
                                <CSSTransition
                                    in={showIcons}
                                    unmountOnExit
                                    nodeRef={markupGroupRef}
                                    timeout={400}
                                    classNames="markup-transition"
                                >
                                    <div ref={markupGroupRef}>
                                        {currentMarkupIcons?.map((icon, id) => {
                                            const canInteractWithIcon =
                                                participantId === icon.participantId || isFacilitator;
                                            const isMyIcon = participantId === icon.participantId;
                                            const calculatedX = icon.coordinates.x * cardDimensions.x;
                                            const calculatedY = icon.coordinates.y * cardDimensions.y;
                                            return (
                                                <SingleAssetMarkupIcon
                                                    key={id}
                                                    icon={icon}
                                                    interactionPermission={canInteractWithIcon}
                                                    isMyIcon={isMyIcon}
                                                    removeMarkupIcon={removeMarkupIcon}
                                                    onIconHovered={props.onIconHovered}
                                                    placement={{ x: calculatedX, y: calculatedY } as Coordinates}
                                                />
                                            );
                                        })}
                                        {!!allMarkupIcons &&
                                            allMarkupIcons.length > 0 &&
                                            !!cardDimensions &&
                                            allMarkupIcons.map((icon: Icon, id: number) => {
                                                const isMyIcon = participantId === icon.participantId;
                                                const calculatedX = icon.coordinates.x * cardDimensions.x;
                                                const calculatedY = icon.coordinates.y * cardDimensions.y;
                                                return (
                                                    <SingleAssetMarkupIcon
                                                        key={"previously-placed-" + id}
                                                        icon={icon}
                                                        interactionPermission={false}
                                                        isMyIcon={isMyIcon}
                                                        removeMarkupIcon={() => null}
                                                        onIconHovered={props.onIconHovered}
                                                        placement={{ x: calculatedX, y: calculatedY } as Coordinates}
                                                    />
                                                );
                                            })}
                                    </div>
                                </CSSTransition>
                            )}
                        <RichText document={props.body} />
                    </BootstrapCard.Body>
                </BootstrapCard>
            </Col>
        </Row>
    );
};

export default SingleAssetActivity;

type IconProps = {
    icon: Icon;
    interactionPermission: boolean;
    isMyIcon: boolean;
    removeMarkupIcon: (icon: Icon) => void;
    onIconHovered: (b: boolean) => void;
    placement: Coordinates;
};

const SingleAssetMarkupIcon: React.FC<IconProps> = (props) => {
    const localized = useLocalizedString();
    return (
        <div
            className="markup-icon-container"
            style={{
                left: `${props.placement.x + 3}px`,
                top: `${props.placement.y - 10}px`,
            }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                props.interactionPermission ? props.removeMarkupIcon(props.icon) : null;
            }}
            onMouseEnter={() => props.onIconHovered(false)}
            onMouseLeave={() => props.onIconHovered(true)}
        >
            <Row>
                <div className="markup-icon">
                    <i className="material-icons marker" style={{ fontSize: "x-large" }}>
                        {props.icon.iconType}
                    </i>
                    {props.interactionPermission && <i className="material-icons delete">close</i>}
                </div>
            </Row>
            <Row>
                {props.isMyIcon && (
                    <div className="me-badge">{localized("vdpActivity_defaultCurrentParticipantName")}</div>
                )}
            </Row>
        </div>
    );
};
