import { colorContrast, mix, shadeColor, tintColor, toRGB, WHITE } from "../client/color-utils";

const BTN_HOVER_BG_SHADE_AMOUNT = 15;
const BTN_HOVER_BG_TINT_AMOUNT = 15;
const BTN_HOVER_BORDER_SHADE_AMOUNT = 20;
const BTN_HOVER_BORDER_TINT_AMOUNT = 10;
const BTN_ACTIVE_BG_SHADE_AMOUNT = 20;
const BTN_ACTIVE_BG_TINT_AMOUNT = 20;
const BTN_ACTIVE_BORDER_SHADE_AMOUNT = 25;
const BTN_ACTIVE_BORDER_TINT_AMOUNT = 10;

export const setButtonProperties = (customColor: string, customPropertyName: string) => {
    const root = document.documentElement;
    const background = customColor;
    const border = customColor;
    const color = colorContrast(customColor);
    const hoverBackground =
        color === WHITE
            ? shadeColor(background, BTN_HOVER_BG_SHADE_AMOUNT)
            : tintColor(background, BTN_HOVER_BG_TINT_AMOUNT);
    const hoverBorder =
        color === WHITE
            ? shadeColor(border, BTN_HOVER_BORDER_SHADE_AMOUNT)
            : tintColor(border, BTN_HOVER_BORDER_TINT_AMOUNT);
    const hoverColor = colorContrast(hoverBackground);
    const focusShadowRgb = toRGB(mix(color, border, 15));
    const activeBackground =
        color === WHITE
            ? shadeColor(background, BTN_ACTIVE_BG_SHADE_AMOUNT)
            : tintColor(background, BTN_ACTIVE_BG_TINT_AMOUNT);
    const activeBorder =
        color === WHITE
            ? shadeColor(border, BTN_ACTIVE_BORDER_SHADE_AMOUNT)
            : tintColor(border, BTN_ACTIVE_BORDER_TINT_AMOUNT);
    const activeColor = colorContrast(activeBackground);
    const disabledBackground = background;
    const disabledBorder = border;
    const disabledColor = colorContrast(disabledBackground);

    root.style.setProperty(`--${customPropertyName}-btn-color`, color);
    root.style.setProperty(`--${customPropertyName}-btn-bg`, background);
    root.style.setProperty(`--${customPropertyName}-btn-border-color`, border);
    root.style.setProperty(`--${customPropertyName}-btn-hover-color`, hoverColor);
    root.style.setProperty(`--${customPropertyName}-btn-hover-bg`, hoverBackground);
    root.style.setProperty(`--${customPropertyName}-btn-hover-border-color`, hoverBorder);
    root.style.setProperty(`--${customPropertyName}-btn-focus-shadow-rgb`, focusShadowRgb);
    root.style.setProperty(`--${customPropertyName}-btn-active-color`, activeColor);
    root.style.setProperty(`--${customPropertyName}-btn-active-bg`, activeBackground);
    root.style.setProperty(`--${customPropertyName}-btn-active-border-color`, activeBorder);
    root.style.setProperty(`--${customPropertyName}-btn-disabled-color`, disabledColor);
    root.style.setProperty(`--${customPropertyName}-btn-disabled-bg`, disabledBackground);
    root.style.setProperty(`--${customPropertyName}-btn-disabled-border-color`, disabledBorder);
};

export const setSidebarProperties = (customColor: string) => {
    const root = document.documentElement;
    const background = customColor;
    const color = colorContrast(customColor);

    root.style.setProperty("--sidebarAndZoomColor", customColor);
    root.style.setProperty("--sidebar-color", color);
    root.style.setProperty("--sidebar-bg", background);
};

export const setMarkupIconProperties = (customColor: string) => {
    const root = document.documentElement;
    const background = customColor;
    const color = colorContrast(customColor);

    root.style.setProperty("--markupIconColor", customColor);
    root.style.setProperty("--markupIcon-color", color);
    root.style.setProperty("--markupIcon-bg", background);
};
