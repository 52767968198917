import { flattenStepStates } from "modules/utils";
import { SessionState, StepState } from "../../types";
import { getStepStates } from "./getStepStates";
import { createSelector } from "reselect";
import { createIntroTimeInPacingSelector } from "../time-tracking/createIntroTimeInPacing";
import { IntroStepStateBundle } from "modules/shared/step-types";
import { getIntroStepStates } from "../intro-sequence/getIntroStepStates";

export function getStepStatesFlattened(): (s: SessionState) => (IntroStepStateBundle | StepState)[] {
    return createSelector(
        [getStepStates, getIntroStepStates, createIntroTimeInPacingSelector()],
        (stepStates, introStepStates, isIntroTimeActive) => {
            const flattenSteps = flattenStepStates(stepStates);
            return isIntroTimeActive ? [...introStepStates, ...flattenSteps] : flattenSteps;
        },
    );
}
