import { useMsal } from "@azure/msal-react";
import { format, formatISO, isBefore, parseISO } from "date-fns";
import { callSecureEndpoint } from "modules/client/auth";
import { useLocalizedString } from "modules/client/localization";
import React, { useMemo, useState } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsInfoCircle } from "react-icons/bs";
import Accordion from "react-bootstrap/Accordion";
import { useForm } from "react-hook-form";
import Experience from "../../database_types/experience";
import {
    ClientSchedulerRoute,
    ConnectionTestRoute,
    GenerateQrCodeRoute,
    SessionRepositoryReportRoute,
} from "../../shared/routes";
import CopyToClipboardButton from "../CopyToClipboard";
import "./ExperienceRow.scss";
import PreviewButton from "./PreviewButton";
import { Link } from "react-router-dom";
import ConfirmationDialogue from "../ConfirmationDialog";
import InfoToggle from "./InfoToggle";
import Form from "react-bootstrap/Form";

type Props = {
    experience: Experience;
    onArchive?: (experienceUuid: string) => void;
    onUpdateDataCollectionDefault?: (experienceUuid: string, dataCollectionDefault: boolean) => void;
};

const ExperienceRow: React.FC<Props> = (props) => {
    const localized = useLocalizedString();
    const { instance: msalInstance } = useMsal();
    const [experience, setExperience] = useState<Experience>(props.experience);
    const [isIconActive, setIsIconActive] = useState(false);
    const [schedulingAvailableThrough, setSchedulingAvailableThrough] = useState<Date>(
        new Date(experience.scheduling_available_through),
    );
    const [confirmArchive, setConfirmArchive] = useState(false);
    const [confirmUpdateDataCollectionDefault, setConfirmUpdateDataCollectionDefault] = useState(false);
    const [dataCollectionDefault, setDataCollectionDefault] = useState<boolean>(
        props.experience.data_collection_default,
    );

    const keyContacts = JSON.parse(props.experience.key_contacts, (_key, value) => (!!value ? value : []));
    const onSubmit = async (data: Partial<Experience>) => {
        const exp = schedulingAvailableThrough;
        await callSecureEndpoint(msalInstance, `/api/v1/experiences/${props.experience.uuid}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                scheduling_available_through: formatISO(exp),
            }),
        });

        setExperience({
            ...experience,
            scheduling_available_through: exp,
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const copyExperienceLink = React.useCallback(
        () => navigator.clipboard.writeText(ClientSchedulerRoute.generate(props.experience.uuid)),
        [props.experience.uuid],
    );

    const copyConnectionTestLink = React.useCallback(
        () => navigator.clipboard.writeText(ConnectionTestRoute.generate(props.experience.uuid)),
        [props.experience.uuid],
    );

    const reportingLink = React.useMemo(
        () => SessionRepositoryReportRoute.generate(props.experience.uuid),
        [props.experience.uuid],
    );

    const GenerateQrCodeLink = React.useMemo(
        () => GenerateQrCodeRoute.generate(props.experience.uuid),
        [props.experience.uuid],
    );

    const emailContact = () => {
        window.location.href = "mailto:" + keyContacts.join(",");
    };

    const experienceLinkExpired = isBefore(new Date(experience.scheduling_available_through), new Date());

    const experienceIsDisabled =
        experienceLinkExpired ||
        experience.status === "expired" ||
        experience.status === "unavailable" ||
        experience.status === "archived";

    const confirmDataCollectionValueText = useMemo(
        () =>
            dataCollectionDefault
                ? localized("confirmDialog_dataCollectionDefault_body_enable")
                : localized("confirmDialog_dataCollectionDefault_body_disable"),
        [dataCollectionDefault, localized],
    );

    return (
        <Accordion defaultActiveKey="0" className="experienceRow">
            <Row className="align-items-center">
                <Col md="2">
                    <Row className="align-items-center">
                        <Col md="10">
                            <PreviewButton experience={props.experience} disabled={experienceIsDisabled} />
                            <i>{props.experience.experience_name}</i>
                        </Col>
                        <Col md="2">
                            <InfoToggle eventKey="1">
                                {props.experience.info && (
                                    <span onClick={() => setIsIconActive(!isIconActive)} style={{ cursor: "pointer" }}>
                                        <BsInfoCircle size={20} color={isIconActive ? "#266ed9" : "black"} />
                                    </span>
                                )}
                            </InfoToggle>
                        </Col>
                    </Row>
                </Col>
                <Col md="3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <InputGroup>
                                <input type="hidden" readOnly value={props.experience.id} {...register("id")} />
                                <FormControl
                                    type="date"
                                    aria-label={format(schedulingAvailableThrough, "yyyy-MM-dd")}
                                    defaultValue={format(schedulingAvailableThrough, "yyyy-MM-dd")}
                                    {...register("scheduling_available_through", {
                                        onChange: (e) => setSchedulingAvailableThrough(parseISO(e.currentTarget.value)),
                                    })}
                                />
                                <Button type="submit" variant="secondary">
                                    Update
                                </Button>
                            </InputGroup>
                        </div>
                    </form>
                </Col>
                <Col md="1">
                    <CopyToClipboardButton
                        variant="primary"
                        onClick={copyExperienceLink}
                        disabled={experienceIsDisabled}
                    >
                        {localized("genUrl_copyLink")}
                    </CopyToClipboardButton>
                </Col>

                <Col md="1">
                    <CopyToClipboardButton
                        variant="secondary"
                        onClick={copyConnectionTestLink}
                        disabled={experienceIsDisabled}
                    >
                        {localized("genUrl_copyLink")}
                    </CopyToClipboardButton>
                </Col>
                <Col md="1">
                    <Link to={reportingLink}>{localized("genUrl_goToSessionRepoReport")}</Link>
                </Col>
                <Col md="1">
                    {keyContacts.length > 0 && (
                        <Button type="submit" onClick={emailContact}>
                            {localized("genUrl_emailContact")}
                        </Button>
                    )}
                </Col>
                {!!props.onArchive && (
                    <Col md="1">
                        <Button type="submit" variant="danger" onClick={() => setConfirmArchive(true)}>
                            {localized("genUrl_archiveButton")}
                        </Button>
                    </Col>
                )}
                {!!props.onUpdateDataCollectionDefault && (
                    <Col md="1">
                        <Form.Group controlId="dataCollectionDeafultGroup">
                            <Form.Label>{localized("genUrl_dataCollectionDefaultLabel")}</Form.Label>
                            <Form.Switch
                                checked={dataCollectionDefault}
                                onChange={() => {
                                    setDataCollectionDefault(!dataCollectionDefault);
                                    setConfirmUpdateDataCollectionDefault(true);
                                }}
                            />
                        </Form.Group>
                    </Col>
                )}
                {experience.inPerson && (
                    <Col md="1">
                        <Link to={GenerateQrCodeLink}>Generate QR Code</Link>
                    </Col>
                )}
                <Row>
                    <Accordion.Collapse eventKey="1">
                        <p style={{ width: "60%", margin: "10px 0" }}>{props.experience.info}</p>
                    </Accordion.Collapse>
                </Row>
            </Row>
            {!!props.onArchive && confirmArchive && (
                <ConfirmationDialogue
                    title={localized("confirmDialog_scheduler_title")}
                    body={localized("confirmDialog_scheduler_body", experience.experience_name)}
                    action={() => props.onArchive!(experience.uuid)}
                    onCloseCb={() => setConfirmArchive(false)}
                    confirmValue={experience.experience_name}
                />
            )}
            {!!props.onUpdateDataCollectionDefault && confirmUpdateDataCollectionDefault && (
                <ConfirmationDialogue
                    title={localized("confirmDialog_dataCollectionDefault_title")}
                    body={localized(
                        "confirmDialog_dataCollectionDefault_body",
                        confirmDataCollectionValueText,
                        experience.experience_name,
                    )}
                    action={() => props.onUpdateDataCollectionDefault!(experience.uuid, dataCollectionDefault)}
                    onCloseCb={() => {
                        setConfirmUpdateDataCollectionDefault(false);
                        setDataCollectionDefault(!dataCollectionDefault);
                    }}
                />
            )}
        </Accordion>
    );
};

export default ExperienceRow;
