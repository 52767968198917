/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

// require("./bootstrap");

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require("regenerator-runtime/runtime");

// Polyfills:
require("core-js/features/object/assign");
require("core-js/features/object/entries");
require("core-js/features/array/from");
require("core-js/features/array/find");
require("core-js/features/url");
require("core-js/features/promise");
require("core-js/features/map");
require("core-js/features/set");
require("whatwg-fetch");

// And load the dang app:
require("./modules/components/App");

// @todo: this may be better places elsewhere
// @todo: do we need to wrap this and only show locally?
/** Add JSON.stringify so Redux Devtools can parse state */
Map.prototype.toJSON = function () {
    return JSON.parse(JSON.stringify([...this]));
};
/** Make IE11 support parentEl.children */
//make sure we have Node.children and Element.children available
(function (constructor) {
    if (constructor && constructor.prototype && constructor.prototype.children == null) {
        Object.defineProperty(constructor.prototype, "children", {
            get: function () {
                var i = 0,
                    node,
                    nodes = this.childNodes,
                    children = [];
                //iterate all childNodes
                while ((node = nodes[i++])) {
                    //remenber those, that are Node.ELEMENT_NODE (1)
                    if (node.nodeType === 1) {
                        children.push(node);
                    }
                }
                return children;
            },
        });
    }
    //apply the fix to all HTMLElements (window.Element) and to SVG/XML (window.Node)
})(window.Node || window.Element);
