import { FetchState } from "modules/hooks";
import {
    ContentfulManagementBodyTypes,
    ContentfulManagementFetchTypes,
    contentfulManagementOptValues,
} from "modules/shared/contentful-mgmt-types";
import { useEffect, useState } from "react";

export function contentfulManagementRequest<K extends keyof ContentfulManagementBodyTypes>(
    key: K,
    body: ContentfulManagementFetchTypes[K]["body"],
): Promise<Response> {
    const optValues = contentfulManagementOptValues[key];
    const url = `${CLIENT_WEBSOCKET_URL}/api/contentful${optValues.path}`;
    const fetchOptions: RequestInit = {
        headers: {
            "content-type": "application/json",
        },
        method: optValues.method,
        body: JSON.stringify(body),
    };
    return fetch(url, fetchOptions);
}

/** NOTE: when using this hook you must memoize body and cb to avoid an infinite loop */
export function useContentfulManagementFetch<K extends keyof ContentfulManagementBodyTypes>(
    key: K,
    body: ContentfulManagementFetchTypes[K]["body"],
    cb?: (resp: ContentfulManagementFetchTypes[K]["resp"]) => void,
): FetchState<ContentfulManagementFetchTypes[K]["resp"]> {
    const [result, setResult] = useState<FetchState<ContentfulManagementFetchTypes[K]["resp"]>>({ state: "LOADING" });

    useEffect(() => {
        const fetcher = async () => {
            try {
                const response = await contentfulManagementRequest(key, body);
                if (response.ok) {
                    const data: ContentfulManagementFetchTypes[K]["resp"] = await response.json();
                    if (cb) cb(data);
                    setResult({
                        state: "DONE",
                        data,
                    });
                } else {
                    setResult({
                        state: "ERROR",
                        status: response.status,
                        statusText: response.statusText,
                    });
                }
            } catch (error: any) {
                setResult({
                    state: "ERROR",
                    error,
                });
            }
        };

        void fetcher();
    }, [body, key, cb]);

    return result;
}
