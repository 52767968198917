import { createSelector } from "@reduxjs/toolkit";
import { MapState, SessionState } from "../../types";
import { getMapState } from "../../utils";
import { createCurrentStepOptionsSelector } from "../step-definition/generic/createCurrentStepOptionsSelector";

export function createMapFocusSelector(): (s: SessionState) => MapState | null {
    return createSelector([createCurrentStepOptionsSelector()], (options) => {
        return getMapState(options?.focusMapOn);
    });
}
