import { END_OF_CARD_STACK } from "modules/shared/constants";
import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";
import { MapState } from "../../shared/types";

type Args = {
    cardId: string | typeof END_OF_CARD_STACK;
    focusArea: MapState | null;
};

const createCardStackWithVisualGoToCard = (args: Args): SessionEvent => {
    return {
        type: "CARD_STACK_WITH_VISUAL__GO_TO_CARD",
        cardId: args.cardId,
        focusArea: args.focusArea,
        uuid: uuidv4(),
    };
};
export default createCardStackWithVisualGoToCard;
