import { SessionEvent } from "modules/shared/session-events";
import { v4 } from "uuid";

type Args = {
    iconId: string;
    placerId: string;
    clickerId: string;
};

const createRemoveMarkupIcon = (args: Args): SessionEvent => {
    return {
        type: "REMOVE_MARKUP_ICON",
        uuid: v4(),
        iconId: args.iconId,
        placerId: args.placerId,
        clickerId: args.clickerId,
    };
};

export default createRemoveMarkupIcon;
