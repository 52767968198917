import { format } from "date-fns";
import { Action } from "modules/client/actions-types";
import { useLocalizedString } from "modules/client/localization";
import React, { Dispatch, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSession } from "./context";
import "./inactivity-prompt.scss";

const InactivityPrompt: React.FC = () => {
    const [timeRemaining, setTimeRemaining] = useState<number>(300);
    const { participantId } = useSession();
    const dispatch = useDispatch<Dispatch<Action>>();

    useEffect(() => {
        let timeLeft = timeRemaining;
        const timer = window.setInterval(() => {
            if (timeLeft > 0) {
                timeLeft = timeLeft - 1;
                setTimeRemaining(timeLeft);
            } else {
                if (!participantId) {
                    throw new Error("Error getting participantId of current participant.");
                }
                dispatch({ type: "DISCONNECT_FROM_SESSION" });
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onButtonClick = () => {
        dispatch({ type: "UPDATE_LAST_ACTIVE_TIME" });
        dispatch({ type: "SET_PARTICIPANT_IDLE", isIdle: false });
    };

    const localized = useLocalizedString();
    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title> {localized("vdpRenderer_inactiveHeader")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="inactive-modal-body">
                    <Col>{localized("vdpRenderer_inactiveBody")}</Col>
                </Row>
                <Row className="inactive-modal-footer">
                    <Col md="auto">
                        <Button
                            onClick={onButtonClick}
                            aria-description={localized("vdpRenderer_setSelfActiveButtonDescription")}
                        >
                            {localized("vdpRenderer_setSelfActiveButton")}
                        </Button>
                    </Col>
                    <Col md="auto" className="spinner-container">
                        <Spinner size="sm" animation="border" className="spinner"></Spinner>
                    </Col>
                    <Col md="auto" className="time-remaining-container">
                        <p className="time-remaining">({format(timeRemaining * 1000, "m:ss")})</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default InactivityPrompt;
