import React from "react";
import Experience from "../../database_types/experience";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExperienceRow from "./ExperienceRow";

type Props = {
    experiences: Experience[];
    onArchive?: (experienceUuid: string) => void;
    onUpdateDataCollectionDefault?: (experienceUuid: string, dataCollectionDefault: boolean) => void;
};

const ExperienceRows: React.FC<Props> = ({ experiences, onArchive, onUpdateDataCollectionDefault }) => {
    return (
        <>
            <Row className="experienceRowHeader align-items-end">
                <Col md="2">Company & Experience</Col>
                <Col md="3">Link Expiration</Col>
                <Col md="1">Link for scheduling sessions</Col>
                <Col md="1">Link for testing connections</Col>
                <Col md="1">Link for reporting</Col>
                <Col md="1">Contact</Col>
                <Col md="1">Archive</Col>
                <Col md="1">Data Collection</Col>
                <Col md="1">QR Code For In-Person Session</Col>
            </Row>

            {experiences.map((experience) => (
                <ExperienceRow
                    key={experience.id}
                    experience={experience}
                    onArchive={onArchive}
                    onUpdateDataCollectionDefault={onUpdateDataCollectionDefault}
                />
            ))}
        </>
    );
};
export default ExperienceRows;
