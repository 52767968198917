import { useLocalizedString } from "modules/client/localization";
import BadgeWithIcon from "modules/components/BadgeWithIcon";
import ProgressBadge from "modules/components/ProgressBadge";
import { useSession, useSessionSelector } from "modules/components/SessionRenderer/context";
import { createSectionDefinitionForCurrentSectionSelector } from "modules/shared/selectors/step-definition/generic/createSectionDefinitionForCurrentSectionSelector";
import { createCurrentSectionTitleSelector } from "modules/shared/selectors/step-definition/generic/createCurrentSectionTitleSelector";
import { createTimeRecommendedForPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeRecommendedForPreviousSectionsSelector";
import { createTimeDevotedToPreviousSectionsSelector } from "modules/shared/selectors/time-tracking/createTimeDevotedToPreviousSectionsSelector";
import { currentSectionAddress } from "modules/shared/selectors/step-address/currentSectionAddress";
import { currentNavigatorSelector } from "modules/shared/selectors/participants/currentNavigatorSelector";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { scriptSections } from "modules/shared/selectors/step-definition/generic/scriptSections";
import { PaceCheck } from "modules/shared/types";
import { evaluatePace, isIntroStepAddress } from "modules/shared/utils";
import { firstStepOfSectionReassignsNavigator } from "modules/utils";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BootstrapCard from "react-bootstrap/Card";
import { MdAssistantDirection } from "react-icons/md";
import "./progress-check.scss";
import { createNavigatorAssignedAutomatically } from "modules/shared/selectors/step-definition/generic/createNavigatorAssignedAutomatically";

const ProgressCheck: React.FC = () => {
    const localized = useRef(useLocalizedString());
    const { participantId } = useSession();
    const timeDevoted = useSessionSelector(createTimeDevotedToPreviousSectionsSelector());
    const timeRecommended = useSessionSelector(createTimeRecommendedForPreviousSectionsSelector());
    const sectionAddress = useSessionSelector(currentSectionAddress);
    const sectionDef = useSessionSelector(createSectionDefinitionForCurrentSectionSelector());
    const sections = useSessionSelector(scriptSections);
    const sectionTitle = useSessionSelector(createCurrentSectionTitleSelector());
    const currentNavigatorInfo = useSessionSelector(currentNavigatorSelector);
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const navigatorAssignedAutomatically = useSessionSelector(createNavigatorAssignedAutomatically());

    const [paceMessage, setPaceMessage] = useState("");
    const [paceClass, setPaceClass] = useState("");

    const sectionNumber = sectionAddress !== "exit" && sectionAddress !== "intro" ? sectionAddress + 1 : "exit";
    const numSections = sections.length;
    const navigatorReassigned =
        sectionDef && sectionAddress !== "exit" && sectionAddress !== "intro"
            ? firstStepOfSectionReassignsNavigator(sectionAddress, sectionDef) || navigatorAssignedAutomatically
            : false;

    useEffect(() => {
        const pace = evaluatePace(timeRecommended, timeDevoted);
        switch (pace) {
            case PaceCheck.AheadOfPace:
                setPaceMessage(localized.current("progressCheck_paceMessageAheadOfPace"));
                setPaceClass("pace-danger");
                break;
            case PaceCheck.BehindPace:
                setPaceMessage(localized.current("progressCheck_paceMessageBehindPace"));
                setPaceClass("pace-danger");
                break;
            case PaceCheck.OnPace:
            default:
                setPaceMessage(localized.current("progressCheck_paceMessageOnPace"));
                setPaceClass("pace-okay");
                break;
        }
    }, [localized, timeRecommended, timeDevoted, setPaceMessage]);

    return (
        <Row className="justify-content-center">
            <Col md={6}>
                <BootstrapCard className="progress-check-card">
                    <BootstrapCard.Body>
                        <Row className="section-intro">
                            <Col className="d-flex align-items-center flex-column">
                                <Row>
                                    <h3>{sectionTitle}</h3>
                                </Row>
                                <Row>
                                    <p className="section-progress">
                                        {sectionNumber !== "exit" &&
                                            localized.current("progressCheck_sectionTitle", sectionNumber, numSections)}
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <Row data-testid="pace-info" className={`info-with-badge ${paceClass}`}>
                            <Col className="d-flex align-items-center flex-column">
                                <Row>
                                    <h6>{localized.current("progressCheck_paceCheckTitle")}</h6>
                                </Row>
                                <Row>
                                    <ProgressBadge />
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <p className="message">{paceMessage}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {navigatorReassigned && currentNavigatorInfo && (
                            <Row className="info-with-badge navigator-info">
                                <Col className="d-flex align-items-center flex-column">
                                    <Row>
                                        <h6>{localized.current("progressCheck_navigatorChangeTitle")}</h6>
                                    </Row>
                                    {participantInfo && participantInfo.id === currentNavigatorInfo.id && (
                                        <Row>
                                            <BadgeWithIcon
                                                pill
                                                variant="warning-200"
                                                icon={MdAssistantDirection}
                                                roleLabel={localized.current("sidebar_navigatorBadge")}
                                            />
                                        </Row>
                                    )}
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <p className="message">
                                                {localized.current(
                                                    "progressCheck_navigatorChangeMessage",
                                                    currentNavigatorInfo.name ?? "",
                                                )}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </BootstrapCard.Body>
                </BootstrapCard>
            </Col>
        </Row>
    );
};

export default ProgressCheck;
