import createCardStackWithVisualGoToCard from "modules/client/event-factories/createCardStackWithVisualGoToCard";
import { CardStackWithVisualStep } from "modules/shared/content-types";
import React from "react";
import classNames from "classnames";
import { createNavigationDisabledSelector } from "modules/shared/selectors/navigation-state/createNavigationDisabledSelector";
import { createCurrentStepStateSelector } from "modules/shared/selectors/step-state/createCurrentStepStateSelector";
import { useSessionDispatch, useSessionParticipantId, useSessionSelector } from "../../SessionRenderer/context";
import CardStackWithVisual from "./CardStackWithVisual";
import "./CardStackWithVisual.scss";
import { Form } from "react-bootstrap";
import { MapState } from "../../../shared/types";
import { getMapState } from "../../../shared/utils";
import CardStackWithVisualStepState from "../../../shared/activity/CardStackWithVisual/types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { END_OF_CARD_STACK } from "modules/shared/constants";

const CardStackWithVisualActivity: React.FC<CardStackWithVisualStep> = (props) => {
    const cards = props.cards;
    const dispatch = useSessionDispatch();
    const stepState: CardStackWithVisualStepState | null = useSessionSelector(
        createCurrentStepStateSelector("CARD_STACK_WITH_VISUAL"),
    );

    const isNavigationDisabled = useSessionSelector(createNavigationDisabledSelector(useSessionParticipantId()));

    const horizontalAlignment: "center" | "right" | "left" = props.stackPlacement ? props.stackPlacement : "center";

    const buttonsClassNames = classNames({
        "non-interactive": isNavigationDisabled,
    });

    const formClassNames = classNames("card-buttons", {
        "non-interactive": isNavigationDisabled,
    });

    const selectedCardId = stepState?.persistent.selectedCardId ?? cards[0].id;
    const selectedCardIndex =
        selectedCardId === END_OF_CARD_STACK
            ? -1
            : cards.indexOf(
                  cards.find((card) => {
                      return selectedCardId === card.id;
                  }) ?? cards[0],
              );

    const handleGoToCard = (cardId: string, focusArea: MapState | null) => {
        if (isNavigationDisabled) {
            return;
        }
        dispatch(
            createCardStackWithVisualGoToCard({
                cardId: cardId,
                focusArea: focusArea,
            }),
        );
    };

    const handleNextCard = () => {
        if (isNavigationDisabled) {
            return;
        }
        const nextCardIndex = selectedCardIndex + 1;
        const nextCard = nextCardIndex !== cards.length ? cards[nextCardIndex] : null;

        dispatch(
            createCardStackWithVisualGoToCard({
                cardId: !!nextCard ? nextCard.id : END_OF_CARD_STACK,
                focusArea: !!nextCard ? getMapState(nextCard.focusMapOn) : null,
            }),
        );
    };

    const buttons = cards.map((card) => {
        return (
            <Form.Check inline key={card.id}>
                <Form.Check.Input
                    checked={selectedCardId === card.id}
                    name="cards"
                    type={"radio"}
                    onChange={() => handleGoToCard(card.id, getMapState(card.focusMapOn))}
                    className={buttonsClassNames}
                    aria-label={card.title}
                    tabIndex={!isNavigationDisabled && selectedCardId === card.id ? 0 : -1}
                />
            </Form.Check>
        );
    });

    let cardStack: JSX.Element[] = [];

    if (selectedCardIndex !== -1) {
        cardStack = cards
            .slice(selectedCardIndex)
            .reverse()
            .map((card, index) => {
                return (
                    <CSSTransition key={card.id} timeout={2000} classNames={"card-transition"}>
                        <CardStackWithVisual key={card.id} card={card} position={{ x: index * -10, y: index * -5 }} />
                    </CSSTransition>
                );
            });
    }

    const keyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Enter" || event.code === "Space") {
            handleNextCard();
        }
    };

    return (
        <div
            className={`card-stack-with-visual-activity--${horizontalAlignment}`}
            tabIndex={isNavigationDisabled ? -1 : 0}
            onClick={handleNextCard}
            onKeyDown={keyDown}
        >
            <div
                className="card-stack"
                style={{
                    marginTop: `${((cards.length - 1) * 10) / 2}px`,
                    marginLeft: `${(cards.length - 1) * 5}px`,
                }}
            >
                <TransitionGroup>{cardStack}</TransitionGroup>
            </div>
            <Form className={formClassNames}>{buttons}</Form>
        </div>
    );
};

export default CardStackWithVisualActivity;
