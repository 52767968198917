import { ApplicationState } from "../application-state";
import { ClientLanguageChanged } from "../actions-types";

export default (state: ApplicationState, payload: ClientLanguageChanged): ApplicationState => {
    {
        return {
            ...state,
            languageCode: payload.languageCode,
        };
    }
};
