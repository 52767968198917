import React, { useEffect, useState } from "react";
import L, { LayerGroup } from "leaflet";
import { Hotspot } from "modules/shared/types";
import classNames from "classnames";
import checkBadge from "modules/client/assets/checkBadge.svg";
import { calculateCoordinatesFor } from "./utils";
import { useLocalizedString } from "modules/client/localization";
import { useMapContext } from "./MapContext";
import { FaPlus } from "react-icons/fa";
import ReactDOMServer from "react-dom/server";

type Props = {
    mapHotspots: Hotspot[] | null;
    selectedHotspotIdx: number | null;
    viewedHotspotIdxs: number[];
    showHotspotModal: (idx: number) => void;
    pixelSize: [number, number];
    isNavigationDisabled: boolean;
};

const HotspotLayer: React.FC<Props> = ({
    mapHotspots,
    selectedHotspotIdx,
    viewedHotspotIdxs,
    showHotspotModal,
    pixelSize,
    isNavigationDisabled,
}) => {
    const { map } = useMapContext();
    const [initialTooltipActioned, setInitialTooltipActioned] = useState<boolean>(false);
    const [hotspotGroup] = useState<LayerGroup>(L.layerGroup);
    const localized = useLocalizedString();

    useEffect(() => {
        map.addLayer(hotspotGroup);
        hotspotGroup.clearLayers();

        mapHotspots?.map((h, idx) => {
            const wasViewed = viewedHotspotIdxs.includes(idx);
            const hotspotClassnames = classNames("hotspot", {
                "non-interactive": isNavigationDisabled,
            });
            const buttonClassnames = classNames("btn", "btn-hotspotIconColor", {
                active: idx === selectedHotspotIdx,
                "non-interactive": isNavigationDisabled,
            });
            // Get icon data, construct SVG and marker HTML
            // we cannot use React since we're injecting elements into the map and the map does not do JSX
            const viewedIcon = wasViewed ? `<img src="${checkBadge}" class="icon" data-testid="check-badge"/>` : "";
            const svgEl = <FaPlus className="svg-inline--fa fa-plus fa-w-14" />;
            const svgHtml = ReactDOMServer.renderToStaticMarkup(svgEl);
            const button = `
                ${viewedIcon}
                <button class="${buttonClassnames}" data-testid="hotspot">${svgHtml}</button>`;

            const hotspotIcon = L.divIcon({
                html: button,
                iconAnchor: [20, 20],
                className: hotspotClassnames,
            });

            const marker = L.marker(calculateCoordinatesFor(pixelSize, h), {
                icon: hotspotIcon,
            }).addTo(hotspotGroup);

            if (!isNavigationDisabled) {
                marker.on("click", () => showHotspotModal(idx));
            }

            // Show one tooltip by default to navigator
            if (!isNavigationDisabled && !initialTooltipActioned && idx === 0) {
                marker
                    .bindTooltip(localized("vdpActivity_hotspotTooltip"), {
                        direction: "top",
                        permanent: true,
                    })
                    .on("click", () => setInitialTooltipActioned(true));

                marker.openTooltip();
            }
        });
        return () => {
            hotspotGroup.clearLayers();
        };
    }, [
        map,
        pixelSize,
        mapHotspots,
        initialTooltipActioned,
        selectedHotspotIdx,
        hotspotGroup,
        isNavigationDisabled,
        viewedHotspotIdxs,
        showHotspotModal,
        localized,
    ]);

    return <></>;
};
export default HotspotLayer;
