import React, { useEffect, useMemo, useRef } from "react";
import L from "leaflet";
import { MapState } from "modules/shared/types";
import { createOverlayWithHighlights, getHighlightAreasFromMapState } from "./utils";
import { useMapContext } from "./MapContext";

type Props = {
    pixelSize: [number, number];
    mapHighlightAreas: MapState[] | null;
};

const FocusAreaLayer: React.FC<Props> = ({ pixelSize, mapHighlightAreas }) => {
    const { map } = useMapContext();
    const highlightAreas = useMemo(() => {
        if (mapHighlightAreas) {
            return getHighlightAreasFromMapState(mapHighlightAreas, pixelSize);
        }
        return null;
    }, [mapHighlightAreas, pixelSize]);
    const highlightPolygonRef = useRef<L.Polygon>();

    useEffect(() => {
        let addedPolygon: L.Polygon;
        if (highlightAreas && highlightAreas.length > 0) {
            const { x, y } = map.getSize();

            const overlay = createOverlayWithHighlights(highlightAreas, map.getCenter(), { x, y });

            if (!!overlay) {
                addedPolygon = L.polygon(overlay, {
                    className: "overlay",
                    color: "black",
                    fillOpacity: 0.8,
                    stroke: false,
                    interactive: false,
                }).addTo(map);
                highlightPolygonRef.current = addedPolygon;
            }
        }

        return () => {
            if (addedPolygon) {
                addedPolygon.removeFrom(map);
            }
        };
    }, [map, highlightAreas]);

    return <></>;
};
export default FocusAreaLayer;
