import { SessionEvent } from "modules/shared/session-events";
import { v4 as uuidv4 } from "uuid";
import { PositionInElementHierarchy, PositionInMap } from "../../components/MouseRenderer/mouse-movement-tracking";

type Args = {
    participantId: string;
    position: PositionInElementHierarchy | PositionInMap;
};
const createMouseMove = (args: Args): SessionEvent => {
    return {
        type: "mouseMove",
        uuid: uuidv4(),
        participantId: args.participantId,
        position: args.position,
    };
};

export default createMouseMove;
