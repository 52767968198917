import { ReceiveSessionReportSuccess } from "modules/client/actions-types/sessionReportActionTypes";
import { ApplicationState } from "modules/client/application-state";
import { ReportStatus } from "modules/client/session-report-state";

export default function receiveSessionReportSuccess(
    state: ApplicationState,
    payload: ReceiveSessionReportSuccess,
): ApplicationState {
    return {
        ...state,
        sessionReportState: {
            filters: payload.filters,
            status: ReportStatus.Complete,
            result: payload.reportData,
        },
    };
}
