import { Document } from "@contentful/rich-text-types";
import { noop } from "lodash-es";
import { useLocalizedString } from "modules/client/localization";
import ActivityModal from "modules/components/ActivityModal";
import RichText from "modules/components/RichText";
import { useSessionSelector } from "modules/components/SessionRenderer/context";
import { Hotspot } from "modules/shared/content-types";
import { createActivityModalSelector } from "modules/shared/selectors/step-definition/generic/createActivityModalSelector";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import CardModal from "../../CardModal/CardModal";
import "./Hotspot.scss";

type Props = {
    hotspot: Pick<Hotspot, "title" | "content" | "card" | "openedCardAlignment">;
    isOpen: boolean;
    onHide: () => void;
    isNavigationDisabled: boolean;
    showRotateNavigator: () => void;
    hideBackground?: boolean;
    isCardFlipped: boolean;
    flipCardToBack: () => void;
    flipCardToFront: () => void;
};

export const HotspotModal: React.FC<Props> = (props) => {
    const { hotspot, hideBackground } = props;
    const localized = useLocalizedString();

    const activityModalOptions = useSessionSelector(createActivityModalSelector());

    const contentClassName = hideBackground ? "modal-backdrop-no-background" : "";
    const card = hotspot.card;
    const cardBackgroundHidden = !!hideBackground;

    if (!card) {
        return (
            <>
                <ActivityModal
                    centered
                    show={props.isOpen}
                    size="lg"
                    onHide={!props.isNavigationDisabled ? props.onHide : noop}
                    contentClassName={contentClassName}
                    {...activityModalOptions}
                >
                    {!hideBackground && (
                        <Modal.Header>
                            <Modal.Title>{hotspot.title}</Modal.Title>
                        </Modal.Header>
                    )}
                    <Modal.Body>{hotspot.content && <RichText document={hotspot.content} />}</Modal.Body>
                    <Modal.Footer className="justify-content-start hotspot-modal-footer">
                        {!props.isNavigationDisabled && (
                            <Button variant="primary" onClick={props.onHide}>
                                {localized("vdpActivity_closeModal")}
                            </Button>
                        )}
                    </Modal.Footer>
                </ActivityModal>
            </>
        );
    } else {
        return (
            <CardModal
                card={card}
                isModalVisible={props.isOpen}
                isNavigationDisabled={props.isNavigationDisabled}
                horizontalAlignment={props.hotspot.openedCardAlignment ?? "center"}
                handleCloseModal={!props.isNavigationDisabled ? props.onHide : noop}
                isCardFlipped={props.isCardFlipped}
                handleFlipCardToBack={props.flipCardToBack}
                handleFlipCardToFront={props.flipCardToFront}
                showRotateNavigator={props.showRotateNavigator}
                backgroundHidden={cardBackgroundHidden}
            />
        );
    }
};
