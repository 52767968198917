import { CARD_STACK_WITH_VISUAL__GO_TO_CARD } from "../activity/CardStackWithVisual/reducer";
import { CHANGE_NAME } from "../activity/ChangeName/reducer";
import {
    DRAG_AND_DROP__CARD_DROPPED,
    DRAG_AND_DROP__CARD_PICKED_UP,
    DRAG_AND_DROP__REVEAL_CORRECTNESS,
} from "../activity/DragAndDrop/reducer";
import {
    ADD_MARKUP_ICON,
    REMOVE_MARKUP_ICON,
    DRAGGING_MARKUP_ICON,
    PLACED_MARKUP_ICON,
    ADD_ASSET_MARKUP_ICON,
    REMOVE_ASSET_MARKUP_ICON,
} from "../activity/Markup/reducer";
import { MULTIPLE_CHOICE__ANSWER_CHANGED, MULTIPLE_CHOICE__SUBMITTED } from "../activity/MultipleChoice/reducer";
import {
    REVEAL_CARD__FLIP_TO_BACK,
    REVEAL_CARD__FLIP_TO_FRONT,
    REVEAL_CARD__HIDE_DETAILS,
    REVEAL_CARD__SHOW_DETAILS,
} from "../activity/RevealCard/reducer";
import {
    CLICKABLE_AREA__HIDE_DETAILS,
    CLICKABLE_AREA__SHOW_DETAILS,
    HOTSPOT__FLIP_CARD_TO_BACK,
    HOTSPOT__FLIP_CARD_TO_FRONT,
    HOTSPOT__HIDE_DETAILS,
    HOTSPOT__SHOW_DETAILS,
} from "../activity/RevealHotspot/reducer";
import { SURVEY_TAKEN } from "../activity/SurveyTaken";
import { TEXT_ENTRY__ANSWER_CHANGED, TEXT_ENTRY__SUBMITTED } from "../activity/TextEntry/reducers";
import {
    EDITABLE_CARD__ANSWER_CHANGED,
    EDITABLE_CARD__SUBMITTED,
    EDITABLE_CARD__SHOW_WARNING,
} from "../activity/EditableCard/reducer";
import { participantReady } from "../activity/WelcomeSequence/reducer";
import { SessionState } from "../types";
import ASSIGN_FACILITATOR from "./assign-facilitator";
import participantAdded from "./participant-added";
import PARTICIPANT_LOCALE_CHANGED from "./participant-locale-changed";
import participantRemoved from "./participant-removed";
import reincarnateParticipant from "./reincarnate-participant";
import startExperience from "./start-experience";
import goToStep from "./go-to-step";
import navigateIceBreaker from "./navigate-ice-breaker";
import mouseMove from "./mouse-move";
import ROTATE_NAVIGATOR from "./rotate-navigator";
import ASSIGN_NAVIGATOR from "./assign-navigator";
import REMOVE_FACILITATOR from "./remove-facilitator";
import RELOAD_SCRIPT from "./reload-script";
import TEST_MESSAGE from "./test-message";
import { EventReducerDefinition } from "./types";
import {
    MATCHING__CARD_PICKED_UP,
    MATCHING__CARD_DROPPED,
    MATCHING__REVEAL_CORRECTNESS,
} from "../activity/Matching/reducer";

export const updateSequenceNumber = (state: SessionState, sequenceNumber: string): SessionState => {
    return {
        ...state,
        lastSequenceNumber: sequenceNumber,
    };
};

export const EventReducerLookup: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: EventReducerDefinition<any, any>;
} = {
    participantAdded,
    participantReady,
    participantRemoved,
    reincarnateParticipant,
    startExperience,
    mouseMove,
    goToStep,
    navigateIceBreaker,
    ROTATE_NAVIGATOR,
    ASSIGN_NAVIGATOR,
    ASSIGN_FACILITATOR,
    REMOVE_FACILITATOR,
    CHANGE_NAME,
    REVEAL_CARD__SHOW_DETAILS,
    REVEAL_CARD__HIDE_DETAILS,
    REVEAL_CARD__FLIP_TO_BACK,
    REVEAL_CARD__FLIP_TO_FRONT,
    CARD_STACK_WITH_VISUAL__GO_TO_CARD,
    HOTSPOT__SHOW_DETAILS,
    HOTSPOT__HIDE_DETAILS,
    HOTSPOT__FLIP_CARD_TO_BACK,
    HOTSPOT__FLIP_CARD_TO_FRONT,
    CLICKABLE_AREA__SHOW_DETAILS,
    CLICKABLE_AREA__HIDE_DETAILS,
    DRAG_AND_DROP__CARD_PICKED_UP,
    DRAG_AND_DROP__CARD_DROPPED,
    DRAG_AND_DROP__REVEAL_CORRECTNESS,
    MATCHING__CARD_PICKED_UP,
    MATCHING__CARD_DROPPED,
    MATCHING__REVEAL_CORRECTNESS,
    MULTIPLE_CHOICE__ANSWER_CHANGED,
    MULTIPLE_CHOICE__SUBMITTED,
    ADD_MARKUP_ICON,
    REMOVE_MARKUP_ICON,
    DRAGGING_MARKUP_ICON,
    PLACED_MARKUP_ICON,
    ADD_ASSET_MARKUP_ICON,
    REMOVE_ASSET_MARKUP_ICON,
    RELOAD_SCRIPT,
    SURVEY_TAKEN,
    TEST_MESSAGE,
    TEXT_ENTRY__ANSWER_CHANGED,
    TEXT_ENTRY__SUBMITTED,
    PARTICIPANT_LOCALE_CHANGED,
    EDITABLE_CARD__ANSWER_CHANGED,
    EDITABLE_CARD__SUBMITTED,
    EDITABLE_CARD__SHOW_WARNING,
};
