import { getUnixTime } from "date-fns";
import { keyBy, uniq, values } from "lodash-es";
import {
    getPoolOfEligibleParticipantsForNavigatorRole,
    incrementStepAddress,
    isActiveParticipant,
    updateParticipantHistory,
} from "modules/utils";
import { v4 } from "uuid";
import goToStep from "./go-to-step";
import { Role } from "../content-types";
import { currentFacilitatorSelector } from "../selectors/participants/currentFacilitatorSelector";
import { GoToStep, ParticipantRemovedEvent, ParticipantRemovedResolutions } from "../session-events";
import { SessionState } from "../types";
import { EventReducerDefinition } from "./types";

const participantRemoved: EventReducerDefinition<ParticipantRemovedEvent, ParticipantRemovedResolutions | null> = {
    predictable: false,
    checkIfApplicable: (state, payload, resolution) => {
        const participantToRemoveExists = isActiveParticipant(state, { participantId: payload.id });
        return (
            participantToRemoveExists &&
            (!resolution ||
                resolution.rolesAssigned.length === 0 ||
                resolution.rolesAssigned.some((r) => isActiveParticipant(state, r)))
        );
    },
    reducer: (
        state: SessionState,
        payload: ParticipantRemovedEvent,
        resolution: ParticipantRemovedResolutions | null,
    ) => {
        const participants = keyBy(state.participants, (p) => p.id);
        const participantCursors = { ...state.participantCursors };

        // Step 1: Remove the participant
        if (resolution && resolution.rolesAssigned.length > 0) {
            const activeParticipantAssignedRoles = resolution.rolesAssigned.filter((r) =>
                isActiveParticipant(state, r),
            );

            activeParticipantAssignedRoles.forEach((r) => {
                const chosenParticipant = participants[r.participantId];
                if (!chosenParticipant) {
                    // Invalid resolution, drop any changes, return the original state
                    return state;
                }

                participants[chosenParticipant.id] = {
                    ...chosenParticipant,
                    assignedRoles: uniq([...chosenParticipant.assignedRoles, r.roleId]),
                };
            });
        }

        delete participants[payload.id];
        delete participantCursors[payload.id];
        // mark step when they left in participantHistory
        const participantsHistory = updateParticipantHistory(state.participantsHistory, payload.id, (p) => ({
            ...p,
            stepWhenLeft: state.currentStep,
        }));
        // if last participant left and session reached exit; mark session as ended
        let sessionEndedAt = state.sessionEndedAt;
        let currentStepAddress = state.currentStep;

        if (state.sessionReachedExit && values(participants).length === 0 && !sessionEndedAt) {
            sessionEndedAt = resolution?.timeOfRemoval || null;
            currentStepAddress = "exit";
        }

        const pool = getPoolOfEligibleParticipantsForNavigatorRole(
            state.participants,
            state.poolOfEligibleParticipantsForNavigatorRole,
            payload.id,
        );

        const iceBreakerPool = [...state.iceBreakerNavigatorPool];

        const currentStep = state.currentStep;

        let currentIntroStep = state.currentIntroStep;

        if (
            state.isIntroStep &&
            state.currentIntroStep.introStepKey === "iceBreaker" &&
            state.currentIntroStep.iceBreakerNavPoolIndex !== "intro" &&
            state.currentIntroStep.iceBreakerNavPoolIndex !== "complete"
        ) {
            const participantIceBreakerIndex = state.iceBreakerNavigatorPool.findIndex((p) => p === payload.id);
            iceBreakerPool.splice(participantIceBreakerIndex, 1);
            if (iceBreakerPool.length === 0) {
                currentIntroStep = {
                    introStepKey: "iceBreaker",
                    iceBreakerNavPoolIndex: "complete",
                };
                const currentFacilitator = currentFacilitatorSelector(state);
                if (currentFacilitator) {
                    participants[currentFacilitator.id] = {
                        ...currentFacilitator,
                        assignedRoles: [Role.Navigator, Role.Facilitator],
                    };
                }
            } else if (participantIceBreakerIndex < state.currentIntroStep.iceBreakerNavPoolIndex) {
                currentIntroStep = {
                    introStepKey: "iceBreaker",
                    iceBreakerNavPoolIndex: state.currentIntroStep.iceBreakerNavPoolIndex - 1,
                };
            } else if (
                participantIceBreakerIndex === state.currentIntroStep.iceBreakerNavPoolIndex &&
                participantIceBreakerIndex + 1 === state.iceBreakerNavigatorPool.length
            ) {
                const currentFacilitator = currentFacilitatorSelector(state);
                if (currentFacilitator) {
                    participants[currentFacilitator.id] = {
                        ...currentFacilitator,
                        assignedRoles: [Role.Navigator, Role.Facilitator],
                    };
                }
                currentIntroStep = { introStepKey: "iceBreaker", iceBreakerNavPoolIndex: "complete" };
                const stateToUpdate = {
                    ...state,
                    poolOfEligibleParticipantsForNavigatorRole: pool,
                    iceBreakerNavigatorPool: iceBreakerPool,
                    participants: values(participants),
                    participantCursors,
                    participantsHistory,
                    currentIntroStep,
                    sessionEndedAt,
                    currentStep: currentStepAddress,
                };
                const nextStep = incrementStepAddress(stateToUpdate);

                if (nextStep) {
                    // icebreaker is complete; go to next step
                    const goToStepEvent = {
                        uuid: v4(),
                        type: "goToStep",
                        from: state.currentIntroStep,
                        to: nextStep,
                    } as GoToStep;
                    return goToStep.reducer(stateToUpdate, goToStepEvent, null);
                }

                // if we weren't able to resolve to another step, just complete the icebreaker
                currentIntroStep = {
                    introStepKey: "iceBreaker",
                    iceBreakerNavPoolIndex: state.currentIntroStep.iceBreakerNavPoolIndex - 1,
                };
            } else if (participantIceBreakerIndex === state.currentIntroStep.iceBreakerNavPoolIndex) {
                const nextNavigator = participants[state.iceBreakerNavigatorPool[participantIceBreakerIndex + 1]];
                participants[nextNavigator.id] = {
                    ...nextNavigator,
                    assignedRoles: [Role.Navigator],
                };
            }
        }

        if (
            currentIntroStep.introStepKey === "iceBreaker" &&
            currentIntroStep.iceBreakerNavPoolIndex !== "intro" &&
            currentIntroStep.iceBreakerNavPoolIndex !== "complete" &&
            iceBreakerPool.length === 0
        ) {
            currentIntroStep = { introStepKey: "iceBreaker", iceBreakerNavPoolIndex: "intro" };
        }

        if (currentStep === "exit" || currentStep.stepKey === "ProgressCheck" || state.isIntroStep) {
            return {
                ...state,
                poolOfEligibleParticipantsForNavigatorRole: pool,
                iceBreakerNavigatorPool: iceBreakerPool,
                participants: values(participants),
                participantCursors,
                participantsHistory,
                currentIntroStep: currentIntroStep,
                sessionEndedAt,
                currentStep: currentStepAddress,
            };
        }

        let section = state.stepStates[currentStep.sectionKey];

        if (values(participants).length === 0) {
            const stepState = section[currentStep.stepKey];
            const introDisabledOrFirstStep = state.script.welcomeOptions?.disableIntro || currentStep.stepKey === 0;
            const timeElapsed = introDisabledOrFirstStep
                ? stepState.timeElapsed
                : stepState.timeElapsed + getUnixTime(new Date()) - state.timeStepStarted;
            section = {
                ...section,
                [currentStep.stepKey]: {
                    ...stepState,
                    timeElapsed: timeElapsed,
                },
            };
        }

        return {
            ...state,
            stepStates: {
                ...state.stepStates,
                [currentStep.sectionKey]: section,
            },
            poolOfEligibleParticipantsForNavigatorRole: pool,
            iceBreakerNavigatorPool: pool,
            participants: values(participants),
            participantCursors,
            participantsHistory,
            currentIntroStep: currentIntroStep,
            sessionEndedAt,
            currentStep: currentStepAddress,
        };
    },
};
export default participantRemoved;
