import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import "./PrivacyPolicyBanner.scss";
import { useLocalizedString } from "modules/client/localization";
import ReactMarkdown from "react-markdown";

const PrivacyPolicyBanner: React.FC = () => {
    const [bannerDismissed, setBannerDismissed] = useState<boolean>(false);
    const localization = useLocalizedString();
    const cookiesPolicyUrl = COOKIES_POLICY_URL || "";
    const privacyStatementUrl = PRIVACY_STATEMENT_URL || "";

    if (bannerDismissed) {
        return <></>;
    } else {
        return (
            <>
                <div className="privacy-policy-overlay"></div>
                <div className="privacy-policy-container">
                    <div className="privacy-policy-banner">
                        <h3>{localization("privacyBanner_title")}</h3>
                        <div className="privacy-policy-banner__text">
                            <ReactMarkdown
                                components={{
                                    a: ({ node, href, ...props }) => {
                                        return (
                                            <a href={href} target="_blank" rel="noopener noreferrer">
                                                {props.children[0]}
                                            </a>
                                        );
                                    },
                                }}
                            >
                                {localization("privacyBanner_message", cookiesPolicyUrl, privacyStatementUrl)}
                            </ReactMarkdown>
                            <Button
                                aria-description={localization("privacyBanner_dismissDescription")}
                                onClick={() => setBannerDismissed(true)}
                            >
                                {localization("privacyBanner_dismiss")}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};
export default PrivacyPolicyBanner;
