import { updateSessionState } from "modules/utils";
import { UpdateContentfulManagement } from "../actions-types";
import { ApplicationState } from "../application-state";

export default function (state: ApplicationState, event: UpdateContentfulManagement) {
    return updateSessionState(state, (session) => {
        if (session.state === "READY") {
            const newManagement = {
                ...session.managementData,
                ...event.payload,
            };
            return {
                ...session,
                managementData: newManagement,
            };
        }
        return session;
    });
}
