import React from "react";
import { useSession, useSessionSelector } from "./context";
import { Card, Container } from "react-bootstrap";
import { createParticipantSelector } from "modules/shared/selectors/participants/createParticipantSelector";
import { useLocalizedString } from "modules/client/localization";
import classNames from "classnames";
import "./end-modal.scss";
import { participantHasRole } from "modules/utils";
import { Role } from "modules/shared/content-types";

type Props = {
    isSidebarOpen: boolean;
};

const EndCard: React.FC<Props> = (props) => {
    const { participantId } = useSession();
    const participantInfo = useSessionSelector(createParticipantSelector(participantId));
    const isNavigator = !!participantInfo && participantHasRole(participantInfo, Role.Navigator);

    const localized = useLocalizedString();

    const endCardClasses = classNames(
        "session-ended-message",
        { open: props.isSidebarOpen },
        { closed: !props.isSidebarOpen },
        { navigator: isNavigator },
    );

    return (
        <Container className="d-flex justify-content-center p-0" fluid>
            <Card className={endCardClasses}>
                <Card.Header>
                    <Card.Title>{localized("vdpRenderer_endOfSession")}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <p>{localized("vdpRenderer_defaultEndingMessage")}</p>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default EndCard;
