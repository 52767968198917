import { without } from "lodash-es";
import { InitFailed, WebsocketMessage, WebsocketMessageWithResolution } from "modules/shared/message-types";
import { updateSessionState } from "modules/utils";
import { WebsocketMessageReceived } from "../actions-types";
import {
    ApplicationState,
    SessionStateContainerConnecting,
    SessionStateContainerReady,
    SessionStateContainerReconnectedWaitingForReincarnation,
} from "../application-state";
import * as selectors from "../selectors";
import { checkIfApplicable, reduce } from "./utils";

export function initState(
    state: ApplicationState,
    event: WebsocketMessage | WebsocketMessageWithResolution,
): ApplicationState {
    if (event.type == "INIT_STATE") {
        const session = selectors.sessionState(state);

        if (!session) {
            return state;
        }

        if (session.state === "READY") {
            return state;
        }

        if (session.state === "WAITING_FOR_INITIAL_STATE") {
            const readySession: SessionStateContainerReady = {
                state: "READY",
                serverSessionState: event.state,
                effectiveState: event.state,
                uncommitted: [],
                sessionId: session.sessionId,
                participantId: session.participantId,
                lastActiveTime: new Date(),
                isIdle: false,
                participantMessages: [],
            };

            const languageCode = readySession.serverSessionState.script.locale;
            const stateWithNewSession: ApplicationState = {
                ...state,
                session: readySession,
                languageCode,
            };

            const pending = session.pendingMessages;
            const stateWithPendingApplied = pending.reduce((memo, e) => {
                return sessionEvent(memo, e as WebsocketMessageWithResolution) || memo;
            }, stateWithNewSession);

            return stateWithPendingApplied;
        } else if (session.state === "CONNECTING") {
            const sessionConnecting: SessionStateContainerConnecting = {
                ...session,
                pendingMessages: [...session.pendingMessages, event],
            };
            return {
                ...state,
                session: sessionConnecting,
            };
        } else {
            throw new Error(`Received initial state message in unsupported state "${session.state}"`);
        }
    }

    return state;
}

export function initFailed(state: ApplicationState, event: InitFailed): ApplicationState {
    return updateSessionState(state, (session) => {
        return {
            ...session,
            state: "ERROR",
            error: event.payload.message,
        };
    });
}

const sessionEvent = (state: ApplicationState, eventWithRes: WebsocketMessageWithResolution): ApplicationState => {
    if (eventWithRes.type != "SESSION_MESSAGE") {
        return state;
    }
    const { event, resolution } = eventWithRes.payload;

    const eventsNeedingResolution = state.eventsAwaitingResolutions ?? [];

    const eventsAwaitingResolutions = without(eventsNeedingResolution, eventWithRes.payload.event.uuid);

    const updatedState = { ...state, eventsAwaitingResolutions };

    const session = state.session;
    if (!session || !(session.state === "READY")) {
        return updatedState;
    }

    // One might be tempted to think that since this came from the server, that
    // it must be legit. However, it turns out that the server will blindly echo
    // basically anything we give it back at us (See Engine#redisStreamHandler).
    // That's ok, since it still forces an official ordering which will mean
    // we'll all get the same results

    if (session.state === "READY" && !checkIfApplicable(event.type, session.serverSessionState, event, resolution)) {
        // Sometimes something is not applicable but an item is still in uncommitted. Weed it out.
        return updateSessionState(updatedState, (session) => {
            if (session.state !== "READY") {
                return session;
            }
            return {
                ...session,
                uncommitted: session.uncommitted.filter((m: WebsocketMessage) => {
                    if (m.type !== "SESSION_MESSAGE") {
                        return true;
                    }
                    return m.payload.event.uuid !== event.uuid;
                }),
            };
        });
    }

    return updateSessionState(updatedState, (session) => {
        if (!(session.state === "READY")) {
            return session;
        }

        const serverSessionState = reduce(event.type, session.serverSessionState, event, resolution);

        const filteredUncommitted: WebsocketMessage[] = [];
        let effectiveState = { ...serverSessionState };

        (session.state === "READY" ? session.uncommitted : []).forEach((u) => {
            if (u.type === "SESSION_MESSAGE" && u.payload.event.uuid === event.uuid) {
                // this is the message that just came in, can ignore
            } else {
                if (u.type === "SESSION_MESSAGE") {
                    const isApplicable = checkIfApplicable(u.payload.event.type, effectiveState, u.payload.event, null);

                    if (isApplicable) {
                        effectiveState = reduce(u.payload.event.type, effectiveState, u.payload.event, null);
                        filteredUncommitted.push(u);
                    }
                }
                return false;
            }
        });

        return {
            ...session,
            uncommitted: filteredUncommitted,
            serverSessionState,
            effectiveState,
        };
    });
};

function handleMessageDuringReincarnation(
    state: ApplicationState,
    eventWithRes: WebsocketMessageWithResolution,
): ApplicationState {
    return updateSessionState(state, (currentSession) => {
        if (currentSession.state !== "RECONNECTED_WAITING_FOR_REINCARNATION") {
            throw new Error(`Error: expected session to be in 'reconnected' state, got ${currentSession.state}`);
        }

        // Case 1: We're waiting for a new initial state, and this is it
        if (currentSession.reincarnationState === "waitingForInitialState" && eventWithRes.type === "INIT_STATE") {
            const oldParticipantId = currentSession.oldParticipantId;

            if (!currentSession.newParticipantId) {
                throw new Error("No socket ID for current connection.");
            }

            const updatedSession: SessionStateContainerReconnectedWaitingForReincarnation = {
                state: "RECONNECTED_WAITING_FOR_REINCARNATION",
                reincarnationState: "waitingForParticipant",
                serverSessionState: eventWithRes.state,
                sessionId: currentSession.sessionId,
                newParticipantId: currentSession.newParticipantId!,
                oldParticipantId: oldParticipantId,
            };

            return updatedSession;
        }

        // Case 2: This isn't it - keep waiting
        if (currentSession.reincarnationState === "waitingForInitialState") {
            // waiting for initial state, but this isn't it
            return currentSession;
        }

        // Else, just accept the message and wait for us to be ready
        let serverState = currentSession.serverSessionState;
        if (eventWithRes.type === "SESSION_MESSAGE") {
            const { event, resolution } = eventWithRes.payload;
            if (checkIfApplicable(event.type, currentSession.serverSessionState, event, resolution)) {
                serverState = reduce(event.type, serverState, event, resolution);
            }
        }

        return {
            ...currentSession,
            serverSessionState: serverState,
        };
    });
}

export default (state: ApplicationState, event: WebsocketMessageReceived): ApplicationState => {
    if (selectors.sessionState(state)?.state === "RECONNECTED_WAITING_FOR_REINCARNATION") {
        return handleMessageDuringReincarnation(state, event.payload);
    }

    if (event.payload.type === "INIT_STATE") {
        return initState(state, event.payload);
    }

    if (event.payload.type === "INIT_FAILED") {
        return initFailed(state, event.payload);
    }

    if (event.payload.type === "SESSION_MESSAGE") {
        const isReady = selectors.isReady(state);
        const sessionMessage = event.payload;
        if (isReady) {
            return sessionEvent(state, sessionMessage);
        } else if (!isReady) {
            return updateSessionState(state, (session) => {
                if (session.state === "CONNECTING" || session.state === "WAITING_FOR_INITIAL_STATE") {
                    return {
                        ...session,
                        pendingMessages: [...session.pendingMessages, event.payload],
                    };
                } else {
                    throw new Error(`Unable to process message with ready of ${isReady} and state of ${session.state}`);
                }
            });
        }
    }

    throw new Error(`Unknown websocket message type ${(event.payload as any).type}`);
};
