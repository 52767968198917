import { IPublicClientApplication } from "@azure/msal-browser";
import { useCallback, useEffect, useState } from "react";
import { callSecureEndpoint } from "./client/auth";
import { useMsal } from "@azure/msal-react";

export type FetchState<T> =
    | { state: "LOADING" }
    | {
          state: "DONE";
          data: T;
      }
    | {
          state: "ERROR";
          status?: number;
          statusText?: string;
          error?: Error;
      };

export function useFetch<T>(url: string, headers?: HeadersInit): FetchState<T> {
    const [result, setResult] = useState<FetchState<T>>({ state: "LOADING" });

    useEffect(() => {
        const fetcher = async () => {
            try {
                const response = await fetch(url, { headers: headers });
                if (response.ok) {
                    const data: T = await response.json();
                    setResult({
                        state: "DONE",
                        data,
                    });
                } else {
                    setResult({
                        state: "ERROR",
                        status: response.status,
                        statusText: response.statusText,
                    });
                }
            } catch (error: any) {
                setResult({
                    state: "ERROR",
                    error,
                });
            }
        };

        void fetcher();
    }, [url, headers]);

    return result;
}

export function useProtectedFetch<T>(
    msalInstance: IPublicClientApplication,
    url: string,
    init?: RequestInit,
): FetchState<T> {
    const [result, setResult] = useState<FetchState<T>>({ state: "LOADING" });

    useEffect(() => {
        const fetcher = async () => {
            try {
                const response = await callSecureEndpoint(msalInstance, url, init);
                if (response?.ok) {
                    const data: T = await response.json();
                    setResult({
                        state: "DONE",
                        data,
                    });
                } else {
                    setResult({
                        state: "ERROR",
                        status: response?.status,
                        statusText: response?.statusText,
                    });
                }
            } catch (error: any) {
                setResult({
                    state: "ERROR",
                    error,
                });
            }
        };

        void fetcher();
        // we do not want msalInstance in the dependency array because we want to refresh
        // the token in the background (and therefore updating context) without triggering
        // a new fetch hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, init]);

    return result;
}

// From https://usehooks.com/useDebounce/
export function useDebounce<TValue>(value: TValue, delay: number): TValue {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<TValue>(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export function useUserEmail() {
    const { accounts } = useMsal();
    if (accounts.length === 0) {
        return null;
    }
    const { idTokenClaims } = accounts[0];
    const userEmail = idTokenClaims?.unique_name as string | null;
    if (!userEmail) {
        throw new Error("A unique name is not defined for the user");
    }
    return userEmail;
}

export function useSignOut() {
    const { instance } = useMsal();

    const signOut = useCallback(async () => {
        const urlString = "api/logout";
        const url = new URL(urlString, CLIENT_APP_URL);
        try {
            const response = await callSecureEndpoint(instance, url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            sessionStorage.clear();
            await instance.logoutRedirect({
                account: instance.getActiveAccount(),
                postLogoutRedirectUri: "/",
            });
        } catch (error) {
            console.error("Logout failed", error instanceof Error ? error.message : error);
        }
    }, [instance]);

    return signOut;
}
