import { updateSessionState } from "modules/utils";
import { SocketReconnect } from "../actions-types";
import { ApplicationState, SessionStateContainer } from "../application-state";

export default (state: ApplicationState, payload: SocketReconnect): ApplicationState => {
    return updateSessionState(state, (session: SessionStateContainer) => {
        if (session.state === "RECONNECTING") {
            return {
                ...session,
                state: "RECONNECTED_WAITING_FOR_REINCARNATION",
                reincarnationState: "waitingForInitialState",
                newParticipantId: payload.newSocketId,
            };
        } else {
            throw new Error(`Received SOCKET_RECONNECT in invalid state ${session.state}`);
        }
    });
};
