import { SessionState, StepAddress, StepIntroAddress, StepState } from "../../types";
import { isIntroStepAddress } from "../../utils";

export function getStepState<TType extends StepState["type"], TStepState extends StepState & { type: TType }>(
    t: TType,
    state: SessionState,
    stepKey?: StepAddress | StepIntroAddress,
): TStepState | null {
    const currentStep = stepKey ?? state.currentStep;
    if (state.isIntroStep || isIntroStepAddress(currentStep)) {
        const isIntroTimingActive = !!state.script.welcomeOptions && state.script.welcomeOptions.time;
        // TODO Fix this later to make adjustment for the new intro step state
        const step = isIntroTimingActive
            ? {
                  type: "INTRO",
                  transient: null,
                  persistent: null,
                  timeElapsed: 0,
              }
            : {
                  type: "INTRO",
                  transient: null,
                  persistent: null,
              };
        return step as TStepState;
    } else {
        if (currentStep === "exit") {
            const step = {
                type: "EXIT",
                transient: null,
                persistent: null,
            };
            return step as TStepState;
        } else if (currentStep.stepKey === "ProgressCheck") {
            const step = {
                type: "PROGRESS_CHECK",
                transient: null,
                persistent: null,
            };
            return step as TStepState;
        }

        const section = state.stepStates[currentStep.sectionKey];
        const step = section[currentStep.stepKey];

        return step.type === t ? (step as TStepState) : null;
    }
}
