import L, { LatLngBounds, LatLngBoundsExpression, LeafletMouseEvent, ResizeEvent } from "leaflet";
import "leaflet/dist/leaflet.css";
import { throttle } from "lodash-es";
import LeafletMapMetadata from "modules/database_types/leaflet-map-metadata";
import { useDebounce } from "modules/hooks";
import React, { useEffect, useRef, useState } from "react";
import { MapState, PreviewFocusAreaPlacement } from "modules/shared/types";
import "./LeafletMap.scss";
import { getMaxZoom, getMinZoom, getPixelSize, getTileSize } from "./utils";
import { useMapContext } from "./MapContext";

type Props = {
    location: string;
    onMouseMove: (e: { latlng: L.LatLng }) => void;
    onMouseMoveForFocusAreaPreviewPlacement: (e: { latlng: L.LatLng }) => void;
    onMapMove?: (b: L.LatLngBounds) => void;
    onMouseClick: (e: { latlng: L.LatLng }) => void;
    focusPixelArea?: MapState | null;
    focusAreaPreviewPlacement: PreviewFocusAreaPlacement | null;
    mapMetadata: LeafletMapMetadata;
    enableMapMarkup: boolean;
    backgroundColor?: string;
    children?: React.ReactNode;
};

const MAP_MARGIN = 1000;

const LeafletMap: React.FC<Props> = ({
    focusAreaPreviewPlacement,
    focusPixelArea,
    onMouseClick,
    onMouseMove,
    onMapMove,
    onMouseMoveForFocusAreaPreviewPlacement,
    location,
    enableMapMarkup,
    backgroundColor,
    children: _children,
    mapMetadata,
}) => {
    const { map } = useMapContext();
    const mapTileLayerRef = useRef<L.TileLayer | undefined>(undefined);

    const { tile_matrix, minzoom, maxzoom, extent } = mapMetadata;

    const tile_matrix_string = JSON.stringify(tile_matrix);

    // Pixel size helps map between pixels and map units. It does differ per
    // zoom level, but I *think* that most of our use cases only care about the
    // zoomed-out level. This is because when we focus a map area, we define our
    // coords in the zoomed-out image.
    // we use tile_matrix_string here to prevent unnecessary re-rendering if the object gets re-instated with the same values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pixelSize = React.useMemo(() => getPixelSize({ minzoom, tile_matrix }), [minzoom, tile_matrix_string]);
    const [ymax, ymin, xmax, xmin] = extent;

    const maximumMapBounds: LatLngBoundsExpression = React.useMemo(
        () => [
            [ymax / pixelSize[1], -xmin / pixelSize[0]],
            [-ymin / pixelSize[1], xmax / pixelSize[0]],
        ],
        [ymax, ymin, xmax, xmin, pixelSize],
    );
    const maximumScrollArea: LatLngBounds = React.useMemo(() => {
        const southWest = L.latLng((ymax - MAP_MARGIN) / pixelSize[1], (-xmin - MAP_MARGIN) / pixelSize[0]);
        const northEast = L.latLng(-(ymin - MAP_MARGIN) / pixelSize[1], (xmax + MAP_MARGIN) / pixelSize[0]);
        return L.latLngBounds(southWest, northEast);
    }, [ymax, ymin, xmax, xmin, pixelSize]);

    const [mapId, setMapId] = useState<number>(Math.random());
    const [resizeId, setResizeId] = useState<string>("");
    const focusId = (!!focusPixelArea ? [...focusPixelArea.topLeft, ...focusPixelArea.bottomRight] : []).join("-");
    const shouldFocusKey = useDebounce(`${mapId}-${resizeId}-${focusId}`, 500);

    const addMapVisual = React.useCallback(() => {
        const mapTileSize = getTileSize({ tile_matrix, minzoom });
        const maxZoom = getMaxZoom({ maxzoom });
        const minZoom = getMinZoom({ minzoom });
        const mapLocation = location;

        const tileLayer = L.tileLayer(`${mapLocation}/{z}/{x}/{y}.png`, {
            tileSize: mapTileSize,
            bounds: maximumMapBounds,
            minZoom,
            maxZoom,
            tms: true,
        });

        return tileLayer.addTo(map);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, maxzoom, minzoom, location, tile_matrix_string, maximumMapBounds]);

    // Create the map object to be used by each hook modifying the map
    // This hook should be first
    useEffect(
        () => {
            map.setMaxBounds(maximumScrollArea);
            map.fitBounds(maximumMapBounds, {});

            mapTileLayerRef.current = addMapVisual();

            const onMoveEnd = function () {
                const bounds = map.getBounds();
                if (onMapMove) {
                    onMapMove(bounds);
                }
            };

            map.on("moveend", onMoveEnd);

            const onResize = (e: ResizeEvent) => {
                setResizeId([e.target.innerWidth, e.target.innerHeight].join("-"));
            };

            // Give the map a bit after initializing, and then set this to trigger a zoom if needed
            setTimeout(() => setMapId(Math.random()), 250);

            window.addEventListener("resize", onResize as any);
            return () => {
                window.removeEventListener("resize", onResize as any);
                map.off("moveend", onMoveEnd);
            };
        },

        // We actually don't want this to ever rerun and reinitialize the map,
        // so in this case the linter is wrong:
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [map],
    );

    // Create mouse event handlers for
    useEffect(() => {
        const throttledMouseMove = throttle(onMouseMove, 250);

        const onMapMouseClick = (e: LeafletMouseEvent) => {
            if (onMouseClick) {
                onMouseClick(e);
            }
        };
        const onMapMouseMove = (e: LeafletMouseEvent) => {
            if (!!focusAreaPreviewPlacement) {
                onMouseMoveForFocusAreaPreviewPlacement(e);
            }
            if (throttledMouseMove) {
                throttledMouseMove(e);
            }
        };
        if (map) {
            map.on("click", onMapMouseClick);
            map.on("mousemove", onMapMouseMove);
        }
        return () => {
            if (map) {
                map.off("click", onMapMouseClick);
                map.off("mousemove", onMapMouseMove);
            }
            throttledMouseMove.cancel();
        };
    }, [map, onMouseMoveForFocusAreaPreviewPlacement, onMouseMove, onMouseClick, focusAreaPreviewPlacement]);

    useEffect(() => {
        if (!mapTileLayerRef.current) {
            return;
        }
        map?.removeLayer(mapTileLayerRef.current);

        mapTileLayerRef.current = addMapVisual();
    }, [location, addMapVisual, map]);

    useEffect(() => {
        if (focusPixelArea) {
            map.flyToPixelArea(focusPixelArea);
        } else {
            map.options.maxBounds = maximumScrollArea;
            const minZoom = getMinZoom(mapMetadata);
            map.options.minZoom = minZoom;
        }

        // I explicitly manage the focusKey to change when this should rerun, so
        // we don't want this behavior here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFocusKey, map]);

    // we have to modify the dom directly because we do not want the leaflet map's dom node to ever unmount
    useEffect(() => {
        const mapElement = map.getContainer();
        if (!!backgroundColor) {
            mapElement.style.backgroundColor = backgroundColor;
        } else {
            mapElement.style.backgroundColor = "";
        }
    }, [backgroundColor, map]);

    // we have to modify the dom directly because we do not want the leaflet map's dom node to ever unmount
    useEffect(() => {
        const mapElement = map.getContainer();
        if (enableMapMarkup) {
            mapElement.classList.add("map-markup");
        } else {
            mapElement.classList.remove("map-markup");
        }
    }, [enableMapMarkup, map]);

    // If we animate to the bounds, then we would need to wait until after the
    // animation completes to find the zoom level Leaflet gave us. So it turns
    // out that rather than setting a min and max zoom to lock us, it's much
    // simpler to just prevent pointer interactions with the css class.
    return <></>;
};

export default LeafletMap;
